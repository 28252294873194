import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesRating } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesRating> =>
    createStyles({
      root: {
        fontSize: '16px',
        color: theme.palette.warning.main,
      },
      iconEmpty: {
        color: theme.palette.grey.A100,
      },
      icon: {
        width: '14px',
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TMagnifier, TStylesImageMagnifier } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesImageMagnifier,
    {
      magnifierPosition: TMagnifier;
      imageUrl: string;
      magnifierRadius: number;
      isMagnifierVisible: boolean;
    }
  > =>
    createStyles({
      magnifier: ({ imageUrl, magnifierRadius, magnifierPosition, isMagnifierVisible }) => ({
        flip: false as unknown as string,
        boxShadow: '0 5px 10px -2px rgba(0, 0, 0, 0.2)',
        pointerEvents: 'none',
        position: 'absolute',
        border: `1.5px solid ${theme.palette.grey.A100}`,
        zIndex: 100,
        display: 'block',
        backgroundImage: `url("${imageUrl}")`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#fff',
        transform: 'scale(1.5)',
        width: magnifierRadius,
        height: magnifierRadius,
        borderRadius: magnifierRadius,
        top: `${magnifierPosition.top}px`,
        left: `${magnifierPosition.left}px`,
        backgroundPositionX: -1 * magnifierPosition.offsetX,
        backgroundPositionY: -1 * magnifierPosition.offsetY,
        opacity: isMagnifierVisible ? 1 : 0,
        [theme.breakpoints.up('md')]: {
          transition: 'opacity 0.1s',
        },
      }),
    }),
);

import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import useStyles from './styles';
import { ISkeletonTopCartPreviousOrderItem } from './types';

const SkeletonTopCartPreviousOrderItem: FC<ISkeletonTopCartPreviousOrderItem> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Box display="flex" justifyContent="flex-start" width={0.75}>
        <Box className={classes.orderWrapper} width={50}>
          <Skeleton animation="wave" width="80%" variant="text" height={36} />
          <Skeleton animation="wave" width="100%" variant="text" />
        </Box>
        <Box className={classes.orderWrapper} width={40}>
          <Skeleton animation="wave" width="60%" variant="text" height={36} />
          <Skeleton animation="wave" width="100%" variant="text" />
        </Box>
        <Box className={classes.orderWrapper} width={90}>
          <Skeleton animation="wave" width="65%" variant="text" height={36} />
          <Skeleton animation="wave" width="50%" variant="text" />
        </Box>
      </Box>
      <Skeleton animation="wave" variant="circle" height={36} width={36} />
    </Box>
  );
};

export default SkeletonTopCartPreviousOrderItem;

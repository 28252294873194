import React, { FC } from 'react';

import CheckBoxWithLabel from 'ui/common/inputs/CheckBoxWithLabel';

import { TCheckBoxWithLabelField } from './types';

const CheckBoxWithLabelField: FC<TCheckBoxWithLabelField> = ({ field, label, ...rest }) => {
  return <CheckBoxWithLabel label={label} {...field} {...rest} />;
};

export default CheckBoxWithLabelField;

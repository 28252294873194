import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUseFreeProducts } from 'hooks/useFreeProducts/types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseFreeProducts> =>
    createStyles({
      divider: {
        backgroundColor: theme.palette.grey.A100,
      },
    }),
);

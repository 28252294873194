import { useCallback, useState } from 'react';

import { OrderService } from 'api';

import { getAvailableAreaHours } from 'utils';

import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useSelector } from 'react-redux';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import { isSubscriptionMode } from 'utils/helpers/subscriptions';
import {
  TAvailableArea,
  TDeliveryAreaItem,
  TSelfPickupAreaItem,
  TUseAvailableServiceAreas,
} from './types';

// TODO - return the actionResult instead of data + message separately (like in other places we use the API)
// TODO - beware - don't use this hook in siblings / parent-child components (it's causing a side effect of fetching the available-delivery-areas)
const useAvailableServiceAreas: TUseAvailableServiceAreas = (initialSelfPickupAreas) => {
  const websiteDetails = useWebsiteDetails();
  const orderMode = useSelector(getOrderMode);
  const [loading, setLoading] = useState<boolean>(!initialSelfPickupAreas?.length);

  const [deliveryArea, setDeliveryArea] = useState<TDeliveryAreaItem>({
    availableArea: [],
    storeServiceAreaId: 0,
  });
  const [selfPickupAreasArray, setSelfPickupAreasArray] = useState<TSelfPickupAreaItem[]>(
    initialSelfPickupAreas || [],
  );

  const [deliveryAreaErrorMessage, setDeliveryAreaErrorMessage] = useState<string>('');
  const [selfPickupErrorMessage, setSelfPickupErrorMessage] = useState<string>('');

  const getAvailableAreas = useCallback(async (): Promise<void> => {
    try {
      const availableAreasResult = await OrderService.getAvailableServiceAreas(
        {
          storeId: websiteDetails.store.id,
          allServiceHours: isSubscriptionMode(orderMode),
        },
        { setLoading, handleError: false },
      );

      const { availableDeliveryArea, availableSelfPickupAreas } = availableAreasResult.data;

      // update deliveryArea state
      if (availableDeliveryArea.success && availableDeliveryArea.data) {
        const newDeliveryAvailableArea: TAvailableArea[] = getAvailableAreaHours(
          availableDeliveryArea.data.availableHours,
          !isSubscriptionMode(orderMode),
        );

        const newDeliveryArea: TDeliveryAreaItem = {
          availableArea: newDeliveryAvailableArea,
          storeServiceAreaId: availableDeliveryArea.data.serviceArea.id,
        };

        setDeliveryArea(newDeliveryArea);
        setDeliveryAreaErrorMessage('');

        if (!availableDeliveryArea.data.availableHours.length) {
          setDeliveryAreaErrorMessage('deliveryPickUp.allTimeFramesAreFull');
        }
      }

      if (!availableDeliveryArea.success) {
        setDeliveryAreaErrorMessage(availableDeliveryArea.resolvedMessage);
        setDeliveryArea({
          availableArea: [],
          storeServiceAreaId: 0,
        });
      }

      // update selfPickupAreasArray state
      if (availableSelfPickupAreas.success && availableSelfPickupAreas.data) {
        const newSelfPickupAreas: TSelfPickupAreaItem[] = [];

        availableSelfPickupAreas.data.forEach(
          ({
            serviceArea: { name, id, matchesClientCity, clientDistanceInKM, coordinatesLatLng },
            availableHours,
          }) => {
            newSelfPickupAreas.push({
              serviceArea: {
                storeServiceAreaId: id,
                name,
                matchesClientCity,
                clientDistanceInKM,
                coordinatesLatLng,
              },
              availableArea: getAvailableAreaHours(availableHours, !isSubscriptionMode(orderMode)),
            });
          },
        );

        setSelfPickupAreasArray(newSelfPickupAreas);
        setSelfPickupErrorMessage('');

        if (!availableSelfPickupAreas.data.length) {
          setSelfPickupErrorMessage('deliveryPickUp.allTimeFramesAreFull');
        }
      }

      if (!availableSelfPickupAreas.success) {
        setSelfPickupErrorMessage(availableSelfPickupAreas.resolvedMessage);
        setSelfPickupAreasArray([]);
      }
    } catch (e) {
      setDeliveryAreaErrorMessage('deliveryPickUp.errorMessage');
      setSelfPickupErrorMessage('deliveryPickUp.errorMessage');
    }
  }, [orderMode, websiteDetails.store.id]);

  return {
    getAvailableAreas,
    loading,
    deliveryArea,
    selfPickupAreasArray,
    deliveryAreaErrorMessage,
    selfPickupErrorMessage,
  };
};

export default useAvailableServiceAreas;

import React, { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, CardMedia } from '@material-ui/core';

import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';
import MobileFooter from 'ui/mobile/Footer/Footer';
import DesktopFooter from 'ui/desktop/Footer/Footer';

import { useFooter, useMobile } from 'hooks';
import classNames from 'classnames';
import Icon from 'ui/common/icons/Icon';
import useStyles from './styles';
import { IErrorContent } from './types';

const ErrorContent: FC<IErrorContent> = ({ errorDescription }) => {
  const { t } = useTranslation();

  const { getDesktopFooterProps, getMobileFooterProps } = useFooter();

  const { isMobile } = useMobile();

  const [showErrorDetails, setShowErrorDetails] = useState<boolean>(true);

  const classes = useStyles({ showErrorDetails });

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <Box className={classes.mainWrapper}>
            <Box className={classes.imageWrapper}>
              <CardMedia
                classes={{ root: classes.errorImage }}
                alt={t('error.somethingWentWrong')}
                src="/assets/images/error-banana.jpg"
                component="img"
              />
            </Box>
            <Box className={classes.typographyWrapper}>
              <Box className={classes.titleWrapper}>
                <Box className={classes.title}>
                  <Typography variant="h1" color="secondary" className={classes.typography}>
                    {'error.oops'}
                  </Typography>
                </Box>
                <Box className={classes.description}>
                  <Typography
                    variant="body1"
                    color="grey"
                    className={classNames(classes.errorDescription, 'font-family-rubik')}
                  >
                    {'error.errorDescription'}
                  </Typography>
                </Box>
              </Box>
              <a href="/" style={{ textDecoration: 'none' }}>
                <CTAButton className={classes.backToTheHomePageBtn}>
                  {'button.backToTheHomePage'}
                </CTAButton>
              </a>
              {errorDescription && (
                <CTAButton
                  className={classes.errorDetailsButton}
                  disableTouchRipple
                  onClick={() => setShowErrorDetails((prev) => !prev)}
                >
                  <Typography variant="body1" className={classes.errorDetailsLabel}>
                    {'error.errorDetails'}
                  </Typography>
                  <Icon
                    name="icon-arrow-down"
                    classes={{
                      root: classNames(classes.iconArrow),
                    }}
                  />
                </CTAButton>
              )}
            </Box>
          </Box>
          {errorDescription && (
            <Box className={classes.errorDetailsWrapper}>
              <Typography
                variant="body1"
                className={classes.errorDetails}
                dangerouslySetInnerHTML={{ __html: errorDescription }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        {isMobile ? (
          <MobileFooter {...getMobileFooterProps('mini')} />
        ) : (
          <DesktopFooter {...getDesktopFooterProps('mini')} />
        )}
      </Box>
    </>
  );
};

export default ErrorContent;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesShareProductCartDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesShareProductCartDialog> =>
    createStyles({
      shareCartWrapper: { padding: theme.spacing(0, 0, 70 / 8) },
      shareCartButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '65px',
        [theme.breakpoints.up('md')]: {
          width: '80px',
        },
      },
      shareCartImage: {
        margin: '0 auto',
        width: '182px',
        marginTop: theme.spacing(40 / 8),
      },
      shareCartTitle: {
        fontSize: '30px',
        lineHeight: '34px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.secondary.main,
        margin: theme.spacing(20 / 8, 0, 10 / 8),
      },
      shareCartSubtitle: {
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        color: theme.palette.grey[600],
        marginBottom: theme.spacing(24 / 8),
      },
      shareCartButton: {
        background: theme.palette.primary.main,
        borderRadius: '50%',
        padding: 0,
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
      },
      shareCartMainWrapper: {
        padding: theme.spacing(0, 30 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 70 / 8),
        },
      },
      shareCartButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      shareCartLabel: { display: 'flex', padding: '17px' },
      shareCartIcon: {
        color: theme.palette.background.paper,
        fontSize: '26px',
      },
      shareCartButtonLabel: {
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'center',
        color: theme.palette.grey[600],
        marginTop: theme.spacing(10 / 8),
      },
    }),
);

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TRenderLanguageField, TUseMultiLanguage } from 'hooks/useMultiLanguage/types';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { Languages } from 'ui/desktop/LanguageSwitcher';

const useMultiLanguage: TUseMultiLanguage = () => {
  const { i18n } = useTranslation();
  const websiteDetails = useWebsiteDetails();

  const renderLanguageField = useCallback<TRenderLanguageField>(
    (sourceObject, key) => {
      const localizedField = sourceObject[i18n.language];

      return (localizedField && localizedField[key]) || sourceObject.he[key];
    },
    [i18n.language],
  );

  const relevantLanguages = useMemo(() => {
    return Languages.filter(
      (lng) =>
        (websiteDetails.websiteSettings.displayableLanguages &&
          websiteDetails.websiteSettings.displayableLanguages[lng.shortName]) ||
        lng.shortName === 'he',
    );
  }, [websiteDetails.websiteSettings.displayableLanguages]);

  return {
    renderLanguageField,
    relevantLanguages,
  };
};

export default useMultiLanguage;

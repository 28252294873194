import { find, omit, sortBy, toLower } from 'lodash';
import {
  IHasMultiLangJson,
  IProductSellingUnit,
  ISellingUnit,
  IStoreProduct,
  IStoreProductSource,
  IStoreProductSourceSellingUnit,
  IWebsiteDetails,
} from 'store';
import { IHasParsedMultiLang } from 'store/modules/storeProduct/types';
import { TSellingUnitServer, TStoreProductServer, TStoreProductSourceServer } from 'types';

export function prepareStoreProductServer(
  storeProduct: TStoreProductServer,
  useMLWeightEstimations: IWebsiteDetails['store']['performSellingUnitsEstimationLearning'],
): IStoreProduct {
  // sort productSellingUnits with by sortOrder
  const sortedProductSellingUnits = sortBy(
    storeProduct.productSellingUnits,
    'sellingUnit.sortOrder',
  );

  const newStoreProductSellingUnitsJson: IStoreProduct['storeProductSellingUnitsJson'] = JSON.parse(
    storeProduct.storeProductSellingUnitsJson || '{}',
  );

  const newProductSellingUnits = sortedProductSellingUnits.map(
    ({ id: unitId, sellingUnit, maxAmount, estimatedUnitWeight }) => {
      const sellingUnitJsonConfig = newStoreProductSellingUnitsJson[unitId];

      // check values in storeProductSellingUnitsJson and change if it exists
      const newAmountJumps = sellingUnitJsonConfig?.amountJumps || sellingUnit.amountJumps;
      const newMaxAmount = sellingUnitJsonConfig?.maxAmount || maxAmount;
      /**
       * 1. if soldByWeight = false set current estimatedUnitWeight (null)
       * 2. if value exist in sellingUnitJsonConfig set sellingUnitJsonConfig.estimatedWeight
       * 3. if current estimatedUnitWeight value not null set it
       * 4. if current estimatedUnitWeight = null set defaultEstimatedUnitWeight (1)
       */
      let newEstimatedUnitWeight = storeProduct.soldByWeight
        ? estimatedUnitWeight || 1
        : estimatedUnitWeight;

      if (sellingUnitJsonConfig?.estimatedWeight) {
        newEstimatedUnitWeight = sellingUnitJsonConfig.estimatedWeight;
      }

      if (sellingUnitJsonConfig?.estimatedWeightML && useMLWeightEstimations) {
        newEstimatedUnitWeight = +sellingUnitJsonConfig.estimatedWeightML;
      }

      const newProductSellingUnit: IProductSellingUnit = {
        id: unitId,
        sellingUnit: prepareMultiLangObject(sellingUnit),
        amountJumps: newAmountJumps,
        maxAmount: newMaxAmount,
        estimatedUnitWeight: newEstimatedUnitWeight,
      };
      return newProductSellingUnit;
    },
  );

  const newDefaultSellingUnit =
    storeProduct.product.defaultSellingUnit || newProductSellingUnits[0].sellingUnit;

  // if soldByWeight = false set first item id in array of newProductSellingUnits
  const newPrimaryQuantityUnit = !storeProduct.soldByWeight
    ? newProductSellingUnits[0].sellingUnit
    : storeProduct.product.primaryQuantityUnit || newProductSellingUnits[0].sellingUnit; // if soldByWeight = true set primaryQuantityUnit id or first ProductSellingUnit by default

  // set product active selling unit
  const selectedSellingUnit =
    find(newProductSellingUnits, ['sellingUnit', newDefaultSellingUnit]) ||
    newProductSellingUnits[0];

  const newStoreProduct: IStoreProduct = {
    ...omit(storeProduct, ['bagOfProductsJson', 'metadataJson']),
    productSellingUnits: newProductSellingUnits,
    productCategory: storeProduct.productCategory,
    product: prepareMultiLangObject({
      ...storeProduct.product,
      defaultSellingUnit: prepareMultiLangObject(newDefaultSellingUnit),
      primaryQuantityUnit: prepareMultiLangObject(newPrimaryQuantityUnit),
    }),
    productQuality: prepareMultiLangObject(storeProduct.productQuality),
    country: storeProduct.country
      ? prepareMultiLangObject({
          ...storeProduct.country,
          shortIdentifier: toLower(storeProduct.country.shortIdentifier),
        })
      : undefined,
    defaultSelectedSellingUnit: {
      ...selectedSellingUnit,
    },
    storeProductSellingUnitsJson: newStoreProductSellingUnitsJson,
    commentType: storeProduct.commentType?.comments?.length ? storeProduct.commentType : undefined,
    additionalImages: storeProduct.additionalImages
      ? JSON.parse(storeProduct.additionalImages).map((url: string) => {
          return `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${url}`;
        })
      : [],
    metadata: JSON.parse(storeProduct.metadataJson || '{}'),
  };

  // set bagOfProducts fields
  if (storeProduct.bagOfProducts) {
    newStoreProduct.bagOfProductsJson = JSON.parse(storeProduct.bagOfProductsJson as string);
  }
  return newStoreProduct;
}

export function prepareSellingUnit(sourceObject: TSellingUnitServer): ISellingUnit {
  omit(sourceObject, ['amountJumps']);

  return {
    ...sourceObject,
    multiLang: {
      he: {
        name: sourceObject.name,
      },
      ...JSON.parse(sourceObject.multiLangJson || '{}'),
    },
  };
}

export function prepareMultiLangObject<T extends IHasMultiLangJson>(
  sourceObject: T,
): T & IHasParsedMultiLang {
  return {
    ...sourceObject,
    multiLang: {
      he: {
        name: sourceObject.name,
      },
      ...JSON.parse(sourceObject.multiLangJson || '{}'),
    },
  };
}

export function prepareStoreProductSource(
  storeProductSource?: TStoreProductSourceServer,
): IStoreProductSource | undefined {
  if (!storeProductSource) {
    return;
  }

  const sellingUnitsArray: IStoreProductSource['productSellingUnits'] = [];
  let primaryQuantityUnit: IStoreProductSourceSellingUnit | undefined;

  storeProductSource.productSellingUnits.forEach((productSellingUnit) => {
    sellingUnitsArray.push({
      sellingUnit: prepareMultiLangObject(productSellingUnit.sellingUnit),
    });
  });

  if (storeProductSource.product.primaryQuantityUnit) {
    primaryQuantityUnit = prepareMultiLangObject(storeProductSource.product.primaryQuantityUnit);
  }

  return {
    ...storeProductSource,
    product: {
      ...storeProductSource.product,
      primaryQuantityUnit,
    },
    productSellingUnits: sellingUnitsArray,
  };
}

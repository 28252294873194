import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesImagesBannerSlider } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesImagesBannerSlider, { isOpenedTopCart: boolean }> =>
    createStyles({
      mobileImagesBannerSlider: {
        padding: '0px  14px',
        display: 'flex',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'transparent !important',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent !important',
          borderRadius: 'none',
        },
      },
      imagesBannerSlider: {
        maxWidth: '1240px',
        margin: '0 auto',
      },
      imageWrapper: {
        width: '320px',
        height: '220px',
        borderRadius: '6px',
        [theme.breakpoints.up('md')]: {
          width: ({ isOpenedTopCart }) => (isOpenedTopCart ? 438 : 609),
          height: ({ isOpenedTopCart }) => (isOpenedTopCart ? 300 : 417),
          borderRadius: '8px',
        },
      },

      imagesWrapper: ({ isOpenedTopCart }) => ({
        maxWidth: isOpenedTopCart ? 898 : '100%',
        '& .swiper-wrapper': {
          boxSizing: 'border-box',
        },
      }),
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesMobileSearch } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMobileSearch, { isFocused: boolean }> =>
    createStyles({
      search: {
        width: ({ isFocused }) => {
          return isFocused ? '100%' : theme.spacing(3.5); // 28px,
        },
        transition: theme.transitions.create('width'),
        position: 'relative',
        marginLeft: 0,
      },
      searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
        fontSize: theme.spacing(18 / 8),
      },
      inputRoot: {
        color: 'inherit',
        transition: theme.transitions.create('width'),
        width: ({ isFocused }) => (isFocused ? '100%' : theme.spacing(32 / 8)),
      },
      inputInput: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.spacing(18 / 8),
        padding: 0,
        fontSize: theme.spacing(14 / 8),
        transition: theme.transitions.create('width'),
        width: theme.spacing(32 / 8),
        height: theme.spacing(32 / 8),
        color: 'transparent',

        '&::placeholder': {
          color: 'transparent',
        },
      },
      focused: {
        padding: theme.spacing(0, 4.25), // 0 34px
        color: theme.palette.text.secondary,
        width: '100%',

        '&::placeholder': {
          color: theme.palette.grey.A400,
          opacity: 1,
        },
      },
      closeIcon: {
        padding: ({ isFocused }) => (isFocused ? theme.spacing(0, 1.5) : 0),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 3,
        fontSize: theme.spacing(1.25), // 10px
        right: 0,
        top: 0,
        cursor: 'pointer',
        opacity: ({ isFocused }) => (isFocused ? 1 : 0),
        transition: ({ isFocused }) =>
          isFocused ? theme.transitions.create('opacity', { duration: 1000 }) : 'none',
      },
    }),
);

import { TProductAdditionSource } from 'hooks';
import { IHasMultiLangJson, IStoreProduct, TBagOfProductsItemWithMetadata } from 'store';
import { ILatLng } from 'ui/common/Map';

export type TMixedType = string | number;

export type TIdDTO = {
  id: TMixedType;
};

// to be used when we have only id & name for a property
export type TIdNameDTO = {
  id: TMixedType;
  name: string;
};

export type TStoreProductServer = {
  id: TMixedType;
  obfuscatedId: string;
  active: boolean;
  ancestor?: TIdDTO;
  name: string;
  fullName: string;
  product: TProductServer;
  productCategory: TProductCategoryServer;
  soldByWeight: boolean;
  productQuality: TProductQualityServer;
  imageUrl: string;
  storeProductSellingUnitsJson: string | null;
  productSellingUnits: TProductSellingUnitServer[];
  bagOfProducts: boolean;
  bagOfProductsJson: string | null;
  genericProduct: boolean;
  price: number;
  originalPrice?: number;
  supplier: TSupplier | null;
  supplierDisplayedToClients: boolean;
  promoted: boolean;
  secondaryName: string | null;
  productExtraDetails: string | null;
  currentRelevancy: null | TCurrentRelevancy;
  currentRelevancyFreeText: string | null;
  localizationJson: string | null;
  commentType?: TProductCommentTypeServer;
  promotion?: TPromotionServer['nonObfuscatedId'];
  additionalImages: string | null;
  metadataJson: string | null;
  excludedFromOnlineCatalog: boolean;
  country?: TOriginCountryServer;
};

export type TOriginCountryServer = IHasMultiLangJson & {
  shortIdentifier: string;
};

export type TProductServer = {
  id: TMixedType;
  name: string;
  sortOrder: number;
  primaryQuantityUnit: TSellingUnitServer | null;
  defaultSellingUnit: TSellingUnitServer | null;
  multiLangJson: string;
  limitedByAge: boolean;
  liquid: boolean;
};

export type TPublicCatalogServer = {
  products: TStoreProductServer[];
  catalogLastUpdateTime: number;
};

export type TSupplier = {
  name: string;
};

export type TCurrentRelevancy = {
  name: TCurrentRelevancyName;
  resolvedName: string;
};

export type TCurrentRelevancyName = 'popular' | 'high' | 'medium' | 'low';

export type TProductCategoryServer = TCategoryServer & {
  parent?: TCategoryServer;
};

export type TCategoryServer = {
  id: TMixedType;
  name: string;
  sortOrder: number;
  mediaFilePath: string | null;
  mobileMediaFilePath: string | null;
  metadataJson?: string;
};

export type TProductQualityServer = {
  id: TMixedType;
  name: string;
  displayQuality: boolean;
  imagePath: string;
  multiLangJson: string;
};

export type TProductSellingUnitServer = {
  id: TMixedType;
  sellingUnit: TSellingUnitServer;
  maxAmount: number;
  estimatedUnitWeight: number | null;
};

export type TSellingUnitServer = IHasMultiLangJson & {
  id: TMixedType;
  sortOrder: number;
  amountJumps: number;
  conversionRateToKG?: number;
};

export type TPaymentMethodTypeServer = {
  name: string;
  resolvedName: string;
};

export type TPaymentMethodServer = {
  deferredPaymentAllowed: boolean;
  noPaymentMethodAllowed: boolean;
  maxOrderPaymentsAllowed: number;
  paymentMethods: TCreditCard[];
};

export type TPlaceNewOrEditOrderResponseServer = {
  cartEstimationForAllocation: number;
  paymentResponse: TPaymentResponseServer;
  id: string;
};

export type TPaymentResponseServer = {
  androidPaymentLink: string;
  iosPaymentLink: string;
};

export type TDeferredPaymentTypeServer = {
  name: string;
  resolvedName: string;
};

// TODO - change this and change IStoreProductCartData as well (the mapping between them is unnecessary, we should only map prior to sending to server)
export type TOrderItemDTO = {
  storeProduct: IStoreProduct;
  requestedQuantity: number;
  requestedSellingUnit: TIdDTO;
  productComment?: string;
  productCommentValidationFailed?: boolean;
  receivedBagOfProductsJson?: string;
  selectedBagItems?: TBagOfProductsItemWithMetadata[];
  sourceEvent?: TProductAdditionSource;
  id?: string;
  isRemoved?: boolean;
  updateTime?: number;
  insertTime?: number;
  loadedFrom?: string;
};

export interface IOrderItemThinDTO extends Omit<TOrderItemDTO, 'storeProduct'> {
  storeProduct: { id: TMixedType };
}

export type TPromotionServer = {
  discountType: TDiscountTypeServer;
  evaluationPhase: TEvaluationPhaseServer;
  externalNotes: string;
  name: string;
  nonObfuscatedId: number;
  parameters: string;
  promotionType: TPromotionType;
  shortName: string;
  valueFormula: string;
  valueFormulaType: TValueFormulaTypeServer;
  valueFormulaSourceQuantity: number | null;
  storeProductSource?: TStoreProductSourceServer;
  expirationTime: string;
  appliedIfProductNotInCart?: boolean;
  bannerText?: string;
  estimatedValue?: number;
  creditedAtCheckout?: boolean;
};

export type TClubMembershipProfileServer = {
  company: TMemberShipCompanyServer;
  totalPoints: number;
  userBenefits: TUserBenefit[];
  memberId: number;
  pointsEnabled: boolean;
};

export type TMemberShipCompanyServer = {
  genericCredentials: boolean;
  group: string;
  name: TMemberShipCompanyNameServer;
};

export type TMemberShipCompanyNameServer = 'valueCard';

export type TUserBenefit = {
  description: string;
  id: number;
  title: string;
  expirationDate: number;
};

export type TStoreProductSourceServer = {
  price: number;
  id: number;
  imageUrl: string;
  name: string;
  productSellingUnits: TStoreProductSourceProductSellingUnit[];
  soldByWeight: boolean;
  product: { primaryQuantityUnit?: TSellingUnitServer };
};

export type TStoreProductSourceProductSellingUnit = {
  sellingUnit: { name: string; multiLangJson: string };
};

export type TEvaluationPhaseServer = {
  name: string;
  sortOrder: number;
};

export type TOrderServer = {
  id: string;
  deliveryDate: string;
  insertDate: string;
  deliveryHour: string; // formatted
  deliveryTimeFrameDurationInMinutes: number;
  deferredPaymentType: TDeferredPaymentTypeServer;
  maxOrderUpdateTimeAllowed: number;
  nonObfuscatedId: number;
  open: boolean;
  status: TOrderStatusServer;
  orderType: TOrderTypeServer;
  cartEstimationAfterPreparation: number;
  cartEstimation: number;
  items: TOrderItemServer[];
  address: string;
  comments: string;
  serviceAreaUsageFees: number;
  serviceAreaShortName: string;
  paymentMethodType: TPaymentMethodTypeServer;
  paymentMethod: TCreditCard;
  paymentStatus: TPaymentStatus | null;
  phone: string;
  city: TCityServer;
  subscription: { id: string };
  coupons?: TDiscountForEditOrder[];
  courierTip: number;
  handlingFee: number;
  storeServiceArea: TStoreServiceAreaForEditOrder;
  ecoPackaging: boolean;
  itemsSubstitution?: TItemsSubstitutionServer;
  subCatalog: TSubCatalogServer;
  selectedBagItems?: TBagOfProductsItemWithMetadata[] | string;
};

export type TPaymentStatus = {
  name: string;
  resolvedName: string;
};

export type TItemsSubstitutionServer = {
  name: string;
  resolvedName: string;
};

export type TStoreServiceAreaForEditOrder = {
  orderMinTotalValue: number;
  bagsMinTotalValue: number;
  orderHandlingFee: number;
  orderHandlingFeeThreshold: number;
  name: string;
  id: number;
};

export type TDiscountForEditOrder = {
  evaluationPhase: TEvaluationPhaseServer;
  estimatedValue: number;
  externalNotes: string;
  originatedFromPromotionAtCheckout: boolean;
  originDescription: string;
  valueFormula: string;
  valueFormulaSourceName: string | null;
  valueFormulaSourceId: number;
  valueFormulaType: TValueFormulaTypeServer;
  discountType: TDiscountTypeServer;
  discountSourceType: TDiscountSourceTypeServer;
  dynamicParameters: string;
  promotion: { id: number };
  promotionType: TPromotionType;
  valueFormulaSourceQuantity: number | null;
  storeProductSource?: TStoreProductSourceServer;
  expirationTime: string;
  actualValue: number | null;
  appliedIfProductNotInCart?: boolean;
  creditedAtCheckout?: boolean;
};

export type TDiscountSourceTypeServer = {
  name: 'clientCoupon' | 'storePromotion' | 'storeCoupon' | 'external';
  sortOrder: number;
};

export const clientCouponType: TDiscountSourceTypeServer = {
  name: 'clientCoupon',
  sortOrder: 1,
};

export const storePromotionType: TDiscountSourceTypeServer = {
  name: 'storePromotion',
  sortOrder: 2,
};

export const storeCouponType: TDiscountSourceTypeServer = {
  name: 'storeCoupon',
  sortOrder: 3,
};

export type TOrderItemServer = {
  actualQuantity?: number;
  actualSellingUnitName: string;
  storeProduct?: TStoreProductServer;
  storeProductFullName: string;
  price: number;
  priceForKGOrUnit: number;
  requestedQuantity?: number;
  requestedSellingUnit?: TProductSellingUnitServer;
  requestedSellingUnitName: string;
  estimatedTotalWeightInKGOrUnit?: number;
  productComment?: string;
  bagOfProducts: boolean;
  bagOfProductsJson: string;
  id: string;
  sourceEvent?: TSourceEventServer;
  excludedFromOnlineCatalog: boolean;
  selectedBagItems?: TBagOfProductsItemWithMetadata[] | string;
};

export type TOrderMembershipBenefits = {
  budgetToUse: number;
  requestedBenefits: TRequestedBenefitsServer[];
};

export type TRequestedBenefitsServer = {
  id: number;
  title: string;
};

export type TSourceEventServer = {
  name: TProductAdditionSource;
  resolvedName: TProductAdditionSource;
};

export type TCouponServer = {
  discountType: TDiscountTypeServer;
  evaluationPhase: TEvaluationPhaseServer;
  name?: string;
  externalNotes: string;
  valueFormula: string;
  valueFormulaType: TValueFormulaTypeServer;
  valueFormulaSourceName: string | null;
  valueFormulaSourceQuantity: number | null;
  expirationTime?: Date;
  storeProductSource?: TStoreProductSourceServer;
  appliedIfProductNotInCart?: boolean;
  estimatedValue?: number;
  creditedAtCheckout?: boolean;
};

export type TDiscountTypeServer = {
  id: TMixedType;
  name:
    | 'itemRefund'
    | 'generalDiscount'
    | 'serviceAreaUsageFeesDiscount'
    | 'itemsPromotionDiscount'
    | 'itemForFree';
  resolvedName: string;
  sortOrder: number;
};

export type TValueFormulaTypeServer = {
  id: TMixedType;
  sign: string;
  sortOrder: number;
};

export type TPromotionType = {
  name: string;
  relevantDiscountTypes?: TDiscountTypeServer[];
  resolvedName?: string;
};

export type TPrepareToPlaceOrderServer = {
  bagsMinTotalValue: number;
  clientServiceFee: number;
  encryptedOrderToken: string;
  maxOrderUpdateTimeAllowed?: number; // TODO use?
  orderMinTotalValue: number;
  promotionalText?: string;
  serviceAreaUsageFees: number;
  expirationTime: number;
  orderHandlingFeeThreshold: number;
  orderHandlingFee: number;
};

export type TPrepareToPlaceSubscriptionServer = {
  bagsMinTotalValue: number;
  clientServiceFee: number;
  encryptedSubscriptionToken: string;
  orderMinTotalValue: number;
  serviceAreaUsageFees: number;
  orderHandlingFeeThreshold: number;
  orderHandlingFee: number;
};

export type TPromotionParameters = {
  orderType?: string;
  requiredQuantity?: number;
  participatingProducts?: TIdDTO[];
  newTotalPrice?: number;
  minQuantity?: number;
  maxQuantity?: number;
  participatingProduct?: TIdDTO;
  newPriceForKG?: number;
  minTotalValueBeforePreparation?: number;
  minTotalValueAfterPreparation?: number;
  relevantWeekdays?: TRelevantWeekdayServer[];
};

export type TRelevantWeekdayServer = {
  id: 1 | 2 | 3 | 4 | 5 | 6 | 7;
};

export type TClientServer = {
  firstName: string;
  lastName: string;
  email: string;
  cellPhone: string;
  apartment: string;
  floor: string;
  doorCode: string;
  fullAddress: string;
  addressNotes: string;
  entrance: string;
  address: {
    city: TCityServer;
    address: string;
    cityAndCountry: string;
    placeId?: string;
    coordinates?: ILatLng;
    addressStatus?: TAddressStatusServer;
    addressType?: {
      name: TAddressTypeServer;
    };
  };
  emailVerified: boolean;
  unauthorizedPendingEmail: string;
};

export type TDayOfWeekServer = {
  id: number;
  name: string;
  resolvedName: string;
};

export type TTimeFrameServer = {
  date: string;
  duration: number;
  openHour: number;
  dayOfWeek: TDayOfWeekServer;
};

export type TServiceAreaServer = {
  id: number;
  matchesClientCity: boolean;
  name: string;
  usageFees: number;
  clientDistanceInKM: number;
  coordinatesLatLng: ILatLng;
};

export type TAvailableDeliveryAreaServer = {
  availableHours: TTimeFrameServer[];
  serviceArea: TServiceAreaServer;
};

export type TAvailableSelfPickupAreasServer = {
  availableHours: TTimeFrameServer[];
  serviceArea: TServiceAreaServer;
}[];

export type TOrderStatusServer = {
  id: TMixedType;
  name: string;
  resolvedName: string;
  sortOrder: number;
};

export type TOrderTypeServer = {
  defaultTimeFrameDurationInMinutes: number;
  id: number;
  name: 'delivery' | 'selfPickup';
  resolvedName: string;
  resolvedShortName: string;
};

export type TCityServer = {
  id: number;
  name: string;
  location: ILatLng;
  governmentPlaceId?: number;
  settlementType: {
    name: TSettlementTypeName;
  };
};

export type TSettlementTypeName = 'city' | 'militaryBase';

export type TSubscriptionServer = {
  expectedNextOrderTime: string;
  preferredDuration: string;
  insertDate: string;
  frequency: TNameAndResolvedName;
  id: string;
  orderType: TOrderTypeServer;
  deferredPaymentType: TDeferredPaymentTypeServer;
  paymentMethodType: TPaymentMethodTypeServer;
  paymentMethod: TCreditCard;
  preferredDay: number;
  preferredHour: number;
  preferredTimeFrameDurationInMinutes: number;
  status: TNameAndResolvedName;
  items: TOrderItemServer[];
  expectedNextOrderDate: string;
  expectedNextOrderTimeFrame: string;
  localedPreferredDay: TDayOfWeekServer;
  lastOrderDate: string;
  lastOrderTimeFrame: string;
  comments: string;
  storeServiceArea: TSubscriptionServiceArea;
  ecoPackaging: boolean;
  itemsSubstitution?: TItemsSubstitutionServer;
  subCatalog: TSubCatalogServer;
};

export type TSubscriptionServiceArea = TStoreServiceAreaForEditOrder & {
  id: number;
  name: string;
  usageFees: number;
};

export type TCreditCard = {
  fourLastDigits: string | null;
  id: string;
  expirationMonth: number;
  expirationYear: number;
  creditCard: {
    name: string;
    brand: TNameAndResolvedName;
    id: number;
    representative: TNameAndResolvedName;
  } | null;
};

export type TNameAndResolvedName = {
  name: string;
  resolvedName: string;
  id?: number;
};

export type TProductCommentTypeServer = {
  id: TMixedType;
  name: string;
  comments: TProductCommentServer[];
};

export type TProductCommentServer = TIdNameDTO;

export type TWebsiteDetailsServer = {
  store: {
    id: number;
    name: string;
    businessFullAddressWithCity: string;
    worksWithStoreCoupons: boolean;
    performSellingUnitsEstimationLearning: boolean;
    businessNumber: string;
    businessName: string;
    addressCoordinates: string;
    hasOnlineBranches: boolean;
  };
  subCatalog?: TSubCatalogServer;
  name: string;
  domain: string;
  businessAddressFormatted?: string;
  businessEmail?: string;
  businessPhone?: string;
  jsonWebEncryption: string;
  logoPath: string;
  coverDesktopImagePath: string;
  coverMobileImagePath: string;
  genericProductsDefaultImagePath: string;
  mainCategoryMobileLogoPath: string;
  facebookLink: string;
  whatsappLink: string;
  instagramLink: string;
  youtubeLink: string;
  descriptionForCustomers: string;
  promotionalText: string;
  externalWebTrackingAccounts: string;
  themeJson: string;
  banners: TStoreWebsiteBanner[];
  rexailAffiliateId?: string;
  metadataJson: string;
  captchaType?: { clientApiKey: string; name: string };
  contentPages: TContentPageServer[];
  settingsJson: string;
  favoriteIconPath: string;
  updateDate: number;
};

export type TStoreWebsiteBanner = {
  category?: TIdNameDTO;
  staticCategory?: TNameAndResolvedName;
  bannersJson: string;
};

export type TServerAvailableAuthenticationMethod = {
  email: string;
  methods: TAuthMethodType[];
  newEmailAllowed: boolean;
};

export type TStoreServiceAreasServer = {
  deliveryAreas: TStoreDeliveryAreaServer[];
  selfPickupAreas: TStoreSelfPickupAreaServer[];
};

export type TStoreSelfPickupAreaServer = {
  primary: boolean;
  coordinatesLatLng: ILatLng;
  serviceAreaHoursJson: string;
  name: string;
  id: number;
};

export type TStoreDeliveryAreaServer = {
  city: {
    name: string;
    coordinatesLatLng: ILatLng;
  };
};

export type TServiceAreaHoursServer = {
  weekday: number;
  openHour: number;
  duration: number;
};

export type TContentPageServer = {
  id: string;
  nonObfuscatedId: number;
  name: string;
  published: boolean;
  type: TContentPageType;
  content: string;
};

export type TCompositeComponentServer = {
  type: string;
  displayName: string;
  atomicComponents: TAtomicComponentServer[];
};

export type TAtomicComponentServer = {
  type: string;
  label: string;
  desktop?: boolean;
  value:
    | string
    | TCustomizedImageSliderServer[]
    | TCustomizedFAQServer[]
    | THomePageSelectServer[]
    | THomePageCategoryServer[]
    | THomePageTestimonial[]
    | THomePageContentCard[];
  url?: string;
  altText?: string;
};

export type THomePageCategoryServer = {
  value: TCustomizedImageSliderServer[];
  type: string;
  category: THomePageCategory;
};

export type THomePageTestimonial = {
  bodyText: string;
  city: string;
  details: string;
};

export type THomePageCategory = { name: string; nonObfuscatedId: number };

export type THomePageContentCard = {
  description: string;
  linkLabel?: string;
  linkUrl?: string;
  title: string;
  value: TCustomizedImageServer;
};

export type TCustomizedFAQServer = {
  question: string;
  answer: string;
};

export type TCustomizedImageSliderServer = {
  url: string;
  altText: string;
};

export type TCustomizedImageServer = {
  value: string;
  altText: string;
};

export type THomePageSelectServer = {
  display: string;
  trackBy: TMixedType;
  value: TMixedType;
};

export type TClientDetailsOnStoreLevelServer = {
  id: string;
  receivesCommercialMessages: boolean;
  ordersCount?: number;
  subCatalog?: TSubCatalogServer;
  deliveryArea?: TClientDeliveryAreaServer;
  externalMembership?: boolean;
};

export type TClientDeliveryAreaServer = {
  onlineActivityBranch?: {
    subCatalog: TSubCatalogServer;
  };
};

export type TSubCatalogServer = {
  id: string;
};

export type TAddressStatusServer = {
  name: TAddressStatus;
};

export type TContentPageType = 'about' | 'customized' | 'home';

export type TPaymentMethodType = 'deferredPayment' | 'creditCard' | 'absent' | 'cash' | 'bit';

export type TAuthMethodType = 'SMS' | 'EMAIL' | 'VOICE';

export type TAddressTypeServer = 'apartmentBuilding' | 'privateHouse' | 'office' | 'militaryBase';

export type TAddressStatus =
  | 'wellDefined'
  | 'foundPartialMatch'
  | 'incorrectStreetNumber'
  | 'incorrectStreet'
  | 'pinpointedByClient';

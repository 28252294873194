import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { TStylesLoginDialog } from './types';

export const dialogDownBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLoginDialog> =>
    createStyles({
      root: {
        padding: theme.spacing(30 / 8, 2.5, 0),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(40 / 8, 70 / 8, 70 / 8),
        },
      },
      logo: {
        width: '130px',
        height: 'auto',
        margin: '0 auto',
      },
      title: {
        marginTop: theme.spacing(20 / 8),
      },
      titleMain: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(10 / 8),
        },
      },
      subTitle: {
        letterSpacing: -0.2,
      },
      input: {
        marginTop: theme.spacing(22 / 8),
      },
      rootButton: {
        marginTop: theme.spacing(2.75), // 22px
      },
      footer: {
        marginTop: theme.spacing(2.5), // 20px
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(2.75), // 22px
        },
      },
      termsOfService: {
        whiteSpace: 'nowrap',
      },
      termsOfServiceLink: {
        whiteSpace: 'nowrap',
      },
      confirmTerms: {
        width: '300px',
      },
      linkText: {
        whiteSpace: 'nowrap',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
    }),
);

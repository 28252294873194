import React, { FC, useCallback } from 'react';
import { format } from 'date-fns';

import { Box, CardMedia } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';
import DatePicker from 'components/common/DatePicker';

import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { SubscriptionService } from 'api/services/SubscriptionService';
import { useMobile, useWebsiteDetails } from 'hooks';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import useStyles from './styles';
import { ISuspendSubscription } from './types';

const SuspendSubscription: FC<ISuspendSubscription> = ({
  title,
  body,
  subscriptionId,
  onClose,
}) => {
  const { isMobile } = useMobile();

  const lang = useSelector(getLang);

  const router = useRouter();
  const websiteDetails = useWebsiteDetails();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [disabled, setDisabled] = React.useState<boolean>(true);

  const { t } = useTranslation();

  const classes = useStyles({ lang });

  const handleDateChange = useCallback((date: MaterialUiPickersDate) => {
    setSelectedDate(date);

    if (date) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, []);

  const suspendSubscription = useCallback(() => {
    if (selectedDate) {
      const suspendUntil = format(selectedDate, 'dd/MM/yyyy');
      SubscriptionService.suspendSubscription(subscriptionId, suspendUntil)
        .then(() => router.push('/account/subscriptions/manage'))
        .finally(onClose);
    }
  }, [selectedDate, onClose, subscriptionId, router]);

  const suspendSubscriptionAndCancelOrder = useCallback(() => {
    if (selectedDate) {
      const suspendUntil = format(selectedDate, 'dd/MM/yyyy');
      Promise.all([
        SubscriptionService.suspendSubscription(subscriptionId, suspendUntil),
        SubscriptionService.cancelOrderCreatedBySubscription(subscriptionId),
      ]).then(() => {
        router.push('/account/subscriptions/manage');
        onClose();
      });
    }
  }, [selectedDate, onClose, subscriptionId, router]);

  return (
    <Box className={classes.root} px={'12px'}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={websiteDetails.theme.images.warning}
          component="img"
          className={classes.image}
          alt={title}
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h1" color="secondary" align="center" className={classes.titleMain}>
          {title}
        </Typography>
        <Box mb={isMobile ? 10 / 8 : 12 / 8} mt={10 / 8}>
          <Typography variant="body1" fontSize={16} color="grey" align="center">
            {body}
          </Typography>
        </Box>
      </Box>
      <Box>
        <DatePicker
          onChange={handleDateChange}
          value={selectedDate}
          minDate={new Date()}
          minDateMessage={t('subscription.dateIsInThePast')}
          invalidDateMessage={t('subscription.invalidDate')}
        />
      </Box>
      <Box>
        <CTAButton
          className={classes.rootButton}
          size={isMobile ? 'medium' : 'large'}
          disabled={disabled}
          onClick={suspendSubscriptionAndCancelOrder}
        >
          <Typography fontWeight="medium" fontSize={isMobile ? 16 : 18}>
            {'dialog.suspendSubscription.suspendAndCancelOrder'}
          </Typography>
        </CTAButton>
        <CTAButton
          className={classes.rootButtonOutlined}
          disabled={disabled}
          variant="outlined"
          size={isMobile ? 'medium' : 'large'}
          onClick={suspendSubscription}
        >
          {'dialog.suspendSubscription.justSuspend'}
        </CTAButton>
        <CTAButton
          className={classes.rootButtonOutlined}
          onClick={onClose}
          variant="outlined"
          size={isMobile ? 'medium' : 'large'}
        >
          {'dialog.suspendSubscription.cancel'}
        </CTAButton>
      </Box>
    </Box>
  );
};

export default SuspendSubscription;

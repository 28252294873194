const ORDER_DISCOUNTS_RECALCULATE = 'orderDiscounts/ORDER_DISCOUNTS_RECALCULATE';
const ORDER_DISCOUNTS_UPDATE = 'orderDiscounts/ORDER_DISCOUNTS_UPDATE';
const CLIENT_COUPONS_UPDATE = 'orderDiscounts/CLIENT_COUPONS_UPDATE';
const STORE_COUPON_UPDATE = 'orderDiscounts/STORE_COUPON_UPDATE';
const UPDATE_STATIC_PROMOTIONS = 'orderDiscounts/UPDATE_STATIC_PROMOTIONS';
const UPDATE_MEMBERSHIP_BENEFITS = 'orderDiscounts/UPDATE_MEMBERSHIP_BENEFITS';
const GET_STATIC_PROMOTIONS = 'orderDiscounts/GET_STATIC_PROMOTIONS';

export {
  ORDER_DISCOUNTS_RECALCULATE,
  ORDER_DISCOUNTS_UPDATE,
  CLIENT_COUPONS_UPDATE,
  STORE_COUPON_UPDATE,
  GET_STATIC_PROMOTIONS,
  UPDATE_STATIC_PROMOTIONS,
  UPDATE_MEMBERSHIP_BENEFITS,
};

const UPDATE_PROFILE = 'user/UPDATE_PROFILE';
const UPDATE_FULL_PROFILE_DATA_REQUEST = 'user/UPDATE_FULL_PROFILE_DATA_REQUEST';
const UPDATE_MINIMUM_PROFILE_DATA_REQUEST = 'user/UPDATE_MINIMUM_PROFILE_DATA_REQUEST';
const UPDATE_DETAILS_AT_STORE_LEVEL = 'user/UPDATE_DETAILS_AT_STORE_LEVEL';
const UPDATE_USER_SUB_CATALOG = 'user/UPDATE_USER_SUB_CATALOG';
const UPDATE_DELIVERY_AREA_SUB_CATALOG = 'user/UPDATE_DELIVERY_AREA_SUB_CATALOG';
const CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED =
  'user/CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED';
const FETCH_AND_UPDATE_CLIENT_COMPENSATIONS_REQUEST =
  'user/FETCH_AND_UPDATE_CLIENT_COMPENSATIONS_REQUEST';
const FETCH_AND_UPDATE_CLIENT_AND_AREA_SUB_CATALOG =
  'user/FETCH_AND_UPDATE_CLIENT_AND_AREA_SUB_CATALOG';
const UPDATE_EXTERNAL_MEMBERSHIP = 'user/UPDATE_EXTERNAL_MEMBERSHIP';
const FETCH_AND_UPDATE_CLUB_MEMBERSHIP_PROFILE = 'user/FETCH_AND_UPDATE_CLUB_MEMBERSHIP_PROFILE';
const FETCH_AND_UPDATE_CLIENT_DETAILS_AT_STORE_LEVEL =
  'user/FETCH_AND_UPDATE_CLIENT_DETAILS_AT_STORE_LEVEL';

export {
  UPDATE_PROFILE,
  UPDATE_FULL_PROFILE_DATA_REQUEST,
  UPDATE_DETAILS_AT_STORE_LEVEL,
  UPDATE_MINIMUM_PROFILE_DATA_REQUEST,
  FETCH_AND_UPDATE_CLIENT_COMPENSATIONS_REQUEST,
  UPDATE_USER_SUB_CATALOG,
  UPDATE_DELIVERY_AREA_SUB_CATALOG,
  FETCH_AND_UPDATE_CLIENT_AND_AREA_SUB_CATALOG,
  CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED,
  FETCH_AND_UPDATE_CLUB_MEMBERSHIP_PROFILE,
  UPDATE_EXTERNAL_MEMBERSHIP,
  FETCH_AND_UPDATE_CLIENT_DETAILS_AT_STORE_LEVEL,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback } from 'react';

import { useHelperFieldText } from 'hooks';

import Autocomplete, { IAutocompleteProps } from 'ui/common/inputs/Autocomplete';

import { TAutocompleteFieldField } from './types';

const AutocompleteField: FC<TAutocompleteFieldField> = ({
  field: { onBlur, value, name },
  form: { touched, errors, setFieldValue },
  inputProps,
  ...rest
}) => {
  const handleChange = useCallback<Required<IAutocompleteProps<any, false>>['onChange']>(
    (_, newValue) => {
      setFieldValue(name, newValue);
    },
    [setFieldValue, name],
  );

  const renderErrorText = useHelperFieldText();
  const isShowError = touched[name] && !!errors[name];

  return (
    <Autocomplete<any, false>
      error={isShowError}
      helperText={isShowError ? renderErrorText(errors[name]) : ''}
      inputProps={{
        onBlur,
        name,
        isShowLabel: true,
        ...inputProps,
      }}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};
export default AutocompleteField;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { MAX_CONTAINER_WIDTH, MIN_TABLET_WIDTH } from 'ui/common/Container';

import { TStylesDeliverySection } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDeliverySection> =>
    createStyles({
      deliverySection: {
        display: 'flex',
      },
      basketSection: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH)]: {
          marginRight: theme.spacing(1), // 8px
        },
      },
      buttonRoot: {
        // reset default button width
        minWidth: 0,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          boxShadow: 'none',
        },
        [theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH)]: {
          marginRight: theme.spacing(12 / 8),
        },
      },
      iconBtn: {
        fontSize: theme.spacing(3),
      },
    }),
);

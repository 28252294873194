import { ItemsPromotionDialog } from 'components/common/Dialog/components/ItemsPromotionDialog';
import { MemberPromotionDialog } from 'components/common/Dialog/components/MemberPromotionDialog';
import OrderEditingIsNotPossibleDialog from 'components/common/Dialog/components/OrderEditingIsNotPossibleDialog';
import React, { CSSProperties, FC, ReactNode, useCallback, useMemo } from 'react';
import { PrivacyPolicyDialog } from 'components/common/Dialog/components/PrivacyPolicyDialog';

import {
  Box,
  ButtonProps,
  DialogContent,
  DialogProps,
  IconButton,
  Dialog as MUIDialog,
  useTheme,
} from '@material-ui/core';
import classNames from 'classnames';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';

import {
  ACCESSIBILITY_DIALOG,
  ACTIVE_SUBSCRIPTION_DIALOG,
  authSelectors,
  BASKET_DIALOG,
  CART_WAS_SHARED_DIALOG,
  DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG,
  DELIVERING_TO_ADDRESS_DIALOG,
  DELIVERY_DIALOG,
  EMAIL_VERIFICATION_DIALOG,
  EXISTING_ORDER_DIALOG,
  GENERIC_DIALOG,
  ITEMS_PROMOTION_DIALOG,
  LOAD_PREVIOUS_ORDERS_DIALOG,
  LOCATION_DIALOG,
  LOCATION_HOURS_DIALOG,
  LOGIN_CODE_DIALOG,
  LOGIN_DIALOG,
  MEMBER_PROMOTION_DISCOUNT_DIALOG,
  ORDER_EDITING_IS_NOT_POSSIBLE_DIALOG,
  PAYMENT_BY_BIT_WAS_DECLINED_DIALOG,
  PREVIOUS_ORDER_ITEMS_DIALOG,
  PRIVACY_POLICY_DIALOG,
  PRODUCT_DETAILS_DIALOG,
  SELF_PICKUP_DIALOG,
  SHARE_PRODUCT_CART_DIALOG,
  SIGN_UP_DIALOG,
  STORE_DELIVERY_HOURS_DIALOG,
  STORE_OPEN_HOURS_DIALOG,
  SUSPEND_SUBSCRIPTION_DIALOG,
  TERMS_OF_USE_DIALOG,
  WELCOME_DIALOG,
} from 'store';

import { useDialog, useMobile } from 'hooks';
import { useTranslation } from 'react-i18next';

import GenericDialog from 'components/common/Dialog/components/GenericDialog';
import SuspendSubscription from 'components/common/Dialog/components/SuspendSubscriptionDialog';
import { ProductDetailsDialog } from 'components/common/Dialog/components/ProductDetailsDialog';
import { TermsOfUseDialog } from 'components/common/Dialog/components/TermsOfUseDialog';
import { AccessibilityDialog } from 'components/common/Dialog/components/AccessibilityDialog';
import { WelcomeDialog } from 'components/common/Dialog/components/WelcomeDialog';
import { ExistingOrderDialog } from 'components/common/Dialog/components/ExistingOrderDialog';
import { ActiveSubscriptionDialog } from 'components/common/Dialog/components/ActiveSubscriptionDialog';
import { EmailVerificationDialog } from 'components/common/Dialog/components/EmailVerificationDialog';
import { DeliveringToAddressDialog } from 'components/common/Dialog/components/DeliveringToAddressDialog';
import { StoreOpenHoursDialog } from 'components/common/Dialog/components/StoreOpenHoursDialog';
import { ShareProductCartDialog } from 'components/common/Dialog/components/ShareProductCartDialog';
import { CartWasSharedDialog } from 'components/common/Dialog/components/CartWasSharedDialog';
import { DeliveringToAddressAboutPageDialog } from 'components/common/Dialog/components/DeliveringToAddressAboutPageDialog';
import PaymentByBitWasDeclined from 'components/common/Dialog/components/PaymentByBitWasDeclinedDialog';

import { useDispatch, useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import {
  BasketDialog,
  DeliveryDialog,
  LoadPreviousOrdersDialog,
  LocationDialog,
  LocationHoursDialog,
  LoginCodeDialog,
  LoginDialog,
  PreviousOrdersItemsDialog,
  SelfPickupDialog,
  SignUpDialog,
  StoreDeliveryHoursDialog,
} from './components';

import useStyles from './styles';

import { IDialog, TBaseDialogContentProps, TDefaultStyles } from './types';

const Dialog: FC<IDialog> = () => {
  const {
    open,
    hideDialog,
    children,
    dialogProps: { onClose, backdropClick, hideCloseButton, ...rest },
    dialogType,
    contentProps,
  } = useDialog();

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useMobile();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const lang = useSelector(getLang);

  // need for different styles of dialog
  const getDialogStyles = useMemo<TDefaultStyles>(() => {
    let defaultStyles: TDefaultStyles = {
      maxWidth: isMobile ? 'calc(100vw - 50px) !important' : 55,
      borderRadius: isMobile ? 0.75 : 1,
      maxHeight: 'auto',
    };

    switch (dialogType) {
      case LOGIN_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : 440 / 8,
          borderRadius: isMobile ? 0 : 1,
        };
        break;
      case SHARE_PRODUCT_CART_DIALOG:
      case CART_WAS_SHARED_DIALOG:
        defaultStyles = {
          maxWidth: isMobile ? 332 / 8 : 400 / 8,
          borderRadius: 1,
        };
        break;
      case LOGIN_CODE_DIALOG:
      case EMAIL_VERIFICATION_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : 55,
          borderRadius: isMobile ? 0 : 1,
        };
        break;
      case DELIVERY_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : '590px',
          borderRadius: isMobile ? 0 : 10 / 8,
        };
        break;
      case SELF_PICKUP_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : '590px',
          borderRadius: isMobile ? 0 : 10 / 8,
          maxHeight: '86vh',
        };
        break;
      case ITEMS_PROMOTION_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : '600px',
          borderRadius: isMobile ? 0 : 1,
          maxHeight: '86vh',
        };
        break;
      case MEMBER_PROMOTION_DISCOUNT_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : '550px',
          borderRadius: isMobile ? 0 : 1,
          maxHeight: '86vh',
        };
        break;
      case PREVIOUS_ORDER_ITEMS_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : 750 / 8,
          borderRadius: isMobile ? 0 : 1,
        };
        break;
      case SIGN_UP_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : 70,
          borderRadius: isMobile ? 0 : 1,
        };
        break;
      case PRODUCT_DETAILS_DIALOG:
      case LOAD_PREVIOUS_ORDERS_DIALOG:
      case BASKET_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxWidth: isMobile ? '100%' : 100,
          borderRadius: isMobile ? 0 : 1,
        };
        break;
      case TERMS_OF_USE_DIALOG:
      case ACCESSIBILITY_DIALOG:
      case PRIVACY_POLICY_DIALOG:
        defaultStyles = {
          fullScreen: isMobile,
          maxHeight: 750 / 8,
          maxWidth: isMobile ? '100%' : 750 / 8,
          borderRadius: isMobile ? 0 : 1,
        };
        break;
      case STORE_DELIVERY_HOURS_DIALOG:
        defaultStyles = {
          maxWidth: isMobile ? `292px !important` : 360 / 8,
          maxHeight: '86vh',
          borderRadius: 1,
        };
        break;
      case STORE_OPEN_HOURS_DIALOG:
        defaultStyles = {
          maxWidth: isMobile ? `292px !important` : (lang === 'ru' && 350 / 8) || 320 / 8,
          borderRadius: 1,
        };
        break;
      case DELIVERING_TO_ADDRESS_DIALOG:
      case DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG:
        defaultStyles = {
          ...defaultStyles,
          maxHeight: isMobile ? '86svh' : '86vh',
        };
        break;
      default:
        break;
    }
    return defaultStyles;
  }, [isMobile, dialogType, lang]);

  const classes = useStyles(getDialogStyles);

  const handleClose = useCallback(() => {
    hideDialog();
  }, [hideDialog]);

  const dialogOnClose = useCallback(
    (e: object, r?: 'backdropClick' | 'escapeKeyDown') => {
      switch (dialogType) {
        case DELIVERY_DIALOG:
        case SELF_PICKUP_DIALOG:
        case ACTIVE_SUBSCRIPTION_DIALOG:
        case EXISTING_ORDER_DIALOG:
        case SIGN_UP_DIALOG: // TODO test it for weired scenarios when user has address but not email
          if (onClose) {
            return onClose(e, r);
          }
          dispatch(introductoryPopupsCompletedAction(true));
          break;

        case WELCOME_DIALOG:
          if (onClose) {
            return onClose(e, r);
          }
          if (isLoggedIn) {
            dispatch(introductoryPopupsCompletedAction(true));
          }
          break;
        default:
          if (onClose) {
            return onClose(e, r);
          }
      }
    },
    [dialogType, dispatch, isLoggedIn, onClose],
  );

  const handleDialogClose = useCallback<Required<DialogProps>['onClose']>(
    (e, r) => {
      if (r === 'backdropClick' && !backdropClick) {
        handleClose();
        // prevent close outside of dialog
      }

      if (dialogOnClose) {
        dialogOnClose(e, r);
      }
    },
    [backdropClick, dialogOnClose, handleClose],
  );

  const handleIconClose = useCallback<Required<ButtonProps>['onClick']>(
    (e) => {
      handleClose();
      if (dialogOnClose) {
        dialogOnClose(e);
      }
    },
    [handleClose, dialogOnClose],
  );

  const renderContent = useMemo<ReactNode>(() => {
    const baseProps: TBaseDialogContentProps = {
      onClose: handleClose,
      ...contentProps,
    };

    switch (dialogType) {
      case WELCOME_DIALOG:
        return <WelcomeDialog {...baseProps} />;
      case GENERIC_DIALOG:
        return <GenericDialog {...baseProps} />;
      case LOCATION_DIALOG:
        return <LocationDialog {...baseProps} />;
      case LOCATION_HOURS_DIALOG:
        return <LocationHoursDialog {...baseProps} />;
      case LOGIN_DIALOG:
        return <LoginDialog {...baseProps} />;
      case LOGIN_CODE_DIALOG:
        return <LoginCodeDialog {...baseProps} />;
      case SIGN_UP_DIALOG:
        return <SignUpDialog {...baseProps} />;
      case DELIVERY_DIALOG:
        return <DeliveryDialog {...baseProps} />;
      case SELF_PICKUP_DIALOG:
        return <SelfPickupDialog {...baseProps} />;
      case DELIVERING_TO_ADDRESS_DIALOG:
        return <DeliveringToAddressDialog {...baseProps} />;
      case DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG:
        return <DeliveringToAddressAboutPageDialog {...baseProps} />;
      case PREVIOUS_ORDER_ITEMS_DIALOG:
        return <PreviousOrdersItemsDialog {...baseProps} />;
      case SUSPEND_SUBSCRIPTION_DIALOG:
        return <SuspendSubscription {...baseProps} />;
      case PRODUCT_DETAILS_DIALOG:
        return <ProductDetailsDialog {...baseProps} />;
      case TERMS_OF_USE_DIALOG:
        return <TermsOfUseDialog {...baseProps} />;
      case ACCESSIBILITY_DIALOG:
        return <AccessibilityDialog {...baseProps} />;
      case PRIVACY_POLICY_DIALOG:
        return <PrivacyPolicyDialog {...baseProps} />;
      case EXISTING_ORDER_DIALOG:
        return <ExistingOrderDialog {...baseProps} />;
      case ACTIVE_SUBSCRIPTION_DIALOG:
        return <ActiveSubscriptionDialog {...baseProps} />;
      case BASKET_DIALOG:
        return <BasketDialog {...baseProps} />;
      case EMAIL_VERIFICATION_DIALOG:
        return <EmailVerificationDialog {...baseProps} />;
      case STORE_OPEN_HOURS_DIALOG:
        return <StoreOpenHoursDialog {...baseProps} />;
      case STORE_DELIVERY_HOURS_DIALOG:
        return <StoreDeliveryHoursDialog {...baseProps} />;
      case LOAD_PREVIOUS_ORDERS_DIALOG:
        return <LoadPreviousOrdersDialog {...baseProps} />;
      case SHARE_PRODUCT_CART_DIALOG:
        return <ShareProductCartDialog {...baseProps} />;
      case CART_WAS_SHARED_DIALOG:
        return <CartWasSharedDialog {...baseProps} />;
      case ITEMS_PROMOTION_DIALOG:
        return <ItemsPromotionDialog {...baseProps} />;
      case PAYMENT_BY_BIT_WAS_DECLINED_DIALOG:
        return <PaymentByBitWasDeclined {...baseProps} />;
      case ORDER_EDITING_IS_NOT_POSSIBLE_DIALOG:
        return <OrderEditingIsNotPossibleDialog {...baseProps} />;
      case MEMBER_PROMOTION_DISCOUNT_DIALOG:
        return <MemberPromotionDialog {...baseProps} />;
      default:
        return children;
    }
  }, [dialogType, children, contentProps, handleClose]);

  const closeIconClassName = useMemo(() => {
    switch (dialogType) {
      case LOAD_PREVIOUS_ORDERS_DIALOG:
      case PRODUCT_DETAILS_DIALOG:
        return 'whiteCloseIcon';
      default:
        return 'closeIcon';
    }
  }, [dialogType]);

  const closeIcon = useMemo(() => {
    switch (dialogType) {
      case EMAIL_VERIFICATION_DIALOG:
        return 'icon-arrow-right';
      default:
        return 'icon-button-x';
    }
  }, [dialogType]);

  const closeButtonText = useMemo(() => {
    switch (dialogType) {
      case EMAIL_VERIFICATION_DIALOG:
        return (
          <Typography className={classes.customCloseText}>{'dialog.loginCode.back'}</Typography>
        );
      default:
        return '';
    }
  }, [classes.customCloseText, dialogType]);

  const rootContentCustomStyle = useMemo(() => {
    switch (dialogType) {
      case PRODUCT_DETAILS_DIALOG:
      case BASKET_DIALOG:
        return { background: theme.palette.grey[50] };
      case SELF_PICKUP_DIALOG:
        return {
          overflowY: 'unset' as CSSProperties['overflowY'], // was added to make map inside sticky
        };
      default:
        return {};
    }
  }, [dialogType, theme.palette.grey]);

  const closeButtonPosition = useMemo(() => {
    if (dialogType === EMAIL_VERIFICATION_DIALOG && lang !== 'he') return 58;
    return isMobile ? 6 : 18;
  }, [dialogType, isMobile, lang]);

  return (
    <MUIDialog
      disableEscapeKeyDown
      onClose={handleDialogClose}
      classes={{
        paper: classes.root,
      }}
      open={open}
      scroll="body"
      fullScreen={getDialogStyles.fullScreen}
      {...rest}
    >
      <DialogContent classes={{ root: classes.rootContent }} style={rootContentCustomStyle}>
        <>
          <Box
            className={
              dialogType === EMAIL_VERIFICATION_DIALOG
                ? classes.rootHeaderEmailDialog
                : classes.rootHeader
            }
            right={closeButtonPosition}
          >
            {!hideCloseButton && (
              <IconButton
                autoFocus
                aria-label={t('dialog.closeButton')}
                className={
                  dialogType === EMAIL_VERIFICATION_DIALOG
                    ? classes.closeEmailDialog
                    : classes.closeButton
                }
                onClick={handleIconClose}
              >
                <Icon
                  name={closeIcon}
                  classes={{ root: classNames(classes[closeIconClassName], classes.close) }}
                />
                {closeButtonText}
              </IconButton>
            )}
          </Box>
          {renderContent}
        </>
      </DialogContent>
    </MUIDialog>
  );
};

export default Dialog;

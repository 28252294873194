import {
  CORRECT_BRANCH_SUB_CATALOG_WAS_FETCHED,
  SET_CATALOG_ID,
  SET_CATALOG_LAST_FETCH_TIME,
  SET_CATALOG_SSR_LAST_UPDATE_TIME,
} from 'store/modules/catalog/constants';
import { initialCatalogState } from './initialState';
import { TCatalogReducer } from './types';

const catalogReducer: TCatalogReducer = (state = initialCatalogState, action) => {
  switch (action.type) {
    case SET_CATALOG_SSR_LAST_UPDATE_TIME:
    case SET_CATALOG_ID:
    case CORRECT_BRANCH_SUB_CATALOG_WAS_FETCHED:
    case SET_CATALOG_LAST_FETCH_TIME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { catalogReducer };

import { TOrderItemServer, TStoreProductServer } from 'types';
import { IMultiLanguage } from 'store';

export const prepareMultiLangForRequestedSellingUnitName = (
  item: TOrderItemServer,
  showActualQuantity: boolean,
): IMultiLanguage => {
  if (showActualQuantity) {
    if (item.actualSellingUnitName) {
      return {
        he: {
          name: item.actualSellingUnitName,
        },
      };
    }
  }

  const relevantSellingUnit = item.storeProduct?.productSellingUnits.find(
    (sellingUnit) => sellingUnit.id === item.requestedSellingUnit?.id,
  );

  return {
    he: {
      name: item.requestedSellingUnitName,
    },
    ...(relevantSellingUnit && JSON.parse(relevantSellingUnit.sellingUnit.multiLangJson || '{}')),
  };
};

export const isStoreProductOutOfStock = (storeProduct: TStoreProductServer): boolean => {
  return !storeProduct.active || storeProduct.excludedFromOnlineCatalog;
};

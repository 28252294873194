import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUsePoweredBy, TUsePoweredBy } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUsePoweredBy, TUsePoweredBy> =>
    createStyles({
      poweredSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      poweredLogo: {
        maxWidth: theme.spacing(17.5), // 140px
      },
      mobile: {
        marginTop: theme.spacing(2.75), // 22px
      },
    }),
);

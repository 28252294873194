import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUseHeaderBackButton } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseHeaderBackButton> =>
    createStyles({
      backButton: {
        display: 'flex',
        alignItems: 'center',
      },
      backIcon: {
        fontSize: theme.spacing(10 / 8),
        marginRight: theme.spacing(0.5),
      },
    }),
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { addDays, format, isBefore, parse, setDay } from 'date-fns';
import { findIndex, isEqual, reduce } from 'lodash';

import { TAvailableArea } from 'hooks';

import { TDayOfWeekServer, TTimeFrameServer } from 'types';
import { defaultDateFormat } from 'utils/constants';

// TODO when we fix server to get dayOfWeek directly from request in subsriptions we can remove this
export const calculateAndFormatArtificialDate = (dayOfWeek: TDayOfWeekServer): string => {
  let artificalDate = setDay(new Date(), dayOfWeek.id);
  if (isBefore(artificalDate, new Date())) {
    artificalDate = addDays(artificalDate, 7);
  }

  return format(artificalDate, defaultDateFormat);
};

// TODO rewrite (and ditch the mapping to flat object)
export const getAvailableAreaHours = (
  availableHours: TTimeFrameServer[],
  generateArtificialDateIfDateFromServerIsAbsent: boolean,
): TAvailableArea[] => {
  const availableAreaHours: TAvailableArea[] = [];

  availableHours.forEach(({ date, openHour, duration, dayOfWeek }, index) => {
    let existingDayIndex;
    if (date) {
      existingDayIndex = findIndex(availableAreaHours, ['date', date]);
    } else {
      existingDayIndex = findIndex(availableAreaHours, ['dayOfWeek.id', dayOfWeek.id]);
    }

    if (existingDayIndex !== -1) {
      availableAreaHours[existingDayIndex].availableHours.push({
        id: availableAreaHours[existingDayIndex].availableHours.length + 1,
        openHour,
        duration,
      });
    } else {
      availableAreaHours.push({
        id: index + 1,
        dayOfWeek,
        date:
          date ||
          (generateArtificialDateIfDateFromServerIsAbsent
            ? calculateAndFormatArtificialDate(dayOfWeek)
            : date),
        availableHours: [
          {
            id: 1,
            openHour,
            duration,
          },
        ],
      });
    }
  });

  return availableAreaHours.sort(
    (a, b) =>
      parse(a.date, defaultDateFormat, new Date()).getTime() -
      parse(b.date, defaultDateFormat, new Date()).getTime(),
  );
};

export const compareObjects = (a: Record<any, any>, b: Record<any, any>): string[] => {
  return reduce<Record<any, any>, string[]>(
    a,
    (result, value, key) => {
      return isEqual(value, b[key]) ? result : result.concat(key);
    },
    [],
  );
};

export const convertNumberToWholeOrTwoDecimal = (numberToConvert: string): number => {
  const number = parseFloat(numberToConvert);

  if (number % 1 === 0) {
    return number; // 10.00 --> 10
  }

  return +number.toFixed(2);
};

import {
  Box,
  Button,
  CardMedia,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  useTheme,
} from '@material-ui/core';

import classNames from 'classnames';
import PWADrawerButton from 'components/mobile/PWADrawerButton';

import { useDialog, useHiLoginText } from 'hooks';
import { useContentPages } from 'hooks/useContentPages';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import usePWAInstallerPrompt from 'hooks/usePWAInstallerPrompt';
import i18next from 'i18next';
import { useRouter } from 'next/router';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors, configActions, MEMBER_PROMOTION_DISCOUNT_DIALOG } from 'store';
import { getIsPWAInstalled } from 'store/modules/config/selectors';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import {
  getOrderDiscounts,
  getOrderMembershipBenefits,
} from 'store/modules/orderDiscounts/selectors';
import { promotionsData } from 'store/modules/promotions/selectors';
import ClientCouponNotice from 'ui/common/ClientCouponNotice';
import Icon from 'ui/common/icons/Icon';

import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';

import { TCategoryMenuItem } from 'ui/desktop/CategoryMenu';
import { Languages } from 'ui/desktop/LanguageSwitcher';

// user menu items
import { defaultLogOutMenuItem } from 'ui/desktop/UserMenu';
import useStyles from './styles';

import { IMenuDrawer, THandleClickMenuDrawerItem, TMenuUserItem } from './types';

const MenuDrawer: FC<IMenuDrawer> = ({
  isLoggedIn,
  onLogOut,
  onLogin,
  isHideLogOut,
  menuUserItems = [],
  websiteDetails,
  categoryItems,
  activeCategoryItem,
  name = '',
  lastName = '',
  loginText = 'userMenu.signUpLoginText',
  hiText = 'userMenu.hiText',
  onLoadPreviousOrder,
  showLanguageSwitcher = false,
}) => {
  const classes = useStyles();
  const { showDialog } = useDialog();

  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const renderHiLoginText = useHiLoginText();

  const router = useRouter();

  const { createContentPagesList } = useContentPages();

  const orderDiscounts = useSelector(getOrderDiscounts);

  const orderMode = useSelector(getOrderMode);

  const orderMembershipBenefits = useSelector(getOrderMembershipBenefits);

  const { clubMembershipProfile } = useSelector(promotionsData);

  const showMembershipSection = useMemo(() => {
    return !!clubMembershipProfile.length && orderMode === 'new';
  }, [clubMembershipProfile.length, orderMode]);

  const clientCouponsOrderDiscounts = useMemo(() => {
    return orderDiscounts.filter((discount) => discount.discountSourceType.name === 'clientCoupon');
  }, [orderDiscounts]);

  const onClientBehalf = useSelector(authSelectors.onClientBehalf);

  // hide/open drawer
  const [open, setOpen] = useState<boolean>(false);
  // hide/open categories list
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { handleOnInstall } = usePWAInstallerPrompt();
  const isPWAInstalled = useSelector(getIsPWAInstalled);

  const [expandedCategoryId, setExpandedCategoryId] = useState<null | number>(null);

  const [currentLanguage, setCurrentLanguage] = useState(Languages[0]); // he

  const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);

  const [openUserMenuItems, setOpenUserMenuItems] = useState<boolean>(true);

  const { relevantLanguages } = useMultiLanguage();

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
    setIsExpanded(false);
    setExpandedCategoryId(null);
  }, []);

  const handleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleClickMenuDrawerItem = useCallback<THandleClickMenuDrawerItem>(
    (cb) => (e) => {
      // run cb if we have
      if (cb) {
        cb(e);
      }
      // close menu
      handleDrawerClose();
    },
    [handleDrawerClose],
  );

  const openSubMenu = useCallback(
    (categoryId) => {
      if (categoryId === expandedCategoryId) {
        // close menu
        setExpandedCategoryId(null);
        return;
      }

      setExpandedCategoryId(categoryId);
    },
    [expandedCategoryId],
  );

  const handleLanguageMenu = useCallback(() => {
    setOpenLanguageMenu(!openLanguageMenu);
  }, [openLanguageMenu]);

  const languageWasChanged = useCallback(
    (selectedLanguage) => {
      setCurrentLanguage(selectedLanguage);
      setOpenLanguageMenu(false);
      setOpen(false);

      dispatch(configActions.updateLanguageConfigAction(selectedLanguage.shortName));
    },
    [dispatch],
  );

  // TODO use something from translations
  const renderTopText = useMemo<string>(() => {
    return renderHiLoginText({
      isLoggedIn,
      name: name || '',
      loginText,
      hiText,
    });
  }, [isLoggedIn, name, loginText, hiText, renderHiLoginText]);

  // array of user menu items
  const renderMenuItemsArr = useMemo<TMenuUserItem[]>(() => {
    const newMenuItems: TMenuUserItem[] = [...menuUserItems];
    // add logout item if isHideLogOut = false
    if (!isHideLogOut) {
      const logOutMenuItem: TMenuUserItem = {
        ...defaultLogOutMenuItem,
        // check last index item id and add 1
        // need for keys render in map
        id: menuUserItems[menuUserItems.length - 1].id + 1,
      };
      // add logout cb if we have onLogOut prop
      if (onLogOut) {
        logOutMenuItem.onClick = () => onLogOut();
      }

      newMenuItems.push(logOutMenuItem);
    }

    return newMenuItems;
  }, [menuUserItems, isHideLogOut, onLogOut]);

  // list of user items
  const renderMenuItemsList = useMemo<ReactNode>(() => {
    return (
      <>
        {renderMenuItemsArr.map(({ id, icon, text, link, onClick }) => {
          const listItemProps: Omit<ListItemProps, 'button'> = {
            key: id,
            onClick: handleClickMenuDrawerItem(),
            selected: router.pathname === (link && link.href),
            classes: {
              root: classNames(classes.rootListItem, classes.rootUserListItem),
              selected: classNames(
                classes.rootListItem,
                classes.rootUserListItem,
                classes.selectedRootUserListItem,
              ),
            },
          };

          // rewrite onclick of we have it in menu item
          if (onClick) {
            listItemProps.onClick = handleClickMenuDrawerItem(onClick);
          }

          const listItem = (
            <ListItem {...listItemProps}>
              <Icon
                classes={{
                  root: classNames(classes.userMenuIcon, {
                    [classes.userMenuIconSelected]: router.pathname === (link && link.href),
                  }),
                }}
                name={icon}
              />
              {typeof text === 'string' ? t(text) : text}
            </ListItem>
          );

          // wrap with link if we have link details
          if (link) {
            return (
              <Link key={id} {...link}>
                {listItem}
              </Link>
            );
          }

          // by default render list item
          return listItem;
        })}
      </>
    );
  }, [
    renderMenuItemsArr,
    handleClickMenuDrawerItem,
    router.pathname,
    classes.rootListItem,
    classes.rootUserListItem,
    classes.selectedRootUserListItem,
    classes.userMenuIcon,
    classes.userMenuIconSelected,
    t,
  ]);

  const handleLoadPreviousOrder = useCallback(() => {
    if (onLoadPreviousOrder) {
      onLoadPreviousOrder();
      setOpen(false);
    }
  }, [onLoadPreviousOrder]);

  // render load previous order
  const renderLoadPreviousOrderItem = useMemo<ReactNode>(() => {
    if (isLoggedIn) {
      return (
        <>
          <ListItem
            onClick={handleLoadPreviousOrder}
            button
            classes={{
              root: classNames(
                classes.rootListItem,
                classes.rootCategoryListItem,
                classes.rootLoadPreviousItems,
              ),
              selected: classNames(
                classes.rootListItem,
                classes.rootCategoryListItem,
                classes.rootLoadPreviousItems,
                classes.selectedRootUserListItem,
              ),
            }}
            disableRipple
          >
            <Icon color="primary" name="icon-refresh" classes={{ root: classes.categoryIcon }} />
            <Typography color="textSecondary" fontSize={18}>
              {'button.loadPreviousOrder'}
            </Typography>
          </ListItem>
          <Divider classes={{ root: classes.loadPreviousItemsDivider }} />
        </>
      );
    }
  }, [
    isLoggedIn,
    handleLoadPreviousOrder,
    classes.rootListItem,
    classes.rootCategoryListItem,
    classes.rootLoadPreviousItems,
    classes.selectedRootUserListItem,
    classes.categoryIcon,
    classes.loadPreviousItemsDivider,
  ]);

  const renderCategoryWithoutSubItems = useCallback(
    (category: TCategoryMenuItem) => {
      const isSelected = category.id === activeCategoryItem?.id;
      return (
        <Link key={category.id} {...category.link}>
          <ListItem
            button
            classes={{
              root: classes.rootListItemCategory,
              selected: classes.selectedCategoryItem,
            }}
            selected={isSelected}
            onClick={handleDrawerClose}
            disableRipple
          >
            <Box maxWidth={222}>
              <Typography
                isTranslate={false}
                variant="body1"
                fontSize={16}
                color="textSecondary"
                className={classNames({ [classes.selectedText]: isSelected })}
              >
                {category.text}
              </Typography>
            </Box>
          </ListItem>
          <Divider classes={{ root: classes.categoriesDivider }} />
        </Link>
      );
    },
    [
      activeCategoryItem?.id,
      classes.categoriesDivider,
      classes.rootListItemCategory,
      classes.selectedCategoryItem,
      classes.selectedText,
      handleDrawerClose,
    ],
  );

  const renderCategoryWithSubItems = useCallback(
    (category: TCategoryMenuItem) => {
      if (!category?.subMenuItems?.length) return;

      const allItems = {
        id: category.id,
        link: category.link,
        text: t('categoryMenu.allItems'),
      };
      const childrenWithParent = [allItems, ...category.subMenuItems];

      return (
        <Box key={category.id}>
          <ListItem
            button
            classes={{
              root: classNames(classes.rootListItemFocus, classes.rootListItemCategory),
              selected: classes.selectedCategoryItem,
            }}
            onClick={() => openSubMenu(category.id)}
            disableRipple
          >
            <Box maxWidth={196}>
              <Typography
                isTranslate={false}
                variant="body1"
                fontSize={16}
                color="textSecondary"
                className={classNames({
                  [classes.selectedText]:
                    category.id === activeCategoryItem?.parent?.id ||
                    category.id === activeCategoryItem?.id,
                })}
              >
                {category.text}
              </Typography>
            </Box>
            <Icon
              name="icon-arrow-down"
              classes={{
                root: classNames(classes.dropDownIcon, {
                  [classes.dropDownOpened]: expandedCategoryId === category.id,
                }),
              }}
            />
          </ListItem>

          <Box>
            <Collapse in={expandedCategoryId === category.id} timeout="auto" unmountOnExit>
              {childrenWithParent.map((submenuItem, index) => (
                <Link key={submenuItem.id} {...submenuItem.link}>
                  <ListItem
                    button
                    classes={{
                      root: classNames(classes.rootSubCategoryMenuItem, classes.rootSubCategory),
                      selected: classes.selectedCategoryItem,
                    }}
                    selected={submenuItem.id === activeCategoryItem?.id}
                    onClick={handleDrawerClose}
                    disableRipple
                    style={{
                      paddingBottom: index + 1 === childrenWithParent.length ? '20px' : '12px',
                      paddingTop: index === 0 ? '20px' : '12px',
                    }}
                  >
                    <Box maxWidth={178}>
                      <Typography
                        isTranslate={false}
                        variant="body1"
                        fontSize={16}
                        color="textSecondary"
                        className={classNames({
                          [classes.selectedText]: submenuItem.id === activeCategoryItem?.id,
                        })}
                      >
                        {submenuItem.text}
                      </Typography>
                    </Box>
                  </ListItem>
                </Link>
              ))}
            </Collapse>
          </Box>
          <Divider classes={{ root: classes.categoriesDivider }} />
        </Box>
      );
    },
    [
      t,
      classes.rootListItemFocus,
      classes.rootListItemCategory,
      classes.selectedCategoryItem,
      classes.selectedText,
      classes.dropDownIcon,
      classes.dropDownOpened,
      classes.categoriesDivider,
      classes.rootSubCategoryMenuItem,
      classes.rootSubCategory,
      activeCategoryItem?.parent?.id,
      activeCategoryItem?.id,
      expandedCategoryId,
      openSubMenu,
      handleDrawerClose,
    ],
  );

  const languageSwitcher = useMemo(() => {
    return (
      <>
        <ListItem
          component="div"
          button
          classes={{
            root: classNames(
              classes.moreButton,
              classes.rootListItem,
              classes.rootListItemCategoryFocus,
            ),
          }}
          disableRipple
          onClick={handleLanguageMenu}
        >
          <Box display="flex">
            <Icon color="primary" name="icon-languages" classes={{ root: classes.categoryIcon }} />
            <Typography
              variant="body1"
              fontSize={18}
              color="textSecondary"
              className={classes.categoriesTitle}
            >
              {'languages.language'}
            </Typography>
          </Box>
          <Icon
            name="icon-arrow-down"
            classes={{
              root: classNames(classes.dropDownIcon, {
                [classes.dropDownOpened]: openLanguageMenu,
              }),
            }}
          />
        </ListItem>
        <Divider classes={{ root: classes.categoriesDivider }} />
        <Collapse in={openLanguageMenu} timeout="auto" unmountOnExit>
          {relevantLanguages.map((language) => (
            <Box key={language.shortName}>
              <ListItem
                button
                classes={{
                  root: classNames(classes.rootListItem, classes.rootListItemCategory),
                  selected: classes.selectedCategoryItem,
                }}
                onClick={() => languageWasChanged(language)}
                disableRipple
              >
                <Box maxWidth={178} display="flex">
                  <CardMedia
                    image={language.imageUrl}
                    component="img"
                    className={classes.langImageMenu}
                    alt={language.title}
                  />
                  <Typography
                    isTranslate={false}
                    variant="body1"
                    fontSize={16}
                    color="textSecondary"
                    className={classNames({
                      [classes.selectedText]: currentLanguage.shortName === language.shortName,
                    })}
                  >
                    {language.title}
                  </Typography>
                </Box>
              </ListItem>
              <Divider classes={{ root: classes.categoriesDivider }} />
            </Box>
          ))}
        </Collapse>
      </>
    );
  }, [
    classes.categoriesDivider,
    classes.categoriesTitle,
    classes.categoryIcon,
    classes.dropDownIcon,
    classes.dropDownOpened,
    classes.langImageMenu,
    classes.moreButton,
    classes.rootListItem,
    classes.rootListItemCategory,
    classes.rootListItemCategoryFocus,
    classes.selectedCategoryItem,
    classes.selectedText,
    currentLanguage.shortName,
    handleLanguageMenu,
    languageWasChanged,
    openLanguageMenu,
    relevantLanguages,
  ]);

  // render categories list with expand list item
  const renderCategoriesBlock = useMemo<ReactNode>(() => {
    return (
      <>
        {renderLoadPreviousOrderItem}
        <ListItem
          component="div"
          button
          classes={{
            root: classNames(
              classes.moreButton,
              classes.rootListItem,
              classes.rootListItemCategoryFocus,
            ),
          }}
          disableRipple
          onClick={handleExpand}
        >
          <Box display="flex">
            <Icon color="primary" name="icon-categories" classes={{ root: classes.categoryIcon }} />
            <Typography
              variant="body1"
              fontSize={18}
              color="textSecondary"
              className={classes.categoriesTitle}
            >
              {'categoryMenu.title'}
            </Typography>
          </Box>
          <Icon
            name="icon-arrow-down"
            classes={{
              root: classNames(classes.dropDownIcon, {
                [classes.dropDownOpened]: isExpanded,
              }),
            }}
          />
        </ListItem>
        <Divider classes={{ root: classes.categoriesDivider }} />
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          {categoryItems.map((category) =>
            category.subMenuItems?.length
              ? renderCategoryWithSubItems(category)
              : renderCategoryWithoutSubItems(category),
          )}
        </Collapse>
      </>
    );
  }, [
    renderLoadPreviousOrderItem,
    classes.moreButton,
    classes.rootListItem,
    classes.rootListItemCategoryFocus,
    classes.categoryIcon,
    classes.categoriesTitle,
    classes.dropDownIcon,
    classes.dropDownOpened,
    classes.categoriesDivider,
    handleExpand,
    isExpanded,
    categoryItems,
    renderCategoryWithSubItems,
    renderCategoryWithoutSubItems,
  ]);

  const renderStoreTitle = useMemo(() => {
    return (
      <Box mt={17 / 8}>
        <ListItem
          classes={{
            root: classes.storeTitle,
          }}
        >
          <Typography
            isTranslate={false}
            variant="body1"
            fontSize={18}
            fontWeight="medium"
            color="textSecondary"
          >
            {websiteDetails.name}
          </Typography>
        </ListItem>
      </Box>
    );
  }, [classes.storeTitle, websiteDetails.name]);

  const contentPages = useMemo(() => {
    return createContentPagesList(true, true);
  }, [createContentPagesList]);

  const renderGeneralPagesMenu = useMemo<ReactNode>(() => {
    return (
      <>
        {renderStoreTitle}
        {contentPages.map((contentPage, index) => {
          return (
            <Box key={contentPage.id}>
              <Button
                onClick={() => setOpen(false)}
                className={classNames(classes.contentPageBtn, 'unstyled-button')}
                fullWidth
              >
                <Link
                  key={contentPage.id}
                  href={`/${contentPage.type}${
                    contentPage.id
                      ? contentPage.nonObfuscatedId && `/${contentPage.nonObfuscatedId}`
                      : ''
                  }`}
                >
                  <ListItem
                    style={{
                      paddingBottom: index + 1 === contentPages.length ? '40px' : '12px',
                    }}
                    classes={{
                      root: classNames(
                        classes.rootListItem,
                        classes.rootListItemGeneralPages,
                        classes.rootListItemGeneralPagesLink,
                      ),
                      selected: classNames(
                        classes.rootListItem,
                        classes.rootUserListItem,
                        classes.selectedRootUserListItem,
                      ),
                    }}
                    selected={
                      router.asPath ===
                      `/${contentPage.type}${
                        contentPage.id
                          ? contentPage.nonObfuscatedId && `/${contentPage.nonObfuscatedId}`
                          : ''
                      }`
                    }
                    disableRipple
                    button
                  >
                    {contentPage.name}
                  </ListItem>
                </Link>
              </Button>
            </Box>
          );
        })}
      </>
    );
  }, [
    renderStoreTitle,
    contentPages,
    classes.contentPageBtn,
    classes.rootListItem,
    classes.rootListItemGeneralPages,
    classes.rootListItemGeneralPagesLink,
    classes.rootUserListItem,
    classes.selectedRootUserListItem,
    router.asPath,
  ]);

  const onUserClick = useCallback(() => {
    if (!isLoggedIn && onLogin) {
      onLogin();

      // close menu
      handleDrawerClose();
      return;
    }

    setOpenUserMenuItems((prev) => !prev);
  }, [isLoggedIn, onLogin, handleDrawerClose]);

  useEffect(() => {
    setCurrentLanguage(
      relevantLanguages.filter((language) => language.shortName === i18next.language)[0] ||
        relevantLanguages[0],
    );
  }, [relevantLanguages]);

  const renderMenuIcon = useMemo(() => {
    const showNotification = clientCouponsOrderDiscounts.length || showMembershipSection;
    const iconClassName = showNotification ? classes.menuNotificationIcon : '';
    const iconName = showNotification ? 'icon-menu-notification-1' : 'icon-menu';
    return (
      <Box maxHeight={24}>
        <Icon classes={{ root: iconClassName }} name={iconName} color="action" />
        {!!showNotification && (
          <Icon classes={{ root: classes.menuNotificationDot }} name="icon-menu-notification-2" />
        )}
      </Box>
    );
  }, [
    classes.menuNotificationDot,
    classes.menuNotificationIcon,
    clientCouponsOrderDiscounts.length,
    showMembershipSection,
  ]);

  const membership = useMemo(() => {
    if (!showMembershipSection) return;

    const title = clubMembershipProfile[0].pointsEnabled
      ? t('membership.youEarnedPoints', {
          pointsNumber:
            clubMembershipProfile[0].totalPoints - (orderMembershipBenefits?.budgetToUse || 0),
        })
      : t('membership.youHaveBenefits', {
          benefitsNumber: clubMembershipProfile[0].userBenefits.length,
        });

    return (
      <Box ml={42 / 8}>
        <Typography
          variant="body1"
          fontSize={theme.direction === 'rtl' ? 16 : 14}
          fontWeight="medium"
          color="secondary"
        >
          {title}
        </Typography>
        <Button
          className={classes.memberPromotionDialog}
          onClick={() => {
            showDialog({
              dialogType: MEMBER_PROMOTION_DISCOUNT_DIALOG,
            });
            handleDrawerClose();
          }}
        >
          <Typography variant="body1" fontSize={14} fontWeight="medium" color="secondary">
            {'membership.forAllBenefits'}
          </Typography>
          <Icon
            color="secondary"
            name={theme.direction === 'rtl' ? 'icon-arrow-left' : 'icon-arrow-right'}
            style={{
              fontSize: '10px',
              marginRight: theme.direction === 'rtl' ? '4px' : 0,
              marginLeft: theme.direction === 'rtl' ? 0 : '4px',
            }}
          />
        </Button>
      </Box>
    );
  }, [
    showMembershipSection,
    theme.direction,
    t,
    clubMembershipProfile,
    orderMembershipBenefits,
    classes.memberPromotionDialog,
    showDialog,
    handleDrawerClose,
  ]);

  const welcomeBlock = useMemo(() => {
    const iconName = showMembershipSection ? 'icon-user-mobile-membership' : 'icon-user-mobile';

    if (onClientBehalf) {
      return (
        <Box className={classes.storeSideWrapper}>
          <Icon classes={{ root: classes.storeIcon }} name="icon-store-impersonate" />
          <Box>
            <Typography variant="body1" className={classes.storeSideText}>
              {'onClientBehalf.storeSide'}
            </Typography>
            <Typography variant="body1" className={classes.welcomeStoreText}>
              {lastName} {name}
            </Typography>
          </Box>
        </Box>
      );
    }
    return (
      <>
        <Box display="flex" alignItems="center">
          <Icon
            name={iconName}
            color="action"
            classes={{ root: classes.userIcon }}
            style={{ position: showMembershipSection ? 'absolute' : 'relative' }}
          />
          {showMembershipSection && (
            <Icon classes={{ root: classes.userNotificationDot }} name={'icon-user-membership'} />
          )}
          <Box ml={10 / 8}>
            <Typography variant="body1" fontSize={18} fontWeight="medium" color="light">
              {renderTopText}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }, [
    showMembershipSection,
    onClientBehalf,
    classes.userIcon,
    classes.userNotificationDot,
    classes.storeSideWrapper,
    classes.storeIcon,
    classes.storeSideText,
    classes.welcomeStoreText,
    renderTopText,
    lastName,
    name,
  ]);

  useEffect(() => {
    if (!contentPages.length) {
      setIsExpanded(true);
    }
  }, [contentPages]);

  return (
    <Box>
      <IconButton
        size="small"
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        disableRipple
      >
        {renderMenuIcon}
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        elevation={0}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        BackdropProps={{
          classes: {
            root: classes.backdropRoot,
          },
        }}
        ModalProps={{ onClose: handleDrawerClose }}
      >
        <List classes={{ root: classes.listRoot }}>
          <ListItem className={classes.header} onClick={onUserClick}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              {welcomeBlock}
              {isLoggedIn && (
                <Icon
                  name="icon-arrow-down"
                  classes={{
                    root: classNames(classes.arrowIcon, {
                      [classes.dropDownOpened]: openUserMenuItems,
                    }),
                  }}
                  color="action"
                />
              )}
            </Box>
            {membership}
          </ListItem>
          {isLoggedIn && (
            <Collapse in={openUserMenuItems} timeout="auto" unmountOnExit>
              <Box mt={20 / 8}>
                {!!clientCouponsOrderDiscounts.length && (
                  <Box mx={20 / 8} mb={10 / 8}>
                    <ClientCouponNotice clientCoupons={clientCouponsOrderDiscounts} />
                  </Box>
                )}
                {renderMenuItemsList}
              </Box>
              <Divider classes={{ root: classes.divider }} />
            </Collapse>
          )}
          {renderCategoriesBlock}
          {showLanguageSwitcher && languageSwitcher}
          {!!contentPages.length && renderGeneralPagesMenu}
          {websiteDetails.websiteSettings.pwaEnabled && !isPWAInstalled && (
            <ListItem
              style={{
                marginTop: contentPages.length ? '-24px' : '26px',
              }}
            >
              <PWADrawerButton onInstall={handleOnInstall} />
            </ListItem>
          )}
        </List>
        {open && (
          <IconButton
            classes={{ root: classes.closeButton }}
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
          >
            <Icon name="icon-button-x" color="primary" fontSize="inherit" />
          </IconButton>
        )}
      </Drawer>
    </Box>
  );
};

export default MenuDrawer;

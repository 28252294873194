import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { IBasketEditCard, TStylesBasketEditCard } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesBasketEditCard,
    { displayBorder: IBasketEditCard['displayBorder']; cardToSwap: boolean; markArrow: boolean }
  > =>
    createStyles({
      wrapper: ({ displayBorder, cardToSwap, markArrow }) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        padding: theme.spacing(14 / 8, 20 / 8),
        border: displayBorder ? `1.5px solid ${theme.palette.primary.main}` : 'none',
        '&:hover': {
          backgroundColor: theme.palette.background.paper, // override default theme hover button behavior
        },
        '&:hover $iconRoot': {
          color: cardToSwap || markArrow ? theme.palette.primary.main : theme.palette.grey.A100,
        },
        [theme.breakpoints.up('md')]: {
          '&:hover': {
            boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.1)',
          },
        },
      }),
      quantity: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(0, 6 / 8, 0, 10 / 8),
      },
      imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(10 / 8),
      },
      iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(10 / 8),
      },
      cardImage: {
        width: '74px',
        height: '52px',
      },
      description: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
      descriptionText: {
        maxHeight: '52px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'left',
        [theme.breakpoints.down(340)]: {
          maxWidth: '80px',
        },
      },
      iconRoot: ({ markArrow }) => ({
        fontSize: '30px',
        color: markArrow ? theme.palette.primary.main : theme.palette.grey.A100,
      }),
      productQuality: {
        position: 'absolute',
        top: '2px',
        left: '62px',
      },
    }),
);

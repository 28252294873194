import { TOrderDetailsReducerState, TUserReducerState } from 'store';
import { TOrderReducerState } from 'store/modules/order';
import { ICoupon, TOrderDiscount, TOrderDiscountState } from 'store/modules/orderDiscounts';
import { IPromotion } from 'store/modules/promotions';
import { IStoreProduct } from 'store/modules/storeProduct';
import { TOrderItemDTO, TPromotionServer, TStoreProductServer } from 'types';
import { getCartEstimation } from 'utils/helpers/cartEstimation';
import { forNonWeighableAboveQuantityProductAdded } from 'utils/helpers/promotions/items/forNonWeighableAboveQuantity';
import { forWeighableBetweenQuantityProductAdded } from 'utils/helpers/promotions/items/forWeighableBetweenQuantity';
import { calculateGeneralPromotionsAndCoupons } from 'utils/helpers/promotions/priceCalculation';
import { validateOrderType, validatePromotionRelevantWeekday } from './validators';

export function calculateOrderDiscounts(
  order: TOrderReducerState,
  orderDetails: TOrderDetailsReducerState,
  serviceAreaUsageFees: number,
  coupons: ICoupon[],
  storeCoupon: ICoupon | null,
  storeProductById: Record<TStoreProductServer['id'], IStoreProduct>,
  outOfStockStoreProductById: Record<TStoreProductServer['id'], IStoreProduct>,
  promotionById: Record<TPromotionServer['nonObfuscatedId'], IPromotion>,
  detailsAtStoreLevel: TUserReducerState['detailsAtStoreLevel'],
): TOrderDiscount[] {
  // calculate on-items promotions
  const itemsPromotions = order.items.reduce(
    (orderDiscounts: TOrderDiscount[], item, cartIndex) => {
      const storeProduct =
        storeProductById[item.storeProduct.id] || outOfStockStoreProductById[item.storeProduct.id];

      if (!item.storeProduct?.promotion || item.isRemoved) {
        return orderDiscounts;
      }

      const currentPromotion = promotionById[item.storeProduct?.promotion || 0];

      if (!currentPromotion) {
        return orderDiscounts;
      }

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return promotedProductAddedToCart(
        storeProduct,
        currentPromotion,
        item,
        cartIndex,
        orderDiscounts,
        orderDetails,
      );
    },
    [],
  );

  const itemsEstimationAfterItemsPromotions = getCartEstimation(order, itemsPromotions, 0);

  // extract general promotions
  const generalPromotions = Object.values(promotionById).filter(
    (p) => p.discountType.name !== 'itemsPromotionDiscount',
  );

  const couponsWithStoreCoupon = (coupons || []).concat(storeCoupon ? [storeCoupon] : []);

  const generalPromotionsAndCoupons = calculateGeneralPromotionsAndCoupons(
    order,
    orderDetails,
    serviceAreaUsageFees,
    itemsEstimationAfterItemsPromotions,
    generalPromotions,
    couponsWithStoreCoupon,
    storeProductById,
    outOfStockStoreProductById,
    detailsAtStoreLevel,
  );

  return [...itemsPromotions, ...generalPromotionsAndCoupons];
}

function promotedProductAddedToCart(
  storeProduct: IStoreProduct,
  promotion: IPromotion,
  orderItem: TOrderItemDTO,
  cartIndex: number,
  orderDiscounts: TOrderDiscount[],
  orderDetails: TOrderDetailsReducerState,
): TOrderDiscount[] {
  const promotionTypeName = promotion.promotionType.name;

  const relevantOrderDiscount = findRelevantOrderDiscountOrCreateNew(
    storeProduct,
    promotion,
    cartIndex,
    orderDiscounts,
    orderDetails,
  );

  if (!relevantOrderDiscount) {
    return orderDiscounts;
  }

  switch (promotionTypeName) {
    case 'forNonWeighableAboveQuantity':
      forNonWeighableAboveQuantityProductAdded(
        storeProduct,
        orderItem.requestedQuantity,
        cartIndex,
        promotion,
        relevantOrderDiscount,
        orderDiscounts,
        orderDetails,
      );
      break;
    case 'forWeighableBetweenQuantity':
      forWeighableBetweenQuantityProductAdded(
        storeProduct,
        orderItem.requestedSellingUnit.id,
        orderItem.requestedQuantity,
        cartIndex,
        promotion,
        relevantOrderDiscount,
      );
      break;
    default:
  }

  return orderDiscounts;
}

export function totalServiceAreaDiscounts(
  discounts: TOrderDiscount[],
  serviceAreaUsageFees: number,
): number {
  const serviceAreaDiscounts = discounts.reduce(
    (total, discount) =>
      discount.discountType.name === 'serviceAreaUsageFeesDiscount'
        ? total + discount.totalPrice
        : total,
    0,
  );

  const discountMaxAllowableValue = Math.min(Math.abs(serviceAreaDiscounts), serviceAreaUsageFees);

  return -Math.abs(discountMaxAllowableValue);
}

export function findRelevantOrderDiscountOrCreateNew(
  storeProduct: IStoreProduct,
  promotion: IPromotion,
  cartIndex: number,
  orderDiscounts: TOrderDiscount[],
  orderDetails: TOrderDetailsReducerState,
): TOrderDiscount | null {
  const { relevantWeekdays } = promotion.parameters;

  if (
    relevantWeekdays &&
    !validatePromotionRelevantWeekday(relevantWeekdays, orderDetails.preferredDay)
  ) {
    return null;
  }

  if (!validateOrderType(promotion, orderDetails.orderType)) {
    return null;
  }

  let relevantPromotion = orderDiscounts.find(
    (orderDiscount) =>
      orderDiscount.promotion === storeProduct.promotion &&
      orderDiscount.state === TOrderDiscountState.Pending,
  );

  if (!relevantPromotion) {
    relevantPromotion = {
      cartIndex,
      accumulatedQuantity: 0,
      accumulatedProducts: [],
      totalParticipatingProductsPrice: 0,
      promotion: promotion.nonObfuscatedId,
      totalPrice: 0,
      name: promotion.name,
      externalNotes: promotion.externalNotes,
      state: TOrderDiscountState.Pending,
      discountType: promotion.discountType,
      valueFormula: promotion.valueFormula,
      valueFormulaType: promotion.valueFormulaType,
      discountSourceType: promotion.discountSourceType,
      storeProductSource: promotion.storeProductSource,
      promotionType: promotion.promotionType,
      parameters: promotion.parameters,
      valueFormulaSourceQuantity: promotion.valueFormulaSourceQuantity,
      shortName: promotion.shortName,
      expirationTime: promotion.expirationTime,
    };

    orderDiscounts.push(relevantPromotion);
  }

  return relevantPromotion;
}

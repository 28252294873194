import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import { ICustomImageLoader } from './types';

const CustomImageLoader: FC<ICustomImageLoader> = ({
  src,
  alt,
  classes,
  loaderComponent,
  errorImageSrc,
  ...imageProps
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
  }, [src]);

  return (
    <>
      {isLoading && loaderComponent}
      <Image
        src={isError ? errorImageSrc || src : src}
        alt={alt}
        layout="fill"
        objectFit="contain"
        onLoadingComplete={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
          setIsError(true);
        }}
        className={classes}
        {...imageProps}
      />
    </>
  );
};

export default CustomImageLoader;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesTextInput } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesTextInput> =>
    createStyles({
      root: {
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(0.75), // 6px,
        color: theme.palette.text.secondary,

        '&$focused $notchedOutline': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },

        '&:hover$focused $notchedOutline': {
          borderColor: theme.palette.primary.main,
        },

        '&:hover $notchedOutline': {
          borderColor: theme.palette.grey.A200,
        },

        '&$error $notchedOutline': {
          borderWidth: '1px',
          borderColor: theme.palette.error.main,
        },

        '&:hover$error $notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
      error: {},
      focused: {},
      labelFocused: {},
      labelError: {},
      asterisk: {},
      notchedOutline: {
        borderColor: theme.palette.grey.A200,
        padding: '0 !important', // reset the default value of the material ui input
      },
      labelRoot: {
        color: theme.palette.grey[600],
        fontSize: theme.spacing(1.625), // 13px
        marginBottom: theme.spacing(0.875), // 7px

        '&$labelFocused': {
          color: theme.palette.grey[600],
        },

        '&$labelError': {
          color: theme.palette.grey[600],
        },

        '& $labelError$asterisk': {
          color: theme.palette.grey[600],
        },
      },
      helperText: {
        lineHeight: 1.1,
        fontSize: theme.spacing(1.625), // 13px
      },
      input: {
        letterSpacing: -0.2,

        '&::placeholder': {
          opacity: 1,
          color: theme.palette.grey.A400,
        },
      },
      adornedStart: {
        paddingLeft: theme.spacing(1.5), // 12px
      },
      inputAdornedStart: {
        marginLeft: theme.spacing(0.75), // 6px
      },
    }),
);

import React, { FC } from 'react';

import { AppBar } from '@material-ui/core';

import { useMobile } from 'hooks';
import { useSelector } from 'react-redux';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import useStyles from './styles';

import { IStickyHeader } from './types';

const StickyHeader: FC<IStickyHeader> = ({ ariaHidden = false, children }) => {
  const { isMobile } = useMobile();
  const orderMode = useSelector(getOrderMode);
  const classes = useStyles({ isMobile, orderMode });

  return (
    <AppBar
      classes={{
        root: classes.appBarRoot,
      }}
      aria-hidden={ariaHidden}
      id="sticky-header"
    >
      {children}
    </AppBar>
  );
};

export default StickyHeader;

import { TProductCategoryServer, TStoreWebsiteBanner } from 'types';
import { ICategoryBanner, IWebsiteDetails } from 'store';

export function processBannersByCategory(
  banners: TStoreWebsiteBanner[],
): IWebsiteDetails['bannersByCategory'] {
  const bannersByCategory: IWebsiteDetails['bannersByCategory'] = {};

  banners.forEach((banner) => {
    let categoryId;
    if (banner.staticCategory) {
      categoryId = banner.staticCategory.name === 'allProducts' ? '-1' : '0';
    } else if (banner.category) {
      categoryId = banner.category.id;
    }

    if (categoryId) {
      if (!bannersByCategory[categoryId]) {
        bannersByCategory[categoryId] = [];
      }

      const categoryBanners: ICategoryBanner[] = JSON.parse(banner.bannersJson);

      bannersByCategory[categoryId] = bannersByCategory[categoryId].concat(categoryBanners);
    }
  });

  return bannersByCategory;
}

export function getBannerForCell(
  bannersByCategory: IWebsiteDetails['bannersByCategory'],
  currentCategoryId: TProductCategoryServer['id'],
  isMobile: boolean,
  isOpenedTopCart: boolean,
  rowIndex: number,
  columnIndex: number,
): ICategoryBanner | null {
  const relevantBanners = bannersByCategory[currentCategoryId];
  if (
    !relevantBanners ||
    (isMobile && rowIndex % 3 !== 2) || // mobile banner every 2 rows of products
    (!isMobile && rowIndex % 2 === 0) // desktop banner every 1 row of products
  ) {
    return null;
  }

  const bannerIndex = Math.floor(isMobile ? rowIndex / 3 : rowIndex / 2);

  if (relevantBanners.length <= bannerIndex) {
    return null;
  }

  if (isMobile) {
    if (columnIndex !== 0) {
      return null;
    }
    return relevantBanners[bannerIndex];
  }

  if (
    (bannerIndex % 2 === 0 && columnIndex !== 0) ||
    (bannerIndex % 2 === 1 && isOpenedTopCart && columnIndex !== 2) ||
    (bannerIndex % 2 === 1 && !isOpenedTopCart && columnIndex !== 3)
  ) {
    return null;
  }

  return relevantBanners[bannerIndex];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getAllBanners(bannersByCategory: IWebsiteDetails['bannersByCategory']): ICategoryBanner[] {
  return Object.values(bannersByCategory).reduce(
    (allBanners, bannersForSpecificCategory) => allBanners.concat(bannersForSpecificCategory),
    [],
  );
}

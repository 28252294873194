import { createStyles, makeStyles, StyleRules } from '@material-ui/core';
import { MAX_CONTAINER_WIDTH } from 'ui/common/Container';
import { TStylesOrderModeIndicator } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesOrderModeIndicator> =>
    createStyles({
      wrapper: {
        height: theme.spacing(40 / 8),
        backgroundColor: theme.palette.text.secondary,
        padding: theme.spacing(0, 14 / 8),
        position: 'sticky',
        top: 0,
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
      container: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.up('md')]: {
          width: MAX_CONTAINER_WIDTH,
          margin: '0 auto',
        },
      },
      indicatorIcon: {
        color: theme.palette.primary.main,
        fontSize: theme.spacing(16 / 8),
        marginRight: theme.spacing(6 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(18 / 8),
        },
      },
      exitButton: {
        padding: 0,
        minWidth: 'auto',
        textTransform: 'capitalize',
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesItemPromotionCart } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesItemPromotionCart> =>
    createStyles({
      wrapper: {
        height: '100px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflow: 'visible',
        position: 'relative',
        borderRadius: '8px',
      },
      main: {
        padding: '14px 12px',
        display: 'flex',
        height: theme.spacing(100 / 8),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: '22px 20px',
        },
      },
      cardImage: {
        width: '74px',
        height: '72px',
        [theme.breakpoints.up('md')]: {
          width: '86px',
          height: '56px',
        },
      },
      productDetailsButton: {
        padding: '0px !important',
        width: 'fit-content',
      },
      nameAndPriceSection: {
        marginLeft: '10px',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginLeft: '12px',
        },
      },
      fullName: {
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 1,
        overflow: 'hidden !important',
        [theme.breakpoints.up('md')]: {
          width: '150px',
          display: 'flex',
          alignItems: 'center',
        },
      },
      productType: {
        zIndex: 2,
        height: theme.spacing(20 / 8),
        position: 'absolute',
        top: 6,
        left: 6,
        [theme.breakpoints.up('md')]: {
          top: 10,
          left: 10,
        },
      },
      priceWrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: '6px 2px 0px 0px',
        [theme.breakpoints.up('md')]: {
          margin: '0px 2px 0px 0px',
          flexDirection: 'column',
        },
      },
      price: {
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: 600,
      },
      sellingUnit: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
      },
      pricePerGram: {
        fontSize: '10px',
        lineHeight: '13px',
        color: '#7A7A7A',
        fontWeight: 'normal',
      },
    }),
);

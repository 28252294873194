import { alpha, createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesMobileFooter } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMobileFooter, { hasAboutPage: boolean }> =>
    createStyles({
      wrapper: {
        width: '100%',
        position: 'relative',
      },
      footerContent: {
        justifyContent: 'center',
        marginTop: theme.spacing(36 / 8),
        textAlign: 'center',
      },
      fullWrapper: {
        minHeight: '407px',
        maxHeight: '430px',
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'space-between',
      },
      tabletFullWrapper: {
        height: '372px',
      },
      miniWrapper: {
        width: '100%',
        height: '138px',
        backgroundColor: theme.palette.text.secondary,
        padding: theme.spacing(30 / 8, 0),
      },
      labelContent: {
        marginTop: theme.spacing(10 / 8),
      },
      upImage: {
        transform: theme.direction === 'rtl' ? 'none' : 'scaleX(-1)',
        width: '80px',
        height: '80px',
        position: 'absolute',
        top: -30,
        left: '5%',
        zIndex: 2,
      },
      bottomImage: {
        zIndex: 2,
        transform: theme.direction === 'rtl' ? 'none' : 'scaleX(-1)',
        width: '100px',
        height: '100px',
        position: 'absolute',
        bottom: 105,
        right: 10,
      },
      tabletBottomImage: {
        right: '3%',
      },
      tabletUpImage: {
        left: '2%',
      },
      dividerHorizontalRoot: {
        width: '100%',
        backgroundColor: alpha(theme.palette.background.paper, 0.4),
        margin: ({ hasAboutPage }) => theme.spacing(hasAboutPage ? 18 / 8 : 24 / 8, 0),
      },
      menuWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(20 / 8),
      },
      contactOrSocialsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(36 / 8),
      },
      socialLinkWrapper: {
        zIndex: 3,
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        margin: theme.spacing(0, 8 / 8),
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      socialLinkItem: {
        height: '18px',
        '& span': {
          fontSize: '18px',
        },
      },
      menuItemLink: {
        zIndex: 2,
        lineHeight: '14px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        '&:not(:last-child)': {
          paddingRight: theme.spacing(10 / 8),
          '&::after': {
            content: '" "',
            height: '100%',
            width: theme.spacing(1 / 8),
            backgroundColor: theme.palette.background.paper,
            marginLeft: theme.spacing(10 / 8),
          },
        },
      },
      menuItem: {
        textAlign: 'center',
      },
      socialIcon: {
        fontSize: '22px',
      },
      termsSection: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(12 / 8),
      },
      logoSection: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(10 / 8),
      },
    }),
);

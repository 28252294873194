import { TLocation } from './types';

// TODO rewrite and lose this
export const showNotFoundLocation: TLocation = {
  placeId: 'mapLocation',
  address: 'location.cantFind',
  cityAndCountry: 'location.selectByMap',
};
export const shareCurrentLocation: TLocation = {
  placeId: 'shareMyLocation',
  address: 'location.shareMyLocation',
  cityAndCountry: '',
  icon: 'icon-current-location',
  customLabel: 'location.myLocation',
};

import { Box, Button, CardMedia, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import {
  isStoreProductOutOfStock,
  prepareMultiLangForRequestedSellingUnitName,
} from 'components/common/contents/ViewSubscriptionContent/utils';

import { useRenderPrice, useWebsiteDetails } from 'hooks';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import useProductPrice from 'hooks/useProductPrice';
import React, { FC, useMemo } from 'react';
import CheckBox from 'ui/common/inputs/CheckBox';
import { renderItemCount } from 'utils';
import ProductQualityTag from 'ui/common/ProductQualityTag';
import { MAX_CONTAINER_WIDTH } from '../Container';
import Typography from '../Typography';

import useStyles from './styles';

import { IPreviousOrderItem } from './types';

const PreviousOrderItem: FC<IPreviousOrderItem> = (props) => {
  const {
    storeProduct,
    actualQuantity,
    storeProductFullName,
    priceForKGOrUnit,
    estimatedTotalWeightInKGOrUnit,
    requestedQuantity,
    price,
    checked,
    clickAway,
    showActualQuantity = true,
    displayCheckBox = false,
    displayPrice = true,
    displayBorderTop = false,
    displayBorderBottom = false,
    displayOutOfStockSection = false,
    overridePrice,
  } = props;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(MAX_CONTAINER_WIDTH));

  const { pricePerUnit } = useProductPrice();

  const renderPrice = useRenderPrice();

  const websiteDetails = useWebsiteDetails();

  const { renderLanguageField } = useMultiLanguage();

  const productToDisplay = useMemo(() => {
    const primarySellingUnit = {
      multiLang: {
        he: {
          name:
            storeProduct && storeProduct.product.primaryQuantityUnit
              ? storeProduct.product.primaryQuantityUnit.name
              : '',
        },
        ...JSON.parse(
          storeProduct && storeProduct.product.primaryQuantityUnit
            ? storeProduct.product.primaryQuantityUnit.multiLangJson
            : '{}',
        ),
      },
    };

    const firstSellingUnit = {
      multiLang: {
        he: {
          name: storeProduct ? storeProduct.productSellingUnits[0].sellingUnit.name : '',
        },
        ...JSON.parse(
          storeProduct ? storeProduct.productSellingUnits[0].sellingUnit.multiLangJson : '{}',
        ),
      },
    };

    return {
      pricingSellingUnit:
        storeProduct && storeProduct.soldByWeight ? primarySellingUnit : firstSellingUnit,
      productQuality: {
        multiLang: {
          he: {
            name: storeProduct ? storeProduct.productQuality.name : '',
          },
          ...JSON.parse(storeProduct ? storeProduct.productQuality.multiLangJson : '{}'),
        },
      },
      quantity: {
        value:
          showActualQuantity && actualQuantity
            ? Number(actualQuantity.toFixed(2))
            : requestedQuantity,
        text: {
          multiLang: prepareMultiLangForRequestedSellingUnitName(
            props,
            !!(showActualQuantity && actualQuantity),
          ),
        },
      },
      price: Number(
        overridePrice ||
          (showActualQuantity && actualQuantity
            ? price
            : priceForKGOrUnit * (estimatedTotalWeightInKGOrUnit || 1)),
      ),
      outOfStock: storeProduct && isStoreProductOutOfStock(storeProduct),
    };
  }, [
    actualQuantity,
    estimatedTotalWeightInKGOrUnit,
    price,
    priceForKGOrUnit,
    overridePrice,
    props,
    requestedQuantity,
    showActualQuantity,
    storeProduct,
  ]);

  const classes = useStyles({
    displayPrice,
    displayBorderTop,
    displayBorderBottom,
    displayCheckBox,
    displayOutOfStockSection,
    outOfStock: productToDisplay.outOfStock,
  });

  const renderTotalPrice = useMemo(() => {
    return (
      <Typography fontWeight="bold" color="textPrimary" variant="body2" fontSize={16}>
        {renderPrice(productToDisplay.price)}
      </Typography>
    );
  }, [renderPrice, productToDisplay.price]);

  const renderItemPrice = useMemo(() => {
    if (!storeProduct) return;

    const pricingSellingUnitName = renderLanguageField(
      productToDisplay.pricingSellingUnit.multiLang,
      'name',
    );

    const pricePerUnitToDisplay = storeProduct.soldByWeight
      ? priceForKGOrUnit / (storeProduct.product?.primaryQuantityUnit?.conversionRateToKG || 1)
      : priceForKGOrUnit;

    return (
      <Box className={classes.itemPrice}>
        {pricePerUnit(
          pricePerUnitToDisplay,
          pricingSellingUnitName,
          classNames(classes.priceAndSellingUnit, 'font-family-heebo'),
          classNames(classes.priceAndSellingUnit, 'font-family-heebo'),
        )}
      </Box>
    );
  }, [
    storeProduct,
    renderLanguageField,
    productToDisplay.pricingSellingUnit.multiLang,
    classes.itemPrice,
    classes.priceAndSellingUnit,
    pricePerUnit,
    priceForKGOrUnit,
  ]);

  const renderDescription = useMemo(() => {
    return (
      <Typography
        fontSize={14}
        color="textPrimary"
        className={classes.descriptionText}
        isTranslate={false}
      >
        {storeProduct &&
        storeProduct.productQuality &&
        storeProduct.productQuality.displayQuality &&
        storeProduct.name
          ? storeProduct.name
          : storeProductFullName || storeProduct?.fullName}
      </Typography>
    );
  }, [classes.descriptionText, storeProduct, storeProductFullName]);

  const renderQuantity = useMemo(() => {
    return (
      <Box>
        <Typography
          color="textPrimary"
          fontWeight="bold"
          variant="body2"
          fontSize={16}
          align="center"
        >
          {renderItemCount(productToDisplay.quantity.value || 0)}
        </Typography>
        <Typography color="grey" fontSize={13} align="center">
          {renderLanguageField(productToDisplay.quantity.text.multiLang, 'name')}
        </Typography>
      </Box>
    );
  }, [
    productToDisplay.quantity.value,
    productToDisplay.quantity.text.multiLang,
    renderLanguageField,
  ]);

  const outOfStockLabel = useMemo(() => {
    return (
      <Box className={classes.outOfStockWrapper}>
        <Typography className={classes.outOfStockLabel}>
          {'previousOrder.itemIsOutOfStock'}
        </Typography>
      </Box>
    );
  }, [classes.outOfStockLabel, classes.outOfStockWrapper]);

  return (
    <Button className={classes.wrapper} onClick={clickAway} disableRipple>
      {displayOutOfStockSection && productToDisplay.outOfStock && outOfStockLabel}
      <Box className={classNames(classes.wrapper, classes.outOfStockOpacity)}>
        {/* Render Promoted Tag if productQuality = true */}
        {storeProduct &&
          storeProduct.productQuality &&
          storeProduct.productQuality.displayQuality && (
            <Box className={displayCheckBox ? classes.tag : classes.tagWithoutCheckbox}>
              <ProductQualityTag
                label={renderLanguageField(productToDisplay.productQuality.multiLang, 'name')}
              />
            </Box>
          )}
        <Box display="flex">
          <Box className={classes.productWrapper}>
            {displayCheckBox && displayOutOfStockSection && !productToDisplay.outOfStock && (
              <Box mt={isMobile ? 5 / 8 : 4 / 8} ml={-8 / 8}>
                <CheckBox checked={checked} size={isMobile ? 'small' : 'large'} />
              </Box>
            )}
            <Box className={classes.imageWrapper}>
              {/* product image */}
              {storeProduct && (
                <CardMedia
                  classes={{ root: classes.cardImage }}
                  component="img"
                  image={
                    storeProduct.imageUrl
                      ? `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${storeProduct.imageUrl}`
                      : websiteDetails.genericProductsDefaultImagePath
                  }
                  alt={storeProduct.fullName}
                />
              )}
            </Box>
          </Box>
          <Box className={classes.description}>{renderDescription}</Box>
        </Box>
        <Box display="flex">
          {/* Render Product Price only in Desktop */}
          {!isMobile && displayPrice && (
            <Box width={displayCheckBox ? 120 : 140} display="flex" justifyContent="center">
              {renderItemPrice}
            </Box>
          )}
          <Box className={classes.quantity}>{renderQuantity}</Box>
          {displayPrice && <Box className={classes.totalPrice}>{renderTotalPrice}</Box>}
        </Box>
      </Box>
    </Button>
  );
};

export default PreviousOrderItem;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, PutEffect, select, SelectEffect, takeEvery } from '@redux-saga/core/effects';

import {
  ADD_OR_REMOVE_PROMOTIONS_CATEGORY,
  categoryActions,
  categorySelectors,
  IProductCategory,
  storeProductSelectors,
  TStoreProductReducerState,
} from 'store';

import { getIdsAndByIds } from 'utils';
import { getPromotionsCategory } from 'utils/helpers/catalog';

import { GET_CATEGORIES_REQUEST } from './constants';

import { IGetCategoriesRequest, IGetCategoriesSuccess } from './types';

function* getProductCategories({
  payload,
}: IGetCategoriesRequest): Generator<PutEffect<IGetCategoriesSuccess>, void, any> {
  const { objById, arrayIds } = getIdsAndByIds<IProductCategory>(payload.categoriesData);

  // update website product state
  yield put(categoryActions.getCategoriesSuccessAction(arrayIds, objById));
}

function* addOrRemovePromotionsCategory(): Generator<
  SelectEffect | PutEffect<IGetCategoriesRequest>,
  void,
  any
> {
  const {
    storeProductById,
  }: {
    storeProductIds: TStoreProductReducerState['storeProductIds'];
    storeProductById: TStoreProductReducerState['storeProductById'];
  } = yield select(storeProductSelectors.storeProductsData);
  const { categoryById, categoryIds } = yield select(categorySelectors.categoriesData);

  const storeProducts = Object.values(storeProductById);

  const catalogContainsPromotions = storeProducts.find(
    (storeProduct) => storeProduct.promotion || storeProduct.promoted,
  );

  if (catalogContainsPromotions) {
    if (categoryIds.find((categoryId: number) => categoryId === 0) === undefined) {
      const existingCategories: IProductCategory[] = Object.values(categoryById);

      existingCategories.push(getPromotionsCategory());

      yield put(categoryActions.getCategoriesRequestAction(existingCategories));
    }
  } else if (categoryIds.find((categoryId: number) => categoryId === 0) !== undefined) {
    const existingCategories: IProductCategory[] = Object.values(categoryById);

    yield put(
      categoryActions.getCategoriesRequestAction(
        existingCategories.filter((category) => category.id !== 0),
      ),
    );
  }
}

function* rootCategorySaga(): Generator {
  yield takeEvery(GET_CATEGORIES_REQUEST, getProductCategories);
  yield takeEvery(ADD_OR_REMOVE_PROMOTIONS_CATEGORY, addOrRemovePromotionsCategory);
}

export default rootCategorySaga;

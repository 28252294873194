import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesIProductDetails } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesIProductDetails,
    {
      isPopup: boolean;
      bagOfProducts: boolean;
      hasAdditionalImages: boolean;
      isSlideItems: boolean;
    }
  > =>
    createStyles({
      storeCard: {
        maxWidth: theme.spacing(600 / 8),
        margin: '0 auto',
        padding: ({ isPopup }) => theme.spacing(isPopup ? 54 / 8 : 20 / 8, 0, 0, 0),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(36 / 8, 0, 0, 0),
          padding: '0 !important',
          width: ({ isPopup }) => (isPopup ? '326px' : '400px'),
          minHeight: ({ isPopup, hasAdditionalImages }) => {
            if (hasAdditionalImages) {
              if (isPopup) {
                return '395px';
              }
              return '484px';
            }
            return '328px';
          },
        },
      },
      storeCardWrapper: {
        [theme.breakpoints.up('md')]: {
          height: 'auto',
        },
      },
      mainWrapper: ({ isPopup }) => ({
        width: 'auto',
        padding: 0,
        [theme.breakpoints.up('md')]: {
          minHeight: isPopup ? 'auto' : '100vh',
          width: isPopup ? 'auto' : '900px',
          marginTop: theme.spacing(46 / 8),
        },
      }),
      divider: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block',
          height: '1px',
          color: theme.palette.grey.A100,
        },
      },
      titleDesktopWrapper: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block',
        },
      },
      bagOfProductsTitleDesktopWrapper: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block',
          paddingBottom: ({ isSlideItems }) => theme.spacing(isSlideItems ? 0 : 40 / 8),
        },
      },
      title: ({ bagOfProducts }) => ({
        fontSize: '18px',
        marginTop: 0,
        marginBottom: theme.spacing(10 / 8),
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(22 / 8),
          marginTop: theme.spacing(bagOfProducts ? 30 / 8 : 40 / 8),
          marginBottom: theme.spacing(14 / 8),
        },
      }),
      titleYouMayAlsoWant: {
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(16 / 8),
        },
      },
      sliderDesktop: ({ isPopup }) => ({
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block',
          marginBottom: theme.spacing(isPopup ? 0 : 76 / 8),
        },
      }),
      sliderMobile: {
        display: 'block',
        width: '100vw',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      sliderTitleMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      bagExtraDetails: {
        whiteSpace: 'pre-line',
        color: theme.palette.text.secondary,
        fontSize: '14px',
        lineHeight: 1.3,
      },
      dividerMobile: {
        margin: theme.spacing(24 / 8, 0),
        display: 'block',
        height: '1px',
        color: theme.palette.grey.A100,
      },
      ratingWrapper: {
        display: 'flex',
        marginTop: theme.spacing(10 / 8),
        alignItems: 'center',
      },
      currentRelevancyFreeText: {
        fontSize: '14px',
        lineHeight: 1.29,
        marginTop: '0',
        textAlign: 'inherit',
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(6 / 8),
      },
    }),
);

import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { dialogActions, dialogSelectors } from 'store';

import { TShowDialog, TUseDialog } from './types';

const useDialog: TUseDialog = () => {
  const dispatch = useDispatch();

  const open = useSelector(dialogSelectors.getOpen);

  const dialogProps = useSelector(dialogSelectors.getDialogProps);

  const dialogType = useSelector(dialogSelectors.getDialogType);

  const children = useSelector(dialogSelectors.getDialogChildren);

  const contentProps = useSelector(dialogSelectors.getContentProps);

  const showDialog = useCallback<TShowDialog>(
    (params) => {
      dispatch(dialogActions.showDialog(params));
    },
    [dispatch],
  );

  const hideDialog = useCallback(() => {
    dispatch(dialogActions.hideDialog());
  }, [dispatch]);

  return { open, showDialog, hideDialog, dialogProps, dialogType, children, contentProps };
};

export default useDialog;

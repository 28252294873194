import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesCodeInput } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCodeInput> =>
    createStyles({
      formControlRoot: {
        display: 'flex',
        flexDirection: 'row',

        '& > $root:not(:last-child)': {
          margin:
            theme.direction === 'ltr'
              ? theme.spacing(0, 1, 0, 0) // 8px
              : theme.spacing(0, 0, 0, 1), // 8px
        },
      },
      root: {
        background: theme.palette.background.paper,
        borderRadius: theme.spacing(0.5), // 4px,
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(3.25), // 26px
        width: theme.spacing(5), // 40px',
        height: theme.spacing(6.25), // 50px

        '&$focused $notchedOutline': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },
        '&:hover $notchedOutline': {
          borderColor: theme.palette.grey.A200,
        },
      },
      focused: {},
      notchedOutline: {
        borderColor: theme.palette.grey.A200,
      },
      completed: {
        borderColor: theme.palette.grey.A200,
      },
      input: {
        padding: theme.spacing(1.25, 1.5, 1.125, 1.625), // '10px 12px 9px 13px'
      },
      helperText: {
        fontSize: theme.spacing(1.625), // 13px
        marginTop: theme.spacing(0.25), // 2px
      },
    }),
);

import React, { FC, useMemo } from 'react';

import { Box, Button, CardMedia } from '@material-ui/core';

import { renderItemCount } from 'utils';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import ProductQualityTag from 'ui/common/ProductQualityTag';

import { useProduct } from 'hooks';
import useStyles from './styles';

import { IBasketEditCard } from './types';

const BasketEditCard: FC<IBasketEditCard> = ({
  bagItem,
  cardToSwap = false,
  markArrow = false,
  displayBorder = false,
  quantity,
  imageUrl,
  onItemClick,
}) => {
  const classes = useStyles({ displayBorder, markArrow, cardToSwap });
  const { formatBagExtraDetails } = useProduct();

  const [productName, productQuality] = useMemo(() => {
    return formatBagExtraDetails(bagItem, false, true);
  }, [bagItem, formatBagExtraDetails]);

  const renderQuantity = useMemo(() => {
    return (
      <Box>
        <Typography
          variant="body2"
          fontWeight="bold"
          color="textPrimary"
          fontSize={16}
          align="center"
        >
          {renderItemCount(quantity?.value || 0)}
        </Typography>
        <Typography color="grey" fontSize={13} align="center">
          {quantity?.text}
        </Typography>
      </Box>
    );
  }, [quantity]);

  return (
    <Button className={classes.wrapper} onClick={onItemClick} disableRipple>
      <Box display="flex">
        <Box className={classes.iconWrapper}>
          <Icon name="icon-swap" classes={{ root: classes.iconRoot }} />
        </Box>
        {productQuality && (
          <Box className={classes.productQuality}>
            <ProductQualityTag label={productQuality} height="18px" />
          </Box>
        )}
        <Box className={classes.imageWrapper}>
          {/* card image */}
          <CardMedia
            classes={{ root: classes.cardImage }}
            component="img"
            image={imageUrl}
            alt={productName}
          />
        </Box>
        <Box className={classes.description}>
          <Typography fontSize={14} color="textPrimary" className={classes.descriptionText}>
            {productName}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.quantity}>{renderQuantity}</Box>
    </Button>
  );
};

export default BasketEditCard;

const GET_STORE_PRODUCTS_REQUEST = 'storeProduct/GET_STORE_PRODUCTS_REQUEST';
const GET_STORE_PRODUCTS_SUCCESS = 'storeProduct/GET_STORE_PRODUCTS_SUCCESS';
const UPDATE_PRODUCTS_BY_ID_SUCCESS = 'storeProduct/UPDATE_PRODUCTS_BY_ID_SUCCESS';
const UPDATE_OUT_OF_STOCK_PRODUCTS = 'storeProduct/UPDATE_OUT_OF_STOCK_PRODUCTS';

export {
  GET_STORE_PRODUCTS_REQUEST,
  GET_STORE_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_BY_ID_SUCCESS,
  UPDATE_OUT_OF_STOCK_PRODUCTS,
};

import {
  OPEN_DELIVERY_SELF_PICKUP_REQUEST,
  UPDATE_COURIER_TIP,
  UPDATE_LIMITATION_BY_AGE_CHECKED,
  UPDATE_ORDER_DETAILS,
  UPDATE_ORDER_MODE,
} from 'store/modules/orderDetails/constants';
import {
  TOpenDeliverySelfPickupRequestAction,
  TUpdateCourierTipAction,
  TUpdateLimitationByAgeCheckedAction,
  TUpdateOrderDetailsAction,
  TUpdateOrderModeAction,
} from 'store/modules/orderDetails/types';

const updateOrderMode: TUpdateOrderModeAction = (newMode) => ({
  type: UPDATE_ORDER_MODE,
  payload: newMode,
});

const updateOrderDetails: TUpdateOrderDetailsAction = (newOrderDetails) => ({
  type: UPDATE_ORDER_DETAILS,
  payload: newOrderDetails,
});

const openDeliverySelfPickupRequest: TOpenDeliverySelfPickupRequestAction = (
  orderType = 'delivery',
  options,
) => ({
  type: OPEN_DELIVERY_SELF_PICKUP_REQUEST,
  payload: {
    orderType,
    ...options,
  },
});

const updateCourierTip: TUpdateCourierTipAction = (tipValue) => ({
  type: UPDATE_COURIER_TIP,
  payload: tipValue,
});

const updateLimitationByAgeChecked: TUpdateLimitationByAgeCheckedAction = (
  limitationByAgeChecked,
) => ({
  type: UPDATE_LIMITATION_BY_AGE_CHECKED,
  payload: limitationByAgeChecked,
});

export {
  updateOrderMode,
  updateOrderDetails,
  openDeliverySelfPickupRequest,
  updateCourierTip,
  updateLimitationByAgeChecked,
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, CardMedia, Tab, Tabs, useTheme } from '@material-ui/core';
import classNames from 'classnames';

import Icon from 'ui/common/icons/Icon';

import Typography from 'ui/common/Typography';

import { TPrepareToPlaceOrderBody } from 'api';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

import { IDeliveryPickUpTabs, THandleClickTab, TRenderLabel } from './types';

const DeliveryPickUpTabs: FC<IDeliveryPickUpTabs> = ({
  tabsProps,
  tabProps,
  onTabClick,
  orderType,
  children,
  showSideCorners,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const { t } = useTranslation();

  const [selectedTabId, setSelectedTabId] = useState(orderType);

  const renderLabel = useCallback<TRenderLabel>(
    (label, disabled) => {
      return (
        <Typography
          fontSize={16}
          fontWeight="medium"
          className={classNames({ [classes.disableLabel]: disabled })}
        >
          {label}
        </Typography>
      );
    },
    [classes],
  );

  const handleClickTab = useCallback<THandleClickTab>(
    (id, label) => (event) => {
      setSelectedTabId(id);

      if (onTabClick) {
        onTabClick(id, { label, event });
      }
    },
    [onTabClick],
  );

  const tabTemplate = useCallback(
    (tabId, showRightCorner, showLeftCorner) => {
      return (
        <Box
          className={classNames(classes.tabWrapperWithCorner, {
            [classes.removeShadow]: selectedTabId !== tabId,
          })}
        >
          {showRightCorner && (
            <CardMedia
              classes={{
                root: theme.direction === 'rtl' ? classes.cornerLeft : classes.cornerRight,
              }}
              component="img"
              src={`/assets/images/tab-round-corner-${
                selectedTabId === tabId ? 'white' : 'grey'
              }.svg`}
              style={{ zIndex: selectedTabId === tabId ? 2 : 1 }}
            />
          )}
          <Tab
            disableTouchRipple
            value={tabId}
            tabIndex={selectedTabId === tabId ? '-1' : '0'}
            label={renderLabel(`orderType.${tabId}`, selectedTabId !== tabId)}
            onClick={handleClickTab(
              tabId as TPrepareToPlaceOrderBody['orderType'],
              t(`orderType.${tabId}`),
            )}
            icon={
              <Icon
                name={`icon-${tabId === 'selfPickup' ? 'store' : 'delivery'}`}
                fontSize="small"
                classes={{
                  root: classNames(classes.icon, {
                    [classes.disableIcon]: selectedTabId !== tabId,
                  }),
                }}
              />
            }
            classes={{
              root: classes.tabRoot,
              wrapper: classes.tabWrapper,
              labelIcon: classNames(classes.tabLabelIcon, {
                [classes.disable]: selectedTabId !== tabId,
              }),
            }}
            {...tabProps}
          />
          {showLeftCorner && (
            <CardMedia
              classes={{
                root: theme.direction === 'rtl' ? classes.cornerRight : classes.cornerLeft,
              }}
              component="img"
              src={`/assets/images/tab-round-corner-${
                selectedTabId === tabId ? 'white' : 'grey'
              }.svg`}
              style={{ zIndex: selectedTabId === tabId ? 2 : 1 }}
            />
          )}
        </Box>
      );
    },
    [
      classes.cornerLeft,
      classes.cornerRight,
      classes.disable,
      classes.disableIcon,
      classes.icon,
      classes.removeShadow,
      classes.tabLabelIcon,
      classes.tabRoot,
      classes.tabWrapper,
      classes.tabWrapperWithCorner,
      handleClickTab,
      renderLabel,
      selectedTabId,
      t,
      tabProps,
      theme.direction,
    ],
  );

  const renderTabs = useMemo(() => {
    return (
      <>
        {tabTemplate('delivery', true, showSideCorners)}
        {tabTemplate('selfPickup', showSideCorners, true)}
      </>
    );
  }, [showSideCorners, tabTemplate]);

  useEffect(() => {
    setSelectedTabId(orderType);
  }, [orderType]);

  return (
    <Box className={classes.wrapper}>
      <Tabs
        value={selectedTabId}
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.flexContainer,
          indicator: classes.indicator,
          fixed: classes.tabsFixed,
        }}
        {...tabsProps}
      >
        {renderTabs}
      </Tabs>
      {children}
    </Box>
  );
};

export default DeliveryPickUpTabs;

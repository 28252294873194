import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUseCustomizedPageContent } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseCustomizedPageContent, { contactUsBackground: string }> =>
    createStyles({
      bottomImageWrapper: ({ contactUsBackground }) => ({
        backgroundImage: `url(${contactUsBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: theme.spacing(258 / 8),
        background: theme.palette.background.default,
        backgroundPosition: 'center center',
        [theme.breakpoints.up('md')]: {
          height: theme.spacing(400 / 8),
        },
      }),
    }),
);

import { addMinutes, getHours, setHours } from 'date-fns';
import { TDayOfWeekServer, TNameAndResolvedName, TServiceAreaHoursServer } from 'types';
import { TOpenHours } from 'hooks/useAboutPageContent';
import { TSocialAndContactItem } from 'ui/desktop/Footer';
import { TRenderItemsCount } from './types';

const maxCount = 99;

export const renderItemCount: TRenderItemsCount = (count) => {
  if (count) {
    return count > maxCount ? `${maxCount}+` : count;
  }
  return 0;
};

// used in available hours
export const formatAsClockNotation = (hours: number): string => {
  return String(hours).padStart(2, '0').padEnd(5, ':00');
};

// used in available dates
export const getDDMM = (date: string): string => {
  const dateArr = [...date.split('/')];
  dateArr.length = 2;

  return dateArr.join('/');
};

export const formatHourRange = (startHour: number, timeFrameDurationInMinutes: number): string => {
  return `${formatAsClockNotation(startHour)}-${formatAsClockNotation(
    getHours(addMinutes(setHours(new Date(), startHour), timeFrameDurationInMinutes)),
  )}`;
};

export const formatDeliveryDate = (
  date: string,
  openHour: number,
  timeFrameDurationInMinutes: number,
): string => {
  return `${date} ${formatHourRange(openHour, timeFrameDurationInMinutes)}`;
};

export const formatSubscriptionDate = (
  frequency: TNameAndResolvedName,
  dayOfWeek: TDayOfWeekServer,
  openHour: number,
  timeFrameDurationInMinutes: number,
): string => {
  return `${frequency.resolvedName}, ${dayOfWeek.resolvedName}, ${formatHourRange(
    openHour,
    timeFrameDurationInMinutes,
  )}`;
};

const mergeOverlappingHours = (hoursRangeArray: string[]): string[] => {
  const mergedHours: string[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < hoursRangeArray.length; i++) {
    const currentHours = hoursRangeArray[i].split('-');

    if (i < hoursRangeArray.length - 1) {
      let numberOfMergedCells = 0;
      // eslint-disable-next-line no-plusplus
      for (let j = i + 1; j < hoursRangeArray.length; j++) {
        const nextHours = hoursRangeArray[j].split('-');
        if (currentHours[1] === nextHours[0]) {
          // eslint-disable-next-line prefer-destructuring
          currentHours[1] = nextHours[1];
          numberOfMergedCells += 1;
        } else {
          break;
        }
      }

      i += numberOfMergedCells;
    }

    mergedHours.push(currentHours.join('-'));
  }

  return mergedHours;
};

export const weekdayAndOpenHoursMap = (
  storeAvailableHours: TServiceAreaHoursServer[],
): TOpenHours[] => {
  const mapOfOpenHours = new Map<number, string[]>([
    [0, []],
    [1, []],
    [2, []],
    [3, []],
    [4, []],
    [5, []],
    [6, []],
  ]);

  storeAvailableHours
    .sort((a, b) => a.openHour - b.openHour)
    .forEach((storeHours) => {
      mapOfOpenHours
        .get(storeHours.weekday === 7 ? 0 : storeHours.weekday)
        ?.push(formatHourRange(storeHours.openHour, storeHours.duration));
    });

  return Array.from(mapOfOpenHours, ([key, value]) => {
    return {
      weekday: key,
      openHours: mergeOverlappingHours(value),
    };
  });
};

export const filterSocialItems = (
  contactItems: TSocialAndContactItem[],
): TSocialAndContactItem[] => {
  return contactItems.filter(({ label }) => !label);
};

export const filterContactItems = (
  contactItems: TSocialAndContactItem[],
): TSocialAndContactItem[] => {
  return contactItems.filter(({ link, label }) => !!link.href && !!label);
};

// converts phone number e.g: 05X XXX XXXX --> 9725X XXX XXXX
export const formatInternationalPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.slice(1).padStart(12, '972');
};

export const getStringBeforeLastComma = (inputString: string): string => {
  const lastCommaIndex = inputString.lastIndexOf(',');

  if (lastCommaIndex === -1) {
    return inputString;
  }
  return inputString.substring(0, lastCommaIndex);
};

export const onlyNumber = (value: string): string | null => {
  return value.replace(/\D/g, '');
};

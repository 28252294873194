import { BaseApi } from 'api/Api/BaseApi';
import { TGetAccessibility, TGetPrivacyPolicy, TGetTermsOfUse } from './types';

export default class LegalInfoService {
  static getTermsOfUse: TGetTermsOfUse = async () => {
    return BaseApi.instance().get(`/client/public/documents/terms-of-service`);
  };

  static getAccessibility: TGetAccessibility = async () => {
    return BaseApi.instance().get(`/client/public/documents/accessibility-info`);
  };

  static getPrivacyPolicy: TGetPrivacyPolicy = async () => {
    return BaseApi.instance().get(`/client/public/documents/privacy-policy`);
  };
}

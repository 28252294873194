// Generate Weekdays with translations
export const weekDayArray = new Array(7);

weekDayArray[0] = 'days.sunday';
weekDayArray[1] = 'days.monday';
weekDayArray[2] = 'days.tuesday';
weekDayArray[3] = 'days.wednesday';
weekDayArray[4] = 'days.thursday';
weekDayArray[5] = 'days.friday';
weekDayArray[6] = 'days.saturday';

export const weekdayShortName = new Array(7);

weekdayShortName[0] = 'days.shortName.sunday';
weekdayShortName[1] = 'days.shortName.monday';
weekdayShortName[2] = 'days.shortName.tuesday';
weekdayShortName[3] = 'days.shortName.wednesday';
weekdayShortName[4] = 'days.shortName.thursday';
weekdayShortName[5] = 'days.shortName.friday';
weekdayShortName[6] = 'days.shortName.saturday';

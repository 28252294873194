import React, { useMemo } from 'react';
import { CardMedia } from '@material-ui/core';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import Link from 'ui/common/Link';
import useStyles from './styles';

import { TRenderPoweredBy, TUsePoweredBy } from './types';

const usePoweredBy: TUsePoweredBy = (isMobile = false) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderPoweredBy = useMemo<TRenderPoweredBy>(
    () =>
      (companyPoweredLogoUrl = '') => {
        return (
          <Link
            className={classNames(classes.poweredSection, { [classes.mobile]: isMobile })}
            href="https://rexail.co.il/"
            targetBlank
          >
            <CardMedia
              component="img"
              classes={{ root: classes.poweredLogo }}
              image={companyPoweredLogoUrl}
              alt={t('rexail.brandName')}
            />
          </Link>
        );
      },
    [classes.mobile, classes.poweredLogo, classes.poweredSection, isMobile, t],
  );

  return { renderPoweredBy };
};

export default usePoweredBy;

import { CONFIG_UPDATE } from './constants';

import { TConfigReducer } from './types';

import { initialConfigState } from './initialState';

const configReducer: TConfigReducer = (state = initialConfigState, action) => {
  switch (action.type) {
    case CONFIG_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { configReducer };

import { TConfigReducerState } from './types';

export const initialConfigState: TConfigReducerState = {
  dir: 'rtl', // initial direction
  lang: 'he',
  isMobile: false,
  isIOS: false,
  isPWAInstallDismissed: false,
  isPWAInstalled: false,
  fullCatalogLoaded: false,
};

import React, { FC, MouseEventHandler, useCallback } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';

import useStyles from './styles';

import { IDeliveryPickUpPopUp } from './types';

const DeliveryPickUpPopUp: FC<IDeliveryPickUpPopUp> = ({
  label = 'orderType.delivery',
  withoutDeliveryTimeText = 'deliveryPickUp.withoutDeliveryTimeText',
  withoutDeliveryTime,
  editClick,
  deliveryAddress,
  deliveryTime,
  onDeliveryTimeClick,
  onClose,
}) => {
  const classes = useStyles();

  const handleEditClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (editClick) editClick(event);
      if (onClose) onClose();
    },
    [editClick, onClose],
  );

  const handleDeliveryTimeClick = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      if (onDeliveryTimeClick) onDeliveryTimeClick(event);
      if (onClose) onClose();
    },
    [onClose, onDeliveryTimeClick],
  );

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Box className={classes.labelWrapper}>
          <Typography variant="body2" fontSize={16} fontWeight="bold" color="secondary">
            {label}
          </Typography>
          <Box className={classes.iconSection}>
            <IconButton size="small" onClick={handleEditClick}>
              <Icon name="icon-edit" color="primary" classes={{ root: classes.iconRoot }} />
            </IconButton>
          </Box>
        </Box>
        {deliveryAddress && (
          <Typography isTranslate={false} color="textSecondary" className={classes.deliveryText}>
            {deliveryAddress}
          </Typography>
        )}
        {!withoutDeliveryTime && (
          <Typography isTranslate={false} color="textSecondary" className={classes.deliveryText}>
            {deliveryTime}
          </Typography>
        )}
        {withoutDeliveryTime && (
          <Button
            variant="text"
            onClick={handleDeliveryTimeClick}
            className={classes.withoutDeliveryTimeBtn}
          >
            <Typography
              className={classes.withoutDeliveryTime}
              color="primary"
              fontWeight="medium"
              isTranslate={false}
            >
              {withoutDeliveryTimeText}
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DeliveryPickUpPopUp;

import React, { ReactNode, useMemo } from 'react';

import { forEach, replace } from 'lodash';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import Typography from 'ui/common/Typography';

import { TRenderOptionText, TRenderTitle, TUseAutocompleteOptionTitle } from './types';

const SELECTED_CHARACTER = '%';

const useAutocompleteOptionTitle: TUseAutocompleteOptionTitle = () => {
  const renderTitle = useMemo<TRenderTitle>(
    () => (inputStr, value) => {
      const arrayItems = inputStr.split(' ').map((itemStr: string) => {
        // TODO: need to trim value
        const searchValue = value?.toLocaleLowerCase() || '';

        const strLowerCase = itemStr.toLocaleLowerCase();

        if (value && typeof value === 'string' && strLowerCase.includes(searchValue)) {
          const regex = new RegExp(value, 'gi');

          const q = replace(itemStr, regex, (a) => {
            return `${SELECTED_CHARACTER}${a}${SELECTED_CHARACTER}`;
          });

          const node = q
            .split(SELECTED_CHARACTER)
            .filter((el) => el)
            .map((item, index) => {
              const itemLowerCase = item.toLocaleLowerCase();

              if (itemLowerCase.includes(searchValue)) {
                return (
                  <Typography
                    key={index}
                    isTranslate={false}
                    fontSize={16}
                    component="span"
                    fontWeight="medium"
                  >
                    {item}
                  </Typography>
                );
              }
              return item;
            });

          return node;
        }
        return itemStr;
      });

      const arrayItemsWithSpaces: Array<string | ReactNode> = [];
      forEach(arrayItems, (el, index) => {
        if (Number(index) <= arrayItems.length - 1) {
          arrayItemsWithSpaces.push(el, ' ');
        } else {
          return el;
        }
      });
      return arrayItemsWithSpaces;
    },
    [],
  );

  const renderOptionText = useMemo<TRenderOptionText>(
    () => (inputStr, value) => {
      const matches = match(value, inputStr);
      const parts = parse(value, matches);

      return parts.map(({ text, highlight }, index) => {
        if (highlight) {
          return (
            <Typography
              key={index}
              isTranslate={false}
              fontSize={16}
              component="span"
              fontWeight="medium"
            >
              {text}
            </Typography>
          );
        }
        return text;
      });
    },
    [],
  );

  return { renderTitle, renderOptionText };
};

export default useAutocompleteOptionTitle;

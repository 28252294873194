const UPDATE_ORDER_MODE = 'orderDetails/UPDATE_ORDER_MODE';
const UPDATE_ORDER_DETAILS = 'orderDetails/UPDATE_ORDER_DETAILS';
const OPEN_DELIVERY_SELF_PICKUP_REQUEST = 'orderDetails/OPEN_DELIVERY_SELF_PICKUP_REQUEST';
const UPDATE_COURIER_TIP = 'orderDetails/UPDATE_COURIER_TIP';
const UPDATE_LIMITATION_BY_AGE_CHECKED = 'orderDetails/UPDATE_LIMITATION_BY_AGE_CHECKED';
const SHARE_CART_ID_UPDATE = 'auth/SHARE_CART_ID_UPDATE';

export {
  UPDATE_ORDER_MODE,
  UPDATE_ORDER_DETAILS,
  OPEN_DELIVERY_SELF_PICKUP_REQUEST,
  UPDATE_COURIER_TIP,
  UPDATE_LIMITATION_BY_AGE_CHECKED,
  SHARE_CART_ID_UPDATE,
};

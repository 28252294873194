import React, { FC } from 'react';

import { Rating as MUIRating } from '@material-ui/lab';

import useStyles from './styles';
import { IRating } from './types';

const Rating: FC<IRating> = ({ value }) => {
  const classes = useStyles();

  return (
    <MUIRating
      classes={{ root: classes.root, icon: classes.icon, iconEmpty: classes.iconEmpty }}
      max={3}
      value={value}
      readOnly
    />
  );
};

export default Rating;

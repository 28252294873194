import React, { FC, ReactNode, useMemo } from 'react';

import { FormControlLabel } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import CheckBox from '../CheckBox';

import useStyles from './styles';

import { ICheckBoxWithLabel } from './types';

const CheckBoxWithLabel: FC<ICheckBoxWithLabel> = ({
  checkBoxSize,
  checkBoxProps,
  classes: overrideClasses,
  label,
  isTranslate = true,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderLabel = useMemo<ReactNode>(() => {
    // check if we need to translate label
    return typeof label === 'string' && isTranslate ? t(label) : label;
  }, [label, isTranslate, t]);

  return (
    <FormControlLabel
      classes={{ label: classes.label, root: classes.root, ...overrideClasses }}
      control={<CheckBox size={checkBoxSize} {...checkBoxProps} />}
      label={renderLabel}
      {...rest}
    />
  );
};

export default CheckBoxWithLabel;

import { BaseApi } from 'api/Api';
import { TGetPromotions } from './types';

export default class PromotionService {
  static getPromotions: TGetPromotions = async (websiteJWE, xForwardedIpAddress) => {
    return BaseApi.instance().get(`/client/public/store/promotions`, {
      headers: {
        ...(websiteJWE ? { 'X-Website': websiteJWE } : {}),
        ...(xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {}),
      },
    });
  };
}

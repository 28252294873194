import React, { FC } from 'react';

import AccessibilityStatementContent from 'components/common/AccessibilityStatementContent';
import { IAccessibilityDialog } from './types';

const AccessibilityDialog: FC<IAccessibilityDialog> = ({ backToText, onBackClick }) => {
  return <AccessibilityStatementContent onBackClick={onBackClick} backToText={backToText} />;
};

export default AccessibilityDialog;

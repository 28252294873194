import { stringify } from 'qs';

import { BaseApi } from 'api/Api';
import { TGetDeliveringToTheAddress, TGetStoreServiceArea, TGetWebsiteDetails } from './types';

export default class StoreService {
  static getWebsiteDetails: TGetWebsiteDetails = async (params, xForwardedIpAddress) => {
    return BaseApi.instance().get(`/client/public/store/website?${stringify(params)}`, {
      headers: xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {},
    });
  };

  static getDeliveringToTheAddress: TGetDeliveringToTheAddress = async (body, config) => {
    return BaseApi.instance(config).post(`/client/public/is-available-at`, body);
  };

  static getStoreServiceAreas: TGetStoreServiceArea = async (xForwardedIpAddress) => {
    return BaseApi.instance().get(`/client/public/store/service-areas`, {
      headers: xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {},
    });
  };
}

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesSelfPickupOption } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSelfPickupOption, { isAreaSelected: boolean }> =>
    createStyles({
      storeIcon: ({ isAreaSelected }) => ({
        color: isAreaSelected ? theme.palette.primary.main : theme.palette.text.secondary,
        fontSize: theme.spacing(18 / 8),
        overflow: 'initial',
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(38 / 8),
        },
      }),
      pickupOptionBtn: {
        width: '100%',
        lineHeight: 'unset',
        padding: 0,
        cursor: 'default',
        textAlign: 'unset',
      },
      serviceAreaName: ({ isAreaSelected }) => ({
        lineHeight: 1.25,
        fontWeight: isAreaSelected ? 500 : 'initial',
      }),
      distance: {
        lineHeight: 1.15,
      },
      choosePickupOption: {
        width: '100%',
        marginTop: '20px',
      },
    }),
);

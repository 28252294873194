import {
  ADD_OR_REMOVE_PROMOTIONS_CATEGORY,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
} from './constants';

import {
  TAddOrRemovePromotionsCategoryAction,
  TGetCategoriesRequestAction,
  TGetCategoriesSuccessAction,
} from './types';

const getCategoriesRequestAction: TGetCategoriesRequestAction = (categoriesData) => ({
  type: GET_CATEGORIES_REQUEST,
  payload: {
    categoriesData,
  },
});

const getCategoriesSuccessAction: TGetCategoriesSuccessAction = (
  newCategoryIds,
  newCategoryById,
) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: {
    categoryIds: newCategoryIds,
    categoryById: newCategoryById,
  },
});

const addOrRemovePromotionsCategoryAction: TAddOrRemovePromotionsCategoryAction = () => ({
  type: ADD_OR_REMOVE_PROMOTIONS_CATEGORY,
});

export {
  getCategoriesRequestAction,
  getCategoriesSuccessAction,
  addOrRemovePromotionsCategoryAction,
};

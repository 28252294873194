import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSkeletonDeliveryOrSelfPickupAvailableHours } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSkeletonDeliveryOrSelfPickupAvailableHours> =>
    createStyles({
      desktopWrapper: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
      mobileWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      skeletonLoader: {
        width: '40%',
        height: theme.spacing(55 / 8),
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(130 / 8),
        },
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesBasicCard } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesBasicCard, { isPopup?: boolean; imageSize?: number }> =>
    createStyles({
      basicCardWrapper: {
        width: '100%',
        height: 'inherit',
        [theme.breakpoints.up('md')]: {
          width: 'inherit',
        },
      },
      root: {
        position: 'relative',
        width: 'inherit',
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        overflow: 'visible',
        boxShadow: 'none',
        borderRadius: theme.spacing(8 / 8),
      },
      cardContentRoot: {
        padding: 0,
      },
      productType: {
        position: 'absolute',
        top: 12,
        left: 12,
        zIndex: 1,
      },
      tagSale: {
        zIndex: 2,
        position: 'absolute',
        top: '-6px',
        right: 12,
      },
      thumbnailWrapper: {
        padding: 0,
        minWidth: 'unset',
        '&:not(:last-child)': {
          marginRight: '14px',
        },
      },
      thumbnailImage: ({ isPopup }) => ({
        width: isPopup ? '54px' : '68.8px',
        height: isPopup ? '54px' : '68.8px',
        borderRadius: '8px',
      }),
      activeThumbnailImage: ({ isPopup }) => ({
        width: isPopup ? '54px' : '68.8px',
        height: isPopup ? '54px' : '68.8px',
        borderRadius: '8px',
        outline: `solid 1.5px ${theme.palette.primary.main}`,
      }),
      mobileImage: ({ imageSize }) => ({
        width: theme.spacing((imageSize || 270) / 8),
        margin: '0 auto',
        borderRadius: '6px',
      }),
    }),
);

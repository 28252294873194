import { Button, IconButton, useTheme } from '@material-ui/core';

import { GoogleMap, MarkerF, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { useMobile } from 'hooks';

import { useNotify } from 'hooks/useNotify';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

import Icon from 'ui/common/icons/Icon';
import { GOOGLE_MAP_OPTIONS } from './constants';

import useStyles from './styles';

import { IMap } from './types';

const Map: FC<IMap> = ({
  initialPosition,
  markerPosition,
  pointers,
  pointersIcon = 'icon-location-on-map',
  pointersColor,
  pointersFontSize,
  mainPointerIcon = 'icon-location-on-map',
  mainPointerColor,
  mainPointerFontSize,
  containerStyle,
  isValid,
  isTouched,
  customZoom = 10,
  onLocationChange,
  onDragEnd,
  onZoomChanged,
  onPointerClick,
  selectedPointerIndex,
  showUserLocation,
}) => {
  const classes = useStyles({
    isValid,
    isTouched,
    mainPointerColor,
    mainPointerFontSize,
    pointersColor,
    pointersFontSize,
  });
  const { notifyShow } = useNotify();
  const theme = useTheme();
  const { t } = useTranslation();

  const { isMobile } = useMobile();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [userCurrentLocation, setUserCurrentLocation] = useState<google.maps.LatLngLiteral | null>(
    null,
  );
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState<boolean>(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
    libraries: ['geometry', 'drawing'],
  });

  const onLoad = useCallback((loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  }, []);

  const onMapLocationChange = useCallback(() => {
    onLocationChange(map?.getCenter()?.toJSON());
  }, [map, onLocationChange]);

  const getCurrentLocation = useCallback(() => {
    setLoadingCurrentLocation(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (geolocationPosition: GeolocationPosition) => {
          const position = {
            lat: geolocationPosition.coords.latitude,
            lng: geolocationPosition.coords.longitude,
          };

          setUserCurrentLocation(position);
          map?.setZoom(17);
          setLoadingCurrentLocation(false);
        },
        () => {
          setLoadingCurrentLocation(false);
          notifyShow({
            message: 'map.navigatorNotSupported',
          });
        },
      );
    } else {
      setLoadingCurrentLocation(false);
      notifyShow({
        message: 'map.navigatorNotSupported',
      });
    }
  }, [map, notifyShow]);

  useEffect(() => {
    map?.setZoom(customZoom);
  }, [initialPosition, customZoom, map]);

  const pointerStyles = useCallback(
    (i): React.CSSProperties => {
      const size = (selectedPointerIndex === i ? 47 : pointersFontSize || 30) / 8;

      const location =
        (selectedPointerIndex === i ? -47 / 2 : pointersFontSize && -pointersFontSize / 2) ||
        '15px';

      return {
        color:
          selectedPointerIndex !== i && pointersColor ? pointersColor : theme.palette.primary.main,
        fontSize: theme.spacing(size),
        height: theme.spacing(size),
        width: theme.spacing(size),
        top: location,
        right: theme.direction === 'rtl' ? location : 0,
        left: theme.direction === 'rtl' ? 0 : location,
        zIndex: selectedPointerIndex === i ? 2 : 1,
      };
    },
    [pointersColor, pointersFontSize, selectedPointerIndex, theme],
  );

  const changeCenterOfMapBySelectedMarkerPosition = useCallback(() => {
    if (map !== null && pointers && selectedPointerIndex !== undefined) {
      const panToPosition = {
        lat: pointers[selectedPointerIndex].lat,
        lng: pointers[selectedPointerIndex].lng,
      };
      map.setCenter(panToPosition);
      map.panTo(panToPosition);
    }
  }, [map, pointers, selectedPointerIndex]);

  useEffect(() => {
    if (selectedPointerIndex === undefined) return;
    changeCenterOfMapBySelectedMarkerPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPointerIndex]);

  return (
    <>
      {isLoaded && (
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={containerStyle}
          center={userCurrentLocation || initialPosition}
          zoom={customZoom}
          options={{ ...GOOGLE_MAP_OPTIONS, gestureHandling: isMobile ? 'greedy' : '' }}
          onCenterChanged={onMapLocationChange}
          onZoomChanged={() => {
            if (!onZoomChanged) return;

            const currentMarkerPosition = map?.getCenter()?.toJSON();
            if (
              initialPosition.lat !== currentMarkerPosition?.lat ||
              initialPosition.lng !== currentMarkerPosition?.lng
            ) {
              onMapLocationChange();
              onZoomChanged();
            }
          }}
          onDragEnd={onDragEnd}
        >
          {loadingCurrentLocation ? (
            <IconButton
              onClick={getCurrentLocation}
              className={classes.crosshairWrapper}
              aria-label={t('map.getLocationFromBrowser')}
            >
              <ClipLoader color={theme.palette.text.secondary} size={15} />
            </IconButton>
          ) : (
            <IconButton
              onClick={getCurrentLocation}
              className={classes.crosshairWrapper}
              aria-label={t('map.getLocationFromBrowser')}
            >
              <Icon name="icon-current-location" classes={{ root: classes.crosshair }} />
            </IconButton>
          )}

          {userCurrentLocation && showUserLocation && (
            <MarkerF
              position={userCurrentLocation}
              icon="/assets/images/pointer-current-location.svg"
            />
          )}

          {markerPosition && (
            <OverlayView position={markerPosition} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <Icon name={mainPointerIcon} classes={{ root: classes.locationIcon }} />
            </OverlayView>
          )}

          {pointers &&
            pointers.map((pointer, i) => {
              const styles = pointerStyles(i);
              return (
                <OverlayView
                  position={pointer}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  key={i}
                >
                  <Button onClick={() => onPointerClick && onPointerClick(i)} disableRipple>
                    <Icon name={pointersIcon} classes={{ root: classes.pointer }} style={styles} />
                  </Button>
                </OverlayView>
              );
            })}
        </GoogleMap>
      )}
    </>
  );
};

export default Map;

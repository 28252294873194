import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesCustom404Content } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCustom404Content> =>
    createStyles({
      root: {
        height: '100%',
        [theme.breakpoints.up('md')]: {
          height: 'calc(100vh - 420px)',
        },
      },
      wrapper: {
        height: '100%',
        minHeight: 570,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(-30 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
          minHeight: 520,
          height: '100%',
          width: '100%',
          margin: '0 auto',
          flexDirection: 'row-reverse',
          padding: theme.spacing(0, 30 / 8),
          maxWidth: 940,
        },
      },
      centerImageWrapper: {
        maxWidth: 172,
        height: 190,
        marginLeft: theme.spacing(theme.direction === 'rtl' ? -48 / 8 : -40 / 8),
        marginRight: theme.spacing(theme.direction === 'rtl' ? -40 / 8 : -48 / 8),
        [theme.breakpoints.up('md')]: {
          maxWidth: 'unset',
          height: 320,
          width: 288,
          marginLeft: theme.spacing(theme.direction === 'rtl' ? -80 / 8 : -66 / 8),
          marginRight: theme.spacing(theme.direction === 'rtl' ? -66 / 8 : -80 / 8),
        },
      },
      imagesWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: 260,
        [theme.breakpoints.up('md')]: {
          maxWidth: 434,
        },
      },
      textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexGrow: 0,
          width: '100%',
          maxWidth: 322,
          height: 200,
          marginRight: 'auto',
          alignItems: 'start',
        },
      },
      pageNotFoundImage: {
        objectFit: 'cover',
        overflow: 'visible',
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('md')]: {
          marginBottom: 0,
        },
      },
      titleText: {
        marginBottom: theme.spacing(10 / 8),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(16 / 8),
          fontSize: '57px',
          lineHeight: '54px',
        },
      },
      subtitleText: {
        textAlign: 'center',
        padding: theme.spacing(0, 64 / 8),
        lineHeight: '24px',
        marginBottom: theme.spacing(30 / 8),
        [theme.breakpoints.up('md')]: {
          padding: 0,
          width: 'unset',
          textAlign: 'start',
          fontSize: '20px',
        },
      },
      iconNumber: {
        overflow: 'visible',
        fontSize: '120px',
        width: 88,
        [theme.breakpoints.up('md')]: {
          width: 'unset',
          fontSize: '200px',
          maxWidth: 146,
        },
      },
      categoriesWrapper: {
        position: 'sticky',
        top: 56,
        zIndex: 4,
        background: theme.palette.background.paper,
        boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.06)',
        [theme.breakpoints.up('md')]: {
          top: 80,
        },
      },
      CTAButtonWrapper: {
        width: 232,
        [theme.breakpoints.up('md')]: {
          width: 220,
          marginBottom: 0,
          marginTop: 'auto',
        },
      },
    }),
);

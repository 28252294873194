import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { IStoreProductDisplay, storeProductSelectors } from 'store';

import { TFilterSubCategory, TGetSortedProducts, useProduct } from 'hooks';

import useProductsSort from 'hooks/useProductsSort';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import { TProductCategoryServer } from 'types';
import { isServer } from 'utils/helpers/isServer';
import { TUseProducts } from './types';

const useProducts: TUseProducts = (selectedSortOrder, activeCategory, searchQuery) => {
  const { storeProductIds, storeProductById } = useSelector(
    storeProductSelectors.storeProductsData,
  );

  const { sortStoreProducts } = useProductsSort();

  const { getDisplayableStoreProduct } = useProduct();

  const { renderLanguageField } = useMultiLanguage();

  const [subCategoryOrder, setSubCategoryOrder] = useState<number | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<TProductCategoryServer | null>(
    null,
  );

  const handleSubCategorySort = useCallback((newSubCategory, newSubCategoryOrder) => {
    setSubCategoryOrder(newSubCategory);
    setSelectedSubCategory(newSubCategoryOrder);
  }, []);

  const resetSubCategoryAndSubCategoryOrder = useCallback(() => {
    setSubCategoryOrder(null);
    setSelectedSubCategory(null);
  }, []);

  const filterSubCategory = useCallback<TFilterSubCategory>(
    (previousState) => {
      if (previousState && selectedSubCategory && subCategoryOrder) {
        const storeProducts = [...previousState];
        const productsUnderSelectedSubCategory = storeProducts.filter(
          (product) => product.productCategory.id === selectedSubCategory.id,
        );
        const sortedSubCategoryProducts = sortStoreProducts(
          productsUnderSelectedSubCategory as IStoreProductDisplay[],
          subCategoryOrder,
        );
        let index = 0;
        for (let i = 0; i < storeProducts.length; i += 1) {
          if (storeProducts[i].productCategory.id === selectedSubCategory.id) {
            storeProducts[i] = sortedSubCategoryProducts[index];
            index += 1;
          }
        }
        return storeProducts;
      }

      return previousState;
    },
    [selectedSubCategory, sortStoreProducts, subCategoryOrder],
  );

  const getSortedProducts = useCallback<TGetSortedProducts>(
    (previousSortedProducts) => {
      let filteredIds = storeProductIds;

      if (searchQuery && !!searchQuery.trim() && searchQuery.length) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        const searchWords = lowerCaseSearchQuery.split(' ').filter((word) => word);

        filteredIds = filteredIds.filter((storeProductId) => {
          const storeProduct = storeProductById[storeProductId];
          return searchWords.some(
            (word) =>
              storeProduct.fullName.toLowerCase().includes(word) ||
              renderLanguageField(storeProduct.product?.multiLang, 'name')
                .toLowerCase()
                .includes(word) ||
              storeProduct.productExtraDetails?.toLowerCase().includes(word) ||
              storeProduct.supplier?.name.toLowerCase().includes(word),
          );
        });
      } else if (activeCategory?.id !== -1) {
        if (subCategoryOrder && selectedSubCategory) {
          return filterSubCategory(previousSortedProducts);
        }

        filteredIds = filteredIds.filter((storeProductId) => {
          if (activeCategory?.id === 0) {
            return (
              storeProductById[storeProductId].promoted ||
              storeProductById[storeProductId].promotion
            );
          }

          if (activeCategory?.subMenuItems) {
            return !!activeCategory.subMenuItems.find(
              (subMenuItem) =>
                subMenuItem.id === storeProductById[storeProductId].productCategory.id,
            );
          }

          return storeProductById[storeProductId].productCategory.id === activeCategory?.id;
        });
      }

      const filteredStoreProducts = filteredIds.map((id) =>
        getDisplayableStoreProduct(id),
      ) as IStoreProductDisplay[];

      // TODO revise, for now ok

      return sortStoreProducts(filteredStoreProducts as IStoreProductDisplay[], selectedSortOrder);
    },
    [
      activeCategory?.id,
      activeCategory?.subMenuItems,
      filterSubCategory,
      getDisplayableStoreProduct,
      renderLanguageField,
      searchQuery,
      selectedSortOrder,
      selectedSubCategory,
      sortStoreProducts,
      storeProductById,
      storeProductIds,
      subCategoryOrder,
    ],
  );

  const [sortedProducts, setSortedProducts] = useState<IStoreProductDisplay[] | null>(() =>
    isServer() ? getSortedProducts(null) : null,
  );

  useEffect(() => {
    setSortedProducts((previousSortedProducts) => getSortedProducts(previousSortedProducts));
  }, [
    getSortedProducts,
    activeCategory,
    filterSubCategory,
    getDisplayableStoreProduct,
    renderLanguageField,
    searchQuery,
    selectedSortOrder,
    selectedSubCategory,
    sortStoreProducts,
    storeProductById,
    storeProductIds,
    subCategoryOrder,
  ]);

  return {
    sortedProducts,
    handleSubCategorySort,
    resetSubCategoryAndSubCategoryOrder,
  };
};

export default useProducts;

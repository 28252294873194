// fonts
import '@fontsource/heebo';
import '@fontsource/heebo/400.css'; // Regular
import '@fontsource/heebo/500.css'; // Medium
import '@fontsource/heebo/600.css'; // Semi Bold
import '@fontsource/heebo/700.css'; // Bold
import '@fontsource/heebo/hebrew.css';
import '@fontsource/heebo/latin.css';
import '@fontsource/roboto';
import '@fontsource/roboto/400.css'; // Regular
import '@fontsource/roboto/500.css'; // Medium
import '@fontsource/roboto/700.css'; // Bold
import '@fontsource/roboto/latin.css';
import '@fontsource/rubik';
import '@fontsource/rubik/400.css'; // Regular
import '@fontsource/rubik/500.css'; // Medium
import '@fontsource/rubik/600.css'; // Semi Bold
import '@fontsource/rubik/700.css'; // Bold
import '@fontsource/rubik/hebrew.css';
import '@fontsource/rubik/latin-ext.css';
import { CssBaseline, jssPreset, StylesProvider, Theme, ThemeProvider } from '@material-ui/core';

import { PersonalDetailsService } from 'api';
import BaseSEOTags from 'components/common/seo/BaseSEOTags';
import { differenceInMilliseconds } from 'date-fns';
import { useCategoryMenu, useWebsiteDetails } from 'hooks';
import { useAnalytics } from 'hooks/useAnalytics';

import createI18n from 'i18n';
import { i18n } from 'i18next';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { I18nextProvider } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  authActions,
  authSelectors,
  catalogActions,
  orderActions,
  orderSelectors,
  shoppingFlowSelectors,
  storeProductSelectors,
  userActions,
  userSelectors,
} from 'store';
import { deviceIdGenerateAction } from 'store/modules/auth/actions';
import {
  getCatalogId,
  getCatalogLastFetchTime,
  getLocalStorageCatalogLastUpdateTime,
  getSSRCatalogLastUpdateTime,
} from 'store/modules/catalog/selectors';
import { configUpdate } from 'store/modules/config/actions';
import { getDirection, getFullCatalogLoaded, getLang } from 'store/modules/config/selectors';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import { routerHistoryUpdate } from 'store/modules/routerHistory/actions';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';
import {
  fetchAndUpdateClientCompensationsRequest,
  fetchAndUpdateClubMembershipProfileAction,
} from 'store/modules/user/actions';
import { getClientDetailsAtStoreLevelWereUpdated } from 'store/modules/user/selectors';
import { createTheme } from 'theme';
import { fetchAndSetPromotions } from 'utils/helpers/catalog';
import { filterItemsOutOfStock } from 'utils/helpers/order/items';
import { IAppContainer } from './types';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const shoppingPages = ['/', '/category/[id]/[slug]', '/all-products']; // TODO add search

const AppContainer: FC<IAppContainer> = ({ children }) => {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const orderMode = useSelector(getOrderMode);
  const lang = useSelector(getLang);
  const direction = useSelector(getDirection);
  const fullCatalogLoaded = useSelector(getFullCatalogLoaded);
  const order = useSelector(orderSelectors.orderData);
  const ssrCatalogLastUpdateTime = useSelector(getSSRCatalogLastUpdateTime);
  const localStorageCatalogLastUpdateTime = useSelector(getLocalStorageCatalogLastUpdateTime);
  const catalogId = useSelector(getCatalogId);
  const isMinimumProfileData = useSelector(userSelectors.isMinimumProfileDataCompleted);
  const { storeProductById, storeProductIds } = useSelector(
    storeProductSelectors.storeProductsData,
  );

  const isIntroductoryPopupsCompleted = useSelector(
    shoppingFlowSelectors.getIntroductoryPopupsCompleted,
  );
  const catalogLastFetchTime = useSelector(getCatalogLastFetchTime);
  const clientDetailsAtStoreLevelWereUpdated = useSelector(getClientDetailsAtStoreLevelWereUpdated);
  const [isFetchingNewerCatalog, setIsFetchingNewerCatalog] = useState(false);

  const websiteDetails = useWebsiteDetails();
  const dispatch = useDispatch();
  const router = useRouter();
  useAnalytics();
  const { activeMenuItem } = useCategoryMenu();

  const getAndSetPromotions = useCallback(async () => {
    await fetchAndSetPromotions(dispatch, websiteDetails);
    await dispatch(configUpdate({ fullCatalogLoaded: true }));
  }, [dispatch, websiteDetails]);

  const getAndSetCatalogAndPromotions = useCallback(() => {
    dispatch(
      catalogActions.fetchCatalogBySubCatalogId(
        catalogId,
        {
          websiteJWE: websiteDetails.jsonWebEncryption,
          catalogId,
        },
        () => {
          getAndSetPromotions();
          setIsFetchingNewerCatalog(false);
        },
      ),
    );
  }, [catalogId, dispatch, getAndSetPromotions, websiteDetails.jsonWebEncryption]);

  const getAndSetRelevantCatalog = useCallback(async () => {
    // in multiple branches, ssrCatalogLastUpdateTime will refer to main website catalog and localStorageCatalogLastUpdateTime might refer to a specific sub catalog (area or user). To be aware...
    const shouldFetchNewCatalog =
      localStorageCatalogLastUpdateTime !== ssrCatalogLastUpdateTime ||
      storeProductIds.length < 100;

    if (shouldFetchNewCatalog) {
      setIsFetchingNewerCatalog(true);
      return;
    }
    // fetch promotions for catalog from localStorage
    void getAndSetPromotions();
  }, [
    getAndSetPromotions,
    localStorageCatalogLastUpdateTime,
    ssrCatalogLastUpdateTime,
    storeProductIds.length,
  ]);

  const renderI18n = useMemo<i18n>(() => {
    return createI18n(lang);
  }, [lang]);

  const renderTheme = useMemo<Theme>(() => {
    return createTheme(websiteDetails.theme.palette, direction);
  }, [direction, websiteDetails.theme.palette]);

  useEffect(() => {
    if (isLoggedIn && isMinimumProfileData) {
      dispatch(
        userActions.fetchAndUpdateClientAndAreaSubCatalogRequest(() =>
          dispatch(fetchAndUpdateClubMembershipProfileAction()),
        ),
      );
    }
  }, [dispatch, isLoggedIn, isMinimumProfileData]);

  useEffect(() => {
    if (shoppingPages.includes(router.pathname)) {
      const lastHoursOfUpdatingCatalog = Math.floor(
        differenceInMilliseconds(new Date().getTime(), catalogLastFetchTime) / (1000 * 60 * 60),
      );

      if (lastHoursOfUpdatingCatalog >= 6) {
        setIsFetchingNewerCatalog(true);
      }
    }

    // don't put catalogLastFetchTime to the dependency it will cause re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    if (!shoppingPages.includes(router.pathname) || websiteDetails.websiteSettings.isSmallEC) {
      if ((isLoggedIn && clientDetailsAtStoreLevelWereUpdated) || !isLoggedIn) {
        dispatch(introductoryPopupsCompletedAction(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetailsAtStoreLevelWereUpdated]);

  useEffect(() => {
    if (isIntroductoryPopupsCompleted) {
      dispatch(
        catalogActions.checkAndFetchBranchCatalogIfNecessary(() =>
          dispatch(introductoryPopupsCompletedAction(false)),
        ),
      );
    }
  }, [dispatch, isIntroductoryPopupsCompleted]);

  useEffect(() => {
    // change body dir to real dir
    document.body.setAttribute('dir', direction);
  }, [direction]);

  useEffect(() => {
    if (isFetchingNewerCatalog) {
      getAndSetCatalogAndPromotions();
    }
  }, [getAndSetCatalogAndPromotions, isFetchingNewerCatalog]);

  useEffect(() => {
    if (!fullCatalogLoaded) {
      void getAndSetRelevantCatalog();
    }

    // We want to run it only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCatalogLoaded]);

  // main useEffect that deals with items to be put in the cart (items from storage)
  useEffect(() => {
    if (fullCatalogLoaded && !isFetchingNewerCatalog && order.items.length) {
      const storageFilteredItems = filterItemsOutOfStock(order.items, storeProductById);
      if (orderMode !== 'edit') {
        const updatedOrderItems = storageFilteredItems.itemsInStock.map((orderItem) => ({
          ...orderItem,
          storeProduct: {
            ...storeProductById[orderItem.storeProduct.id],
          },
        }));
        dispatch(orderActions.setOrderItemsSuccessAction(updatedOrderItems));
      }
    }
    // we are not running this effect on subsequent changes to any state variable except "fullCatalogLoaded" and "isFetchingNewerCatalog" (this is intentional, we want just the initial state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCatalogLoaded, isFetchingNewerCatalog]);

  useEffect(() => {
    dispatch(deviceIdGenerateAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      PersonalDetailsService.getPersonalDetails().then((personalDetailsResult) => {
        const personalDetails = personalDetailsResult.data;

        const isMinimumProfileDataCompleted =
          !!personalDetails.cellPhone &&
          !!personalDetails.firstName &&
          !!personalDetails.lastName &&
          !!personalDetails.email;

        if (isMinimumProfileDataCompleted) {
          dispatch(userActions.updateProfile(personalDetails));

          dispatch(fetchAndUpdateClientCompensationsRequest());
          return;
        }
        dispatch(authActions.logOutRequest());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      dispatch(routerHistoryUpdate(url));
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [dispatch, router.events]);

  return (
    <>
      <I18nextProvider i18n={renderI18n}>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={renderTheme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <BaseSEOTags activeCategoryMenuItem={activeMenuItem} />
            <CssBaseline />
            {children}
          </ThemeProvider>
        </StylesProvider>
      </I18nextProvider>
    </>
  );
};

export default AppContainer;

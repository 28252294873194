import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { isToday, isTomorrow, parse } from 'date-fns';

import { find } from 'lodash';

import { Box, Button, Divider, Grid } from '@material-ui/core';

import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';

import { formatHourRange, getDDMM } from 'utils';

import { useAvailableHours, useMobile } from 'hooks';

import { isSubscriptionMode } from 'utils/helpers/subscriptions';

import { TPrepareToPlaceOrderBody } from 'api';

import { useSelector } from 'react-redux';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import useStyles from '../../styles';

import {
  IAvailableHours,
  THandleClickDateMobile,
  THandleClickHour,
  TRenderHoursRow,
} from './types';

const AvailableHours: FC<IAvailableHours> = ({
  availableAreas,
  onSubmit,
  isSubmitting,
  initialLimitAvailableHours = 3,
  isDialog = true,
  gridSize = { xs: 6, sm: 4, md: 4 },
}) => {
  const classes = useStyles({ isDialog });

  const { isMobile } = useMobile();

  const { initialTimeFrame, getLimitAvailableHours } = useAvailableHours();

  const orderMode = useSelector(getOrderMode);

  const [timeFrame, setTimeFrame] = useState<TPrepareToPlaceOrderBody['timeFrame']>();

  const [limitAvailableHours, setLimitAvailableHours] = useState(initialLimitAvailableHours);

  const shouldShowToggle = useMemo(() => {
    return !isDialog && availableAreas.length > limitAvailableHours;
  }, [availableAreas, isDialog, limitAvailableHours]);

  useEffect(() => {
    if (shouldShowToggle) {
      setLimitAvailableHours(
        getLimitAvailableHours(availableAreas, initialLimitAvailableHours) ||
          initialLimitAvailableHours,
      );
    }
  }, [availableAreas, shouldShowToggle, getLimitAvailableHours, initialLimitAvailableHours]);

  const handleSubmit = useCallback(
    (selectedTimeFrame?: TPrepareToPlaceOrderBody['timeFrame']) => {
      if (selectedTimeFrame) {
        onSubmit(selectedTimeFrame);
      }
    },
    [onSubmit],
  );

  const handleClickHour = useCallback<THandleClickHour>(
    (availableArea, openHour, autoSubmit = true) => {
      const newTimeFrame: TPrepareToPlaceOrderBody['timeFrame'] = {
        openHour,
        date: availableArea.date,
        dayOfWeek: availableArea.dayOfWeek,
      };

      setTimeFrame(newTimeFrame);

      if (!isMobile && autoSubmit) {
        handleSubmit(newTimeFrame);
      }
    },
    [isMobile, handleSubmit],
  );

  const handleClickDateMobile = useCallback<THandleClickDateMobile>(
    (newDate, newDayOfWeek, availableHours) => {
      //  on mobile if only one time slot for specific area - selected it
      setTimeFrame({
        date: newDate,
        dayOfWeek: newDayOfWeek,
        openHour: availableHours.length === 1 ? availableHours[0].openHour : -1,
      });
    },
    [],
  );

  const renderHoursForAvailableDay = useMemo<TRenderHoursRow>(
    () => (availableArea) => {
      return (
        <Grid container spacing={isMobile ? 1 : 2} justifyContent="flex-start">
          {availableArea.availableHours.map(({ id: hourId, openHour, duration }) => {
            const isActive =
              timeFrame &&
              timeFrame.date === availableArea.date &&
              timeFrame.dayOfWeek?.id === availableArea.dayOfWeek.id &&
              timeFrame.openHour === openHour;
            return (
              <Grid
                item
                key={hourId}
                classes={{ root: classes.horizontalButtonItem }}
                {...gridSize}
              >
                <CTAButton
                  loading={isDialog && !isMobile && isActive && isSubmitting}
                  size="medium"
                  variant={isActive ? 'contained' : 'outlined'}
                  isTranslate={false}
                  onClick={() => handleClickHour(availableArea, openHour, isDialog)}
                  classes={{ root: classes.hourItem }}
                >
                  {formatHourRange(openHour, duration)}
                </CTAButton>
              </Grid>
            );
          })}
        </Grid>
      );
    },
    [
      isMobile,
      timeFrame,
      classes.horizontalButtonItem,
      classes.hourItem,
      gridSize,
      isDialog,
      isSubmitting,
      handleClickHour,
    ],
  );

  const dayOfTheWeekLabel = useCallback(
    (date, dayOfWeek) => {
      const parsedDate = parse(date, 'dd/MM/yyyy', new Date());

      if (isToday(parsedDate) && !isSubscriptionMode(orderMode)) {
        return 'availableHours.today';
      }
      if (isTomorrow(parsedDate) && !isSubscriptionMode(orderMode)) {
        return 'availableHours.tomorrow';
      }

      return dayOfWeek.resolvedName;
    },
    [orderMode],
  );

  const renderDesktopContent = useMemo<ReactNode>(() => {
    return (
      <>
        {availableAreas
          .slice(0, shouldShowToggle ? limitAvailableHours : availableAreas.length)
          .map((availableArea, index) => {
            return (
              <Box className={classes.buttonsSection} key={availableArea.id}>
                <Box display="flex" mb={isMobile ? 1 : 24 / 8}>
                  {!isMobile && (
                    <Box className={classes.dateSection}>
                      <Typography fontSize={16} fontWeight="medium" color="textSecondary">
                        {dayOfTheWeekLabel(availableArea.date, availableArea.dayOfWeek)}
                      </Typography>
                      {availableArea.date && (
                        <Typography fontSize={16} color="textSecondary">
                          {getDDMM(availableArea.date)}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {renderHoursForAvailableDay(availableArea)}
                </Box>
                {index + 1 !== availableAreas.length && <Divider className={classes.divider} />}
              </Box>
            );
          })}
        {shouldShowToggle && (
          <Box mt={2.25}>
            <Button
              onClick={() => setLimitAvailableHours(availableAreas.length)}
              classes={{
                root: classes.additionalDeliveryBtnRoot,
                text: classes.additionalDeliveryBtnText,
              }}
            >
              <Typography color="primary" fontWeight="medium" fontSize={16}>
                {'forms.checkout.additionalDelivery'}
              </Typography>
            </Button>
          </Box>
        )}
      </>
    );
  }, [
    availableAreas,
    shouldShowToggle,
    limitAvailableHours,
    classes.additionalDeliveryBtnRoot,
    classes.additionalDeliveryBtnText,
    classes.buttonsSection,
    classes.dateSection,
    classes.divider,
    isMobile,
    dayOfTheWeekLabel,
    renderHoursForAvailableDay,
  ]);

  const renderMobileContent = useMemo<ReactNode>(() => {
    let availableAreaForDate;
    if (timeFrame) {
      if (timeFrame.date) {
        availableAreaForDate = find(availableAreas, ['date', timeFrame.date]);
      } else {
        // subscription (only weekday)
        availableAreaForDate = find(availableAreas, ['dayOfWeek.id', timeFrame.dayOfWeek?.id]);
      }
    }

    return (
      <>
        <Box mb={1.75}>
          <Typography fontSize={16} fontWeight="medium" color="textSecondary">
            {'availableHours.chooseDay'}
          </Typography>
        </Box>
        <Grid container spacing={1} justifyContent="flex-start" className={classes.hoursSection}>
          {availableAreas.map(({ id, date, dayOfWeek, availableHours }) => {
            return (
              <Grid item key={id}>
                <CTAButton
                  variant={
                    timeFrame && timeFrame.dayOfWeek?.id === dayOfWeek.id && timeFrame.date === date
                      ? 'contained'
                      : 'outlined'
                  }
                  size="medium"
                  classes={{
                    root: classes.mobileCTAButtonRoot,
                    label: classes.mobileCTAButtonLabel,
                    contained: classes.containedButton,
                  }}
                  isTranslate={false}
                  onClick={() => handleClickDateMobile(date, dayOfWeek, availableHours)}
                >
                  <Typography
                    fontSize={16}
                    fontWeight="medium"
                    color={
                      timeFrame &&
                      timeFrame.dayOfWeek?.id === dayOfWeek.id &&
                      timeFrame.date === date
                        ? 'light'
                        : 'textSecondary'
                    }
                  >
                    {dayOfTheWeekLabel(date, dayOfWeek)}
                  </Typography>
                  {date && (
                    <Typography
                      color={
                        timeFrame &&
                        timeFrame.dayOfWeek?.id === dayOfWeek.id &&
                        timeFrame.date === date
                          ? 'light'
                          : 'textSecondary'
                      }
                      fontSize={14}
                    >
                      {getDDMM(date)}
                    </Typography>
                  )}
                </CTAButton>
              </Grid>
            );
          })}
        </Grid>
        <Divider className={classes.divider} />

        <Box mb={14 / 8} mt={22 / 8}>
          <Typography fontSize={16} fontWeight="medium" color="textSecondary">
            {'availableHours.chooseTimeframe'}
          </Typography>
        </Box>

        {availableAreaForDate && renderHoursForAvailableDay(availableAreaForDate)}
      </>
    );
  }, [
    availableAreas,
    classes.containedButton,
    classes.divider,
    classes.hoursSection,
    classes.mobileCTAButtonLabel,
    classes.mobileCTAButtonRoot,
    dayOfTheWeekLabel,
    handleClickDateMobile,
    renderHoursForAvailableDay,
    timeFrame,
  ]);

  const renderContent = useMemo<ReactNode>(() => {
    return isMobile ? renderMobileContent : renderDesktopContent;
  }, [isMobile, renderDesktopContent, renderMobileContent]);

  // set up initial selected timeFrame according to business logic
  useEffect(() => {
    if (initialTimeFrame) {
      const serviceArea = availableAreas.find(
        (availableArea) =>
          // eslint-disable-next-line eqeqeq
          availableArea.date == initialTimeFrame.date &&
          availableArea.dayOfWeek.id === initialTimeFrame.dayOfWeek.id,
      );

      if (serviceArea) {
        const availableHour = serviceArea.availableHours.find(
          (hour) => hour.openHour === initialTimeFrame.openHour,
        );

        if (availableHour) {
          handleClickHour(serviceArea, availableHour.openHour, false);
        }
      }
    } else {
      // clear selected timeframe if initial timeframe has been reset
      setTimeFrame(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAreas, initialTimeFrame, isMobile]);

  // handle auto date click on mobile if no initial timeframe
  useEffect(() => {
    if (!initialTimeFrame && isMobile && availableAreas.length > 0) {
      const [{ date, dayOfWeek, availableHours }] = availableAreas;
      handleClickDateMobile(date, dayOfWeek, availableHours);
    }
  }, [availableAreas, handleClickDateMobile, handleClickHour, initialTimeFrame, isMobile]);

  return (
    <Box>
      {!!availableAreas.length && (
        <Box>
          {renderContent}
          {(isMobile || !isDialog) && (
            <Box mt={30 / 8}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={6}>
                  <Box textAlign="end">
                    <CTAButton
                      fullWidth
                      disabled={!timeFrame || timeFrame.openHour === -1}
                      onClick={() => handleSubmit(timeFrame)}
                      loading={isSubmitting}
                      className={classes.submitButton}
                    >
                      {isDialog ? 'button.continueShopping' : 'button.goToCheckout'}
                    </CTAButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AvailableHours;

import React, {
  createRef,
  FC,
  FocusEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { omit } from 'lodash';
import classNames from 'classnames';

import { Box, FormHelperText, OutlinedInput, useTheme } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { KEY_CODE } from './constants';

import useStyles from './styles';

import {
  ICodeInput,
  THandleChange,
  THandleOnKeyDown,
  TOTPCredential,
  TOTPRequest,
  TTriggerChange,
} from './types';

const codeLength: number = 6;

const onlyNumbersRegExp = new RegExp('^[0-9]$');

// source code - https://github.com/suweya/react-verification-code-input/blob/master/src/index.js
const CodeInput: FC<ICodeInput> = ({
  onChange,
  onComplete,
  defaultValue,
  wrapperProps,
  helperText,
  error,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [inputValues, setInputValues] = useState<string[]>(new Array(codeLength).fill(''));

  const [autoFocusIndex] = useState<number>(0);

  const renderRefs = useMemo(() => {
    const refsArr: RefObject<HTMLInputElement>[] = [];
    for (let i = 0; i < codeLength; i += 1) {
      refsArr.push(createRef());
    }

    return refsArr;
  }, []);

  useEffect(() => {
    if (defaultValue) {
      const newDefaultInputValues: string[] = new Array(codeLength).fill('');
      let splitArr;

      if (typeof defaultValue === 'string') {
        splitArr = defaultValue.split('');
      } else {
        splitArr = [...defaultValue];
      }

      const onlyNumbersArr = splitArr.filter((item) => onlyNumbersRegExp.test(item));

      if (onlyNumbersArr.length === 0) return;
      const newAutoFocusIndex = onlyNumbersArr.length >= codeLength ? 0 : onlyNumbersArr.length;

      for (let i = 0; i < codeLength; i += 1) {
        if (onlyNumbersArr[i]) {
          newDefaultInputValues[i] = onlyNumbersArr[i];
        }
      }

      const next: RefObject<HTMLInputElement> = renderRefs[newAutoFocusIndex];

      next.current?.focus();

      setInputValues(newDefaultInputValues);
    }
  }, [defaultValue, renderRefs]);

  const triggerChange = useCallback<TTriggerChange>(
    (valuesArr) => {
      // get all values as sting
      const inputValue = valuesArr.join('');

      // trigger onChange every time when some inputs changed
      if (onChange) {
        onChange(inputValue);
      }
    },
    [onChange],
  );

  const handleOTP = useCallback(
    ({ code }) => {
      if (!code) {
        return;
      }

      const stringArray = code.split('');

      // insert received code into input values
      setInputValues(stringArray);

      // set authToken and enable submit button
      if (onChange) {
        onChange(code);
      }

      if (onComplete) {
        onComplete(code);
      }
    },
    [onChange, onComplete],
  );

  useEffect(() => {
    // feature detection
    if ('OTPCredential' in window) {
      const ac = new AbortController();

      const otpRequest: TOTPRequest = {
        otp: { transport: ['sms'] },
        signal: ac.signal,
      };

      navigator.credentials
        .get(otpRequest)
        .then((otp) => {
          // process the OTP
          handleOTP(otp as TOTPCredential);
          ac.abort();
        })
        // aborting the message
        .catch(() => {
          ac.abort();
        });
    }
  }, [handleOTP]);

  const handleChange = useCallback<THandleChange>(
    (inputIndex) => (e) => {
      const {
        target: { value: inputValue },
      } = e;
      // get only numbers
      const value = inputValue.replace(/[^\d]/gi, '');
      // next element ref, need fot focus
      let next: RefObject<HTMLInputElement>;

      const newInputValues = [...inputValues];

      // do not update values if we have empty value
      if (!value) return;

      // if we pass with copy/paste into input it will split all digits
      if (value.length > 1) {
        let nextIndex = value.length + inputIndex - 1;
        if (nextIndex >= codeLength) {
          nextIndex = codeLength - 1;
        }
        next = renderRefs[nextIndex];
        const split = value.split('');
        split.forEach((item, i) => {
          const cursor = inputIndex + i;
          if (cursor < codeLength) {
            newInputValues[cursor] = item;
          }
        });
        // if only one digit
      } else {
        next = renderRefs[inputIndex + 1];
        newInputValues[inputIndex] = value;
      }

      setInputValues(newInputValues);

      if (next) {
        // set focus on next input
        next.current?.focus();
      }

      // trigger callbacks (onChange, onComplete)
      triggerChange(newInputValues, inputIndex, value);
    },
    [setInputValues, inputValues, renderRefs, triggerChange],
  );

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>((e) => {
    e.target.select();
  }, []);

  const onKeyDown = useCallback<THandleOnKeyDown>(
    (inputIndex) => (e) => {
      const prevIndex = inputIndex - 1;
      const nextIndex = inputIndex + 1;
      const prev: RefObject<HTMLInputElement> = renderRefs[prevIndex];
      const next: RefObject<HTMLInputElement> = renderRefs[nextIndex];

      const code = e.key;

      switch (code) {
        case KEY_CODE.backspace: {
          e.preventDefault();
          const values = [...inputValues];
          // remove if current index have value delete current value
          if (inputValues[inputIndex]) {
            values[inputIndex] = '';
            setInputValues(values);

            triggerChange(values, inputIndex, '');
            // if we don't have current value, but have prev, remove prev value
          } else if (prev) {
            values[prevIndex] = '';
            prev.current?.focus();
            setInputValues(values);
            // valuesArr, currentIndex, currentValue
            triggerChange(values, prevIndex, '');
          }
          break;
        }
        case KEY_CODE.left:
          e.preventDefault();
          if (prev) {
            prev.current?.focus();
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (next) {
            next.current?.focus();
          }
          break;
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault();
          break;
        default:
          break;
      }
    },
    [inputValues, renderRefs, triggerChange],
  );

  const renderInputs = useMemo(() => {
    return inputValues.map((value, index) => {
      const key = index;

      return (
        <OutlinedInput
          key={key}
          inputRef={renderRefs[key]}
          onChange={handleChange(key)}
          onFocus={handleFocus}
          onKeyDown={onKeyDown(key)}
          value={value}
          autoFocus={autoFocusIndex === key}
          type="tel"
          classes={{
            root: classes.root,
            focused: classes.focused,
            notchedOutline: classNames(classes.notchedOutline, { [classes.completed]: !!value }),
            input: classes.input,
          }}
          inputProps={{
            label: t('dialog.loginCode.codeFromSms', { number: index + 1 }),
            autocomplete: 'one-time-code',
            dir: 'ltr',
            pattern: '[0-9]*',
          }}
          dir="ltr"
          error={error}
          {...rest}
        />
      );
    });
  }, [
    inputValues,
    renderRefs,
    handleChange,
    handleFocus,
    onKeyDown,
    autoFocusIndex,
    classes.root,
    classes.focused,
    classes.notchedOutline,
    classes.completed,
    classes.input,
    t,
    error,
    rest,
  ]);

  return (
    <Box display="inline-flex" flexDirection="column">
      <Box
        className={classNames(classes.formControlRoot, {
          [wrapperProps?.className || '']: wrapperProps?.className,
        })}
        {...omit(wrapperProps, ['className'])}
        dir="ltr"
      >
        {renderInputs}
      </Box>
      {helperText && (
        <FormHelperText dir={theme.direction} error={error} classes={{ root: classes.helperText }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CodeInput;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TSkeletonTopCartPreviousOrderItem } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TSkeletonTopCartPreviousOrderItem> =>
    createStyles({
      wrapper: {
        height: '72px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: theme.spacing(18 / 8, 17 / 8, 18 / 8, 20 / 8),
      },
      orderWrapper: {
        display: 'flex',
        flexDirection: 'column',
        '&:not(:last-child)': {
          marginRight: '7vw',
          [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(28 / 8),
          },
        },
      },
    }),
);

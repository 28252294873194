import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesSaleHorizontalTagWithPrice } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesSaleHorizontalTagWithPrice, { backgroundColor: string; lang: string }> =>
    createStyles({
      chipRoot: {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        marginLeft: theme.spacing(-0.625),
        marginRight: theme.spacing(-0.625),
        width: `calc(100% + ${theme.spacing(1.25)}px)`, // 100% + 10px
        height: theme.spacing(4), // 32px
        padding: theme.spacing(0, 2), // 6px 16px
        borderRadius: theme.spacing(0),
        textAlign: 'center',
        position: 'relative',
        justifyContent: 'initial',
      },
      // reset default MUI-label styles
      label: {
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        width: '100%',
      },
      labelTypography: {
        paddingRight: theme.spacing(0.625), // 5px
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: theme.spacing(10), // 80px
        letterSpacing: -0.31,
      },
      secondaryTypography: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        letterSpacing: -0.31,
      },
      priceTypography: {
        letterSpacing: -0.31,
      },
      icon: {
        objectFit: 'contain',
        fontSize: theme.spacing(2.5), // 20px
        marginRight: theme.spacing(0.5), // 4px
      },
      rightBottomTriangle: {
        position: 'absolute',
        width: theme.spacing(0),
        height: theme.spacing(0),
        borderTop: '5px solid rgba(0, 0, 0, 0.85)',
        borderLeft: '5px solid transparent',
        bottom: theme.spacing(-0.625), // -5px
        left: theme.spacing(0),
      },
      leftBottomTriangle: {
        position: 'absolute',
        width: theme.spacing(0),
        height: theme.spacing(0),
        borderTop: '5px solid rgba(0, 0, 0, 0.85)',
        borderRight: '5px solid transparent',
        bottom: theme.spacing(-0.625), // -5px
        right: theme.spacing(0),
      },
      tagWrapper: ({ lang }) => ({
        display: 'flex',
        maxWidth: lang === 'he' ? '98%' : '80%',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          maxWidth: '98%',
        },
      }),
    }),
);

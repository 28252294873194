import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesLinksHeader } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLinksHeader> =>
    createStyles({
      wrapper: {
        backgroundColor: theme.palette.secondary.main,
        position: 'sticky',
        width: '100%',
        height: '26px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.13)',
        zIndex: 6,
        alignContent: 'center',
      },
      contactItem: {
        display: 'flex',
        alignItems: 'center',
      },
      contactItemLable: {
        fontSize: '13px',
        lineHeight: '13px',
      },
      contactItemText: {
        fontStyle: 'normal',
        marginLeft: theme.spacing(6 / 8),
      },
      socialIcon: {
        fontSize: '14px',
        marginLeft: '16px',
      },
      dividerForContentPage: {
        margin: theme.spacing(0, 12 / 8),
        height: '10px',
        width: '1px',
        backgroundColor: theme.palette.background.paper,
      },
    }),
);

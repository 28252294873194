import { BaseApi } from 'api/Api';
import axiosRetry from 'axios-retry';
import { TGetCatalogVersion, TGetCategories, TGetProductDetails, TGetPublicCatalog } from './types';

export default class CatalogService {
  static getCategories: TGetCategories = async (websiteJWE, xForwardedIpAddress) => {
    return BaseApi.instance().get(`/client/public/store/catalog/categories`, {
      headers: {
        ...(websiteJWE ? { 'X-Website': websiteJWE } : {}),
        ...(xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {}),
      },
    });
  };

  static getPublicCatalog: TGetPublicCatalog = async (params) => {
    const {
      limited,
      categoryId,
      parentCategoryId,
      websiteJWE,
      xForwardedIpAddress,
      catalogId,
      master,
    } = params;

    return BaseApi.instance(
      { handleError: false },
      {
        retries: 3,
        retryDelay: axiosRetry.exponentialDelay,
      },
    ).get(`/client/public/store/catalog`, {
      headers: {
        'Compress-Response': 'True',
        ...(websiteJWE ? { 'X-Website': websiteJWE } : {}),
        ...(xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {}),
      },
      params: {
        ...(limited ? { limited } : {}),
        ...(categoryId ? { categoryId } : {}),
        ...(parentCategoryId ? { parentCategoryId } : {}),
        ...(catalogId ? { subCatalogId: catalogId } : undefined),
        ...(master ? { master } : false),
      },
    });
  };

  static getCatalogVersion: TGetCatalogVersion = async (params) => {
    const { websiteJWE, xForwardedIpAddress } = params;
    return BaseApi.instance().get(`/client/public/store/catalog/version`, {
      headers: {
        ...(websiteJWE ? { 'X-Website': websiteJWE } : {}),
        ...(xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {}),
      },
    });
  };

  static getProductDetails: TGetProductDetails = async (
    websiteJWE,
    productId,
    xForwardedIpAddress,
  ) => {
    return BaseApi.instance().get(`/client/public/store/catalog/${productId}`, {
      headers: {
        ...(websiteJWE ? { 'X-Website': websiteJWE } : {}),
        ...(xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {}),
      },
    });
  };
}

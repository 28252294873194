import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { Box, IconButton, InputBase, OutlinedInputProps } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';

import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import { useRouter } from 'next/router';
import useStyles from './styles';

import { IMobileSearch } from './types';

const MobileSearch: FC<IMobileSearch> = ({
  placeholder = 'input.search',
  customPlaceholder,
  onFocus,
  onBlur,
  inputProps,
  rootClassName,
  onClose,
  onSearch,
  onChange,
  ...rest
}) => {
  const {
    searchContext: { searchInputValue, handleChange, handleClear },
  } = useContext(MainLayoutContext);
  const searchInput = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const classes = useStyles({ isFocused });

  const handleSearchInputChange = useCallback<Required<OutlinedInputProps>['onChange']>(
    (e) => {
      if (window.scrollY > 311 && e.target.value.length === 1) {
        // TODO check if we can use setTimeout / requestAnimationFrame
        window.scrollTo({ top: 311, behavior: 'smooth' });
      }

      handleChange(e);

      if (onChange) {
        onChange(e);
      }
    },
    [handleChange, onChange],
  );

  const handleOpenSearch = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleClose = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      handleClear();
      setIsFocused(false);

      // avoid click on handleOpenSearch
      e.stopPropagation();
    },
    [handleClear],
  );

  // if we do not need to translate placeholder use customPlaceholder
  const searchPlaceholder = customPlaceholder || t(placeholder);

  // if route equals search query then open search
  useEffect(() => {
    if (router.route === '/search/[query]') {
      handleOpenSearch();
    }
  }, [router, handleOpenSearch]);

  return (
    <Box className={classNames(classes.search, rootClassName)}>
      <IconButton onClick={handleOpenSearch} className={classes.searchIcon}>
        <Icon color="primary" name="icon-big-search" fontSize="inherit" />
      </IconButton>
      <InputBase
        inputRef={searchInput}
        placeholder={searchPlaceholder}
        classes={{
          root: classes.inputRoot,
          input: classNames(classes.inputInput, { [classes.focused]: isFocused }),
        }}
        value={searchInputValue}
        onChange={handleSearchInputChange}
        inputProps={{ 'aria-label': 'search', ...inputProps }}
        endAdornment={
          isFocused && (
            <IconButton onClick={handleClose} className={classes.closeIcon}>
              <Icon color="primary" name="icon-button-x" fontSize="inherit" />
            </IconButton>
          )
        }
        {...rest}
      />
    </Box>
  );
};

export default MobileSearch;

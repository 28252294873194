import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesProductImageSlider } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesProductImageSlider> =>
    createStyles({
      cardImage: {
        width: '100%',
        margin: '0 auto',
      },
      swiper: {
        alignSelf: 'center',
        overflow: 'hidden',
        paddingTop: '34px',
        height: '220px',
        width: '270px !important',
      },
      pagination: {
        width: theme.spacing(6 / 8),
        height: theme.spacing(6 / 8),
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '50%',
        marginLeft: theme.spacing(6 / 8),
      },
      clickablePagination: {
        bottom: '12px !important',
        display: 'flex',
        justifyContent: 'center',
      },
      activeBulletPagination: {
        width: theme.spacing(6 / 8),
        height: theme.spacing(6 / 8),
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
      },
    }),
);

import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesPreviousOrdersContent } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesPreviousOrdersContent> =>
    createStyles({
      refreshIcon: {
        fontSize: '22px',
        marginRight: theme.spacing(8 / 8),
      },
      content: {
        width: '100%',
        background: theme.palette.background.paper,
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: theme.spacing(18 / 8),
      },
      header: {
        paddingTop: theme.spacing(46 / 8),
        paddingBottom: theme.spacing(18 / 8),
      },
      loadPreviousOrderTitle: {
        lineHeight: '24px',
        fontSize: '22px',
      },
    }),
);

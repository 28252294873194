import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUsePreviousOrderItems } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUsePreviousOrderItems> =>
    createStyles({
      divider: {
        background: theme.palette.grey.A100,
      },
    }),
);

import { Action, Reducer } from 'redux';
import { ILogOutSuccess } from 'store/modules/auth';
import { IPlaceOrderSuccess, SET_ORDER_ITEMS_SUCCESS } from 'store/modules/order';
import { IPromotion, IStoreProductSource } from 'store/modules/promotions';

import { IStoreProduct } from 'store/modules/storeProduct';

import { TStoreState } from 'store/types';

import {
  TCouponServer,
  TDiscountSourceTypeServer,
  TDiscountTypeServer,
  TPromotionServer,
  TPromotionType,
  TUserBenefit,
  TValueFormulaTypeServer,
} from 'types';
import {
  CLIENT_COUPONS_UPDATE,
  GET_STATIC_PROMOTIONS,
  ORDER_DISCOUNTS_RECALCULATE,
  ORDER_DISCOUNTS_UPDATE,
  STORE_COUPON_UPDATE,
  UPDATE_MEMBERSHIP_BENEFITS,
  UPDATE_STATIC_PROMOTIONS,
} from './constants';

// state
export interface ICoupon extends Omit<TCouponServer, 'storeProductSource'> {
  discountSourceType: TDiscountSourceTypeServer;
  storeProductSource?: IStoreProductSource;
}

export type TOrderDiscountsReducerState = {
  discounts: TOrderDiscount[];
  clientCoupons: ICoupon[]; // refunds, and other client-specific discounts brought at
  storeCoupon?: ICoupon;
  staticPromotions: TStaticPromotions;
  membershipBenefits?: TMembershipBenefits;
};

export type TOrderDiscount = {
  valueFormula: string;
  valueFormulaType: TValueFormulaTypeServer;
  cartIndex: number;
  accumulatedQuantity: number; // accumulated quantity of products
  accumulatedProducts: TOrderDiscountAccumulatedProduct[];
  promotion?: TPromotionServer['nonObfuscatedId'];
  totalParticipatingProductsPrice: number; // used for duplicate (kefel) calculations
  totalPrice: number;
  name: string;
  externalNotes: string;
  shortName?: string;
  state: TOrderDiscountState;
  discountSourceType: TDiscountSourceTypeServer;
  valueFormulaSourceQuantity: number | null;
  storeProductSource?: IStoreProductSource;
  expirationTime?: string; // format dd.MM.yy
  discountType: TDiscountTypeServer;
  creditedAtCheckout?: boolean;
  promotionType: TPromotionType;
  parameters: IPromotion['parameters'];
};

export enum TOrderDiscountState {
  Pending,
  Active,
  Ineligible,
}

export type TStaticPromotions = {
  staticPromotionsIds: TPromotionServer['nonObfuscatedId'][];
  staticPromotionsById: Record<TPromotionServer['nonObfuscatedId'], IPromotion>;
};

export type TMembershipBenefits = {
  budgetToUse: number;
  requestedBenefits: Record<TUserBenefit['id'], TUserBenefit>;
};

export type TOrderDiscountAccumulatedProduct = {
  storeProduct: IStoreProduct;
  quantity: number;
  cartIndex: number;
};

// action creators
export type TOrderDiscountsRecalculateAction = () => IOrderDiscountsRecalculate;

export type TOrderDiscountsUpdateAction = (
  discounts: TOrderDiscountsReducerState['discounts'],
) => IOrderDiscountsUpdate;

export type TClientCouponsUpdateAction = (coupons: ICoupon[]) => IClientCouponsUpdate;

export type TStoreCouponUpdateAction = (storeCoupon?: ICoupon) => IStoreCouponUpdate;

export type TGetStaticPromotionsAction = (promotions: IPromotion[]) => IGetStaticPromotions;

export type TUpdateStaticPromotionsAction = (
  staticPromotionsById: TStaticPromotions['staticPromotionsById'],
  staticPromotionsIds: TStaticPromotions['staticPromotionsIds'],
) => IUpdateStaticPromotions;

export type TUpdateMembershipBenefitsAction = (
  membershipBenefits?: TMembershipBenefits,
) => IUpdateMembershipBenefits;

// actions
export interface IOrderDiscountsRecalculate extends Action<typeof ORDER_DISCOUNTS_RECALCULATE> {}

export interface IOrderDiscountsUpdate extends Action<typeof ORDER_DISCOUNTS_UPDATE> {
  payload: { discounts: TOrderDiscountsReducerState['discounts'] };
}

export interface IClientCouponsUpdate extends Action<typeof CLIENT_COUPONS_UPDATE> {
  payload: {
    clientCoupons: ICoupon[];
  };
}

export interface IStoreCouponUpdate extends Action<typeof STORE_COUPON_UPDATE> {
  payload: {
    storeCoupon?: ICoupon;
  };
}

export interface IUpdateStaticPromotions extends Action<typeof UPDATE_STATIC_PROMOTIONS> {
  payload: {
    staticPromotions: TStaticPromotions;
  };
}

export interface IUpdateMembershipBenefits extends Action<typeof UPDATE_MEMBERSHIP_BENEFITS> {
  payload: {
    membershipBenefits?: TMembershipBenefits;
  };
}

export interface IGetStaticPromotions extends Action<typeof GET_STATIC_PROMOTIONS> {
  payload: {
    staticPromotions: IPromotion[];
  };
}

export interface IRecalculateOrderDiscounts extends Action<typeof SET_ORDER_ITEMS_SUCCESS> {
  payload: { showPromotionWasAppliedSnackbar?: boolean };
}

// all actions
export type TOrderDiscountsActions =
  | IOrderDiscountsRecalculate
  | IOrderDiscountsUpdate
  | IClientCouponsUpdate
  | IStoreCouponUpdate
  | IPlaceOrderSuccess
  | IUpdateStaticPromotions
  | ILogOutSuccess
  | IUpdateMembershipBenefits;

// reducer
export type TOrderDiscountsReducer = Reducer<TOrderDiscountsReducerState, TOrderDiscountsActions>;

// selectors
export type TOrderDiscountsSelector = (
  state: TStoreState,
) => TOrderDiscountsReducerState['discounts'];

export type TClientCouponsSelector = (
  state: TStoreState,
) => TOrderDiscountsReducerState['clientCoupons'];

export type TStoreCouponSelector = (
  state: TStoreState,
) => TOrderDiscountsReducerState['storeCoupon'];

export type TGetStaticPromotionsSelector = (
  state: TStoreState,
) => TOrderDiscountsReducerState['staticPromotions'];

export type TGetMembershipBenefitsSelector = (
  state: TStoreState,
) => TOrderDiscountsReducerState['membershipBenefits'];

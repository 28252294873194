import { TOrderReducerState } from './types';

export const initialOrderState: TOrderReducerState = {
  paymentMethodType: {
    name: '',
    resolvedName: '',
  },
  items: [],
  comments: '',
};

import { Box } from '@material-ui/core';
import { ContentPageService } from 'api/services/ContentPageService';

import { ICustomizedPageComponent } from 'components/common/CustomizedPage';
import Banner from 'components/common/HomePageContent/components/common/Banner';
import CategoriesGrid from 'components/common/HomePageContent/components/common/CategoriesGrid';
import ContentCardsSlider from 'components/common/HomePageContent/components/common/ContentCardsSlider';
import ImagesBannerSlider from 'components/common/HomePageContent/components/common/ImagesBannerSlider';
import PromotedProductsSlider from 'components/common/HomePageContent/components/common/PromotedProductsSlider';
import TestimonialsSlider from 'components/common/HomePageContent/components/common/TestimonialsSlider';
import TextAndImage from 'components/common/HomePageContent/components/common/TextAndImage';
import TextInsideImage from 'components/common/HomePageContent/components/common/TextInsideImage';
import { useMobile } from 'hooks/useMobile';
import { useProduct } from 'hooks/useProduct';
import useProductsSort from 'hooks/useProductsSort';

import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import React, { useCallback, useMemo, useState } from 'react';
import { IStoreProductDisplay } from 'store';

import {
  TCompositeComponentServer,
  TCustomizedImageServer,
  TCustomizedImageSliderServer,
  THomePageCategoryServer,
  THomePageContentCard,
  THomePageSelectServer,
  THomePageTestimonial,
} from 'types';
import { prepareImageUrl } from 'utils/helpers/prepareImageUrl';
import useStyles from './styles';
import { TUseCustomizedPageContent } from './types';

const useHomePageContent: TUseCustomizedPageContent = () => {
  const { getDisplayableStoreProduct } = useProduct();
  const { sortStoreProducts } = useProductsSort();

  const websiteDetails = useWebsiteDetails();

  const { isMobile } = useMobile();

  const [pageContent, setPageContent] = useState<ICustomizedPageComponent>({
    compositeComponents: [],
  });

  const classes = useStyles({
    contactUsBackground:
      websiteDetails.theme.aboutPage.images[
        isMobile ? 'contactUsBackgroundMobile' : 'contactUsBackgroundDesktop'
      ],
  });

  const renderHomePageComponents = useCallback(
    (compositeComponents: TCompositeComponentServer[]) => {
      const components: React.ReactChild[] = [];

      if (!compositeComponents.length) return;

      compositeComponents.forEach((component) => {
        switch (component.type) {
          case 'banner':
            if (component.atomicComponents.length) {
              const bannerImagesDesktop: TCustomizedImageSliderServer[] = [];
              const bannerImagesMobile: TCustomizedImageSliderServer[] = [];

              component.atomicComponents.forEach((banner) => {
                if (banner.desktop) {
                  bannerImagesDesktop.push(...(banner.value as TCustomizedImageSliderServer[]));
                } else {
                  bannerImagesMobile.push(...(banner.value as TCustomizedImageSliderServer[]));
                }
              });

              components.push(
                <Box mt={isMobile ? 0 : 30 / 8} mb={isMobile ? 40 / 8 : 80 / 8}>
                  <Banner
                    imagesDesktop={bannerImagesDesktop as TCustomizedImageSliderServer[]}
                    imagesMobile={bannerImagesMobile as TCustomizedImageSliderServer[]}
                  />
                </Box>,
              );
            }
            return;

          case 'promotedProducts':
            if (component.atomicComponents[1].value) {
              const preparedProducts = (
                component.atomicComponents[1].value as THomePageSelectServer[]
              )
                .map((product) => {
                  return getDisplayableStoreProduct(product.value);
                })
                .filter((product): product is IStoreProductDisplay => !!product);

              if (!preparedProducts.length) return;

              components.push(
                <Box my={isMobile ? 40 / 8 : 80 / 8}>
                  <PromotedProductsSlider
                    products={sortStoreProducts(preparedProducts as IStoreProductDisplay[], 2)}
                    linkLabel={component.atomicComponents[2].value as string}
                    linkHref={component.atomicComponents[3].value as string}
                    title={component.atomicComponents[0].value as string}
                  />
                </Box>,
              );
            }
            return;

          case 'imagesSlider':
            components.push(
              <Box my={isMobile ? 40 / 8 : 80 / 8}>
                <ImagesBannerSlider
                  images={component.atomicComponents[1].value as TCustomizedImageSliderServer[]}
                  title={component.atomicComponents[0].value as string}
                />
              </Box>,
            );
            return;

          case 'selectCategoryAndImage':
            components.push(
              <Box>
                <CategoriesGrid
                  title={component.atomicComponents[0].value}
                  categories={component.atomicComponents[1].value as THomePageCategoryServer[]}
                />
              </Box>,
            );
            return;
          case 'testimonial':
            components.push(
              <Box mt={isMobile ? 40 / 8 : 80 / 8} mb={80 / 8}>
                <TestimonialsSlider
                  testimonials={component.atomicComponents[1].value as THomePageTestimonial[]}
                  title={component.atomicComponents[0].value as string}
                />
              </Box>,
            );
            return;
          case 'textAndImage':
            components.push(
              <Box>
                <TextAndImage
                  title={component.atomicComponents[0].value}
                  description={component.atomicComponents[1].value}
                  image={component.atomicComponents[2] as TCustomizedImageServer}
                  linkLabel={component.atomicComponents[3].value as string}
                  linkHref={component.atomicComponents[4].value as string}
                />
              </Box>,
            );
            return;
          case 'textOnImage':
            components.push(
              <Box>
                <TextInsideImage
                  title={component.atomicComponents[0].value}
                  description={component.atomicComponents[1].value}
                  backgroundImage={prepareImageUrl(component.atomicComponents[2].value as string)}
                  linkLabel={component.atomicComponents[3].value}
                  linkHref={component.atomicComponents[4].value}
                />
              </Box>,
            );
            return;
          case 'contentCard':
            components.push(
              <Box my={isMobile ? 40 / 8 : 80 / 8}>
                <ContentCardsSlider
                  title={component.atomicComponents[0].value as string}
                  linkLabel={component.atomicComponents[1].value as string}
                  linkHref={component.atomicComponents[2].value as string}
                  content={component.atomicComponents[3].value as THomePageContentCard[]}
                />
              </Box>,
            );
            return;
          default:
            return '';
        }
      });

      if (components.length) {
        components.push(<Box className={classes.bottomImageWrapper} />);
      }
      return components;
    },
    [classes.bottomImageWrapper, getDisplayableStoreProduct, isMobile, sortStoreProducts],
  );

  const populatePageContent = useCallback((pageId: string) => {
    ContentPageService.getStorePageContent(pageId).then((actionResult) => {
      if (actionResult.data) {
        setPageContent(JSON.parse(actionResult.data.content).compositeComponents);
      }
    });
  }, []);

  const homePageId = useMemo(() => {
    return websiteDetails.contentPages.find((contentPage) => contentPage.type === 'home')
      ?.id as string;
  }, [websiteDetails.contentPages]);

  return {
    renderHomePageComponents,
    pageContent,
    populatePageContent,
    homePageId,
  };
};

export default useHomePageContent;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesTextInsideImage } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesTextInsideImage, { backgroundImage: string; isOpenedTopCart: boolean }> =>
    createStyles({
      contentBackground: {
        backgroundColor: theme.palette.grey[50],
        [theme.breakpoints.up('md')]: {
          padding: '80px 0px',
        },
      },
      textInsideImageWrapper: ({ backgroundImage }) => ({
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        // minHeight: '492px',
        height: '100%',
        width: '100%',
        padding: theme.spacing(0, 50 / 8),
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(107 / 8, 0),
          borderRadius: '16px',
          height: '450px',
          width: ({ isOpenedTopCart }) => (isOpenedTopCart ? '898px' : '1240px'),
        },
      }),
      wrapper: {
        margin: ' 0 auto',
        maxWidth: '1240px',
      },
      overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(0, 50 / 8),
        [theme.breakpoints.up('md')]: {
          padding: '84px 0px 76px',
          borderRadius: '16px',
        },
      },
      textInsideImageTitle: {
        fontSize: '26px',
        color: theme.palette.background.paper,
        fontWeight: 'bold',
        lineHeight: '28px',
        textAlign: 'center',
        marginBottom: '12px',
        [theme.breakpoints.up('md')]: {
          fontSize: '36px',
          lineHeight: '42px',
          marginBottom: '14px',
        },
      },
      textInsideImageDescription: {
        fontSize: '18px',
        color: theme.palette.background.paper,
        lineHeight: '26px',
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: '600px',
        [theme.breakpoints.up('md')]: {
          maxWidth: 'initial',
          width: '780px',
          fontSize: '20px',
          lineHeight: '30px',
        },
      },
      buttonLabel: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
      },
      textInsideImageButton: {
        margin: '0 auto',
        marginTop: '30px',
        height: '40px',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        color: theme.palette.background.paper,
        width: '160px',
      },
    }),
);

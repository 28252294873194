import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesErrorContent } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesErrorContent, { showErrorDetails: boolean }> =>
    createStyles({
      root: {
        background: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 138px)',
        paddingTop: '16vh',
        [theme.breakpoints.up('md')]: {
          paddingTop: '28vh',
          minHeight: 'calc(100vh - 60px)',
        },
      },
      wrapper: {
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 20 / 8),
        [theme.breakpoints.up('md')]: {
          width: 'min-content',
          padding: 0,
        },
      },
      imageWrapper: {
        width: '100%',
        marginBottom: theme.spacing(14 / 8),
        [theme.breakpoints.up('md')]: {
          marginBottom: 0,
        },
      },
      errorImage: {
        width: '90%',
        maxWidth: '600px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          transform: theme.direction === 'rtl' ? 'none' : 'scaleX(-1)',
          maxWidth: 'initial',
          margin: '0',
          width: '670px',
        },
      },
      typography: {
        fontSize: theme.spacing(32 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(57 / 8),
          lineHeight: '0.95',
        },
      },
      errorDescription: {
        fontSize: theme.spacing(18 / 8),
        textAlign: 'center',
        padding: '0 55px',
        [theme.breakpoints.up('md')]: {
          lineHeight: '26px',
          letterSpacing: '-0.22px',
          textAlign: 'initial',
          padding: 0,
          fontSize: theme.spacing(20 / 8),
        },
      },
      typographyWrapper: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(10 / 8),
          textAlign: 'inherit',
        },
      },
      titleWrapper: {
        [theme.breakpoints.up('md')]: {
          width: theme.direction === 'rtl' ? '353px' : '274px',
        },
      },
      title: {
        marginBottom: theme.spacing(12 / 8),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(16 / 8),
        },
      },
      description: {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
      },
      backToTheHomePageBtn: {
        width: '232px',
        marginTop: theme.spacing(30 / 8),
        [theme.breakpoints.up('md')]: {
          width: '220px',
          marginTop: theme.direction === 'rtl' ? theme.spacing(50 / 8) : theme.spacing(33 / 8),
        },
      },
      errorDetailsButton: {
        backgroundColor: 'transparent',
        padding: 0,
        justifyContent: 'initial',
        height: 'auto !important',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
        display: 'flex',
        margin: '0 auto',
        marginTop: theme.spacing(30 / 8),
        minWidth: 'auto',
        marginBottom: theme.spacing(12 / 8),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(50 / 8, 0, 16 / 8),
        },
      },
      errorDetailsLabel: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '22px',
      },
      errorDetails: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        lineHeight: '18px',
        textAlign: 'right',
        [theme.breakpoints.up('md')]: {
          fontSize: '15px',
          lineHeight: '21px',
        },
      },
      iconArrow: ({ showErrorDetails }) => ({
        transform: showErrorDetails ? 'rotate(-180deg)' : 'initial',
        transition: 'transform 300ms',
        color: theme.palette.primary.main,
        fontSize: '14px',
        marginLeft: theme.spacing(6 / 8),
      }),
      errorDetailsWrapper: ({ showErrorDetails }) => ({
        display: showErrorDetails ? 'block' : 'none',
        transition: 'display 300ms',
        height: 'auto',
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(14 / 8, 15 / 8),
        marginBottom: theme.spacing(44 / 8),
        maxWidth: 'calc(100vw - 40px)',
        borderRadius: '8px',
        wordWrap: 'break-word',
        [theme.breakpoints.up('md')]: {
          borderRadius: '10px',
          maxWidth: 'auto',
          padding: theme.spacing(20 / 8, 30 / 8),
          marginBottom: theme.spacing(100 / 8),
        },
      }),
      mainWrapper: {
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          flexDirection: 'row-reverse',
        },
      },
    }),
);

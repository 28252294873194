import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesMap, TStylesMapProps } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMap, TStylesMapProps> =>
    createStyles({
      locationIcon: {
        width: ({ mainPointerFontSize }) => theme.spacing((mainPointerFontSize || 52) / 8),
        height: ({ mainPointerFontSize }) => theme.spacing((mainPointerFontSize || 52) / 8),
        fontSize: ({ mainPointerFontSize }) => theme.spacing((mainPointerFontSize || 52) / 8),
        color: ({ isValid, isTouched, mainPointerColor }) => {
          if (mainPointerColor) {
            return mainPointerColor;
          }
          if (!isTouched) {
            return theme.palette.grey.A700;
          }

          return !isValid ? theme.palette.primary.main : theme.palette.error.main;
        },
        position: 'absolute',
        top: ({ mainPointerFontSize }) =>
          mainPointerFontSize ? -(mainPointerFontSize / 2) : '-26px',
        right: ({ mainPointerFontSize }) =>
          mainPointerFontSize ? -(mainPointerFontSize / 2) : '-26px',
        transform: 'translateY(-50%)',
      },
      pointer: {
        position: 'absolute',
        transform: 'translateY(-50%)',
      },
      crosshair: {
        fontSize: '14px',
        borderRadius: '50%',
        color: theme.palette.grey[600],
      },
      crosshairWrapper: {
        flip: false,
        right: 12,
        borderRadius: '50%',
        position: 'absolute',
        padding: theme.spacing(1),
        background: theme.palette.background.paper,
        bottom: '92px',
        boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
        '& .MuiTouchRipple-root span': {
          backgroundColor: `${theme.palette.grey[300]} !important`,
        },

        '&:hover': {
          background: `${theme.palette.background.paper} !important`,
        },
      },
      loaderWrapper: {
        position: 'absolute',
        left: theme.spacing(12 / 8),
      },
      loader: {
        height: theme.spacing(30 / 8),
        width: theme.spacing(30 / 8),
        padding: theme.spacing(1),
        boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
        borderRadius: '50%',
        background: theme.palette.background.paper,
      },
    }),
);

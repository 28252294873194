import useGeneratedMetadata from 'hooks/useGeneratedMetadata';
import React, { FC, useMemo } from 'react';
import Head from 'next/head';
import { useCategoryMenu, useWebsiteDetails } from 'hooks';
import { useSelector } from 'react-redux';
import { storeProductSelectors } from 'store';
import { useRouter } from 'next/router';
import { IBaseSEOTags } from './types';

const BaseSEOTags: FC<IBaseSEOTags> = ({ activeCategoryMenuItem }) => {
  const websiteDetails = useWebsiteDetails();
  const { pathname, query } = useRouter();

  const { categoriesByIdsIncludingParents } = useCategoryMenu();

  const { storeProductById } = useSelector(storeProductSelectors.storeProductsData);
  const { categoryGeneratedDescription } = useGeneratedMetadata();

  const storeProduct = useMemo(() => {
    if (pathname === '/product-details/[id]/[slug]' && query.id)
      return storeProductById[query.id.toString()];
  }, [pathname, query.id, storeProductById]);

  const activeCategory = useMemo(() => {
    return categoriesByIdsIncludingParents[activeCategoryMenuItem?.id || -1];
  }, [activeCategoryMenuItem?.id, categoriesByIdsIncludingParents]);

  return (
    <>
      <Head>
        <title key="title">{websiteDetails.metadata?.title || websiteDetails.name}</title>
        <link rel="icon" href={websiteDetails.favoriteIconPath} />

        {!!websiteDetails.metadata?.metaTags &&
          websiteDetails.metadata?.metaTags.map((metaTag) => (
            <meta key={metaTag.content} name={metaTag.name} content={metaTag.content} />
          ))}

        <meta
          key="ogTitle"
          property="og:title"
          content={websiteDetails.metadata?.title || websiteDetails.name}
        />
        {!!websiteDetails.metadata?.description && (
          <>
            <meta
              key="description"
              name="description"
              content={websiteDetails.metadata.description}
            />
            <meta
              key="ogDescription"
              property="og:description"
              content={websiteDetails.metadata.description}
            />
          </>
        )}
        {!!websiteDetails.metadata?.keywords && (
          <meta key="keywords" name="keywords" content={websiteDetails.metadata.keywords} />
        )}

        <meta key="ogImage" property="og:image" content={websiteDetails.logoPath} />
      </Head>

      {activeCategory && (
        <Head>
          <title key="title">
            {activeCategory.metadata.title || `${activeCategory.name} | ${websiteDetails.name}`}
          </title>
          <meta
            key="ogTitle"
            property="og:title"
            content={
              activeCategory.metadata.title || `${websiteDetails.name} | ${activeCategory.name}`
            }
          />
          {!!activeCategory.metadata.description && (
            <meta
              key="description"
              name="description"
              content={activeCategory.metadata.description}
            />
          )}
          {
            <meta
              key="ogDescription"
              property="og:description"
              content={activeCategory.metadata.description || categoryGeneratedDescription}
            />
          }
          {!!activeCategory.metadata.keywords && (
            <meta key="keywords" name="keywords" content={activeCategory.metadata.keywords} />
          )}
        </Head>
      )}

      {!!storeProduct && (
        <Head>
          <title key="title">
            {storeProduct.metadata.title ||
              `${storeProduct.fullName} | ${websiteDetails.metadata?.title || websiteDetails.name}`}
          </title>
          <meta
            key="ogTitle"
            property="og:title"
            content={storeProduct.metadata.title || storeProduct.name}
          />
          {!!storeProduct.metadata.description && (
            <meta
              key="description"
              name="description"
              content={storeProduct.metadata.description}
            />
          )}
          {!!storeProduct.metadata.description && (
            <meta
              key="ogDescription"
              property="og:description"
              content={storeProduct.metadata.description}
            />
          )}
          {!!storeProduct.metadata.keywords && (
            <meta key="keywords" name="keywords" content={storeProduct.metadata.keywords} />
          )}
        </Head>
      )}
    </>
  );
};

export default BaseSEOTags;

export const deliveryOrSelfPickupBodyText = (
  performsDelivery: boolean,
  performsSelfPickup: boolean,
): string => {
  if (performsDelivery && performsSelfPickup) {
    return 'dialog.deliveryToClientAddress.performsSelfPickupAndDeliveryBody';
  }
  if (performsSelfPickup) {
    return 'dialog.deliveryToClientAddress.performsSelfPickupBody';
  }
  if (performsDelivery) {
    return 'dialog.deliveryToClientAddress.performDeliveryBody';
  }
  return 'dialog.deliveryToClientAddress.doesntPerformDeliveryBody';
};

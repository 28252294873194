import { CONFIG_UPDATE, UPDATE_LANGUAGE_CONFIG } from './constants';

import { TConfigUpdateAction, TUpdateLanguageConfigAction } from './types';

const configUpdate: TConfigUpdateAction = (newConfigState) => ({
  type: CONFIG_UPDATE,
  payload: newConfigState,
});

const updateLanguageConfigAction: TUpdateLanguageConfigAction = (newLanguage) => ({
  type: UPDATE_LANGUAGE_CONFIG,
  payload: {
    language: newLanguage,
  },
});

export { configUpdate, updateLanguageConfigAction };

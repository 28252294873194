import React, { FC } from 'react';
import { useDialog, useWebsiteDetails } from 'hooks';
import { useTranslation } from 'react-i18next';

import { Box, CardMedia, IconButton } from '@material-ui/core';
import Typography from 'ui/common/Typography';
import Icon from 'ui/common/icons/Icon';

import Link from 'ui/common/Link';
import { notifyActions } from 'store';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import { IShareProductCartDialog } from './types';

const ShareProductCartDialog: FC<IShareProductCartDialog> = ({ linkToShare }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const websiteDetails = useWebsiteDetails();

  const { hideDialog } = useDialog();

  const classes = useStyles();

  return (
    <Box className={classes.shareCartWrapper}>
      <CardMedia
        src={websiteDetails.theme.images.shareCart}
        component="img"
        className={classes.shareCartImage}
        alt={t('topCard.shareCart')}
      />
      <Typography className={classes.shareCartTitle} variant="h2">
        {'topCard.shareCartItems'}
      </Typography>
      <Box className={classes.shareCartMainWrapper}>
        <Typography className={classes.shareCartSubtitle} variant="body1">
          {'topCard.shareCartInFollowingWays'}
        </Typography>
        <Box className={classes.shareCartButtonsWrapper}>
          <Box
            className={classes.shareCartButtonWrapper}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <IconButton
              classes={{ root: classes.shareCartButton, label: classes.shareCartLabel }}
              onClick={() => {
                navigator.clipboard.writeText(linkToShare).then(() => {
                  dispatch(
                    notifyActions.showNotification({
                      message: 'topCard.linkCopiedSuccessfully',
                    }),
                  );
                  hideDialog();
                });
              }}
            >
              <Icon name="icon-link" classes={{ root: classes.shareCartIcon }} />
            </IconButton>
            <Typography className={classes.shareCartButtonLabel} variant="body1">
              {'topCard.shareLink'}
            </Typography>
          </Box>
          <Box className={classes.shareCartButtonWrapper}>
            <Link
              href={`https://wa.me/?text=${t('topCard.checkSharedCard')} ${encodeURIComponent(
                linkToShare,
              )}`}
              className={classes.shareCartButton}
              targetBlank
            >
              <Box className={classes.shareCartLabel}>
                <Icon name="icon-whatsapp" classes={{ root: classes.shareCartIcon }} />
              </Box>
            </Link>
            <Typography className={classes.shareCartButtonLabel} variant="body1">
              {'topCard.whatsapp'}
            </Typography>
          </Box>
          <Box
            className={classes.shareCartButtonWrapper}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {/* TODO Check how it will work on windows, on Mac it opens Mail app */}
            <Link
              href={`mailto:?subject=${t('topCard.emailSharedCard', {
                storeName: websiteDetails.store.name,
              })}&body=${encodeURIComponent(linkToShare)}`}
              className={classes.shareCartButton}
              targetBlank
            >
              <Box className={classes.shareCartLabel}>
                <Icon name="icon-mail" classes={{ root: classes.shareCartIcon }} />
              </Box>
            </Link>
            <Typography className={classes.shareCartButtonLabel} variant="body1">
              {'input.email'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShareProductCartDialog;

import React, { FC } from 'react';

import { Box, FormControl, FormHelperText, FormLabel, IconButton } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import Map, { GOOGLE_MAP_CONTAINER_STYLE, GOOGLE_MAP_INITIAL_POSITION } from 'ui/common/Map';
import Icon from 'ui/common/icons/Icon';

import useStyles from './styles';

import { IMapInput } from './types';

const MapInput: FC<IMapInput> = ({
  resource = 'input',
  label,
  name,
  customLabel,
  fullWidth,
  helperText,
  error,
  isShowLabel = true,
  required,
  initialPosition,
  markerPosition,
  containerStyle = GOOGLE_MAP_CONTAINER_STYLE,
  onCloseMap,
  isTouched,
  ...rest
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const inputLabel = customLabel || t(`${resource}.${label || name}`);

  const initialPositionValue =
    !initialPosition || Object.keys(initialPosition).length === 0
      ? GOOGLE_MAP_INITIAL_POSITION
      : initialPosition;

  const markerPositionValue =
    !markerPosition || Object.keys(markerPosition).length === 0
      ? GOOGLE_MAP_INITIAL_POSITION
      : markerPosition;

  return (
    <FormControl fullWidth={fullWidth} size="small" component="fieldset" error={error}>
      {isShowLabel && (
        <FormLabel
          classes={{
            root: classes.labelRoot,
            focused: classes.labelFocused,
            error: classes.labelError,
            asterisk: classes.asterisk,
          }}
          component="legend"
          required={required}
        >
          {inputLabel}
        </FormLabel>
      )}
      <Box position="relative">
        {onCloseMap && (
          <IconButton size="small" className={classes.closeMapButtonRoot} onClick={onCloseMap}>
            <Icon
              name="icon-button-x"
              color="primary"
              fontSize="small"
              classes={{ root: classes.closeMapIcon }}
            />
          </IconButton>
        )}
        <Map
          isValid={error}
          isTouched={isTouched}
          initialPosition={initialPositionValue}
          markerPosition={markerPositionValue}
          containerStyle={containerStyle}
          {...rest}
        />
      </Box>
      {helperText && (
        <FormHelperText classes={{ root: classes.helperText }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
export default MapInput;

import React, { FC, useMemo } from 'react';

import { merge } from 'lodash';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import Icon from 'ui/common/icons/Icon';

import useStyles from './styles';

import { ITextInput } from './types';

const TextInput: FC<ITextInput> = ({
  resource = 'input',
  label,
  customLabel,
  name,
  isShowLabel = true,
  helperText,
  error,
  classes: overrideClasses,
  fullWidth = true,
  startIcon,
  endIcon,
  customIconColor,
  iconSize = 'small',
  iconStyles,
  required,
  ...rest
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const inputLabel = customLabel || t(`${resource}.${label || name}`);

  const renderIconProp = useMemo<
    Pick<OutlinedInputProps, 'startAdornment' | 'endAdornment'>
  >(() => {
    const iconProps: Pick<OutlinedInputProps, 'startAdornment' | 'endAdornment'> = {};

    if (startIcon) {
      iconProps.startAdornment = (
        <Icon
          color={error ? 'error' : customIconColor || 'primary'}
          name={startIcon}
          fontSize={iconSize}
          classes={iconStyles}
        />
      );
    }

    if (endIcon) {
      iconProps.endAdornment = (
        <Icon
          color={error ? 'error' : customIconColor || 'primary'}
          name={endIcon}
          fontSize={iconSize}
          classes={iconStyles}
        />
      );
    }

    return iconProps;
  }, [startIcon, endIcon, error, customIconColor, iconSize, iconStyles]);

  return (
    <FormControl fullWidth={fullWidth} size="small" component="fieldset" error={error}>
      {isShowLabel && (
        <FormLabel
          classes={{
            root: classes.labelRoot,
            focused: classes.labelFocused,
            error: classes.labelError,
            asterisk: classes.asterisk,
          }}
          component="legend"
          required={required}
        >
          {inputLabel}
        </FormLabel>
      )}
      <OutlinedInput
        placeholder={inputLabel}
        error={error}
        classes={merge(
          {
            root: classes.root,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
            adornedStart: classes.adornedStart,
            inputAdornedStart: classes.inputAdornedStart,
            error: classes.error,
          },
          overrideClasses,
        )}
        name={name}
        required={required}
        {...renderIconProp}
        {...rest}
      />
      {helperText && (
        <FormHelperText classes={{ root: classes.helperText }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default TextInput;

import React, { FC, useMemo } from 'react';

import { Icon as MUIIcon } from '@material-ui/core';

import useStyles from './styles';

import { IIcon, TRenderPathArray } from './types';

const Icon: FC<IIcon> = ({ name, classes, ...rest }) => {
  const defaultClasses = useStyles();

  const renderPathArr = useMemo<TRenderPathArray>(
    () => (arrLength) => {
      // create array with choosen length with empty strings
      // do not remove .fill('') because map will not work
      const spanArr = new Array(arrLength).fill('');

      return spanArr.map((_, index) => {
        const key = index + 1;
        return <span key={key} className={`path${key}`} />;
      });
    },
    [],
  );

  // need for render colored icons
  const renderChildren = useMemo(() => {
    // length depend on each icon, u can check in graphics/icons/style.css file
    let childrenLength: null | number = null;

    switch (name) {
      case 'icon-diners':
        childrenLength = 43;
        break;
      case 'icon-isracard':
        childrenLength = 10;
        break;
      case 'icon-visa':
        childrenLength = 6;
        break;
      case 'icon-americanExpress':
      case 'icon-mastercard':
        childrenLength = 4;
        break;
      default:
        break;
    }

    if (childrenLength) {
      return renderPathArr(childrenLength);
    }
    return childrenLength;
  }, [name, renderPathArr]);

  return (
    <MUIIcon
      classes={{
        colorAction: defaultClasses.colorAction,
        fontSizeSmall: defaultClasses.fontSizeSmall,
        ...classes,
      }}
      className={`${name}`}
      {...rest}
    >
      {renderChildren}
    </MUIIcon>
  );
};

export default Icon;

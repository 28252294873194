import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IProductSellingUnit } from 'store';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import { UnitSwitcher } from './components';

import useStyles from './styles';

import { ICartItemQuantityAndUnitControls, TRenderIcon } from './types';

const CartItemQuantityAndUnitControls: FC<ICartItemQuantityAndUnitControls> = (props) => {
  const {
    requestedQuantity,
    productSellingUnits = [],
    activeSellingUnit,
    orientation = 'vertical',
    onChooseUnit,
    onChangeProductCount,
  } = props;

  const classes = useStyles(props);

  const { t } = useTranslation();

  const handleChangeProductCount = useCallback(
    (event, action) => {
      if (onChangeProductCount) {
        onChangeProductCount(action);
      }

      // avoid link redirect
      event.preventDefault();
    },
    [onChangeProductCount],
  );

  const handleChangeSellingUnit = useCallback(
    (sellingUnitId: IProductSellingUnit['id']) => {
      if (onChooseUnit) {
        onChooseUnit(sellingUnitId);
      }
    },
    [onChooseUnit],
  );

  const renderQuantity = useMemo(() => {
    return (
      <Box className={classes.quantity}>
        <Typography
          variant="body2"
          fontWeight="bold"
          color="textPrimary"
          fontSize={16}
          align="center"
        >
          {requestedQuantity}
        </Typography>
      </Box>
    );
  }, [classes, requestedQuantity]);

  const renderUnitSwitcher = useMemo<ReactNode>(() => {
    return (
      <UnitSwitcher
        productSellingUnits={productSellingUnits}
        activeSellingUnit={activeSellingUnit}
        onChange={handleChangeSellingUnit}
      />
    );
  }, [productSellingUnits, activeSellingUnit, handleChangeSellingUnit]);

  const renderIcon = useMemo<TRenderIcon>(
    () => (iconName) => {
      return <Icon name={iconName} color="action" classes={{ root: classes.iconRoot }} />;
    },
    [classes],
  );

  const renderQuantityButton = useMemo(() => {
    switch (orientation) {
      case 'vertical':
        return (
          <>
            <Button
              classes={{ root: classes.buttonRoot }}
              onClick={(e) => handleChangeProductCount(e, 'add')}
            >
              {renderIcon('icon-plus')}
            </Button>
            {renderQuantity}

            {renderUnitSwitcher}

            <Button
              classes={{ root: classes.buttonRoot }}
              onClick={(e) => handleChangeProductCount(e, 'remove')}
            >
              {renderIcon('icon-minus')}
            </Button>
          </>
        );
      case 'horizontal':
        return (
          <>
            <Box className={classes.buttonsWrapper}>
              <Button
                classes={{ root: classNames(classes.buttonRoot, classes.horizontalPlusBtn) }}
                onClick={(e) => handleChangeProductCount(e, 'add')}
              >
                {renderIcon('icon-plus')}
              </Button>
              {renderQuantity}

              <Button
                classes={{ root: classNames(classes.buttonRoot, classes.horizontalMinusBtn) }}
                onClick={(e) => handleChangeProductCount(e, 'remove')}
              >
                {renderIcon('icon-minus')}
              </Button>
            </Box>
            <Box className={classes.unitSwitcherSection}>{renderUnitSwitcher}</Box>
          </>
        );
      case 'block':
        return (
          <Box>
            <Box className={classes.blockOrientation}>
              <Button
                classes={{ root: classNames(classes.buttonRoot, classes.horizontalPlusBtn) }}
                onClick={(e) => handleChangeProductCount(e, 'add')}
                aria-label={t('button.increaseQuantity')}
              >
                {renderIcon('icon-plus')}
              </Button>
              {renderQuantity}

              <Button
                classes={{ root: classNames(classes.buttonRoot, classes.horizontalMinusBtn) }}
                onClick={(e) => handleChangeProductCount(e, 'remove')}
                aria-label={t('button.decreaseQuantity')}
              >
                {renderIcon('icon-minus')}
              </Button>
            </Box>

            <Box className={classes.unitSwitcherSection}>{renderUnitSwitcher}</Box>
          </Box>
        );
      default:
        return null;
    }
  }, [
    orientation,
    classes.buttonRoot,
    classes.buttonsWrapper,
    classes.horizontalPlusBtn,
    classes.horizontalMinusBtn,
    classes.unitSwitcherSection,
    classes.blockOrientation,
    renderIcon,
    renderQuantity,
    renderUnitSwitcher,
    t,
    handleChangeProductCount,
  ]);

  return <Box className={classes.wrapper}>{renderQuantityButton}</Box>;
};
export default CartItemQuantityAndUnitControls;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesLocationTypeButton } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLocationTypeButton> =>
    createStyles({
      iconButton: {
        borderRadius: '6px',
        border: `solid 1.5px ${theme.palette.primary.main}`,
        padding: '0px',
        width: '100%',
        height: '100%',
      },
      iconButtonActive: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
      },
      iconButtonLabel: {
        padding: '8px',
        flexDirection: 'column',
      },
      iconButtonIcon: {
        fontSize: '22px',
        marginBottom: theme.direction === 'rtl' ? '2px' : '4px',
      },
      iconButtonDescription: {
        color: theme.palette.grey.A700,
        fontSize: '14px',
        lineHeight: theme.direction === 'rtl' ? '18px' : '14px',
        width: theme.direction === 'rtl' ? '72px' : '74px',
      },
      iconButtonDescriptionActive: {
        color: theme.palette.background.paper,
        fontWeight: 500,
      },
    }),
);

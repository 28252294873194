import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import {
  ITypography,
  TFontSizeObj,
  TFontWeightObj,
  TRenderFontSize,
  TRenderFontWeight,
  TRenderLineHeihgt,
  TStylesTypography,
} from './types';

const renderFontSize: TRenderFontSize = (fontSize = 18) => {
  const fontSizeObj: TFontSizeObj = {
    26: 3.25, // 26px
    24: 3, // 24px
    22: 2.75, // 22px
    20: 2.5, // 20px
    18: 2.25, // 18px
    16: 2, // 16px
    14: 1.75, // 14px
    13: 1.625, // 13px
    12: 1.5, // 12px
    10: 1.25, // 10px
  };

  return fontSizeObj[fontSize];
};

const renderLineHeihgt: TRenderLineHeihgt = (fontSize = 18) => {
  const lineHeightObj: TFontSizeObj = {
    26: 3.75, // 30px
    24: 3.5, // 28px
    22: 3, // 24px
    20: 3, // 24px
    18: 2.75, // 22px
    16: 2.5, // 20px
    14: 2.25, // 18px
    13: 2, // 16px
    12: 2, // 16px
    10: 1.25, // 10px
  };

  return lineHeightObj[fontSize];
};

const renderFontWeight: TRenderFontWeight = (fontWeight = 'regular') => {
  const fontWeightObj: TFontWeightObj = {
    bold: 'bold',
    medium: 500,
    semiBold: 600,
    semiBoldRoboto: 700,
    regular: 400,
  };

  return fontWeightObj[fontWeight];
};

const downBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesTypography, ITypography> =>
    createStyles({
      light: {
        color: theme.palette.background.paper,
      },
      grey: {
        color: theme.palette.grey[600],
      },
      mediumGrey: {
        color: theme.palette.grey.A400,
      },
      error: {
        color: theme.palette.error.main,
      },
      h1: {
        fontSize: theme.spacing(4.5), // 36px
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
        lineHeight: `${theme.spacing(4.75)}px`, // 38px
        letterSpacing: 0,

        [theme.breakpoints.down(downBreakpoint)]: {
          fontSize: theme.spacing(4), // 32px
          lineHeight: `${theme.spacing(4)}px`, // 32px
          letterSpacing: -0.2,
        },
      },
      h2: {
        fontSize: theme.spacing(3.75), // 30px
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
        lineHeight: `${theme.spacing(4.25)}px`, // 34px
        letterSpacing: 0,

        [theme.breakpoints.down(downBreakpoint)]: {
          fontSize: theme.spacing(3.25), // 26px
          lineHeight: `${theme.spacing(3.5)}px`, // 28px
        },
      },
      h3: {
        fontSize: theme.spacing(2.75), // 22px
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
        lineHeight: `${theme.spacing(3)}px`, // 24px
        letterSpacing: -0.2,

        [theme.breakpoints.down(downBreakpoint)]: {
          letterSpacing: 0,
        },
      },
      h4: {
        fontSize: theme.spacing(2.25), // 18px
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
        lineHeight: `${theme.spacing(2.75)}px`, // 22px
        letterSpacing: 0,
      },
      body1: {
        fontSize: ({ fontSize }) => theme.spacing(renderFontSize(fontSize)),
        lineHeight: ({ fontSize }) => `${theme.spacing(renderLineHeihgt(fontSize))}px`,
        fontWeight: ({ fontWeight }) => renderFontWeight(fontWeight),
        letterSpacing: -0.1,
      },
      body2: {
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: ({ fontSize }) => theme.spacing(renderFontSize(fontSize)),
        lineHeight: ({ fontSize }) => `${theme.spacing(renderLineHeihgt(fontSize))}px`,
        fontWeight: ({ fontWeight }) => renderFontWeight(fontWeight),
        letterSpacing: -0.2,
      },
    }),
);

import { Box, Divider } from '@material-ui/core';
import classNames from 'classnames';
import SkeletonSimilarProductsSlider from 'components/common/skeletons/SkeletonSimilarProductsSlider';
import { useMobile } from 'hooks';
import { useProduct } from 'hooks/useProduct';
import useRelevancy from 'hooks/useRelevancy';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderSelectors } from 'store';
import { IStoreProductDisplay } from 'store/modules/storeProduct';

import Container from 'ui/common/Container';

import SimilarProductsSlider from 'ui/common/SimilarProductsSlider';
import Typography from 'ui/common/Typography';
import ProductDescription from 'ui/desktop/ProductDescription';

import useStyles from './styles';
import { IProductDetails } from './types';

const ProductDetails: FC<IProductDetails> = (props) => {
  const {
    onChooseUnit,
    onAddProduct,
    onChangeProductCount,
    handleProductDetailsPopup,
    isPopup,
    showSimilarItems,
    ...storeProductDisplay
  } = props;
  const { getDisplayableStoreProduct, getSimilarProductsIds } = useProduct();
  const { renderRelevancy, relevancyDefaultText } = useRelevancy(
    storeProductDisplay.currentRelevancy?.name,
  );
  const orderItems = useSelector(orderSelectors.orderItems);

  const [similarProductsIds, setSimilarProductsIds] = useState<number[]>([]);
  const [loadingSimilarProducts, setLoadingSimilarProducts] = useState(true);

  const { isMobile } = useMobile();

  const slideItems = useMemo<IStoreProductDisplay[]>(() => {
    const similarProductsArray: IStoreProductDisplay[] = [];
    if (!similarProductsIds) return [];
    similarProductsIds.forEach((productId) => {
      const similarProduct = getDisplayableStoreProduct(productId);
      if (similarProduct) {
        similarProductsArray.push(similarProduct);
      }
    });

    return similarProductsArray;
  }, [getDisplayableStoreProduct, similarProductsIds]);

  const classes = useStyles({
    isPopup: isPopup || false,
    bagOfProducts: storeProductDisplay.bagOfProducts,
    hasAdditionalImages: !!storeProductDisplay.additionalImages.length,
    isSlideItems: !!slideItems.length,
  });

  const slidesPerView = useMemo(() => {
    if (isPopup) {
      if (slideItems.length > 3) {
        return 3;
      }
      return slideItems.length;
    }

    if (slideItems.length > 4) {
      return 4;
    }
    return slideItems.length;
  }, [isPopup, slideItems.length]);

  const shouldDisplaySliderArrowsOnDesktop = useMemo(() => {
    if (isPopup && slideItems.length > 3) return true;
    return !isPopup && slideItems.length > 4;
  }, [isPopup, slideItems.length]);

  useEffect(() => {
    if (!storeProductDisplay.id) return;

    const storeProduct = getDisplayableStoreProduct(storeProductDisplay.id);

    if (storeProduct && storeProduct.obfuscatedId) {
      getSimilarProductsIds(storeProduct.obfuscatedId, {
        setLoading: setLoadingSimilarProducts,
      }).then((similarProductsIdsServer) => {
        const similarProductsIdsWhichDoesntExistOnTheCart = similarProductsIdsServer.filter(
          (similarProductId) =>
            !orderItems.some(({ storeProduct: cartItemId }) => similarProductId === cartItemId.id),
        );

        setSimilarProductsIds(similarProductsIdsWhichDoesntExistOnTheCart);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeProductDisplay.id]);

  return (
    <Container className={classes.mainWrapper}>
      <Box className={classes.storeCardWrapper} mb={isMobile && !slideItems.length ? 3 : 0}>
        <Box className={classes.storeCard}>
          {/* product details */}
          <ProductDescription
            {...storeProductDisplay}
            onChooseUnit={onChooseUnit}
            onAddProduct={onAddProduct}
            onChangeProductCount={onChangeProductCount}
            isPopup={isPopup}
          />
        </Box>
        {storeProductDisplay.bagOfProducts &&
          (storeProductDisplay.productExtraDetails || storeProductDisplay.currentRelevancy) && (
            <Box>
              <Box mt={isMobile ? 0 : 40 / 8}>
                <Divider classes={{ root: classes.divider }} />
                <Box className={classes.bagOfProductsTitleDesktopWrapper}>
                  <Typography
                    fontWeight="bold"
                    className={classNames(classes.title, 'font-family-heebo')}
                    variant="body1"
                  >
                    {'bagOfProducts.moreDetails'}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    className={classes.bagExtraDetails}
                    isTranslate={false}
                  >
                    {storeProductDisplay.productExtraDetails}
                  </Typography>
                  {storeProductDisplay.currentRelevancy && (
                    <Box className={classes.ratingWrapper}>
                      {renderRelevancy}
                      <Typography
                        className={classes.currentRelevancyFreeText}
                        variant="body1"
                        isTranslate={false}
                      >
                        {storeProductDisplay.currentRelevancyFreeText || relevancyDefaultText}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        {showSimilarItems && (
          <>
            {/* product slider desktop */}
            {slideItems && !!slideItems.length && !isMobile && (
              <Box>
                <Box mt={storeProductDisplay.bagOfProducts ? 30 / 8 : 40 / 8}>
                  <Divider classes={{ root: classes.divider }} />
                  <Box className={classes.titleDesktopWrapper}>
                    <Typography
                      fontWeight="bold"
                      className={classNames(
                        classes.title,
                        classes.titleYouMayAlsoWant,
                        'font-family-heebo',
                      )}
                      variant="body1"
                    >
                      {'comments.youMayAlsoWant'}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.sliderDesktop}>
                  {loadingSimilarProducts ? (
                    <SkeletonSimilarProductsSlider />
                  ) : (
                    <SimilarProductsSlider
                      sourceEvent="similarProductsSectionDesktop"
                      allowTouchMove={false}
                      slideItems={slideItems}
                      slidesPerView={slidesPerView}
                      spaceBetween={isPopup ? 23 : 22}
                      slideWidth={isPopup ? 218 : 208}
                      displayArrows={shouldDisplaySliderArrowsOnDesktop}
                    />
                  )}
                </Box>
              </Box>
            )}
            {/* /!* product slider mobile *!/ */}
            {slideItems && !!slideItems.length && isMobile && (
              <Box maxWidth={600} margin="0 auto">
                <Divider classes={{ root: classes.dividerMobile }} />
                <Box className={classes.sliderTitleMobile}>
                  <Typography
                    fontWeight="bold"
                    className={classNames(classes.title, 'font-family-heebo')}
                    variant="body1"
                  >
                    {'comments.youMayAlsoWant'}
                  </Typography>
                </Box>
                <Box className={classes.sliderMobile}>
                  {loadingSimilarProducts ? (
                    <SkeletonSimilarProductsSlider />
                  ) : (
                    <SimilarProductsSlider
                      sourceEvent="similarProductsSectionMobile"
                      slideItems={slideItems}
                      spaceBetween={12}
                      displayArrows={false}
                      slidesPerView="auto"
                      slideWidth={200}
                    />
                  )}
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default ProductDetails;

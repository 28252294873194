import {
  UPDATE_COURIER_TIP,
  UPDATE_LIMITATION_BY_AGE_CHECKED,
  UPDATE_ORDER_DETAILS,
  UPDATE_ORDER_MODE,
} from 'store/modules/orderDetails/constants';

import { LOG_OUT_SUCCESS } from 'store/modules/auth';
import { PLACE_ORDER_SUCCESS } from 'store/modules/order';
import { initialOrderDetailsState } from './initialState';
import { TOrderDetailsReducer } from './types';

const orderDetailsReducer: TOrderDetailsReducer = (state = initialOrderDetailsState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_DETAILS:
      return {
        ...action.payload,
      };
    case UPDATE_ORDER_MODE:
      return {
        ...state,
        orderMode: action.payload,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...initialOrderDetailsState,
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...initialOrderDetailsState,
        orderType: action.payload.defaultOrderType,
      };
    case UPDATE_COURIER_TIP:
      return {
        ...state,
        courierTip: action.payload,
      };
    case UPDATE_LIMITATION_BY_AGE_CHECKED:
      return {
        ...state,
        limitationByAgeChecked: action.payload,
      };
    default:
      return state;
  }
};

export { orderDetailsReducer };

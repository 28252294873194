import React, { FC, ReactNode, useMemo } from 'react';

import { Box, Chip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';

import { ISaleHorizontalTagWithPrice } from './types';

import useStyles from './styles';

const SaleHorizontalTagWithPrice: FC<ISaleHorizontalTagWithPrice> = ({
  backgroundColor = '#005350',
  children,
}) => {
  const lang = useSelector(getLang);

  const classes = useStyles({ backgroundColor, lang });

  const renderLabel = useMemo<ReactNode>(() => {
    return (
      <>
        <Box className={classes.leftBottomTriangle} />
        <Box className={classes.rightBottomTriangle} />
        {children}
      </>
    );
  }, [children, classes.leftBottomTriangle, classes.rightBottomTriangle]);

  return <Chip label={renderLabel} classes={{ root: classes.chipRoot, label: classes.label }} />;
};

export default SaleHorizontalTagWithPrice;

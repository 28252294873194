import { Box } from '@material-ui/core';
import { useNotify } from 'hooks';
import { isString } from 'lodash';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import Icon from 'ui/common/icons/Icon';

import Snackbar from 'ui/common/Snackbar';
import useStyles from './styles';

import { INotify, THandleClose } from './types';

const Notify: FC<INotify> = () => {
  const {
    notifyHide,
    NotifyData: { message, type, onClose, autoHideDuration = 5000, ...rest },
  } = useNotify();

  const { t, i18n } = useTranslation();

  const classes = useStyles({ type });

  const handleClose = useCallback<THandleClose>(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      if (onClose) {
        onClose(event, reason);
      }

      notifyHide();
    },
    [onClose, notifyHide],
  );

  const iconName = useMemo(() => {
    if (!type) return;

    switch (type) {
      case 'error':
        return 'icon-alert-circled';
      default:
        return 'icon-check-circled';
    }
  }, [type]);

  const renderContent = useMemo<ReactNode>(() => {
    const label = isString(message) && i18n.exists(message) ? t(message) : message;

    if (type) {
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name={iconName} classes={{ root: classes.notifyIcon }}></Icon>
          {label}
        </Box>
      );
    }

    return label;
  }, [message, i18n, t, type, iconName, classes.notifyIcon]);

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={renderContent}
      {...rest}
    />
  );
};

export default Notify;

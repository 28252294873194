import {
  TGetClientAddressSelector,
  TGetClientDetailsAtStoreLevelWereUpdated,
  TGetClubMembership,
  TGetDeliveryAreaSubCatalogId,
  TGetDetailsAtStoreLevelSelector,
  TGetProfileSelector,
  TGetUserSubCatalogIdSelector,
  TIsMinimumProfileDataCompletedSelector,
  TIsProfileCompletedSelector,
} from './types';

const getClientAddress: TGetClientAddressSelector = (state) => {
  const {
    user: { profile },
    activeOrder: { orderDetails },
  } = state;

  if (
    orderDetails.orderMode === 'edit' &&
    orderDetails.orderAddress &&
    orderDetails.orderType !== 'selfPickup' // order address for self pickup orders its address of point, we dont want to display it
  ) {
    return orderDetails.orderAddress;
  }

  if (profile.address?.addressType?.name === 'militaryBase') {
    return profile.address.cityAndCountry;
  }

  if (profile.address.address && profile.address.cityAndCountry) {
    return `${profile.address.address}, ${profile.address.cityAndCountry}`;
  }
  return '';
};

const isMinimumProfileDataCompleted: TIsMinimumProfileDataCompletedSelector = (state) => {
  const {
    user: { profile },
  } = state;

  return !!profile.cellPhone && !!profile.firstName && !!profile.lastName && !!profile.email;
};

const isProfileCompleted: TIsProfileCompletedSelector = (state) => {
  const address = getClientAddress(state);

  return isMinimumProfileDataCompleted(state) && !!address;
};

const getProfile: TGetProfileSelector = ({ user }) => user.profile;

const getDetailsAtStoreLevel: TGetDetailsAtStoreLevelSelector = ({ user }) =>
  user.detailsAtStoreLevel;

const getUserSubCatalogId: TGetUserSubCatalogIdSelector = ({ user }) => user.userSubCatalogId;

const getDeliveryAreaSubCatalogId: TGetDeliveryAreaSubCatalogId = ({ user }) =>
  user.deliveryAreaSubCatalogId;

const getClientDetailsAtStoreLevelWereUpdated: TGetClientDetailsAtStoreLevelWereUpdated = ({
  user,
}) => user.clientDetailsAtStoreLevelWereUpdated;

const getClubMembership: TGetClubMembership = ({ user }) => user.clubMembership;

export {
  isProfileCompleted,
  isMinimumProfileDataCompleted,
  getProfile,
  getClientAddress,
  getDetailsAtStoreLevel,
  getUserSubCatalogId,
  getDeliveryAreaSubCatalogId,
  getClientDetailsAtStoreLevelWereUpdated,
  getClubMembership,
};

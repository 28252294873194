import { TSocialAndContactItem } from 'ui/desktop/Footer';
import { IWebsiteDetails } from 'store';
import { formatInternationalPhoneNumber } from 'utils';

export const storeContactItems: (websiteDetails: IWebsiteDetails) => TSocialAndContactItem[] = (
  websiteDetails,
) => {
  const contactItems: TSocialAndContactItem[] = [];

  if (
    websiteDetails.websiteSettings.displayAddress &&
    websiteDetails.store.businessFullAddressWithCity &&
    !websiteDetails.store.hasOnlineBranches
  ) {
    contactItems.push({
      id: 1,
      label: websiteDetails.store.businessFullAddressWithCity,
      iconName: 'icon-location-2',
      link: {
        href: `https://maps.google.com?q=${encodeURIComponent(
          websiteDetails.store.businessFullAddressWithCity,
        )}`,
      },
      component: 'address',
      ariaLabel: 'footer.ariaLabels.storeAddress',
    });
  }

  if (websiteDetails.businessPhone) {
    contactItems.push({
      id: 2,
      label: websiteDetails.businessPhone,
      iconName: 'icon-phone',
      link: { href: `tel:${websiteDetails.businessPhone}` },
      ariaLabel: 'footer.ariaLabels.storePhone',
    });
  }

  if (websiteDetails.whatsappLink) {
    const internationalNumber = formatInternationalPhoneNumber(websiteDetails.whatsappLink);
    contactItems.push({
      id: 3,
      label: websiteDetails.whatsappLink,
      iconName: 'icon-whatsapp',
      link: { href: `https://wa.me/${internationalNumber}` },
      ariaLabel: 'footer.ariaLabels.storeWhatsapp',
    });
  }

  if (websiteDetails.businessEmail) {
    contactItems.push({
      id: 7,
      label: websiteDetails.businessEmail,
      iconName: 'icon-mail',
      link: { href: `mailto:${websiteDetails.businessEmail}` },
      ariaLabel: 'footer.ariaLabels.storeEmail',
    });
  }

  if (websiteDetails.facebookLink) {
    contactItems.push({
      id: 4,
      iconName: 'icon-facebook',
      link: { href: websiteDetails.facebookLink },
      ariaLabel: 'footer.ariaLabels.storeFacebook',
    });
  }

  if (websiteDetails.instagramLink) {
    contactItems.push({
      id: 5,
      iconName: 'icon-instagram',
      link: { href: websiteDetails.instagramLink },
      ariaLabel: 'footer.ariaLabels.storeInstagram',
    });
  }

  if (websiteDetails.youtubeLink) {
    contactItems.push({
      id: 6,
      iconName: 'icon-youtube',
      link: { href: websiteDetails.youtubeLink },
      ariaLabel: 'footer.ariaLabels.storeYoutube',
    });
  }

  return contactItems;
};

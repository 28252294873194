import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { IWebsiteDetails, websiteSelectors } from 'store';
import { getContentPagePreviewMode } from 'store/modules/uiStyles/selectors';
import { TUseWebsiteDetails } from './types';

// TODO should happen in redux (in order to avoid people using useSelector and getting a non-modified version)
const useWebsiteDetails: TUseWebsiteDetails = () => {
  const websiteDetails = useSelector(websiteSelectors.getWebsiteDetails);
  const isActiveContentPagePreviewMode = useSelector(getContentPagePreviewMode);

  const appendRootImagePath = useCallback((images: Record<string, string>) => {
    const newImages = { ...images };
    (Object.keys(newImages) as (keyof typeof newImages)[]).forEach((key) => {
      newImages[key] = `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${newImages[key]}`;
    });

    return newImages;
  }, []);

  return useMemo<IWebsiteDetails>(() => {
    return {
      ...websiteDetails,
      theme: {
        ...websiteDetails.theme,
        images: appendRootImagePath(
          websiteDetails.theme.images,
        ) as IWebsiteDetails['theme']['images'],
        aboutPage: {
          images:
            websiteDetails.theme.aboutPage &&
            (appendRootImagePath(
              websiteDetails.theme.aboutPage.images,
            ) as IWebsiteDetails['theme']['aboutPage']['images']),
        },
        homePage: {
          images:
            websiteDetails.theme.homePage &&
            (appendRootImagePath(
              websiteDetails.theme.homePage.images,
            ) as IWebsiteDetails['theme']['homePage']['images']),
        },
        subscriptionPage: {
          images:
            websiteDetails.theme.subscriptionPage &&
            (appendRootImagePath(
              websiteDetails.theme.subscriptionPage.images,
            ) as IWebsiteDetails['theme']['subscriptionPage']['images']),
        },
        customizedPage: {
          images: {
            textInsideImage:
              websiteDetails.theme.customizedPage &&
              (appendRootImagePath(
                websiteDetails.theme.customizedPage.images.textInsideImage,
              ) as IWebsiteDetails['theme']['customizedPage']['images']['textInsideImage']),
          },
        },
        notFoundPage: {
          images: {
            center:
              websiteDetails?.theme.notFoundPage &&
              `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${websiteDetails?.theme.notFoundPage.images.center}`,
          },
        },
      },
      logoPath: `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${websiteDetails?.logoPath}`,
      coverDesktopImagePath: `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${websiteDetails?.coverDesktopImagePath}`,
      coverMobileImagePath: `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${websiteDetails?.coverMobileImagePath}`,
      genericProductsDefaultImagePath: `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${websiteDetails?.genericProductsDefaultImagePath}`,
      contentPages: isActiveContentPagePreviewMode
        ? websiteDetails.contentPages || []
        : (websiteDetails.contentPages || []).filter((contentPage) => contentPage.published),
      favoriteIconPath: `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${websiteDetails.favoriteIconPath}`,
    };
  }, [appendRootImagePath, isActiveContentPagePreviewMode, websiteDetails]);
};

export default useWebsiteDetails;

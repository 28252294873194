import React, { FC, useCallback } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CardMedia,
  ClickAwayListener,
  Divider,
  Slide,
  useTheme,
} from '@material-ui/core';
import Typography from 'ui/common/Typography';

import Icon from 'ui/common/icons/Icon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import useStyles from './styles';
import { IPWAApplePrompt, THandleClose } from './types';

const PWAApplePrompt: FC<IPWAApplePrompt> = ({ open, onClose }) => {
  const { name } = useSelector(getWebsiteDetails);

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClose = useCallback<THandleClose>(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <ClickAwayListener onClickAway={handleClose}>
        <Slide in={open} direction="up">
          <Box className={classes.installPromptWrapper}>
            <Box className={classes.topSectionWrapper}>
              <Typography className={classes.titleTypography}>{'pwa.iosPromptTitle'}</Typography>
              <Button onClick={handleClose} classes={{ root: classes.closeIcon }} disableRipple>
                {t('button.cancel')}
              </Button>
            </Box>
            <Divider
              style={{
                backgroundColor: '#eee',
              }}
            />
            <Box className={classes.bottomSectionWrapper}>
              <Typography className={classes.descriptionTypography}>
                {t('pwa.iosPromptDescription', { storeName: name })}
              </Typography>
              <Box mt={15 / 8}>
                <Box display="flex" alignItems="center">
                  <CardMedia
                    image="/assets/images/ios-share-icon.svg"
                    component="img"
                    className={classes.shareIcon}
                    alt=""
                  />
                  <Typography
                    className={classes.stepTypography}
                    style={{ marginTop: theme.spacing(13 / 8) }}
                  >
                    {t('pwa.iosPromptStep1')}
                  </Typography>
                </Box>
              </Box>
              <Box mt={22 / 8}>
                <Box display="flex" alignItems="center">
                  <Icon name="icon-add" classes={{ root: classes.addIcon }} />
                  <Typography className={classes.stepTypography}>
                    {t('pwa.iosPromptStep2')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Slide>
      </ClickAwayListener>
    </Backdrop>
  );
};

export default PWAApplePrompt;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesLoginCodeDialog } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesLoginCodeDialog,
    { numberOfAuthMethod: number; newEmailAllowed: boolean; email: boolean }
  > =>
    createStyles({
      root: {
        padding: theme.spacing(30 / 8, 2.5, 0),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(40 / 8, 70 / 8, 70 / 8),
        },
      },
      logo: {
        width: theme.spacing(130 / 8),
        height: 'auto',
        margin: '0 auto',
      },
      title: {
        marginTop: theme.spacing(20 / 8),
      },
      titleMain: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(1.25), // 10px
        },
      },
      subTitle: {
        letterSpacing: -0.2,
      },
      cellPhone: {
        letterSpacing: 0,
      },
      codeInput: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(22 / 8),
      },
      rootButton: {
        maxWidth: theme.spacing(320 / 8),
        width: '100%',
        margin: '0 auto',
        marginTop: theme.spacing(22 / 8),
      },
      footer: {
        marginTop: theme.spacing(2.5), // 20px

        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(2.75), // 22px
        },
      },
      footerItem: {
        marginBottom: theme.spacing(1.25), // 10px
      },
      linkBtn: {
        minWidth: 'unset',
        padding: 0,
      },
      linkText: {
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
      labelButton: {
        display: 'block',
      },
      descriptionOfAuthMethod: {
        fontSize: theme.spacing(14 / 8),
        fontWeight: 500,
        color: theme.palette.primary.main,
        lineHeight: '16px',
      },
      verticalDivider: {
        borderLeft: '1px solid #e9eded',
        height: '44px',
        marginTop: theme.spacing(6 / 8),
      },
      btnRoot: {
        height: '56px',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.light} !important`,
          borderRadius: '6px',
          height: '56px',
        },

        '& .MuiTouchRipple-root span': {
          backgroundColor: `${theme.palette.primary.light} !important`,
          borderRadius: '6px',
          height: '70px',
        },
      },
      opacityBlock: ({ numberOfAuthMethod, newEmailAllowed, email }) => ({
        maxWidth: theme.spacing(
          numberOfAuthMethod === 3 || (numberOfAuthMethod === 2 && !newEmailAllowed && !email)
            ? 320 / 8
            : 214 / 8,
        ),
        margin: '0 auto',
        display:
          numberOfAuthMethod === 1 || (numberOfAuthMethod === 2 && !newEmailAllowed && !email)
            ? 'block'
            : 'flex',
        justifyContent: 'space-between',
        background: theme.palette.background.paper,
        opacity: 0.18,
        marginTop: theme.spacing(11 / 8),
      }),
      rootBlock: ({ numberOfAuthMethod, newEmailAllowed, email }) => ({
        maxWidth: theme.spacing(
          numberOfAuthMethod === 3 || (numberOfAuthMethod === 2 && !newEmailAllowed && !email)
            ? 320 / 8
            : 214 / 8,
        ),
        margin: '0 auto',
        display:
          numberOfAuthMethod === 1 || (numberOfAuthMethod === 2 && !newEmailAllowed && !email)
            ? 'block'
            : 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(11 / 8),
      }),
      counterWrapper: {
        position: 'absolute',
        right: '50%',
        bottom: '12%',
        transform: 'translate(50%, -12%)',
      },
    }),
);

const SHOW_DIALOG = 'dialog/SHOW_DIALOG';
const HIDE_DIALOG = 'dialog/HIDE_DIALOG';

// dialog types
const WELCOME_DIALOG = 'WELCOME_DIALOG ';
const GENERIC_DIALOG = 'GENERIC_DIALOG';
const LOCATION_DIALOG = 'LOCATION_DIALOG';
const LOCATION_HOURS_DIALOG = 'LOCATION_HOURS_DIALOG';
const LOGIN_DIALOG = 'LOGIN_DIALOG';
const LOGIN_CODE_DIALOG = 'LOGIN_CODE_DIALOG';
const SIGN_UP_DIALOG = 'SIGN_UP_DIALOG';
const DELIVERY_DIALOG = 'DELIVERY_DIALOG';
const SELF_PICKUP_DIALOG = 'SELF_PICKUP_DIALOG';
const PREVIOUS_ORDER_ITEMS_DIALOG = 'PREVIOUS_ORDER_ITEMS_DIALOG';
const SUSPEND_SUBSCRIPTION_DIALOG = 'SUSPEND_SUBSCRIPTION_DIALOG';
const PRODUCT_DETAILS_DIALOG = 'PRODUCT_DETAILS_DIALOG';
const TERMS_OF_USE_DIALOG = 'TERMS_OF_USE_DIALOG';
const PRIVACY_POLICY_DIALOG = 'PRIVACY_POLICY_DIALOG';
const ACCESSIBILITY_DIALOG = 'ACCESSIBILITY_DIALOG';
const EXISTING_ORDER_DIALOG = 'EXISTING_ORDER_DIALOG';
const ACTIVE_SUBSCRIPTION_DIALOG = 'ACTIVE_SUBSCRIPTION_DIALOG';
const BASKET_DIALOG = 'BASKET_DIALOG';
const EMAIL_VERIFICATION_DIALOG = 'EMAIL_VERIFICATION_DIALOG';
const STORE_OPEN_HOURS_DIALOG = 'STORE_OPEN_HOURS_DIALOG';
const STORE_DELIVERY_HOURS_DIALOG = 'STORE_DELIVERY_HOURS_DIALOG';
const DELIVERING_TO_ADDRESS_DIALOG = 'DELIVERING_TO_ADDRESS_DIALOG';
const DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG = 'DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG';
const LOAD_PREVIOUS_ORDERS_DIALOG = 'LOAD_PREVIOUS_ORDERS_DIALOG';
const SHARE_PRODUCT_CART_DIALOG = 'SHARE_PRODUCT_CART_DIALOG';
const CART_WAS_SHARED_DIALOG = 'CART_WAS_SHARED_DIALOG';
const ITEMS_PROMOTION_DIALOG = 'ITEMS_PROMOTION_DIALOG';
const PAYMENT_BY_BIT_WAS_DECLINED_DIALOG = 'PAYMENT_BY_BIT_WAS_DECLINED_DIALOG';
const ORDER_EDITING_IS_NOT_POSSIBLE_DIALOG = 'ORDER_EDITING_IS_NOT_POSSIBLE_DIALOG';
const MEMBER_PROMOTION_DISCOUNT_DIALOG = 'MEMBER_PROMOTION_DISCOUNT_DIALOG';
export {
  SHOW_DIALOG,
  HIDE_DIALOG,
  WELCOME_DIALOG,
  GENERIC_DIALOG,
  LOCATION_DIALOG,
  LOCATION_HOURS_DIALOG,
  LOGIN_DIALOG,
  LOGIN_CODE_DIALOG,
  SIGN_UP_DIALOG,
  DELIVERY_DIALOG,
  SELF_PICKUP_DIALOG,
  PREVIOUS_ORDER_ITEMS_DIALOG,
  SUSPEND_SUBSCRIPTION_DIALOG,
  PRODUCT_DETAILS_DIALOG,
  TERMS_OF_USE_DIALOG,
  ACCESSIBILITY_DIALOG,
  PRIVACY_POLICY_DIALOG,
  EXISTING_ORDER_DIALOG,
  ACTIVE_SUBSCRIPTION_DIALOG,
  BASKET_DIALOG,
  EMAIL_VERIFICATION_DIALOG,
  STORE_OPEN_HOURS_DIALOG,
  STORE_DELIVERY_HOURS_DIALOG,
  DELIVERING_TO_ADDRESS_DIALOG,
  LOAD_PREVIOUS_ORDERS_DIALOG,
  DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG,
  SHARE_PRODUCT_CART_DIALOG,
  CART_WAS_SHARED_DIALOG,
  ITEMS_PROMOTION_DIALOG,
  PAYMENT_BY_BIT_WAS_DECLINED_DIALOG,
  ORDER_EDITING_IS_NOT_POSSIBLE_DIALOG,
  MEMBER_PROMOTION_DISCOUNT_DIALOG,
};

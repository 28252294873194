import { TOrderItemDTO, TStoreProductServer } from 'types';
import { IStoreProduct } from 'store';

export function setBagOfProductsReceivedJson(
  item: TOrderItemDTO,
  storeProductsById: Record<TStoreProductServer['id'], IStoreProduct>,
  outOfStockStoreProductById: Record<TStoreProductServer['id'], IStoreProduct>,
): TOrderItemDTO {
  const storeProduct =
    storeProductsById[item.storeProduct.id] || outOfStockStoreProductById[item.storeProduct.id];
  if (!storeProduct.bagOfProducts) {
    return item;
  }

  if (item.selectedBagItems) {
    return {
      ...item,
      receivedBagOfProductsJson: JSON.stringify({
        ...storeProduct.bagOfProductsJson,
        selectedItems: item.selectedBagItems,
      }),
    };
  }

  return { ...item, receivedBagOfProductsJson: JSON.stringify(storeProduct.bagOfProductsJson) };
}

import { TAuthReducer } from './types';

import {
  DEVICE_ID_UPDATE,
  JWE_TOKEN_UPDATE,
  LOG_OUT_SUCCESS,
  ON_CLIENT_BEHALF_UPDATE,
  SESSION_ONLY_JWE_TOKEN_UPDATE,
} from './constants';

import { initialAuthState } from './initialState';

const authReducer: TAuthReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case DEVICE_ID_UPDATE:
    case JWE_TOKEN_UPDATE:
    case SESSION_ONLY_JWE_TOKEN_UPDATE:
    case ON_CLIENT_BEHALF_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        jweToken: initialAuthState.jweToken,
        sessionOnlyJweToken: initialAuthState.sessionOnlyJweToken,
        onClientBehalf: initialAuthState.onClientBehalf,
      };
    default:
      return state;
  }
};

export { authReducer };

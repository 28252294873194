import { Box, Divider, FormHelperText, IconButton, useTheme } from '@material-ui/core';
import MembershipPromotionCard from 'components/common/MembershipPromotionCard';
import { useMobile } from 'hooks';
import { toNumber } from 'lodash';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TMembershipBenefits } from 'store';
import { getLang } from 'store/modules/config/selectors';
import { updateMembershipBenefits } from 'store/modules/orderDiscounts/actions';
import { getOrderMembershipBenefits } from 'store/modules/orderDiscounts/selectors';
import { promotionsData } from 'store/modules/promotions/selectors';
import { TUserBenefit } from 'types';
import CTAButton from 'ui/common/buttons/CTAButton';

import Icon from 'ui/common/icons/Icon';
import TextInput from 'ui/common/inputs/TextInput';
import Tooltip from 'ui/common/Tooltip';
import Typography from 'ui/common/Typography';
import { onlyNumber } from 'utils';

import useStyles from './styles';

const MemberPromotionDialog: FC = () => {
  const lang = useSelector(getLang);
  const orderMembershipBenefits = useSelector(getOrderMembershipBenefits);
  const { clubMembershipProfile } = useSelector(promotionsData);

  const { isMobile } = useMobile();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { t } = useTranslation();
  const [pointsToRedeem, setPointsToRedeem] = useState<string>();
  const [remainingPoints, setRemainingPoints] = useState<number>();
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [showEditPointsSection, setShowEditPointsSection] = useState<boolean>(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);
  const [promotionsToUseMap, setPromotionsToUseMap] =
    useState<TMembershipBenefits['requestedBenefits']>();

  const classes = useStyles({ lang });

  const onPointAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValueFormatted = onlyNumber(e.target.value as string);
      setIsShowError(false);

      if (inputValueFormatted) {
        setPointsToRedeem(inputValueFormatted);
        setDisabledButton(false);

        if (!toNumber(inputValueFormatted)) {
          setDisabledButton(true);
          return;
        }

        if (toNumber(inputValueFormatted) > clubMembershipProfile[0].totalPoints) {
          setDisabledButton(true);
          setIsShowError(true);
        }
        return;
      }

      setDisabledButton(true);
      setPointsToRedeem(undefined);
    },
    [clubMembershipProfile],
  );

  const updateBenefits = useCallback(
    (pointsToUse, promotionsMap) => {
      dispatch(
        updateMembershipBenefits({
          budgetToUse: toNumber(pointsToUse || 0),
          requestedBenefits: promotionsMap,
        }),
      );
    },
    [dispatch],
  );

  const submitPointsToUse = useCallback(() => {
    setRemainingPoints(clubMembershipProfile[0].totalPoints - toNumber(pointsToRedeem));
    setDisabledButton(true);
    setShowEditPointsSection(true);
    updateBenefits(pointsToRedeem, promotionsToUseMap);
  }, [clubMembershipProfile, pointsToRedeem, updateBenefits, promotionsToUseMap]);

  const onEditPointsClick = useCallback(() => {
    setShowEditPointsSection(false);
    setPointsToRedeem(undefined);
    setRemainingPoints(clubMembershipProfile[0].totalPoints);
    updateBenefits(0, promotionsToUseMap);
  }, [clubMembershipProfile, promotionsToUseMap, updateBenefits]);

  const onBenefitClick = useCallback(
    (promotion: TUserBenefit) => {
      setPromotionsToUseMap((prevState) => {
        const newMap = { ...prevState };

        if (newMap[promotion.id]) {
          delete newMap[promotion.id];
        } else {
          newMap[promotion.id] = promotion;
        }

        updateBenefits(pointsToRedeem, newMap);
        return newMap;
      });
    },
    [pointsToRedeem, updateBenefits],
  );

  const header = useMemo(() => {
    const tooltipProps = isMobile && {
      open: isOpenTooltip,
    };

    return (
      <Box className={classes.header}>
        <Box className={classes.headerTop}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Icon
                name="icon-diamond-membership"
                color="action"
                classes={{ root: classes.diamondMembership }}
              />
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" mb={2 / 8}>
                  <Typography color="light" className={classes.customerClubTitle}>
                    {`membership.${isMobile ? 'customerClub' : 'customerClubDesktop'}`}
                  </Typography>
                  <Tooltip
                    placement="bottom"
                    title={t('membership.calculatedByExternalCompany') as string}
                    initialClasses={{
                      tooltip: classes.tooltip,
                      arrow: classes.tooltipArrow,
                    }}
                    {...tooltipProps}
                  >
                    <Box
                      ml={1}
                      display="flex"
                      onClick={() => setIsOpenTooltip((prevState) => !prevState)}
                    >
                      <Icon
                        name="icon-question-1"
                        color="primary"
                        classes={{ root: classes.questionIcon }}
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography color="light" className={classes.memberNumber}>
                  {t('membership.memberNumber', {
                    memberNumber: clubMembershipProfile[0].memberId,
                  })}
                </Typography>
              </Box>
            </Box>
            {clubMembershipProfile[0].pointsEnabled && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  color="light"
                  style={{
                    fontSize: isMobile ? '22px' : '30px',
                    lineHeight: isMobile ? '24px' : '30px',
                  }}
                >
                  {remainingPoints}
                </Typography>
                <Typography color="light" fontSize={isMobile ? 13 : 14}>
                  {'membership.points'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }, [
    isMobile,
    isOpenTooltip,
    classes.header,
    classes.headerTop,
    classes.diamondMembership,
    classes.customerClubTitle,
    classes.tooltip,
    classes.tooltipArrow,
    classes.questionIcon,
    classes.memberNumber,
    t,
    clubMembershipProfile,
    remainingPoints,
  ]);

  const pointsInput = useMemo(() => {
    const helperText = isShowError
      ? t('membership.errorValueIsMoreThanPoints')
      : t('membership.equalToShekel', { equalNumber: pointsToRedeem || 0 });

    return (
      <Box display="flex">
        <Box style={{ width: '100%' }}>
          <TextInput
            inputProps={{
              inputMode: 'numeric',
            }}
            placeholder="0"
            isShowLabel={false}
            value={pointsToRedeem}
            onChange={onPointAmountChange}
            error={isShowError}
          />
          <FormHelperText
            classes={{ root: classes.helperText }}
            style={{ color: isShowError ? theme.palette.error.main : theme.palette.text.secondary }}
          >
            {helperText}
          </FormHelperText>
        </Box>
        <CTAButton
          onClick={submitPointsToUse}
          disabled={disabledButton}
          className={classes.redeemPointsButton}
          size="medium"
        >
          {'membership.redeemPoints'}
        </CTAButton>
      </Box>
    );
  }, [
    isShowError,
    t,
    pointsToRedeem,
    onPointAmountChange,
    classes.helperText,
    classes.redeemPointsButton,
    theme.palette.error.main,
    theme.palette.text.secondary,
    submitPointsToUse,
    disabledButton,
  ]);

  const addOrEditPoints = useMemo(() => {
    return showEditPointsSection ? (
      <Typography fontSize={16} color="textSecondary">
        {t('membership.redeemedPointsToShekel', { points: pointsToRedeem })}
      </Typography>
    ) : (
      pointsInput
    );
  }, [pointsToRedeem, pointsInput, showEditPointsSection, t]);

  const membershipPromotionsCards = useMemo(() => {
    return clubMembershipProfile[0].userBenefits.map((benefit) => (
      <Box mb={12 / 8} key={benefit.id}>
        <MembershipPromotionCard
          promotion={benefit}
          handleBenefitClick={onBenefitClick}
          outlinedButton={!!promotionsToUseMap?.[benefit.id]}
        />
      </Box>
    ));
  }, [clubMembershipProfile, onBenefitClick, promotionsToUseMap]);

  const points = useMemo(() => {
    if (!clubMembershipProfile[0].pointsEnabled) return;

    return (
      <>
        <Box display="flex" justifyContent="space-between" mb={14 / 8}>
          <Typography className={classes.pointLable} color="secondary">
            {'membership.points'}
          </Typography>
          {showEditPointsSection && (
            <IconButton
              classes={{ root: 'unstyled-button' }}
              size="small"
              onClick={onEditPointsClick}
            >
              <Icon
                name="icon-edit"
                color="primary"
                fontSize="medium"
                classes={{ root: classes.editPointIcon }}
              />
              {!isMobile && (
                <Typography
                  className={classes.editText}
                  variant="body1"
                  color="primary"
                  fontSize={16}
                  fontWeight="medium"
                >
                  {'button.edit'}
                </Typography>
              )}
            </IconButton>
          )}
        </Box>
        {addOrEditPoints}
      </>
    );
  }, [
    addOrEditPoints,
    classes.editPointIcon,
    classes.editText,
    classes.pointLable,
    clubMembershipProfile,
    isMobile,
    onEditPointsClick,
    showEditPointsSection,
  ]);

  const body = useMemo(() => {
    return (
      <Box className={classes.mainPartWrapper}>
        {points}
        {!!clubMembershipProfile[0].userBenefits.length && (
          <>
            {clubMembershipProfile[0].pointsEnabled && <Divider className={classes.divider} />}
            <Box mb={14 / 8}>
              <Typography className={classes.pointLable} color="secondary">
                {'membership.benefits'}
              </Typography>
            </Box>
            {membershipPromotionsCards}
          </>
        )}
      </Box>
    );
  }, [
    classes.divider,
    classes.mainPartWrapper,
    classes.pointLable,
    clubMembershipProfile,
    membershipPromotionsCards,
    points,
  ]);

  useEffect(() => {
    setRemainingPoints(clubMembershipProfile[0].totalPoints);
    if (orderMembershipBenefits?.budgetToUse) {
      setShowEditPointsSection(true);
      setPointsToRedeem(orderMembershipBenefits?.budgetToUse.toString());
      setRemainingPoints(
        clubMembershipProfile[0].totalPoints - orderMembershipBenefits?.budgetToUse,
      );
    }

    if (orderMembershipBenefits?.requestedBenefits) {
      setPromotionsToUseMap(orderMembershipBenefits?.requestedBenefits);
    }
    // don't put orderMembershipBenefits it will cause re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {header}
      {body}
    </Box>
  );
};

export default MemberPromotionDialog;

import {
  TCorrectBranchSubCatalogWasFetched,
  TGetCatalogId,
  TGetCatalogLastFetchTime,
  TGetLocalStorageCatalogLastUpdateTime,
  TGetSSRCatalogLastUpdateTime,
} from 'store/modules/catalog/types';

const getSSRCatalogLastUpdateTime: TGetSSRCatalogLastUpdateTime = ({ catalog }) =>
  catalog.ssrLastUpdateTime;

const getLocalStorageCatalogLastUpdateTime: TGetLocalStorageCatalogLastUpdateTime = ({ catalog }) =>
  catalog.localStorageLastUpdateTime;

const getCatalogId: TGetCatalogId = ({ catalog }) => catalog?.catalogId;
const getCorrectBranchSubCatalogWasFetched: TCorrectBranchSubCatalogWasFetched = ({ catalog }) =>
  catalog?.correctBranchSubCatalogWasFetched;

const getCatalogLastFetchTime: TGetCatalogLastFetchTime = ({ catalog }) => catalog.lastFetchTime;

export {
  getSSRCatalogLastUpdateTime,
  getLocalStorageCatalogLastUpdateTime,
  getCatalogId,
  getCorrectBranchSubCatalogWasFetched,
  getCatalogLastFetchTime,
};

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesProductDetailsDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesProductDetailsDialog> =>
    createStyles({
      productDetailsDialog: {
        overflow: 'hidden',
        minHeight: '100vh',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(4 / 8, 50 / 8, 40 / 8, 50 / 8),
          height: 'auto',
          overflow: 'initial',
          minHeight: 'auto',
          maxHeight: '86vh',
        },
      },
      productContentContainer: {
        width: '100%',
        padding: theme.spacing(0, 20 / 8),
        [theme.breakpoints.up('md')]: {
          overflow: 'visible',
          padding: 0,
          top: '0',
          margin: '0 auto',
          marginTop: 'initial',
        },
      },
      productContentWrapper: {
        [theme.breakpoints.up('md')]: {
          width: '100%',
        },
      },
    }),
);

import { Box, Button, Grid } from '@material-ui/core';
import { ClientService } from 'api/services/ClientService';

import { MapService } from 'api/services/MapService';
import { calculateDistance } from 'components/common/Dialog/components/LocationDialog/helpers';

import {
  AutocompleteField,
  CheckBoxWithLabelField,
  LocationAutocompleteField,
  MapField,
  TextField,
} from 'components/common/formsFields';
import BackIcon from 'components/common/icons/BackIcon';

import { showNotFoundLocation, TLocation } from 'components/common/LocationAutocomplete';
import { Field, Form, FormikProps, withFormik } from 'formik';

import { useCity, useMobile } from 'hooks';

import { omit } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TCityServer } from 'types';
import AddressTypeButton, { addressTypes, TAddressTypes } from 'ui/common/AddressTypeButton';
import CTAButton from 'ui/common/buttons/CTAButton';
import Icon from 'ui/common/icons/Icon';
import { IAutocompleteProps } from 'ui/common/inputs/Autocomplete';
import { ITextInput } from 'ui/common/inputs/TextInput';

import { ILatLng } from 'ui/common/Map';
import Typography from 'ui/common/Typography';

import { boolean, mixed, object, string } from 'yup';

import useStyles from './styles';
import { IClientForm, IClientFormValues, TClientFormSchema } from './types';

const ClientFormSchema: TClientFormSchema = () => {
  return object().shape({
    apartmentAndFloorIsRequired: boolean(),
    displayCity: boolean(),
    firstName: string().required('validation.required'),
    apartment: string().when('apartmentAndFloorIsRequired', {
      is: true,
      then: string().required('validation.required'),
    }),
    floor: string().when('apartmentAndFloorIsRequired', {
      is: true,
      then: string().required('validation.required'),
    }),
    lastName: string().required('validation.required'),
    address: mixed().when('displayCity', {
      is: false,
      then: mixed().required('validation.required'),
    }),
    city: mixed().when('displayCity', {
      is: true,
      then: mixed().required('validation.required'),
    }),
    coordinates: mixed().when('mapFieldIsRequired', {
      is: true,
      then: mixed().required('validation.required'),
    }),
    streetAndStreetNumber: mixed().when(['streetAndStreetNumberIsRequired', 'displayCity'], {
      is: true,
      then: mixed().required('validation.required'),
    }),
  });
};

const ClientForm: FC<IClientForm & FormikProps<IClientFormValues>> = ({
  isSubmitting,
  dirty,
  type = 'checkout_1',
  values,
  setFieldValue,
  setFieldTouched,
  isValid,
  displayReceivesCommercialMessagesCheckbox,
  defaultValues,
  handleSubmit,
}) => {
  const classes = useStyles({ type });

  const { isMobile } = useMobile();

  const { cities, militaryBases } = useCity();

  const { t } = useTranslation();

  const [addressStatus, setAddressStatus] = useState<string | null>(null);
  const [validateCityOnBlur, setValidateCityOnBlur] = useState<boolean>(false);
  const [showLocationAutocomplete, setShowLocationAutocomplete] = useState<boolean>(true);
  const [citiesDropDownOptions, setCitiesDropDownOptions] = useState<TCityServer[]>();

  const [showMap, setShowMap] = useState<boolean>(false);
  const [showMarkPositionOnTheMapButton, setShowMarkPositionOnTheMapButton] =
    useState<boolean>(false);
  const [mapPosition, setMapPosition] = useState<ILatLng | null>(null);
  const [mapError, setMapError] = useState<boolean>(false);
  const [mapErrorMessage, setMapErrorMessage] = useState<string>('');
  const [mapIsInvalid, setMapIsInvalid] = useState<boolean>(false);

  const zoom = 16;

  const clearErrorOnMap = useCallback(() => {
    setMapError(false);
    setMapErrorMessage('');
    setMapIsInvalid(false);
  }, []);

  const clearTheMap = useCallback(() => {
    setShowMap(false);
    setMapPosition(null);
    clearErrorOnMap();

    setFieldValue('mapFieldIsRequired', false);
    setFieldValue('coordinates', null);
    setTimeout(() => setFieldTouched('coordinates', false));
  }, [clearErrorOnMap, setFieldTouched, setFieldValue]);

  const clearAddressDetails = useCallback(() => {
    setFieldValue('address', null);
    setFieldValue('streetAndStreetNumber', null);
    setFieldValue('city', null);
  }, [setFieldValue]);

  const clearResidenceDetails = useCallback(() => {
    setFieldValue('apartment', '');
    setFieldTouched('apartment', false);

    setFieldValue('floor', '');
    setFieldTouched('floor', false);

    setFieldValue('entrance', '');
    setFieldTouched('entrance', false);

    setFieldValue('doorCode', '');
    setFieldTouched('doorCode', false);

    setFieldValue('addressNotes', '');
    setFieldTouched('addressNotes', false);
  }, [setFieldTouched, setFieldValue]);

  const switchToStreetStreetNumberAndCity = useCallback(() => {
    setFieldValue('addressType', 'apartmentBuilding');

    setFieldValue('apartmentAndFloorIsRequired', true);
    setFieldValue('displayCity', true);

    clearAddressDetails();
    clearResidenceDetails();

    setShowLocationAutocomplete(false);
    setValidateCityOnBlur(true);
  }, [clearAddressDetails, clearResidenceDetails, setFieldValue]);

  const backToTheLocationAutocomplete = useCallback(() => {
    setShowLocationAutocomplete(true);
    setAddressStatus(null);
    setValidateCityOnBlur(false);

    clearAddressDetails();
    clearResidenceDetails();

    setFieldValue('apartmentAndFloorIsRequired', false);
    setFieldValue('addressType', null);

    setFieldValue('displayCity', false);
    setShowMarkPositionOnTheMapButton(false);

    clearTheMap();
  }, [clearAddressDetails, clearResidenceDetails, clearTheMap, setFieldValue]);

  const onCityOrStreetAndStreetNumberChange = useCallback(() => {
    setShowMarkPositionOnTheMapButton(false);

    clearTheMap();

    if (values.addressType === 'militaryBase') {
      setShowMarkPositionOnTheMapButton(true);
      return;
    }

    if (values.city && values.streetAndStreetNumber) {
      const dataToPost = {
        description: values.streetAndStreetNumber as string,
        city: values.city,
      };

      ClientService.getAddressStatus(dataToPost).then((actionResult) => {
        setValidateCityOnBlur(false);

        if (
          actionResult.data.name === 'foundPartialMatch' ||
          actionResult.data.name === 'incorrectStreetNumber' ||
          actionResult.data.name === 'incorrectStreet'
        ) {
          setShowMarkPositionOnTheMapButton(true);
        }
      });
    }
  }, [clearTheMap, values.addressType, values.city, values.streetAndStreetNumber]);

  const handleCityChange = useCallback<
    Required<IAutocompleteProps<TCityServer, false>>['onChange']
  >(
    (_, value) => {
      setFieldValue('city', value);

      if (values.addressType === 'militaryBase') {
        setValidateCityOnBlur(false);
        setShowMarkPositionOnTheMapButton(true);
      }
    },
    [values.addressType, setFieldValue],
  );

  const handleStreetAndStreetNumberChange = useCallback<Required<ITextInput>['onChange']>(
    (e) => {
      setValidateCityOnBlur(true);
      setFieldValue('address', null); // TODO: Shay check scenario when we have one result in fetch
      setFieldValue('streetAndStreetNumber', e.target.value);
    },
    [setFieldValue],
  );

  const onAddressTypeChange = useCallback(
    (newAddressType: TAddressTypes['id']) => {
      setFieldValue('addressType', newAddressType);
      setShowMarkPositionOnTheMapButton(false);

      setFieldValue('streetAndStreetNumber', '');
      setFieldTouched('streetAndStreetNumber', false);

      clearResidenceDetails();

      setFieldTouched('city', false);
      setFieldValue('city', null);

      setFieldValue('apartmentAndFloorIsRequired', newAddressType === 'apartmentBuilding');
      setFieldValue('streetAndStreetNumberIsRequired', newAddressType !== 'militaryBase');

      clearTheMap();
    },
    [clearResidenceDetails, clearTheMap, setFieldTouched, setFieldValue],
  );

  const checkIfAddressIsValid = useCallback(
    (selectedLocation: TLocation) => {
      if (!selectedLocation) return;

      if (selectedLocation.placeId === 'mapLocation') {
        switchToStreetStreetNumberAndCity();
        return;
      }

      ClientService.getAddressStatus({
        description: `${selectedLocation.address}`,
        placeId: selectedLocation.placeId,
        cityAndCountry: selectedLocation.cityAndCountry,
      }).then((actionResult) => {
        setAddressStatus(actionResult.code);

        if (actionResult.code === 'cityNotFound') {
          switchToStreetStreetNumberAndCity();
        }
      });
    },
    [switchToStreetStreetNumberAndCity],
  );

  const onShowMap = useCallback(() => {
    if (!values.city?.location) return;
    setMapPosition(values.city?.location);

    setShowMarkPositionOnTheMapButton(false);
    setShowMap(true);

    setFieldValue('mapFieldIsRequired', true);
    setFieldValue('coordinates', values.city?.location);
  }, [setFieldValue, values.city?.location]);

  const onCloseMap = useCallback(() => {
    setShowMarkPositionOnTheMapButton(true);
    clearTheMap();
  }, [clearTheMap]);

  const displayErrorOnMap = useCallback((errorMessage) => {
    setMapError(true);
    setMapErrorMessage(errorMessage);
    setMapIsInvalid(true);
  }, []);

  const checkIfLocationOfPointerIsNotTooFar = useCallback(() => {
    if (values.coordinates && values.city?.location) {
      const distanceBetweenCityCenterAndPinnedLocation = calculateDistance(
        values.coordinates.lat,
        values.coordinates.lng,
        values.city.location.lat,
        values.city.location.lng,
      );

      if (distanceBetweenCityCenterAndPinnedLocation > 10) {
        displayErrorOnMap('map.pinIsFar');
        return;
      }

      clearErrorOnMap();
    }
  }, [clearErrorOnMap, displayErrorOnMap, values.city, values.coordinates]);

  const onFormSubmit = useCallback(() => {
    if (values.coordinates) {
      MapService.validateCoordinates(values.coordinates).then((actionResult) => {
        if (!actionResult.data) {
          displayErrorOnMap('map.pinIsInvalid');
          return;
        }
        handleSubmit();
      });
    } else {
      handleSubmit();
    }
  }, [displayErrorOnMap, handleSubmit, values.coordinates]);

  const renderFooter = useMemo(() => {
    switch (type) {
      case 'delivery_popup':
        return (
          <Box mt={30 / 8}>
            <CTAButton
              fullWidth
              onClick={onFormSubmit}
              disabled={!isValid || !dirty || isSubmitting || validateCityOnBlur || mapIsInvalid}
            >
              {'forms.checkout.submitButton'}
            </CTAButton>
          </Box>
        );
      case 'edit_details':
        return (
          <Box mt={isMobile ? 21 / 8 : 15 / 8} display="flex" justifyContent="flex-end">
            <Box flexGrow={1}>
              <CTAButton
                fullWidth
                disabled={!isValid || !dirty || isSubmitting || validateCityOnBlur || mapIsInvalid}
                onClick={onFormSubmit}
              >
                {'button.saveChanges'}
              </CTAButton>
            </Box>
          </Box>
        );
      case 'checkout_1':
      default:
        return (
          <Box mt={isMobile ? 30 / 8 : 5} textAlign="end">
            <CTAButton
              fullWidth={isMobile}
              onClick={onFormSubmit}
              disabled={!isValid || isSubmitting || validateCityOnBlur || mapIsInvalid}
              className={classes.submitButtonSection}
            >
              {'forms.checkout.submitButton'}
            </CTAButton>
          </Box>
        );
    }
  }, [
    type,
    isValid,
    dirty,
    isSubmitting,
    validateCityOnBlur,
    mapIsInvalid,
    isMobile,
    onFormSubmit,
    classes.submitButtonSection,
  ]);

  const cityFieldLabel = useMemo(() => {
    switch (values.addressType) {
      case 'privateHouse':
        return 'cityMoshavKibuts';
      case 'militaryBase':
        return 'nameOfBase';
      default:
        return 'city';
    }
  }, [values.addressType]);

  const addressNotDefined = useMemo(() => {
    let addressTypeButtonWidth = 'auto';

    if (type === 'checkout_1') {
      addressTypeButtonWidth = '160px';
    }
    if (type === 'delivery_popup') {
      addressTypeButtonWidth = '104px';
    }

    return (
      <>
        <Box position="relative" mt={isMobile ? 2 : 3} mb={isMobile ? 18 / 8 : 24 / 8}>
          {addressStatus === 'cityNotFound' && (
            <Typography className={classes.couldNotLocateAddress}>
              {'dialog.location.couldNotLocateAddress'}
            </Typography>
          )}
          <Box mb={10 / 8} display="flex" justifyContent="space-between">
            <Typography className={classes.fieldLabel} component="span">
              {'dialog.location.locationType'}
            </Typography>
            <Button className={classes.linkWrapper} onClick={backToTheLocationAutocomplete}>
              <BackIcon classes={{ root: classes.arrowRightIcon }} />
              <Typography className={classes.link} component="span">
                {'links.backToAddressSearch'}
              </Typography>
            </Button>
          </Box>
          <Box display="flex" justifyContent="space-between" mt={10 / 8}>
            {isMobile ? (
              <Box className={classes.sliderWrapper}>
                {addressTypes.map((location, index) => {
                  const isActive = location.id === values.addressType;

                  return (
                    <Box pl={index === 0 ? 14 / 8 : 0} pr={16 / 8} key={location.id}>
                      <AddressTypeButton
                        isActive={isActive}
                        location={location}
                        onClick={onAddressTypeChange}
                      />
                    </Box>
                  );
                })}
              </Box>
            ) : (
              addressTypes.map((location) => {
                const isActive = location.id === values.addressType;
                return (
                  <Box
                    key={location.id}
                    width={addressTypeButtonWidth}
                    height={type === 'checkout_1' ? '80px' : 'auto'}
                  >
                    <AddressTypeButton
                      isActive={isActive}
                      location={location}
                      onClick={onAddressTypeChange}
                    />
                  </Box>
                );
              })
            )}
          </Box>
        </Box>
        {values.addressType !== 'militaryBase' && (
          <Grid item xs={12} spacing={isMobile ? 2 : 3}>
            <Box mb={isMobile ? 16 / 8 : 24 / 8}>
              <Field
                name="streetAndStreetNumber"
                required
                component={TextField}
                label={
                  values.addressType === 'office'
                    ? 'streetAndNumberOfBuilding'
                    : 'streetAndStreetNumber'
                }
                inputProps={{
                  placeholder: t('input.streetAndStreetExample'),
                }}
                onChange={handleStreetAndStreetNumberChange}
                onBlur={onCityOrStreetAndStreetNumberChange}
              />
            </Box>
          </Grid>
        )}
        <Box mb={isMobile ? 2 : 3}>
          <Grid item xs={12}>
            <Field
              name="city"
              required
              options={citiesDropDownOptions}
              label={cityFieldLabel}
              inputProps={{
                placeholder: t('input.cityPlaceholder'),
              }}
              component={AutocompleteField}
              onOpen={clearTheMap}
              value={values.city}
              onChange={handleCityChange}
              onBlur={onCityOrStreetAndStreetNumberChange}
            />
          </Grid>
        </Box>
      </>
    );
  }, [
    values.addressType,
    addressStatus,
    backToTheLocationAutocomplete,
    citiesDropDownOptions,
    cityFieldLabel,
    classes.arrowRightIcon,
    classes.couldNotLocateAddress,
    classes.fieldLabel,
    classes.link,
    classes.linkWrapper,
    classes.sliderWrapper,
    clearTheMap,
    handleCityChange,
    handleStreetAndStreetNumberChange,
    isMobile,
    onAddressTypeChange,
    onCityOrStreetAndStreetNumberChange,
    t,
    type,
    values.city,
  ]);

  useEffect(() => {
    if (values.addressType === 'militaryBase') {
      setCitiesDropDownOptions(militaryBases);
      return;
    }
    setCitiesDropDownOptions(cities);
  }, [values.addressType, cities, militaryBases]);

  // initial render - set screen mode according to initial value of city field
  useEffect(() => {
    if (values.city) {
      setShowLocationAutocomplete(false);
      if (!values.addressType) {
        setFieldValue('addressType', 'apartmentBuilding');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initialAddress = defaultValues?.address;
    const initialAddressStatus = initialAddress?.addressStatus;

    if (initialAddressStatus) {
      setShowMarkPositionOnTheMapButton(false);

      if (
        initialAddressStatus.name === 'pinpointedByClient' &&
        defaultValues?.address?.coordinates
      ) {
        setShowMap(!!defaultValues.address.coordinates);
        setMapPosition(defaultValues.address.coordinates);
        return;
      }

      if (
        initialAddressStatus.name === 'foundPartialMatch' ||
        initialAddressStatus.name === 'incorrectStreetNumber' ||
        initialAddressStatus.name === 'incorrectStreet' ||
        initialAddress?.addressType?.name === 'militaryBase'
      ) {
        setShowMarkPositionOnTheMapButton(true);
      }
    }
  }, [defaultValues?.address]);

  return (
    <Form>
      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={6}>
          <Field name="firstName" required component={TextField} />
        </Grid>
        <Grid item xs={6}>
          <Field name="lastName" required component={TextField} />
        </Grid>
      </Grid>
      <Box mt={isMobile ? 1 : 12 / 8}>
        {showLocationAutocomplete && (
          <Grid container spacing={isMobile ? 2 : 3}>
            <Grid item xs={12}>
              <Field
                name="address"
                required
                inputProps={{
                  label: 'fullAddress',
                  placeholder: t('dialog.signUp.addressExample'),
                }}
                component={LocationAutocompleteField}
                extraOption={showNotFoundLocation}
                onChange={(selectedLocation: TLocation) => checkIfAddressIsValid(selectedLocation)}
              />
            </Grid>
          </Grid>
        )}
        <>{!showLocationAutocomplete && addressNotDefined}</>
        {(showLocationAutocomplete ||
          values.addressType === 'apartmentBuilding' ||
          values.addressType === 'office') && (
          <Box mt={isMobile ? 1 : 12 / 8}>
            <Grid item xs={12} spacing={isMobile ? 2 : 3}>
              <Grid container spacing={isMobile ? 2 : 3}>
                <Grid item xs={6} sm={values.addressType === 'office' ? 6 : 3}>
                  <Field
                    name="floor"
                    component={TextField}
                    required={values.addressType === 'apartmentBuilding'}
                  />
                </Grid>
                {(values.addressType === 'apartmentBuilding' || showLocationAutocomplete) && (
                  <Grid item xs={6} sm={3}>
                    <Field
                      name="apartment"
                      component={TextField}
                      required={values.addressType === 'apartmentBuilding'}
                    />
                  </Grid>
                )}
                <Grid item xs={6} sm={values.addressType === 'office' ? 6 : 3}>
                  <Field name="entrance" component={TextField} />
                </Grid>
                {(values.addressType === 'apartmentBuilding' || showLocationAutocomplete) && (
                  <Grid item xs={6} sm={3}>
                    <Field
                      name="doorCode"
                      component={TextField}
                      placeholder={t('input.optional')}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Box mt={isMobile ? 16 / 8 : 24 / 8}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              name="addressNotes"
              rows={2}
              multiline
              component={TextField}
              style={{
                height: '58px',
              }}
              inputProps={{
                label: 'addressNotes',
                placeholder: t('input.whatDeliveryPersonShouldKnow'),
              }}
            />
          </Grid>
        </Grid>
        {showMarkPositionOnTheMapButton && (
          <Button className={classes.markLocationOnTheMapButton} onClick={onShowMap}>
            <Icon name="icon-location-2" fontSize="small" color="primary" />
            <Typography variant="body1" className={classes.markLocationOnTheMap}>
              {'map.markLocationOnTheMap'}
            </Typography>
          </Button>
        )}
        {showMap && (
          <Box mt={isMobile ? 16 / 8 : 24 / 8}>
            <Grid item xs={12}>
              <Field
                name="coordinates"
                required
                component={MapField}
                initialPosition={mapPosition}
                isError={mapError}
                errorText={mapErrorMessage}
                customZoom={zoom}
                onCloseMap={onCloseMap}
                onDragEnd={checkIfLocationOfPointerIsNotTooFar}
                onZoomChanged={checkIfLocationOfPointerIsNotTooFar}
              />
            </Grid>
          </Box>
        )}
      </Box>
      {displayReceivesCommercialMessagesCheckbox && (
        <Box mt={isMobile ? 9 / 8 : 15 / 8}>
          <Field
            name="clientDetailsOnStoreLevel.receivesCommercialMessages"
            component={CheckBoxWithLabelField}
            type="checkbox"
            classes={{ label: classes.receivesMessagesLabel }}
            label={t('input.receivesCommercialMessages')}
          />
        </Box>
      )}
      {renderFooter}
    </Form>
  );
};

export default withFormik<IClientForm, IClientFormValues>({
  mapPropsToValues: ({ defaultValues }) => {
    return {
      apartmentAndFloorIsRequired: false,
      displayCity:
        defaultValues && !defaultValues?.address?.placeId && !!defaultValues?.address?.city,
      streetAndStreetNumberIsRequired: defaultValues?.address?.addressType?.name !== 'militaryBase',
      mapFieldIsRequired: false,
      firstName: (defaultValues && defaultValues.firstName) || '',
      lastName: (defaultValues && defaultValues.lastName) || '',
      address: defaultValues && defaultValues?.address?.placeId ? defaultValues.address : undefined,
      floor: (defaultValues && defaultValues.floor) || '',
      apartment: (defaultValues && defaultValues.apartment) || '',
      entrance: (defaultValues && defaultValues.entrance) || '',
      doorCode: (defaultValues && defaultValues.doorCode) || '',
      addressNotes: (defaultValues && defaultValues.addressNotes) || '',
      coordinates:
        (defaultValues &&
          defaultValues?.address?.addressStatus?.name === 'pinpointedByClient' &&
          defaultValues.address.coordinates) ||
        null,
      city:
        (defaultValues && !defaultValues?.address?.placeId && defaultValues?.address?.city) ||
        undefined,
      streetAndStreetNumber:
        (defaultValues && !defaultValues?.address?.placeId && defaultValues?.address?.address) ||
        '',
      clientDetailsOnStoreLevel:
        (defaultValues && defaultValues.clientDetailsOnStoreLevel) || undefined,
      addressType: defaultValues && defaultValues?.address?.addressType?.name,
    };
  },
  validationSchema: () => ClientFormSchema(),
  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus, resetForm }) => {
    // omit displayCity, that we use for address field validation
    const newValues: IClientFormValues = omit(values, ['displayCity']) as IClientFormValues;

    props.handleSubmit(newValues, { setSubmitting, setErrors, setStatus, resetForm });
  },
  enableReinitialize: true,
})(ClientForm);

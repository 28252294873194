/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from '@redux-saga/core/effects';

import { OrderService, TPrepareToPlaceOrder } from 'api';
import { TActionResult } from 'api/Api/BaseApi/types';
import { SubscriptionService } from 'api/services/SubscriptionService';

import {
  ICheckoutFlowRequest,
  IGetPromotionsSuccess,
  IHideDialog,
  IOrderTokenUpdate,
  IPrepareToPlaceOrderRequest,
  IPrepareToPlaceOrderSuccess,
  IPrepareToPlaceSubscriptionSuccess,
  IShowNotify,
  IStoreCouponUpdate,
  IUpdateOrderDetails,
  IUpdateOrderStoreCouponToken,
  notifyActions,
  orderActions,
  PREPARE_TO_PLACE_ORDER_REQUEST,
  PREPARE_TO_PLACE_ORDER_SUCCESS,
  TOrderDiscount,
} from 'store';
import { orderStoreCouponTokenUpdate } from 'store/modules/order/actions';

import { TOrderDetailsReducerState } from 'store/modules/orderDetails';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { storeCouponUpdate } from 'store/modules/orderDiscounts/actions';
import { getOrderDiscounts } from 'store/modules/orderDiscounts/selectors';
import { prepareToPlaceOrderSuccess } from 'store/modules/prepareToPlaceOrder/actions';

import { checkoutFlowRequestAction } from 'store/modules/shoppingFlow/actions';
import { getRedirectAfterHourSelection } from 'store/modules/shoppingFlow/selectors';
import { prepareToPlaceSubscriptionSuccess } from 'store/modules/subscriptions/actions';
import { TNameAndResolvedName, TPrepareToPlaceOrderServer } from 'types';
import { formatDeliveryDate, formatHourRange, formatSubscriptionDate } from 'utils';
import { dialogActions } from '../dialog';

function* prepareToPlaceOrderRequestSaga({
  payload,
}: IPrepareToPlaceOrderRequest): Generator<
  | TPrepareToPlaceOrder
  | SelectEffect
  | CallEffect
  | PutEffect<IHideDialog>
  | PutEffect<IPrepareToPlaceOrderSuccess>
  | PutEffect<IPrepareToPlaceSubscriptionSuccess>
  | PutEffect<IStoreCouponUpdate>
  | PutEffect<IUpdateOrderStoreCouponToken>
  | PutEffect<IUpdateOrderDetails>
  | PutEffect<ICheckoutFlowRequest>
  | PutEffect<IShowNotify>,
  void,
  any
> {
  const oldOrderDetails: TOrderDetailsReducerState = yield select(getOrderDetails);
  const oldOrderDiscounts: TOrderDiscount[] = yield select(getOrderDiscounts);

  const { body, selfPickupLocation, duration, frequency, uiHelpers } = payload;

  try {
    const orderMode = yield select(getOrderMode);
    let newOrderDetails: TOrderDetailsReducerState;
    switch (orderMode) {
      case 'addSubscription': {
        const { data } = yield call(SubscriptionService.prepareToPlaceSubscription, {
          ...body,
        });
        yield put(prepareToPlaceSubscriptionSuccess(data));
        newOrderDetails = {
          orderMode,
          orderTime: formatSubscriptionDate(
            frequency as TNameAndResolvedName,
            body.timeFrame.dayOfWeek,
            body.timeFrame.openHour,
            duration || 60,
          ),
          orderType: body.orderType,
          selfPickupLocation,
          serviceAreaId: body.storeServiceAreaId,
          frequency,
          preferredDay: body.timeFrame.dayOfWeek.id,
          preferredHour: body.timeFrame.openHour,
        };

        break;
      }

      case 'editSubscription': {
        newOrderDetails = {
          orderMode,
          orderTime: formatSubscriptionDate(
            frequency as TNameAndResolvedName,
            body.timeFrame.dayOfWeek,
            body.timeFrame.openHour,
            duration || 60,
          ),
          orderType: body.orderType,
          selfPickupLocation,
          serviceAreaId: body.storeServiceAreaId,
          frequency,
          preferredDay: body.timeFrame.dayOfWeek.id,
          preferredHour: body.timeFrame.openHour,
        };
        break;
      }

      default:
      case 'new': {
        const { data }: TActionResult<TPrepareToPlaceOrderServer> = yield call(
          OrderService.prepareToPlaceOrder,
          body,
        );
        yield put(prepareToPlaceOrderSuccess(data));

        newOrderDetails = {
          orderMode,
          orderTime: formatDeliveryDate(
            body.timeFrame.date,
            body.timeFrame.openHour,
            duration || 60,
          ),
          orderType: body.orderType,
          orderDate: body.timeFrame.date,
          orderHour: formatHourRange(body.timeFrame.openHour, duration || 60),
          preferredHour: body.timeFrame.openHour,
          preferredDay: body.timeFrame.dayOfWeek.id,
          selfPickupLocation,
        };
        break;
      }
    }

    yield put(updateOrderDetails(newOrderDetails));
    yield put(dialogActions.hideDialog());

    // TODO consolidate!
    yield put(storeCouponUpdate(undefined));
    yield put(orderStoreCouponTokenUpdate(undefined));

    const newOrderDiscounts: TOrderDiscount[] = yield select(getOrderDiscounts);

    // There is no need to check if orderType is changed.
    // It will change even without submitting preferredDay, because of the specific "intermediate" case in the project it will be null.
    if (oldOrderDetails.preferredDay !== newOrderDetails.preferredDay) {
      const irrelevantPromotions = oldOrderDiscounts.filter((oldPromotion) => {
        return !newOrderDiscounts.find(
          (newPromotion) => newPromotion.promotion === oldPromotion.promotion,
        );
      });

      if (irrelevantPromotions.length) {
        yield put(
          notifyActions.showNotification({
            message: 'discount.notValidInSelectedDay',
            autoHideDuration: 7000,
          }),
        );
      }
    }

    const redirectAfterHourSelection = yield select(getRedirectAfterHourSelection);
    if (redirectAfterHourSelection) {
      yield put(checkoutFlowRequestAction());
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  } finally {
    if (uiHelpers) {
      uiHelpers.setLoading(false);
    }
  }
}

function* prepareToPlaceOrderSuccessSaga({
  payload,
}: IPrepareToPlaceOrderSuccess): Generator<
  SelectEffect | PutEffect<IOrderTokenUpdate> | PutEffect<IGetPromotionsSuccess> | void,
  any
> {
  const prepareToPlaceOrder = payload;

  // update orderToken in order state
  yield put(orderActions.orderTokenUpdateAction(prepareToPlaceOrder.encryptedOrderToken));
}

function* rootPrepareToPlaceOrderSaga(): Generator {
  yield takeEvery(PREPARE_TO_PLACE_ORDER_REQUEST, prepareToPlaceOrderRequestSaga);
  yield takeEvery(PREPARE_TO_PLACE_ORDER_SUCCESS, prepareToPlaceOrderSuccessSaga);
}

export default rootPrepareToPlaceOrderSaga;

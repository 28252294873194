import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { IUserMenu, TStylesUserMenu } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesUserMenu,
    IUserMenu & {
      onClientBehalf: boolean;
      isMinimumProfileDataCompleted: boolean;
      clientCouponsOrderDiscountsLength: number;
    }
  > =>
    createStyles({
      root: {
        padding: '0px !important',
        boxShadow: 'none !important',
        minWidth: ({ isHideText }) => (isHideText ? 0 : theme.spacing(8)),
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },

        '& $userIcon': {
          fontSize: ({ size }) =>
            size === 'tablet'
              ? theme.spacing(3) // 24px
              : theme.spacing(4), // 32px
        },
        '& $arrowDownIcon': {
          fontSize: theme.spacing(1.375), // 11px
        },
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(6 / 8, 8 / 8),
        },
      },
      startIcon: {
        marginLeft: 0,
        margin: ({ size }) =>
          (size === 'mobile' && '0px !important') ||
          // eslint-disable-next-line no-nested-ternary
          (size === 'tablet'
            ? theme.direction === 'rtl'
              ? theme.spacing(0, 0, 0, 0.375)
              : theme.spacing(0, 0.375, 0, 0) // 0 0 0 3px
            : theme.direction === 'rtl'
            ? theme.spacing(0, 0, 0, 1)
            : theme.spacing(0, 1, 0, 0)), // 0 0 0 8px
      },
      buttonText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textTransform: 'initial',
        maxWidth: '115px',
        whiteSpace: 'nowrap',
      },
      endIcon: ({ isLoggedIn, onClientBehalf, isMinimumProfileDataCompleted }) => ({
        display: isLoggedIn && isMinimumProfileDataCompleted ? 'inherit' : 'none',
        marginRight: 0,
        marginLeft: theme.spacing(6 / 8),
        marginBottom: theme.spacing(onClientBehalf ? 1 / 8 : 0),
        alignSelf: onClientBehalf ? 'flex-end' : 'initial',
      }),
      // do not remove, need for styles, to overwrite styles (used in root)
      userIcon: {
        fontSize: ({ size }) =>
          size === 'tablet'
            ? theme.spacing(3) // 24px
            : theme.spacing(4), // 32px
      },
      userIconWrapper: {
        maxHeight: ({ size }) => (size === 'tablet' ? '28px' : '32px'),
      },
      userNotificationIcon: {
        fontSize: ({ size }) =>
          size === 'tablet'
            ? theme.spacing(3) // 24px
            : theme.spacing(4), // 32px
        position: 'absolute',
      },
      userNotificationDot: {
        fontSize: ({ size }) =>
          size === 'tablet'
            ? theme.spacing(3) // 24px
            : theme.spacing(4), // 32px
        color: theme.palette.primary.main,
        position: 'relative',
      },
      arrowDownIcon: {
        fontSize: '11px !important',
      },
      paper: {
        marginTop: theme.spacing(-0.5), // -4px
        boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: theme.spacing(0.75), // 6px
        border: `1px solid ${theme.palette.grey.A100}`,
        backgroundColor: theme.palette.grey[50],
      },
      menuList: {
        minWidth: '220px',
        padding: theme.spacing(2.5, 0), // 20px 0
      },
      menuItem: {
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(2), // 16px
        lineHeight: `${theme.spacing(2.5)}px`, // 20px
        padding: theme.spacing(1.25, 1.25, 1.25, 3.75), // '10px 10px 10px 30px'
        minWidth: theme.spacing(26.25), // 210px
        minHeight: 'auto',
        letterSpacing: -0.2,
      },
      selectedMenuItem: {
        fontWeight: 500,
        color: theme.palette.primary.main,

        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
      },
      menuIcon: {
        fontSize: theme.spacing(2.25), // 18px
        marginRight: theme.spacing(1.75), // 14px
      },
      buttonRipple: {
        backgroundColor: `${theme.palette.background.paper} !important`,
        opacity: '0.8 !important',
      },
      storeIcon: {
        color: theme.palette.primary.main,
        fontSize: '32px',
      },
      storeSideText: {
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'left',
        color: theme.palette.primary.main,
        lineHeight: 1.29,
      },
      welcomeStoreText: {
        fontSize: '14px',
        color: theme.palette.background.paper,
        textAlign: 'left',
        lineHeight: 1.29,
      },
      label: ({ onClientBehalf }) => ({
        display: 'flex',
        alignItems: onClientBehalf ? 'flex-start' : 'inherit',
        height: '32px',
      }),
      onClientBehalfWelcomeText: {
        width: '85px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      membershipPromotionsWrapper: {
        width: '260px',
        marginTop: '-20px',
        height: '70px',
        backgroundColor: theme.palette.primary.main,
        padding: '16px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        alignItems: 'center',
      },
      memberPromotionDialog: {
        padding: '0px !important',
      },
      diamondMembership: {
        color: theme.palette.secondary.main,
        fontSize: '34px',
      },
      clientCouponWrapper: ({ clientCouponsOrderDiscountsLength }) => ({
        margin: clientCouponsOrderDiscountsLength > 1 ? 0 : '0px 20px 12px',
      }),
    }),
);

import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesPickupForm } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesPickupForm> =>
    createStyles({
      submitButtonSection: {
        [theme.breakpoints.up('md')]: {
          width: '338px',
        },
      },
    }),
);

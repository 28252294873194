import { omit } from 'lodash';
import { TOrderReducer } from './types';

import {
  ORDER_TOKEN_UPDATE,
  ORDER_UPDATE,
  PLACE_ORDER_SUCCESS,
  SET_ORDER_ITEMS_SUCCESS,
  UPDATE_ECO_PACKAGING,
  UPDATE_ORDER_COMMENTS,
  UPDATE_ORDER_STORE_COUPON_TOKEN,
  UPDATE_SUBSTITUTE_ITEMS,
} from './constants';

import { initialOrderState } from './initialState';

const orderReducer: TOrderReducer = (state = initialOrderState, action) => {
  switch (action.type) {
    case ORDER_UPDATE:
      return {
        ...action.payload,
      };
    case UPDATE_ORDER_COMMENTS:
    case UPDATE_ECO_PACKAGING:
    case UPDATE_SUBSTITUTE_ITEMS:
    case SET_ORDER_ITEMS_SUCCESS:
    case ORDER_TOKEN_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ORDER_STORE_COUPON_TOKEN:
      return {
        ...omit(state, 'storeCouponToken'),
        ...action.payload,
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...initialOrderState,
      };

    default:
      return state;
  }
};

export { orderReducer };

const ORDER_UPDATE = 'order/ORDER_UPDATE';
const ORDER_TOKEN_UPDATE = 'order/ORDER_TOKEN_UPDATE';
const SET_ORDER_ITEM_REQUEST = 'order/SET_ORDER_ITEM_REQUEST';
const SET_ORDER_ITEMS_SUCCESS = 'order/SET_ORDER_ITEMS_SUCCESS';
const UPDATE_ORDER_ITEM_UNIT_REQUEST = 'order/UPDATE_ORDER_ITEM_UNIT_REQUEST';
const UPDATE_ORDER_COMMENTS = 'order/UPDATE_ORDER_COMMENTS';
const UPDATE_ECO_PACKAGING = 'order/UPDATE_ECO_PACKAGING';
const UPDATE_SUBSTITUTE_ITEMS = 'order/UPDATE_SUBSTITUTE_ITEMS';
const UPDATE_ORDER_STORE_COUPON_TOKEN = 'order/UPDATE_ORDER_STORE_COUPON_TOKEN';
const UPDATE_ORDER_ITEM_COMMENT_REQUEST = 'order/UPDATE_ORDER_ITEM_COMMENT_REQUEST';
const TOGGLE_PARTLY_REMOVE_PRODUCT_FROM_CART = 'order/PARTLY_REMOVE_PRODUCT_FROM_CART';
const UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST =
  'order/UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST';
const PLACE_ORDER_REQUEST = 'order/PLACE_ORDER_REQUEST';
const PLACE_ORDER_SUCCESS = 'order/PLACE_ORDER_SUCCESS';
const PRODUCT_CART_DELETED_SUCCESS = 'order/PRODUCT_CART_DELETED_SUCCESS';
const PRODUCT_CART_DELETED_REQUEST = 'order/PRODUCT_CART_DELETED_REQUEST';
const CLEANUP_HALF_DELETED_ITEMS_REQUEST = 'order/CLEANUP_HALF_DELETED_ITEMS_REQUEST';

const UPDATE_CLIENT_ACTIVITY_TIME = 'order/UPDATE_CLIENT_ACTIVITY_TIME';

export {
  ORDER_UPDATE,
  ORDER_TOKEN_UPDATE,
  SET_ORDER_ITEM_REQUEST,
  SET_ORDER_ITEMS_SUCCESS,
  UPDATE_ORDER_ITEM_UNIT_REQUEST,
  UPDATE_ORDER_COMMENTS,
  UPDATE_ORDER_STORE_COUPON_TOKEN,
  UPDATE_ORDER_ITEM_COMMENT_REQUEST,
  UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST,
  UPDATE_ECO_PACKAGING,
  UPDATE_SUBSTITUTE_ITEMS,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  TOGGLE_PARTLY_REMOVE_PRODUCT_FROM_CART,
  PRODUCT_CART_DELETED_SUCCESS,
  PRODUCT_CART_DELETED_REQUEST,
  CLEANUP_HALF_DELETED_ITEMS_REQUEST,
  UPDATE_CLIENT_ACTIVITY_TIME,
};

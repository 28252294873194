import { defaultPalette } from 'theme/constants';
import { IWebsiteSettings, TWebsiteReducerState } from './types';

export const defaultWebsiteSettings: IWebsiteSettings = {
  allowClosingDeliveringToAddressPopup: true,
  displayOpeningHours: true,
  displayDeliveringToAddressPopupOnload: true,
  displayMultiLanguage: false,
  displayAddress: true,
  courierTipEnabled: false,
  paymentToCourierAllowed: false,
  bitPaymentAllowed: false,
  enableSubscriptions: true,
  ecoPackaging: false,
  itemsSubstitution: false,
  pwaEnabled: false,
  displayableLanguages: undefined,
  defaultOrderType: 'delivery',
  onlySelfPickups: false,
  isSmallEC: false,
};

export const initialWebsiteState: TWebsiteReducerState = {
  websiteDetails: {
    store: {
      id: 0,
      name: '',
      businessNumber: '',
      businessName: '',
      businessFullAddressWithCity: '',
      worksWithStoreCoupons: false,
      performSellingUnitsEstimationLearning: false,
      addressCoordinates: '',
      hasOnlineBranches: false,
    },
    captchaType: { clientApiKey: '', name: '' },
    name: '',
    domain: '',
    jsonWebEncryption: '',
    logoPath: '',
    coverDesktopImagePath: '',
    coverMobileImagePath: '',
    genericProductsDefaultImagePath: '',
    mainCategoryMobileLogoPath: '',
    facebookLink: '',
    whatsappLink: '',
    instagramLink: '',
    youtubeLink: '',
    descriptionForCustomers: '',
    promotionalText: '',
    externalWebTrackingAccounts: [],
    updateDate: 0,
    lastTimeShowingWelcomeDialog: 0,
    showWelcomeDialog: true,
    theme: {
      palette: defaultPalette,
      images: {
        searchSuccess: '/assets/images/baseTheme/search-success.svg',
        searchFailure: '/assets/images/baseTheme/search-failed.svg',
        locationRangePopup: '/assets/images/baseTheme/search.svg',
        deliveryPopupHeader: '/assets/images/baseTheme/delivery-man-light-green.png',
        selfPickupPopupHeader: '/assets/images/baseTheme/pickup.png',
        warning: '/assets/images/baseTheme/warning.svg',
        footerTop: '/assets/images/baseTheme/nana.png',
        footerBottom: '/assets/images/baseTheme/red-orange.png',
        noOrders: '/assets/images/baseTheme/no-order.svg',
        noSubscriptions: '/assets/images/baseTheme/no-subscription-star.svg',
        noPaymentMethods: '/assets/images/baseTheme/credit-card-2.svg',
        orderCompleted: '/assets/images/baseTheme/order-completed.gif',
        subscriptionCompleted: '/assets/images/baseTheme/subscription-completed.gif',
        emptyCart: '/assets/images/baseTheme/empty-basket.svg',
        hasNewOrder: '/assets/images/baseTheme/has-new-order.svg',
        hasActiveSubscription: '/assets/images/baseTheme/has-active-subscription.svg',
        shareCart: '/assets/images/baseTheme/share-cart.svg',
        sms: '/assets/images/baseTheme/phone-sms.svg',
      },
      aboutPage: {
        images: {
          background: '/assets/images/baseTheme/image-below-delivery-areas.jpg',
          contactUsBackgroundDesktop: '/assets/images/baseTheme/contact-us-bg-desktop.jpg',
          contactUsBackgroundMobile: '/assets/images/baseTheme/contact-us-bg-mobile.jpg',
          deliveryBackground: '/assets/images/baseTheme/city-bg-vegetable.png',
          deliveryVan: '/assets/images/baseTheme/van-vegetable.png',
          subscriptionLeft: '/assets/images/baseTheme/banana.png',
          subscriptionRight: '/assets/images/baseTheme/pineapple.png',
        },
      },
      subscriptionPage: {
        images: {
          header: '/assets/images/baseTheme/bag-of-products.jpg',
          howItWorksBackground: '/assets/images/baseTheme/how-it-works-bg.jpg',
          basket: '/assets/images/baseTheme/basket.svg',
          calendar: '/assets/images/baseTheme/calendar.svg',
          payment: '/assets/images/baseTheme/payment.svg',
        },
      },
      customizedPage: {
        images: {
          textInsideImage: {
            background: '/assets/images/baseTheme/bg-customized-page.jpg',
          },
        },
      },
      homePage: {
        images: {
          categories: '/assets/images/baseTheme/categories.png',
          imageWithText: '/assets/images/baseTheme/imageWithText.png',
        },
      },
      notFoundPage: {
        images: {
          center: '/assets/images/baseTheme/404-kiwi.png',
        },
      },
    },
    bannersByCategory: {},
    contentPages: [],
    websiteSettings: defaultWebsiteSettings,
    favoriteIconPath: '',
  },
};

import React, { FC, useCallback } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import Repeat from 'components/common/Repeat';
import useStyles from './styles';
import { ISkeletonContentPages } from './types';

const SkeletonAccessibilityTerms: FC<ISkeletonContentPages> = () => {
  const classes = useStyles();

  const getRandomWidth = useCallback((min: number, max: number): string => {
    return `${Math.round(Math.random() * (max - min) + min)}%`;
  }, []);

  return (
    <Box className={classes.skeletonContentPageWrapper}>
      <Box className={classes.headersSkeletonWrapper}>
        <Skeleton className={classes.headersSkeleton} width={getRandomWidth(55, 65)} />
        <Skeleton className={classes.headersSkeleton} width={getRandomWidth(55, 65)} />
        <Skeleton className={classes.headersSkeleton} width={getRandomWidth(55, 65)} />
      </Box>
      <Box className={classes.textSkeletonWrapper}>
        <Repeat times={4}>
          <>
            <Skeleton className={classes.textSkeleton} width={getRandomWidth(80, 90)} />
            <Skeleton className={classes.textSkeleton} width={getRandomWidth(80, 90)} />
            <Skeleton className={classes.textSkeleton} width={getRandomWidth(80, 90)} />
            <Skeleton className={classes.textSkeleton} width={getRandomWidth(80, 90)} />
            <br />
          </>
        </Repeat>
      </Box>
    </Box>
  );
};

export default SkeletonAccessibilityTerms;

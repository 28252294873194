import { Box, Button, useTheme } from '@material-ui/core';

import ShareCartService from 'api/services/ShareCartService/api';
import classNames from 'classnames';

import {
  IOrderItemsToShare,
  TFormatDiscountMessage,
  THandleContinueToCheckout,
  THandleEmptyCart,
  TMembershipDescription,
  useDialog,
  useFreeProducts,
  useRenderPrice,
  useWebsiteDetails,
} from 'hooks';

import { useMobile } from 'hooks/useMobile';
import React, { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import {
  authSelectors,
  GENERIC_DIALOG,
  ITEMS_PROMOTION_DIALOG,
  LOGIN_DIALOG,
  notifyActions,
  orderActions,
  orderSelectors,
  prepareToPlaceOrderSelectors,
  SHARE_PRODUCT_CART_DIALOG,
  storeProductSelectors,
  TOrderDiscount,
  TOrderDiscountState,
} from 'store';
import { getLang } from 'store/modules/config/selectors';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import {
  getOrderDiscounts,
  getOrderMembershipBenefits,
} from 'store/modules/orderDiscounts/selectors';
import { promotionsData } from 'store/modules/promotions/selectors';
import {
  continueToCartRequest,
  continueToCheckoutRequest,
} from 'store/modules/shoppingFlow/actions';
import { TDiscountForEditOrder } from 'types';
import Icon from 'ui/common/icons/Icon';

import SaleHorizontalTag from 'ui/common/tags/SaleHorizontalTag';
import Tooltip from 'ui/common/Tooltip';
import Typography from 'ui/common/Typography';

import SaleHorizontalTagWithPrice from 'ui/mobile/tags/SaleHorizontalTagWithPrice';

import { getCartEstimation, shouldOrderHandlingFeeBeCharged } from 'utils/helpers/cartEstimation';
import useStyles from './styles';
import {
  THandleContinueToCart,
  TPromotionRenderItem,
  TRenderPromotions,
  TRenderSaleTag,
  TUseCart,
} from './types';

// TODO move the estimations to another hook (useCartEstimation)
const useCart: TUseCart = () => {
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const renderPrice = useRenderPrice();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const { showDialog, hideDialog } = useDialog();

  const { filterFreeProductsDiscounts } = useFreeProducts();

  const websiteDetails = useWebsiteDetails();

  const { clubMembershipProfile } = useSelector(promotionsData);
  const lang = useSelector(getLang);
  const { orderType } = useSelector(getOrderDetails);
  const order = useSelector(orderSelectors.orderData);
  const orderItems = useSelector(orderSelectors.orderItems);
  const { storeProductById } = useSelector(storeProductSelectors.storeProductsData);
  const orderDiscounts = useSelector(getOrderDiscounts);
  const prepareToPlaceOrder = useSelector(prepareToPlaceOrderSelectors.getPrepareToPlaceOrder);
  const orderDetails = useSelector(getOrderDetails);
  const orderMembershipBenefits = useSelector(getOrderMembershipBenefits);

  const classes = useStyles({ lang });

  const [membershipTooltipId, setMembershipTooltipId] = useState<number | null>(null);

  const freeProductsOrderDiscounts = useMemo(() => {
    return filterFreeProductsDiscounts(orderDiscounts);
  }, [orderDiscounts, filterFreeProductsDiscounts]);

  const renderSaleTag = useMemo<TRenderSaleTag>(
    () =>
      (
        price,
        text,
        maxLabelWidth = 620,
        secondaryText,
        backgroundColor = theme.palette.secondary.main,
        iconName = 'icon-discount',
        iconColor = 'primary',
      ) => {
        return (
          <SaleHorizontalTag
            size="large"
            backgroundColor={backgroundColor}
            iconName={iconName}
            iconColor={iconColor}
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                color="primary.light"
                className={classes.promotionTextWrapper}
                maxWidth={maxLabelWidth}
              >
                {text && <Box className={classes.promotionPrimaryText}>{text}</Box>}
                {secondaryText && (
                  <Box className={classes.promotionSecondaryText}>{secondaryText}</Box>
                )}
              </Box>
              <Box className={classes.promotionPriceWrapper}>
                <Typography color="light" fontWeight="medium" fontSize={13} variant="body2">
                  {typeof price === 'string' ? price : renderPrice(price)}
                </Typography>
              </Box>
            </Box>
          </SaleHorizontalTag>
        );
      },
    [
      classes.promotionPriceWrapper,
      classes.promotionPrimaryText,
      classes.promotionSecondaryText,
      classes.promotionTextWrapper,
      renderPrice,
      theme.palette.secondary.main,
    ],
  );

  // this function is getting orderDiscount and returns a formatted string based on discount type,
  // it is used to render the discount message for client coupon notice AND the discount ribbon's primary text (bold)
  // e.g: formatDiscountMessage(orderDiscount) --> '' || t('orderType') + 'discountValue' || t('orderType)
  const formatDiscountMessage = useCallback<TFormatDiscountMessage>(
    (orderDiscount) => {
      // set default values for discount ribbon bold text or client coupon notice message
      let discountValue = Math.abs(
        (orderDiscount as TOrderDiscount).totalPrice ||
          (orderDiscount as TDiscountForEditOrder).estimatedValue,
      );
      let alternativeResolvedName = orderDiscount.discountType.resolvedName;
      let renderDiscountValue = true;

      // switch to determine message text and
      switch (orderDiscount.discountType.name) {
        case 'generalDiscount':
          discountValue = Number(orderDiscount.valueFormula);

          if (orderDiscount.creditedAtCheckout) {
            alternativeResolvedName = t('discount.payment');
            break;
          }

          alternativeResolvedName = t(`discount.${orderDiscount.discountType.name}`);
          break;
        case 'itemRefund':
          discountValue = Number(orderDiscount.valueFormula);
          alternativeResolvedName = t(`discount.${orderDiscount.discountType.name}`);
          break;
        case 'serviceAreaUsageFeesDiscount':
          alternativeResolvedName = t(`discount.${orderType}UsageFees`);
          discountValue = Number(orderDiscount.valueFormula);
          break;
        case 'itemForFree':
          alternativeResolvedName = t(`discount.${orderDiscount.discountType.name}`);
          renderDiscountValue = false;
          break;
        case 'itemsPromotionDiscount':
          alternativeResolvedName = '';
          renderDiscountValue = false;
          break;
        default:
          break;
      }

      // handle discount types that should render primary (bold) text but no discount value
      if (!renderDiscountValue) {
        return alternativeResolvedName;
      }

      // if discount was placed in '%', use the intended value and not the real calculated value
      if (orderDiscount.valueFormulaType.sign === '%') {
        const value = orderDiscount.valueFormula.replace('%', '');
        return `${alternativeResolvedName} ${
          parseFloat(value) + orderDiscount.valueFormulaType.sign
        }`;
      }

      return `${alternativeResolvedName} 
      ${renderPrice(discountValue, undefined, Number.isInteger(discountValue) ? 0 : 2)}`;
    },
    [orderType, renderPrice, t],
  );

  const openItemsPromotionDialog = useCallback(
    (selectedPromotion) => {
      showDialog({
        dialogType: ITEMS_PROMOTION_DIALOG,
        contentProps: {
          promotion: selectedPromotion,
        },
      });
    },
    [showDialog],
  );

  const nonWeighablePromotionRibbonTemplate = useCallback(
    (promotion, remainQuantity) => {
      return (
        <Box display="flex" alignItems="center" justifyContent="space-between" position="relative">
          <Box display="flex" alignItems="center">
            {isMobile && (
              <Icon name="icon-discount" color="primary" classes={{ root: classes.icon }} />
            )}
            <Box>
              {remainQuantity && (
                <Typography
                  color="secondary"
                  className={classNames(classes.addMoreItemsTitle, 'font-family-rubik')}
                >
                  {t(
                    `storeProductCard.addMoreItem${remainQuantity === 1 ? 'Single' : 'Multiple'}`,
                    {
                      numberOfItems: remainQuantity,
                    },
                  )}
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Button
              style={{ padding: '0px', minWidth: 'auto' }}
              onClick={() => openItemsPromotionDialog(promotion)}
            >
              <Typography
                color="primary"
                className={classNames(classes.addMoreItemsTitle, 'font-family-rubik')}
              >
                {'storeProductCard.toPromotion'}
              </Typography>
              <Icon
                name={theme.direction === 'rtl' ? 'icon-arrow-left' : 'icon-arrow-right'}
                color="primary"
                classes={{ root: classes.toPromotionLabel }}
              />
            </Button>
          </Box>
        </Box>
      );
    },
    [
      classes.addMoreItemsTitle,
      classes.icon,
      classes.toPromotionLabel,
      isMobile,
      openItemsPromotionDialog,
      t,
      theme.direction,
    ],
  );

  const nonWeighablePromotionRibbon = useCallback(
    (index) => {
      const pendingNonWeighableOrderPromotions = orderDiscounts.filter(
        (discount) =>
          discount.promotionType?.name === 'forNonWeighableAboveQuantity' &&
          discount.state === TOrderDiscountState.Pending &&
          discount.accumulatedProducts[discount.accumulatedProducts.length - 1].cartIndex === index,
      );

      if (!pendingNonWeighableOrderPromotions.length) {
        return null;
      }

      return pendingNonWeighableOrderPromotions.map((promotion) => {
        const remainQuantity =
          (promotion.parameters.requiredQuantity || 1) - promotion.accumulatedQuantity;

        return (
          <Box className={classes.promotionWrapper} key={index}>
            {isMobile ? (
              <SaleHorizontalTagWithPrice backgroundColor={theme.palette.grey[200]}>
                {nonWeighablePromotionRibbonTemplate(promotion, remainQuantity)}
              </SaleHorizontalTagWithPrice>
            ) : (
              <SaleHorizontalTag
                size="large"
                backgroundColor={theme.palette.grey[200]}
                iconColor="secondary"
              >
                {nonWeighablePromotionRibbonTemplate(promotion, remainQuantity)}
              </SaleHorizontalTag>
            )}
          </Box>
        );
      });
    },
    [
      classes.promotionWrapper,
      isMobile,
      nonWeighablePromotionRibbonTemplate,
      orderDiscounts,
      theme.palette.grey,
    ],
  );

  const membershipDescription: TMembershipDescription = useCallback(
    (description, benefitId, title = 'membership.customerClub') => {
      return (
        <Box
          id="membershipDescription"
          display="flex"
          alignItems="center"
          style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          <Typography
            color="textSecondary"
            fontSize={13}
            className={classes.secondaryTypography}
            style={{ overflow: 'visible' }}
            fontWeight="medium"
          >
            {title}
          </Typography>
          <Typography
            color="textSecondary"
            fontSize={13}
            className={classes.secondaryTypography}
            isTranslate={false}
          >
            &nbsp;
            {description}
          </Typography>
          <Tooltip
            placement="bottom"
            title={t('membership.calculatedByExternalCompany') as string}
            initialClasses={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            open={membershipTooltipId === benefitId}
          >
            <Box
              ml={6 / 8}
              display="flex"
              onClick={() =>
                setMembershipTooltipId(membershipTooltipId === benefitId ? null : benefitId)
              }
              onMouseOver={() =>
                !isMobile &&
                setMembershipTooltipId(membershipTooltipId === benefitId ? null : benefitId)
              }
              onMouseLeave={() => !isMobile && setMembershipTooltipId(null)}
            >
              <Icon
                name="icon-question-1"
                color="action"
                classes={{ root: classes.questionIcon }}
              />
            </Box>
          </Tooltip>
        </Box>
      );
    },
    [
      classes.questionIcon,
      classes.secondaryTypography,
      classes.tooltip,
      classes.tooltipArrow,
      isMobile,
      membershipTooltipId,
      t,
    ],
  );

  const membershipBenefitTag = useCallback(
    (description, benefitId) => {
      return (
        <Box className={classes.promotionWrapper}>
          {isMobile ? (
            <SaleHorizontalTagWithPrice backgroundColor={theme.palette.primary.main}>
              <Box justifyContent="space-between" alignItems="center" position="relative">
                <Box className={classes.tagWrapper}>
                  <Icon name="icon-diamond" color="action" classes={{ root: classes.icon }} />
                  {membershipDescription(description, benefitId)}
                </Box>
              </Box>
            </SaleHorizontalTagWithPrice>
          ) : (
            renderSaleTag(
              '',
              '',
              'auto',
              membershipDescription(description, benefitId),
              theme.palette.primary.main,
              'icon-diamond',
              'action',
            )
          )}
        </Box>
      );
    },
    [
      classes.icon,
      classes.promotionWrapper,
      classes.tagWrapper,
      isMobile,
      membershipDescription,
      renderSaleTag,
      theme.palette.primary.main,
    ],
  );

  const renderMembershipBenefits = useCallback(() => {
    if (!clubMembershipProfile.length) return;

    const template = [];
    const requestedMembershipPromotionsArray = orderMembershipBenefits?.requestedBenefits
      ? Object.values(orderMembershipBenefits?.requestedBenefits)
      : [];

    if (requestedMembershipPromotionsArray.length) {
      template.push(
        requestedMembershipPromotionsArray.map((membershipPromotion) =>
          membershipBenefitTag(membershipPromotion.title, membershipPromotion.id),
        ),
      );
    }

    if (orderMembershipBenefits?.budgetToUse) {
      template.push(
        membershipBenefitTag(
          t('membership.usedAmountOfPoints', { points: orderMembershipBenefits?.budgetToUse }),
          -1,
        ),
      );
    }

    return template.map((templateElement) => templateElement);
  }, [clubMembershipProfile.length, membershipBenefitTag, orderMembershipBenefits, t]);

  // TODO remove type TPromotionRenderItem
  const renderPromotions = useCallback<TRenderPromotions>(
    (endOfCart, index, maxPromotionLabelWidth = 620) => {
      const promotionsArr: TPromotionRenderItem[] = [];
      // filter out free items discounts - rendered via renderFreeProducts function
      const excludedDiscountTypes = ['itemForFree'];

      orderDiscounts
        .filter((orderDiscount) => {
          if (excludedDiscountTypes.includes(orderDiscount.discountType.name)) {
            return false;
          }

          if (!endOfCart) {
            return (
              orderDiscount.discountType.name === 'itemsPromotionDiscount' &&
              orderDiscount.cartIndex === index &&
              orderDiscount.totalPrice
            );
          }

          return orderDiscount.discountType.name !== 'itemsPromotionDiscount';
        })
        .forEach((orderDiscount, id) => {
          const {
            externalNotes,
            totalPrice,
            discountType,
            discountSourceType,
            creditedAtCheckout,
          } = orderDiscount;

          const isClientCoupon = discountSourceType.name === 'clientCoupon';
          const text = formatDiscountMessage(orderDiscount);
          let newMaxLabelWidth = maxPromotionLabelWidth;
          let price = renderPrice(totalPrice);

          if (discountType.name === 'serviceAreaUsageFeesDiscount' || creditedAtCheckout) {
            price = '';
            // increase label width if usage fee discount is rendered only on top cart
            if (maxPromotionLabelWidth <= 200) {
              newMaxLabelWidth = 250;
            }
          }

          promotionsArr.push({
            isClientCoupon,
            id,
            price,
            text,
            secondaryText: externalNotes,
            maxLabelWidth: newMaxLabelWidth,
            creditedAtCheckout,
          });
        });

      if (promotionsArr.length === 0) {
        return null;
      }

      const sortedPromotionsArray = promotionsArr.sort((a, b) => {
        // Client coupons always first because we do concat on promotionsCalculator.ts
        // we want that client coupons without price will be after client coupons with price
        // and only after other promotions

        if (a.price !== '' && b.price === '') {
          return -1;
        }

        if (a.price === '' && b.price !== '') {
          return 1;
        }

        return 0;
      });

      return (
        <>
          {sortedPromotionsArray.map(
            (
              { price, text, maxLabelWidth, isClientCoupon = false, secondaryText = '' },
              promotionIndex,
            ) => {
              let tagMaxWidth = '100%';

              if (price && isMobile) {
                tagMaxWidth = lang === 'he' ? '84%' : '80%';
              }

              return (
                <Box key={promotionIndex} className={classes.promotionWrapper}>
                  {isMobile ? (
                    <SaleHorizontalTagWithPrice
                      backgroundColor={
                        isClientCoupon ? theme.palette.text.secondary : theme.palette.secondary.main
                      }
                    >
                      <Box
                        justifyContent="space-between"
                        display="flex"
                        alignItems="center"
                        position="relative"
                      >
                        <Box className={classes.tagWrapper} maxWidth={tagMaxWidth}>
                          <Icon
                            name={isClientCoupon ? 'icon-refund-2' : 'icon-discount'}
                            color="primary"
                            classes={{ root: classes.icon }}
                          />
                          <Typography
                            color="light"
                            fontSize={13}
                            className={classes.secondaryTypography}
                            fontWeight="medium"
                          >
                            {text && <Box display="inline-block">{text}</Box>}
                            {secondaryText && (
                              <Box
                                display="inline"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                fontWeight={400}
                              >
                                &nbsp;{secondaryText}
                              </Box>
                            )}
                          </Typography>
                        </Box>
                        {price && (
                          <Box alignItems="center">
                            <Typography
                              color="light"
                              isTranslate={false}
                              fontSize={13}
                              fontWeight="bold"
                              variant="body2"
                              className={classes.priceTypography}
                            >
                              {price}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </SaleHorizontalTagWithPrice>
                  ) : (
                    renderSaleTag(
                      price,
                      text,
                      maxLabelWidth,
                      secondaryText,
                      isClientCoupon ? theme.palette.text.secondary : theme.palette.secondary.main,
                      isClientCoupon ? 'icon-refund-2' : 'icon-discount',
                    )
                  )}
                </Box>
              );
            },
          )}
        </>
      );
    },
    [
      orderDiscounts,
      formatDiscountMessage,
      renderPrice,
      isMobile,
      classes.promotionWrapper,
      classes.tagWrapper,
      classes.icon,
      classes.secondaryTypography,
      classes.priceTypography,
      theme.palette.text.secondary,
      theme.palette.secondary.main,
      renderSaleTag,
      lang,
    ],
  );

  const isEmpty = useMemo<boolean>(() => {
    return !orderItems.length;
  }, [orderItems]);

  const uniqueItemCount = useMemo<number>(() => {
    return orderItems.filter((item) => !item.isRemoved).length;
  }, [orderItems]);

  const areAllItemsRemoved = useMemo(() => {
    return (
      orderItems.filter((item) => item.isRemoved).length === orderItems.length &&
      orderItems.length > 0
    );
  }, [orderItems]);

  const itemsEstimation = useMemo<number>(() => {
    const relevantOrderDiscountsForCartEstimation = orderDiscounts.filter(
      (discount) =>
        discount.discountType.name !== 'serviceAreaUsageFeesDiscount' &&
        !discount.creditedAtCheckout,
    );

    return getCartEstimation(order, relevantOrderDiscountsForCartEstimation, 0);
  }, [order, orderDiscounts]);

  const itemsEstimationForCheckout = useMemo<number>(() => {
    const relevantOrderDiscountsForCartEstimation = orderDiscounts.filter(
      (discount) =>
        discount.discountSourceType.name !== 'storeCoupon' &&
        !discount.creditedAtCheckout &&
        discount.discountType.name !== 'serviceAreaUsageFeesDiscount',
    );

    return getCartEstimation(order, relevantOrderDiscountsForCartEstimation, 0);
  }, [order, orderDiscounts]);

  const cartEstimation = useMemo<number>(() => {
    return getCartEstimation(
      order,
      orderDiscounts,
      prepareToPlaceOrder.serviceAreaUsageFees,
      orderDetails.courierTip,
      shouldOrderHandlingFeeBeCharged(
        itemsEstimation,
        prepareToPlaceOrder.orderMinTotalValue,
        prepareToPlaceOrder.orderHandlingFeeThreshold,
      )
        ? prepareToPlaceOrder.orderHandlingFee
        : 0,
    );
  }, [
    itemsEstimation,
    order,
    orderDetails.courierTip,
    orderDiscounts,
    prepareToPlaceOrder.orderHandlingFee,
    prepareToPlaceOrder.orderHandlingFeeThreshold,
    prepareToPlaceOrder.orderMinTotalValue,
    prepareToPlaceOrder.serviceAreaUsageFees,
  ]);

  const handleEmptyCart = useCallback<THandleEmptyCart>(() => {
    if (areAllItemsRemoved) {
      dispatch(orderActions.productCartDeletedRequestAction());
      return;
    }

    showDialog({
      dialogType: GENERIC_DIALOG,
      contentProps: {
        title: 'dialog.emptyCart.title',
        body: 'dialog.emptyCart.body',
        buttons: [
          {
            text: 'dialog.emptyCart.yesButton',
            variant: 'contained',
            onClick: () => {
              dispatch(orderActions.productCartDeletedRequestAction());
              hideDialog();
            },
          },
          {
            text: 'dialog.emptyCart.noButton',
            variant: 'outlined',
            onClick: () => {
              hideDialog();
            },
          },
        ],
      },
    });
  }, [areAllItemsRemoved, dispatch, hideDialog, showDialog]);

  // TODO think about limitedByAge products out of the catalog (for example edit order/subscription)
  const limitedByAgeProducts = useMemo<string[]>(() => {
    const limitedProducts: string[] = [];
    orderItems.forEach((item) => {
      if (
        storeProductById[item.storeProduct.id] &&
        storeProductById[item.storeProduct.id].product.limitedByAge
      ) {
        limitedProducts.push(storeProductById[item.storeProduct.id].fullName);
      }
    });

    return limitedProducts;
  }, [orderItems, storeProductById]);

  const handleContinueToCart = useCallback<THandleContinueToCart>(() => {
    dispatch(continueToCartRequest());
  }, [dispatch]);

  const handleContinueToCheckout = useCallback<THandleContinueToCheckout>(() => {
    dispatch(continueToCheckoutRequest());
  }, [dispatch]);

  const onShareCart = useCallback(() => {
    if (!isLoggedIn) {
      showDialog({
        dialogType: LOGIN_DIALOG,
      });

      return;
    }

    const contentItems: IOrderItemsToShare[] = orderItems.map((item) => {
      const newItem: IOrderItemsToShare = {
        ...item,
        storeProduct: { id: item.storeProduct.id },
        selectedBagItems: JSON.stringify(item.selectedBagItems) || undefined,
      };

      delete newItem.sourceEvent;

      return newItem;
    });

    ShareCartService.shareCart({
      contentItems,
    }).then((actionResult) => {
      if (actionResult.data) {
        const linkToShare = `https://${websiteDetails.domain}?share_cart=${actionResult.data}`;

        const mobileDetailsToShare = {
          text: `${t('topCard.checkSharedCard')} ${linkToShare}`,
        };

        if (isMobile) {
          // TODO needs to be tested on mobile on local doesn't work
          if (navigator.share && navigator.canShare(mobileDetailsToShare)) {
            navigator.share(mobileDetailsToShare);
          } else {
            navigator.clipboard.writeText(linkToShare);

            dispatch(
              notifyActions.showNotification({
                message: 'topCard.deviceNotSupportShare',
                autoHideDuration: 7000,
              }),
            );
          }
          return;
        }
        showDialog({
          dialogType: SHARE_PRODUCT_CART_DIALOG,
          contentProps: {
            linkToShare,
          },
        });
      }
    });
  }, [dispatch, isLoggedIn, isMobile, orderItems, showDialog, t, websiteDetails.domain]);

  return {
    uniqueItemCount,
    itemsEstimation,
    itemsEstimationForCheckout,
    cartEstimation,
    isEmpty,
    areAllItemsRemoved,
    limitedByAgeProducts,
    handleContinueToCart,
    handleContinueToCheckout,
    handleEmptyCart,
    renderPromotions,
    formatDiscountMessage,
    renderMembershipBenefits,
    freeProductsOrderDiscounts,
    nonWeighablePromotionRibbon,
    membershipDescription,
    onShareCart,
  };
};

export default useCart;

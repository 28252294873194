import { TAddressTypes } from './types';

export const addressTypes: TAddressTypes[] = [
  {
    label: 'dialog.location.residential',
    id: 'apartmentBuilding',
    icon: 'icon-building',
  },
  {
    label: 'dialog.location.private',
    id: 'privateHouse',
    icon: 'icon-private-house',
  },
  {
    label: 'dialog.location.office',
    id: 'office',
    icon: 'icon-office',
  },
  {
    label: 'dialog.location.militaryBase',
    id: 'militaryBase',
    icon: 'icon-military-base',
  },
];

/* eslint-disable @typescript-eslint/no-explicit-any */

import { TPaymentMethodForPlaceOrder } from 'components/common/forms';
import { pick } from 'lodash';
import loadJS from 'utils/helpers/dynamicJS/dynamicJS';

declare const window: any;

export default function perform3dSecure(
  cartEstimation: number,
  paymentMethod: TPaymentMethodForPlaceOrder,
  initializationData: T3DSecurePlaceOrderFailureData,
): Promise<T3DSecureAuthenticateProcessedResult> {
  return new Promise<T3DSecureAuthenticateProcessedResult>((resolve, reject) => {
    loadJS(process.env.NEXT_PUBLIC_3D_SECURE_SAFECHARGE_URL as string)
      .then(() => loadJS(process.env.NEXT_PUBLIC_3D_SECURE_SDK_URL as string))
      .then(
        () => {
          if (!window.Cg3ds) {
            reject('3D Secure API Not loaded!');
          }
          const cg3ds: Tcg3ds = new window.Cg3ds({
            ...pick(initializationData, ['sessionToken', 'merchantId', 'merchantSiteId']),
            env: process.env.NEXT_PUBLIC_3D_SECURE_ENV,
          });

          let cardDetailsPayload: Omit<TAuthenticateParams, 'currency' | 'amount'>;

          if (paymentMethod.newPaymentMethod) {
            cardDetailsPayload = {
              paymentOption: {
                card: {
                  cardNumber: paymentMethod.newPaymentMethod.creditCardNumber,
                  expirationMonth: paymentMethod.newPaymentMethod.expirationMonth,
                  expirationYear: paymentMethod.newPaymentMethod.expirationYear,
                  CVV: paymentMethod.newPaymentMethod.cvv,
                  cardHolderName: paymentMethod.newPaymentMethod.cardHolderName,
                },
              },
            };
          } else {
            cardDetailsPayload = {
              cardId: initializationData.cardToken,
              paymentOption: {
                userPaymentOptionId: initializationData.card3DSToken,
              },
            };
          }

          cg3ds.authenticate(
            {
              currency: 'ils',
              amount: cartEstimation.toFixed(2),
              ...cardDetailsPayload,
            },
            (res) =>
              resolve({
                ...res,
                result: res.result || res.status,
                errorDescription: res.errorDescription || res.reason,
              }),
          );
        },
        () => {
          reject('Error loading 3D Secure API');
        },
      );
  });
}

export type T3DSecureInitializationData = {
  sessionToken: string;
  merchantId: string;
  merchantSiteId: string;
};

export type T3DSecurePlaceOrderFailureData = T3DSecureInitializationData & {
  cardToken: string;
  card3DSToken: string;
  terminalId: string;
};

export type T3DSecureAuthenticateRawResult = {
  errCode: number;
  errorDescription: string;
  cavv: string;
  eci: number;
  xid: string;
  cg3dsVersion: number;
  cg3dsLiability: string;
  result: 'APPROVED' | 'DECLINED' | 'ERROR';
  status: 'APPROVED' | 'DECLINED' | 'ERROR';
  reason: string;
  userPaymentOptionId: string;
};

export type T3DSecureAuthenticateProcessedResult = {
  errCode: number;
  errorDescription: string;
  cavv: string;
  eci: number;
  xid: string;
  cg3dsVersion: number;
  cg3dsLiability: string;
  result: 'APPROVED' | 'DECLINED' | 'ERROR';
  userPaymentOptionId: string;
};

export type Tcg3ds = {
  authenticate: (
    authenticateParams: TAuthenticateParams,
    callback: (result: T3DSecureAuthenticateRawResult) => void,
  ) => void;
};

type TAuthenticateParams = {
  currency: string;
  amount: string;
  cardId?: string;
  paymentOption: {
    userPaymentOptionId?: string;
    card?: {
      cardNumber: string;
      expirationMonth: string;
      expirationYear: string;
      CVV: string;
      cardHolderName: string;
    };
  };
};

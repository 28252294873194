import { HIDE_DIALOG, SHOW_DIALOG } from './constants';

import { THideDialogAction, TShowDialogAction } from './types';

const showDialog: TShowDialogAction = (params) => ({
  type: SHOW_DIALOG,
  payload: params,
});

const hideDialog: THideDialogAction = () => ({
  type: HIDE_DIALOG,
});

export { showDialog, hideDialog };

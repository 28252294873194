import React, { FC } from 'react';

import { Box } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { IProductQualityTag } from './types';

const ProductQualityTag: FC<IProductQualityTag> = ({ label, height = '20px' }) => {
  const classes = useStyles({ height });

  return (
    <Box className={classes.tagWrapper}>
      <Typography className={classes.tagLabel} isTranslate={false}>
        {label}
      </Typography>
    </Box>
  );
};

export default ProductQualityTag;

import {
  DEVICE_ID_GENERATE,
  DEVICE_ID_UPDATE,
  JWE_TOKEN_UPDATE,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  ON_CLIENT_BEHALF_UPDATE,
  SESSION_ONLY_JWE_TOKEN_UPDATE,
} from './constants';

import {
  TDeviceIdGenerateAction,
  TDeviceIdUpdateAction,
  TJweTokenUpdateAction,
  TLogOutRequestAction,
  TLogOutSuccessAction,
  TOnClientBehalfUpdateAction,
  TSessionOnlyJweTokenUpdateAction,
} from './types';

const jweTokenUpdateAction: TJweTokenUpdateAction = (newJweToken) => ({
  type: JWE_TOKEN_UPDATE,
  payload: { jweToken: newJweToken },
});

const sessionOnlyJweTokenUpdateAction: TSessionOnlyJweTokenUpdateAction = (newJweToken) => ({
  type: SESSION_ONLY_JWE_TOKEN_UPDATE,
  payload: { sessionOnlyJweToken: newJweToken },
});

const deviceIdGenerateAction: TDeviceIdGenerateAction = () => ({
  type: DEVICE_ID_GENERATE,
});

const deviceIdUpdateAction: TDeviceIdUpdateAction = (newDeviceId) => ({
  type: DEVICE_ID_UPDATE,
  payload: {
    deviceId: newDeviceId,
  },
});

const logOutRequest: TLogOutRequestAction = () => ({
  type: LOG_OUT_REQUEST,
});

const logOutSuccess: TLogOutSuccessAction = () => ({
  type: LOG_OUT_SUCCESS,
});

const onClientBehalfUpdateAction: TOnClientBehalfUpdateAction = (newOnClientBehalf) => ({
  type: ON_CLIENT_BEHALF_UPDATE,
  payload: {
    onClientBehalf: newOnClientBehalf,
  },
});

export {
  jweTokenUpdateAction,
  sessionOnlyJweTokenUpdateAction,
  deviceIdGenerateAction,
  deviceIdUpdateAction,
  logOutRequest,
  logOutSuccess,
  onClientBehalfUpdateAction,
};

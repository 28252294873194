import ErrorContent from 'components/common/contents/ErrorContent';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';

interface IProps {
  children?: ReactNode;
}

interface IState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  public getRenderedError(): string {
    return `${this.state.error?.message}\n ${this.state.error?.stack}\n Component stack: ${this.state.errorInfo?.componentStack}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(error: Error): IState {
    return { hasError: true, error };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can use your own error logging service here

    this.setState((prevState) => ({
      ...prevState,
      error,
      errorInfo,
    }));

    Sentry.captureException(error);
  }

  public render(): ReactNode {
    // Check if the error is thrown
    if (this.state.hasError && this.state.error) {
      // You can render any custom fallback UI
      return <ErrorContent errorDescription={this.getRenderedError()} />;
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import Repeat from 'components/common/Repeat';
import useStyles from './styles';
import { ISkeletonDeliveryOrSelfPickupAvailableHours } from './types';

const SkeletonDeliveryOrSelfPickupAvailableHours: FC<
  ISkeletonDeliveryOrSelfPickupAvailableHours
> = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.desktopWrapper}>
        <Repeat times={3}>
          <Skeleton className={classes.skeletonLoader} />
        </Repeat>
      </Box>

      <Box className={classes.mobileWrapper}>
        <Repeat times={2}>
          <Skeleton className={classes.skeletonLoader} />
        </Repeat>
      </Box>
    </>
  );
};

export default SkeletonDeliveryOrSelfPickupAvailableHours;

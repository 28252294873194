import React, { FC } from 'react';
import { IBackIcon } from 'components/common/icons/BackIcon/types';
import Icon from 'ui/common/icons/Icon';
import { useSelector } from 'react-redux';
import { getDirection } from 'store/modules/config/selectors';

const BackIcon: FC<IBackIcon> = (props) => {
  const dir = useSelector(getDirection);
  return <Icon name={dir === 'rtl' ? 'icon-arrow-right' : 'icon-arrow-left'} {...props} />;
};

export default BackIcon;

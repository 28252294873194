import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { ISaleHorizontalTag, TStylesSaleHorizontalTag } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesSaleHorizontalTag, ISaleHorizontalTag & { smallTriangleStyles: boolean }> =>
    createStyles({
      chipRoot: {
        backgroundColor: ({ backgroundColor }) => backgroundColor || theme.palette.secondary.main,
        minWidth: ({ size }) =>
          size === 'large'
            ? theme.spacing(6.25) //
            : theme.spacing(6), // 48px
        height: ({ size }) =>
          size === 'large'
            ? theme.spacing(4) // 32px
            : theme.spacing(20 / 8), // 18px
        width: ({ size }) => (size === 'large' ? 'calc(100% - 12px)' : 'auto'),
        justifyContent: ({ size }) => (size === 'large' ? 'initial' : 'flex-start'),
        padding: ({ size }) => (size === 'large' ? theme.spacing(6 / 8) : 0),
        borderRadius: theme.spacing(0),
        // move tag outside the block
        marginLeft: theme.spacing(-0.625),
        textAlign: 'center',
        position: 'relative',
      },
      // reset default MUI-label styles
      label: ({ size }) => ({
        paddingRight: theme.spacing(size === 'large' ? 0 : 4 / 8),
        paddingLeft: theme.spacing(1), // 8px
        fontSize: theme.spacing(1.25),
        color: theme.palette.background.paper,
        width: '100%',
      }),
      icon: ({ size }) => ({
        objectFit: 'contain',
        fontSize:
          size === 'large'
            ? theme.spacing(2.5) // 20px
            : theme.spacing(1.25), // 10px
        margin:
          size === 'large'
            ? theme.spacing(0, 0.5, 0, 0) // 4px
            : theme.spacing(0, 0.375, 0, 0), // 3px
      }),
      leftTopTriangle: ({ size, backgroundColor, smallTriangleStyles }) => ({
        position: 'absolute',
        width: theme.spacing(0),
        height: theme.spacing(0),
        borderTop:
          size === 'large'
            ? `25px solid ${backgroundColor || theme.palette.secondary.main}`
            : `12px solid ${backgroundColor || theme.palette.secondary.main}`,
        borderRight: smallTriangleStyles ? '6px solid transparent' : '8px solid transparent',
        top: theme.spacing(0),
        right: smallTriangleStyles ? '-5px' : '-7px',
        borderTopRightRadius: theme.spacing(0.25), // 2px
      }),
      leftBottomTriangle: ({ size, backgroundColor, smallTriangleStyles }) => ({
        position: 'absolute',
        width: theme.spacing(0),
        height: theme.spacing(0),
        borderBottom:
          size === 'large'
            ? `25px solid ${backgroundColor || theme.palette.secondary.main}`
            : `12px solid ${backgroundColor || theme.palette.secondary.main}`,
        borderRight: smallTriangleStyles ? '6px solid transparent' : '8px solid transparent',
        bottom: theme.spacing(0),
        right: smallTriangleStyles ? '-5px' : '-7px',
        borderBottomRightRadius: theme.spacing(0.25), // 2px
      }),
      rightBottomTriangle: {
        position: 'absolute',
        width: theme.spacing(0),
        height: theme.spacing(0),
        borderTop: '5px solid rgba(0, 0, 0, 0.85)',
        borderLeft: '5px solid transparent',
        bottom: theme.spacing(-0.625), // -5px
        left: theme.spacing(0),
      },
    }),
);

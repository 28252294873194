import ProductRecommendationService from 'api/services/ProductRecommendationService';
import { useDialog } from 'hooks/useDialog';

import { useMultiLanguage } from 'hooks/useMultiLanguage';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';

import { find, snakeCase } from 'lodash';
import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  IStoreProductCartData,
  IStoreProductDisplay,
  orderActions,
  orderSelectors,
  PRODUCT_DETAILS_DIALOG,
  promotionsSelectors,
  storeProductSelectors,
} from 'store';

import { getItemTotalPrice } from 'utils/helpers/cartEstimation';
import {
  TFormatBagExtraDetails,
  TGetCartItem,
  TGetDisplayableStoreProduct,
  TGetOrderItemToDisplay,
  TGetPricingSellingUnitName,
  TGetProductDetails,
  TGetProductOrBagDetails,
  TGetSimilarProductsIds,
  THandleAddStoreProduct,
  THandleChooseUnit,
  THandleDeleteStoreProduct,
  THandleProductCommentChanged,
  THandleProductDetailsPopup,
  THandleProductSelectedBagItemsChanged,
  THandleStoreChangeProductQuantity,
  TPopulateActiveSellingUnit,
  TProductAdditionSource,
  TShouldDisplaySupplier,
  TUseProduct,
} from './types';

const useProduct: TUseProduct = () => {
  const dispatch = useDispatch();

  const { showDialog } = useDialog();

  const { renderLanguageField } = useMultiLanguage();

  const websiteDetails = useWebsiteDetails();

  const { storeProductById, outOfStockStoreProductById } = useSelector(
    storeProductSelectors.storeProductsData,
  );
  const { promotionsById } = useSelector(promotionsSelectors.promotionsData);
  const orderItems = useSelector(orderSelectors.orderItems);

  const handleChooseUnit = useCallback<THandleChooseUnit>(
    (storeProductId, sellingUnitId) => {
      dispatch(orderActions.updateOrderItemUnitRequestAction(storeProductId, sellingUnitId));
    },
    [dispatch],
  );

  const handleAddStoreProduct = useCallback<THandleAddStoreProduct>(
    (storeProductId, sourceEvent = 'unknown' as TProductAdditionSource) => {
      const storeProduct =
        storeProductById[storeProductId] || outOfStockStoreProductById[storeProductId];
      dispatch(orderActions.setOrderItemRequestAction(storeProduct, 'add', sourceEvent));
    },
    [dispatch, outOfStockStoreProductById, storeProductById],
  );

  const handleChangeStoreProductQuantity = useCallback<THandleStoreChangeProductQuantity>(
    (storeProductId, action) => {
      const storeProduct =
        storeProductById[storeProductId] || outOfStockStoreProductById[storeProductId];
      dispatch(orderActions.setOrderItemRequestAction(storeProduct, action));
    },
    [dispatch, outOfStockStoreProductById, storeProductById],
  );

  const handleDeleteStoreProduct = useCallback<THandleDeleteStoreProduct>(
    (storeProductId) => {
      const storeProduct =
        storeProductById[storeProductId] || outOfStockStoreProductById[storeProductId];

      dispatch(orderActions.setOrderItemRequestAction(storeProduct, 'delete'));
    },
    [dispatch, outOfStockStoreProductById, storeProductById],
  );

  const handleProductCommentChanged = useCallback<THandleProductCommentChanged>(
    (productId, productComment) => {
      dispatch(orderActions.updateOrderItemCommentRequestAction(productId, productComment));
    },
    [dispatch],
  );

  const handleProductSelectedBagItemsChanged = useCallback<THandleProductSelectedBagItemsChanged>(
    (productId, selectedBagItems, sourceEvent = 'unknown' as TProductAdditionSource) => {
      dispatch(
        orderActions.updateOrderItemSelectedBagItemsRequestAction(
          productId,
          selectedBagItems,
          sourceEvent,
        ),
      );
    },
    [dispatch],
  );

  const populateActiveSellingUnit = useMemo<TPopulateActiveSellingUnit>(
    () =>
      (
        activeSellingUnitIdBasedOnCartItem,
        activeSellingUnitIdBasedOnCatalog,
        productSellingUnits,
      ) => {
        if (activeSellingUnitIdBasedOnCartItem) {
          return (
            find(productSellingUnits, ['id', activeSellingUnitIdBasedOnCartItem]) ||
            productSellingUnits[0]
          );
        }

        return (
          find(productSellingUnits, ['id', activeSellingUnitIdBasedOnCatalog]) ||
          productSellingUnits[0]
        );
      },
    [],
  );

  const getPricingSellingUnit = useMemo<TGetPricingSellingUnitName>(
    () => (soldByWeight, primaryQuantityUnit, productSellingUnits) => {
      return soldByWeight
        ? renderLanguageField(primaryQuantityUnit.multiLang, 'name')
        : renderLanguageField(productSellingUnits[0].sellingUnit.multiLang, 'name');
    },
    [renderLanguageField],
  );

  const getCartOrderItem = useMemo<TGetCartItem>(
    () => (storeProductId) => {
      const existingCartItem = find(orderItems, ['storeProduct.id', storeProductId]);
      if (existingCartItem) {
        return existingCartItem;
      }
    },
    [orderItems],
  );

  const getProductDetails = useCallback<TGetProductDetails>(
    (storeProduct) => {
      const cartOrderItem = getCartOrderItem(storeProduct.id);
      const promotion = storeProduct.promotion ? promotionsById[storeProduct.promotion] : undefined;
      const activeSellingUnit = populateActiveSellingUnit(
        cartOrderItem?.requestedSellingUnit.id,
        storeProduct.defaultSelectedSellingUnit.id,
        storeProduct.productSellingUnits,
      );
      const pricingSellingUnitName = getPricingSellingUnit(
        storeProduct.soldByWeight,
        storeProduct.product.primaryQuantityUnit,
        storeProduct.productSellingUnits,
      );

      const displayableStoreProduct: IStoreProductDisplay = {
        ...storeProduct,
        displayName:
          storeProduct.secondaryName ||
          renderLanguageField(storeProduct.product?.multiLang, 'name'),
        promotion,
        pricingSellingUnitName,
        imageUrl: storeProduct.imageUrl
          ? `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${storeProduct.imageUrl}`
          : websiteDetails.genericProductsDefaultImagePath,
        additionalImages: storeProduct.additionalImages || [],
        commentType: storeProduct.commentType?.comments.length
          ? storeProduct.commentType
          : undefined,
        activeSellingUnit,
      };

      if (cartOrderItem) {
        const cartData: IStoreProductCartData = {
          orderItem: cartOrderItem,
          totalPrice: getItemTotalPrice(cartOrderItem),
        };

        if (storeProduct.promoted) {
          cartData.originalTotalPrice = getItemTotalPrice(
            cartOrderItem,
            storeProduct.originalPrice,
          );
        }

        if (cartOrderItem.productComment) {
          cartData.productComment = storeProduct.commentType?.comments.find(
            (comment) => comment.name === cartOrderItem.productComment,
          );
        }

        displayableStoreProduct.cartData = cartData;
      }

      return displayableStoreProduct;
    },
    [
      getCartOrderItem,
      getPricingSellingUnit,
      populateActiveSellingUnit,
      promotionsById,
      renderLanguageField,
      websiteDetails.genericProductsDefaultImagePath,
    ],
  );

  const getDisplayableStoreProduct = useCallback<TGetDisplayableStoreProduct>(
    (storeProductId, includeOutOfStockProducts = false) => {
      let storeProduct = storeProductById[storeProductId];

      if (!storeProduct && includeOutOfStockProducts) {
        storeProduct = outOfStockStoreProductById[storeProductId];
      }

      if (!storeProduct) {
        return null;
      }
      return getProductDetails(storeProduct);
    },
    [storeProductById, getProductDetails, outOfStockStoreProductById],
  );

  const getOrderItemToDisplay = useCallback<TGetOrderItemToDisplay>(
    (storeProduct) => {
      // promotionById array not exist yet, will be fetch and updated together with full catalog
      return getProductDetails(storeProduct);
    },
    [getProductDetails],
  );

  const getSimilarProductsIds = useCallback<TGetSimilarProductsIds>(
    (storeProductObfuscatedId, setLoading) => {
      return ProductRecommendationService.getSimilarProducts(
        storeProductObfuscatedId,
        setLoading,
      ).then((actionResult) => {
        return actionResult.data.split(',').map((productObfuscatedId) => +productObfuscatedId);
      });
    },
    [],
  );

  const handleProductDetailsPopup = useCallback<THandleProductDetailsPopup>(
    (storeProductId) => {
      const storeProduct = getDisplayableStoreProduct(storeProductId);

      if (!storeProduct) {
        return;
      }

      if (window.history.state && window.history.state.productDetailsPopup) {
        window.history.replaceState(
          { productDetailsPopup: true },
          storeProduct.displayName,
          `/product-details/${storeProduct.id}/${snakeCase(storeProduct.fullName)}`,
        );
      } else {
        window.history.pushState(
          { productDetailsPopup: true },
          storeProduct.displayName,
          `/product-details/${storeProduct.id}/${snakeCase(storeProduct.fullName)}`,
        );
      }

      showDialog({
        dialogType: PRODUCT_DETAILS_DIALOG,
        contentProps: {
          storeProductId,
        },
        dialogProps: {
          onClose: () => window.history.back(),
        },
      });
    },
    [getDisplayableStoreProduct, showDialog],
  );

  const formatBagExtraDetails = useCallback<TFormatBagExtraDetails>(
    (product, asHTML, differValues) => {
      const {
        product: { name },
        id,
      } = product;

      // top level id indicates that product is a storeProduct
      if (id && name) {
        const lastCommaIndex: number = name.lastIndexOf(',');
        if (lastCommaIndex !== -1) {
          const productName = name.slice(0, lastCommaIndex);
          const productQuality = name.slice(lastCommaIndex + 1).trim();

          if (productName && productQuality) {
            if (asHTML) {
              return [
                `${productName} <span class="productQualityInBag">(${productQuality})</span>`,
              ];
            }
            if (differValues) {
              return [productName, productQuality];
            }
            return [`${productName} (${productQuality})`];
          }
        }
      }

      return [name];
    },
    [],
  );

  const getProductOrBagDetails = useCallback<TGetProductOrBagDetails>(
    (storeProductId, asHTML) => {
      const storeProduct = getDisplayableStoreProduct(storeProductId);

      if (!storeProduct) {
        return null;
      }

      if (storeProduct.bagOfProducts && storeProduct.bagOfProductsJson) {
        let { selectedItems } = storeProduct.bagOfProductsJson;

        if (storeProduct.cartData && storeProduct.cartData.orderItem.selectedBagItems) {
          selectedItems = storeProduct.cartData.orderItem.selectedBagItems;
        }

        return selectedItems
          .map((product) => formatBagExtraDetails(product, asHTML))
          .flat()
          .join(', ');
      }
      return storeProduct.productExtraDetails;
    },
    [formatBagExtraDetails, getDisplayableStoreProduct],
  );

  const shouldDisplaySupplier = useCallback<TShouldDisplaySupplier>((storeProduct) => {
    return !!(
      storeProduct.supplierDisplayedToClients &&
      storeProduct.supplier &&
      storeProduct.supplier.name
    );
  }, []);

  return {
    getProductOrBagDetails,
    handleProductDetailsPopup,
    getDisplayableStoreProduct,
    getSimilarProductsIds,
    handleChooseUnit,
    handleAddStoreProduct,
    handleChangeStoreProductQuantity,
    handleDeleteStoreProduct,
    handleProductCommentChanged,
    handleProductSelectedBagItemsChanged,
    formatBagExtraDetails,
    getOrderItemToDisplay,
    shouldDisplaySupplier,
  };
};

export default useProduct;

import { useCallback } from 'react';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useTranslation } from 'react-i18next';

import { TContentPage, TUseContentPages } from 'hooks/useContentPages/types';

const useContentPages: TUseContentPages = () => {
  const websiteDetails = useWebsiteDetails();
  const { t } = useTranslation();

  const createContentPagesList = useCallback(
    (isAddSubscriptionPage = false, isAddCustomizedPage = false) => {
      const aboutPage: TContentPage[] = [];
      const customizedPage: TContentPage[] = [];
      websiteDetails.contentPages.forEach((contentPage) => {
        if (contentPage.type === 'about') {
          aboutPage.push(contentPage);
          aboutPage.push(
            {
              id: `${contentPage.nonObfuscatedId}#delivery-areas`,
              nonObfuscatedId: `${contentPage.nonObfuscatedId}#delivery-areas`,
              type: 'about',
              name: t('generalPage.deliveryAreas'),
            },
            {
              id: `${contentPage.nonObfuscatedId}#contact`,
              nonObfuscatedId: `${contentPage.nonObfuscatedId}#contact`,
              type: 'about',
              name: t('generalPage.contact'),
            },
          );
          if (isAddSubscriptionPage && websiteDetails.websiteSettings.enableSubscriptions) {
            aboutPage.push({
              id: '',
              nonObfuscatedId: `${contentPage.nonObfuscatedId}#delivery-areas`,
              type: 'what-is-subscription',
              name: t('generalPage.subscribe'),
            });
          }
          return;
        }

        if (contentPage.type === 'customized') {
          if (isAddCustomizedPage) {
            customizedPage.push(contentPage);
          }
        }
      });

      return aboutPage.concat(customizedPage);
    },
    [websiteDetails.contentPages, websiteDetails.websiteSettings.enableSubscriptions, t],
  );

  return { createContentPagesList };
};

export default useContentPages;

import { useCategoryMenu } from 'hooks/useCategoryMenu';
import { TOnLoadBackgroundImage, TUseMainLayout } from 'hooks/useMainLayout/types';
import { useMobile } from 'hooks/useMobile';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { categorySelectors } from 'store';

const useMainLayout: TUseMainLayout = () => {
  const { categoryById } = useSelector(categorySelectors.categoriesData);

  const { activeMenuItem } = useCategoryMenu();

  const websiteDetails = useWebsiteDetails();
  const { isMobile } = useMobile();

  const getBackgroundImage = useCallback(
    (showCategoryImage) => {
      if (isMobile) {
        if (
          showCategoryImage &&
          activeMenuItem &&
          categoryById[activeMenuItem.id] &&
          categoryById[activeMenuItem.id].mobileMediaFilePath
        ) {
          return `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${
            categoryById[activeMenuItem.id].mobileMediaFilePath
          }`;
        }
        return websiteDetails.coverMobileImagePath;
      }

      if (
        showCategoryImage &&
        activeMenuItem &&
        categoryById[activeMenuItem.id] &&
        categoryById[activeMenuItem.id].mediaFilePath
      ) {
        return `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${
          categoryById[activeMenuItem.id].mediaFilePath
        }`;
      }
      return websiteDetails.coverDesktopImagePath;
    },
    [
      activeMenuItem,
      categoryById,
      isMobile,
      websiteDetails.coverDesktopImagePath,
      websiteDetails.coverMobileImagePath,
    ],
  );

  const onLoadBackgroundImage: TOnLoadBackgroundImage = useCallback(
    (callback, showCategoryImage) => {
      if (getBackgroundImage(showCategoryImage)) {
        const bgElement = document.querySelector('#bg-img') as HTMLElement;
        let preloaderImg: HTMLImageElement | null = document.createElement('img');
        preloaderImg.src = getBackgroundImage(showCategoryImage);
        if (bgElement) {
          preloaderImg.addEventListener('load', () => {
            bgElement.style.backgroundImage = `url(${getBackgroundImage(showCategoryImage)})`;
            preloaderImg = null;
            callback();
          });
          preloaderImg.addEventListener('error', () => {
            bgElement.style.backgroundImage = '';
            preloaderImg = null;
            callback();
          });
        }
      }
    },
    [getBackgroundImage],
  );

  const onMainHeaderObserve = useCallback(() => {
    const mainHeader = document.getElementById('mainHeader');

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        const stickyHeader = document.getElementById('sticky-header');

        if (!stickyHeader) return;

        if (!entry.isIntersecting) {
          stickyHeader.style.zIndex = '7';
          stickyHeader.style.transition = 'opacity 600ms ease-out';
          stickyHeader.style.willChange = 'opacity';
          stickyHeader.style.opacity = '1';
        } else {
          stickyHeader.style.zIndex = '';
          stickyHeader.style.opacity = '0';
        }
      },
      { threshold: 1 },
    );
    if (mainHeader) {
      observer.observe(mainHeader);
    }
  }, []);

  return {
    onLoadBackgroundImage,
    getBackgroundImage,
    onMainHeaderObserve,
  };
};

export default useMainLayout;

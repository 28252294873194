import { Box, IconButton, useTheme } from '@material-ui/core';
import { IHomePageSlider } from 'components/common/HomePageContent/components/desktop/HomePageSlider/types';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import Icon from 'ui/common/icons/Icon';
import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';
import useStyles from 'components/common/HomePageContent/components/desktop/HomePageSlider/styles';

const HomePageSlider: FC<IHomePageSlider> = ({
  slides,
  linkLabel,
  linkHref,
  title,
  amountOfSlidesToShow,
  amountOfSlidesPerGroup,
  spaceBetweenSlides,
}) => {
  const swiper = useRef<SwiperClass | null>(null);
  const theme = useTheme();

  const classes = useStyles();
  const { direction } = useTheme();

  const showPrev = useCallback(() => {
    swiper.current?.slidePrev();
  }, []);

  const showNext = useCallback(() => {
    swiper.current?.slideNext();
  }, []);

  const slidesToDisplay = useMemo(() => {
    let slidesArray = slides;

    if (slides.length >= amountOfSlidesToShow && amountOfSlidesToShow * 2 > slides.length) {
      slidesArray = [...slidesArray, ...slidesArray];
    }
    // we duplicate array because of how the loop mode works (it will rearrange slides), total number of slides must be >= slidesPerView * 2

    return slidesArray;
  }, [amountOfSlidesToShow, slides]);

  return (
    <>
      <Box display="flex" mb={24 / 8} justifyContent="space-between" alignItems="center">
        <Typography className={classes.title} variant="body1" isTranslate={false}>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box>
            {linkHref && linkLabel && (
              <Link href={linkHref} targetBlank>
                <Typography className={classes.linkLabel} isTranslate={false}>
                  {linkLabel}
                </Typography>
              </Link>
            )}
          </Box>
          {slides.length > amountOfSlidesToShow && (
            <Box display="flex" justifyContent="flex-end">
              <IconButton classes={{ root: classes.navigationButton }} onClick={showPrev}>
                <Icon
                  color="primary"
                  name={direction === 'rtl' ? 'icon-arrow-right' : 'icon-arrow-left'}
                  classes={{ root: classes.navigationIcon }}
                />
              </IconButton>
              <IconButton classes={{ root: classes.navigationButton }} onClick={showNext}>
                <Icon
                  color="primary"
                  name={direction === 'rtl' ? 'icon-arrow-left' : 'icon-arrow-right'}
                  classes={{ root: classes.navigationIcon }}
                />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Swiper
        modules={[EffectFade, Pagination, Autoplay]}
        onInit={(element: SwiperClass) => {
          swiper.current = element;
        }}
        loop
        speed={800}
        slidesPerGroup={amountOfSlidesPerGroup || amountOfSlidesToShow}
        slidesPerView={amountOfSlidesToShow}
        spaceBetween={spaceBetweenSlides}
        className={classes.swiper}
        dir={theme.direction}
        key={theme.direction}
      >
        {slidesToDisplay.map((slide, index) => {
          return (
            <SwiperSlide dir={theme.direction} key={index}>
              {slide}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default HomePageSlider;

const IS_SHOW_LOCATION_UPDATE = 'shoppingFlow/IS_SHOW_LOCATION_UPDATE';
const IS_INTRODUCTORY_POPUPS_COMPLETED = 'shoppingFlow/IS_INTRODUCTORY_POPUPS_COMPLETED';
const REDIRECT_AFTER_HOURS_SELECTION_UPDATE = 'shoppingFlow/REDIRECT_AFTER_HOURS_SELECTION_UPDATE';
const CONTINUE_TO_CART_REQUEST = 'shoppingFlow/CONTINUE_TO_CART_REQUEST';
const CONTINUE_TO_CHECKOUT_REQUEST = 'shoppingFlow/CONTINUE_TO_CHECKOUT_REQUEST';
const CHECKOUT_FLOW_REQUEST = 'shoppingFlow/CHECKOUT_FLOW_REQUEST';
const UPDATE_KNOWN_ADDRESS_AND_SUB_CATALOG = 'shoppingFlow/UPDATE_KNOWN_ADDRESS_AND_SUB_CATALOG';

export {
  IS_SHOW_LOCATION_UPDATE,
  REDIRECT_AFTER_HOURS_SELECTION_UPDATE,
  CONTINUE_TO_CART_REQUEST,
  CONTINUE_TO_CHECKOUT_REQUEST,
  CHECKOUT_FLOW_REQUEST,
  IS_INTRODUCTORY_POPUPS_COMPLETED,
  UPDATE_KNOWN_ADDRESS_AND_SUB_CATALOG,
};

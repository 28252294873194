import React, { FC, useCallback } from 'react';

import { useHelperFieldText } from 'hooks';

import LocationAutocomplete, { TLocationOnChange } from 'components/common/LocationAutocomplete';

import { TLocationAutocompleteField } from './types';

const LocationAutocompleteField: FC<TLocationAutocompleteField> = ({
  field: { onBlur, value, name },
  form: { touched, errors, setFieldValue },
  inputProps,
  onChange,
  ...rest
}) => {
  const handleChange = useCallback<TLocationOnChange>(
    (newValue) => {
      setFieldValue(name, newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [setFieldValue, name, onChange],
  );

  const renderErrorText = useHelperFieldText();
  const isShowError = touched[name] && !!errors[name];

  return (
    <LocationAutocomplete
      minChars={3}
      defaultValue={value}
      error={isShowError}
      helperText={isShowError ? renderErrorText(errors[name]) : ''}
      inputProps={{
        onBlur,
        name,
        isShowLabel: true,
        ...inputProps,
      }}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};
export default LocationAutocompleteField;

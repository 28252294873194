import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesPreviousOrdersItemsDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPreviousOrdersItemsDialog, { lang: string }> =>
    createStyles({
      root: {
        padding: theme.spacing(50 / 8, 50 / 8, 38 / 8),
        backgroundColor: theme.palette.grey[50],
      },
      header: {
        paddingTop: theme.spacing(16 / 8),
        paddingBottom: theme.spacing(18 / 8),
        [theme.breakpoints.up('md')]: {
          paddingTop: 0,
          paddingBottom: 0,
          height: '120px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: theme.palette.secondary.main,
        },
      },
      headerTextRoot: {
        marginLeft: theme.spacing(20 / 8),
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          marginLeft: 0,
        },
      },
      headerText: {
        marginLeft: theme.spacing(10 / 8),
      },
      content: {
        width: '100%',
        background: theme.palette.background.paper,
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: theme.spacing(40 / 8),
        [theme.breakpoints.up('md')]: {
          minHeight: 'auto',
          height: '70vh',
          overflow: 'auto',
          paddingTop: theme.spacing(50 / 8),
          background: theme.palette.grey[50],
          borderRadius: 0,
          marginBottom: 0,
        },
      },
      button: ({ lang }) => ({
        width: lang === 'ru' ? '300px' : '250px',
        marginLeft: theme.spacing(22 / 8),
      }),
      refreshIcon: {
        fontSize: '22px',
        marginRight: theme.spacing(8 / 8),
      },
      loadPreviousOrderTitle: {
        lineHeight: '24px',
        fontSize: '22px',
      },
      addItemsBtn: {
        borderRadius: theme.spacing(100 / 8),
      },
      backButton: {
        paddingBottom: theme.spacing(14 / 8),
        cursor: 'pointer',
        padding: 0,
      },
      backIcon: {
        fontSize: '10px',
        marginRight: theme.spacing(2 / 8),
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesCartUniqueItemsCount } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCartUniqueItemsCount> =>
    createStyles({
      root: {
        position: 'relative',
        display: 'inline-flex',
      },
      count: {
        position: 'absolute',
        bottom: '17%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        [theme.breakpoints.up('md')]: {
          bottom: '12%',
        },
      },
    }),
);

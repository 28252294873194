import {
  CHANGE_PRODUCT_CARD_MODE,
  COMPLETE_REGISTRATION,
  FULLY_COMPLETE_REGISTRATION,
  INIT_STORE_ACCOUNTS,
  PAGE_VIEW,
  PRODUCT_CLICK,
  PROFILE_UPDATE,
  START_CHECKOUT,
  USER_LOGIN,
} from 'store/modules/analytics/constants';
import {
  TChangeProductCardModeAction,
  TFullyCompleteRegistrationAction,
  TInitStoreAnalyticsAccountsAction,
  TPageViewAction,
  TProductClickAction,
  TRegistrationCompleteAction,
  TStartCheckoutAction,
  TUserLoginAction,
  TUserProfileUpdateAction,
} from 'store/modules/analytics/types';

const initStoreAnalyticsAccounts: TInitStoreAnalyticsAccountsAction = () => ({
  type: INIT_STORE_ACCOUNTS,
});

const pageView: TPageViewAction = (page) => ({
  type: PAGE_VIEW,
  payload: page,
});

const completeRegistration: TRegistrationCompleteAction = (payload) => ({
  type: COMPLETE_REGISTRATION,
  payload,
});

const fullyCompleteRegistration: TFullyCompleteRegistrationAction = (payload) => ({
  type: FULLY_COMPLETE_REGISTRATION,
  payload,
});

const userLogin: TUserLoginAction = (payload) => ({
  type: USER_LOGIN,
  payload,
});

const startCheckout: TStartCheckoutAction = () => ({
  type: START_CHECKOUT,
});

const productClick: TProductClickAction = (payload) => ({
  type: PRODUCT_CLICK,
  payload,
});

const profileUpdate: TUserProfileUpdateAction = (payload) => ({
  type: PROFILE_UPDATE,
  payload,
});

const changeProductCardMode: TChangeProductCardModeAction = (payload) => ({
  type: CHANGE_PRODUCT_CARD_MODE,
  payload,
});

export {
  initStoreAnalyticsAccounts,
  pageView,
  completeRegistration,
  startCheckout,
  productClick,
  userLogin,
  fullyCompleteRegistration,
  profileUpdate,
  changeProductCardMode,
};

import { format } from 'date-fns';
import { toNumber } from 'lodash';
import { ICoupon, TOrderDiscount, TOrderDiscountState } from 'store/modules/orderDiscounts';
import { TValueFormulaTypeServer } from 'types';
import { TOrderReducerState } from 'store/modules/order';
import { TStoreProductReducerState } from 'store/modules/storeProduct';
import { getItemTotalPrice } from 'utils/helpers/cartEstimation';
import {
  arePromotionConditionsFulfilled,
  canItemRefundPromotionBeApplied,
} from 'utils/helpers/promotions/general';
import { IPromotion } from 'store/modules/promotions';
import { TOrderDetailsReducerState, TUserReducerState } from 'store';

export function calculateGeneralPromotionsAndCoupons(
  order: TOrderReducerState,
  orderDetails: TOrderDetailsReducerState,
  serviceAreaUsageFees: number,
  itemsTotalEstimation: number,
  generalPromotions: IPromotion[],
  coupons: ICoupon[],
  storeProductById: TStoreProductReducerState['storeProductById'],
  outOfStockStoreProductById: TStoreProductReducerState['outOfStockStoreProductById'],
  detailsAtStoreLevel: TUserReducerState['detailsAtStoreLevel'],
): TOrderDiscount[] {
  const maxAllowableAmountsBySourceId = initMaxAllowableAmountsBySourceId(
    order,
    serviceAreaUsageFees,
  );

  const orderDiscounts: TOrderDiscount[] = [];
  const sortedDiscounts = sortByPrecedence([...generalPromotions, ...coupons]);
  let rollingItemsEstimation = itemsTotalEstimation;
  let rollingEstimationWithCreditAtCheckout = itemsTotalEstimation;

  sortedDiscounts.forEach((discount) => {
    if ((discount as IPromotion).promotionType) {
      // promotion
      if (!(discount as IPromotion).skipPromotionValidation) {
        if (
          !arePromotionConditionsFulfilled(
            discount as IPromotion,
            rollingItemsEstimation,
            orderDetails,
            detailsAtStoreLevel,
          )
        ) {
          return;
        }
      }

      // TODO - if client coupon will have availability to choose "appliedIfProductNotInCart" we need to put this out of the if "promotionType"
      if (
        discount.discountType.name === 'itemRefund' &&
        !canItemRefundPromotionBeApplied(discount as IPromotion, order)
      ) {
        return;
      }
    }

    const discountTotalPrice = getDiscountTotalPriceAndUpdateMaxAllowableAmount(
      discount,
      rollingItemsEstimation,
      rollingEstimationWithCreditAtCheckout,
      maxAllowableAmountsBySourceId,
      order,
      storeProductById,
      outOfStockStoreProductById,
    );

    const expirationTime =
      discount.expirationTime &&
      (typeof discount.expirationTime === 'string'
        ? discount.expirationTime
        : format(new Date(discount?.expirationTime as Date), 'dd.MM.yy'));

    orderDiscounts.push({
      cartIndex: order.items.length - 1,
      state: TOrderDiscountState.Active,
      name: discount.name || discount.externalNotes,
      externalNotes: discount.externalNotes,
      totalPrice: discountTotalPrice as number,
      accumulatedProducts: [],
      accumulatedQuantity: 0,
      totalParticipatingProductsPrice: 0,
      discountType: discount.discountType,
      valueFormula: discount.valueFormula,
      valueFormulaType: discount.valueFormulaType,
      storeProductSource: discount.storeProductSource,
      valueFormulaSourceQuantity: discount.valueFormulaSourceQuantity,
      expirationTime,
      discountSourceType: discount.discountSourceType,
      promotion: (discount as IPromotion).nonObfuscatedId,
      promotionType: (discount as IPromotion).promotionType,
      parameters: (discount as IPromotion).parameters,
      creditedAtCheckout: discount.creditedAtCheckout,
    });

    if (discount.discountType.name !== 'serviceAreaUsageFeesDiscount') {
      rollingEstimationWithCreditAtCheckout += discountTotalPrice;
      if (!discount.creditedAtCheckout) {
        rollingItemsEstimation += discountTotalPrice as number;
      }
    }
  });

  return orderDiscounts;
}

function initMaxAllowableAmountsBySourceId(
  order: TOrderReducerState,
  serviceAreaUsageFees: number,
): Map<string | number, number> {
  return order.items
    .filter((item) => !item.isRemoved)
    .reduce(
      (map, item) => map.set(item.storeProduct.id, getItemTotalPrice(item)),
      new Map().set('serviceAreaUsageFees', serviceAreaUsageFees),
    );
}

function getDiscountTotalPriceAndUpdateMaxAllowableAmount(
  discount: IPromotion | ICoupon,
  cartEstimation: number,
  rollingEstimationWithCreditAtCheckout: number,
  maxAllowableAmountsBySourceId: Map<string | number, number>,
  order: TOrderReducerState,
  storeProductById: TStoreProductReducerState['storeProductById'],
  outOfStockStoreProductById: TStoreProductReducerState['outOfStockStoreProductById'],
): number {
  let allowableAmount;
  let discountTotalPrice;
  switch (discount.discountType.name) {
    case 'itemRefund':
      if (discount.valueFormulaType.sign === '%') {
        if (discount.appliedIfProductNotInCart) {
          allowableAmount =
            (discount.storeProductSource &&
              discount.storeProductSource.price * (discount.valueFormulaSourceQuantity || 0)) ||
            0;
        } else {
          if (!discount.storeProductSource) return 0;
          const refundCartProduct = order.items.find(
            (item) => item.storeProduct.id === discount.storeProductSource?.id,
          );

          const refundStoreProduct =
            storeProductById[discount.storeProductSource?.id] ||
            outOfStockStoreProductById[discount.storeProductSource?.id];

          const refundProductEstimatedUnitWeight = refundCartProduct
            ? refundStoreProduct.productSellingUnits.find(
                (sellingUnit) => sellingUnit.id === refundCartProduct.requestedSellingUnit.id,
              )?.estimatedUnitWeight
            : null;

          allowableAmount =
            discount.storeProductSource.price *
              Math.min(
                (refundCartProduct ? refundCartProduct.requestedQuantity : 0) *
                  (refundProductEstimatedUnitWeight || 1),
                discount.valueFormulaSourceQuantity || 0,
              ) || 0;
        }
      } else {
        allowableAmount = cartEstimation;
      }

      discountTotalPrice = calculateDiscountTotalPrice(
        discount.valueFormula,
        discount.valueFormulaType,
        allowableAmount,
      );
      break;
    case 'generalDiscount':
      // TODO - duplicate promotions (need to get flag & update logic from server)
      discountTotalPrice = calculateDiscountTotalPrice(
        discount.valueFormula,
        discount.valueFormulaType,
        discount.creditedAtCheckout ? rollingEstimationWithCreditAtCheckout : cartEstimation,
      );
      break;
    case 'serviceAreaUsageFeesDiscount':
      allowableAmount = maxAllowableAmountsBySourceId.get('serviceAreaUsageFees') || 0;
      discountTotalPrice = calculateDiscountTotalPrice(
        discount.valueFormula,
        discount.valueFormulaType,
        allowableAmount,
      );
      maxAllowableAmountsBySourceId.set(
        'serviceAreaUsageFees',
        allowableAmount + discountTotalPrice,
      );
      break;
    case 'itemForFree':
    default:
      return 0;
  }

  return discountTotalPrice;
}

function sortByPrecedence(discounts: (IPromotion | ICoupon)[]): (IPromotion | ICoupon)[] {
  return discounts.sort((a, b) => {
    return (
      toNumber(a.creditedAtCheckout) - toNumber(b.creditedAtCheckout) ||
      a.evaluationPhase.sortOrder - b.evaluationPhase.sortOrder ||
      a.discountSourceType.sortOrder - b.discountSourceType.sortOrder ||
      a.discountType.sortOrder - b.discountType.sortOrder ||
      a.valueFormulaType.sortOrder - b.valueFormulaType.sortOrder ||
      parseFloat(a.valueFormula) - parseFloat(b.valueFormula)
    );
  });
}

export function calculateDiscountTotalPrice(
  valueFormula: string,
  valueFormulaType: TValueFormulaTypeServer,
  maxAmountToGive: number,
): number {
  // TODO: produces a 'bug' with two discount 50% and 50%
  if (valueFormulaType.sign !== '%') {
    return -Math.min(maxAmountToGive, parseFloat(valueFormula));
  }
  return -Math.min(maxAmountToGive, (maxAmountToGive * parseFloat(valueFormula)) / 100);
}

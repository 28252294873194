import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesStoreDeliveryHoursDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesStoreDeliveryHoursDialog> =>
    createStyles({
      wrapper: {
        padding: theme.spacing(50 / 8, 20 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(60 / 8, 60 / 8, 44 / 8, 60 / 8),
        },
      },
      image: {
        width: theme.spacing(70 / 8),
        height: theme.spacing(70 / 8),
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      hourIcon: {
        fontSize: '26px',
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(6 / 8),
      },
      hoursTitle: {
        fontWeight: 'bold',
        fontSize: '26px',
        lineHeight: '28px',

        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
          fontSize: '30px',
          lineHeight: '34px',
        },
      },
      weekday: {
        fontSize: '16px',
      },
    }),
);

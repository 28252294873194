import { Box, CardMedia } from '@material-ui/core';
import classNames from 'classnames';
import { useCategoryMenu, useMobile, useWebsiteDetails } from 'hooks';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import CTAButton from 'ui/common/buttons/CTAButton';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import Link from 'ui/common/Link';

import Typography from 'ui/common/Typography';
import { prepareImageUrl } from 'utils/helpers/prepareImageUrl';
import useStyles from './styles';

import { ICategoriesGrid } from './types';

const CategoriesGrid: FC<ICategoriesGrid> = ({ categories, title }) => {
  const { categoriesByIdsIncludingParents } = useCategoryMenu();
  const websiteDetails = useWebsiteDetails();

  const { isMobile } = useMobile();
  const router = useRouter();

  const mainLayoutContext = useContext(MainLayoutContext);

  const classes = useStyles({ isOpenedTopCart: mainLayoutContext.isOpenedTopCart });

  const preparedCategoriesToDisplay = useMemo(() => {
    const bigItems = categories.filter((category) => category.type === 'big');
    const smallItems = categories.filter((category) => category.type === 'small');

    const categoriesInCorrectOrder = isMobile
      ? [
          bigItems[0],
          smallItems[0],
          smallItems[1],
          bigItems[bigItems.length - 1],
          smallItems[2],
          smallItems[3],
        ]
      : [bigItems[0], ...smallItems, bigItems[bigItems.length - 1]];

    const categoriesToDisplay = categoriesInCorrectOrder.map((category) => {
      if (categoriesByIdsIncludingParents[category.category.nonObfuscatedId]) {
        return {
          name: categoriesByIdsIncludingParents[category.category.nonObfuscatedId].name,
          id: categoriesByIdsIncludingParents[category.category.nonObfuscatedId].id,
          type: category.type,
          image: { ...category.value },
        };
      }

      return {
        name: category.category.name,
        type: category.type,
        image: { ...category.value },
      };
    });

    return categoriesToDisplay;
  }, [categories, categoriesByIdsIncludingParents, isMobile]);

  const bigGridItem = useCallback(
    (category) => {
      const template = (
        <>
          <CardMedia
            className={classes.categoryImageBig}
            src={prepareImageUrl(category.image.value)}
            component="img"
            alt={category.image.altText}
          />
          <Box className={classes.overlay}>
            <Typography className={classes.categoryName}>{category.name}</Typography>
          </Box>
        </>
      );

      if (category.id) {
        return (
          <Link
            className={classNames(classes.gridBigItem)}
            href={`category/${category.id}/${category.name}`}
          >
            {template}
          </Link>
        );
      }

      return <Box className={classNames(classes.gridBigItem)}>{template}</Box>;
    },
    [classes.categoryImageBig, classes.categoryName, classes.gridBigItem, classes.overlay],
  );

  const smallGridItem = useCallback(
    (category) => {
      const template = (
        <>
          <CardMedia
            className={classes.categoryImage}
            src={prepareImageUrl(category.image.value)}
            component="img"
            alt={category.image.altText}
          />
          <Box className={classes.overlay}>
            <Typography className={classes.categoryName}>{category.name}</Typography>
          </Box>
        </>
      );

      if (category.id) {
        return (
          <Link
            className={classNames(classes.gridItem)}
            href={`category/${category.id}/${category.name}`}
          >
            {template}
          </Link>
        );
      }

      return <Box className={classNames(classes.gridItem)}>{template}</Box>;
    },
    [classes.categoryImage, classes.categoryName, classes.gridItem, classes.overlay],
  );
  return (
    <Box className={classes.categoriesGridBackground}>
      <Box className={classes.categoriesGridWrapper}>
        <CardMedia
          classes={{ root: classes.categoriesGridImage }}
          alt=""
          component="img"
          image={websiteDetails.theme.homePage.images.categories}
        />
        <Box className={classes.categoriesGridContainer}>
          <Box
            display="flex"
            mb={isMobile ? 22 / 8 : 34 / 8}
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.sectionTitle} variant="body1" isTranslate={false}>
              {title}
            </Typography>
            {!isMobile && (
              <Box display="flex" alignItems="center">
                <Box>
                  <Link href="/all-products">
                    <Typography className={classes.linkLabel}>
                      {'homePage.toAllProducts'}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.gridContainer}>
            {preparedCategoriesToDisplay.map((category) => (
              <>
                {category.type === 'big' && bigGridItem(category)}
                {category.type === 'small' && smallGridItem(category)}
              </>
            ))}
          </Box>
        </Box>
        {isMobile && (
          <Box className={classes.buttonWrapper}>
            <CTAButton
              onClick={() => router.push('/all-products')}
              className={classes.loadMoreButton}
              size="medium"
              variant="outlined"
            >
              <Typography className={classes.loadMoreButtonLabel}>
                {'homePage.toAllProducts'}
              </Typography>
            </CTAButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CategoriesGrid;

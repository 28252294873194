import React, { FC, useMemo } from 'react';
import { Box, BoxProps, CardMedia, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';

import {
  useFooterAboutSection,
  useFooterAllRights,
  useFooterTerms,
  useHeader,
  usePoweredBy,
} from 'hooks';
import Container, { MAX_CONTAINER_WIDTH, MIN_TABLET_WIDTH } from 'ui/common/Container';
import Typography, { ITypography } from 'ui/common/Typography';
import Link from 'ui/common/Link';
import Icon from 'ui/common/icons/Icon';
import useAboutPageContent from 'hooks/useAboutPageContent';
import { useContentPages } from 'hooks/useContentPages';
import { TSocialAndContactItem } from 'ui/desktop/Footer';
import useStyles from './styles';

import { IFooterMobile } from './types';

const Footer: FC<IFooterMobile> = ({
  variant = 'full',
  websiteDetails,
  address,
  description,
  imagesUrl = {},
  termsItems,
  contactItems,
}) => {
  const { iconUrl, upImageUrl, bottomImageUrl, companyPoweredLogoUrl } = imagesUrl;

  const { aboutPage } = useAboutPageContent();
  const classes = useStyles({ hasAboutPage: !!aboutPage });
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH));

  const { renderDescription, renderIcon, renderLabel, renderSubTitle } = useFooterAboutSection();
  const { renderTerms } = useFooterTerms(termsItems);
  const { allRightsSection } = useFooterAllRights();
  const { renderPoweredBy } = usePoweredBy();
  const { createContentPagesList } = useContentPages();
  const { shouldDisplayStoreAddress } = useHeader();

  const renderTermsSection = useMemo(() => {
    return renderTerms();
  }, [renderTerms]);

  const renderPoweredBySection = useMemo(() => {
    return <>{companyPoweredLogoUrl && renderPoweredBy(companyPoweredLogoUrl)}</>;
  }, [renderPoweredBy, companyPoweredLogoUrl]);

  const renderIcons = useMemo(() => {
    return (
      <>
        <CardMedia
          classes={{ root: classNames(classes.upImage, { [classes.tabletUpImage]: isTablet }) }}
          component="img"
          image={upImageUrl}
          alt="Theme image"
        />
        <CardMedia
          classes={{
            root: classNames(classes.bottomImage, { [classes.tabletBottomImage]: isTablet }),
          }}
          component="img"
          image={bottomImageUrl}
          alt="Theme image"
        />
      </>
    );
  }, [classes, bottomImageUrl, upImageUrl, isTablet]);

  const renderMenuItems = useMemo(() => {
    if (!createContentPagesList().length) {
      return null;
    }

    return (
      <>
        {createContentPagesList(true, false).map((contentPage) => {
          return (
            <Link
              className={classes.menuItemLink}
              key={contentPage.id}
              href={`/${contentPage.type}${
                contentPage.id
                  ? contentPage.nonObfuscatedId && `/${contentPage.nonObfuscatedId}`
                  : ''
              }`}
            >
              <Typography fontSize={13} color="light" className={classes.menuItem}>
                {contentPage.name}
              </Typography>
            </Link>
          );
        })}
      </>
    );
  }, [classes.menuItem, classes.menuItemLink, createContentPagesList]);

  const socialItems = useMemo<TSocialAndContactItem[]>(() => {
    const socials = contactItems?.filter(({ label }) => !label) || [];
    if (aboutPage) {
      return socials;
    }

    const relevantContactItems = ['icon-mail', 'icon-phone', 'icon-whatsapp'];
    const contacts =
      contactItems?.filter(({ iconName }) => relevantContactItems.includes(iconName)) || [];

    return contacts.concat(socials);
  }, [aboutPage, contactItems]);

  const socialItemsContent = useMemo(() => {
    return (
      <>
        {socialItems
          ?.filter(({ link }) => !!link.href)
          .map(({ iconName, id, link }) => {
            return (
              <Box className={classes.socialLinkWrapper} key={id}>
                <Link className={classes.socialLinkItem} targetBlank {...link}>
                  <Icon name={iconName} color="action" classes={{ root: classes.socialIcon }} />
                </Link>
              </Box>
            );
          })}
      </>
    );
  }, [classes.socialIcon, classes.socialLinkItem, classes.socialLinkWrapper, socialItems]);

  const renderFullFooterVariant = useMemo(() => {
    const iconWrapperClasses: BoxProps = {
      display: 'flex',
      justifyContent: 'center',
    };

    const labelClasses: ITypography = {
      align: 'center',
    };

    const descriptionClasses: ITypography = {
      align: 'center',
      fontSize: 13,
    };

    const subTitleClasses: ITypography = {
      fontSize: 14,
    };
    return (
      <Box className={classNames(classes.fullWrapper, { [classes.tabletFullWrapper]: isTablet })}>
        {renderIcons}
        <Container>
          <Box className={classes.footerContent}>
            <Box>
              {renderIcon(iconUrl, iconWrapperClasses)}
              <Box className={classes.labelContent}>
                <Box mb={shouldDisplayStoreAddress ? 12 / 8 : 5 / 8}>
                  {renderLabel(websiteDetails.name, labelClasses)}
                </Box>
                {shouldDisplayStoreAddress && address && (
                  <Box component="address" fontStyle="inherit">
                    <Link
                      href={`https://maps.google.com?q=${encodeURIComponent(address)}`}
                      targetBlank
                    >
                      {renderSubTitle(address, subTitleClasses)}
                    </Link>
                  </Box>
                )}
                <Box>{renderDescription(description, descriptionClasses)}</Box>
              </Box>
            </Box>
          </Box>
          <Divider classes={{ root: classes.dividerHorizontalRoot }} />
          {!!aboutPage && <Box className={classes.menuWrapper}>{renderMenuItems}</Box>}
          <Box className={classes.contactOrSocialsWrapper}>{socialItemsContent}</Box>
        </Container>
      </Box>
    );
  }, [
    classes.fullWrapper,
    classes.tabletFullWrapper,
    classes.footerContent,
    classes.labelContent,
    classes.dividerHorizontalRoot,
    classes.menuWrapper,
    classes.contactOrSocialsWrapper,
    isTablet,
    renderIcons,
    renderIcon,
    iconUrl,
    shouldDisplayStoreAddress,
    renderLabel,
    websiteDetails.name,
    address,
    renderSubTitle,
    renderDescription,
    description,
    aboutPage,
    renderMenuItems,
    socialItemsContent,
  ]);

  return (
    <Box className={classes.wrapper}>
      {variant === 'full' && renderFullFooterVariant}
      <Box className={classes.miniWrapper}>
        <Container>
          <Box>{renderPoweredBySection}</Box>
          <Box className={classes.termsSection}>{renderTermsSection}</Box>
          <Box className={classes.logoSection}>{allRightsSection}</Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;

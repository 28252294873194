import { Box, CardMedia } from '@material-ui/core';

import { useDialog } from 'hooks/useDialog';
import { useMobile } from 'hooks/useMobile';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';

import { useRouter } from 'next/router';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';
import CTAButton from 'ui/common/buttons/CTAButton';
import Typography from 'ui/common/Typography';
import useStyles from './styles';
import { IActiveSubscriptionDialog } from './types';

const ActiveSubscriptionDialog: FC<IActiveSubscriptionDialog> = ({ subscription }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { hideDialog } = useDialog();
  const { isMobile } = useMobile();

  const websiteDetails = useWebsiteDetails();

  const router = useRouter();
  const { t } = useTranslation();

  const closeDialog = useCallback(() => {
    dispatch(introductoryPopupsCompletedAction(true));

    hideDialog();
  }, [dispatch, hideDialog]);

  const editOrder = useCallback(() => {
    router.push(`/account/subscriptions/view/${subscription.id}`);
    hideDialog();
  }, [hideDialog, router, subscription.id]);

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={websiteDetails.theme.images.hasActiveSubscription}
          component="img"
          className={classes.image}
          alt="Warning"
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h1" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.activeSubscription.title'}
        </Typography>
        <Box mb={isMobile ? 10 / 8 : 12 / 8} mt={10 / 8}>
          <Box>
            <Typography
              variant="body1"
              fontSize={16}
              color="grey"
              align="center"
              className={classes.existingOrderBody}
              isTranslate={false}
            >
              {t('dialog.activeSubscription.body', {
                nextOrderDate: `${subscription.expectedNextOrderDate}, ${subscription.expectedNextOrderTimeFrame}`,
              })}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              variant="body1"
              fontSize={16}
              color="textSecondary"
              align="center"
              fontWeight="medium"
            >
              {'dialog.activeSubscription.bodyDescription'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <CTAButton onClick={editOrder} className={classes.rootButton}>
          <Typography fontWeight="medium">{'dialog.activeSubscription.okButton'}</Typography>
        </CTAButton>
        <CTAButton onClick={closeDialog} className={classes.rootButton} variant="outlined">
          <Typography fontWeight="medium">{'dialog.activeSubscription.cancelButton'}</Typography>
        </CTAButton>
      </Box>
    </Box>
  );
};

export default ActiveSubscriptionDialog;

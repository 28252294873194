import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { TStylesSuspendSubscription } from './types';

export const dialogDownBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSuspendSubscription, { lang: string }> =>
    createStyles({
      root: {
        padding: theme.spacing(4.75, theme.direction === 'ltr' ? 12 / 8 : 24 / 8), // 38px 24px
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(8.75, 8.75), // 70px 70px
        },
      },
      image: {
        width: theme.spacing(70 / 8),
        height: theme.spacing(70 / 8),
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      title: {
        marginTop: theme.spacing(22 / 8),

        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(20 / 8),
        },
      },
      titleMain: {
        fontSize: theme.spacing(26 / 8),
        lineHeight: `${theme.spacing(28 / 8)}px`,
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(30 / 8),
          lineHeight: `${theme.spacing(34 / 8)}px`,
        },
      },
      searchRoot: {
        marginTop: theme.spacing(2.875), // 23px
      },
      rootButton: ({ lang }) => ({
        width: '100%',
        marginTop: theme.spacing(1.5), // 12px
        whiteSpace: lang === 'ru' ? 'initial' : 'nowrap',
      }),
      rootButtonOutlined: {
        width: '100%',
        fontSize: '16px !important',
        marginTop: theme.spacing(12 / 8),
        fontWeight: 'normal',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px !important',
          fontWeight: 500,
        },
        '&:disabled': {
          color: 'rgba(198, 203, 203, 0.5)',
          backgroundColor: '#fff',
          border: `1.5px solid ${theme.palette.grey.A100}`,
        },
      },
      footer: {
        marginTop: theme.spacing(2.75), // 22px

        [theme.breakpoints.down(dialogDownBreakpoint)]: {
          marginTop: theme.spacing(2.5), // 20px
        },
      },
      footerItem: {
        marginBottom: theme.spacing(0.5), // 4px

        [theme.breakpoints.down(dialogDownBreakpoint)]: {
          marginBottom: theme.spacing(0.625), // 5px
        },
      },
      linkText: {
        cursor: 'pointer',
      },
    }),
);

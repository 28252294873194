import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesBasketDialog, TStylesBasketDialogProps } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesBasketDialog, TStylesBasketDialogProps> =>
    createStyles({
      root: {
        padding: theme.spacing(26 / 8, 14 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(37 / 8, 50 / 8),
        },
      },
      title: {
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 2,
        overflow: 'hidden !important',
        fontSize: '22px',
        marginBottom: theme.spacing(6 / 8),
      },
      header: ({ lang }) => ({
        backgroundColor: theme.palette.secondary.main,
        height: lang === 'ru' ? '150px' : '132px',
        [theme.breakpoints.up('md')]: {
          height: '120px',
        },
      }),
      replaceSectionWrapper: {
        flex: 1,
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(20 / 8),
        },
      },
      headerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(26 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: '0',
        },
      },
      content: {
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(74 / 8),
        minHeight: 'calc(100vh - 202px)',
        [theme.breakpoints.up('md')]: {
          paddingBottom: 0,
          maxHeight: '60vh',
        },
      },
      closeButton: {
        position: 'absolute',
        right: 12,
        top: 24,
        [theme.breakpoints.up('md')]: {
          right: 0,
          top: 0,
        },
      },
      closeIcon: {
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
        },
      },
      saveBtnRoot: {
        width: '200px',
        height: '46px',
        boxShadow: 'none',
      },
      saveMobileBtnRoot: {
        borderRadius: '23px',
        height: '46px',
      },
      contentWrapper: {
        padding: theme.spacing(25 / 8, 0),
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'space-between',
          padding: theme.spacing(36 / 8, 50 / 8),
        },
      },
      saveMobileBtnWrapper: {
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(14 / 8),
        boxShadow: '0 -2px 7px 0 rgba(0, 0, 0, 0.1)',
        marginTop: theme.spacing(30 / 8),
      },
      inBasketSection: {
        flex: 1,
        padding: theme.spacing(0, 14 / 8),

        [theme.breakpoints.up('md')]: {
          padding: '0',
          flex: 1,
          marginRight: theme.spacing(20 / 8),
        },
      },
      replaceSection: {
        position: 'relative',
        marginTop: theme.spacing(24 / 8),
        backgroundColor: theme.palette.grey.A100,
        padding: theme.spacing(24 / 8, 14 / 8),
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.up('md')]: {
          boxShadow: 'initial',
          backgroundColor: 'transparent',
          padding: '0',
          marginTop: theme.spacing(0),
        },
      },
      inBasketRestSection: {
        padding: theme.spacing(0, 14 / 8),
        [theme.breakpoints.up('md')]: {
          padding: '0',
          marginTop: ({ isReplaceSectionShow }) =>
            isReplaceSectionShow ? theme.spacing(24 / 8) : 0,
        },
      },
      cardWrapper: {
        marginTop: theme.spacing(12 / 8),
      },
    }),
);

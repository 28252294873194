import React, { FC, useMemo } from 'react';

import { Tooltip as MUITooltip } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import { useMobile } from 'hooks';
import { ITooltip } from './types';
import useStyles from './styles';

const Tooltip: FC<ITooltip> = ({ children, title, initialClasses, ...rest }) => {
  const { isMobile } = useMobile();

  const classes = useStyles({ isMobile });

  const titleTemplate = useMemo(() => {
    return (
      <Typography className={classes.title} color="light" isTranslate={false}>
        {title}
      </Typography>
    );
  }, [title, classes]);

  return (
    <MUITooltip
      arrow
      title={titleTemplate}
      classes={{
        tooltip: (initialClasses && initialClasses.tooltip) || classes.tooltip,
        arrow: (initialClasses && initialClasses.arrow) || classes.arrow,
        popper: (initialClasses && initialClasses.popper) || classes.popper,
        touch: (initialClasses && initialClasses.touch) || classes.touch,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
      }}
      {...rest}
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;

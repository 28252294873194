import React, { FC, useEffect, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import useStyles from 'components/common/Dialog/components/TermsOfUseDialog/styles';
import { LegalInfoService } from 'api';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import SkeletonAccessibilityTerms from 'components/common/skeletons/SkeletonAccessibilityTerms';
import { IPrivacyPolicyContent } from './types';

const PrivacyPolicyContent: FC<IPrivacyPolicyContent> = ({ backToText, onBackClick }) => {
  const classes = useStyles();

  const [privacyPolicy, setPrivacyPolicy] = useState<string>();

  useEffect(() => {
    LegalInfoService.getPrivacyPolicy().then((actionResult) => {
      setPrivacyPolicy(actionResult.data);
    });
  }, []);

  return (
    <>
      {!privacyPolicy ? (
        <SkeletonAccessibilityTerms />
      ) : (
        <Box className={classes.root}>
          {onBackClick && (
            <Box className={classes.backTo}>
              <IconButton aria-label="close" onClick={onBackClick}>
                <Icon name="icon-arrow" color="primary" />
              </IconButton>
              <Typography
                variant="body1"
                fontSize={14}
                color="secondary"
                className={classes.backToText}
              >
                {backToText}
              </Typography>
            </Box>
          )}
          <Typography
            component="div"
            className={classes.textContent}
            dangerouslySetInnerHTML={{ __html: privacyPolicy }}
          />
        </Box>
      )}
    </>
  );
};

export default PrivacyPolicyContent;

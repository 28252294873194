import { TOrderDiscountsReducerState } from './types';

export const initialStaticPromotionsState = {
  staticPromotions: {
    staticPromotionsIds: [],
    staticPromotionsById: {},
  },
};

export const initialMembershipBenefits = {
  membershipBenefits: undefined,
};

export const initialOrderDiscountsState: TOrderDiscountsReducerState = {
  discounts: [],
  clientCoupons: [],
  ...initialMembershipBenefits,
  ...initialStaticPromotionsState,
};

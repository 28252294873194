import { Box, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import Repeat from 'components/common/Repeat';
import { useMobile } from 'hooks';
import React, { FC } from 'react';
import useStyles from './styles';

const SkeletonSelfPickupPoints: FC = () => {
  const classes = useStyles();
  const { isMobile } = useMobile();

  return (
    <>
      <Skeleton className={classes.skeletonLoader} variant="rect" width={160} height={24} />

      <Box>
        <Repeat times={4}>
          <Box mt={24 / 8}>
            <Box display={isMobile ? 'block' : 'flex'} justifyContent="space-between">
              <Skeleton className={classes.skeletonLoader} variant="rect" />
              <Skeleton className={classes.skeletonButtonLoader} variant="rect" />
            </Box>
            <Divider className={classes.divider} />
          </Box>
        </Repeat>
      </Box>
    </>
  );
};

export default SkeletonSelfPickupPoints;

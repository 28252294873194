import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSellingUnitsSelectionButtons } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesSellingUnitsSelectionButtons,
    { height: string; size: 'small' | 'medium' | 'large' }
  > =>
    createStyles({
      unitsTypeWrapper: ({ height, size }) => ({
        display: 'flex',
        width: 'fit-content',
        maxWidth: size === 'large' ? '150px' : '126px',
        height,
        backgroundColor: theme.palette.grey.A100,
        borderRadius: size === 'large' ? '20px' : '15px',
        margin: '0 auto',
        direction: 'ltr',
      }),
      sellingUnitButton: ({ size }) => ({
        borderRadius: size === 'large' ? '20px' : '15px',
        alignItems: 'center',
        minWidth: '40px',
        display: 'flex',
        justifyContent: 'center',
        padding: size === 'large' ? theme.spacing(6 / 8, 16 / 8) : theme.spacing(5 / 8, 14 / 8),
        border: 'none',
      }),
      activeSellingUnit: ({ size }) => ({
        backgroundColor: size === 'small' ? theme.palette.grey.A100 : theme.palette.secondary.main,
        color: size === 'small' ? theme.palette.text.secondary : theme.palette.background.paper,
        '&:hover': {
          backgroundColor:
            size === 'small' ? theme.palette.grey.A100 : theme.palette.secondary.main,
          color: size === 'small' ? theme.palette.text.secondary : theme.palette.background.paper,
        },
        '&:disabled': {
          color: size === 'small' ? theme.palette.text.secondary : theme.palette.background.paper,
          boxShadow: 'inherit',
          backgroundColor:
            size === 'small'
              ? theme.palette.grey.A100
              : `${theme.palette.secondary.main} !important`,
        },
      }),
      disabledSellingUnit: {
        color: theme.palette.text.secondary,
        '&:hover': {
          backgroundColor: 'rgba(66, 36, 13, 0.04)',
          color: theme.palette.text.secondary,
        },
      },
      sellingUnitLabel: ({ size }) => ({
        fontSize: size === 'large' ? '16px' : '13px',
        lineHeight: 1.23,
        whiteSpace: 'nowrap',
        textTransform: 'lowercase',
      }),
    }),
);

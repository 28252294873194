import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import {
  IDropDown,
  TDropDownBorderRadiusObj,
  TDropDownHeigthObj,
  TIconArrowObj,
  TRenderBorderRadius,
  TRenderDropDownHeigth,
  TRenderIconArrowFontSize,
  TStylesDropDown,
} from './types';

const renderHeight: TRenderDropDownHeigth = (size = 'medium') => {
  const sizeObj: TDropDownHeigthObj = {
    small: 2.5, // 20px
    medium: 3.25, // 26px
    large: 5, // 40px
    extraLarge: 50 / 8,
  };

  return sizeObj[size];
};

const renderBorderRadius: TRenderBorderRadius = (size = 'medium') => {
  const sizeObj: TDropDownBorderRadiusObj = {
    small: 1.25, // 10px
    medium: 1.875, // 15px
    large: 2.5, // 20px
    extraLarge: 1,
  };

  return sizeObj[size];
};

const renderIconArrowFontSize: TRenderIconArrowFontSize = (size = 'medium') => {
  const sizeObj: TIconArrowObj = {
    small: 0.875, // 7px
    medium: 1.5, // 112px
    large: 1.75, // 14px
    extraLarge: 18 / 8,
  };

  return sizeObj[size];
};

const downBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDropDown, IDropDown> =>
    createStyles({
      rootSelect: {
        fontSize: ({ size }) =>
          size === 'small'
            ? theme.spacing(1.625) // 13px
            : theme.spacing(1.75), // 14px
        padding: 0,
        boxSizing: 'border-box',
        color: theme.palette.text.secondary,
        height: ({ size }) => theme.spacing(renderHeight(size)),
        lineHeight: ({ size }) => `${theme.spacing(renderHeight(size))}px`,

        [theme.breakpoints.down(downBreakpoint)]: {
          height: ({ size }) =>
            size === 'medium'
              ? theme.spacing(3.25) // 26px
              : '', // need empty string to use renderHeight sizes
          lineHeight: ({ size }) =>
            `${
              size === 'medium'
                ? theme.spacing(3.25) // 26px
                : ''
            }px`,
        },

        '&$notchedOutline': {
          textAlign: 'left',
          display: ({ size }) => (size === 'extraLarge' ? 'flex' : 'block'),
          whiteSpace: 'nowrap',
          overflow: 'hidden !important',
          textOverflow: 'ellipsis',
          border: 'none',
          paddingRight: ({ size }) => {
            if (size === 'extraLarge') {
              return '16px';
            }
            if (theme.direction === 'rtl') {
              return theme.spacing(size === 'small' ? 7 / 8 : 10 / 8);
            }
            return theme.spacing(size === 'small' ? 7 / 8 : 24 / 8);
          },
          paddingLeft: ({ size }) => {
            if (size === 'extraLarge') {
              return '16px';
            }
            if (theme.direction === 'rtl') {
              return theme.spacing(size === 'small' ? 7 / 8 : 24 / 8);
            }
            return theme.spacing(size === 'small' ? 7 / 8 : 10 / 8);
          },
          color: theme.palette.text.secondary,
          [theme.breakpoints.up('md')]: {
            paddingRight: ({ size }) => {
              if (size === 'extraLarge') {
                return '16px';
              }
              if (theme.direction === 'rtl') {
                return theme.spacing(size === 'small' ? 7 / 8 : 14 / 8); // 0
              }
              return theme.spacing(size === 'small' ? 7 / 8 : 24 / 8);
            },
            paddingLeft: ({ size }) => {
              if (size === 'extraLarge') {
                return '16px';
              }
              if (theme.direction === 'rtl') {
                return theme.spacing(size === 'small' ? 7 / 8 : 24 / 8);
              }
              return theme.spacing(size === 'small' ? 7 / 8 : 14 / 8); // 0
            },
          },
        },

        '&$notchedOutline$rootSelectWithIcon': {
          padding: theme.spacing(0, 3),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 3),
          },
        },

        '&[aria-expanded = true] + input + $iconArrow': {
          transform: 'rotate(180deg) translate(0, 50%)',
        },

        '&:focus': {
          background: theme.palette.background.paper,
          borderRadius: ({ size }) => theme.spacing(renderBorderRadius(size)),

          [theme.breakpoints.down(downBreakpoint)]: {
            borderRadius: ({ size }) =>
              size === 'large'
                ? theme.spacing(0.75) // 6px
                : theme.spacing(renderBorderRadius(size)),
          },
        },
      },
      placeholder: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        fontSize: ({ size }) =>
          size === 'small'
            ? theme.spacing(1.625) // 13px
            : theme.spacing(1.75), // 14px
        lineHeight: ({ size }) => `${theme.spacing(renderHeight(size))}px`,
      },
      // do not remove, ised in notchedOutline styles
      rootSelectWithIcon: {},
      root: {
        '&:hover $notchedOutline': {
          border: `1px solid ${theme.palette.grey.A200}`,
        },

        '&:hover $rootSelect': {
          border: 'none',
        },

        '&$focused $notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
      },
      notchedOutline: ({ size, addBorderRadius }) => ({
        border: `1px solid ${theme.palette.grey.A200}`,
        display: 'flex',
        alignItems: 'center',
        borderRadius:
          size === 'large'
            ? theme.spacing(0.75) // 6px
            : theme.spacing(renderBorderRadius(size)),

        [theme.breakpoints.up('md')]: {
          borderRadius: addBorderRadius
            ? theme.spacing(renderBorderRadius(size))
            : theme.spacing(0.75),
        },
      }),
      focused: ({ size, addBorderRadius }) => ({
        background: theme.palette.background.paper,
        opacity: 0.9,

        borderRadius:
          size === 'large'
            ? theme.spacing(0.75) // 6px
            : theme.spacing(renderBorderRadius(size)),

        [theme.breakpoints.up('md')]: {
          borderRadius: addBorderRadius
            ? theme.spacing(renderBorderRadius(size))
            : theme.spacing(0.75),
        },
      }),
      paper: {
        width: 'auto',
        marginTop: theme.spacing(1), // 8px
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: theme.spacing(0.75), // 6px
      },
      iconArrow: {
        top: '50%',
        transform: 'translate(0, -50%)',
        right: theme.spacing(10 / 8),
        position: 'absolute',
        pointerEvents: 'none',
        fontSize: ({ size }) => theme.spacing(renderIconArrowFontSize(size)),
        [theme.breakpoints.up('md')]: {
          right: theme.spacing(12 / 8),
        },
      },
      iconArrowSmall: {
        right: theme.spacing(0.75), // 6px
      },
      startIcon: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        left: theme.spacing(1.25), // 10px
        fontSize: ({ size }) =>
          size === 'large'
            ? theme.spacing(2) // 16px
            : theme.spacing(1.25), // 10px
      },
      menuList: {
        padding: theme.spacing(1.25, 0), // 10px 0
      },
      menuItem: {
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(1.75), // 14px
        padding: theme.spacing(0.75, 1.625), // 6px 13px
        minWidth: ({ menuItemWidth }) => menuItemWidth || 'auto',
        minHeight: 'auto',

        '&$menuItemSelected': {
          backgroundColor: 'inherit',
        },
      },
      menuItemSelected: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      helperText: {
        lineHeight: theme.spacing(1.1 / 8),
        fontSize: theme.spacing(1.625), // 13px
        marginTop: theme.spacing(0.25), // 2px
      },
      labelFocused: {},
      labelError: {},
      asterisk: {},
      labelRoot: {
        color: theme.palette.grey[600],
        fontSize: theme.spacing(1.625), // 13px
        marginBottom: theme.spacing(0.875), // 7px

        '&$labelFocused': {
          color: theme.palette.grey[600],
        },

        '&$labelError': {
          color: theme.palette.grey[600],
        },

        '& $labelError$asterisk': {
          color: theme.palette.grey[600],
        },
      },
      formControl: {
        minWidth: '40px',
        maxWidth: 700,
      },
    }),
);

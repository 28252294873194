import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { TStylesDeliveryPickUpTabs } from './types';

const downBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDeliveryPickUpTabs> =>
    createStyles({
      wrapper: {
        width: '100%',
      },
      tabsRoot: {
        minHeight: 0,
        overflow: 'visible',
      },
      tabRoot: {
        textTransform: 'none',
        paddingTop: theme.spacing(6 / 8),
      },
      flexContainer: {
        justifyContent: 'space-between',
      },
      tabWrapper: {
        flexDirection: 'row',
      },
      icon: {
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(0.75), // 6px
        marginTop: theme.spacing(0.75), // 6px
      },
      tabLabelIcon: {
        color: theme.palette.secondary.main,
        minHeight: theme.spacing(5.875), // 47px
        minWidth: '100%',
        opacity: 1,
        backgroundColor: theme.palette.background.paper,
        borderTopLeftRadius: theme.spacing(10 / 8),
        borderTopRightRadius: theme.spacing(10 / 8),
      },
      disable: {
        color: theme.palette.grey.A400,
        boxShadow: 'none',
        background: 'linear-gradient(to bottom, #f0f3f5 67%, #dfe1e3 100%)',
      },
      disableIcon: {
        color: theme.palette.grey.A400,
        boxShadow: 'none',
      },
      tabPanel: {
        borderRadius: '10px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.1)',
        borderBottomLeftRadius: theme.spacing(1), // 8px
        borderBottomRightRadius: theme.spacing(1), // 8px

        [theme.breakpoints.down(downBreakpoint)]: {
          padding: theme.spacing(22 / 8, 14 / 8, 30 / 8, 14 / 8),
          borderBottomLeftRadius: theme.spacing(0.75), // 6px
          borderBottomRightRadius: theme.spacing(0.75), // 6px
        },
      },
      indicator: {
        display: 'none',
      },
      disableLabel: {
        fontWeight: 400,
      },
      cornerRight: {
        flip: false as unknown as string,
        height: '10px',
        width: '10px',
        transform: 'rotate(90deg)',
        position: 'absolute',
        right: '-10px',
        top: '37px',
      },
      cornerLeft: {
        flip: false as unknown as string,
        height: '10px',
        width: '10px',
        position: 'absolute',
        left: '-10px',
        top: '37px',
      },
      tabWrapperWithCorner: {
        position: 'relative',
        flexDirection: 'row',
        color: theme.palette.secondary.main,
        height: theme.spacing(47 / 8),
        width: '100%',
        maxWidth: `calc(50% - 5px)`,
        opacity: 1,
        backgroundColor: theme.palette.background.paper,
        borderTopLeftRadius: theme.spacing(10 / 8),
        borderTopRightRadius: theme.spacing(10 / 8),
        boxShadow: 'rgb(0 0 0 / 6%) 0px -8px 8px 1px',
      },
      removeShadow: {
        boxShadow: 'none',
      },
      tabsFixed: {
        zIndex: 1,
        overflow: 'visible !important',
      },
    }),
);

import { alpha, createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesCTAButton } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCTAButton, { variant: string; loading?: boolean }> =>
    createStyles({
      root: {
        height: '40px',
        minWidth: '136px',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        borderRadius: '6px',
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
        },
      },
      rootSizeSmall: {
        whiteSpace: 'nowrap',
        height: '34px !important',
        fontSize: '16px !important',
        minWidth: '100px',
      },
      rootSizeLarge: {
        whiteSpace: 'nowrap',
        height: '46px !important',
        fontSize: '18px !important',
      },
      rootContained: ({ loading }) => ({
        height: '40px',
        fontSize: '16px',
        color: theme.palette.background.paper,

        '&:hover': { boxShadow: 'none' },

        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: `${alpha(theme.palette.primary.main, loading ? 1 : 0.4)} !important`,
          color: theme.palette.background.paper,
        },
      }),
      rootOutlined: {
        height: '40px',
        fontSize: '16px !important',
        color: theme.palette.grey.A700,
        fontWeight: 'normal',
        border: `1.5px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.paper,

        '&:hover': {
          boxShadow: 'none',
          border: `1.5px solid ${theme.palette.primary.main}`,
          backgroundColor: theme.palette.primary.light,
        },

        '&:active': {
          backgroundColor: theme.palette.primary.light,
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: ({ loading }) => alpha(theme.palette.primary.light, loading ? 1 : 0.4),
          border: `1.5px solid ${theme.palette.primary.main}`,
        },
      },
      outlinedSizeSmall: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      outlinedSizeLarge: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      loader: {
        '& span': {
          verticalAlign: 'middle',
        },
      },

      buttonRipple: ({ variant }) => ({
        backgroundColor: `${
          variant === 'outlined' ? theme.palette.primary.main : '#000'
        } !important`,
        opacity: `${variant === 'outlined' ? 0.4 : 0.3} !important`,
      }),
    }),
);

import { BaseApi } from 'api/Api';
import { calculateAndFormatArtificialDate } from 'utils';
import {
  TCancelOrderCreatedBySubscription,
  TCancelSubscription,
  TGetFrequencies,
  TGetSubscription,
  TGetSubscriptions,
  TPlaceSubscription,
  TPrepareToPlaceSubscription,
  TSuspendSubscription,
  TUnsuspendSubscription,
  TUpdateSubscriptionFromEditedOrder,
} from './types';

export default class SubscriptionService {
  static getFrequencies: TGetFrequencies = async (config) => {
    return BaseApi.instance(config).get('/client/client/orders/subscriptions/frequencies');
  };

  static getSubscriptions: TGetSubscriptions = async (config) => {
    return BaseApi.instance(config).get(`/client/client/orders/subscriptions`);
  };

  static getSubscription: TGetSubscription = async (subscriptionId, config) => {
    return BaseApi.instance(config).get(`/client/client/orders/subscriptions/${subscriptionId}`);
  };

  static suspendSubscription: TSuspendSubscription = async (subscriptionId, suspendUntil) => {
    return BaseApi.instance().post(
      `/client/client/orders/subscriptions/suspend`,
      `id=${subscriptionId}&suspendUntil=${suspendUntil}`,
    );
  };

  static unsuspendSubscription: TUnsuspendSubscription = async (subscriptionId) => {
    return BaseApi.instance().post(
      `/client/client/orders/subscriptions/unsuspend`,
      `id=${subscriptionId}`,
    );
  };

  static cancelSubscription: TCancelSubscription = async (subscriptionId) => {
    return BaseApi.instance().post(
      `/client/client/orders/subscriptions/revoke`,
      `id=${subscriptionId}`,
    );
  };

  static prepareToPlaceSubscription: TPrepareToPlaceSubscription = async (body) => {
    const bodyTransformed = {
      ...body,
      timeFrame: {
        ...body.timeFrame,
        dayOfWeek: body.timeFrame.dayOfWeek?.name,
        date: calculateAndFormatArtificialDate(body.timeFrame.dayOfWeek), // TODO remove with Aviad's help
      },
    };
    return BaseApi.instance().post(
      '/client/client/orders/subscriptions/prepare-to-place-subscription',
      bodyTransformed,
    );
  };

  static placeSubscription: TPlaceSubscription = async (body) => {
    return BaseApi.instance().post('/client/client/orders/subscriptions', body);
  };

  static editSubscription: TPlaceSubscription = async (body) => {
    return BaseApi.instance().put('/client/client/orders/subscriptions', body);
  };

  static cancelOrderCreatedBySubscription: TCancelOrderCreatedBySubscription = async (
    subscriptionId,
  ) => {
    return BaseApi.instance().post(
      `/client/client/orders/subscriptions/${subscriptionId}/orders/cancel`,
    );
  };

  static updateSubscriptionFromEditedOrder: TUpdateSubscriptionFromEditedOrder = async (body) => {
    return BaseApi.instance().post(
      `/client/client/orders/subscriptions/update-from-edited-order`,
      body,
    );
  };
}

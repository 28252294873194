import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, CardMedia, Divider } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import Repeat from 'components/common/Repeat';
import SkeletonTopCartPreviousOrderItem from 'components/common/skeletons/SkeletonTopCartPreviousOrderItem';
import { useWebsiteDetails } from 'hooks';
import { getDay, parse } from 'date-fns';
import { dateFormatInOrderHistory } from 'utils/constants';
import { TOrderServer } from 'types';
import { useTranslation } from 'react-i18next';
import ActivePreviousOrder from '../ActivePreviousOrder';
import PreviousOrder, { IPreviousOrder } from '../PreviousOrder';
import { IPreviousOrdersList, TGetPreviousOrderByIdAndProps } from './types';
import useStyles from './styles';

const PreviousOrdersList: FC<IPreviousOrdersList> = ({
  loading,
  previousOrders,
  previousOrdersById,
  onViewPreviousOrder,
  onViewActiveOrder,
  onEditActiveOrderStart,
}) => {
  const websiteDetails = useWebsiteDetails();
  const { t } = useTranslation();

  const classes = useStyles();

  const [nonNewOrders, setNonNewOrders] = useState<TOrderServer[]>([]);

  useEffect(() => {
    setNonNewOrders(
      previousOrders.filter((order: TOrderServer) => order.status.name !== 'newOrder'),
    );
  }, [previousOrders]);

  const getPreviousOrderByIdAndProps = useCallback<TGetPreviousOrderByIdAndProps>(
    (id) => {
      const previousOrder = previousOrdersById[id];
      const deliveryDate = parse(previousOrder.deliveryDate, dateFormatInOrderHistory, new Date());
      const weekDayNumber = getDay(deliveryDate);

      const previousOrderProps: IPreviousOrder = {
        price: previousOrder.cartEstimation,
        itemsCount: previousOrder.items.length,
        deliveryDate,
        weekDayNumber,
      };

      return { previousOrder, previousOrderProps };
    },
    [previousOrdersById],
  );

  const activePreviousOrder = useMemo(() => {
    const activeOrder = previousOrders.find(
      (previousOrder) => previousOrder.status.name === 'newOrder',
    );

    if (activeOrder) {
      const { previousOrderProps } = getPreviousOrderByIdAndProps(activeOrder.id);

      return { activeOrder, activeOrderProps: previousOrderProps };
    }
    return null;
  }, [previousOrders, getPreviousOrderByIdAndProps]);

  if (loading)
    return (
      <Repeat times={4}>
        <Box>
          <Divider />
          <SkeletonTopCartPreviousOrderItem />
        </Box>
      </Repeat>
    );

  if (previousOrders.length) {
    return (
      <Box>
        {activePreviousOrder && (
          <Box component="section" key={activePreviousOrder.activeOrder.id}>
            <ActivePreviousOrder
              activePreviousOrder={activePreviousOrder}
              onEditStart={onEditActiveOrderStart}
              onViewClick={onViewActiveOrder}
            />
          </Box>
        )}
        {nonNewOrders?.map((previousOrder) => {
          const { previousOrderProps } = getPreviousOrderByIdAndProps(previousOrder.id);
          return (
            <Box component="section" key={previousOrder.id}>
              <PreviousOrder
                watchButtonClick={onViewPreviousOrder(previousOrder)}
                {...previousOrderProps}
              />
              <Divider />
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box className={classes.noOrdersWrapper}>
      <CardMedia
        component="img"
        image={websiteDetails.theme.images.noOrders}
        classes={{ root: classes.image }}
        alt={t('order.noOrders')}
      />
      <Typography className={classes.title}>{'order.noOrders'}</Typography>
    </Box>
  );
};

export default PreviousOrdersList;

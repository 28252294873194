import { TStoreProductsDataSelector } from './types';

const storeProductsData: TStoreProductsDataSelector = ({
  storeProduct: {
    storeProductIds,
    storeProductById,
    outOfStockStoreProductIds,
    outOfStockStoreProductById,
    ancestorStoreProductById,
  },
}) => ({
  storeProductIds,
  storeProductById,
  outOfStockStoreProductIds,
  outOfStockStoreProductById,
  ancestorStoreProductById,
});

export { storeProductsData };

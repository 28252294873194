import React, { FC, useMemo } from 'react';
import { Box, CardMedia, useTheme } from '@material-ui/core';
import SaleHorizontalTag from 'ui/common/tags/SaleHorizontalTag';
import Typography from 'ui/common/Typography';
import { renderItemCount } from 'utils';
import { useCardPrice, useMobile } from 'hooks';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import { IFreeProductCard } from './types';
import useStyles from './styles';

const FreeProductCard: FC<IFreeProductCard> = ({
  quantity,
  storeProductDisplay,
  showPricePerUnit = false,
  expandedLayout = false,
  isClientCoupon,
}) => {
  const { isMobile } = useMobile();
  const theme = useTheme();
  const { renderLanguageField } = useMultiLanguage();
  const { renderCartItemTotalPrice } = useCardPrice(
    renderLanguageField(storeProductDisplay.sellingUnit.multiLang, 'name') || '',
  );

  const classes = useStyles({ showPricePerUnit, expandedLayout });

  const itemPriceContent = useMemo(() => {
    return (
      <Box component="td" className={classes.itemPrice}>
        <Typography color="textPrimary" fontSize={16}>
          {renderCartItemTotalPrice(0, storeProductDisplay.price, false, 'regular', true)}
        </Typography>
      </Box>
    );
  }, [classes.itemPrice, renderCartItemTotalPrice, storeProductDisplay.price]);

  const quantityAndSellingUnit = useMemo(() => {
    return (
      <Box component="td" className={classes.quantityAndSellingUnitWrapper}>
        <Typography
          variant="body2"
          fontWeight="bold"
          color="textPrimary"
          fontSize={16}
          align="center"
        >
          {renderItemCount(quantity || 0)}
        </Typography>
        <Box className={classes.sellingUnitWrapper}>
          <Typography color="grey" fontSize={13} align="center">
            {renderLanguageField(storeProductDisplay.sellingUnit.multiLang, 'name')}
          </Typography>
        </Box>
      </Box>
    );
  }, [
    classes.quantityAndSellingUnitWrapper,
    classes.sellingUnitWrapper,
    quantity,
    renderLanguageField,
    storeProductDisplay.sellingUnit.multiLang,
  ]);

  return (
    <Box component="article" key={storeProductDisplay.id}>
      <Box component="tr" className={classes.rootContainer}>
        <Box className={classes.mainContent}>
          <SaleHorizontalTag
            className={classes.tag}
            backgroundColor={
              isClientCoupon ? theme.palette.text.secondary : theme.palette.secondary.main
            }
            iconName={isClientCoupon ? 'icon-refund-2' : 'icon-discount'}
            label="tag.freeItem"
          />
          <Box component="td" className={classes.productDescriptionWrapper}>
            <Box className={classes.imageWrapper}>
              {/* product image */}
              <CardMedia
                classes={{ root: classes.cardImage }}
                component="img"
                alt={storeProductDisplay.name}
                image={storeProductDisplay.imageUrl}
              />
            </Box>
            <Box px={isMobile ? 1 : 0} alignSelf="center">
              <Box className={classes.description} textOverflow="ellipsis">
                <Typography
                  color="textPrimary"
                  className={classes.descriptionText}
                  isTranslate={false}
                >
                  {storeProductDisplay.name}
                </Typography>
              </Box>
              {isMobile && !expandedLayout && quantityAndSellingUnit}
            </Box>
          </Box>
          <Box display="flex">
            {showPricePerUnit && itemPriceContent}
            {(!isMobile || expandedLayout) && quantityAndSellingUnit}
            <Box component="td" className={classes.price}>
              <Typography component="div" fontWeight="bold" color="textPrimary" fontSize={16}>
                {renderCartItemTotalPrice(
                  0,
                  quantity ? storeProductDisplay.price * quantity || 0 : undefined,
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FreeProductCard;

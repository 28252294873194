import {
  GET_STORE_PRODUCTS_REQUEST,
  GET_STORE_PRODUCTS_SUCCESS,
  UPDATE_OUT_OF_STOCK_PRODUCTS,
  UPDATE_PRODUCTS_BY_ID_SUCCESS,
} from './constants';

import {
  TGetStoreProductsRequestAction,
  TGetStoreProductsSuccessAction,
  TUpdateOutOfStockProductsAction,
  TUpdateProductByIdSuccessAction,
} from './types';

const getStoreProductsRequest: TGetStoreProductsRequestAction = (
  storeProducts,
  useMLWeightEstimations,
) => ({
  type: GET_STORE_PRODUCTS_REQUEST,
  payload: {
    storeProducts,
    useMLWeightEstimations,
  },
});

const getStoreProductsSuccess: TGetStoreProductsSuccessAction = (
  newStoreProductIds,
  newStoreProductById,
  newAncestorStoreProductById,
) => ({
  type: GET_STORE_PRODUCTS_SUCCESS,
  payload: {
    storeProductIds: newStoreProductIds,
    storeProductById: newStoreProductById,
    ancestorStoreProductById: newAncestorStoreProductById,
  },
});

const updateProductByIdSuccess: TUpdateProductByIdSuccessAction = (newStoreProductById) => ({
  type: UPDATE_PRODUCTS_BY_ID_SUCCESS,
  payload: {
    storeProductById: newStoreProductById,
  },
});

const updateOutOfStockProducts: TUpdateOutOfStockProductsAction = (
  outOfStockStoreProductIds,
  outOfStockStoreProductById,
) => ({
  type: UPDATE_OUT_OF_STOCK_PRODUCTS,
  payload: {
    outOfStockStoreProductIds,
    outOfStockStoreProductById,
  },
});

export {
  getStoreProductsRequest,
  getStoreProductsSuccess,
  updateProductByIdSuccess,
  updateOutOfStockProducts,
};

import {
  CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED,
  FETCH_AND_UPDATE_CLIENT_AND_AREA_SUB_CATALOG,
  FETCH_AND_UPDATE_CLIENT_COMPENSATIONS_REQUEST,
  FETCH_AND_UPDATE_CLIENT_DETAILS_AT_STORE_LEVEL,
  FETCH_AND_UPDATE_CLUB_MEMBERSHIP_PROFILE,
  UPDATE_DELIVERY_AREA_SUB_CATALOG,
  UPDATE_DETAILS_AT_STORE_LEVEL,
  UPDATE_EXTERNAL_MEMBERSHIP,
  UPDATE_FULL_PROFILE_DATA_REQUEST,
  UPDATE_MINIMUM_PROFILE_DATA_REQUEST,
  UPDATE_PROFILE,
  UPDATE_USER_SUB_CATALOG,
} from './constants';

import {
  TClientDetailsAtStoreLevelWereUpdated,
  TClubMembership,
  TFetchAndUpdateClientAndAreaSubCatalog,
  TFetchAndUpdateClientCompensationsRequestAction,
  TFetchAndUpdateClientDetailsAtStoreLevelAction,
  TFetchAndUpdateClubMembershipProfileAction,
  TUpdateDeliveryAreaSubCatalog,
  TUpdateDetailsAtStoreLevelAction,
  TUpdateFullProfileDataRequestAction,
  TUpdateMinimumProfileDataRequestAction,
  TUpdateProfileAction,
  TUpdateUserSubCatalog,
} from './types';

const updateProfile: TUpdateProfileAction = (newProfile) => ({
  type: UPDATE_PROFILE,
  payload: { profile: newProfile },
});

const updateFullProfileDataRequest: TUpdateFullProfileDataRequestAction = (
  body,
  fieldsToUpdate,
  formikHelpers,
  callBack,
) => ({
  type: UPDATE_FULL_PROFILE_DATA_REQUEST,
  payload: { body, fieldsToUpdate, formikHelpers, callBack },
});

const updateMinimumProfileDataRequest: TUpdateMinimumProfileDataRequestAction = (
  body,
  fieldsToUpdate,
  formikHelpers,
  callBack,
) => ({
  type: UPDATE_MINIMUM_PROFILE_DATA_REQUEST,
  payload: { body, fieldsToUpdate, formikHelpers, callBack },
});

const updateDetailsAtStoreLevel: TUpdateDetailsAtStoreLevelAction = (newDetails) => ({
  type: UPDATE_DETAILS_AT_STORE_LEVEL,
  payload: { detailsAtStoreLevel: newDetails },
});

const clientDetailsAtStoreLevelWereUpdated: TClientDetailsAtStoreLevelWereUpdated = (
  wasUpdated,
) => ({
  type: CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED,
  payload: { clientDetailsAtStoreLevelWereUpdated: wasUpdated },
});

const fetchAndUpdateClientCompensationsRequest: TFetchAndUpdateClientCompensationsRequestAction =
  () => ({
    type: FETCH_AND_UPDATE_CLIENT_COMPENSATIONS_REQUEST,
  });

const updateUserSubCatalog: TUpdateUserSubCatalog = (userSubCatalogId) => ({
  type: UPDATE_USER_SUB_CATALOG,
  payload: { userSubCatalogId },
});

const updateDeliveryAreaSubCatalog: TUpdateDeliveryAreaSubCatalog = (deliveryAreaSubCatalogId) => ({
  type: UPDATE_DELIVERY_AREA_SUB_CATALOG,
  payload: { deliveryAreaSubCatalogId },
});

const fetchAndUpdateClientAndAreaSubCatalogRequest: TFetchAndUpdateClientAndAreaSubCatalog = (
  callback,
) => ({
  type: FETCH_AND_UPDATE_CLIENT_AND_AREA_SUB_CATALOG,
  payload: { callback },
});

const fetchAndUpdateClubMembershipProfileAction: TFetchAndUpdateClubMembershipProfileAction =
  () => ({
    type: FETCH_AND_UPDATE_CLUB_MEMBERSHIP_PROFILE,
  });

const fetchAndUpdateClientDetailsAtStoreLevelAction: TFetchAndUpdateClientDetailsAtStoreLevelAction =
  () => ({
    type: FETCH_AND_UPDATE_CLIENT_DETAILS_AT_STORE_LEVEL,
  });

const updateClubMembership: TClubMembership = (clubMembership) => ({
  type: UPDATE_EXTERNAL_MEMBERSHIP,
  payload: { clubMembership },
});

export {
  updateProfile,
  updateFullProfileDataRequest,
  updateDetailsAtStoreLevel,
  updateMinimumProfileDataRequest,
  fetchAndUpdateClientCompensationsRequest,
  updateUserSubCatalog,
  updateDeliveryAreaSubCatalog,
  fetchAndUpdateClientAndAreaSubCatalogRequest,
  clientDetailsAtStoreLevelWereUpdated,
  fetchAndUpdateClubMembershipProfileAction,
  updateClubMembership,
  fetchAndUpdateClientDetailsAtStoreLevelAction,
};

import { LOG_OUT_SUCCESS } from '../auth';

import { TUserReducer } from './types';

import {
  CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED,
  UPDATE_DELIVERY_AREA_SUB_CATALOG,
  UPDATE_DETAILS_AT_STORE_LEVEL,
  UPDATE_EXTERNAL_MEMBERSHIP,
  UPDATE_PROFILE,
  UPDATE_USER_SUB_CATALOG,
} from './constants';

import { initialUserState } from './initialState';

const userReducer: TUserReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
    case UPDATE_DETAILS_AT_STORE_LEVEL:
    case UPDATE_DELIVERY_AREA_SUB_CATALOG:
    case UPDATE_USER_SUB_CATALOG:
    case CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED:
    case UPDATE_EXTERNAL_MEMBERSHIP:
      return {
        ...state,
        ...action.payload,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...initialUserState,
      };

    default:
      return state;
  }
};

export { userReducer };

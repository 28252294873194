import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, CardMedia, useTheme } from '@material-ui/core';

import MobileProductImageSlider from 'ui/mobile/MobileProductImageSlider';

import { useMultiLanguage } from 'hooks/useMultiLanguage';
import { useMobile } from 'hooks';

import PromotionTag from 'ui/common/PromotionTag';
import ProductQualityTag from 'ui/common/ProductQualityTag';

import ImageMagnifier from 'components/common/ImageMagnifier';

import CustomImageLoader from 'components/common/CustomImageLoader';
import Image from 'next/image';

import { IBasicStoreProductCard } from './types';

import useStyles from './styles';

const BasicStoreProductCard: FC<IBasicStoreProductCard> = (props) => {
  const {
    fullName,
    imageUrl,
    promoted,
    promotion,
    productQuality,
    imageSize,
    additionalImages,
    isPopup,
  } = props;

  const { renderLanguageField } = useMultiLanguage();
  const { isMobile } = useMobile();
  const theme = useTheme();

  const classes = useStyles({ isPopup, imageSize });

  const [activeImageUrl, setActiveImageUrl] = useState<string>('');

  const productImages = useMemo(() => {
    return [imageUrl, ...additionalImages];
  }, [imageUrl, additionalImages]);

  const desktopAdditionalProductImages = useMemo(() => {
    return (
      <Box display="flex" mt={14 / 8}>
        {productImages.map((url, i) => {
          return (
            <Button
              key={i}
              onClick={() => setActiveImageUrl(url)}
              disableRipple
              className={classes.thumbnailWrapper}
            >
              <CardMedia
                classes={{
                  root:
                    url === activeImageUrl ? classes.activeThumbnailImage : classes.thumbnailImage,
                }}
                component="img"
                image={url}
                alt={fullName}
              />
            </Button>
          );
        })}
      </Box>
    );
  }, [
    productImages,
    classes.thumbnailWrapper,
    classes.activeThumbnailImage,
    classes.thumbnailImage,
    activeImageUrl,
    fullName,
  ]);

  const desktopProductImage = useMemo(() => {
    const image = !additionalImages.length ? imageUrl : activeImageUrl;

    return (
      <ImageMagnifier
        imageUrl={image}
        imageAlt={fullName}
        style={{
          width: theme.spacing((imageSize || 320) / 8),
          margin: '0 auto',
          borderRadius: '6px',
        }}
      />
    );
  }, [activeImageUrl, additionalImages.length, fullName, imageSize, imageUrl, theme]);

  const mobileProductImages = useMemo(() => {
    return additionalImages.length ? (
      <MobileProductImageSlider additionalImages={productImages} fullName={fullName} />
    ) : (
      <CustomImageLoader
        src={imageUrl}
        errorImageSrc="/assets/images/product-image-on-error.svg"
        alt={fullName}
        classes={classes.mobileImage}
        loaderComponent={
          <Image
            src="/assets/images/product-image-skeleton.svg"
            layout="fill"
            objectFit="contain"
            className={classes.mobileImage}
          />
        }
        unoptimized
      />
    );
  }, [additionalImages.length, productImages, fullName, imageUrl, classes.mobileImage]);

  useEffect(() => {
    setActiveImageUrl(imageUrl);
  }, [imageUrl]);

  return (
    <Box className={classes.basicCardWrapper}>
      <Card classes={{ root: classes.root }}>
        {/* promo tag */}
        {(promoted || promotion) && (
          <Box className={classes.tagSale}>
            <PromotionTag size="large" />
          </Box>
        )}

        {/* product quality  */}
        {productQuality && productQuality.displayQuality && (
          <Box className={classes.productType}>
            <ProductQualityTag label={renderLanguageField(productQuality.multiLang, 'name')} />
          </Box>
        )}
        {/* mobile product image and images slider or desktop product image */}
        {isMobile ? mobileProductImages : desktopProductImage}
      </Card>
      {/* desktop product additional images */}
      {!isMobile && !!additionalImages.length && desktopAdditionalProductImages}
    </Box>
  );
};

export default BasicStoreProductCard;

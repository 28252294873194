import i18next, { i18n } from 'i18next';

import russianMessages from 'i18n/locales/ru';
import englishMessages from './locales/en';
import hebrewMessages from './locales/he';

import { TCreateI18n, TResource } from './types';

const resources: TResource = {
  en: { translation: englishMessages },
  he: { translation: hebrewMessages },
  ru: { translation: russianMessages },
};

const createI18n: TCreateI18n = (lng = 'he', fallbackLng = 'he') => {
  const customI18next: i18n = i18next;

  i18next.init({
    fallbackLng,
    lng,
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
    },
    resources,
  });

  return customI18next;
};

export default createI18n;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Button, CardMedia } from '@material-ui/core';

import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';

import {
  DELIVERING_TO_ADDRESS_DIALOG,
  LOCATION_HOURS_DIALOG,
  LOGIN_DIALOG,
  shoppingFlowActions,
} from 'store';

import {
  useAutocompleteOptionTitle,
  useCity,
  useDialog,
  useSelfPickupAndDeliveryService,
  useWebsiteDetails,
} from 'hooks';
import { StoreService } from 'api/services/StoreService';
import Autocomplete, {
  IAutocompleteProps,
  TAutocompleteValue,
} from 'ui/common/inputs/Autocomplete';
import { TCityServer, TStoreSelfPickupAreaServer } from 'types';
import classNames from 'classnames';
import useStyles from './styles';

import { ILocationDialog } from './types';

const LocationDialog: FC<ILocationDialog> = () => {
  const minChars = 3;

  const classes = useStyles();

  const { showDialog } = useDialog();

  const websiteDetails = useWebsiteDetails();

  const { cities } = useCity();

  const { renderOptionText } = useAutocompleteOptionTitle();

  const dispatch = useDispatch();

  const { prepareSelfPickupAreasAndShowDialog } = useSelfPickupAndDeliveryService();

  const [options, setOptions] = useState<TCityServer[]>([]);
  const [city, setCity] = useState<TCityServer | null>(null);
  const [minCharsValid, setMinCharsValid] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selfPickupAreas, setSelfPickupAreas] = useState<TStoreSelfPickupAreaServer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(shoppingFlowActions.isShowLocationUpdateAction(true));
  }, [dispatch]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleCityChange = useCallback<
    Required<IAutocompleteProps<TCityServer, false>>['onChange']
  >((_, value) => {
    if (value) {
      setMinCharsValid(false);
      setCity(value as TCityServer);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!city) return;

    const actionResult = await StoreService.getDeliveringToTheAddress(
      {
        addressOrCity: {
          city,
        },
        storeJWE: websiteDetails.jsonWebEncryption,
      },
      { setLoading },
    );

    if (actionResult.data.deliveryAreasCount > 1 || actionResult.data.deliveryArea?.limited) {
      showDialog({
        dialogType: LOCATION_HOURS_DIALOG,
        contentProps: {
          city,
          areasCount: actionResult.data.deliveryAreasCount,
        },
        dialogProps: {
          hideCloseButton: !websiteDetails.websiteSettings.allowClosingDeliveringToAddressPopup,
          backdropClick: !websiteDetails.websiteSettings.allowClosingDeliveringToAddressPopup,
        },
      });
      return;
    }
    showDialog({
      dialogType: DELIVERING_TO_ADDRESS_DIALOG,
      contentProps: {
        deliveryAreasCount: actionResult.data.deliveryAreasCount,
        performsDelivery: !!actionResult.data.deliveryAreasCount,
        performsSelfPickup: actionResult.data.performsSelfPickup,
        deliveryArea: actionResult.data.deliveryArea,
        selfPickupAreas,
        city,
      },
    });
  }, [
    city,
    selfPickupAreas,
    showDialog,
    websiteDetails.jsonWebEncryption,
    websiteDetails.websiteSettings.allowClosingDeliveringToAddressPopup,
  ]);

  const handleLogin = useCallback(() => {
    showDialog({
      dialogType: LOGIN_DIALOG,
    });
  }, [showDialog]);

  const handleInputChange = useCallback<Required<IAutocompleteProps>['onInputChange']>(
    (_e, inputValue) => {
      setOptions([]);
      setMinCharsValid(false);

      if (inputValue.length >= (minChars || 1)) {
        setMinCharsValid(true);
        setOptions(cities);
      }
    },
    [cities, minChars],
  );

  const renderOption = useMemo<IAutocompleteProps<TAutocompleteValue, false>['renderOption']>(
    () =>
      ({ name }, { inputValue }) => {
        return (
          <Box>
            <Typography className={classes.cityOption}>
              {renderOptionText(inputValue, name)}
            </Typography>
          </Box>
        );
      },
    [classes.cityOption, renderOptionText],
  );

  useEffect(() => {
    StoreService.getStoreServiceAreas().then((actionResult) => {
      if (actionResult.data.selfPickupAreas) {
        setSelfPickupAreas(actionResult.data.selfPickupAreas);
      }
    });
  }, [websiteDetails.jsonWebEncryption]);

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={websiteDetails.theme.images.locationRangePopup}
          component="img"
          className={classes.image}
          alt=""
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h1" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.location.title'}
        </Typography>
        <Typography variant="body1" fontSize={16} color="grey" align="center">
          {'dialog.location.subTitle'}
        </Typography>
      </Box>
      <Box className={classes.searchRoot}>
        <Autocomplete<TCityServer, false>
          openAutocomplete={minChars ? isFocused && minCharsValid : undefined}
          options={options}
          overrideClassNames={{ option: classes.option }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          label="city"
          onInputChange={handleInputChange}
          renderOption={renderOption}
          onChange={handleCityChange}
        />
      </Box>
      <Box className={classes.rootButton}>
        <Box className={classes.footer}>
          <Box display="flex" justifyContent="center" className={classes.footerItem}>
            <Typography variant="body1" color="grey" fontSize={14} align="center">
              {'dialog.location.haveAccount'}
            </Typography>
            <Box>&nbsp;</Box>
            <Button
              className={classNames(classes.loginBtn, 'unstyled-button')}
              onClick={handleLogin}
              disableTouchRipple
            >
              <Typography
                variant="body1"
                color="primary"
                fontSize={14}
                align="center"
                fontWeight="medium"
                className={classes.linkText}
              >
                {'auth.loginText'}
              </Typography>
            </Button>
          </Box>
          {!!selfPickupAreas.length && (
            <Box textAlign="center">
              <Box display="inline-block">
                <Typography variant="body1" color="grey" fontSize={14} align="center">
                  {'dialog.location.interestingInSelfPickup'}
                </Typography>
              </Box>
              <Box display="inline-block">&nbsp;</Box>
              <Button
                className={classNames(classes.loginBtn, 'unstyled-button')}
                onClick={() => prepareSelfPickupAreasAndShowDialog(selfPickupAreas)}
                disableTouchRipple
              >
                <Typography
                  variant="body1"
                  color="primary"
                  fontSize={14}
                  align="center"
                  fontWeight="medium"
                  className={classes.linkText}
                >
                  {'dialog.location.chooseSelfPickup'}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <CTAButton fullWidth disabled={!city} onClick={handleSubmit} loading={loading}>
          {'button.continue'}
        </CTAButton>
      </Box>
    </Box>
  );
};

export default LocationDialog;

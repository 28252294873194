import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUseCardPrice } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseCardPrice> =>
    createStyles({
      priceText: {
        width: 'fit-content',
        alignItems: 'baseline',
        display: 'flex',
        margin: '0 auto',
        lineHeight: '16px',
      },
      productOldPriceText: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        marginTop: theme.spacing(2 / 8),
        textDecoration: 'line-through',
      },
      productSaleText: {
        marginTop: theme.spacing(2 / 8),
        color: theme.palette.error.main,
        letterSpacing: 'normal',
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 1,
        overflow: 'hidden !important',
        padding: '0px 18px',
        [theme.breakpoints.up('md')]: {
          padding: '0px 40px',
        },
      },
      originalTotalPrice: {
        textDecoration: 'line-through',
      },
      productSaleTextForProductDetails: {
        color: theme.palette.error.main,
        letterSpacing: 'normal',
        textAlign: 'center',
      },
    }),
);

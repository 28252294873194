import { Box, Chip } from '@material-ui/core';
import React, { FC, ReactNode, useMemo } from 'react';

import Icon from 'ui/common/icons/Icon';

import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { ISaleHorizontalTag } from './types';

const SaleHorizontalTag: FC<ISaleHorizontalTag> = (props) => {
  const {
    children,
    label = 'tag.deal',
    size,
    backgroundColor,
    iconName = 'icon-discount',
    iconColor = 'primary',
    ...rest
  } = props;

  const smallTriangleStyles = useMemo<boolean>(() => {
    return label === 'tag.freeItem' || label === 'tag.deal';
  }, [label]);

  const classes = useStyles({ ...props, backgroundColor, smallTriangleStyles });

  const renderLabel = useMemo<ReactNode>(() => {
    return (
      <>
        <Box className={classes.leftTopTriangle} />
        <Box className={classes.leftBottomTriangle} />
        <Box className={classes.rightBottomTriangle} />

        <Box display="flex" alignItems="center" width="100%">
          <Icon name={iconName} color={iconColor} classes={{ root: classes.icon }} />
          <Box width="96%" textAlign="initial">
            <Typography variant="body1" fontSize={size === 'large' ? 13 : 12} color="light">
              {children || label}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }, [
    classes.leftTopTriangle,
    classes.leftBottomTriangle,
    classes.rightBottomTriangle,
    classes.icon,
    iconName,
    iconColor,
    size,
    children,
    label,
  ]);

  return (
    <Chip
      label={renderLabel}
      classes={{
        root: classes.chipRoot,
        label: classes.label,
      }}
      {...rest}
    />
  );
};

export default SaleHorizontalTag;

export default function loadJS(url: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.onload = () => resolve();
    script.onerror = reject;
    script.src = url;
    document.head.appendChild(script);
  });
}

import React, { FC } from 'react';

import PreviousOrdersListContent from 'components/mobile/PreviousOrdersListContent';
import { ILoadPreviousOrdersDialog } from './types';

const LoadPreviousOrdersDialog: FC<ILoadPreviousOrdersDialog> = () => {
  return <PreviousOrdersListContent />;
};

export default LoadPreviousOrdersDialog;

import React, { FC, useMemo } from 'react';

import { Box, CardMedia } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import { useWebsiteDetails } from 'hooks';
import DaysAndHours from 'components/common/DaysAndHours';
import { useTranslation } from 'react-i18next';
import DeliveryFeesAndMinimumOrder from 'components/common/checkout/DeliveryFeesAndMinimumOrder';
import classNames from 'classnames';
import useStyles from './styles';
import { IStoreDeliveryHoursDialog } from './types';
import { deliveryOrSelfPickupBodyText } from '../DeliveringToAddressDialog/helpers';

const StoreDeliveryHoursDialog: FC<IStoreDeliveryHoursDialog> = ({
  storeOpenHours,
  deliveryAreasCount,
  performsSelfPickup,
  deliveryArea,
  cityName,
  withAreaName = false,
}) => {
  const { t } = useTranslation();
  const websiteDetails = useWebsiteDetails();
  const classes = useStyles();

  const areaName = useMemo(() => {
    return withAreaName ? `${cityName} - ${deliveryArea?.name}` : cityName;
  }, [cityName, withAreaName, deliveryArea?.name]);

  const daysAndHoursTitle = useMemo(() => {
    return (
      <Box mb={16 / 8}>
        <Typography variant="body1" fontSize={16} color="textSecondary" fontWeight="medium">
          {t('dialog.delivery.storeHours.title', { areaName })}
        </Typography>
      </Box>
    );
  }, [areaName, t]);

  return (
    <Box className={classes.wrapper}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems={'center'}
        flexDirection="column"
        mb={10 / 8}
      >
        <Box className={classes.rootImage} mb={20 / 8}>
          <CardMedia
            src={websiteDetails.theme.images.searchSuccess}
            component="img"
            className={classes.image}
            alt="Warning"
          />
        </Box>
        <Typography className={classNames(classes.hoursTitle, 'font-family-heebo')} variant="body1">
          {'dialog.deliveryToClientAddress.performDeliveryTitle'}
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={16} color="grey" align="center">
          {deliveryOrSelfPickupBodyText(!!deliveryAreasCount, performsSelfPickup)}
        </Typography>
        <DaysAndHours storeOpenHours={storeOpenHours} hideClosedDays title={daysAndHoursTitle}>
          <DeliveryFeesAndMinimumOrder
            deliveryFees={deliveryArea.deliveryFees}
            orderMinTotalValue={deliveryArea.orderMinTotalValue}
          />
        </DaysAndHours>
      </Box>
    </Box>
  );
};

export default StoreDeliveryHoursDialog;

import React, { FC, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { stopAddEditSubscription } from 'store/modules/subscriptions/actions';
import { stopOrderEditing } from 'store/modules/orderEditing/actions';
import { GENERIC_DIALOG, orderSelectors } from 'store';
import { useDialog } from 'hooks';
import { useTranslation } from 'react-i18next';
import { IOrderModeIndicator } from './types';
import useStyles from './styles';

const OrderModeIndicator: FC<IOrderModeIndicator> = ({ orderMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useDialog();
  const { t } = useTranslation();

  const activeOrder = useSelector(orderSelectors.orderData);

  const showExitModePopup = useCallback(
    (successCallback) => {
      showDialog({
        dialogType: GENERIC_DIALOG,
        contentProps: {
          title: `dialog.exitModeIndicator.${orderMode}.title`,
          body: `dialog.exitModeIndicator.${orderMode}.body`,
          buttons: [
            {
              text: `dialog.exitModeIndicator.${orderMode}.okButton`,
              variant: 'contained',
              onClick: () => {
                if (successCallback) {
                  successCallback();
                }
                hideDialog();
              },
            },
            {
              text: `dialog.exitModeIndicator.${orderMode}.cancelButton`,
              variant: 'outlined',
              onClick: () => {
                hideDialog();
              },
            },
          ],
        },
      });
    },
    [hideDialog, orderMode, showDialog],
  );

  const resetOrderMode = useCallback(() => {
    showExitModePopup(() => {
      switch (orderMode) {
        case 'addSubscription':
        case 'editSubscription':
          dispatch(stopAddEditSubscription());
          break;
        case 'edit':
          dispatch(stopOrderEditing());
          break;
        default:
      }
    });
  }, [dispatch, orderMode, showExitModePopup]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <Box display="flex">
          <Icon name="icon-alert-circled" classes={{ root: classes.indicatorIcon }} />
          <Typography
            fontSize={14}
            fontWeight="medium"
            color="light"
            component="span"
            className="white-space-no-wrap"
          >
            {t(`orderModeIndicator.${orderMode}`, { id: activeOrder.nonObfuscatedId })}
          </Typography>
        </Box>
        <Box>
          <Button className={classes.exitButton} onClick={resetOrderMode} disableRipple>
            <Typography fontWeight="medium" fontSize={14} color="primary" component="span">
              {'orderModeIndicator.exit'}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderModeIndicator;

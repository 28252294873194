import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { IDeliverySection, TStylesDeliverySection } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesDeliverySection, IDeliverySection & { onClientBehalf: boolean }> =>
    createStyles({
      deliveryIcon: {
        fontSize: '32px',
        marginRight: theme.spacing(1), // 8px
      },
      deliveryWrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '196px',
        cursor: ({ enableRootClick }) => (!enableRootClick ? 'pointer' : 'auto'),
      },
      deliveryAddress: {
        justifyContent: 'flex-start',
        padding: '0',
      },
      buttonRipple: {
        backgroundColor: `${theme.palette.background.paper} !important`,
        opacity: '0.8 !important',
      },
      address: ({ onClientBehalf }) => ({
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: '18px',
        maxWidth: onClientBehalf ? '130px' : '153px',
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      }),
      time: {
        lineHeight: '18px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '153px',
      },
    }),
);

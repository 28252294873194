import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesItemsPromotionDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesItemsPromotionDialog> =>
    createStyles({
      header: {
        backgroundColor: theme.palette.secondary.main,
      },
      headerTop: {
        padding: theme.spacing(50 / 8, 14 / 8, 18 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(32 / 8, 50 / 8, 18 / 8),
        },
      },
      discountIcon: {
        fontSize: '22px',
        marginRight: '6px',
      },
      limitationMainPart: {
        padding: '10px 14px',
        backgroundColor: 'rgb(0,0,0, 0.14)',
        display: 'flex',
        borderRadius: '2px',
        alignItems: 'baseline',
        [theme.breakpoints.up('md')]: {
          padding: '10px 50px',
        },
      },
      limitationTypography: {
        fontSize: '13px',
        lineHeight: '16px',
        color: theme.palette.background.paper,
        opacity: 0.7,
        textAlign: 'left',
      },
      mainPartWrapper: {
        minHeight: 'calc(100vh - 174px)',
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(24 / 8, 14 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(40 / 8, 50 / 8),
          minHeight: 'auto',
        },
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesProductQualityTag } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesProductQualityTag, { height?: string }> =>
    createStyles({
      tagWrapper: ({ height }) => ({
        display: 'inline-flex',
        minWidth: '43px',
        height,
        borderRadius: '4px',
        backgroundColor: theme.palette.grey[50],
        justifyContent: 'center',
      }),
      tagLabel: {
        fontSize: '12px',
        color: theme.palette.secondary.main,
        lineHeight: 1.6,
        padding: theme.spacing(0, 1),
      },
    }),
);

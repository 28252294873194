import { stringify } from 'qs';

import { BaseApi } from 'api/Api';
import {
  TAddCreditCard,
  TDeletePaymentMethod,
  TGetPaymentMethod,
  TInitiateExternalGatewayPaymentPage,
  TResendPaymentByBitSms,
} from './types';

export default class PaymentMethodService {
  static getPaymentMethod: TGetPaymentMethod = async (params, config) => {
    return BaseApi.instance(config).get(
      `/client/client/payments-methods/my-payment-options?${stringify(params)}`,
    );
  };

  static deletePaymentMethod: TDeletePaymentMethod = async (id) => {
    return BaseApi.instance().delete(`/client/client/payments-methods/${id}`);
  };

  static addCreditCard: TAddCreditCard = async (formData, storeId) => {
    return BaseApi.instance().post(`/client/client/payments-methods/place-payment-details`, {
      ...formData,
      storeId,
    });
  };

  static initiateExternalGatewayPaymentPage: TInitiateExternalGatewayPaymentPage = async (
    cartEstimation,
    config,
  ) => {
    return BaseApi.instance(config).post(
      `/client/client/payments-methods/gateway/payment-page/initiate`,
      {
        cartEstimation,
      },
    );
  };

  static resendPaymentByBitSms: TResendPaymentByBitSms = async (orderId, config) => {
    return BaseApi.instance(config).post(`/client/client/orders/my-orders/${orderId}/bit/resend`);
  };
}

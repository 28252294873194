import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesHeaderBigInfo } from './types';
// TODO: need to fix styles for tablet and mobile
export default makeStyles(
  (theme: Theme): StyleRules<TStylesHeaderBigInfo, { displayAddress: boolean }> =>
    createStyles({
      root: {
        boxShadow: 'none',
      },
      logoWrapper: {
        width: '130px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          width: '160px',
          marginRight: theme.spacing(3), // 24px
        },
      },
      logo: {
        borderRadius: '50%',
        width: '100%',
        height: 'auto',
      },
      logoSkeleton: {
        position: 'absolute',
        background: theme.palette.background.paper,
        boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.08);',
        width: '130px',
        height: '130px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          margin: '0',
          width: '160px',
          height: '160px',
        },
      },
      contactItem: {
        display: 'inline-flex',
        [theme.breakpoints.up('md')]: {
          fontStyle: 'inherit',
          marginBottom: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
        },
      },
      contactItemText: {
        fontStyle: 'normal',
        marginLeft: theme.spacing(6 / 8),
      },
      wrapper: ({ displayAddress }) => ({
        display: 'block',
        marginTop: theme.spacing(displayAddress ? 10 / 8 : 4 / 8),
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          marginTop: theme.spacing(-12 / 8),
        },
      }),
      storeName: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          textAlign: 'left',
          fontSize: '30px',
          lineHeight: '34px',
        },
      },
      storeDetailWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(18 / 8),
        [theme.breakpoints.up('md')]: {
          alignItems: 'start',
          marginTop: theme.spacing(0),
        },
      },
      dividerForContentPage: {
        margin: theme.spacing(0, 10 / 8),
        height: '14px',
        width: '1px',
        backgroundColor: theme.palette.background.paper,
      },
    }),
);

import { useWebsiteDetails } from 'hooks';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store';
import { TFooterMenuItem } from 'ui/desktop/Footer';
import { TMenuItem } from 'ui/desktop/UserMenu';
import { TUseDefaultMenuItems } from './types';

const useDefaultMenuItems = (): TUseDefaultMenuItems => {
  const {
    websiteSettings: { enableSubscriptions, isSmallEC },
  } = useWebsiteDetails();

  const onClientBehalf = useSelector(authSelectors.onClientBehalf);

  let defaultMenuItems: TMenuItem[] = [
    {
      id: 1,
      text: 'userMenu.accountDetails',
      icon: 'icon-user-2',
      link: {
        href: '/account/personal-details',
        as: '/account/personal-details',
      },
    },
    {
      id: 2,
      text: 'userMenu.payments',
      icon: 'icon-credit-card-3',
      link: {
        href: '/account/payment-methods/manage',
        as: '/account/payment-methods/manage',
      },
    },
    {
      id: 3,
      text: 'userMenu.mySubscriptions',
      icon: 'icon-member',
      link: {
        href: '/account/subscriptions/manage',
        as: '/account/subscriptions/manage',
      },
    },
    {
      id: 4,
      text: 'userMenu.orderHistory',
      icon: 'icon-history',
      link: {
        href: '/account/orders/history',
        as: '/account/orders/history',
      },
    },
  ];

  let defaultFooterMenuItems: TFooterMenuItem[] = [
    {
      id: 5,
      link: {
        href: '/account/personal-details',
        as: '/account/personal-details',
      },
      text: 'footer.menu.details',
    },
    {
      id: 6,
      link: {
        href: '/account/payment-methods/manage',
        as: '/account/payment-methods/manage',
      },
      text: 'footer.menu.paymentMethod',
    },
    {
      id: 7,
      link: {
        href: '/account/subscriptions/manage',
        as: '/account/subscriptions/manage',
      },
      text: 'footer.menu.subscription',
    },
    {
      id: 8,
      link: {
        href: '/account/orders/history',
        as: '/account/orders/history',
      },
      text: 'footer.menu.orderHistory',
    },
  ];

  if (!enableSubscriptions || isSmallEC) {
    defaultMenuItems = defaultMenuItems.filter((menuItem) => menuItem.id !== 3);
    defaultFooterMenuItems = defaultFooterMenuItems.filter(
      (menuItem) => !menuItem.text.includes('subscription'),
    );
  }

  if (onClientBehalf) {
    defaultMenuItems = defaultMenuItems.filter(
      (menuItem) => menuItem.id !== 1 && menuItem.id !== 2,
    );
    defaultFooterMenuItems = defaultFooterMenuItems.filter(
      (menuItem) => !menuItem.text.includes('payment-methods' || 'personal-details'),
    );
  }

  return {
    defaultFooterMenuItems,
    defaultMenuItems,
  };
};

export default useDefaultMenuItems;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, PutEffect, select, SelectEffect, takeEvery } from '@redux-saga/core/effects';

import {
  CLIENT_COUPONS_UPDATE,
  GET_STATIC_PROMOTIONS,
  IGetStaticPromotions,
  IPromotion,
  IRecalculateOrderDiscounts,
  IShowNotify,
  IUpdateStaticPromotions,
  LOG_OUT_SUCCESS,
  notifyActions,
  ORDER_DISCOUNTS_RECALCULATE,
  ORDER_UPDATE,
  SET_ORDER_ITEMS_SUCCESS,
  STORE_COUPON_UPDATE,
  TOrderDiscount,
  TOrderReducerState,
  TPrepareToPlaceOrderReducerState,
  UPDATE_DETAILS_AT_STORE_LEVEL,
} from 'store';
import { orderData } from 'store/modules/order/selectors';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { orderDiscountsUpdate, updateStaticPromotions } from 'store/modules/orderDiscounts/actions';
import {
  getClientCoupons,
  getOrderDiscounts,
  getStoreCoupon,
  staticPromotionsData,
} from 'store/modules/orderDiscounts/selectors';
import { getPrepareToPlaceOrder } from 'store/modules/prepareToPlaceOrder/selectors';

import { promotionsData } from 'store/modules/promotions/selectors';
import { storeProductsData } from 'store/modules/storeProduct/selectors';
import { getDetailsAtStoreLevel } from 'store/modules/user/selectors';
import { getIdsAndByIds } from 'utils';
import { calculateOrderDiscounts } from 'utils/helpers/promotions';
import { validateActiveItemsPromotionDiscount } from 'utils/helpers/promotions/validators';
import { IOrderDiscountsUpdate } from './types';

function* recalculateOrderDiscounts({
  payload,
}: IRecalculateOrderDiscounts): Generator<
  SelectEffect | PutEffect<IOrderDiscountsUpdate> | PutEffect<IShowNotify>,
  void,
  any
> {
  const prepareToPlaceOrder: TPrepareToPlaceOrderReducerState = yield select(
    getPrepareToPlaceOrder,
  );
  const order: TOrderReducerState = yield select(orderData);
  const orderDetails = yield select(getOrderDetails);
  const { storeProductById, outOfStockStoreProductById } = yield select(storeProductsData);
  const { promotionsById } = yield select(promotionsData);
  const staticPromotions = yield select(staticPromotionsData);
  const coupons = yield select(getClientCoupons);
  const storeCoupon = yield select(getStoreCoupon);
  const detailsAtStoreLevel = yield select(getDetailsAtStoreLevel);
  const oldOrderDiscounts: TOrderDiscount[] = yield select(getOrderDiscounts);

  const allPromotionsById = { ...promotionsById, ...staticPromotions.staticPromotionsById };

  const orderDiscounts = calculateOrderDiscounts(
    order,
    orderDetails,
    prepareToPlaceOrder.serviceAreaUsageFees,
    coupons,
    storeCoupon,
    storeProductById,
    outOfStockStoreProductById,
    allPromotionsById,
    detailsAtStoreLevel,
  );

  if (payload?.showPromotionWasAppliedSnackbar) {
    const oldActiveOrderDiscounts = validateActiveItemsPromotionDiscount(oldOrderDiscounts);
    const currentActiveOrderDiscounts = validateActiveItemsPromotionDiscount(orderDiscounts);

    if (currentActiveOrderDiscounts.length > oldActiveOrderDiscounts.length) {
      yield put(
        notifyActions.showNotification({
          message: 'snackbar.promotionAppliedSuccessfully',
          type: 'success',
        }),
      );
    }
  }

  yield put(orderDiscountsUpdate(orderDiscounts));
}

function* getStaticPromotionsSaga({
  payload,
}: IGetStaticPromotions): Generator<SelectEffect | PutEffect<IUpdateStaticPromotions>, void, any> {
  const { staticPromotions } = payload;
  const { objById, arrayIds } = getIdsAndByIds<IPromotion, number>(
    staticPromotions,
    'nonObfuscatedId',
  );

  yield put(updateStaticPromotions(objById, arrayIds));
}

function* rootOrderDiscountsSaga(): Generator {
  yield takeEvery(
    [
      ORDER_DISCOUNTS_RECALCULATE,
      LOG_OUT_SUCCESS,
      ORDER_UPDATE,
      SET_ORDER_ITEMS_SUCCESS,
      CLIENT_COUPONS_UPDATE,
      STORE_COUPON_UPDATE,
      UPDATE_DETAILS_AT_STORE_LEVEL,
    ],
    recalculateOrderDiscounts,
  );
  yield takeEvery(GET_STATIC_PROMOTIONS, getStaticPromotionsSaga);
}

export default rootOrderDiscountsSaga;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesFreeProductCard } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesFreeProductCard, { showPricePerUnit: boolean; expandedLayout: boolean }> =>
    createStyles({
      rootContainer: {
        display: 'block',
        borderRadius: '8px',
        height: '100px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflow: 'visible',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
          marginRight: 'auto',
        },
      },
      tag: {
        position: 'absolute',
        left: 0,
        top: 6,
      },
      mainContent: {
        padding: theme.spacing(18 / 8, 10 / 8, 18 / 8),
        display: 'flex',
        justifyContent: ({ showPricePerUnit }) =>
          showPricePerUnit ? 'flex-start' : 'space-between',
        alignItems: 'center',
        height: '100px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: ({ showPricePerUnit, expandedLayout }) => {
            if (expandedLayout) {
              return theme.spacing(18 / 8, 22 / 8, 18 / 8, 37 / 8);
            }
            if (showPricePerUnit) {
              return theme.spacing(0, 20 / 8, 0, 20 / 8);
            }
            return theme.spacing(18 / 8, 10 / 8, 18 / 8);
          },
        },
      },
      imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(6 / 8),
        marginLeft: ({ showPricePerUnit }) => theme.spacing(showPricePerUnit ? 12 / 8 : 0),
      },
      cardImage: {
        width: ({ expandedLayout }) => (expandedLayout ? '74px' : '70px'),
        height: ({ expandedLayout }) => (expandedLayout ? '54.2px' : '52px'),
        [theme.breakpoints.up('md')]: {
          height: ({ expandedLayout, showPricePerUnit }) => {
            if (expandedLayout) {
              return '52px';
            }
            if (showPricePerUnit) {
              return '60px';
            }
            return '52px';
          },
          width: ({ expandedLayout, showPricePerUnit }) => {
            if (expandedLayout) {
              return '90px';
            }
            if (showPricePerUnit) {
              return '86px';
            }
            return '70px';
          },
        },
      },
      description: ({ showPricePerUnit }) => ({
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        marginLeft: 0,
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(!showPricePerUnit ? 5 / 8 : 0),
        },
      }),
      descriptionText: ({ showPricePerUnit, expandedLayout }) => ({
        fontSize: '14px',
        display: '-webkit-box',
        lineHeight: '16px',
        '-webkitLineClamp': showPricePerUnit ? 2 : 3,
        '-webkitBoxOrient': showPricePerUnit ? 'horizontal' : 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '64px',
        maxWidth: '32vw',
        marginBottom: theme.spacing(!showPricePerUnit && !expandedLayout ? 5 / 8 : 0),
        [theme.breakpoints.up('md')]: {
          maxWidth: showPricePerUnit || expandedLayout ? '277px' : '80px',
          fontSize: !showPricePerUnit ? '14px' : '16px',
        },
      }),
      price: ({ showPricePerUnit, expandedLayout }) => ({
        minWidth: expandedLayout && !showPricePerUnit ? '70px' : '50px',
        display: 'flex',
        flexGrow: () => {
          if (showPricePerUnit) {
            if (expandedLayout) {
              return 0;
            }
            return 1;
          }
          return 0;
        },
        justifyContent: 'end',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          minWidth: () => {
            if (!expandedLayout && showPricePerUnit) {
              return '104px';
            }
          },
          width: () => {
            if (expandedLayout && showPricePerUnit) {
              return '95px';
            }
            return '65px';
          },
          justifyContent: () => {
            if (showPricePerUnit && !expandedLayout) return 'center';
            return 'end';
          },
          marginLeft: !showPricePerUnit && !expandedLayout ? theme.spacing(12 / 8) : 0,
        },
      }),
      itemPrice: ({ expandedLayout, showPricePerUnit }) => ({
        width: expandedLayout && showPricePerUnit ? '140px' : '130px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }),
      productDescriptionWrapper: ({ expandedLayout, showPricePerUnit }) => ({
        display: 'flex',
        flexGrow: expandedLayout || showPricePerUnit ? 0 : 1,
        width: 'auto',
        [theme.breakpoints.up('md')]: {
          flexGrow: showPricePerUnit ? 1 : 0,
          maxWidth: !expandedLayout && showPricePerUnit ? '360px' : 'auto',
        },
      }),
      quantityAndSellingUnitWrapper: ({ expandedLayout, showPricePerUnit }) => ({
        alignItems: expandedLayout && !showPricePerUnit ? 'center' : 'baseline',
        display: 'flex',
        flexGrow: 1,
        flexDirection: expandedLayout ? 'column' : 'unset',
        minWidth: '40px',
        width: 'auto',
        marginRight: expandedLayout && !showPricePerUnit ? theme.spacing(4 / 8) : 0,
        [theme.breakpoints.up('md')]: {
          width: () => {
            if (expandedLayout) return '90px';
            return '52px';
          },
          minWidth: () => (!expandedLayout && showPricePerUnit ? '104px' : 'initial'),
          alignItems: 'center !important',
          alignSelf: 'center',
          flexDirection: 'column  !important' as 'column',
        },
      }),
      sellingUnitWrapper: ({ expandedLayout, showPricePerUnit }) => ({
        marginLeft: theme.spacing(!expandedLayout && !showPricePerUnit ? 4 / 8 : 0),
        [theme.breakpoints.up('md')]: {
          marginLeft: 0,
        },
      }),
    }),
);

import { CONTENT_PAGE_PREVIEW_UPDATE, UI_HOME_PAGE_STYLES_UPDATE } from './constants';

import { TUiStylesReducer } from './types';

import { initialUiStylesState } from './initialState';

const uiStylesReducer: TUiStylesReducer = (state = initialUiStylesState, action) => {
  switch (action.type) {
    case UI_HOME_PAGE_STYLES_UPDATE:
      return {
        ...state,
        homePage: {
          ...state.homePage,
          ...action.payload,
        },
      };
    case CONTENT_PAGE_PREVIEW_UPDATE:
      return {
        ...state,
        isActiveContentPagePreviewMode: action.payload,
      };
    default:
      return state;
  }
};

export { uiStylesReducer };

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSkeletonSimilarProductsSlider } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSkeletonSimilarProductsSlider> =>
    createStyles({
      wrapper: {
        height: 'auto',
        width: '100%',
        marginTop: theme.spacing(26 / 8),
        marginBottom: theme.spacing(46 / 8),
        [theme.breakpoints.up('md')]: {
          paddingBottom: theme.spacing(24 / 8),
        },
      },
      image: {
        width: '200px',
        height: '280px',
        borderRadius: '8px',
        margin: theme.spacing(0, 10 / 8),
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(22 / 8),
          width: '218px',
          height: '300px',
        },
      },
      mainPart: {
        display: 'flex',
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSkeletonDeliveryOrSelfPickupAvailableHours } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSkeletonDeliveryOrSelfPickupAvailableHours> =>
    createStyles({
      mobileWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      skeletonLoader: {
        width: 'auto',
        height: '36px',
        borderRadius: '4px',
        [theme.breakpoints.up('md')]: {
          width: '320px',
          height: '40px',
        },
      },
      skeletonButtonLoader: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block',
          width: '130px',
          height: '40px',
          borderRadius: '4px',
        },
      },
      divider: {
        backgroundColor: theme.palette.grey[200],
        marginTop: '24px',
      },
    }),
);

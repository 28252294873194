import { Box, CardMedia } from '@material-ui/core';
import { IContentCardsSlider } from 'components/common/HomePageContent/components/common/ContentCardsSlider/types';
import HomePageSlider from 'components/common/HomePageContent/components/desktop/HomePageSlider';
import MobileHomePageSlider from 'components/common/HomePageContent/components/mobile/MobileHomePageSlider';
import { useMobile } from 'hooks';
import React, { FC, useContext, useMemo } from 'react';
import Icon from 'ui/common/icons/Icon';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';
import { prepareImageUrl } from 'utils/helpers/prepareImageUrl';
import useStyles from './styles';

const ContentCardsSlider: FC<IContentCardsSlider> = ({ content, title, linkLabel, linkHref }) => {
  const { isMobile } = useMobile();

  const mainLayoutContext = useContext(MainLayoutContext);

  const classes = useStyles({ isOpenedTopCart: mainLayoutContext.isOpenedTopCart });

  const contentCardToDisplay = useMemo(() => {
    return content.map((contentCard, index) => {
      return (
        <Box className={classes.contentCardWrapper} key={index}>
          <CardMedia
            src={prepareImageUrl(contentCard.value.value)}
            component="img"
            className={classes.imageWrapper}
            alt={contentCard.value.altText}
          />
          <Box className={classes.cardBottomSection}>
            <Box>
              <Typography className={classes.contentCardLabel} color="textSecondary">
                {contentCard.title}
              </Typography>
              <Typography className={classes.contentCardDescription} color="textSecondary">
                {contentCard.description}
              </Typography>
            </Box>
            {contentCard.linkLabel && contentCard.linkUrl && (
              <Box>
                <Link href={contentCard.linkUrl} targetBlank className={classes.contentCardLink}>
                  <Typography className={classes.linkLabel} isTranslate={false}>
                    {contentCard.linkLabel}
                  </Typography>
                  <Icon
                    name="icon-arrow-left"
                    color="primary"
                    classes={{ root: classes.iconArrow }}
                  />
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      );
    }, []);
  }, [
    classes.cardBottomSection,
    classes.contentCardDescription,
    classes.contentCardLabel,
    classes.contentCardLink,
    classes.contentCardWrapper,
    classes.iconArrow,
    classes.imageWrapper,
    classes.linkLabel,
    content,
  ]);

  return (
    <>
      {isMobile ? (
        <>
          <MobileHomePageSlider
            slides={contentCardToDisplay}
            title={title}
            linkLabel={linkLabel}
            linkHref={linkHref}
          />
        </>
      ) : (
        <Box className={classes.contentCardsSlider}>
          {!!content?.length && (
            <Box className={classes.contentCardsSliderWrapper}>
              <HomePageSlider
                slides={contentCardToDisplay}
                title={title}
                linkLabel={linkLabel}
                linkHref={linkHref}
                amountOfSlidesToShow={2}
                amountOfSlidesPerGroup={2}
                spaceBetweenSlides={22}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ContentCardsSlider;

import { IPromotion } from 'store/modules/promotions';
import { TOrderDetailsReducerState, TUserReducerState } from 'store';
import {
  validateOrderType,
  validatePromotionRelevantWeekday,
} from 'utils/helpers/promotions/validators';

export function forNewCustomer(
  promotion: IPromotion,
  orderDetails: TOrderDetailsReducerState,
  detailsAtStoreLevel?: TUserReducerState['detailsAtStoreLevel'],
): boolean {
  const { relevantWeekdays } = promotion.parameters;

  if (
    relevantWeekdays &&
    !validatePromotionRelevantWeekday(relevantWeekdays, orderDetails.preferredDay)
  ) {
    return false;
  }

  if (!validateOrderType(promotion, orderDetails.orderType)) {
    return false;
  }

  return !!detailsAtStoreLevel && !detailsAtStoreLevel.ordersCount;
}

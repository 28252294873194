import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import useStyles from './styles';
import { IAddressTypeButton } from './types';

const AddressTypeButton: FC<IAddressTypeButton> = ({ isActive, location, onClick }) => {
  const classes = useStyles();

  return (
    <IconButton
      classes={{
        root: classNames(classes.iconButton, { [classes.iconButtonActive]: isActive }),
        label: classes.iconButtonLabel,
      }}
      onClick={() => onClick(location.id)}
    >
      <Icon
        name={location.icon}
        color={isActive ? 'action' : 'primary'}
        classes={{ root: classes.iconButtonIcon }}
      />
      <Typography
        className={classNames(classes.iconButtonDescription, {
          [classes.iconButtonDescriptionActive]: isActive,
        })}
        component="span"
      >
        {location.label}
      </Typography>
    </IconButton>
  );
};

export default AddressTypeButton;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUseFooterAboutSection } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseFooterAboutSection> =>
    createStyles({
      label: {
        fontSize: '22px',
        letterSpacing: '-0.2px',
      },
      logo: {
        borderRadius: '50%',
        width: '100%',
        height: 'auto',
      },
      logoWrapper: {
        width: '86px',
        margin: '0 auto',
      },
      mobileLogo: {
        borderRadius: '50%',
        width: '86px',
        height: '86px',
      },
      description: {
        display: '-webkit-box',
        overflow: 'hidden',
        maxHeight: theme.spacing(100 / 8),
        '-webkitLineClamp': 4,
        '-webkitBoxOrient': 'vertical',
        lineHeight: 1.29,
        opacity: 0.8,
        marginTop: theme.spacing(10 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(6 / 8),
        },
      },
      websiteDetails: {
        lineHeight: 1.29,
        opacity: 0.8,
        fontSize: theme.spacing(11 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(12 / 8),
        },
      },
    }),
);

import React, { FC, useCallback, useMemo } from 'react';
import { Box, Button, CardMedia } from '@material-ui/core';

import {
  useDialog,
  useFooterAboutSection,
  useFooterAllRights,
  useFooterTerms,
  usePoweredBy,
} from 'hooks';
import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';
import Icon from 'ui/common/icons/Icon';

import Container from 'ui/common/Container';

import { useSelector } from 'react-redux';
import { authSelectors } from 'store/modules/auth';
import { LOGIN_DIALOG } from 'store/modules/dialog';
import useAboutPageContent from 'hooks/useAboutPageContent';
import { useTranslation } from 'react-i18next';
import { useContentPages } from 'hooks/useContentPages';
import { filterContactItems } from 'utils';
import useStyles from './styles';

import { IFooter, TSocialAndContactItem } from './types';

const Footer: FC<IFooter> = ({ variant = 'full', ...rest }) => {
  const { menuItems, termsItems, contactItems, description, websiteDetails, imagesUrl = {} } = rest;

  const { iconUrl, upImageUrl, bottomImageUrl, companyPoweredLogoUrl } = imagesUrl;

  const classes = useStyles({
    variant,
    ...rest,
  });

  const { renderDescription, renderIcon, renderLabel } = useFooterAboutSection();
  const { renderTerms } = useFooterTerms(termsItems);
  const { allRightsSection } = useFooterAllRights();
  const { renderPoweredBy } = usePoweredBy();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const { aboutPage } = useAboutPageContent();

  const { createContentPagesList } = useContentPages();

  const { showDialog } = useDialog();

  const { t } = useTranslation();

  const showLoginDialog = useCallback(() => {
    showDialog({
      dialogType: LOGIN_DIALOG,
    });
  }, [showDialog]);

  const termsSection = useMemo(() => {
    return renderTerms();
  }, [renderTerms]);

  const filteredSocialItems = useMemo<TSocialAndContactItem[]>(() => {
    return (
      contactItems?.filter(({ label, iconName }) => !label || iconName.includes('whatsapp')) || []
    );
  }, [contactItems]);

  const filteredContactItems = useMemo(() => {
    return contactItems ? filterContactItems(contactItems) : [];
  }, [contactItems]);

  const poweredBySection = useMemo(() => {
    return renderPoweredBy(companyPoweredLogoUrl);
  }, [renderPoweredBy, companyPoweredLogoUrl]);

  const icons = useMemo(() => {
    return (
      <>
        <CardMedia classes={{ root: classes.upImage }} component="img" image={upImageUrl} alt="" />
        <CardMedia
          classes={{ root: classes.bottomImage }}
          component="img"
          image={bottomImageUrl}
          alt=""
        />
      </>
    );
  }, [classes, bottomImageUrl, upImageUrl]);

  const socialItemsContent = useMemo(() => {
    return (
      <Box display="flex">
        {filteredSocialItems
          ?.filter(({ link }) => !!link.href)
          .map(({ iconName, id, link }) => {
            return (
              <Box className={classes.socialLinkWrapper} key={id}>
                <Link className={classes.socialLinkItem} targetBlank {...link}>
                  <Icon name={iconName} color="action" classes={{ root: classes.socialItemIcon }} />
                </Link>
              </Box>
            );
          })}
      </Box>
    );
  }, [classes, filteredSocialItems]);

  const contactItemsContent = useMemo(() => {
    if (!contactItems) return;

    return (
      <>
        {filteredContactItems
          // if whatsapp item exist in socialItems then filter out whatsapp item from contactItems
          ?.filter(({ iconName }) => !iconName.includes('whatsapp'))
          .map(({ label, id, iconName, link, ariaLabel, component }) => {
            return (
              <Box key={id} component={component || 'div'} fontStyle="inherit">
                <Link className={classes.contactLinkItem} {...link} targetBlank>
                  <Icon
                    name={iconName}
                    color="primary"
                    fontSize="small"
                    aria-hidden={false}
                    aria-label={t(ariaLabel as string)}
                  />
                  <Typography fontSize={14} color="light" className={classes.contactItem}>
                    {label}
                  </Typography>
                </Link>
              </Box>
            );
          })}
        {!!filteredSocialItems && <Box>{socialItemsContent}</Box>}
      </>
    );
  }, [
    classes.contactItem,
    classes.contactLinkItem,
    contactItems,
    filteredContactItems,
    filteredSocialItems,
    socialItemsContent,
    t,
  ]);

  const renderSiteMenuItems = useMemo(() => {
    if (!createContentPagesList().length) {
      return null;
    }

    return (
      <>
        {createContentPagesList(true, false).map((contentPage) => {
          return (
            <Link
              key={contentPage.id}
              href={`/${contentPage.type}${
                contentPage.id
                  ? contentPage.nonObfuscatedId && `/${contentPage.nonObfuscatedId}`
                  : ''
              }`}
            >
              <Typography fontSize={14} color="light" className={classes.menuItem}>
                {contentPage.name}
              </Typography>
            </Link>
          );
        })}
      </>
    );
  }, [classes.menuItem, createContentPagesList]);

  // TODO - when not logged in we need to show the login flow + have a callback to go to the relevant page after login.
  const renderUserMenuItems = useMemo(() => {
    return (
      <>
        {menuItems?.map(({ id, text, link }) => {
          if (!isLoggedIn) {
            return (
              <Box key={id} className={classes.showDialog}>
                <Button onClick={showLoginDialog} className={classes.buttonMenuItem}>
                  <Typography fontSize={14} color="light" className={classes.menuItem}>
                    {text}
                  </Typography>
                </Button>
              </Box>
            );
          }

          return (
            <Link key={id} {...link} targetBlank>
              <Typography fontSize={14} color="light" className={classes.menuItem}>
                {text}
              </Typography>
            </Link>
          );
        })}
      </>
    );
  }, [
    classes.buttonMenuItem,
    classes.menuItem,
    classes.showDialog,
    isLoggedIn,
    menuItems,
    showLoginDialog,
  ]);

  const renderFullFooterVariant = useMemo(() => {
    return (
      <Box className={classes.fullWrapper}>
        <Container maxContainerWidth>
          <Box className={classes.footerContent}>
            {icons}
            <Box className={classes.labelSection}>
              {iconUrl && renderIcon(iconUrl)}
              <Box className={classes.labelContent}>
                {renderLabel(websiteDetails.name)}
                {renderDescription(description)}
              </Box>
            </Box>
            <Box>{contactItemsContent}</Box>
            {!!aboutPage && <Box className={classes.menuSection}>{renderSiteMenuItems}</Box>}
            <Box className={classes.menuSection}>{renderUserMenuItems}</Box>
          </Box>
        </Container>
      </Box>
    );
  }, [
    classes.fullWrapper,
    classes.footerContent,
    classes.labelSection,
    classes.labelContent,
    classes.menuSection,
    icons,
    iconUrl,
    renderIcon,
    renderLabel,
    websiteDetails.name,
    description,
    renderDescription,
    contactItemsContent,
    aboutPage,
    renderSiteMenuItems,
    renderUserMenuItems,
  ]);

  return (
    <Box className={classes.wrapper}>
      {variant === 'full' && renderFullFooterVariant}

      <Box className={classes.miniWrapper}>
        <Container maxContainerWidth>
          <Box className={classes.footerContent}>
            <Box className={classes.allRightsSection}>{allRightsSection}</Box>
            <Box className={classes.termsSection}>{termsSection}</Box>
            <Box className={classes.logoSection}>{poweredBySection}</Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;

import { Box, useTheme } from '@material-ui/core';
import { ITestimonialsSlider } from 'components/common/HomePageContent/components/common/TestimonialsSlider/types';
import HomePageSlider from 'components/common/HomePageContent/components/desktop/HomePageSlider';
import { useMobile } from 'hooks';
import React, { FC, useContext, useMemo } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import Icon from 'ui/common/icons/Icon';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import Typography from 'ui/common/Typography';
import useStyles from './styles';

const TestimonialsSlider: FC<ITestimonialsSlider> = ({ testimonials, title }) => {
  const { isMobile } = useMobile();
  const theme = useTheme();

  const mainLayoutContext = useContext(MainLayoutContext);

  const classes = useStyles({
    isOpenedTopCart: mainLayoutContext.isOpenedTopCart,
    showOpacity: testimonials.length > 3,
  });

  const testimonialToDisplay = useMemo(() => {
    const array = testimonials;

    if (isMobile && testimonials.length === 3) {
      // because of the bug of slider, when we have loop
      // amount of sliders supposed to be more than amount of slides that we show
      array.push(testimonials[testimonials.length - 1]);
    }

    return array.map((testimonial, index) => {
      return (
        <Box className={classes.testimonialCardWrapper} key={index}>
          <Box>
            <Icon name="icon-quoets" classes={{ root: classes.quoets }} />
            <Typography className={classes.testimonialDescription}>
              {testimonial.bodyText}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.testimonialName}>{testimonial.details}</Typography>
            <Typography className={classes.testimonialCity}>{testimonial.city}</Typography>
          </Box>
        </Box>
      );
    }, []);
  }, [
    classes.quoets,
    classes.testimonialCardWrapper,
    classes.testimonialCity,
    classes.testimonialDescription,
    classes.testimonialName,
    isMobile,
    testimonials,
  ]);

  const mobilePagination = useMemo<PaginationOptions>(() => {
    return {
      clickable: true,
      renderBullet(_, className) {
        return `<a class="${className}"></a>`;
      },
      bulletClass: classes.pagination,
      bulletActiveClass: classes.activeBulletPagination,
      clickableClass: classes.clickablePagination,
    };
  }, [classes.activeBulletPagination, classes.clickablePagination, classes.pagination]);

  return isMobile ? (
    <>
      <Typography className={classes.title} variant="body1" isTranslate={false}>
        {title}
      </Typography>
      <Box className={classes.swiperWrapper}>
        <Swiper
          modules={[Pagination]}
          spaceBetween={12}
          centeredSlides
          loop
          slidesPerView="auto"
          dir={theme.direction}
          key={theme.direction}
          pagination={mobilePagination}
        >
          {testimonialToDisplay.map((slide, index) => (
            <SwiperSlide style={{ width: '300px' }} key={index}>
              {slide}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  ) : (
    <Box className={classes.testimonialsSlider}>
      {!!testimonials?.length && (
        <Box className={classes.testimonialsWrapper}>
          <HomePageSlider
            slides={testimonialToDisplay}
            title={title}
            amountOfSlidesToShow={3}
            amountOfSlidesPerGroup={1}
            spaceBetweenSlides={23}
          />
        </Box>
      )}
    </Box>
  );
};

export default TestimonialsSlider;

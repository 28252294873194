import { Box, Button, CardMedia, Divider } from '@material-ui/core';
import DeliveryFeesAndMinimumOrder from 'components/common/checkout/DeliveryFeesAndMinimumOrder';

import DaysAndHours from 'components/common/DaysAndHours';

import { useDialog, useMobile, useSelfPickupAndDeliveryService, useWebsiteDetails } from 'hooks';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LOCATION_DIALOG, orderDetailsActions, shoppingFlowActions } from 'store';
import { checkAndFetchBranchCatalogIfNecessary } from 'store/modules/catalog/actions';
import CTAButton from 'ui/common/buttons/CTAButton';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import { weekdayAndOpenHoursMap } from 'utils';
import { deliveryOrSelfPickupBodyText } from './helpers';

import useStyles from './styles';

import { IDeliveringToAddressDialog } from './types';

const DeliveringToAddressDialog: FC<IDeliveringToAddressDialog> = ({
  deliveryAreasCount,
  city,
  performsDelivery,
  performsSelfPickup,
  deliveryArea,
  selfPickupAreas,
  cityFromAutocomplete,
}) => {
  const { isMobile } = useMobile();

  const { t } = useTranslation();

  const websiteDetails = useWebsiteDetails();

  const dispatch = useDispatch();

  const { showDialog, hideDialog } = useDialog();

  const { prepareSelfPickupAreasAndShowDialog } = useSelfPickupAndDeliveryService();

  const [showDeliverySchedule, setShowDeliverySchedule] = useState(false);

  const classes = useStyles({ showDeliverySchedule });

  const daysAndHoursTitle = useMemo(() => {
    if (!deliveryArea) return;
    return (
      <Box mb={16 / 8}>
        <Typography variant="body1" fontSize={16} color="textSecondary" fontWeight="medium">
          {t('dialog.delivery.storeHours.title', {
            areaName:
              (deliveryAreasCount && deliveryAreasCount > 1) || deliveryArea.limited
                ? deliveryArea.name
                : deliveryArea.cityName,
          })}
        </Typography>
      </Box>
    );
  }, [deliveryArea, deliveryAreasCount, t]);

  const dayAndHoursAndDeliveryFees = useMemo(() => {
    if (deliveryArea && deliveryArea.serviceAreaHoursJson) {
      const storeOpenHours = weekdayAndOpenHoursMap(JSON.parse(deliveryArea.serviceAreaHoursJson));

      return (
        <DaysAndHours storeOpenHours={storeOpenHours} title={daysAndHoursTitle} hideClosedDays>
          {deliveryArea && (
            <DeliveryFeesAndMinimumOrder
              deliveryFees={deliveryArea.deliveryFees}
              orderMinTotalValue={deliveryArea.orderMinTotalValue}
            />
          )}
        </DaysAndHours>
      );
    }
  }, [daysAndHoursTitle, deliveryArea]);

  const showLocationDialog = useCallback(() => {
    showDialog({
      dialogType: LOCATION_DIALOG,
    });
  }, [showDialog]);

  const getStoreServiceAreas = useCallback(() => {
    if (selfPickupAreas) {
      prepareSelfPickupAreasAndShowDialog(selfPickupAreas);
    }
  }, [prepareSelfPickupAreasAndShowDialog, selfPickupAreas]);

  const buttons = useMemo(() => {
    if (performsDelivery && performsSelfPickup) {
      return (
        <Box>
          <CTAButton
            onClick={() => {
              if (city) {
                dispatch(orderDetailsActions.openDeliverySelfPickupRequest('delivery'));

                dispatch(
                  shoppingFlowActions.updateKnownAddressAndSubCatalog(
                    cityFromAutocomplete || city.name,
                    deliveryArea?.subCatalog,
                  ),
                );

                dispatch(checkAndFetchBranchCatalogIfNecessary());
              }
              hideDialog();
            }}
            className={classes.rootButton}
          >
            <Typography fontWeight="medium">
              {'dialog.deliveryToClientAddress.performDeliveryYesButton'}
            </Typography>
          </CTAButton>

          <CTAButton
            onClick={getStoreServiceAreas}
            className={classes.outlinedRootButton}
            variant="outlined"
          >
            <Typography fontWeight="medium">
              {'dialog.deliveryToClientAddress.performSelfPickupYesButton'}
            </Typography>
          </CTAButton>
        </Box>
      );
    }

    if (performsDelivery) {
      return (
        <CTAButton
          onClick={() => {
            if (city) {
              dispatch(
                shoppingFlowActions.updateKnownAddressAndSubCatalog(
                  cityFromAutocomplete || city.name,
                  deliveryArea?.subCatalog,
                ),
              );

              dispatch(checkAndFetchBranchCatalogIfNecessary());
            }
            hideDialog();
          }}
          className={classes.rootButton}
        >
          <Typography fontWeight="medium">
            {'dialog.deliveryToClientAddress.performDeliveryYesButton'}
          </Typography>
        </CTAButton>
      );
    }

    if (performsSelfPickup) {
      return (
        <>
          <CTAButton onClick={getStoreServiceAreas} className={classes.rootButton}>
            <Typography fontWeight="medium">
              {'dialog.deliveryToClientAddress.performSelfPickupYesButton'}
            </Typography>
          </CTAButton>
          <CTAButton
            onClick={showLocationDialog}
            className={classes.outlinedRootButton}
            variant="outlined"
          >
            <Typography fontWeight="medium">
              {'dialog.deliveryToClientAddress.doesntPerformDeliveryChangeAddressButton'}
            </Typography>
          </CTAButton>
        </>
      );
    }

    return (
      <>
        <CTAButton
          onClick={() => {
            showDialog({
              dialogType: LOCATION_DIALOG,
            });
          }}
          className={classes.rootButton}
        >
          <Typography fontWeight="medium">
            {'dialog.deliveryToClientAddress.doesntPerformDeliveryChangeAddressButton'}
          </Typography>
        </CTAButton>

        <CTAButton onClick={hideDialog} className={classes.outlinedRootButton} variant="outlined">
          <Typography fontWeight="medium">
            {'dialog.deliveryToClientAddress.doesntPerformDeliveryChangeCloseButton'}
          </Typography>
        </CTAButton>
      </>
    );
  }, [
    city,
    cityFromAutocomplete,
    classes.outlinedRootButton,
    classes.rootButton,
    deliveryArea?.subCatalog,
    dispatch,
    getStoreServiceAreas,
    hideDialog,
    performsDelivery,
    performsSelfPickup,
    showDialog,
    showLocationDialog,
  ]);

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={
            performsDelivery
              ? websiteDetails.theme.images.searchSuccess
              : websiteDetails.theme.images.searchFailure
          }
          component="img"
          className={classes.image}
          alt={performsDelivery ? t('dialog.searchSucceeded') : t('dialog.searchFailed')}
        />
      </Box>
      <Box className={classes.mainWrapper}>
        <Typography variant="h1" color="secondary" className={classes.title}>
          {performsDelivery
            ? t('dialog.deliveryToClientAddress.performDeliveryTitle')
            : t('dialog.deliveryToClientAddress.doesntPerformDeliveryTitle')}
        </Typography>
        <Box mt={isMobile ? 1 : 10 / 8}>
          <Typography variant="body1" fontSize={16} color="grey" align="center">
            <Box>
              <Typography fontSize={16} variant="body1" isTranslate={false}>
                {t(deliveryOrSelfPickupBodyText(performsDelivery, performsSelfPickup), {
                  city: cityFromAutocomplete || city.name,
                })}
              </Typography>
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box>{buttons}</Box>
      {deliveryArea && (
        <Button
          className={classes.showDeliveryScheduleButton}
          disableTouchRipple
          onClick={() => setShowDeliverySchedule((prevState) => !prevState)}
        >
          <Typography className={classes.deliveryHoursAndMoreDetails}>
            {'dialog.location.deliveryHoursAndMoreDetails'}
          </Typography>
          <Icon name="icon-arrow-down" classes={{ root: classes.arrowIcon }} color="primary" />
        </Button>
      )}
      {showDeliverySchedule && (
        <Box className={classes.bodyWrapper}>
          <Divider classes={{ root: classes.rootDivider }} />
          {dayAndHoursAndDeliveryFees}
        </Box>
      )}
    </Box>
  );
};

export default DeliveringToAddressDialog;

import React, { FC } from 'react';

import { Box } from '@material-ui/core';

import { IFixedButton } from './types';

const FixedButton: FC<IFixedButton> = ({ boxProps, children, isHide }) => {
  if (isHide) return null;

  return (
    <Box
      width="100%"
      bgcolor="grey.50"
      boxShadow="0 -2px 7px 0 rgba(0, 0, 0, 0.1)"
      zIndex={100}
      position="fixed"
      bottom={0}
      left={0}
      height={74}
      display="flex"
      alignItems="center"
      px={1.75} // 14px
      justifyContent="center"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default FixedButton;

import React, { FC } from 'react';
import { IAvatar } from 'components/common/Avatar/types';
import { CardMedia, Avatar as MuiAvatar } from '@material-ui/core';
import { useWebsiteDetails } from 'hooks';

const Avatar: FC<IAvatar> = ({ ...rest }) => {
  const websiteDetails = useWebsiteDetails();

  return (
    <MuiAvatar {...rest}>
      <CardMedia
        alt={websiteDetails.name}
        component="img"
        image="assets/images/store-logo-on-error.svg"
      />
    </MuiAvatar>
  );
};

export default Avatar;

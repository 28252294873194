/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from '@redux-saga/core/effects';

import { ClientMembershipService, IUpdatePersonalDetailsBody } from 'api';

import { TActionResult } from 'api/Api/BaseApi/types';
import { ClientService } from 'api/services/ClientService';
import { PersonalDetailsService } from 'api/services/PersonalDetailsService';

import { pick, uniq } from 'lodash';

import {
  catalogActions,
  FETCH_AND_UPDATE_CLIENT_AND_AREA_SUB_CATALOG,
  FETCH_AND_UPDATE_CLIENT_COMPENSATIONS_REQUEST,
  FETCH_AND_UPDATE_CLIENT_DETAILS_AT_STORE_LEVEL,
  FETCH_AND_UPDATE_CLUB_MEMBERSHIP_PROFILE,
  IClientCouponsUpdate,
  IClientDetailsAtStoreLevelWereUpdated,
  IFetchAndUpdateClientAndAreaSubCatalog,
  IGetClubMembershipProfileRequest,
  IShowNotify,
  IUpdateClubMembershipRequest,
  IUpdateDeliveryAreaSubCatalogRequest,
  IUpdateDetailsAtStoreLevel,
  IUpdateMembershipBenefits,
  IUpdateMinimumProfileDataRequest,
  IUpdateUserSubCatalogRequest,
  notifyActions,
  promotionsActions,
  UPDATE_MINIMUM_PROFILE_DATA_REQUEST,
  userActions,
  useStore,
} from 'store';
import {
  clientCouponsUpdate,
  updateMembershipBenefits,
} from 'store/modules/orderDiscounts/actions';
import { clientDetailsAtStoreLevelWereUpdated } from 'store/modules/user/actions';
import { getClubMembership } from 'store/modules/user/selectors';
import {
  TClientDetailsOnStoreLevelServer,
  TClientServer,
  TClubMembershipProfileServer,
  TCouponServer,
} from 'types';
import { prepareCoupons } from 'utils/helpers/promotions/prepareClientCoupons';
import { UPDATE_FULL_PROFILE_DATA_REQUEST } from './constants';

import { IUpdateFullProfileDataRequest, IUpdateProfile } from './types';

function* updateFullProfileData({
  payload: { body, fieldsToUpdate, formikHelpers, callBack },
}: IUpdateFullProfileDataRequest): Generator<
  | Promise<TActionResult<TClientServer>>
  | PutEffect<IUpdateProfile>
  | PutEffect<IShowNotify>
  | PutEffect<IFetchAndUpdateClientAndAreaSubCatalog>
  | CallEffect,
  void,
  any
> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useStore();
  const newFieldsToUpdate = fieldsToUpdate.includes('coordinates')
    ? fieldsToUpdate.map((field) => (field === 'coordinates' ? 'address' : field))
    : [...fieldsToUpdate];

  const uniqFieldsToUpdate = uniq(newFieldsToUpdate);

  let address: IUpdatePersonalDetailsBody['address'];
  // check if we have address in fieldsToUpdate and update address field
  if (uniqFieldsToUpdate.includes('address')) {
    if (body.city) {
      address = {
        description: body.streetAndStreetNumber,
        city: body.city,
        ...(body.coordinates && { coordinates: JSON.stringify(body.coordinates) }),
      };
    } else {
      address = {
        description: body.address && `${body.address.address}`,
        placeId: body.address && body.address.placeId ? body.address.placeId : '',
        cityAndCountry: body.address && body.address.cityAndCountry,
      };
    }
  }

  const newBody: IUpdatePersonalDetailsBody = {
    ...pick(body, uniqFieldsToUpdate), // get with pick all values that we have in uniqFieldsToUpdate array
    address, // if it will be undefined it will not send
    fieldsToUpdate: uniqFieldsToUpdate,
  };

  const actionResult = yield PersonalDetailsService.updatePersonalDetails(newBody);

  if (formikHelpers.setSubmitting) {
    formikHelpers.setSubmitting(false);
  }

  if (!actionResult.success) {
    yield put(
      notifyActions.showNotification({
        message: actionResult.resolvedMessage,
      }),
    );
    if (formikHelpers.setStatus) {
      // if success = false update status in form
      // status used in useEffect and check when we need to show city input
      formikHelpers.setStatus(actionResult?.code);
    }
    return;
  }

  yield put(userActions.updateProfile(actionResult.data));

  if (fieldsToUpdate.some((field) => field === 'address')) {
    yield put(
      userActions.fetchAndUpdateClientAndAreaSubCatalogRequest(() => {
        store.dispatch(catalogActions.checkAndFetchBranchCatalogIfNecessary());
      }),
    );
  }

  // callback (init signup analytics and show delivery dialog by default)
  if (callBack) {
    callBack(actionResult.data);
  }
}

function* updateMinimumProfileData({
  payload: { body, fieldsToUpdate, formikHelpers, callBack },
}: IUpdateMinimumProfileDataRequest): Generator<
  Promise<TActionResult<TClientServer>> | PutEffect<IUpdateProfile> | PutEffect<IShowNotify>,
  void,
  any
> {
  const uniqFieldsToUpdate = uniq(fieldsToUpdate);

  const newBody: IUpdatePersonalDetailsBody = {
    ...pick(body, uniqFieldsToUpdate),
    fieldsToUpdate: uniqFieldsToUpdate,
  };

  const actionResult = yield PersonalDetailsService.updatePersonalDetails(newBody);

  if (formikHelpers.setSubmitting) {
    formikHelpers.setSubmitting(false);
  }

  if (!actionResult.success) {
    yield put(
      notifyActions.showNotification({
        message: actionResult.resolvedMessage,
      }),
    );
    if (formikHelpers.setStatus) {
      // if success = false update status in form
      // status used in useEffect and check when we need to show city input
      formikHelpers.setStatus(actionResult?.code);
    }
    return;
  }

  yield put(userActions.updateProfile(actionResult.data));

  // callback (init signup analytics and show delivery dialog by default)
  if (callBack) {
    callBack(actionResult.data);
  }
}

function* fetchAndUpdateClientDetailsAtStoreLevel(): Generator<
  | PutEffect<IUpdateDetailsAtStoreLevel>
  | CallEffect<TActionResult<TClientDetailsOnStoreLevelServer>>,
  void,
  any
> {
  const { data }: TActionResult<TClientDetailsOnStoreLevelServer> = yield call(
    ClientService.getClientDetailsOnStoreLevel,
  );

  yield put(userActions.updateDetailsAtStoreLevel(data));
}

function* fetchAndUpdateClientCompensation(): Generator<
  SelectEffect | PutEffect<IClientCouponsUpdate> | CallEffect,
  void,
  any
> {
  try {
    const { data }: TActionResult<TCouponServer[]> = yield call(
      ClientService.getClientCompensations,
    );

    // update coupons to include client coupons
    yield put(clientCouponsUpdate(prepareCoupons(data)));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* fetchAndUpdateClubMembershipProfile(): Generator<
  | SelectEffect
  | PutEffect<IClientCouponsUpdate | IGetClubMembershipProfileRequest | IUpdateMembershipBenefits>
  | CallEffect,
  void,
  any
> {
  try {
    const clubMembership = yield select(getClubMembership);

    if (clubMembership) {
      const { data: clubMembershipProfile }: TActionResult<TClubMembershipProfileServer[]> =
        yield call(ClientMembershipService.getClubMembershipProfile);

      yield put(promotionsActions.getClubMembershipProfileSuccessAction(clubMembershipProfile));
      return;
    }

    yield put(updateMembershipBenefits(undefined)); // reset for scenario when user had some benefits chosen but not a member anymore
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* fetchAndUpdateClientAndAreaSubCatalog({
  payload,
}: IFetchAndUpdateClientAndAreaSubCatalog): Generator<
  | SelectEffect
  | PutEffect<
      | IUpdateUserSubCatalogRequest
      | IUpdateDeliveryAreaSubCatalogRequest
      | IClientDetailsAtStoreLevelWereUpdated
      | IUpdateClubMembershipRequest
      | IUpdateDetailsAtStoreLevel
    >
  | CallEffect,
  void,
  any
> {
  yield put(clientDetailsAtStoreLevelWereUpdated(false));

  const { callback } = payload;
  const { data }: TActionResult<TClientDetailsOnStoreLevelServer> = yield call(
    ClientService.getClientDetailsOnStoreLevel,
  );

  yield put(userActions.updateDetailsAtStoreLevel(data));

  yield put(userActions.updateClubMembership(!!data.externalMembership));

  yield put(userActions.updateUserSubCatalog(data?.subCatalog?.id));
  yield put(
    userActions.updateDeliveryAreaSubCatalog(
      data?.deliveryArea?.onlineActivityBranch?.subCatalog?.id,
    ),
  );
  yield put(clientDetailsAtStoreLevelWereUpdated(true));

  if (callback) {
    yield call(callback);
  }
}

function* rootUserSaga(): Generator {
  yield takeEvery(UPDATE_FULL_PROFILE_DATA_REQUEST, updateFullProfileData);
  yield takeEvery(UPDATE_MINIMUM_PROFILE_DATA_REQUEST, updateMinimumProfileData);
  yield takeEvery(FETCH_AND_UPDATE_CLIENT_COMPENSATIONS_REQUEST, fetchAndUpdateClientCompensation);
  yield takeEvery(
    FETCH_AND_UPDATE_CLIENT_AND_AREA_SUB_CATALOG,
    fetchAndUpdateClientAndAreaSubCatalog,
  );
  yield takeEvery(FETCH_AND_UPDATE_CLUB_MEMBERSHIP_PROFILE, fetchAndUpdateClubMembershipProfile);
  yield takeEvery(
    FETCH_AND_UPDATE_CLIENT_DETAILS_AT_STORE_LEVEL,
    fetchAndUpdateClientDetailsAtStoreLevel,
  );
}

export default rootUserSaga;

import { TLanguageTranslations } from '../types';

const hebrewMessages: TLanguageTranslations = {
  languages: {
    language: 'שפה',
  },
  rexail: {
    formalName: 'קמעונאי און-ליין בע"מ',
    brandName: 'Rexail',
  },
  pages: {
    titles: {
      cart: 'סל הקניות שלי',
      checkout: 'סיום רכישה',
    },
  },
  currency: {
    shekel: '₪',
  },
  button: {
    continue: 'המשך',
    loadPreviousOrder: 'טעינת הזמנה קודמת',
    previousOrder: 'טעינת הזמנה קודמת',
    goToCheckout: 'המשך לתשלום',
    goToCart: 'המשך לסל',
    continueToCheckout: 'המשך לתשלום',
    placeOrder: 'סיום הזמנה',
    paymentViaBit: 'תשלום באמצעות ביט',
    placeSubscription: 'שמור מנוי',
    continueShopping: 'המשך בקנייה',
    viewSubscription: 'צפיה במנוי',
    viewSubscriptionCompletedCard: 'צפיה במנוי',
    viewOrder: 'צפיה בהזמנה',
    view: 'צפיה',
    orderAgain: 'הזמנה חוזרת',
    edit: 'עריכה',
    cancelOrder: 'ביטול הזמנה',
    contactStore: 'יצירת קשר עם החנות',
    downloadInvoice: 'הורדת חשבונית',
    downloadDeliveryCertificate: 'הורדת תעודת משלוח',
    restore: 'שחזור',
    cancel: 'ביטול',
    ok: 'אישור',
    saveChanges: 'שמור שינויים',
    backToTheHomePage: 'חזרה לעמוד הבית',
    orderAgainBtn: 'שחזור',
    increaseQuantity: 'הגדלת כמות למוצר',
    decreaseQuantity: 'הקטנת כמות למוצר',
    loadMoreProducts: 'טען עוד',
  },
  input: {
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    email: 'אימייל',
    phone: 'טלפון',
    fullAddress: 'כתובת מלאה',
    floor: 'קומה',
    apartment: 'דירה',
    doorCode: 'קוד כניסה',
    entrance: 'כניסה',
    addressNotes: 'הערות לכתובת',
    optional: 'אופציונלי',
    search: 'חיפוש מוצר',
    chooseStore: 'בחרו חנות',
    cardHolderName: 'שם בעל הכרטיס',
    cardHolderID: 'ת.ז. בעל הכרטיס',
    creditCardNumber: 'מספר כרטיס אשראי',
    expirationYear: 'שנה',
    expirationMonth: 'חודש',
    expirationDate: 'MM/YY',
    cvv: 'CVV',
    validity: 'תוקף',
    coupon: 'הזינו קוד קופון כאן',
    cellPhone: 'טלפון נייד',
    hidden: '',
    coordinates: 'אנא גררו את המפה כדי לסמן את מיקומכם המדוייק',
    city: 'עיר',
    streetAndStreetNumber: 'רחוב ומספר בית',
    streetAndStreetExample: 'לדוגמא: ויצמן 5',
    address: 'כתובת',
    details: 'פרטי הפניה',
    receivesCommercialMessages: 'אני רוצה לקבל עדכונים לגבי מבצעים והנחות במייל ו- SMS',
    whatDeliveryPersonShouldKnow: 'מה חשוב שהשליח שלנו ידע?',
    notSelected: 'לא נבחר',
    otherAmount: 'סכום אחר',
    tipPlaceholder: 'ניתן להזין סכום עד ₪100',
    cityMoshavKibuts: 'עיר/ מושב/ קיבוץ',
    streetAndNumberOfBuilding: 'רחוב ומספר בניין',
    nameOfBase: 'שם בסיס',
    cityPlaceholder: 'בחרו מהרשימה',
    searchPickup: 'חיפוש נק׳ איסוף',
    searchLocationByAddress: 'חיפוש נק׳ לפי כתובת',
  },
  link: {
    backToTheShop: 'חזרה לחנות',
  },
  payments: {
    options: {
      addNewCard: 'הוספת כרטיס חדש',
    },
    hiddenDigits: '••••',
    noPaymentMethods: 'אין אמצעי תשלום שמור במערכת',
    creditCardDetails: 'פרטי כרטיס אשראי',
    backToPayments: 'חזרה לאמצעי תשלום',
    validUntil: 'תוקף',
    bit: {
      name: 'bit',
      awaitingPayment: 'ממתין לתשלום.',
      linkIsValidTenMinutes: 'הלינק לתשלום תקף ל10 דקות בלבד.',
      paymentDeclined: 'תשלום לא התקבל.',
      sendLinkTo: 'נשלח לינק לתשלום למס׳ {{phoneNumber}}',
      resend: 'שלח שוב',
      paymentAccepted: 'תשלום התקבל',
      bitLinkWasSendSuccessfully: 'לינק לתשלום נשלח בהצלחה',
    },
  },
  userMenu: {
    hiText: 'היי',
    loginText: 'התחברות',
    signUpLoginText: 'הרשמה / התחברות',
    accountDetails: 'פרטי חשבון',
    payments: 'אמצעי תשלום',
    mySubscriptions: 'מנויים שלי',
    orderHistory: 'היסטוריית הזמנות',
    logOut: 'התנתקות',
    clientCoupons: {
      clientCoupon: 'הוזן עבורך זיכוי',
      validUntil: 'בתוקף עד ',
    },
  },
  accountDetails: {
    edit: 'עריכה',
    sendVerificationEmailTo: 'לאחר לחיצה על כפתור ה"שמירה", אנו נשלח מייל אימות',
    sendToOldAndNewEmail: 'לכתובת אימייל הישנה והחדשה שלך',
    sendToNewEmail: 'לכתובת אימייל החדשה שלך',
    forSecurityPurposes: 'למטרות אבטחה.',
    emailNotDefined: 'לא הוגדר',
    keep: 'שמירה',
    verified: 'מאומת',
    waitingForVerificationAtTheEmail: 'ממתין לאימות במייל {{email}}',
    waitingForVerification: 'ממתין לאימות',
  },
  categoryMenu: {
    more: 'עוד',
    title: 'קטגוריות',
    allProducts: 'כל המוצרים',
    allItems: 'כל הפריטים',
    promotions: 'מבצעים',
  },
  topCard: {
    expandCart: 'הרחב / הסתר סל קניות',
    myShoppingBag: 'סל הקניות שלי',
    continue: 'המשך',
    payment: 'לתשלום',
    cardEmpty: 'סל הקניות שלכם ריק',
    startAdding: 'התחילו להוסיף מוצרים',
    revaluation: 'שערוך. עלות סופית לפי שקילה.',
    minimumOrder: 'מינימום הזמנה',
    backToStore: 'חזרה לחנות',
    orderSummary: 'סיכום הזמנה',
    commentsToPreparer: 'הערות למכין ההזמנה',
    totalShoppingCart: 'סה"כ סל קניות',
    listHeader: {
      product: 'מוצר',
      pricePerUnit: 'מחיר ליחידה',
      quantity: 'כמות',
      total: 'סה"כ',
      remove: 'הסר',
    },
    deleteBasket: 'מחיקת סל',
    backToCart: 'חזור לסל הקניות שלי',
    showPreviousOrder: 'טעינת הזמנה קודמת',
    shoppingCartNItems: 'סל הקניות שלך מכיל {{items}} פריטים',
    deleteFromCart: 'הסר פריט מהסל',
    productsYouMightMissed: 'מוצרים שאולי פספסת',
    restore: 'שחזר',
    delete: 'מחק',
    removedItem: 'הפריט הוסר',
    shareCart: 'שיתוף סל',
    shareCartItems: 'שיתוף סל',
    cartWasShared: 'שיתוף סל',
    shareCartInFollowingWays: 'קניות זה יותר כיף בשניים! שתפו את הסל שלכם באחת מהדרכים הבאות',
    shareLink: 'העתקת קישור',
    whatsapp: 'Whatsapp',
    checkSharedCard: 'איזה כיף! שיתפו אותך בסל מלא בכל טוב!',
    cartWasSharedSuccessfully: 'איזה כיף! שיתפו אותך בסל מלא בכל טוב!',
    emailSharedCard: 'איזה כיף! שיתפו אותך בסל מלא בכל טוב מה{{storeName}}!',
    goToCart: 'צפיה בסל',
    deviceNotSupportShare: 'אופס! השיתוף אינו נתמך במכשירך. הקישור לסל הקניות הועתק ללוח.',
    linkCopiedSuccessfully: 'הקישור הועתק בהצלחה!',
  },
  generalPage: {
    about: 'אודות החנות',
    subscribe: 'יצירת מנוי',
    deliveryAreas: 'אזורי חלוקה',
    contact: 'יצירת קשר',
  },
  deliveryPickUp: {
    withoutDeliveryTimeText: 'בחרו מועד הספקה',
    allTimeFramesAreFull: 'מצטערים, אין חלונות זמן פנויים להזמנה, אנא נסו שנית במועד מאוחר יותר',
    errorMessage: 'עקב תקלה לא הצלחנו למצוא חלונות אספקה, אנא נסו שנית',
    chooseDeliveryMethod: 'בחרו אופן אספקה',
    sortPointsByCurrentLocation: 'מיון נקודות לפי מיקום נוכחי',
    onlySelfPickup: 'נקודות איסוף עצמי',
    onlySelfPickupChoseTime: 'נקודת איסוף עצמי',
  },
  orderType: {
    delivery: 'משלוח',
    selfPickup: 'נקודת איסוף',
  },
  stepper: {
    order: {
      personalInformation: 'פרטים אישיים',
      coordinationArrival: 'תיאום הגעה',
      payment: 'תשלום',
    },
    subscription: {
      personalInformation: 'הרכבת סל',
      coordinationArrival: 'תדירות, יום ושעה',
      payment: 'תשלום',
    },
  },
  overlay: {
    title: 'החליקו ימינה כדי לראות את שאר הקטגוריות',
    buttonText: 'הבנתי, תודה',
  },
  storeProductCard: {
    addToCart: 'הוספה לסל',
    productCommentTypeAvailable: 'הוראות הכנה בסיום הקניה',
    originalPrice: 'מחיר קודם',
    relevancy: {
      popular: 'מוצר מומלץ',
      high: 'מעולה',
      medium: 'איכות בינונית',
      low: 'פחות מומלץ',
    },
    supplier: 'ספק',
    pricePerOneHundredGram: '₪{{price}} ל-100 ג׳',
    pricePerOneHundredMl: '₪{{price}} ל-100 מ״ל',
    countryOfOrigin: 'ארץ מוצא {{countryName}}',
    addMoreItemMultiple: 'הוסיפו עוד {{numberOfItems}} פריטים לקבלת הנחה',
    addMoreItemSingle: 'הוסיפו עוד פריט לקבלת הנחה',
    toPromotion: 'למבצע',
  },
  tag: {
    sale: 'הנחה',
    deal: 'מבצע',
    freeItem: 'מוצר מתנה',
  },
  header: {
    backButton: 'חזרה לסל הקניות',
    navigation: 'לאן מגיעים?',
    chooseDelivery: 'בחרו מועד אספקה',
    chooseSelfPickup: 'בחרו מועד אספקה',
    chooseSelfPickupTime: 'ומועד אספקה',
    chooseDeliveryTime: 'לבחירת מועד אספקה',
    chooseDeliverySubscriptionMode: 'בחרו תדירות ומועד אספקה',
    chooseSelfPickupSubscriptionMode: 'בחרו תדירות ומועד איסוף עצמי',
    skipToTheCategory: 'דילוג לתפריט קטגוריות',
    skipToTheMainContent: 'דילוג לתוכן ראשי',
    skipToTheFooter: 'דילוג לתחתית העמוד',
  },
  previousOrder: {
    price: 'לתשלום',
    items: 'פריטים',
    addItems: 'הוסיפו לסל {{count}} פריטים', // TODO can be removed
    backToOrderList: 'חזרה לרשימת ההזמנות',
    payAttention: 'שימו לב',
    ongoingOrder: 'קיימת הזמנה פתוחה ל',
    itemIsOutOfStock: 'הפריט חסר במלאי',
  },
  editOrder: {
    removedItemsExcludedFromCatalog:
      'הפריטים הבאים הוסרו מההזמנה מכיוון שהם לא זמינים: {{- removedItems}}.',
    removedItemsOutOfStock: 'הפריטים הבאים הוסרו מההזמנה מכיוון שהם לא במלאי: {{- removedItems}}.',
  },
  order: {
    deliveryDate: 'זמן אספקה',
    orderType: 'סוג הזמנה',
    numberOfItems: 'מס׳ פריטים',
    totalToPay: 'סה"כ לתשלום',
    total: 'סה"כ',
    orderNumber: 'הזמנה מס׳',
    status: 'סטטוס',
    deliveryAddress: 'כתובת למשלוח',
    selfPickupAddress: 'כתובת לאיסוף עצמי',
    paymentMethod: 'אמצעי תשלום',
    comments: 'הערות',
    revaluation: 'שערוך. עלות סופית לפי שקילה.',
    noOrders: 'אין הזמנות שמורות במערכת',
    startBuying: 'התחלת קנייה',
    orderTime: 'זמן ביצוע הזמנה',
    ecoPackaging: 'אריזה אקולוגית',
    itemsSubstitution: 'מוצרים חלופיים',
    suppliedItems: 'מוצרים שסופקו',
    notSupplied: 'מוצרים שלצערנו לא סופקו',
    orderedItems: 'מוצרים שהזומנו',
    chooseEcoPackaging: 'אריזה אקולוגית - כמה שפחות שקיות',
    inCaseItemIsOutOfStock: 'במקרה של חוסר במוצר',
    substituteWithSimilar: 'החליפו במוצר דומה',
    doNotSubstitute: 'אל תחליפו',
    contactMe: 'צרו איתי קשר',
  },
  days: {
    monday: 'יום שני',
    tuesday: 'יום שלישי',
    wednesday: 'יום רביעי',
    thursday: 'יום חמישי',
    friday: 'יום שישי',
    saturday: 'יום שבת',
    sunday: 'יום ראשון',
    shortName: {
      monday: 'ב׳',
      tuesday: 'ג׳',
      wednesday: 'ד׳',
      thursday: 'ה׳',
      friday: 'ו׳',
      saturday: 'ש׳',
      sunday: 'א׳',
    },
    today: 'היום',
    tomorrow: 'מחר',
  },
  footer: {
    termsAndConditions: {
      privacy: 'מדיניות פרטיות',
      accessibilityStatement: 'הצהרת נגישות',
      terms: 'תנאי שימוש',
    },
    menu: {
      about: 'אודות החנות',
      delivery: 'אזורי חלוקה',
      contact: 'יצירת קשר',
      details: 'פרטי חשבון',
      paymentMethod: 'אמצעי תשלום',
      subscription: 'מנויים שלי',
      orderHistory: 'היסטוריית הזמנות',
    },
    allRights: '© קמעונאי און ליין בע’’מ, {{year}}. כל הזכויות שמורות.',
    companyType: 'ח.פ/ע.מ.',
    ariaLabels: {
      storeAddress: 'כתובת בית העסק',
      storePhone: 'טלפון בית העסק',
      storeWhatsapp: 'וואטסאפ בית עסק',
      storeEmail: 'דואל בית העסק',
      storeFacebook: 'לינק לדף הפייסבוק של בית העסק',
      storeYoutube: 'לינק ליוטיוב של בית העסק',
      storeInstagram: 'לינק לאינסטגרם של בית העסק',
    },
  },
  snackbar: {
    undo: 'ביטול',
    orderMinimumFellShort: {
      delivery:
        'שימו לב! על מנת להשלים את ההזמנה יש להגיע למינימום של {{orderMinimum}} ש"ח (לא כולל דמי משלוח).',
      selfPickup:
        'שימו לב! על מנת להשלים את ההזמנה יש להגיע למינימום של {{orderMinimum}} ש"ח (לא כולל דמי הכנה).',
    },
    orderMinimumFellShortIfNoDeliveryFee:
      'שימו לב! על מנת להשלים את ההזמנה יש להגיע למינימום של {{orderMinimum}} ש"ח.',
    promotionAppliedSuccessfully: 'ההנחה התווספה בהצלחה לסל הקניות!',
  },
  forms: {
    checkout: {
      submitButton: 'המשך לתיאום הגעה',
      backButton: 'חזרה לסל הקניות',
      arrivalSubtitle: 'בחרו מועד שמתאים לכם',
      collectionPoint: 'בחרו נקודת איסוף',
      pickUpDate: 'בחרו מועד איסוף',
      backToPersonal: 'חזרה לפרטים אישיים',
      backToArrival: 'חזרה לתיאום הגעה',
      moveToPay: 'המשך לתשלום',
      additionalDelivery: 'הצג זמני משלוח נוספים >',
      creditDetails: 'פרטי כרטיס אשראי',
      billingCredit: 'כרטיס אשראי לחיוב',
      saveCredit: 'שמור את פרטי כרטיס האשראי להזמנות עתידיות',
      saveCreditTooltip:
        'פרטי התשלום לא נשמרים בשרתי האתר, הם מאוחסנים בצורה מאובטחת באמצעות טכנולוגיית TOKENIZATION בשיתוף עם חברת הסליקה CREDIT GUARD',
    },
    contactUs: {
      title: 'אפשר גם להשאיר לנו הודעה',
      submitButton: 'שליחה',
    },
  },
  validation: {
    email: 'כתובת האימייל אינה חוקית',
    required: 'זהו שדה חובה',
    chooseCity: 'אנא בחר עיר',
    error: 'ערך לא חוקי',
    minMonth: 'ערך מינימלי 01',
    maxMonth: 'ערך מקסימלי 12',
    minYear: 'ערך מינימלי - שנה נוכחית',
    twoDigits: 'יש להזין שתי ספרות',
    noOptions: 'אין תוצאות',
    productComment: 'יש לבחור אופן הכנה',
    chooseHowToPrepareItems: 'אנא בחרו אופן הכנה לפריטים הרלוונטיים',
    language: 'פעולה זו אינה זמינה ברגע זה. אנא נסו שוב מאוחר יותר.',
  },
  map: {
    getLocationFromBrowser: 'מיקום נוכחי',
    moveMapToGetPosition: 'אנא גררו את המפה כדי לסמן את מיקומכם המדוייק *',
    navigatorNotSupported:
      'שירותי מיקום לא נתמכים / חסומים במכשיר זה. יש להפעיל שירותי מיקום דרך תפריט הגדרות',
    markLocationOnTheMap: 'סימון מיקום מדוייק על המפה',
    pinIsFar: 'מיקום הסימון רחוק מדי מהכתבות שהזנתם',
    pinIsInvalid: 'מיקום הסיכה אינו חוקי. נסה למקם מחדש את הסיכה במדויק.',
  },
  title: {
    aboutProduct: 'על המוצר',
    ordersHistory: 'היסטוריית הזמנות',
    activeOrders: 'הזמנות פעילות',
    closedOrders: 'הזמנות סגורות',
    subscriptions: 'מנויים שלי',
  },
  comments: {
    preparationInstructionsAtTheEndOfThePurchase: 'הוראות הכנה בסיום הקניה',
    youMayAlsoWant: 'אולי תרצו גם',
  },
  shoppingPage: {
    searchResults: 'תוצאות חיפוש',
    cleanSearch: 'נקה חיפוש',
    goToHomePage: 'עבור לדף הבית של {{storeName}}',
    sort: {
      sortBy: 'מיון לפי',
      sortByName: 'שם מוצר',
      sortByCategoryAndName: 'קטגוריה ושם מוצר',
      sortByPriceAsc: 'מחיר מהנמוך לגבוה',
      sortByPriceDesc: 'מחיר מהגבוה לנמוך',
      sortByPromoted: 'מוצרים במבצע',
      sortBySearchRelevancy: 'רלוונטיות',
    },
  },
  paymentInfoSection: {
    creditCard: {
      text: 'הסליקה מתבצעת בצורה מאובטחת, פרטי התשלום מוגנים על ידי טכנולוגיות אבטחת מידע המתקדמות ביותר בשיתוף פעולה עם חברת Credit Guard.',
      externalFormText:
        'בלחיצה על "המשך" תועברו לדף סליקה חיצוני שהוקם בשיתוף חברת Credit Guard המובילה בתחום סליקת אשראי ואבטחת פרטי אשראי בישראל.',
      link: 'למד עוד',
    },
    deferredPayment: 'ההזמנה תתבצע בהקפה - לא ייגבה תשלום כרגע. בהמשך ניצור קשר לצורך גביית התשלום',
    absent: 'ההזמנה תוזן ללא אמצעי תשלום, בהמשך ניתן יהיה לשייך אמצעי תשלום להזמנה',
    cash: 'השליח יגבה את התשלום במזומן במועד המשלוח. יש להכין סכום מדוייק ככל הניתן (ייתכן ולשליח לא יהיה עודף).',
    bit: {
      onlyNonWeighableItems:
        'לאחר לחיצה על כפתור ״תשלום באמצעות ביט״ נשלח אליכם הודעת sms עם לינק לתשלום דרך ביט. אנו נכין את ההזמנה רק במידה והתשלום התבצע ואושר. ניתן לבדוק את סטטוס התשלום בעמוד ״פרטי הזמנה״.',
      weighableItems:
        'לאחר לחיצה על כפתור ״תשלום באמצעות ביט״ נשלח אליכם הודעת sms עם לינק לתשלום דרך ביט. אנו נכין את הזמנתכם רק במידה והתשלום התבצע ואושר. בנוסף, נשריין סכום מעט גבוה מסכום השערוך לטובת חריגות אפשריות במשקלי הפריטים שלוקטו. סכום החיוב הסופי יהיה ע"פ הכמויות המדויקות שנשקלו.',
    },
  },
  auth: {
    loginText: 'התחברו',
  },
  dialog: {
    bitErrorViewOrder: 'בעקבות תקלת תקשורת העמוד ירוענן לצורך הצגת הנתונים המעודכנים ',
    bitErrorOrderCompleted:
      'בעקבות תקלת תקשורת, הנכם מועברים לעמוד פרטי ההזמנה לצורך הצגת הנתונים המעודכנים אודות הזמנתכם',
    multipleDeliveryAreas:
      'אנו מבצעים משלוחים בכמה אזורים ב{{cityName}}, אנא הזינו כתובת מלאה על מנת להמשיך',
    oneDeliveryArea:
      'אנו מבצעים משלוחים באיזור מסויים ב{{cityName}}, אנא הזינו כתובת מלאה על מנת להמשיך',
    unavailableTimeframe: 'חלון הזמן שבחרתם אינו זמין יותר. הנכם מועברים לתיאום הגעה מחדש',
    searchSucceeded: 'החיפוש הצליח',
    searchFailed: 'החיפוש נכשל',
    closeButton: 'סגור חלון פופאפ',
    catalogWasUpdated: {
      title: 'עדכון קטלוג',
      body: 'שימו לב, בהתאם לכתובת שנבחרה שוייכתם לסניף הנותן שירות באיזורכם. ייתכנו שינויים במלאי ובמחירים.',
      okButton: 'אישור',
    },
    handlingFee: {
      title: 'דמי טיפול',
      body: 'עבור הזמנות מתחת ל-{{orderHandlingFeeThreshold}} ₪ יתווספו דמי טיפול בגובה {{orderHandlingFee}} ₪ לסה״כ לתשלום',
      okButton: 'המשך לתשלום',
      cancelButton: 'חזרה לחנות',
    },
    orderMinimumLabel: 'מינימום הזמנה',
    orderMinimum: {
      title: 'מינימום הזמנה',
      body: {
        delivery:
          'מינימום הזמנה הינו ₪{{orderMinimum}} (לא כולל דמי משלוח). אנא הוסיפו עוד מוצרים לסל.',
        selfPickup:
          'מינימום הזמנה הינו ₪{{orderMinimum}} (לא כולל דמי הכנה). אנא הוסיפו עוד מוצרים לסל.',
      },
      bodyIfNoDeliveryFee: 'מינימום הזמנה הינו {{orderMinimum}}. אנא הוסיפו עוד מוצרים לסל',
      okButton: 'המשך בקניה',
    },
    emptyCart: {
      title: 'מחיקת סל',
      body: 'אתם בטוחים שברצונכם לרוקן את סל הקניות?',
      yesButton: 'כן, מחק את הסל',
      noButton: 'לא, חזור לסל',
    },
    location: {
      title: 'ברוכים הבאים!',
      subTitle: 'בואו נוודא שאנו מגיעים אליכם',
      haveAccount: 'כבר יש לכם חשבון?',
      addressExample: 'רחוב, מס׳ בית ויישוב',
      interestingInSelfPickup: 'מעוניינים באיסוף עצמי?',
      chooseSelfPickup: 'בחרו נק׳ איסוף',
      deliveryHoursAndMoreDetails: 'שעות חלוקה ועוד פרטים',
      residential: 'בניין מגורים',
      locationType: 'סוג מיקום',
      private: 'בית פרטי',
      office: 'משרד',
      militaryBase: 'בסיס צבאי',
      couldNotLocateAddress: 'לא הצלחנו לאתר את הכתובת שלך, אנא הזינו את הכתובת שלכם כאן:',
    },
    login: {
      title: 'ברוכים הבאים!',
      subTitle: 'אנא הקישו את מס׳ טלפון הנייד על מנת להתחבר',
      confirmTerms: 'בלחיצה על המשך, קראתי ואישרתי את ',
      terms: 'תנאי השימוש',
    },
    loginCode: {
      titleSendViaTel: 'אימות טלפון נייד',
      titleSendViaEmail: 'אימות אימייל',
      subTitleTel: 'אנא הזינו את הקוד שנמסר למספר',
      subTitleEmail: 'אנא הזינו את הקוד שנמסר לאימייל',
      sendAgain: 'שלח שוב',
      selectMethodToReceivedCode: 'אנא בחרו שיטה בה תרצו לקבל את קוד אימות',
      dontReceivedCode: 'לא קיבלתם קוד אימות?',
      smsMessage: 'הודעת SMS',
      phoneCall: 'שיחת טלפון',
      email: 'כתובת אימייל',
      enterEmailToSendAuthCode: 'אנא הזינו כתובת אימייל לשליחת הקוד אימות',
      send: 'שליחה',
      back: 'חזרה',
      codeFromSms: 'קוד אימות - ספרה {{number}} מתוך 6',
    },
    signUp: {
      title: 'נעים להכיר!',
      subTitle: 'עוד כמה פרטים לסיום הליך ההרשמה',
      submitButton: 'הרשמה',
      addressExample: 'לדוגמא: דיזינגוף 20, תל אביב',
    },
    welcome: {
      title: 'ברוכים הבאים!',
      closeButton: 'סבבה, אפשר להתחיל',
    },
    paymentMethod: {
      title: 'מחיקת אמצעי תשלום',
      body: 'אתם בטוחים שברצונכם למחוק את כרטיס האשראי המסתיים ב {{fourLastDigits}}?',
      yesButton: 'כן, מחק את הכרטיס',
      noButton: 'ביטול',
    },
    delivery: {
      shippingAddress: 'כתובת למשלוח',
      storeHours: {
        title: 'שעות החלוקה ב{{-areaName}}',
      },
      coordination: {
        title: 'תיאום הגעה',
        description:
          'חלונות האספקה הינם הערכת זמנים בלבד ועשויים להשתנות בהתאם לכמות המשלוחים ותנאי השטח. אנא אפשרו גמישות בזמנים, וצרו קשר עם החנות בכל מקרה חריג.',
      },
      editText: 'עריכת פרטים',
      storeDoesntMakeDeliveryToYourAddressButSelfPickupExist:
        'מצטערים, כרגע המשלוחים שלנו לא מגיעים אליכם, אבל יש לנו נקודות איסוף בישוב שלך:',
      kmFromYourAddress: 'ק"מ מכתובתך',
      kmFromSelectedAddress: 'ק"מ מהכתובת שנבחרה',
      kmFromYourCurrentLocation: 'ק״מ ממיקומך הנוכחי',
      choose: 'בחירה',
      chooseAddress: 'הזינו כתובת למשלוח',
      chooseSelfPickup: 'בחרו נקודת איסוף',
      dropDownChooseSelfPickup: 'יש לבחור נקודת איסוף',
      selfPickupPoint: 'נקודת איסוף',
      changeSelfPickupPoint: 'שינוי נק. איסוף',
      toChooseSelfPickupDateNeedLog: 'על מנת לבחור מועד איסוף, עליכם להתחבר למערכת',
      chooseSelfPickupTime: 'לבחירת מועד אספקה',
    },
    existingOrder: {
      title: 'הזמנה קיימת',
      body: 'שמנו לב שפעם האחרונה שהיית פה ביצעת הזמנה ל{{deliveryDate}} בין השעות {{deliveryTime}}',
      description: 'האם ברצונך לערוך את ההזמנה הקיימת או לבצע הזמנה נוספת?',
      submitButton: 'לערוך הזמנה קיימת',
      cancelButton: 'לבצע הזמנה נוספת',
    },
    activeSubscription: {
      title: 'מנוי פעיל',
      body: 'היי, יש לך אצלנו מנוי פעיל שבמסגרתו צפויה הזמנה לתאריך {{- nextOrderDate}}.',
      bodyDescription: 'האם ברצונך לערוך את המנוי הקיים, או שהגעת לכאן כדי לעשות משהו אחר?',
      okButton: 'עריכת המנוי הקיים',
      cancelButton: 'המשך גלישה באתר',
    },
    cancelOrder: {
      title: 'ביטול הזמנה',
      editedTitle: 'ביטול הזמנה במצב עריכה',
      body: 'אתם בטוחים שברצונכם לבטל את הזמנה מס׳ {{id}}?',
      editedBody: 'אתם בטוחים שברצונכם לבטל את הזמנה מס׳ {{id}} אשר נמצאת כרגע במצב עריכה?',
      yesButton: 'כן, בטל את ההזמנה',
      noButton: 'לא',
    },
    cancelSubscription: {
      title: 'ביטול מנוי',
      editedTitle: 'ביטול מנוי במצב עריכה',
      body: 'האם תרצו לבטל רק את המנוי או גם את ההזמנה הקרובה של המנוי שנוצרה מהמנוי?',
      editedBody:
        'אתם בטוחים שברצונכם לבטל את המנוי שנמצא כרגע במצב עריכה או גם את ההזמנה הקרובה שנוצרה מהמנוי?',
      cancel: 'השאר את המנוי',
      ok: 'בטל את המנוי וההזמנה הקרובה',
      justUnsubscribe: 'בטל רק את המנוי',
    },
    unsuspendSubscription: {
      title: 'ביטול הקפאה',
      body: 'האם לבטל את הקפאת המנוי?',
      cancel: 'לא',
      ok: 'כן, בטל הקפאה',
    },
    suspendSubscription: {
      cancel: 'השאר את המנוי',
      suspendAndCancelOrder: 'הקפא את המנוי וההזמנה הקרובה',
      justSuspend: 'הקפא את המנוי בלבד',
    },
    editOrderMode: {
      title: 'מצב עריכת הזמנה',
      body: 'לידיעתכם - ההזמנה אותה אתם עורכים כעת הינה לתאריך {{orderDate}}, איך ברצונכם להמשיך?',
      keepEditing: 'המשך בעריכת ההזמנה',
      exitEditMode: 'בטל עריכת הזמנה',
    },
    deliveryToClientAddress: {
      performDeliveryTitle: 'כן!',
      performDeliveryBody: 'המשלוחים שלנו מגיעים ל{{city}}',
      performsSelfPickupBody:
        'המשלוחים שלנו לא מגיעים לכתובתך, אבל יש לנו נקודת איסוף ביישוב הנבחר!',
      performsSelfPickupAndDeliveryBody:
        'המשלוחים שלנו מגיעים ל{{city}} ובנוסף יש לנו נק׳ איסוף בישוב הנבחר!',
      performDeliveryYesButton: 'המשך בקנייה',
      performSelfPickupYesButton: 'בחירת נק׳ איסוף',
      doesntPerformDeliveryTitle: 'מצטערים…',
      doesntPerformDeliveryBody: 'המשלוחים שלנו לא מגיעים לכתובתך',
      doesntPerformDeliveryChangeAddressButton: 'שינוי עיר',
      doesntPerformDeliveryChangeCloseButton: 'המשך בקניה',
      edit: 'ערוך',
    },
    threeDSecure: {
      errorTitle: 'שגיאה',
      apiLoadError: 'שגיאה בהתקשרות לחברת האשראי, יש לרענן את הדף ולנסות שנית',
      authError:
        'שגיאה באימות כרטיס האשראי. נא לברר כי כל הפרטים שהוקשו נכונים ולנסות שנית, או להשתמש בכרטיס אשראי אחר. קוד השגיאה כפי שהתקבל מחברת האשראי: {{errorCode}}',
      genericAuthError:
        'שגיאה באימות כרטיס האשראי. נא לברר כי כל הפרטים שהוקשו נכונים ולנסות שנית, או להשתמש בכרטיס אשראי אחר.',
      okButton: 'אישור',
    },
    basket: {
      title: 'שינוי הרכב {{- bagName}}',
      subtitle: 'ניתן להחליף עד {{maxNumOfReplacements}} מוצרים. לחצו על המוצר להחלפתו.',
      save: 'שמור שינויים',
      productInBasket: 'מוצרים בסל',
      replaceProducts: 'אשמח לקבל במקום',
      maxReplacementsReached: 'הגעתם לכמות ההחלפות המקסימלית לסל!',
      defaultItemQuantity: 'מנה',
    },
    exitModeIndicator: {
      addSubscription: {
        title: 'יציאה ממצב יצירת מנוי',
        body: 'אתם בטוחים שברצונכם לצאת ממצב יצירת מנוי?',
        okButton: 'כן, בטוחים',
        cancelButton: 'ביטול',
      },
      editSubscription: {
        title: 'יציאה ממצב עריכת מנוי',
        body: 'אתם בטוחים שברצונכם לצאת ממצב עריכת מנוי? ',
        okButton: 'כן, בטוחים',
        cancelButton: 'ביטול',
      },
      edit: {
        title: 'יציאה ממצב עריכת הזמנה',
        body: 'אתם בטוחים שברצונכם לצאת ממצב עריכת הזמנה?',
        okButton: 'כן, בטוחים',
        cancelButton: 'ביטול',
      },
    },
    updateSubscription: {
      title: 'שינויים במנוי',
      body: 'האם תרצו להחיל שינויים אלו גם עבור ההזמנות העתידיות של מנוי זה?',
      // TODO should be translated!
      bodyIfTipExist: 'Your chosen courier tip will be apply only to this order.',
      okButton: 'כן, החל',
      cancelButton: 'לא, רק עבור הזמנה זו',
    },
    orderEditingIsNotPossible: {
      title: 'אופס!',
      body: 'לא ניתן לערוך את ההזמנה כי זמן האספקה קרוב מדי, מעוניינים ליצור איתנו קשר טלפוני?',
      yesButton: 'כן, צור קשר',
      noButton: 'לא, צא ממצב עריכה',
    },
    paymentByBit: {
      title: 'תשלום ב bit',
      body: 'בקשת תשלום בסך ₪{{cartEstimation}} ממתינה לכם בהודעת SMS במספר',
      or: 'או',
      scanQR: 'סירקו את ה QR קוד בנייד',
      awaitingPaymentViaBit: 'ממתין לתשלום ב bit',
      completePayment: 'אנא השלימו את תהליך התשלום באפליקציית bit לסיום ביצוע ההזמנה',
    },
    paymentByBitWasDeclined: {
      title: 'תשלום ב bit',
      subtitle: 'התשלום באמצעות bit לא התקבל!',
      body: 'באפשרותכם לעבור לעמוד פרטי הזמנה על מנת להשלים את התשלום',
      yesButton: 'קבלת לינק חדש לתשלום',
      noButton: 'מעבר לעמוד פרטי הזמנה',
    },
  },
  location: {
    cantFind: 'לא מוצאים את הכתובת שלכם?',
    selectByMap: 'הזינו רחוב וישוב באופן ידני',
    shareMyLocation: 'שיתוף מיקום נוכחי',
    myLocation: 'המיקום שלי',
  },
  availableHours: {
    chooseDay: 'בחרו יום',
    chooseTimeframe: 'בחרו טווח שעות',
    today: 'היום',
    tomorrow: 'מחר',
  },
  links: {
    backToSubscriptions: 'חזרה למנויים שלי',
    backToTheShop: 'חזרה לחנות',
    backToOrderHistory: 'חזרה להיסטוריית הזמנות',
    backToAddressSearch: 'חזרה לחיפוש כתובת',
  },
  completedOrder: {
    yourOrderInTheSystem: 'הזמנתך נקלטה במערכת!',
    noteCompletedOrder:
      'לידיעתך שם העסק שיופיע בכרטיס האשראי הוא של מערכת ההזמנות Rexail. לאחר הכנת הזמנתך ושקילת המוצרים, תשלח אליך חשבונית וכרטיס האשראי יחויב בהתאם.',
    backToTheHomeScreen: 'חזרה למסך הבית',
    orderDetails: 'פרטי ההזמנה',
    orderNumber: 'מס׳ הזמנה',
  },
  completedSubscription: {
    yourSubscriptionInTheSystem: 'המנוי נקלט בהצלחה!',
    noteCompletedSubscription:
      'ניתן לערוך ולבטל את המנוי בכל עת בצורה פשוטה ויעילה דרך תפריט "החשבון שלי"',
    backToTheHomeScreen: 'חזרה למסך הבית',
    subscriptionDetails: 'פרטי המנוי',
  },
  error: {
    itemsNotFoundTitle: 'מצטערים, לא נמצאו פריטים בשם "{{searchQuery}}"',
    itemsNotFoundDescription: 'ייתכן שהפריט אינו במלאי, או שקיימת שגיאת כתיב :)',
    previousOrderNotExist: 'אף אחד מהפריטים שנבחרו לא קיים בקטלוג!',
    oops: 'אופס!',
    pageNotFound: 'אנחנו לא מוצאים את העמוד שחיפשתם',
    somethingWentWrong: 'משהו השתבש…',
    errorDescription: 'כנראה משהו השתבש, נסו לחזור לעמוד הבית',
    increaseYourExperience: 'אנו משפרים את החוויה שלכם',
    systemCurrentlyUpgraded: 'המערכת עוברת כרגע שידרוג. אנא חיזרו בעוד מספר דקות.',
    maxAmountOfProduct: 'הגעת לכמות המקסימלית של מוצר זה :)',
    errorDetails: 'פרטי שגיאה',
  },
  subscription: {
    frequency: 'תדירות',
    orderType: 'סוג הזמנה',
    numberOfItems: 'מס׳ פריטים',
    nextOrder: 'הזמנה הבאה',
    subscriptionTo: 'מנוי ל{{shopName}}',
    subscriptionDate: 'תאריך יצירת המנוי',
    status: 'סטטוס',
    deliveryTime: 'זמן אספקה',
    lastOrderDate: 'מועד הזמנה אחרונה',
    localedPreferredDay: 'זמן מועדף',
    expectedNextOrder: 'צפי הזמנה הבאה',
    methodsOfPayment: 'אמצעי תשלום',
    suspendSubscription: 'הקפאת מנוי',
    unsuspendSubscription: 'ביטול הקפאה',
    cancelSubscription: 'ביטול מנוי',
    day: 'יום {{day}}',
    selectDay: 'בחרו תאריך חזרה לפעילות',
    dateIsInThePast: 'לא ניתן לבחור תאריך עבר',
    invalidDate: 'תאריך לא חוקי',
    cancel: 'ביטול',
    ok: 'אישור',
    noSubscription: 'אין מנויים שמורים במערכת',
    startToSubscribeTo: 'יצירת מנוי',
    chooseFrequency: 'בחרו תדירות',
    chooseDayAndTime: 'בחרו מועד שמתאים לכם',
    coordinationArrival: 'תדירות, יום ושעה',
  },
  whatIsSubscription: {
    title: 'עדיין לא מנויים שלנו?',
    subTitle: 'יצירת מנוי חוסכת זמן, ואין צורך לזכור להזמין בכל פעם מחדש.',
    howItWorks: 'איך זה עובד?',
    assembleTheBasketTitle: 'מרכיבים את הסל',
    assembleTheBasketSubtitle: 'עם המוצרים האהובים עליכם ואנחנו נדאג למוצרים טריים ואיכותיים',
    chooseFrequencyDayAndTimeTitle: 'בוחרים תדירות, יום ושעה',
    chooseFrequencyDayAndTimeSubtitle:
      'שבועי, דו-שבועי או חודשי, לפי הצורך, תמיד אפשר לערוך ולעדכן',
    enterPaymentDetailsTitle: 'מזינים פרטי תשלום',
    enterPaymentDetailsSubtitle: 'החיוב יתבצע לאחר אספקת ההזמנה, בהתאם לתדירות שנבחרה.',
    startToSubscribe: 'אפשר להתחיל',
    whatIsSubscriptionAndHowItWorksTitle: 'מהו מנוי ואיך הוא עובד?',
    whatIsSubscriptionAndHowItWorksDescription:
      'הזמנות המנוי נוצרות באופן אוטומטי יומיים לפני מועד האספקה וזאת בהתאם לתדירות וליום החלוקה שנבחר, למעט מקרים מיוחדים בהם יום המשלוח אינו זמין באותו השבוע (למשל בחגים ומועדים) - במקרה כזה, המערכת תייצר הזמנה ליום קרוב ומועד האספקה יעודכן בהודעת התזכורת ובמייל!',
    whatIsSubscriptionAndHowItWorksDescriptionTwo:
      'כשנוצרת הזמנת מנוי נשלחים סמס תזכורת עם תאריך אספקה ומייל מ – postmaster@rexail.co.il‏. למייל מצורף פירוט הזמנת המנוי החדשה בקובץ PDF, בגוף המייל תמצאו פירוט לגבי חוסרים צפויים לפי המלאי הזמין באתר. בנוסף, בגוף המייל יופיע קישור מהיר וישיר לעריכה/ביטול של ההזמנה (ללא צורך בתהליך התחברות).',
    whatIsSubscriptionAndHowItWorksDescriptionThree:
      'שימו לב! הזמנות שנוצרו על ידי המשתמש או על ידי המנוי הן באחריות הלקוח! נא לשים לב להודעות ועדכונים חשובים. במידת הצורך ניתן לפנות לבית העסק בשעות הפעילות.',
    addNewSubscriptionTitle: 'הוספת מנוי חדש',
    addNewSubscriptionDescription: 'ניתן להוסיף מנוי חדש בעמוד ״מנויים שלי״ (לאחר התחברות).',
    addNewSubscriptionDescriptionTwo:
      'שלב ראשון - יש להרכיב את הסל ממוצרים שאתם הייתם רוצים לקבל באופן קבוע. אחרי שסיימתם ללקט את המוצרים, יש ללחוץ על כפתור ״המשך לתשלום״ ומיד תועברו לעמוד ״סל הקניות שלי״ בו תוכלו לסקור את כל המוצרים שהוספתם לסל ולשנות במידת הצורך.',
    addNewSubscriptionDescriptionThree: 'שלב שני - בחירת תדירות, יום ושעה מועדפים לאספקה.',
    addNewSubscriptionDescriptionFour:
      'שלב שלישי - הזנת פרטי תשלום. לאחר הוספת או בחירת כרטיס אשראי לתשלום, יש ללחוץ על כפתור ״שמור מנוי״ כאשר לאחר מכן יוצג על המסך אישור שהמנוי נקלט יחד עם פרטי המנוי. החיוב יתבצע לאחר אספקת ההזמנה, בהתאם לתדירות שנבחרה.',
    howDoIFreezeSubscriptionTitle: 'עריכת/הקפאת/ביטול מנוי',
    howDoIFreezeSubscriptionDescription: 'ישנן שתי דרכים לעריכת ההזמנה.',
    howDoIFreezeSubscriptionDescriptionTwo:
      '1. באמצעות מייל אישור ההזמנה מ – postmaster@rexail.co.il המכיל קישור ישיר לעריכת/ביטול ההזמנה (ללא צורך בתהליך התחברות) הנשלח אוטומטית בסיום יצירת ההזמנה.',
    howDoIFreezeSubscriptionDescriptionThree:
      '2. בעמוד ״המנויים שלי״ (לאחר התחברות). בעמוד זה תוכלו לצפות במנויים שלכם. כדי לערוך/ להקפיא/ לבטל מנוי קיים יש ללחוץ על ״צפיה במנוי״ ולבחור את הפעולה הרצויה.',
    deficienciesInOrderingSubscriptionTitle: 'חוסרים בהזמנת מנוי',
    deficienciesInOrderingSubscriptionDescription:
      'חוסרים של פריטים באתר בית העסק יכולים להיגרם מסיבות שונות, לדוגמא: פריט מסוים הגיע והוכרז כלא ראוי לאריזה, או פריט לא בעונה, או מגודל בכמויות קטנות או מכל סיבה אחרת. אל דאגה! פריטים שקיימים במנוי ולא סופקו בהזמנה עקב חוסרים, לא יחוייבו.',
    changeDeliveryDateTitle: 'שינוי מועד אספקה חד פעמי',
    changeDeliveryDateDescription:
      'אם ההזמנה מהמנוי כבר נוצרה (יומיים לפני מועד האספקה), שינוי מועד האספקה אפשרי ע"י יצירת קשר עם בית העסק.',
    feelingConfused: 'מרגישים עדיין מבולבלים?',
    answersFrequentlyQuestions: 'כאן תוכלו למצוא תשובות לשאלות נפוצות',
  },
  personalDetails: {
    floorApartment: 'קומה {{floor}}, דירה {{apartment}}',
    apartment: 'דירה {{apartment}}',
    floor: 'קומה {{floor}}',
  },
  checkout: {
    noOrderComments: 'אין הערות',
    choosePayment: 'בחרו אמצעי תשלום',
    chooseCard: 'בחרו כרטיס',
    info: {
      address: 'כתובת',
    },
    paymentMethod: {
      creditCard: 'כרטיס אשראי',
      deferredPayment: 'תשלום בהקפה',
      absent: 'ללא תשלום',
      cash: 'מזומן',
      bit: 'תשלום ב bit',
    },
    coupon: {
      couponDiscount: 'הנחה קופון',
      generalDiscount: 'הנחה כללית',
      itemRefund: 'זיכוי מוצר',
      code: 'קוד קופון',
      noCoupon: 'לא הוזן',
      couponValueMessage: 'חסכתם! {{valueFormula}}',
      refundItemIsNotInTheCart: 'הפריט להנחה לא קיים בעגלת הקניות שלכם',
    },
    numberOfPayments: "מס' תשלומים",
    betweenHours: 'בין השעות',
    courierTip: 'טיפ לשליח (אופציונלי)',
    noTip: 'ללא טיפ',
    other: 'אחר',
    tipForCourier: 'טיפ לשליח',
    handlingFee: 'דמי טיפול',
  },
  autocomplete: {
    loading: 'טוען...',
  },
  bagOfProducts: {
    whatBagContain: 'מה מכיל הסל?',
    moreDetails: 'עוד פרטים',
  },
  serviceAreaUsageFees: {
    delivery: 'דמי משלוח',
    selfPickup: 'דמי הכנת הזמנה',
  },
  orderModeIndicator: {
    edit: 'מצב עריכת הזמנה מס׳ {{id}}',
    addSubscription: 'מצב יצירת מנוי',
    editSubscription: 'מצב עריכת מנוי קיים',
    exit: 'יציאה',
  },
  aboutPage: {
    aboutTitle: 'אודותינו',
    openHours: 'שעות פתיחה של החנות',
    regularlyTitle: 'קונים אצלנו בקבוע?',
    regularlySubtitleOne: 'בוחרים תדירות ומקבלים את ההזמנה הקבועה ישר הביתה!',
    regularlySubtitleTwo:
      'יומיים לפני שההזמנה תגיע, תקבלו תזכורת לנייד ולמייל ותוכלו לעדכן את תכולת ההזמנה או לבטל אותה.',
    regularlyBtn: 'יצירת מנוי',
    divisionalTitle: 'אזורי חלוקה',
    divisionalSubtitle: 'בדקו שהמשלוחים שלנו מגיעים עד אליכם',
    contactTitle: 'צרו קשר',
    openingHours: 'שעות פתיחה',
    closed: 'סגור',
    moreDetails: 'פרטים נוספים',
    check: 'בדיקה',
    checkAgain: 'בדיקה חוזרת',
    citySearchDescription: 'הזינו שם יישוב על מנת לבדוק האם אנו מבצעים משלוחים לכתובתכם',
    followUs: 'עקבו אחרינו',
  },
  discount: {
    payment: 'קרדיט לחשבון',
    creditAtCheckoutDescription:
      'מימוש ההנחה הינו חד פעמי ולא ניתן לממש אותו באופן חלקי ו/או בשיעורים',
    deliveryUsageFees: 'הנחת דמי משלוח',
    selfPickupUsageFees: 'הנחת דמי הכנה',
    storePromotion: 'מבצע',
    storeCoupon: 'קופון',
    clientCoupon: 'זיכוי',
    couponDiscount: 'הנחת קופון',
    generalDiscount: 'הנחה כללית',
    itemRefund: 'זיכוי מוצר',
    itemForFree: 'מוצר במתנה',
    forOrders: {
      delivery: 'בהזמנת משלוח',
      selfPickup: 'בהזמנת איסוף עצמי',
    },
    valid: 'תקף',
    days: 'לימים',
    maxQuantityUsage: {
      forNonWeighableAboveQuantity: 'מוגבל ל-{{maxQuantity}} מימושים',
      forWeighableBetweenQuantity: 'מוגבל ל-{{maxQuantity}} ק״ג',
    },
    validUntil: 'בתוקף עד',
    notValidInSelectedDay: 'חלק מהמבצעים בעגלה אינם בתוקף ליום האספקה שנבחר ולכן הוסרו מההזמנה',
  },
  onClientBehalf: {
    storeSide: 'צד חנות',
  },
  ageValidation: {
    ageValidation: 'הגבלת גיל',
    orderContainAgeLimitedItems: 'ההזמנה שלך מכילה מוצרים המוגבלים למכירה מעל גיל 18 בלבד ע״פ חוק',
    ageConformation: 'אני מאשר.ת כי אני מעל גיל 18, בעת המסירה אדרש להציג תעודה מזהה',
  },
  incentivePromotion: {
    tooltip: {
      redeemRefund: 'למימוש ההנחה יש להוסיף את ה{{productName}} לסל הקניות',
    },
    generatedText: {
      forCartEstimationBeforePreparation: {
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'הוסיפו עוד ₪{{discountShortage}} לקבלת משלוח חינם',
            partialDiscount:
              'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה על דמי משלוח',
          },
          delivery: {
            fullDiscount: 'הוסיפו עוד ₪{{discountShortage}} לקבלת משלוח חינם',
            partialDiscount:
              'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה על דמי משלוח',
          },
          selfPickup: {
            fullDiscount: 'הוסיפו עוד ₪{{discountShortage}} לקבלת דמי הכנה בחינם',
            partialDiscount:
              'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה על דמי הכנה',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה',
            minimumOrder: 'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה',
          },
          delivery: {
            smallMinimumOrder: 'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה',
            minimumOrder: 'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה',
          },
          selfPickup: {
            smallMinimumOrder: 'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה',
            minimumOrder: 'הוסיפו עוד ₪{{discountShortage}} לקבלת {{discountValue}} הנחה',
          },
        },
        itemForFree: {
          all: 'הוסיפו עוד ₪{{discountShortage}} לקבלת {{-sellingUnitName}} {{-storeProductSourceName}} {{valueFormulaSourceQuantity}} מתנה',
          delivery:
            'הוסיפו עוד ₪{{discountShortage}} לקבלת {{-sellingUnitName}} {{-storeProductSourceName}} {{valueFormulaSourceQuantity}} מתנה',
          selfPickup:
            'הוסיפו עוד ₪{{discountShortage}} לקבלת {{-sellingUnitName}} {{-storeProductSourceName}} {{valueFormulaSourceQuantity}} מתנה',
        },
        itemRefund: {
          all: 'הוסיפו עוד  ₪{{discountShortage}} לקבלת {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}}',
          delivery:
            'הוסיפו עוד  ₪{{discountShortage}} לקבלת {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}}',
          selfPickup:
            'הוסיפו עוד  ₪{{discountShortage}} לקבלת {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}}',
        },
      },
    },
    productsParticipatingSale: 'מוצרים המשתתפים במבצע',
    seeParticipationProducts: 'למוצרים המשתתפים במבצע',
  },
  promotionsBanner: {
    validForDays: 'תקף לימים',
    until: 'עד',
    validUntil: 'בתוקף עד',
    tooltip: {
      readMore: 'פרטים נוספים',
      forCartEstimationBeforePreparation:
        'ההנחה תחושב רק ע״פ שערוך הסל המופיע באתר, ללא תלות בסכום החיוב הסופי, אשר נקבע לאחר שקילת ואספקת המוצרים.',
      forCartEstimationAfterPreparation:
        'ההנחה תתקבל במעמד החיוב, לאחר שקילת הפריטים ורק במידה וסכום החיוב הסופי יעמוד בתנאים. ההנחה תופיע בחשבונית מס\\קבלה.',
      forSubscription:
        'ההנחה למנויים תתקבל במעמד החיוב, לאחר שקילת הפריטים ואספקתם. ההנחה תופיע בחשבונית מס\\קבלה',
      forNewSubscription:
        'ההנחה למנויים תתקבל במעמד החיוב, לאחר שקילת הפריטים ואספקתם. ההנחה תופיע בחשבונית מס\\קבלה',
    },
    maxQuantityPerOrder: 'מוגבל ל-{{valueFormulaSourceQuantity}} {{-unitName}}',
    generatedText: {
      forCartEstimationBeforePreparation: {
        itemForFree: {
          all: '{{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנה מעל  ₪{{minimumOrder}}',
          delivery:
            '{{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנת משלוח מעל  ₪{{minimumOrder}}',
          selfPickup:
            '{{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנת איסוף עצמי מעל  ₪{{minimumOrder}}',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'משלוח חינם בהזמנה מעל ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} הנחה על דמי משלוח בהזמנה מעל ₪{{minimumOrder}}',
          },
          delivery: {
            fullDiscount: 'משלוח חינם בהזמנה מעל ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} הנחה על דמי משלוח בהזמנה מעל ₪{{minimumOrder}}',
          },
          selfPickup: {
            fullDiscount: 'דמי הכנה עלינו בהזמנת איסוף עצמי מעל ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} הנחה על דמי הכנה בהזמנה מעל ₪{{minimumOrder}}',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: '{{discountValue}} הנחה על כל האתר',
            minimumOrder: '{{discountValue}} הנחה על כל האתר בהזמנה מעל ₪{{minimumOrder}}',
          },
          delivery: {
            smallMinimumOrder: '{{discountValue}} הנחה על כל האתר בהזמנת משלוח',
            minimumOrder: '{{discountValue}} הנחה על כל האתר בהזמנת משלוח מעל ₪{{minimumOrder}}',
          },
          selfPickup: {
            smallMinimumOrder: ' {{discountValue}} הנחה על כל האתר בהזמנת איסוף עצמי',
            minimumOrder:
              ' {{discountValue}} הנחה על כל האתר בהזמנת איסוף עצמי מעל ₪{{minimumOrder}}',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה מעל ₪{{minimumOrder}}',
          delivery:
            '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנת משלוח מעל ₪{{minimumOrder}}',
          selfPickup:
            '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנת איסוף עצמי מעל ₪{{minimumOrder}}',
        },
      },
      forCartEstimationAfterPreparation: {
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'משלוח חינם בהזמנה מעל ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} הנחה על דמי משלוח בהזמנה מעל ₪{{minimumOrder}}',
          },
          delivery: {
            fullDiscount: 'משלוח חינם בהזמנה מעל ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} הנחה על דמי משלוח בהזמנה מעל ₪{{minimumOrder}}',
          },
          selfPickup: {
            fullDiscount: 'דמי הכנה עלינו בהזמנת איסוף עצמי מעל ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} הנחה על דמי הכנה בהזמנה מעל ₪{{minimumOrder}}',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: '{{discountValue}} הנחה על כל האתר',
            minimumOrder: '{{discountValue}} הנחה על כל האתר בהזמנה מעל ₪{{minimumOrder}}',
          },
          delivery: {
            smallMinimumOrder: '{{discountValue}} הנחה על כל האתר בהזמנת משלוח',
            minimumOrder: '{{discountValue}} הנחה על כל האתר בהזמנת משלוח מעל ₪{{minimumOrder}}',
          },
          selfPickup: {
            smallMinimumOrder: ' {{discountValue}} הנחה על כל האתר בהזמנת איסוף עצמי',
            minimumOrder:
              ' {{discountValue}} הנחה על כל האתר בהזמנת איסוף עצמי מעל ₪{{minimumOrder}}',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה מעל ₪{{minimumOrder}}',
          delivery:
            '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנת משלוח מעל ₪{{minimumOrder}}',
          selfPickup:
            '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנת איסוף עצמי מעל ₪{{minimumOrder}}',
        },
      },
      forNewCustomer: {
        itemForFree: {
          all: '{{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנה ראשונה באתר',
          delivery:
            '{{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנה ראשונה באתר מסוג משלוח',
          selfPickup:
            '{{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנה ראשונה באתר מסוג איסוף עצמי',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'משלוח חינם בהזמנה ראשונה באתר',
            partialDiscount: '{{discountValue}} הנחה על דמי המשלוח בהזמנה ראשונה באתר',
          },
          delivery: {
            fullDiscount: 'משלוח חינם בהזמנה ראשונה באתר',
            partialDiscount: 'הנחה על דמי המשלוח בהזמנה ראשונה באתר {{discountValue}}',
          },
          selfPickup: {
            fullDiscount: 'דמי הכנה עלינו בהזמנה ראשונה באתר',
            partialDiscount: '{{discountValue}} הנחה על דמי הכנה בהזמנה ראשונה באתר',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: '{{discountValue}} הנחה בהזמנה ראשונה באתר',
          },
          delivery: {
            smallMinimumOrder: '{{discountValue}} הנחה בהזמנה ראשונה באתר מסוג משלוח',
          },
          selfPickup: {
            smallMinimumOrder: '{{discountValue}} הנחה בהזמנה ראשונה באתר מסוג איסוף עצמי',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה ראשונה באתר',
          delivery:
            '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה ראשונה באתר מסוג משלוח',
          selfPickup:
            '{{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה ראשונה באתר מסוג איסוף עצמי',
        },
      },
      forSubscription: {
        itemForFree: {
          all: 'מבצע למנויים בלבד! {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בכל הזמנה',
          delivery:
            'מבצע למנויים בלבד! {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנת משלוח',
          selfPickup:
            'מבצע למנויים בלבד! {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנת איסוף עצמי',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'מבצע למנויים בלבד! משלוח חינם בכל הזמנה',
            partialDiscount: 'מבצע למנויים בלבד! {{discountValue}} הנחה על דמי המשלוח בכל הזמנה',
          },
          delivery: {
            fullDiscount: 'מבצע למנויים בלבד! משלוח חינם בכל הזמנה',
            partialDiscount: 'מבצע למנויים בלבד! {{discountValue}} הנחה על דמי המשלוח בכל הזמנה',
          },
          selfPickup: {
            fullDiscount: 'מבצע למנויים בלבד! דמי הכנה עלינו בהזמנת איסוף עצמי',
            partialDiscount:
              'מבצע למנויים בלבד! {{discountValue}} הנחה על דמי הכנה בהזמנת איסוף עצמי',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'מבצע למנויים בלבד! {{discountValue}} הנחה בכל הזמנה',
          },
          delivery: {
            smallMinimumOrder: 'מבצע למנויים בלבד! {{discountValue}} הנחה בהזמנת משלוח',
          },
          selfPickup: {
            smallMinimumOrder: 'מבצע למנויים בלבד! {{discountValue}} הנחה בהזמנת איסוף עצמי',
          },
        },
        itemRefund: {
          all: 'מבצע למנויים בלבד! {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בכל הזמנה',
          delivery:
            'מבצע למנויים בלבד! {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנת משלוח',
          selfPickup:
            'מבצע למנויים בלבד! {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנת  איסוף עצמי',
        },
      },
      forNewSubscription: {
        itemForFree: {
          all: 'מבצע למנויים חדשים! {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנה ראשונה מהמנוי',
          delivery:
            'מבצע למנויים חדשים! {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנה ראשונה מסוג משלוח',
          selfPickup:
            'מבצע למנויים חדשים! {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} מתנה בהזמנה ראשונה מסוג איסוף עצמי',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'מבצע למנויים חדשים! משלוח חינם בהזמנה ראשונה מהמנוי',
            partialDiscount:
              'מבצע למנויים חדשים! {{discountValue}} הנחה על דמי המשלוח בהזמנה ראשונה מהמנוי',
          },
          delivery: {
            fullDiscount: 'מבצע למנויים חדשים! משלוח חינם בהזמנה ראשונה מהמנוי',
            partialDiscount:
              'מבצע למנויים חדשים! {{discountValue}} הנחה על דמי המשלוח בהזמנה ראשונה מהמנוי',
          },
          selfPickup: {
            fullDiscount: 'מבצע למנויים חדשים! דמי הכנה עלינו בהזמנה ראשונה מהמנוי',
            partialDiscount:
              'מבצע למנויים חדשים! {{discountValue}} הנחה על דמי הכנה בהזמנה ראשונה מהמנוי',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'מבצע למנויים חדשים! {{discountValue}} הנחה בהזמנה ראשונה מהמנוי',
          },
          delivery: {
            smallMinimumOrder:
              'מבצע למנויים חדשים! {{discountValue}} הנחה בהזמנה ראשונה מסוג משלוח',
          },
          selfPickup: {
            smallMinimumOrder:
              'מבצע למנויים חדשים! {{discountValue}} הנחה בהזמנה ראשונה מסוג איסוף עצמי',
          },
        },
        itemRefund: {
          all: 'מבצע למנויים חדשים! {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה ראשונה מהמנוי',
          delivery:
            'מבצע למנויים חדשים! {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה ראשונה מסוג משלוח',
          selfPickup:
            'מבצע למנויים חדשים! {{-storeProductSourceName}} ב₪{{productPromotedPrice}} ל{{-primaryQuantityUnit}} בהזמנה ראשונה מסוג איסוף עצמי',
        },
      },
    },
  },
  pwa: {
    installerPromptText: 'הוסיפו אותנו כאפליקציה למסך הבית שלכם',
    iosPromptTitle: 'הוספה למסך הבית',
    iosPromptDescription:
      'לחווית קניה מהירה יותר, הוסיפו את ״{{-storeName}}״ למסך הבית שלכם ב-2 צעדים פשוטים',
    iosPromptStep1: '1. לחצו על כפתור ה״שיתוף״ בדפדפן שלכם',
    iosPromptStep2: '2. לחצו על כפתור ״הוספה למסך הבית״',
  },
  seo: {
    products: 'מוצרים',
    genericCategoryMetadata:
      'אנו ב{{websiteName}} מציעים מגוון רחב של {{categoryName}} באיכות ללא תחרות - ',
    andMore: 'ועוד.',
    similarProducts: 'מוצרים דומים:',
  },
  serverGenericErrorDescription: 'שגיאת שרת פנימית',
  subCatalog: {
    catalogWasUpdated: 'שימו לב! הקטלוג עודכן',
  },
  homePage: {
    toAllProducts: 'לכל המוצרים',
    previewMode: 'מצב תצוגה מקדימה',
  },
  membership: {
    youEarnedPoints: 'ברשותך {{pointsNumber}} נק׳ במועדון',
    youHaveBenefits: 'ברשותך {{benefitsNumber}} הטבות במועדון',
    forAllBenefits: 'לכל ההטבות',
    customerClub: 'מועדון לקוחות',
    customerClubDesktop: 'הטבות מועדון לקוחות',
    memberNumber: 'מס׳ חבר {{memberNumber}}',
    points: 'נקודות',
    equalToShekel: 'שווה ל {{equalNumber}} שקלים',
    benefits: 'הטבות',
    redeemPoints: 'מימוש נקודות',
    use: 'מימוש',
    remove: 'הסרה',
    errorValueIsMoreThanPoints: 'הערך לא יכול להיות גבוה מסך הנקודות שברשותך',
    redeemedPointsToShekel: 'מימוש {{points}} נקודות = ₪{{points}}',
    calculatedByExternalCompany:
      'ההנחות וההטבות מחושבות ע"י פלטפורמה חיצונית, ויבואו לידי ביטוי בחשבונית המס לאחר הכנת ההזמנה.',
    validUntil: 'בתוקף עד {{expirationDate}}',
    usedAmountOfPoints: 'מימוש {{points}} נקודות',
    membershipClubDiscount: 'הנחות מועדון לקוחות',
  },
  smallEC: {
    promotionsAndDiscounts: 'הנחות ומבצעים',
    chooseFromTheList: 'בחרו מתוך הרשימה',
    addProducts: 'הוסיפו מוצרים',
    pickupPointNotAvailable: 'מצטערים, מועד האספקה אינו זמין יותר. אנא בחרו מועד חדש',
  },
};

export default hebrewMessages;

import { TOrderDetailsReducerState } from 'store';
import { TOrderDiscount, TOrderDiscountState } from 'store/modules/orderDiscounts';
import { IPromotion } from 'store/modules/promotions';
import { IStoreProduct } from 'store/modules/storeProduct';
import { findRelevantOrderDiscountOrCreateNew } from 'utils/helpers/promotions/promotionsCalculator';

/* eslint-disable no-param-reassign */
export function forNonWeighableAboveQuantityProductAdded(
  storeProduct: IStoreProduct,
  quantityAdded: number,
  cartIndex: number,
  promotion: IPromotion,
  orderDiscount: TOrderDiscount,
  orderDiscounts: TOrderDiscount[],
  orderDetails: TOrderDetailsReducerState,
): void {
  if (promotion.parameters && promotion.parameters.maxQuantity) {
    const totalAddedQuantityFromThisTypeOfPromotion = getTotalAddedQuantityFromThisTypeOfPromotion(
      orderDiscounts,
      promotion,
    );

    if (totalAddedQuantityFromThisTypeOfPromotion >= promotion.parameters.maxQuantity) {
      orderDiscount.state = TOrderDiscountState.Ineligible;
      return;
    }
  }

  const requiredQuantity = promotion?.parameters?.requiredQuantity || 0;

  if (orderDiscount.accumulatedQuantity + quantityAdded <= requiredQuantity) {
    orderDiscount.accumulatedQuantity += quantityAdded;
    orderDiscount.accumulatedProducts.push({
      storeProduct,
      quantity: quantityAdded,
      cartIndex,
    });

    if (orderDiscount.accumulatedQuantity === requiredQuantity) {
      orderDiscount.state = TOrderDiscountState.Active;
      orderDiscount.cartIndex = orderDiscount.accumulatedProducts.reduce(
        (maxCartIndex, accumulatedProduct) => Math.max(maxCartIndex, accumulatedProduct.cartIndex),
        0,
      );
      let originalTotal = 0;

      orderDiscount.accumulatedProducts.forEach((accumulatedProduct) => {
        originalTotal += accumulatedProduct.storeProduct.price * accumulatedProduct.quantity;
      });

      const newTotalPrice = promotion.parameters.newTotalPrice || 0;
      orderDiscount.totalParticipatingProductsPrice = newTotalPrice;
      orderDiscount.totalPrice = newTotalPrice - originalTotal;
    }

    return;
  }

  // quantity is large, need to split it
  const quantityToAddToCurrentPromotionInstance =
    requiredQuantity - orderDiscount.accumulatedQuantity;
  let quantityToAddToNextPromotionInstance =
    quantityAdded - quantityToAddToCurrentPromotionInstance;

  forNonWeighableAboveQuantityProductAdded(
    storeProduct,
    quantityToAddToCurrentPromotionInstance,
    cartIndex,
    promotion,
    orderDiscount,
    orderDiscounts,
    orderDetails,
  );

  const totalAddedQuantityFromThisTypeOfPromotion = getTotalAddedQuantityFromThisTypeOfPromotion(
    orderDiscounts,
    promotion,
  );

  if (
    promotion.parameters &&
    promotion.parameters.maxQuantity &&
    totalAddedQuantityFromThisTypeOfPromotion + quantityToAddToNextPromotionInstance >
      promotion.parameters.maxQuantity
  ) {
    quantityToAddToNextPromotionInstance =
      promotion.parameters.maxQuantity - totalAddedQuantityFromThisTypeOfPromotion;
  }

  if (quantityToAddToNextPromotionInstance > 0) {
    const nextPromotionInstance = findRelevantOrderDiscountOrCreateNew(
      storeProduct,
      promotion,
      orderDiscount.cartIndex,
      orderDiscounts,
      orderDetails,
    );

    if (!nextPromotionInstance) {
      return;
    }

    forNonWeighableAboveQuantityProductAdded(
      storeProduct,
      quantityToAddToNextPromotionInstance,
      cartIndex,
      promotion,
      nextPromotionInstance,
      orderDiscounts,
      orderDetails,
    );
  }
}

function getTotalAddedQuantityFromThisTypeOfPromotion(
  orderDiscounts: TOrderDiscount[],
  promotion: IPromotion,
): number {
  return orderDiscounts
    .filter((discount) => discount.promotion === promotion.nonObfuscatedId)
    .reduce((totalOfDiscount, discount) => totalOfDiscount + discount.accumulatedQuantity, 0);
}

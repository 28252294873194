import React, { FC, useCallback, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton, InputAdornment, OutlinedInput, OutlinedInputProps } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';

import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import useStyles from './styles';

import { ISearch } from './types';

const Search: FC<ISearch> = ({
  placeholder = 'input.search',
  onChange,
  customPlaceholder,
  ...rest
}) => {
  const {
    searchContext: { searchInputValue, handleChange, handleClear },
  } = useContext(MainLayoutContext);

  const classes = useStyles();
  const { t } = useTranslation();

  const handleSearchInputChange = useCallback<Required<OutlinedInputProps>['onChange']>(
    (e) => {
      if (window.scrollY > 302 && e.target.value.length === 1) {
        // TODO check if we can use setTimeout / requestAnimationFrame
        window.scrollTo({ top: 302, behavior: 'smooth' });
      }
      handleChange(e);

      if (onChange) {
        onChange(e);
      }
    },
    [handleChange, onChange],
  );

  // if we do not need translate placeholder use customPlaceholder
  const searchPlaceholder = customPlaceholder || t(placeholder);

  return (
    <OutlinedInput
      value={searchInputValue}
      onChange={handleSearchInputChange}
      fullWidth
      placeholder={searchPlaceholder}
      classes={{
        root: classes.root,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
        input: classes.input,
      }}
      startAdornment={
        <InputAdornment position="start">
          <Icon color="action" name="icon-search" fontSize="inherit" />
        </InputAdornment>
      }
      endAdornment={
        <>
          {searchInputValue && (
            <InputAdornment position="start" classes={{ root: classes.closeRoot }}>
              <IconButton
                aria-label={t('shoppingPage.cleanSearch')}
                classes={{ root: classes.closeButtonRoot }}
                onClick={handleClear}
              >
                <Icon color="action" name="icon-button-x" fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          )}
        </>
      }
      {...rest}
    />
  );
};

export default Search;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesBanner } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesBanner, { isOpenedTopCart: boolean }> =>
    createStyles({
      bannerWrapper: {
        margin: '0 auto',
        padding: '0px 14px',
        [theme.breakpoints.up('md')]: {
          padding: '0px',
          maxWidth: '1240px',
        },
      },
      bannerSwiperWrapper: {
        '& > .swiper': {
          borderRadius: '20px',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: ({ isOpenedTopCart }) => (!isOpenedTopCart ? '100%' : 898),
        },
      },
      bannerImage: {
        minHeight: '360px',
        borderRadius: '12px',
        [theme.breakpoints.up('md')]: {
          minWidth: '898px',
          minHeight: '350px',
          maxWidth: '1240px',
          maxHeight: '450px',
          borderRadius: '20px',
        },
      },
      pagination: {
        alignItems: 'center',
        width: theme.spacing(1),
        height: theme.spacing(1),
        opacity: 0.8,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50%',
        marginLeft: theme.spacing(10 / 8),
      },
      clickablePagination: {
        display: 'flex',
        justifyContent: 'center',
        bottom: '15px !important',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          bottom: '24px !important',
        },
      },
      activeBulletPagination: {
        width: theme.spacing(10 / 8),
        height: theme.spacing(10 / 8),
        opacity: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50%',
      },
    }),
);

import { TDialogReducer } from './types';

import { HIDE_DIALOG, SHOW_DIALOG } from './constants';

import { initialDialogState } from './initialState';

const dialogReducer: TDialogReducer = (state = initialDialogState, action) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        ...initialDialogState, // to prevent passing props from previously shown dialog
        ...action.payload,
        open: true,
      };
    case HIDE_DIALOG:
      return {
        ...initialDialogState,
        open: false,
      };

    default:
      return state;
  }
};

export { dialogReducer };

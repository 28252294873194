// this hook is a simplified version of react-pwa-installer-prompt library https://github.com/shnaveen25/react-pwa-installer-prompt
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsIos, getIsPWAInstallDismissed } from 'store/modules/config/selectors';
import { configActions } from 'store';
import {
  IBeforeInstallPromptEvent,
  INavigator,
  TAppInstalledHandler,
  TBeforeAppInstallPromptHandler,
  TCheckPWAInstallationStatus,
  THandleOnDismiss,
  THandleOnInstall,
  TPWAInstallStatus,
  TUsePWAInstallerPrompt,
} from './types';

const usePWAInstallerPrompt: TUsePWAInstallerPrompt = () => {
  const dispatch = useDispatch();
  const isIOS = useSelector(getIsIos);

  const isPWAInstallDismissed = useSelector(getIsPWAInstallDismissed);

  const initialInstallStatus: TPWAInstallStatus = {
    isInstallAllowed: !isPWAInstallDismissed,
    isInstalling: false,
    isInstallCancelled: false,
    isInstallSuccess: false,
    isInstallFailed: false,
  };

  const [installStatus, setInstallStatus] = useState<TPWAInstallStatus>(initialInstallStatus);
  const [installEvent, setInstallEvent] = useState<Event | null>(null);

  const beforeAppInstallPromptHandler = useCallback<TBeforeAppInstallPromptHandler>(
    (e) => {
      e.preventDefault();
      if (!installStatus.isInstalling) {
        if (!installStatus.isInstallSuccess) {
          setInstallEvent(e);
          if (!installStatus.isInstallAllowed) {
            setInstallStatus((status) => ({
              ...status,
              isInstallAllowed: true,
            }));
          }
        }
      }
    },
    [installStatus.isInstallAllowed, installStatus.isInstallSuccess, installStatus.isInstalling],
  );

  // The appinstalled event is available only on Android devices.
  const appInstalledHandler = useCallback<TAppInstalledHandler>(
    (e) => {
      if (!installStatus.isInstallSuccess) {
        window.removeEventListener('beforeinstallprompt', beforeAppInstallPromptHandler);
        e.preventDefault();
        setInstallStatus((status) => ({
          ...status,
          isInstallSuccess: true,
        }));
        // Update redux config module
        dispatch(configActions.configUpdate({ isPWAInstalled: true }));
      }
    },
    [beforeAppInstallPromptHandler, dispatch, installStatus.isInstallSuccess],
  );

  const handleOnDismiss = useCallback<THandleOnDismiss>(() => {
    setInstallStatus((status) => ({
      ...status,
      isInstallCancelled: true,
      isInstallAllowed: false,
    }));
    dispatch(configActions.configUpdate({ isPWAInstallDismissed: true }));
  }, [dispatch]);

  const handleOnInstall = useCallback<THandleOnInstall>(() => {
    (installEvent as IBeforeInstallPromptEvent)?.prompt();
    (installEvent as IBeforeInstallPromptEvent)?.userChoice
      .then((choiceResult) => {
        if (choiceResult?.outcome === 'accepted') {
          setInstallStatus((status) => ({
            ...status,
            isInstalling: true,
            isInstallAllowed: false,
          }));
        } else {
          setInstallStatus((status) => ({
            ...status,
            isInstallCancelled: true,
            isInstallAllowed: true,
          }));
        }
      })
      .catch(() => {
        setInstallStatus((status) => ({
          ...status,
          isInstallFailed: true,
          isInstallAllowed: true,
        }));
      });
    setInstallEvent(null);
  }, [installEvent]);

  // Update redux 'isPWAInstalled' by 'getInstalledRelatedApps'.
  const checkPWAInstallationStatus = useCallback<TCheckPWAInstallationStatus>(async () => {
    if ('getInstalledRelatedApps' in navigator) {
      const relatedApps = (await (navigator as INavigator).getInstalledRelatedApps?.()) || [];
      if (relatedApps.length > 0) {
        // related PWA was found, update redux state for all devices
        dispatch(configActions.configUpdate({ isPWAInstalled: true }));
      } else if (!isIOS) {
        // related PWA was not found, update redux state for Android
        dispatch(configActions.configUpdate({ isPWAInstalled: false }));
      }
    }
  }, [dispatch, isIOS]);

  useEffect(() => {
    checkPWAInstallationStatus();
  }, [checkPWAInstallationStatus]);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', beforeAppInstallPromptHandler);
    window.addEventListener('appinstalled', appInstalledHandler);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeAppInstallPromptHandler);
      window.removeEventListener('appinstalled', appInstalledHandler);
    };
  }, [appInstalledHandler, beforeAppInstallPromptHandler]);

  return {
    handleOnInstall,
    handleOnDismiss,
  };
};

export default usePWAInstallerPrompt;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { MAX_CONTAINER_WIDTH, MIN_TABLET_WIDTH } from 'ui/common/Container';

import { IMobileHeader, TStylesHeader } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesHeader, IMobileHeader> =>
    createStyles({
      wrapper: {
        height: theme.spacing(7), // 56px
        padding: theme.spacing(10 / 8, 0),
        backgroundColor: ({ isTransparent }) =>
          !isTransparent ? theme.palette.secondary.main : 'transparent',
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      logo: {
        borderRadius: '50%',
        width: '100%',
        height: 'auto',
        marginRight: theme.spacing(0.75), // 6px
      },
      logoWrapper: {
        width: '38px',
        marginRight: theme.spacing(0.75), // 6px
        [theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH)]: {
          marginRight: theme.spacing(0.5), // 4px
        },
      },
      labelSection: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: ({ showHamburgerMenu }) =>
          showHamburgerMenu ? theme.spacing(0, 1) : theme.spacing(0),
      },
      header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      },
      basketSection: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        [theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH)]: {
          marginRight: theme.spacing(20 / 8),
        },
      },
      storeName: {
        lineHeight: `${theme.spacing(18 / 8)}px`,
        fontSize: theme.spacing(18 / 8),
      },
      basketIcon: {
        color: theme.palette.background.paper,
      },
      deliverySelfPickupPopper: {
        height: '100px',
        padding: theme.spacing(13 / 8, 16 / 8, 0, 14 / 8),
      },
    }),
);

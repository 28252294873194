import { Box, IconButton, useTheme } from '@material-ui/core';
import { useCart, useMobile } from 'hooks';
import React, { FC, useCallback, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { PaginationOptions } from 'swiper/types/modules/pagination';
import SwiperClass from 'swiper/types/swiper-class';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import useStyles from './styles';
import {
  IClientCouponNotice,
  TRenderClientCouponNotice,
  TRenderOneOrMoreClientCouponNotices,
} from './types';

const ClientCouponNotice: FC<IClientCouponNotice> = ({ clientCoupons }) => {
  const lang = useSelector(getLang);
  const classes = useStyles({ lang });
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useMobile();
  const { formatDiscountMessage } = useCart();

  const swiper = useRef<SwiperClass | null>(null);

  const swiperWrapper = useRef<HTMLDivElement | null>(null);

  const goNext = useCallback(() => {
    swiper.current?.slideNext();
  }, []);

  const goPrev = useCallback(() => {
    swiper.current?.slidePrev();
  }, []);

  const pagination: PaginationOptions = useMemo(() => {
    return {
      clickable: true,
      renderBullet(_, className) {
        return `<a class="${className}"></a>`;
      },
      bulletClass: classes.pagination,
      bulletActiveClass: classes.activeBulletPagination,
      clickableClass: classes.clickablePagination,
    };
  }, [classes.pagination, classes.activeBulletPagination, classes.clickablePagination]);

  const renderClientCouponNotice = useCallback<TRenderClientCouponNotice>(
    (orderDiscount) => {
      const clientCouponFooterString = formatDiscountMessage(orderDiscount);

      const clientCouponExpirationDate =
        orderDiscount.expirationTime &&
        t('userMenu.clientCoupons.validUntil') + orderDiscount.expirationTime;

      return (
        <Box component="section" className={classes.cardWrapper}>
          <Box className={classes.cardBody}>
            <Box>
              <Typography className={classes.noticeTitle} variant="body1" isTranslate>
                {'userMenu.clientCoupons.clientCoupon'}
              </Typography>
              <Typography className={classes.clientCouponText} variant="body1" isTranslate>
                {orderDiscount.externalNotes}
              </Typography>
            </Box>
            <Icon
              name="icon-refund-2"
              color="secondary"
              classes={{ root: classes.clientCouponIcon }}
            />
          </Box>
          <Box className={classes.cardFooter}>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.clientCouponFooterText}
            >
              {clientCouponFooterString}
            </Typography>

            <Typography variant="body1" color="grey" className={classes.clientCouponExpirationDate}>
              {clientCouponExpirationDate}
            </Typography>
          </Box>
        </Box>
      );
    },
    [
      classes.cardBody,
      classes.cardFooter,
      classes.cardWrapper,
      classes.clientCouponExpirationDate,
      classes.clientCouponFooterText,
      classes.clientCouponIcon,
      classes.clientCouponText,
      classes.noticeTitle,
      formatDiscountMessage,
      t,
    ],
  );

  const renderOneOrMoreClientCouponNotices = useCallback<TRenderOneOrMoreClientCouponNotices>(
    (coupons) => {
      if (coupons.length > 1) {
        if (isMobile) {
          return (
            <Box component="section" className={classes.cardsWrapper}>
              <Swiper
                modules={[Pagination, Navigation]}
                height={220}
                className={classes.swiper}
                pagination={pagination}
                dir={theme.direction}
                spaceBetween={10}
                loop
              >
                {coupons?.map((clientCoupon, index) => (
                  <SwiperSlide dir={theme.direction} key={index}>
                    {renderClientCouponNotice(clientCoupon)}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          );
        }

        return (
          <Box component="section" width={260}>
            <div className={classes.sliderWrapper} ref={swiperWrapper}>
              <IconButton classes={{ root: classes.navigationButtonNext }} onClick={goPrev}>
                <Icon
                  name="icon-arrow-right"
                  color="primary"
                  classes={{ root: classes.iconArrow }}
                />
              </IconButton>
              <Swiper
                modules={[Pagination, Navigation]}
                onInit={(element: SwiperClass) => {
                  swiper.current = element;
                }}
                height={230}
                className={classes.swiper}
                spaceBetween={22}
                loop
              >
                {coupons?.map((clientCoupon, index) => (
                  <SwiperSlide dir={theme.direction} key={index}>
                    {renderClientCouponNotice(clientCoupon)}
                  </SwiperSlide>
                ))}
              </Swiper>
              <IconButton classes={{ root: classes.navigationButtonPrev }} onClick={goNext}>
                <Icon
                  name="icon-arrow-left"
                  color="primary"
                  classes={{ root: classes.iconArrow }}
                />
              </IconButton>
            </div>
          </Box>
        );
      }

      return <Box>{renderClientCouponNotice(coupons[0])}</Box>;
    },
    [
      classes.cardsWrapper,
      classes.iconArrow,
      classes.navigationButtonNext,
      classes.navigationButtonPrev,
      classes.sliderWrapper,
      classes.swiper,
      goNext,
      goPrev,
      isMobile,
      pagination,
      renderClientCouponNotice,
      theme.direction,
    ],
  );

  return renderOneOrMoreClientCouponNotices(clientCoupons);
};

export default ClientCouponNotice;

import { get, omit } from 'lodash';
import { TPaymentMethodForPlaceOrder } from 'components/common/forms/PaymentForm/types';
import { TPlaceOrderPaymentMethodFromUI } from 'store';

const stubPaymentMethodForExternalPaymentForm = {
  // why we need this?
  creditCardNumber: '4580458045804580',
  expirationMonth: '01',
  expirationYear: '99',
  cardHolderID: '999999999',
  cardHolderName: 'John Doe',
  cvv: '123',
};

export const paymentMethodCreator = (
  clientPaymentMethod: TPlaceOrderPaymentMethodFromUI,
): TPaymentMethodForPlaceOrder => {
  const paymentMethodToPost: TPaymentMethodForPlaceOrder = {};

  if (clientPaymentMethod.id) {
    paymentMethodToPost.paymentMethod = {
      id: clientPaymentMethod.id,
    };
  } else if (clientPaymentMethod.formValues) {
    const { expirationDate, ...newCreditCard } = clientPaymentMethod.formValues;

    const [expirationMonth, expirationYear] = expirationDate.split('/');

    paymentMethodToPost.newPaymentMethod = {
      ...omit(
        {
          ...newCreditCard,
          expirationMonth,
          expirationYear,
          creditCardNumber: newCreditCard.creditCardNumber.replace(/\s/g, ''),
        },
        ['is_saveCredit'],
      ),
    };
    paymentMethodToPost.deactivatePaymentMethod = !get(
      clientPaymentMethod.formValues,
      'is_saveCredit',
      false,
    );
  } else if (clientPaymentMethod.externalFormValues) {
    paymentMethodToPost.newPaymentMethod = {
      ...stubPaymentMethodForExternalPaymentForm,
      ...clientPaymentMethod.externalFormValues,
    };
  }

  return paymentMethodToPost;
};

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesPreviousOrder } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPreviousOrder> =>
    createStyles({
      wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: '72px',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(18 / 8, 17 / 8, 18 / 8, 20 / 8),
      },
      typography: {
        lineHeight: '20px',
      },
      watchSection: {},
      infoSection: {
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: theme.spacing(215 / 8),
      },
      countSection: {
        marginRight: '7vw',
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(26 / 8),
        },
      },
      deliverySection: {
        marginRight: '7vw',
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(26 / 8),
        },
      },
      watchIconButtonRoot: {
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        width: '36px',
        height: '36px',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        },
      },
      buttonRipple: {
        backgroundColor: '#000 !important',
        opacity: '0.2 !important',
      },
      price: {
        whiteSpace: 'nowrap',
        maxWidth: theme.spacing(11.25), // 90
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    }),
);

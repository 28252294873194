import { Box } from '@material-ui/core';
import Avatar from 'components/common/Avatar';
import { PersonalDetailsForm } from 'components/common/forms/PersonalDetailsForm';

import { useClientForm, useDialog, useWebsiteDetails } from 'hooks';
import React, { FC, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  DELIVERY_DIALOG,
  SELF_PICKUP_DIALOG,
  shoppingFlowSelectors,
  TUpdatePersonalDetailsSuccessCallback,
  userActions,
} from 'store';

import { completeRegistration } from 'store/modules/analytics/actions';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';

import Typography from 'ui/common/Typography';
import useStyles from './styles';

import { ISignUpDialog } from './types';

const SignUpDialog: FC<ISignUpDialog> = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { defaultValuesForPersonalDetailsForm, onPersonalDetailsSubmit } = useClientForm();

  const knownAddress = useSelector(shoppingFlowSelectors.getKnownAddress);

  const orderDetails = useSelector(getOrderDetails);

  const { showDialog, hideDialog } = useDialog();

  const websiteDetails = useWebsiteDetails();

  useEffect(() => {
    dispatch(userActions.fetchAndUpdateClientDetailsAtStoreLevelAction());
  }, [dispatch]);

  const successCallback = useCallback<TUpdatePersonalDetailsSuccessCallback>(
    (clientProfile) => {
      if (clientProfile) {
        const { firstName, lastName, email, cellPhone } = clientProfile;
        dispatch(completeRegistration({ firstName, lastName, email, cellPhone }));
      }

      if (websiteDetails.websiteSettings.isSmallEC) {
        hideDialog();
        return;
      }

      let dialogToDisplay = websiteDetails.websiteSettings.defaultOrderType;

      if (knownAddress) {
        dialogToDisplay = 'delivery';
      }

      if (orderDetails.selfPickupLocation && orderDetails.selfPickupLocation.name) {
        dialogToDisplay = 'selfPickup';
      }

      if (dialogToDisplay === 'delivery') {
        showDialog({
          dialogType: DELIVERY_DIALOG,
          contentProps: {
            initialView: 'createNewDeliveryAddress',
          },
        });
        return;
      }
      showDialog({
        dialogType: SELF_PICKUP_DIALOG,
      });
    },
    [
      websiteDetails.websiteSettings.isSmallEC,
      websiteDetails.websiteSettings.defaultOrderType,
      knownAddress,
      orderDetails.selfPickupLocation,
      showDialog,
      dispatch,
      hideDialog,
    ],
  );

  return (
    <Box className={classes.root}>
      <Avatar
        src={websiteDetails.logoPath}
        variant="circle"
        classes={{
          root: classes.logo,
          img: classes.logo,
        }}
      />
      <Box className={classes.title}>
        <Typography variant="h2" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.signUp.title'}
        </Typography>
        <Typography
          variant="body1"
          fontSize={16}
          color="grey"
          align="center"
          className={classes.subTitle}
        >
          {'dialog.signUp.subTitle'}
        </Typography>
      </Box>
      <Box className={classes.formRoot}>
        <PersonalDetailsForm
          defaultValues={defaultValuesForPersonalDetailsForm}
          handleSubmit={onPersonalDetailsSubmit(successCallback)}
        />
      </Box>
    </Box>
  );
};

export default SignUpDialog;

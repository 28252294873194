import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesLanguageSwitcher } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLanguageSwitcher> =>
    createStyles({
      languageSwitcherButton: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      },
      selectedLanguageImage: {
        height: '32px',
        width: '32px',
      },
      dropDownRoot: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '6px',
      },
      dropDownIcon: {
        fontSize: '11px',
        color: theme.palette.background.paper,
      },
      selectedLanguageItem: {
        padding: '8px 0',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
      paper: {
        width: ' 160px',
        padding: '20px 0',
        background: theme.palette.grey[50],
        boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.1);',
      },
      langImageMenu: {
        height: '22px',
        width: '22px',
        marginRight: '12px',
      },
      langTitleMenu: {
        fontSize: '16px',
        color: theme.palette.text.secondary,
      },
      langTitleMenuSelected: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        lineHeight: 1.25,
      },
      langMenuItem: {
        padding: '10px 30px',
        '&:hover': {
          background: theme.palette.grey[200],
        },
      },
      menuList: {
        padding: 0,
      },
      buttonRipple: {
        backgroundColor: `${theme.palette.background.paper} !important`,
        opacity: '0.8 !important',
      },
    }),
);

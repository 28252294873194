import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { notifyActions, notifySelectors } from 'store';

import { TNotifyHideCallback, TNotifyShowCallback, TUseNotify } from './types';

const useNotify: TUseNotify = () => {
  const dispatch = useDispatch();

  const NotifyData = useSelector(notifySelectors.getNotify);

  const notifyShow = useCallback<TNotifyShowCallback>(
    (notifyData) => {
      dispatch(notifyActions.showNotification(notifyData));
    },
    [dispatch],
  );

  const notifyHide = useCallback<TNotifyHideCallback>(() => {
    dispatch(notifyActions.hideNotification());
  }, [dispatch]);

  return { notifyShow, notifyHide, NotifyData };
};

export default useNotify;

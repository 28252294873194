import { IPromotion } from 'store/modules/promotions';
import { TOrderDetailsReducerState } from 'store';
import {
  validateOrderType,
  validatePromotionRelevantWeekday,
} from 'utils/helpers/promotions/validators';

export function forCartEstimationBeforePreparation(
  promotion: IPromotion,
  cartEstimation: number,
  orderDetails: TOrderDetailsReducerState,
): boolean {
  const { relevantWeekdays } = promotion.parameters;

  if (
    relevantWeekdays &&
    !validatePromotionRelevantWeekday(relevantWeekdays, orderDetails.preferredDay)
  ) {
    return false;
  }

  if (!validateOrderType(promotion, orderDetails.orderType)) {
    return false;
  }

  return cartEstimation >= (promotion.parameters.minTotalValueBeforePreparation || 0);
}

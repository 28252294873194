import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useRenderPrice } from 'hooks';

import { Box } from '@material-ui/core';
import Typography from 'ui/common/Typography';

import { TPricePerOneHundredGram, TPricePerUnit, TUseProductPrice } from './types';

const useProductPrice: TUseProductPrice = () => {
  const renderPrice = useRenderPrice();

  const { t } = useTranslation();

  const pricePerUnit = useCallback<TPricePerUnit>(
    (price, pricingSellingUnitName, priceClassName, sellingUnitClassName) => {
      return (
        <>
          <Typography className={priceClassName} isTranslate={false}>
            {renderPrice(price)}
          </Typography>
          <Typography className={sellingUnitClassName} isTranslate={false}>
            {'/'}
            {pricingSellingUnitName}
          </Typography>
        </>
      );
    },
    [renderPrice],
  );

  const pricePerOneHundredGram = useCallback<TPricePerOneHundredGram>(
    (estimatedUnitWeight, productPrice, liquid, pricePerGramClassName) => {
      if (estimatedUnitWeight) {
        const actualPrice = ((productPrice * 0.1) / estimatedUnitWeight).toFixed(2);
        const label = liquid
          ? t('storeProductCard.pricePerOneHundredMl', { price: actualPrice })
          : t('storeProductCard.pricePerOneHundredGram', { price: actualPrice });

        return <Box className={pricePerGramClassName}>{label}</Box>;
      }
    },
    [t],
  );

  return { pricePerUnit, pricePerOneHundredGram };
};

export default useProductPrice;

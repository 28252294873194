import { useCallback } from 'react';

import { format, getDay, isToday, isTomorrow, parse } from 'date-fns';
import i18next from 'i18next';
import { weekDayArray, weekdayShortName } from './constants';

import { TRenderDay, TRenderWeekdaysShortNames, TUseRenderDate } from './types';

const useRenderDate: TUseRenderDate = () => {
  // TODO maybe use t and return an already translated version
  const renderDay = useCallback<TRenderDay>((dayNumber, dayCameFromTheServer = false) => {
    // day from the server come in a format different from js. Server: Sunday day #1, JS: Sunday #0
    let day = weekDayArray[dayCameFromTheServer && dayNumber === 7 ? 0 : dayNumber];

    // return empty string of day not exist in weekDayArray
    if (!day) {
      // eslint-disable-next-line no-console
      console.error('dayNumber should be from 0 to 6');

      day = '';
    }

    return day;
  }, []);

  const renderDayShortName = useCallback<TRenderDay>((dayNumber, dayCameFromTheServer = false) => {
    // day from the server come in a format different from js. Server: Sunday day #1, JS: Sunday #0
    let day = weekdayShortName[dayCameFromTheServer && dayNumber === 7 ? 0 : dayNumber];

    // return empty string of day not exist in weekDayArray
    if (!day) {
      // eslint-disable-next-line no-console
      console.error('dayNumber should be from 0 to 6');

      day = '';
    }

    return day;
  }, []);

  const renderDateAndWeekday = useCallback(
    (date, existingDateFormat, expectedDateFormat, isDisplayTodayAndTomorrow) => {
      const parsedDate = parse(date, existingDateFormat, new Date());

      if (isDisplayTodayAndTomorrow && isToday(parsedDate)) {
        return i18next.t('days.today');
      }
      if (isDisplayTodayAndTomorrow && isTomorrow(parsedDate)) {
        return i18next.t('days.tomorrow');
      }

      return expectedDateFormat
        ? `${i18next.t(renderDay(getDay(parsedDate)))}, ${format(parsedDate, expectedDateFormat)}`
        : `${i18next.t(renderDay(getDay(parsedDate)))}`;
    },
    [renderDay],
  );

  const renderWeekdaysShortNames = useCallback<TRenderWeekdaysShortNames>((relevantWeekdays) => {
    return relevantWeekdays
      .map((weekday) => (weekday.id === 7 ? 0 : weekday.id))
      .sort((a, b) => a - b)
      .map((weekday) => i18next.t(weekdayShortName[weekday]))
      .join(', ');
  }, []);

  return {
    renderDay,
    renderDateAndWeekday,
    renderWeekdayShortNames: renderWeekdaysShortNames,
    renderDayShortName,
  };
};

export default useRenderDate;

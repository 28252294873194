import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { useProduct } from 'hooks';
import { getLang } from 'store/modules/config/selectors';

import { Box } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import ItemPromotionCart from 'ui/common/ItemPromotionCart';
import PromotionLimitationsDescription from 'ui/common/Tooltip/PromotionLimitationsDescription';
import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { IItemsPromotionDialog } from './types';

const ItemsPromotionDialog: FC<IItemsPromotionDialog> = ({ promotion }) => {
  const lang = useSelector(getLang);

  const { getDisplayableStoreProduct } = useProduct();

  const classes = useStyles({ lang });

  const header = useMemo(() => {
    return (
      <Box className={classes.header}>
        <Box className={classes.headerTop}>
          <Box display="flex" mb={6 / 8}>
            <Icon name="icon-discount" color="action" classes={{ root: classes.discountIcon }} />
            <Typography color="light" variant="h3">
              {promotion?.shortName}
            </Typography>
          </Box>
          <Typography color="light" variant="body1" fontSize={16}>
            {promotion.externalNotes}
          </Typography>
        </Box>
        <Box>
          <PromotionLimitationsDescription
            promotion={promotion}
            customClasses={{
              limitationMainPart: classes.limitationMainPart,
              limitationTypography: classNames(classes.limitationTypography, 'font-family-rubik'),
            }}
          />
        </Box>
      </Box>
    );
  }, [
    classes.discountIcon,
    classes.header,
    classes.headerTop,
    classes.limitationMainPart,
    classes.limitationTypography,
    promotion,
  ]);

  const body = useMemo(() => {
    return (
      <Box className={classes.mainPartWrapper}>
        <Typography variant="h4" color="secondary">
          {'incentivePromotion.productsParticipatingSale'}
        </Typography>
        {promotion.parameters.participatingProducts?.map((participatingProduct) => {
          const storeProduct = getDisplayableStoreProduct(participatingProduct.id);

          if (!storeProduct) return;
          return (
            <Box margin="12px 0px" key={participatingProduct.id}>
              <ItemPromotionCart storeProduct={storeProduct} />
            </Box>
          );
        })}
      </Box>
    );
  }, [
    classes.mainPartWrapper,
    getDisplayableStoreProduct,
    promotion.parameters.participatingProducts,
  ]);

  return (
    <Box>
      {header}
      {body}
    </Box>
  );
};

export default ItemsPromotionDialog;

import {
  GET_CLUB_MEMBERSHIP_PROFILE_SUCCESS,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
} from './constants';

import {
  TGetClubMembershipProfileSuccessAction,
  TGetPromotionsRequestAction,
  TGetPromotionsSuccessAction,
} from './types';

const getPromotionsRequestAction: TGetPromotionsRequestAction = (promotionsData) => ({
  type: GET_PROMOTIONS_REQUEST,
  payload: {
    promotions: promotionsData,
  },
});

const getPromotionsSuccessAction: TGetPromotionsSuccessAction = (
  newPromotionsIds,
  newPromotionsById,
  newPromotionsForBanners,
) => ({
  type: GET_PROMOTIONS_SUCCESS,
  payload: {
    promotionsIds: newPromotionsIds,
    promotionsById: newPromotionsById,
    promotionsForBanners: newPromotionsForBanners,
  },
});

const getClubMembershipProfileSuccessAction: TGetClubMembershipProfileSuccessAction = (
  clubMembershipProfile,
) => ({
  type: GET_CLUB_MEMBERSHIP_PROFILE_SUCCESS,
  payload: {
    clubMembershipProfile,
  },
});

export {
  getPromotionsRequestAction,
  getPromotionsSuccessAction,
  getClubMembershipProfileSuccessAction,
};

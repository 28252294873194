import React, { FC, ReactNode, useMemo } from 'react';

import { Box, Divider } from '@material-ui/core';

import Container from 'ui/common/Container';
import Typography from 'ui/common/Typography';
import Icon from 'ui/common/icons/Icon';

import Link from 'ui/common/Link';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useTranslation } from 'react-i18next';
import { useContentPages } from 'hooks/useContentPages';
import { useHeader, useMobile } from 'hooks';
import CustomImageLoader from 'components/common/CustomImageLoader';
import { Skeleton } from '@material-ui/lab';
import useStyles from './styles';
import { IHeaderBigInfo } from './types';

const HeaderBigInfo: FC<IHeaderBigInfo> = ({ storeName, imageUrl }) => {
  const websiteDetails = useWebsiteDetails();

  const { createContentPagesList } = useContentPages();

  const { t } = useTranslation();

  const { isMobile } = useMobile();

  const { shouldDisplayStoreAddress } = useHeader();

  const classes = useStyles({ displayAddress: shouldDisplayStoreAddress });

  const renderContactItems = useMemo<ReactNode>(() => {
    const contentPages = createContentPagesList(false, true);
    return (
      <>
        {!!contentPages.length && !isMobile && (
          <Box display="flex">
            <Box mr={6 / 8}>
              <Icon name="icon-info" color="action" fontSize="small" />
            </Box>
            {contentPages.map((contentPage, i) => {
              return (
                <Link
                  className={classes.contactItem}
                  key={contentPage.id}
                  href={`/${contentPage.type}${
                    contentPage.id
                      ? contentPage.nonObfuscatedId && `/${contentPage.nonObfuscatedId}`
                      : ''
                  }`}
                >
                  <Typography fontSize={14} fontWeight="medium" color="light">
                    {contentPage.name}
                  </Typography>
                  {contentPages.length !== i + 1 && (
                    <Divider className={classes.dividerForContentPage} />
                  )}
                </Link>
              );
            })}
          </Box>
        )}
        {shouldDisplayStoreAddress && (
          <Box component="address" className={classes.contactItem}>
            <Icon name="icon-location-2" color="action" fontSize="small" />
            <Link
              href={`https://maps.google.com?q=${encodeURIComponent(
                websiteDetails.store.businessFullAddressWithCity,
              )}`}
              targetBlank
            >
              <Typography fontSize={14} color="light" className={classes.contactItemText}>
                {websiteDetails.store.businessFullAddressWithCity}
              </Typography>
            </Link>
          </Box>
        )}
      </>
    );
  }, [
    createContentPagesList,
    isMobile,
    shouldDisplayStoreAddress,
    websiteDetails.store.businessFullAddressWithCity,
    classes.contactItem,
    classes.contactItemText,
    classes.dividerForContentPage,
  ]);

  const storeLogo = useMemo(() => {
    return (
      <Link href={'/'}>
        <Box className={classes.logoWrapper}>
          {imageUrl && (
            <CustomImageLoader
              src={imageUrl}
              errorImageSrc="/assets/images/store-logo-on-error.svg"
              alt={t('shoppingPage.goToHomePage', { storeName: websiteDetails.name })}
              layout="fixed"
              width={isMobile ? 130 : 160}
              height={isMobile ? 130 : 160}
              loaderComponent={
                <Skeleton
                  animation="wave"
                  variant="circle"
                  classes={{ root: classes.logoSkeleton }}
                />
              }
              classes={classes.logo}
              priority
              unoptimized
            />
          )}
        </Box>
      </Link>
    );
  }, [
    classes.logo,
    classes.logoSkeleton,
    classes.logoWrapper,
    imageUrl,
    isMobile,
    websiteDetails.name,
    t,
  ]);

  return (
    <Container fixed maxContainerWidth>
      <Box className={classes.wrapper}>
        {storeLogo}
        <Box className={classes.storeDetailWrapper}>
          <Link href={'/'}>
            <Typography
              isTranslate={false}
              variant="h2"
              color="light"
              className={classes.storeName}
            >
              {storeName}
            </Typography>
          </Link>
          <Box mt={1}>{renderContactItems}</Box>
        </Box>
      </Box>
    </Container>
  );
};

export default HeaderBigInfo;

import {
  TOpenOrderForEditingAction,
  TStopOrderEditingAction,
  TValidateAndEditOrderAction,
} from 'store/modules/orderEditing/types';
import {
  OPEN_ORDER_FOR_EDITING,
  STOP_ORDER_EDITING,
  VALIDATE_AND_EDIT_ORDER,
} from 'store/modules/orderEditing/constants';

const openOrderForEditing: TOpenOrderForEditingAction = (order) => ({
  type: OPEN_ORDER_FOR_EDITING,
  payload: { order },
});
const validateAndEditOrder: TValidateAndEditOrderAction = (order, onEditStart) => ({
  type: VALIDATE_AND_EDIT_ORDER,
  payload: { order, onEditStart },
});

const stopOrderEditing: TStopOrderEditingAction = () => ({
  type: STOP_ORDER_EDITING,
});

export { openOrderForEditing, stopOrderEditing, validateAndEditOrder };

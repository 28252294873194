import React, { FC } from 'react';

import classNames from 'classnames';

import { Box, Checkbox } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';

import useStyles from './styles';

import { ICheckBox } from './types';

const CheckBox: FC<ICheckBox> = ({ size = 'medium', ...rest }) => {
  const classes = useStyles({ size, ...rest });

  return (
    <Checkbox
      color="primary"
      icon={<Box component="span" className={classes.icon} />}
      checkedIcon={
        <Icon
          name="icon-check"
          color="action"
          classes={{ root: classNames(classes.icon, classes.checkedIcon) }}
        />
      }
      {...rest}
    />
  );
};

export default CheckBox;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesLocationAutocomplete } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLocationAutocomplete> =>
    createStyles({
      rootOption: {
        display: 'flex',
        width: '100%',

        '&:hover $optionText': {
          color: theme.palette.primary.main,
        },

        '&:hover $icon': {
          color: theme.palette.primary.main,
        },
      },
      rootOptionSelected: {
        '& $optionText': {
          color: theme.palette.primary.main,
        },

        '& $icon': {
          color: theme.palette.primary.main,
        },
      },
      icon: {
        fontSize: theme.spacing(2.25), // 18px
      },
      optionText: {
        marginLeft: theme.spacing(1), // 8px
        letterSpacing: theme.spacing(-0.025), // -0.2px
      },
    }),
);

import { ROUTER_HISTORY_UPDATE } from './constants';

import { TRouterHistoryReducer } from './types';

import { initialRouterHistoryState } from './initialState';

const routerHistoryReducer: TRouterHistoryReducer = (state = initialRouterHistoryState, action) => {
  switch (action.type) {
    case ROUTER_HISTORY_UPDATE:
      return [...state, action.payload];
    default:
      return state;
  }
};

export { routerHistoryReducer };

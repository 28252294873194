import React, { FC, useMemo } from 'react';

import { IRepeat } from './types';

const Repeat: FC<IRepeat> = ({ times, children }) => {
  const items = useMemo(() => {
    const itemsArray = [];

    for (let i = 0; i < times; i += 1) {
      itemsArray.push(children);
    }

    return itemsArray;
  }, [children, times]);

  return <>{items}</>;
};

export default Repeat;

import storage from 'redux-persist/lib/storage';
import { applyMiddleware, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createMigrate, PersistConfig, persistReducer } from 'redux-persist';
import { omit } from 'lodash';

import { TStore, TStoreState } from 'store/types';

import {
  activeOrderTransformer,
  authTransformer,
  catalogTransformer,
  configTransformer,
  shoppingFlowTransformer,
  storeProductTransformer,
  websiteDetailsTransformer,
} from 'store/useStore/persist';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

import { initialAppState } from './initialState';

// redux-persist migrations, need to use when persisted state changed (use version in persistConfig)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const migrations: any = {
  18: () => {
    // added club membership (value card)
    return {};
  },
  17: () => {
    // added updateTime, insertTime, localStorage to cart product
    return {};
  },
  16: () => {
    // added multiple branches options
    return {};
  },
  15: () => {
    // created onlySelfPickup option
    return {};
  },
  14: () => {
    // removed localForage
    return {};
  },
  13: () => {
    // added localForage to be able to saved big data
    return {};
  },
  12: (state: Pick<TStoreState, 'activeOrder'>) => {
    // remove promotion id from storeProduct level when save it to localStorage and changed the order of fetching catalog and promotion
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  11: (state: Pick<TStoreState, 'activeOrder'>) => {
    // remove itemsFromStorage, save all product info inside order item and save catalog in localStorage
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  10: (state: Pick<TStoreState, 'activeOrder'>) => {
    //  added itemsFromStorage field to avoid racing before catalog is fetched
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  9: (state: Pick<TStoreState, 'activeOrder'>) => {
    //  remove limited by age field from cart items
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  8: (state: Pick<TStoreState, 'activeOrder'>) => {
    // added ecoPackaging and itemsSubstitution
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  7: (state: Pick<TStoreState, 'activeOrder'>) => {
    // removed preOrderPromotions
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  6: (state: Pick<TStoreState, 'store'>) => {
    // changed StoreDetails to WebsiteDetails
    const newState = { ...state };
    return omit(newState, 'store');
  },
  5: (state: Pick<TStoreState, 'activeOrder'>) => {
    // changed OrderDiscounts structure (change source to discountSourceType)
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  4: (state: Pick<TStoreState, 'dialog'>) => {
    // removed dialog state from persist
    const newState = { ...state };
    return omit(newState, 'dialog');
  },
  3: (state: Pick<TStoreState, 'activeOrder'>) => {
    // changed TOrderDiscount type (change source to discountSourceType)
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  2: (state: Pick<TStoreState, 'activeOrder'>) => {
    // changed TOrderDiscount type (added discountType)
    const newState = { ...state };
    return omit(newState, 'activeOrder');
  },
  1: (state: Pick<TStoreState, 'activeOrder'>) => {
    // changed orderTime display & saving format
    const newState = { ...state };
    delete newState.activeOrder.orderDetails.orderTime;

    return newState;
  },
};

const persistConfig: PersistConfig<TStoreState> = {
  key: 'rexail',
  storage,
  version: 18,
  whitelist: ['auth', 'activeOrder', 'shoppingFlow', 'config', 'store', 'storeProduct', 'catalog'], // reducers that will be persisted
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [
    authTransformer,
    activeOrderTransformer,
    configTransformer,
    websiteDetailsTransformer,
    storeProductTransformer,
    catalogTransformer,
    shoppingFlowTransformer,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeStore(initialState: any = initialAppState): TStore {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = applyMiddleware(sagaMiddleware);

  const enhancer = composeWithDevTools({})(middlewares);

  const store = createStore(persistedReducer, initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  return store;
}

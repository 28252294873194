import { CardMedia } from '@material-ui/core';
import React, { FC, MouseEvent, TouchEvent, useCallback, useEffect, useRef, useState } from 'react';

import useStyles from './styles';

import { TImageMagnifier, TMagnifier } from './types';

const ImageMagnifier: FC<TImageMagnifier> = ({
  imageUrl,
  imageAlt,
  magnifierRadius = 80,
  style,
}) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [imageUrl]);

  const magnifierRef = useRef<HTMLDivElement>(null);

  const imageRef = useRef<HTMLDivElement>(null);

  const [magnifierPosition, setMagnifierPosition] = useState<TMagnifier>({
    top: 0,
    left: 0,
    offsetX: 0,
    offsetY: 0,
  });

  const [isMagnifierVisible, setIsMagnifierVisible] = useState<boolean>(false);

  const classes = useStyles({
    imageUrl,
    magnifierRadius,
    magnifierPosition,
    isMagnifierVisible,
  });

  const handleMove = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      if (isError) return;

      const image = e.currentTarget;

      const x = e.nativeEvent && e.nativeEvent.offsetX;
      const y = e.nativeEvent && e.nativeEvent.offsetY;

      setMagnifierPosition({
        top: y - magnifierRadius,
        left: x - magnifierRadius,
        offsetX: (x / image.width) * image.naturalWidth - magnifierRadius,
        offsetY: (y / image.height) * image.naturalHeight - magnifierRadius,
      });

      setIsMagnifierVisible(true);
    },
    [isError, magnifierRadius],
  );

  const hideMagnifier = useCallback(() => {
    setIsMagnifierVisible(false);
  }, []);

  const isElementIntersecting = useCallback(
    (element: HTMLElement, target: HTMLElement): boolean => {
      const elementRect = element.getBoundingClientRect();
      const targetRect = target.getBoundingClientRect();

      return (
        elementRect.right > targetRect.left &&
        elementRect.left < targetRect.right &&
        elementRect.bottom > targetRect.top &&
        elementRect.top < targetRect.bottom
      );
    },
    [],
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLImageElement>): void => {
      if (isError) return;

      const image = e.currentTarget;

      const x = e.touches && e.touches[0].clientX - image.getBoundingClientRect().left;
      const y = e.touches && e.touches[0].clientY - image.getBoundingClientRect().top;

      setMagnifierPosition({
        top: y - magnifierRadius,
        left: x - magnifierRadius,
        offsetX: (x / image.width) * image.naturalWidth - magnifierRadius,
        offsetY: (y / image.height) * image.naturalHeight - magnifierRadius,
      });

      setIsMagnifierVisible(true);

      if (magnifierRef.current && imageRef.current) {
        const isIntersecting = isElementIntersecting(magnifierRef.current, imageRef.current);
        if (!isIntersecting) {
          hideMagnifier();
        }
      }
    },
    [hideMagnifier, isElementIntersecting, isError, magnifierRadius],
  );

  useEffect(() => {
    // Disable scrolling on the page when starting to touch image
    imageRef.current?.addEventListener('touchstart', (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <div style={{ position: 'relative', margin: '0 auto' }} ref={imageRef}>
      <CardMedia
        component="img"
        alt={imageAlt}
        image={isError ? '/assets/images/product-image-on-error.svg' : imageUrl}
        onError={() => {
          setIsError(true);
        }}
        onMouseMove={handleMove}
        onMouseLeave={hideMagnifier}
        onTouchMove={handleTouchMove}
        onTouchEnd={hideMagnifier}
        style={{ ...style }}
      />
      <div className={classes.magnifier} ref={magnifierRef} />
    </div>
  );
};

export default ImageMagnifier;

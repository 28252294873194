import React, { FC } from 'react';

import { useMobile } from 'hooks';

import { Box } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { IPromotionTag } from './types';

const PromotionTag: FC<IPromotionTag> = ({ label = 'tag.deal', size = 'regular' }) => {
  const { isMobile } = useMobile();

  const classes = useStyles({ isMobile, size });

  return (
    <Box>
      <Box className={classes.leftBottomTriangle} />
      <Box className={classes.rightBottomTriangle} />
      <Box className={classes.topSection} />
      <Box className={classes.promotionTagWrapper}>
        <Icon name="icon-discount" color="primary" classes={{ root: classes.icon }} />
        <Typography variant="body1" color="light" className={classes.typographyRoot}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default PromotionTag;

import { Box, CardMedia, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';

import { useCart, useHeaderBackButton } from 'hooks';
import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import Container, { MAX_CONTAINER_WIDTH, MIN_TABLET_WIDTH } from 'ui/common/Container';
import CartUniqueItemsCount from 'ui/common/icons/CartUniqueItemsCount';
import Link from 'ui/common/Link';

import Typography from 'ui/common/Typography';
import UserMenu from 'ui/desktop/UserMenu';
import MenuDrawer from '../MenuDrawer';
import { DeliveryPickUpPopUp, DeliverySection, Popper } from './components';

import useStyles from './styles';

import { IMobileHeader, TOnImageError } from './types';

const Header: FC<IMobileHeader> = ({ variant = 'default', ...rest }) => {
  const {
    showHamburgerMenu,
    showUserMenu,
    categories,
    activeCategory,
    imageUrl,
    backButtonlink = {
      href: '/cart',
      as: '/cart',
    },
    deliverySectionProps,
    deliverySelfPickupPopUpProps,
    websiteDetails,
    menuDrawerProps,
    userMenuProps,
    basketCount = 0,
  } = rest;

  const classes = useStyles({
    variant,
    ...rest,
  });

  const { handleContinueToCart } = useCart();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH));

  const { renderBackButton } = useHeaderBackButton(backButtonlink);

  const [isOpenDeliveryPopper, setIsOpenDeliveryPopper] = useState<boolean>(false);

  const renderHeaderItems = useMemo<ReactNode>(() => {
    switch (variant) {
      case 'deliverySelfPickup': {
        return (
          <Popper
            overrideClasses={{ popper: classes.deliverySelfPickupPopper }}
            iconName={deliverySelfPickupPopUpProps?.iconName}
            isOpen={isOpenDeliveryPopper}
            onClick={() => setIsOpenDeliveryPopper(!isOpenDeliveryPopper)}
          >
            {deliverySelfPickupPopUpProps && (
              <DeliveryPickUpPopUp
                {...deliverySelfPickupPopUpProps}
                onClose={() => setIsOpenDeliveryPopper(!isOpenDeliveryPopper)}
              />
            )}
          </Popper>
        );
      }
      case 'checkout': {
        return renderBackButton();
      }
      case 'default': {
        return <DeliverySection {...deliverySectionProps} />;
      }
      default:
        return null;
    }
  }, [
    variant,
    classes.deliverySelfPickupPopper,
    deliverySelfPickupPopUpProps,
    isOpenDeliveryPopper,
    renderBackButton,
    deliverySectionProps,
  ]);

  const onImageError = useCallback<TOnImageError>((e) => {
    (e.target as HTMLImageElement).src = '/assets/images/store-logo-on-error.svg';
  }, []);

  return (
    <>
      <Box className={classes.wrapper}>
        <Container>
          <Box className={classes.content}>
            <Box className={classes.header}>
              {showHamburgerMenu && (
                <MenuDrawer
                  categoryItems={categories}
                  activeCategoryItem={activeCategory}
                  websiteDetails={websiteDetails}
                  {...menuDrawerProps}
                />
              )}
              {!rest.isTransparent && (
                <Box className={classes.labelSection}>
                  <Link href={'/'}>
                    <Box className={classes.logoWrapper}>
                      <CardMedia
                        classes={{ root: classes.logo }}
                        component="img"
                        image={imageUrl}
                        alt={websiteDetails.name}
                        onError={onImageError}
                      />
                    </Box>
                  </Link>
                  <Link href={'/'}>
                    <Typography
                      fontWeight="bold"
                      color="light"
                      variant="h2"
                      className={classes.storeName}
                    >
                      {websiteDetails.name}
                    </Typography>
                  </Link>
                </Box>
              )}
            </Box>
            <Box display="flex" width="fit-content" alignItems="center" maxHeight={36}>
              {isTablet && showUserMenu && (
                <Box className={classes.basketSection}>
                  <CartUniqueItemsCount
                    count={basketCount}
                    iconFontSize="default"
                    textFontSize={10}
                    textColor="secondary"
                    iconClasses={{ root: classNames({ [classes.basketIcon]: !basketCount }) }}
                    onIconClick={handleContinueToCart}
                  />
                </Box>
              )}
              {renderHeaderItems}
              {isTablet && showUserMenu && <UserMenu size="tablet" isHideText {...userMenuProps} />}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Header;

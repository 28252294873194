import {
  TGetDirectionSelector,
  TGetFullCatalogLoadedSelector,
  TGetIsMobileSelector,
  TGetIsPWAInstallDismissed,
  TGetIsPWAInstalled,
  TGetLangSelector,
} from './types';

const getIsMobile: TGetIsMobileSelector = (state) => state.config.isMobile;

const getIsIos: TGetIsMobileSelector = (state) => state.config.isIOS;

const getIsPWAInstallDismissed: TGetIsPWAInstallDismissed = (state) =>
  state.config.isPWAInstallDismissed;

const getIsPWAInstalled: TGetIsPWAInstalled = (state) => state.config.isPWAInstalled;

const getDirection: TGetDirectionSelector = (state) => state.config.dir;

const getLang: TGetLangSelector = (state) => state.config.lang;

const getFullCatalogLoaded: TGetFullCatalogLoadedSelector = (state) =>
  state.config.fullCatalogLoaded;

export {
  getIsMobile,
  getIsIos,
  getIsPWAInstallDismissed,
  getIsPWAInstalled,
  getDirection,
  getLang,
  getFullCatalogLoaded,
};

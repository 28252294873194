import { CONTENT_PAGE_PREVIEW_UPDATE, UI_HOME_PAGE_STYLES_UPDATE } from './constants';

import { THomePageUiStylesUpdateAction, TUpdateContentPagePreview } from './types';

const homePageStylesUpdate: THomePageUiStylesUpdateAction = (newHomePageUiStylesState) => ({
  type: UI_HOME_PAGE_STYLES_UPDATE,
  payload: newHomePageUiStylesState,
});

const updateContentPagePreview: TUpdateContentPagePreview = (isActiveContentPagePreviewMode) => ({
  type: CONTENT_PAGE_PREVIEW_UPDATE,
  payload: isActiveContentPagePreviewMode,
});

export { homePageStylesUpdate, updateContentPagePreview };

import {
  CLEANUP_HALF_DELETED_ITEMS_REQUEST,
  ORDER_TOKEN_UPDATE,
  ORDER_UPDATE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PRODUCT_CART_DELETED_REQUEST,
  PRODUCT_CART_DELETED_SUCCESS,
  SET_ORDER_ITEM_REQUEST,
  SET_ORDER_ITEMS_SUCCESS,
  TOGGLE_PARTLY_REMOVE_PRODUCT_FROM_CART,
  UPDATE_CLIENT_ACTIVITY_TIME,
  UPDATE_ECO_PACKAGING,
  UPDATE_ORDER_COMMENTS,
  UPDATE_ORDER_ITEM_COMMENT_REQUEST,
  UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST,
  UPDATE_ORDER_ITEM_UNIT_REQUEST,
  UPDATE_ORDER_STORE_COUPON_TOKEN,
  UPDATE_SUBSTITUTE_ITEMS,
} from './constants';

import {
  TCleanupHalfDeletedItemsAction,
  TOrderTokenUpdateAction,
  TOrderUpdateAction,
  TPlaceOrderRequestAction,
  TPlaceOrderSuccessAction,
  TProductCartDeletedAction,
  TProductCartDeletedRequestAction,
  TSetOrderItemRequestAction,
  TSetOrderItemsSuccessAction,
  TTogglePartlyRemoveProductFromCartAction,
  TUpdateClientActivityTimeAction,
  TUpdateEcoPackagingAction,
  TUpdateItemsSubstitutionAction,
  TUpdateOrderCommentsAction,
  TUpdateOrderItemCommentRequestAction,
  TUpdateOrderItemSelectedBagItemsRequestAction,
  TUpdateOrderItemUnitRequestAction,
  TUpdateOrderStoreCouponTokenAction,
} from './types';

const orderUpdateAction: TOrderUpdateAction = (newOrder) => ({
  type: ORDER_UPDATE,
  payload: newOrder,
});

const orderTokenUpdateAction: TOrderTokenUpdateAction = (newOrderToken) => ({
  type: ORDER_TOKEN_UPDATE,
  payload: { orderToken: newOrderToken },
});

const setOrderItemRequestAction: TSetOrderItemRequestAction = (
  storeProduct,
  action,
  sourceEvent,
) => ({
  type: SET_ORDER_ITEM_REQUEST,
  payload: { storeProduct, action, sourceEvent },
});

const setOrderItemsSuccessAction: TSetOrderItemsSuccessAction = (
  newItems,
  showPromotionWasAppliedSnackbar,
) => ({
  type: SET_ORDER_ITEMS_SUCCESS,
  payload: { items: newItems, showPromotionWasAppliedSnackbar },
});

const updateOrderItemUnitRequestAction: TUpdateOrderItemUnitRequestAction = (
  storeProductId,
  sellingUnitId,
) => ({
  type: UPDATE_ORDER_ITEM_UNIT_REQUEST,
  payload: { storeProductId, sellingUnitId },
});

const updateOrderCommentsAction: TUpdateOrderCommentsAction = (comments) => ({
  type: UPDATE_ORDER_COMMENTS,
  payload: { comments },
});

const updateItemsSubstitutionAction: TUpdateItemsSubstitutionAction = (itemsSubstitution) => ({
  type: UPDATE_SUBSTITUTE_ITEMS,
  payload: { itemsSubstitution },
});

const updateEcoPackagingAction: TUpdateEcoPackagingAction = (ecoPackaging) => ({
  type: UPDATE_ECO_PACKAGING,
  payload: { ecoPackaging },
});

const orderStoreCouponTokenUpdate: TUpdateOrderStoreCouponTokenAction = (storeCouponToken) => ({
  type: UPDATE_ORDER_STORE_COUPON_TOKEN,
  payload: { storeCouponToken },
});

const updateOrderItemCommentRequestAction: TUpdateOrderItemCommentRequestAction = (
  storeProductId,
  productComment,
) => ({
  type: UPDATE_ORDER_ITEM_COMMENT_REQUEST,
  payload: { storeProductId, productComment },
});

const togglePartlyRemoveProductFromCart: TTogglePartlyRemoveProductFromCartAction = (
  storeProductId,
) => ({
  type: TOGGLE_PARTLY_REMOVE_PRODUCT_FROM_CART,
  payload: { storeProductId },
});

const updateOrderItemSelectedBagItemsRequestAction: TUpdateOrderItemSelectedBagItemsRequestAction =
  (storeProductId, selectedBagItems, sourceEvent) => ({
    type: UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST,
    payload: { storeProductId, selectedBagItems, sourceEvent },
  });

const placeOrderRequestAction: TPlaceOrderRequestAction = (
  cartEstimation,
  paymentMethodType,
  paymentMethod,
  numberOfCreditPayments,
  updateSubscription,
  uiHelpers,
) => ({
  type: PLACE_ORDER_REQUEST,
  payload: {
    cartEstimation,
    paymentMethodType,
    paymentMethod,
    numberOfCreditPayments,
    updateSubscription,
    uiHelpers,
  },
});

const placeOrderSuccessAction: TPlaceOrderSuccessAction = ({
  order,
  orderDiscounts,
  prepareToPlaceOrder,
  defaultOrderType,
}) => ({
  type: PLACE_ORDER_SUCCESS,
  payload: { order, orderDiscounts, prepareToPlaceOrder, defaultOrderType },
});

const productCartDeletedRequestAction: TProductCartDeletedRequestAction = () => ({
  type: PRODUCT_CART_DELETED_REQUEST,
});

const productCartWasDeletedSuccess: TProductCartDeletedAction = () => ({
  type: PRODUCT_CART_DELETED_SUCCESS,
});

const cleanupHalfDeletedItemsAction: TCleanupHalfDeletedItemsAction = () => ({
  type: CLEANUP_HALF_DELETED_ITEMS_REQUEST,
});

const updateClientActivityTimeAction: TUpdateClientActivityTimeAction = () => ({
  type: UPDATE_CLIENT_ACTIVITY_TIME,
});

export {
  orderUpdateAction,
  orderTokenUpdateAction,
  setOrderItemRequestAction,
  setOrderItemsSuccessAction,
  updateOrderItemUnitRequestAction,
  updateOrderCommentsAction,
  orderStoreCouponTokenUpdate,
  updateOrderItemCommentRequestAction,
  togglePartlyRemoveProductFromCart,
  updateOrderItemSelectedBagItemsRequestAction,
  placeOrderRequestAction,
  placeOrderSuccessAction,
  productCartDeletedRequestAction,
  productCartWasDeletedSuccess,
  cleanupHalfDeletedItemsAction,
  updateEcoPackagingAction,
  updateItemsSubstitutionAction,
  updateClientActivityTimeAction,
};

import { TNotifyReducer } from './types';

import { HIDE_NOTIFY, SHOW_NOTIFY } from './constants';

import { initialNotifyState } from './initialState';

const notifyReducer: TNotifyReducer = (state = initialNotifyState, action) => {
  switch (action.type) {
    case SHOW_NOTIFY:
      return {
        ...state,
        open: true,
        type: undefined,
        ...action.payload,
      };
    case HIDE_NOTIFY:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export { notifyReducer };

var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dpgvfoxZUMP1QS2LGKYnm"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/browser';

// // This file is used to track issues and events from the client side
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0, // Determines the rate at which Sentry tracks events and creates traces.
  replaysSessionSampleRate: 0.1, // Rate for replays that begin recording immediately and last the entirety of the user's session.
  replaysOnErrorSampleRate: 1.0, // Rate at which replays (screen recordings) are created for errors.
  normalizeDepth: 10,
  // enabled: process.env.NODE_ENV !== 'development',
  integrations: [],
});

// Lazy-loading lets us add pluggable integrations
// without increasing the initial bundle size
import('@sentry/nextjs').then((lazyLoadedSentry) => {
  Sentry.addIntegration(
    lazyLoadedSentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  );
});

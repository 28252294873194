import { TOrderDetailsReducerState, TOrderDiscount, TOrderDiscountState } from 'store';
import { IPromotion } from 'store/modules/promotions';
import { TRelevantWeekdayServer } from 'types';

export const validateOrderType: (
  promotion: IPromotion,
  orderType?: TOrderDetailsReducerState['orderType'],
) => boolean = (promotion, orderType) => {
  return !promotion.parameters.orderType || promotion.parameters.orderType === orderType;
};

export const validatePromotionRelevantWeekday: (
  relevantWeekdays?: TRelevantWeekdayServer[],
  preferredDay?: TOrderDetailsReducerState['preferredDay'],
) => boolean = (relevantWeekdays, preferredDay) => {
  return (
    !preferredDay ||
    !relevantWeekdays ||
    !relevantWeekdays.length || // relevantWeekdays empty [] - should work in all days
    relevantWeekdays.length === 7 || // when length is 7 it means all the days are selected, so there's no need to select a weekday to test this
    !!relevantWeekdays.find((weekday) => weekday.id === preferredDay)
  );
};

export const validateActiveItemsPromotionDiscount = (
  orderDiscounts: TOrderDiscount[],
): TOrderDiscount[] => {
  return orderDiscounts.filter(
    (discount) =>
      discount.discountType.name !== 'itemsPromotionDiscount' ||
      discount.state === TOrderDiscountState.Active,
  );
};

import { LOG_OUT_SUCCESS } from '../auth';

import {
  IS_INTRODUCTORY_POPUPS_COMPLETED,
  IS_SHOW_LOCATION_UPDATE,
  REDIRECT_AFTER_HOURS_SELECTION_UPDATE,
  UPDATE_KNOWN_ADDRESS_AND_SUB_CATALOG,
} from './constants';

import { initialShoppingFlowState } from './initialState';

import { TShoppingFlowReducer } from './types';

const shoppingFlowReducer: TShoppingFlowReducer = (state = initialShoppingFlowState, action) => {
  switch (action.type) {
    case IS_SHOW_LOCATION_UPDATE:
    case REDIRECT_AFTER_HOURS_SELECTION_UPDATE:
    case IS_INTRODUCTORY_POPUPS_COMPLETED:
    case UPDATE_KNOWN_ADDRESS_AND_SUB_CATALOG:
      return {
        ...state,
        ...action.payload,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...initialShoppingFlowState,
      };

    default:
      return state;
  }
};

export { shoppingFlowReducer };

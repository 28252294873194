import { Box, CardMedia } from '@material-ui/core';
import { StoreService, TStorePerformDeliveryToTheAddressServer } from 'api/services/StoreService';
import LocationAutocomplete, { TAddress } from 'components/common/LocationAutocomplete';

import { useDialog, useWebsiteDetails } from 'hooks';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG, DELIVERING_TO_ADDRESS_DIALOG } from 'store';
import { TStoreSelfPickupAreaServer } from 'types';
import CTAButton from 'ui/common/buttons/CTAButton';

import Typography from 'ui/common/Typography';
import { getStringBeforeLastComma } from 'utils';
import useStyles from './styles';
import { ILocationHoursDialog } from './types';

const LocationHoursDialog: FC<ILocationHoursDialog> = ({
  city,
  isAboutPage,
  areasCount,
  successCallback,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { showDialog } = useDialog();

  const websiteDetails = useWebsiteDetails();

  const [address, setAddress] = useState<TAddress>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selfPickupAreas, setSelfPickupAreas] = useState<TStoreSelfPickupAreaServer[]>([]);

  const handleResponse = useCallback(
    (data: TStorePerformDeliveryToTheAddressServer) => {
      const { deliveryAreasCount, performsSelfPickup, deliveryArea } = data;

      showDialog({
        dialogType: isAboutPage
          ? DELIVERING_TO_ADDRESS_ABOUT_PAGE_DIALOG
          : DELIVERING_TO_ADDRESS_DIALOG,
        contentProps: {
          deliveryAreasCount,
          performsDelivery: !!deliveryAreasCount,
          performsSelfPickup,
          deliveryArea,
          selfPickupAreas,
          successCallback,
          city,
          cityFromAutocomplete: address?.city,
        },
      });
    },
    [showDialog, isAboutPage, selfPickupAreas, successCallback, city, address?.city],
  );

  const handleSubmit = useCallback(async () => {
    if (!address) return;

    const addressToPost = { ...address };
    delete addressToPost.city;

    const actionResult = await StoreService.getDeliveringToTheAddress(
      {
        addressOrCity: {
          address: addressToPost,
        },
        storeJWE: websiteDetails.jsonWebEncryption,
      },
      { setLoading },
    );

    return handleResponse(actionResult.data);
  }, [websiteDetails.jsonWebEncryption, address, handleResponse]);

  const handleAddressChange = useCallback(
    (locationValue) => {
      if (!locationValue) return;
      // we need to add city just because of the UI issue, and remove it before fetch
      const cityFromAutocomplete = getStringBeforeLastComma(locationValue.cityAndCountry);

      setAddress({
        placeId: locationValue.placeId,
        description: locationValue.address,
        city: cityFromAutocomplete,
        cityAndCountry: locationValue.cityAndCountry,
      });
    },
    [setAddress],
  );

  useEffect(() => {
    StoreService.getStoreServiceAreas().then((actionResult) => {
      if (actionResult.data.selfPickupAreas) {
        setSelfPickupAreas(actionResult.data.selfPickupAreas);
      }
    });
  }, [websiteDetails.jsonWebEncryption]);

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={websiteDetails.theme.images.locationRangePopup}
          component="img"
          className={classes.image}
          alt=""
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h1" color="secondary" align="center" className={classes.titleMain}>
          {'generalPage.deliveryAreas'}
        </Typography>
        <Typography variant="body1" fontSize={16} color="grey" align="center">
          {t(`dialog.${areasCount > 1 ? 'multipleDeliveryAreas' : 'oneDeliveryArea'}`, {
            cityName: city.name,
          })}
        </Typography>
      </Box>
      <Box className={classes.searchRoot}>
        <LocationAutocomplete
          minChars={3}
          variant="addresses"
          customLabel={t('dialog.location.addressExample')}
          onChange={handleAddressChange}
        />
      </Box>
      <Box className={classes.rootButton}>
        <CTAButton fullWidth disabled={!address} onClick={handleSubmit} loading={loading}>
          {'button.continue'}
        </CTAButton>
      </Box>
    </Box>
  );
};

export default LocationHoursDialog;

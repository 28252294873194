import React, { FC, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { Typography as MUITypography, TypographyProps } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import useStyles from './styles';

import { ITypography } from './types';

const Typography: FC<ITypography> = ({
  children,
  color,
  variant,
  isTranslate = true,
  fontSize = 18,
  fontWeight = 'regular',
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    children,
    color,
    variant,
    isTranslate,
    fontSize,
    fontWeight,
    ...rest,
  });

  const renderChildren = useMemo<ReactNode>(() => {
    return typeof children === 'string' && isTranslate ? t(children) : children;
  }, [children, t, isTranslate]);

  // need this because we have custom colors (light, grey, mediumGrey)
  const typographyColor: TypographyProps['color'] =
    color === 'light' || color === 'grey' || color === 'mediumGrey' ? 'inherit' : color;

  return (
    <MUITypography
      classes={{
        root: classNames({
          [classes.light]: color === 'light',
          [classes.grey]: color === 'grey',
          [classes.mediumGrey]: color === 'mediumGrey',
          [classes.error]: color === 'error',
        }),
        h1: classes.h1,
        h2: classes.h2,
        h3: classes.h3,
        h4: classes.h4,
        body1: classes.body1,
        body2: classes.body2,
      }}
      color={typographyColor}
      variant={variant}
      {...rest}
    >
      {renderChildren}
    </MUITypography>
  );
};

export default Typography;

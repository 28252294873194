import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesDeliveryPickUpPopUp } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDeliveryPickUpPopUp> =>
    createStyles({
      wrapper: {
        position: 'relative',
        width: '100%',
      },
      labelWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2 / 8),
      },
      iconRoot: {
        fontSize: '18px',
      },
      deliveryText: {
        fontSize: '15px',
        lineHeight: 1.4,
        letterSpacing: theme.spacing(-0.0125), // -0.1px;
        // 3 dots styles
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
      },
      iconSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      withoutDeliveryTime: {
        maxWidth: '200px',
        fontSize: '15px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      withoutDeliveryTimeBtn: {
        whiteSpace: 'nowrap',
        padding: 0,
      },
    }),
);

import { Box, CardMedia } from '@material-ui/core';
import { IImagesBannerSlider } from 'components/common/HomePageContent/components/common/ImagesBannerSlider/types';
import HomePageSlider from 'components/common/HomePageContent/components/desktop/HomePageSlider';
import MobileHomePageSlider from 'components/common/HomePageContent/components/mobile/MobileHomePageSlider';
import { useMobile } from 'hooks';
import React, { FC, useContext, useMemo } from 'react';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import { prepareImageUrl } from 'utils/helpers/prepareImageUrl';
import useStyles from './styles';

const ImagesBannerSlider: FC<IImagesBannerSlider> = ({ images, title }) => {
  const { isMobile } = useMobile();

  const mainLayoutContext = useContext(MainLayoutContext);

  const classes = useStyles({ isOpenedTopCart: mainLayoutContext.isOpenedTopCart });

  const imagesToDisplay = useMemo(() => {
    return images.map((image, index) => {
      return (
        <Box key={index}>
          <CardMedia
            src={prepareImageUrl(image.url)}
            component="img"
            className={classes.imageWrapper}
            alt={image.altText}
          />
        </Box>
      );
    }, []);
  }, [classes.imageWrapper, images]);

  const mobileSlider = useMemo(() => {
    return images.map((image, index) => (
      <Box key={index}>
        <CardMedia
          className={classes.imageWrapper}
          src={prepareImageUrl(image.url)}
          component="img"
          alt={image.altText}
        />
      </Box>
    ));
  }, [classes.imageWrapper, images]);

  return (
    <>
      {isMobile ? (
        <MobileHomePageSlider slides={mobileSlider} title={title} />
      ) : (
        <Box className={classes.imagesBannerSlider}>
          {!!images?.length && (
            <Box className={classes.imagesWrapper}>
              <HomePageSlider
                slides={imagesToDisplay}
                title={title}
                amountOfSlidesToShow={2}
                spaceBetweenSlides={22}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ImagesBannerSlider;

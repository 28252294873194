import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TRenderErrorText, TUseHelperFieldText } from './types';

const useHelperFieldText: TUseHelperFieldText = () => {
  const { t } = useTranslation();
  const renderErrorText = useCallback<TRenderErrorText>(
    (error) => {
      let helperText = '';
      if (typeof error === 'string') {
        helperText = t(error);
      }
      return helperText;
    },
    [t],
  );
  return renderErrorText;
};
export default useHelperFieldText;

import { createStyles, makeStyles, StyleRules } from '@material-ui/core';
import { TOrderDetailsReducerState } from 'store';

import { TWithMobileStyles } from 'types';
import { TStylesStickyHeader } from './types';

export default makeStyles(
  (): StyleRules<
    TStylesStickyHeader,
    TWithMobileStyles & { orderMode: TOrderDetailsReducerState['orderMode'] }
  > =>
    createStyles({
      appBarRoot: ({ isMobile, orderMode }) => {
        return {
          position: 'sticky',
          top: orderMode === 'new' || isMobile ? 0 : 40,
          left: 0,
          zIndex: 5,
          boxShadow: 'none',
          padding: '0px !important',
          height: isMobile ? 'auto' : '80px',
        };
      },
    }),
);

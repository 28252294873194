import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesCartWasSharedDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCartWasSharedDialog> =>
    createStyles({
      cartWasSharedWrapper: {
        padding: theme.spacing(0, 0, 38 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 0, 70 / 8),
        },
      },
      cartWasSharedImage: {
        margin: '0 auto',
        width: '154px',
        marginTop: theme.spacing(40 / 8),
        [theme.breakpoints.up('md')]: {
          width: '182px',
        },
      },
      cartWasSharedTitle: {
        fontSize: '26px',
        lineHeight: '28px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.palette.secondary.main,
        margin: theme.spacing(20 / 8, 0, 8 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: '30px',
          lineHeight: '34px',
          margin: theme.spacing(20 / 8, 0, 10 / 8),
        },
      },
      cartWasSharedSubtitle: {
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',
        color: theme.palette.grey[600],
        marginBottom: theme.spacing(22 / 8),
      },
      cartWasSharedMainWrapper: {
        padding: theme.spacing(0, 30 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 70 / 8),
        },
      },
      goToCartButton: {
        width: '100%',
      },
    }),
);

import React, { FC, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useMobile, useRenderDate } from 'hooks';
import Typography from 'ui/common/Typography';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import { IDaysAndHours } from './types';
import useStyles from './styles';

const DaysAndHours: FC<IDaysAndHours> = ({
  storeOpenHours,
  hideClosedDays = false,
  title,
  children,
}) => {
  const { renderDay } = useRenderDate();
  const { isMobile } = useMobile();

  const lang = useSelector(getLang);

  const classes = useStyles();

  const dayLabelWidth = useMemo(() => {
    if (lang === 'ru') {
      return isMobile ? '100px' : '116px';
    }
    return hideClosedDays ? '116px' : '67px';
  }, [hideClosedDays, isMobile, lang]);

  return (
    <Box className={classes.daysAndHoursWrapper} mt={title ? 16 / 8 : 0}>
      {title && title}
      {storeOpenHours.map((storeHours, i) => (
        <Box key={i} className={classes.hoursWrapper}>
          {hideClosedDays ? (
            !!storeHours.openHours.length && (
              <Box width={dayLabelWidth} mb={16 / 8}>
                <Typography variant="body1" className={classes.openDays}>
                  {renderDay(storeHours.weekday)}
                </Typography>
              </Box>
            )
          ) : (
            <Box width={dayLabelWidth} mr={20 / 8} mb={16 / 8}>
              <Typography variant="body1" className={classes.openDays}>
                {renderDay(storeHours.weekday)}
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems={hideClosedDays ? 'initial' : 'self-start'}
            width={97}
          >
            {storeHours.openHours.length
              ? storeHours.openHours.map((openHours, index) => (
                  <Box mb={index + 1 === storeHours.openHours.length ? 16 / 8 : 6 / 8} key={index}>
                    <Typography isTranslate={false} className={classes.openHours}>
                      {openHours}
                    </Typography>
                  </Box>
                ))
              : !hideClosedDays && (
                  <Box mb={7 / 8} className={classes.closedDay}>
                    <Typography variant="body1" className={classes.openHours}>
                      {'aboutPage.closed'}
                    </Typography>
                  </Box>
                )}
          </Box>
        </Box>
      ))}
      {children}
    </Box>
  );
};

export default DaysAndHours;

const JWE_TOKEN_UPDATE = 'auth/JWE_TOKEN_UPDATE';
const SESSION_ONLY_JWE_TOKEN_UPDATE = 'auth/SESSION_ONLY_JWE_TOKEN_UPDATE';
const DEVICE_ID_GENERATE = 'auth/DEVICE_ID_GENERATE';
const DEVICE_ID_UPDATE = 'auth/DEVICE_ID_UPDATE';
const LOG_OUT_REQUEST = 'auth/LOG_OUT_REQUEST';
const LOG_OUT_SUCCESS = 'auth/LOG_OUT_SUCCESS';
const ON_CLIENT_BEHALF_UPDATE = 'auth/ON_CLIENT_BEHALF_UPDATE';

export {
  JWE_TOKEN_UPDATE,
  SESSION_ONLY_JWE_TOKEN_UPDATE,
  DEVICE_ID_GENERATE,
  DEVICE_ID_UPDATE,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  ON_CLIENT_BEHALF_UPDATE,
};

import { TLanguageTranslations } from '../types';

const englishMessages: TLanguageTranslations = {
  languages: {
    language: 'Language',
  },
  rexail: {
    formalName: 'Retailer Online LTD',
    brandName: 'Rexail',
  },
  pages: {
    titles: {
      cart: 'Shopping Cart',
      checkout: 'Checkout',
    },
  },
  currency: {
    shekel: '₪',
  },
  button: {
    continue: 'Continue',
    loadPreviousOrder: 'Show previous orders',
    previousOrder: 'Previous orders',
    goToCheckout: 'Go to checkout',
    goToCart: 'Go to cart',
    continueToCheckout: 'Go to checkout',
    placeOrder: 'Place order',
    paymentViaBit: 'Payment via bit',
    placeSubscription: 'Place subscription',
    continueShopping: 'Continue shopping',
    view: 'View',
    viewSubscription: 'View subscription',
    viewSubscriptionCompletedCard: 'View',
    viewOrder: 'View order',
    orderAgain: 'Order again',
    edit: 'Edit',
    cancelOrder: 'Cancel order',
    contactStore: 'Contact store',
    downloadInvoice: 'Download invoice',
    downloadDeliveryCertificate: 'Download delivery certificate',
    restore: 'Restore',
    cancel: 'Cancel',
    ok: 'Ok',
    saveChanges: 'Save changes',
    backToTheHomePage: 'Back to Home Page',
    orderAgainBtn: 'Order again',
    increaseQuantity: 'Increase quantity',
    decreaseQuantity: 'Decrease quantity',
    loadMoreProducts: 'Load more',
  },
  input: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    phone: 'Phone',
    fullAddress: 'Full address',
    floor: 'Floor',
    apartment: 'Apartment',
    doorCode: 'Door code',
    entrance: 'Entrance',
    optional: 'Optional',
    addressNotes: 'Address comments',
    search: 'Search product',
    chooseStore: 'Choose a store',
    cardHolderName: 'Cardholder name',
    cardHolderID: 'Cardholder ID',
    creditCardNumber: 'Card number',
    expirationYear: 'Exp. year',
    expirationMonth: 'Exp. month',
    expirationDate: 'MM/YY',
    cvv: 'CVV',
    validity: 'Expiration date',
    coupon: 'Enter coupon code here',
    cellPhone: 'Mobile phone',
    hidden: '',
    coordinates: 'Please drag the map to mark your exact location',
    city: 'City (Hebrew only)',
    streetAndStreetNumber: 'Street name and number',
    streetAndStreetExample: 'Example: Weizmann 5',
    address: 'Address',
    details: 'Message',
    receivesCommercialMessages:
      'I would like to receive updates regarding promotions and discounts by email and SMS',
    whatDeliveryPersonShouldKnow: 'What our delivery person should know?',
    notSelected: 'Not selected',
    otherAmount: 'Other amount',
    tipPlaceholder: 'You can enter up to NIS 100',
    cityMoshavKibuts: 'City/ Moshav/ Kibbutz',
    streetAndNumberOfBuilding: 'Street name and number',
    nameOfBase: 'Base name',
    cityPlaceholder: 'Please choose',
    searchPickup: 'Search pickup point',
    searchLocationByAddress: 'Search location by address',
  },
  payments: {
    options: {
      addNewCard: 'Add a new card',
    },
    hiddenDigits: '••••',
    noPaymentMethods: 'You have no saved payment methods yet',
    creditCardDetails: 'Credit card details',
    backToPayments: 'Back to payment methods',
    validUntil: 'Expires',
    bit: {
      name: 'bit',
      awaitingPayment: 'Awaiting payment.',
      sendLinkTo: "We've sent a link for a payment request to {{phoneNumber}}",
      resend: 'Resend',
      paymentDeclined: 'Payment declined.',
      linkIsValidTenMinutes: 'The payment link is valid for 10 minutes.',
      paymentAccepted: 'Payment accepted',
      bitLinkWasSendSuccessfully: 'Sms was sent successfully',
    },
  },
  link: {
    backToTheShop: 'Back to shopping',
  },
  userMenu: {
    hiText: 'Hi',
    loginText: 'Login',
    signUpLoginText: 'Signup / Login',
    accountDetails: 'Account details',
    payments: 'Payment methods',
    mySubscriptions: 'My subscriptions',
    orderHistory: 'Order history',
    logOut: 'Log out',
    clientCoupons: {
      clientCoupon: `You've received a refund`,
      validUntil: 'Expires on ',
    },
  },
  accountDetails: {
    edit: 'Edit',
    sendVerificationEmailTo:
      'After clicking the "Save" button, we will send a verification email to',
    sendToOldAndNewEmail: 'your old and new email address',
    sendToNewEmail: 'your new email address',
    forSecurityPurposes: 'for security purposes.',
    emailNotDefined: 'Your email is not defined',
    keep: 'Save',
    verified: 'Verified',
    waitingForVerificationAtTheEmail: 'Awaiting verification at {{email}}',
    waitingForVerification: 'Awaiting verification',
  },
  categoryMenu: {
    more: 'More',
    title: 'Categories',
    allProducts: 'All products',
    allItems: 'All items',
    promotions: 'Promotions',
  },
  topCard: {
    expandCart: 'Expand / Collapse cart',
    myShoppingBag: 'My cart',
    continue: 'Go to',
    payment: 'checkout',
    cardEmpty: 'Your cart is empty',
    startAdding: 'Start adding products',
    revaluation: 'Estimation. Final cost by weight.',
    minimumOrder: 'Minimum order',
    backToStore: 'Back to shopping',
    orderSummary: 'Order summary',
    commentsToPreparer: 'Order comments',
    totalShoppingCart: 'Items subtotal',
    listHeader: {
      product: 'Product',
      pricePerUnit: 'Price per unit',
      quantity: 'Quantity',
      total: 'Total',
      remove: 'Remove',
    },
    deleteBasket: 'Empty cart',
    backToCart: 'Back to my cart',
    showPreviousOrder: 'Show previous orders',
    shoppingCartNItems: 'Your shopping cart has {{items}} items',
    deleteFromCart: 'Delete from cart',
    productsYouMightMissed: 'Items you might have missed',
    restore: 'Restore',
    delete: 'Delete',
    removedItem: 'Item was removed',
    shareCart: 'Share cart',
    shareCartItems: 'Share cart',
    cartWasShared: 'Shared cart',
    shareCartInFollowingWays: 'Sharing is caring! Share your cart in one of the following ways',
    shareLink: 'Copy link',
    whatsapp: 'Whatsapp',
    checkSharedCard: 'Check out this shared cart full of goodies handpicked just for you!',
    cartWasSharedSuccessfully:
      'Check out this shared cart full of goodies handpicked just for you!',
    emailSharedCard: 'Check out this shared cart from {{storeName}} handpicked just for you!',
    goToCart: 'Go to cart',
    deviceNotSupportShare:
      'Oops! Sharing not supported on your device. Link of shared cart was copied to clipboard.',
    linkCopiedSuccessfully: 'Link copied successfully!',
  },
  generalPage: {
    about: 'About the store',
    subscribe: 'Subscribe',
    deliveryAreas: 'Delivery areas',
    contact: 'Contact us',
  },
  deliveryPickUp: {
    withoutDeliveryTimeText: 'Select delivery date',
    allTimeFramesAreFull:
      'We are sorry, there are no available time-frames for ordering, please check back again later',
    errorMessage: 'We were unable to find available time-frames due to an error, please try again.',
    chooseDeliveryMethod: 'Choose an order preference',
    onlySelfPickup: 'Self pickup locations',
    onlySelfPickupChoseTime: 'Self pickup locations',
    sortPointsByCurrentLocation: 'Sort pickup points by current location',
  },
  orderType: {
    delivery: 'Delivery',
    selfPickup: 'Self Pickup',
  },
  stepper: {
    order: {
      personalInformation: 'Personal info',
      coordinationArrival: 'Schedule a time',
      payment: 'Payment',
    },
    subscription: {
      personalInformation: 'Select items',
      coordinationArrival: 'Frequency & time',
      payment: 'Payment',
    },
  },
  overlay: {
    title: 'Swipe right to see the rest of the categories',
    buttonText: 'I understood',
  },
  storeProductCard: {
    addToCart: 'Add to cart',
    productCommentTypeAvailable: 'Prep comments at checkout',
    originalPrice: 'Original price (before discounts)',
    relevancy: {
      popular: 'Recommended item',
      high: 'Excellent',
      medium: 'Standard quality',
      low: 'Low grade',
    },
    supplier: 'Supplier',
    pricePerOneHundredGram: '₪{{price}} per 100 g',
    pricePerOneHundredMl: '₪{{price}} per 100 ml',
    countryOfOrigin: 'Country of origin {{countryName}}',
    addMoreItemMultiple: 'Add {{numberOfItems}} items to redeem discount',
    addMoreItemSingle: 'Add {{numberOfItems}} item to redeem discount',
    toPromotion: 'See items',
  },
  tag: {
    sale: 'Sale',
    deal: 'Deal',
    freeItem: 'Free Item',
  },
  header: {
    backButton: 'Back to cart',
    navigation: 'Where to deliver?',
    chooseDelivery: 'Choose a delivery time',
    chooseSelfPickup: 'Choose a pickup time',
    chooseSelfPickupTime: 'location & time',
    chooseDeliveryTime: 'choose a delivery time',
    chooseDeliverySubscriptionMode: 'Choose frequency & delivery time',
    chooseSelfPickupSubscriptionMode: 'Choose frequency & pickup time',
    skipToTheCategory: 'Skip to category menu',
    skipToTheMainContent: 'Skip to main content',
    skipToTheFooter: 'Skip to footer',
  },
  previousOrder: {
    price: 'Total',
    items: 'Items',
    addItems: 'Add {{count}} item to your cart',
    backToOrderList: 'Back to order history',
    payAttention: 'Pay attention',
    ongoingOrder: 'There is an ongoing order scheduled to ',
    itemIsOutOfStock: 'The item is out of stock',
  },
  editOrder: {
    removedItemsExcludedFromCatalog:
      'The following items were removed from your order because they are not available: {{- removedItems}}.',
    removedItemsOutOfStock:
      'The following items were removed from your order because they are out of stock: {{- removedItems}}.',
  },
  order: {
    deliveryDate: 'Scheduled time',
    orderType: 'Order type',
    numberOfItems: 'Items',
    totalToPay: 'Total',
    total: 'Total',
    orderNumber: 'Order No.',
    status: 'Status',
    deliveryAddress: 'Delivery address',
    selfPickupAddress: 'Pickup address',
    paymentMethod: 'Payment method',
    comments: 'Comments',
    revaluation: 'Estimation. Final cost by weight.',
    noOrders: "You haven't made any orders yet",
    startBuying: 'Start shopping',
    orderTime: 'Order placed',
    ecoPackaging: 'ECO Packaging',
    itemsSubstitution: 'Item substitution',
    suppliedItems: 'Supplied items',
    notSupplied: 'Not supplied items',
    orderedItems: 'Ordered items',
    chooseEcoPackaging: 'ECO Packaging - few bags as possible',
    inCaseItemIsOutOfStock: 'In case item is out of stock',
    substituteWithSimilar: 'Substitute with similar item',
    doNotSubstitute: 'Do not substitute',
    contactMe: 'Contact me',
  },
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    shortName: {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    },
    today: 'Today',
    tomorrow: 'Tomorrow',
  },
  footer: {
    menu: {
      about: 'About the store',
      delivery: 'Delivery areas',
      contact: 'Contact us',
      details: 'My account',
      paymentMethod: 'Payment methods',
      subscription: 'Subscriptions',
      orderHistory: 'Order history',
    },
    termsAndConditions: {
      privacy: 'Privacy Policy',
      accessibilityStatement: 'Accessibility statement',
      terms: 'Terms of Use',
    },
    allRights: '© Retailer Online LTD, {{year}}. All rights reserved',
    companyType: 'Company ID',
    ariaLabels: {
      storeAddress: 'Store address',
      storePhone: 'Store phone',
      storeWhatsapp: 'Store whatsapp',
      storeEmail: 'Store email',
      storeFacebook: 'Store facebook link',
      storeYoutube: 'Store youtube link',
      storeInstagram: 'Store instagram link',
    },
  },
  snackbar: {
    undo: 'undo',
    orderMinimumFellShort: {
      delivery:
        'Please note! in order to complete your order please reach a minimum of {{orderMinimum}}₪ not including delivery fees.',
      selfPickup:
        'Please note! in order to complete your order please reach a minimum of {{orderMinimum}}₪ not including preparation fees.',
    },
    orderMinimumFellShortIfNoDeliveryFee:
      'Please note! in order to complete your order please reach a minimum of {{orderMinimum}}₪.',
    promotionAppliedSuccessfully: 'Promotion has been applied successfully to the cart',
  },
  forms: {
    checkout: {
      submitButton: 'Continue',
      backButton: 'Back to cart',
      arrivalSubtitle: 'Choose a date',
      collectionPoint: 'Choose a pickup location',
      pickUpDate: 'Schedule a time',
      backToPersonal: 'Back to personal details',
      backToArrival: 'Back to arrival coordination',
      moveToPay: 'Continue to pay',
      additionalDelivery: 'View Additional Delivery Times >',
      creditDetails: 'Credit card details',
      billingCredit: 'Billing credit card',
      saveCredit: 'Save card information for future payments',
      saveCreditTooltip:
        'Payment details are not stored on the site servers, they are stored securely using state-of-the-art tokenization technologies in cooperation with Credit-Guard Payments Gateway',
    },
    contactUs: {
      title: 'You can also leave us a message',
      submitButton: 'Sending',
    },
  },
  validation: {
    email: 'Invalid email address',
    required: 'This field is required',
    chooseCity: 'Please choose city',
    error: 'Incorrect value',
    minMonth: 'Min value is 01',
    maxMonth: 'Max value is 12',
    minYear: 'Min year must be current',
    twoDigits: 'Value should contain two digits',
    noOptions: 'No results',
    productComment: 'Please choose a preparation comment',
    chooseHowToPrepareItems: 'Please select preparation method for all relevant items',
    language: 'This action is unavailable at this moment, please try again later.',
  },
  map: {
    getLocationFromBrowser: 'Current location',
    moveMapToGetPosition: 'Please drag the map to mark your exact location *',
    navigatorNotSupported:
      "Your browser either does not support geolocation or you haven't given permission for location access",
    markLocationOnTheMap: 'Pin the location on the map',
    pinIsFar: 'Sorry, but the pin location is too far from the entered address',
    pinIsInvalid: 'Pin location is invalid. Try repositioning the pin accurately.',
  },
  title: {
    aboutProduct: 'About the product',
    ordersHistory: 'Order history',
    activeOrders: 'Active orders',
    closedOrders: 'Closed orders',
    subscriptions: 'My subscriptions',
  },
  comments: {
    preparationInstructionsAtTheEndOfThePurchase: 'Prep comments at checkout',
    youMayAlsoWant: 'You might also like',
  },
  shoppingPage: {
    searchResults: 'Search Results',
    cleanSearch: 'Clear search',
    goToHomePage: 'Go to homepage of {{storeName}}',
    sort: {
      sortBy: 'Sort by',
      sortByName: 'Product name',
      sortByCategoryAndName: 'Category and product name',
      sortByPriceAsc: 'Price low to high',
      sortByPriceDesc: 'Price high to low',
      sortByPromoted: 'Promoted items',
      sortBySearchRelevancy: 'Search Relevance',
    },
  },
  paymentInfoSection: {
    creditCard: {
      text: 'Checkout is done securely, your payment details are protected using state-of-the-art tokenization technologies in cooperation with Credit-Guard Payments Gateway',
      externalFormText:
        'By clicking on "Continue" you will be redirected to an external payment page built in cooperation with Credit-Guard, a leading credit card processing company in Israel',
      link: 'Learn more',
    },
    deferredPayment:
      'The order will not be charged now, we will contact you later to settle the payment',
    absent:
      'The order will be created without payment info. Later you will be able to associate a payment method.',
    cash: 'The payment will be collected by the courier at the day of the delivery. Please prepare the exact amount (change may not be available).',
    bit: {
      onlyNonWeighableItems:
        'After pressing the "Payment via bit" button, you\'ll receive an SMS containing a link to proceed with payment via Bit. We\'ll proceed with order preparation upon confirmation of your payment. You can track the payment status on the "Order Details" page',
      weighableItems:
        "After pressing the \"Payment via bit\" button, you'll receive an SMS containing a link to proceed with payment via Bit. We'll proceed with order preparation upon confirmation of your payment. Additionally, we'll temporarily reserve a slightly higher amount than the estimated value to accommodate potential weight variations in the collected items. The final charge will be based on the precise quantities weighed.",
    },
  },
  auth: {
    loginText: 'Log in',
  },
  dialog: {
    bitErrorViewOrder: "We're experiencing issues, page will be refreshed",
    bitErrorOrderCompleted: "We're experiencing issues, you will be redirect to view order page.",
    multipleDeliveryAreas:
      'We deliver in several areas in {{cityName}}, please enter your full address in order to proceed.',
    oneDeliveryArea:
      'We deliver to a certain area in {{cityName}}, please enter your full address in order to proceed.',
    unavailableTimeframe:
      'The time frame you selected is no longer available. You are being redirected to choose a new one.',
    searchSucceeded: 'Search Succeeded',
    searchFailed: 'Search Failed',
    closeButton: 'Close popup dialog',
    orderMinimumLabel: 'Order Minimum',
    catalogWasUpdated: {
      title: 'Catalog update',
      body: 'Please note! Based on your chosen address, you have been assigned to a branch that services your area. Stock availability and prices may vary.',
      okButton: 'OK',
    },
    orderMinimum: {
      title: 'Order Minimum',
      body: {
        delivery:
          'The minimum order value is {{orderMinimum}}₪ (not included delivery fee). Please, add more items to your shopping cart.',
        selfPickup:
          'The minimum order value is {{orderMinimum}}₪ (not included preparation fee). Please, add more items to your shopping cart.',
      },
      bodyIfNoDeliveryFee:
        'The minimum order value is {{orderMinimum}}. Please, add more items to your shopping cart.',
      okButton: 'Continue Shopping',
    },
    handlingFee: {
      title: 'Handling fee',
      body: ' For orders under {{orderHandlingFeeThreshold}} NIS, a handling fee of {{orderHandlingFee}} NIS will be added to the total payment',
      okButton: 'Go to checkout',
      cancelButton: 'Back to shopping',
    },
    emptyCart: {
      title: 'Empty Cart',
      body: 'Are you sure you want to empty your shopping cart?',
      yesButton: 'Yes, empty my cart',
      noButton: 'No, keep items',
    },
    location: {
      title: 'Welcome!',
      subTitle: "Let's make sure we deliver to your location",
      haveAccount: 'Already have an account?',
      addressExample: 'Street, House No. & City',
      interestingInSelfPickup: 'Prefer self-pickup?',
      chooseSelfPickup: 'Choose a pickup location',
      deliveryHoursAndMoreDetails: 'Delivery hours and more details',
      residential: 'Residential',
      locationType: 'Location type',
      private: 'Private',
      office: 'Office',
      militaryBase: 'military base',
      couldNotLocateAddress: 'We could not locate your address, please enter your address here:',
    },
    login: {
      title: 'Welcome!',
      subTitle: 'Enter your mobile number',
      confirmTerms: 'By clicking Continue, I confirm that I have read & accepted the',
      terms: 'Terms of Use',
    },
    loginCode: {
      titleSendViaTel: 'Mobile authentication',
      titleSendViaEmail: 'Email authentication',
      subTitleTel: 'Please enter the code provided to',
      subTitleEmail: 'Enter the code provided in the email',
      sendAgain: 'Send again',
      selectMethodToReceivedCode:
        'Please choose how you would like to receive the verification code',
      dontReceivedCode: "Didn't get a code?",
      smsMessage: 'SMS message',
      phoneCall: 'Phone call',
      email: 'Email',
      enterEmailToSendAuthCode: 'Please enter an email address to send the verification code',
      send: 'Send',
      back: 'Back',
      codeFromSms: 'Verification code - digit {{number}} out of 6',
    },
    signUp: {
      title: 'Nice to meet you!',
      subTitle: 'A few more details to complete the registration process',
      submitButton: 'Submit',
      addressExample: 'Example: Dizengoff 20, Tel Aviv',
    },
    welcome: {
      title: 'Welcome aboard!',
      closeButton: "OK, let's start",
    },
    paymentMethod: {
      title: 'Delete payment method',
      body: 'Are you sure you want to delete the card ending with {{fourLastDigits}}?',
      yesButton: 'Yes, delete the card',
      noButton: 'Cancel',
    },
    delivery: {
      shippingAddress: 'Delivery address',
      storeHours: {
        title: 'Delivery time in {{-areaName}}',
      },
      coordination: {
        title: 'Schedule a time',
        description:
          'The displayed time frames are estimated. They may vary depending on the quantity of shipments and the conditions of the area. Please allow flexibility in times, and contact the store in any exceptional case.',
      },
      editText: 'Edit details',
      storeDoesntMakeDeliveryToYourAddressButSelfPickupExist:
        "Sorry, we don't deliver to your address, but we do have a self pickup point available in your city",
      kmFromYourAddress: 'km from your address',
      kmFromSelectedAddress: 'km from selected address',
      kmFromYourCurrentLocation: 'km from current location',
      choose: 'Choose',
      chooseAddress: 'Enter address to',
      chooseSelfPickup: 'Choose pickup',
      dropDownChooseSelfPickup: 'Please choose an option',
      selfPickupPoint: 'Pickup point',
      changeSelfPickupPoint: 'Change pickup point',
      toChooseSelfPickupDateNeedLog:
        'In order to choose a self pickup time, you need log in to the system',
      chooseSelfPickupTime: 'Choose a pickup time',
    },
    existingOrder: {
      title: 'Existing order',
      body: 'We noticed that the last time you were here you made an order for {{deliveryDate}} between {{deliveryTime}}',
      description: 'Do you want to edit the existing order or place another order?',
      submitButton: 'Edit an existing order',
      cancelButton: 'Place another order',
    },
    activeSubscription: {
      title: 'Active subscription',
      body: 'Hi, you have an active subscription with us with an expected next order time at {{- nextOrderDate}}.',
      bodyDescription: 'Do you want to edit your subscription or have you come for something else?',
      okButton: 'Edit my subscription',
      cancelButton: 'Continue to site',
    },
    cancelOrder: {
      title: 'Cancel Order',
      editedTitle: 'Cancel order in editing mode',
      body: 'Are you sure you want to cancel order No. {{id}}?',
      editedBody:
        'Are you sure you want to cancel order No. {{id}} which is currently in editing mode?',
      yesButton: 'Yes, cancel order',
      noButton: 'No, keep order',
    },
    cancelSubscription: {
      title: 'Cancel subscription',
      editedTitle: 'Cancel subscription in editing mode',
      body: 'Would you like to cancel only the subscription or also the pending order of the subscription?',
      editedBody:
        'Are you sure you want to cancel the subscription that is currently in editing mode or also the pending order of the subscription?',
      cancel: 'Keep the subscription',
      ok: 'Cancel subscription & order',
      justUnsubscribe: 'Cancel subscription only',
    },
    unsuspendSubscription: {
      title: 'Unsuspend subscription',
      body: 'Are you sure you want to unsuspend the subscription order?',
      cancel: 'No',
      ok: 'Yes, unsuspend it',
    },
    suspendSubscription: {
      cancel: 'Keep subscription',
      suspendAndCancelOrder: 'Suspend & cancel future order',
      justSuspend: 'Suspend only',
    },
    editOrderMode: {
      title: 'Edit order mode',
      body: 'FYI, you are currently editing an order due for {{- orderDate}}, how do you want to proceed?',
      keepEditing: 'Continue editing order',
      exitEditMode: 'Discard & exit edit mode',
    },
    deliveryToClientAddress: {
      performDeliveryTitle: 'Yes!',
      performDeliveryBody: 'We deliver to {{city}}',
      performsSelfPickupBody:
        "We don't deliver to your address, but we have a self pickup point available in the chosen city.",
      performsSelfPickupAndDeliveryBody:
        'We deliver to {{city}}, and we have a self pickup point available in the chosen city',
      performDeliveryYesButton: 'Continue shopping',
      performSelfPickupYesButton: 'Choose a pickup location',
      doesntPerformDeliveryTitle: 'Sorry…',
      doesntPerformDeliveryBody: "We don't deliver to your address",
      doesntPerformDeliveryChangeAddressButton: 'Change city',
      doesntPerformDeliveryChangeCloseButton: 'Continue shopping',
      edit: 'Edit',
    },
    threeDSecure: {
      errorTitle: 'Error',
      apiLoadError:
        'Error contacting the credit-card company. Please refresh the page and try again',
      authError:
        'Error validating your credit card details. Please make sure all the details are correct and try again, or try another card. The error message as received from the credit card company {{errorCode}}',
      genericAuthError:
        'Error validating your credit card details. Please make sure all the details are correct and try again, or try another card.',
      okButton: 'OK',
    },
    basket: {
      title: 'Edit {{- bagName}}',
      subtitle:
        'You can replace up to {{maxNumOfReplacements}} items. Click on the item to replace it.',
      save: 'Save changes',
      productInBasket: 'Products in the basket',
      replaceProducts: 'I would like to receive instead',
      maxReplacementsReached: "You've reached the max amount of replacements",
      defaultItemQuantity: 'Portion',
    },
    exitModeIndicator: {
      addSubscription: {
        title: 'Exit subscription mode',
        body: 'Are you sure you want to exit subscription creation mode?',
        okButton: "Yes, I'm sure",
        cancelButton: 'Cancel',
      },
      editSubscription: {
        title: 'Exit edit subscription mode',
        body: 'Are you sure you want to exit edit subscription mode?',
        okButton: "Yes, I'm sure",
        cancelButton: 'Cancel',
      },
      edit: {
        title: 'Exit edit order mode',
        body: 'Are you sure you want to exit edit order mode?',
        okButton: "Yes, I'm sure",
        cancelButton: 'Cancel',
      },
    },
    updateSubscription: {
      title: 'Subscription changes',
      body: 'Would you like to apply these changes to the future orders of this subscription as well?',
      bodyIfTipExist: 'Your chosen courier tip will be apply only to this order.',
      okButton: 'Yes, apply',
      cancelButton: 'No, just for this order',
    },
    orderEditingIsNotPossible: {
      title: 'Oops!',
      body: "Sorry, but it's too late for editing this order, it's too close to the delivery time. Do you want to contact the store?",
      yesButton: 'Yes, contact store',
      noButton: 'No, just cancel editing',
    },
    paymentByBit: {
      title: 'Payment via bit',
      body: "We've sent an SMS containing a payment request of ₪{{cartEstimation}} to the following number",
      or: 'or',
      scanQR: 'Scan the QR code',
      awaitingPaymentViaBit: 'Awaiting payment via bit',
      completePayment: 'Please complete the payment process in the bit app to complete the order',
    },
    paymentByBitWasDeclined: {
      title: 'Payment via bit',
      subtitle: "The payment via bit wasn't accepted!",
      body: 'You can go to the order details page to complete the payment',
      yesButton: 'Receive a new payment link',
      noButton: 'Go to order details page',
    },
  },
  location: {
    cantFind: "Can't find your address?",
    selectByMap: 'Enter your address manually',
    shareMyLocation: 'Share my current location',
    myLocation: 'My location',
  },
  availableHours: {
    chooseDay: 'Choose a day',
    chooseTimeframe: 'Choose timeframe',
    today: 'היום', // supposed to be NOT translated for now
    tomorrow: 'מחר', // supposed to be NOT translated for now
  },
  links: {
    backToSubscriptions: 'Back to my subscriptions',
    backToTheShop: 'Back to shopping',
    backToOrderHistory: 'Back to order history',
    backToAddressSearch: 'Back to address search',
  },
  completedOrder: {
    yourOrderInTheSystem: 'Your order has been received!',
    noteCompletedOrder:
      'Please note that the business name that will appear on the credit card statement is of Rexail ordering platform. After preparing your order and weighing the products, we will send you an invoice and the credit card will be charged accordingly.',
    backToTheHomeScreen: 'Back to shopping',
    orderDetails: 'Order Details',
    orderNumber: 'Order No.',
  },
  completedSubscription: {
    yourSubscriptionInTheSystem: 'Your subscription has been received!',
    noteCompletedSubscription:
      'You can edit and unsubscribe at any time in a simple way using the "My Account" menu',
    backToTheHomeScreen: 'Back to shopping',
    subscriptionDetails: 'Subscription details',
  },
  error: {
    itemsNotFoundTitle: 'No search results for: {{searchQuery}}',
    itemsNotFoundDescription: 'Please check for misspellings or the product might be out of stock',
    previousOrderNotExist: 'Items not exist',
    oops: 'Oops!',
    pageNotFound: "We can't seem to find the page you're looking for",
    somethingWentWrong: 'Something went wrong…',
    errorDescription: 'Something went wrong. Click to go back to the homepage. ',
    increaseYourExperience: 'We’re improving your experience',
    systemCurrentlyUpgraded:
      'The system is currently being upgraded. \n Please come back in a few minutes.',
    maxAmountOfProduct: 'You have reached the maximum quantity of this product :)',
    errorDetails: 'Error details',
  },
  subscription: {
    frequency: 'Frequency',
    orderType: 'Order type',
    numberOfItems: 'Items',
    nextOrder: 'Next order',
    subscriptionTo: 'Subscription to {{shopName}}',
    status: 'Status',
    deliveryTime: 'Preferred time',
    subscriptionDate: 'Subscription date',
    lastOrderDate: 'Last order',
    localedPreferredDay: 'Preferred time',
    expectedNextOrder: 'Expected next order',
    methodsOfPayment: 'Payment method',
    suspendSubscription: 'Suspend subscription',
    unsuspendSubscription: 'Reactivate subscription',
    cancelSubscription: 'Cancel subscription',
    day: 'Day',
    selectDay: 'Choose a date for reactivating your subscription',
    dateIsInThePast: 'Date is in the past',
    invalidDate: 'Invalid date',
    cancel: 'Cancel',
    ok: 'Confirm',
    noSubscription: 'There are no subscriptions yet',
    startToSubscribeTo: 'New subscription',
    chooseFrequency: 'Choose frequency',
    chooseDayAndTime: 'Choose day & time',
    coordinationArrival: 'Frequency & time',
  },
  whatIsSubscription: {
    title: 'Not our subscribers yet?',
    subTitle:
      'Creating a subscription saves time, and there is no need to remember to re-order each time.',
    howItWorks: 'How It Works?',
    assembleTheBasketTitle: 'Assemble the basket',
    assembleTheBasketSubtitle:
      'With your favorite products and we will take care of fresh and quality products',
    chooseFrequencyDayAndTimeTitle: 'Choose frequency, day and time',
    chooseFrequencyDayAndTimeSubtitle:
      'Weekly, bi-weekly or monthly, as needed, can always be edited and updated',
    startToSubscribe: 'You can start',
    whatIsSubscriptionAndHowItWorksTitle: 'What is a subscription and how does it work?',
    whatIsSubscriptionAndHowItWorksDescription:
      'Subscription orders are automatically created two days before the delivery date, depending on the frequency and the chosen delivery day, except in special cases where the delivery day is not available that week (for example on holidays) - in this case, the system will create an order for the next day and the delivery date will be updated (An email notification would be sent)!',
    whatIsSubscriptionAndHowItWorksDescriptionTwo:
      'When a subscription order is created, a reminder SMS is sent with a delivery date and an email from postmaster@rexail.co.il. Attached to the email is a detail of the new subscription order in a PDF file, in the body of the email you will find details regarding expected shortages according to the stock available on the website. In addition, a quick and direct link will appear in the body of the email to edit / cancel the order (without the need for a login process).',
    whatIsSubscriptionAndHowItWorksDescriptionThree:
      'Pay attention! Orders created by the user or by the subscriber are the responsibility of the customer! Please pay attention to important announcements and updates. If necessary, you can contact the business during business hours.',
    enterPaymentDetailsTitle: 'Enter payment details',
    enterPaymentDetailsSubtitle:
      'The charge will be made after the order is delivered, depending on the frequency chosen.',
    addNewSubscriptionTitle: 'Add a new subscription',
    addNewSubscriptionDescription:
      'You can add a new subscription on the "My Subscriptions" page (after logging in).',
    addNewSubscriptionDescriptionTwo:
      'Step One - Assemble the basket from products you would like to receive regularly. Once you have finished collecting the products, click on the "Go to checkout" button and you will immediately be taken to the "My Shopping Cart" page where you can review all the products you have added to the basket and change if necessary.',
    addNewSubscriptionDescriptionThree:
      'Second stage - selection of preferred frequency, day and time for delivery.',
    addNewSubscriptionDescriptionFour:
      'Third step - entering payment details. After adding or selecting a credit card for payment, click on the "Place Subscription" button, after which a confirmation will be displayed on the screen that the subscription has been received together with the subscription details. The charge will be made after the order is delivered, depending on the frequency chosen.',
    howDoIFreezeSubscriptionTitle: 'Editing / freezing / canceling a subscription',
    howDoIFreezeSubscriptionDescription: 'There are two ways to place an order:',
    howDoIFreezeSubscriptionDescriptionTwo:
      '1. Via the order confirmation email from postmaster@rexail.co.il which contains a direct link to edit / cancel the order (without the need for a login process) which is sent automatically when the order is created.',
    howDoIFreezeSubscriptionDescriptionThree:
      '2. On the "My Subscriptions" page (after logging in). On this page you can view your subscriptions. To edit / freeze / cancel an existing subscription, click on "View subscription" and select the desired action.',
    deficienciesInOrderingSubscriptionTitle: 'Deficiencies in ordering a subscription',
    deficienciesInOrderingSubscriptionDescription:
      'Deficiencies of items on the business website can be caused by various reasons, for example: a particular item has arrived and been declared unsuitable for packaging, or an item out of season, or overgrown in small quantities or for any other reason.\n' +
      'do not worry! Items that are in the subscription and not provided in the order due to deficiencies, will not be charged.',
    changeDeliveryDateTitle: 'Change of one-time delivery date',
    changeDeliveryDateDescription:
      'If the order from the subscriber has already been created (two days before the delivery date), a change in the delivery date is possible by contacting the business.',
    feelingConfused: 'Still feeling confused?',
    answersFrequentlyQuestions: 'Here you will find answers to frequently asked questions',
  },
  personalDetails: {
    floorApartment: '{{floor}} floor, apartment {{apartment}}',
    apartment: 'apartment {{apartment}}',
    floor: 'floor {{floor}}',
  },
  checkout: {
    noOrderComments: 'No Comments',
    choosePayment: 'Choose a payment method',
    chooseCard: 'Choose a card',
    info: {
      address: 'Address',
    },
    paymentMethod: {
      creditCard: 'Credit Card',
      deferredPayment: 'Pay later',
      absent: 'No payment',
      cash: 'Cash',
      bit: 'Bit',
    },
    coupon: {
      couponDiscount: 'Coupon',
      generalDiscount: 'general discount',
      itemRefund: 'item refund',
      code: 'Coupon code',
      noCoupon: 'Not coupon',
      couponValueMessage: 'Saved! {{valueFormula}}',
      refundItemIsNotInTheCart: "The discounted item isn't in your shopping cart",
    },
    numberOfPayments: 'Number of payments',
    betweenHours: 'Between',
    courierTip: 'Courier tip (optional)',
    noTip: 'No tip',
    other: 'Other',
    tipForCourier: 'Tip for courier',
    handlingFee: 'Handling fee',
  },
  autocomplete: {
    loading: 'Loading...',
  },
  bagOfProducts: {
    whatBagContain: "What's in the basket?",
    moreDetails: 'More details',
  },
  serviceAreaUsageFees: {
    delivery: 'Delivery fees',
    selfPickup: 'Order preparation fee',
  },
  orderModeIndicator: {
    edit: 'Editing mode for order No. {{id}}',
    addSubscription: 'Subscription creation mode',
    editSubscription: 'Subscription editing mode',
    exit: 'exit',
  },
  aboutPage: {
    aboutTitle: 'About us',
    openHours: 'Store opening hours',
    regularlyTitle: 'Do you buy from us regularly?',
    regularlySubtitleOne: 'Choose frequency and get your regular order straight home!',
    regularlySubtitleTwo:
      ' Two days before the order arrives, you will receive a reminder to your mobile and email and you can update the contents of the order or cancel it.',
    regularlyBtn: 'Subscribe',
    divisionalTitle: 'Delivery areas',
    divisionalSubtitle: 'Check if we deliver to your location',
    contactTitle: 'Contact us',
    openingHours: 'Opening Hours',
    closed: 'Closed',
    moreDetails: 'More details',
    check: 'Check',
    checkAgain: 'Check again',
    citySearchDescription: 'Enter a city or settlement to see if we make deliveries to your place',
    followUs: 'Follow us',
  },
  discount: {
    payment: 'Store credit',
    creditAtCheckoutDescription:
      'Store credit is a one-time-use voucher and cannot be divided across multiple transactions.',
    deliveryUsageFees: 'Delivery discount',
    selfPickupUsageFees: ' Preparation fees',
    storePromotion: 'Promotion',
    storeCoupon: 'Coupon',
    clientCoupon: 'Refund',
    couponDiscount: 'Coupon',
    generalDiscount: 'General discount',
    itemRefund: 'Item refund',
    itemForFree: 'Free item',
    forOrders: {
      delivery: 'when ordering delivery',
      selfPickup: 'when ordering self-pickup',
    },
    valid: 'Valid',
    days: 'for',
    maxQuantityUsage: {
      forNonWeighableAboveQuantity: 'Max {{maxQuantity}} redemptions per order',
      forWeighableBetweenQuantity: 'Max {{maxQuantity}} kg per order',
    },
    validUntil: 'Valid till',
    notValidInSelectedDay:
      'Some of the promotions in the cart are not valid for the selected delivery day, therefore have been removed from the order',
  },
  onClientBehalf: {
    storeSide: 'Store side',
  },
  ageValidation: {
    ageValidation: 'Age validation',
    orderContainAgeLimitedItems: 'Your order contains age restricted items according to the law',
    ageConformation:
      'I confirm that I am over the age of 18. At handoff I will be required to show an ID',
  },
  incentivePromotion: {
    tooltip: {
      redeemRefund: 'To redeem discount, add the {{productName}} to the cart',
    },
    generatedText: {
      forCartEstimationBeforePreparation: {
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Add ₪{{discountShortage}} to enjoy free delivery',
            partialDiscount:
              'Add ₪{{discountShortage}} to enjoy {{discountValue}} off delivery fees',
          },
          delivery: {
            fullDiscount: 'Add ₪{{discountShortage}} to enjoy free delivery',
            partialDiscount:
              'Add ₪{{discountShortage}} to enjoy {{discountValue}} off delivery fees',
          },
          selfPickup: {
            fullDiscount: 'Add ₪{{discountShortage}} to enjoy free preparation fees',
            partialDiscount:
              'Add ₪{{discountShortage}} to enjoy {{discountValue}} off preparation fees',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'Add ₪{{discountShortage}} to enjoy {{discountValue}} off',
            minimumOrder: 'Add ₪{{discountShortage}} to enjoy {{discountValue}} off',
          },
          delivery: {
            smallMinimumOrder: 'Add ₪{{discountShortage}} to enjoy {{discountValue}} off',
            minimumOrder: 'Add ₪{{discountShortage}} to enjoy {{discountValue}} off',
          },
          selfPickup: {
            smallMinimumOrder: 'Add ₪{{discountShortage}} to enjoy {{discountValue}} off',
            minimumOrder: 'Add ₪{{discountShortage}} to enjoy {{discountValue}} off',
          },
        },
        itemForFree: {
          all: 'Add ₪{{discountShortage}} to enjoy free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}}',
          delivery:
            'Add ₪{{discountShortage}} to enjoy free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}}',
          selfPickup:
            'Add ₪{{discountShortage}} to enjoy free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}}',
        },
        itemRefund: {
          all: 'Add ₪{{discountShortage}} to receive {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}}',
          delivery:
            'Add ₪{{discountShortage}} to receive {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}}',
          selfPickup:
            'Add ₪{{discountShortage}} to receive {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}}',
        },
      },
    },
    productsParticipatingSale: 'Eligible promotional items',
    seeParticipationProducts: 'See eligible items',
  },
  promotionsBanner: {
    validForDays: 'Valid for',
    until: 'Valid till',
    validUntil: 'Valid till',
    maxQuantityPerOrder: 'Max {{valueFormulaSourceQuantity}} {{-unitName}} per order',
    tooltip: {
      readMore: 'Read more',
      forCartEstimationBeforePreparation:
        'The discount will be calculated based solely on the cart estimation displayed on the website, and not by the invoice total.',
      forCartEstimationAfterPreparation:
        'The discount will be applied at preparation stage, provided that the final billing amount meets the specified conditions. The discount will appear on the invoice.',
      forSubscription:
        'The discount for subscribers will be received at the billing stage, after the items have been weighed and supplied. The discount will appear on the invoice.',
      forNewSubscription:
        'The discount for subscribers will be received at the billing stage, after the items have been weighed and supplied. The discount will appear on the invoice.',
    },
    generatedText: {
      forCartEstimationBeforePreparation: {
        itemForFree: {
          all: 'Free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} on orders over ₪{{minimumOrder}}',
          delivery:
            'Free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} on delivery orders over ₪{{minimumOrder}}',
          selfPickup:
            'Free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} on self-pickup orders over ₪{{minimumOrder}}',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Free delivery on orders over ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} off delivery fees on orders over ₪{{minimumOrder}}',
          },
          delivery: {
            fullDiscount: 'Free delivery on orders over ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} off delivery fees on orders over ₪{{minimumOrder}}',
          },
          selfPickup: {
            fullDiscount: 'No preparation fees on self-pickup orders over ₪{{minimumOrder}}',
            partialDiscount:
              '{{discountValue}} off preparation fees on self-pickup orders over  ₪{{minimumOrder}}',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: '{{discountValue}} off all orders',
            minimumOrder: '{{discountValue}} off all orders over ₪{{minimumOrder}}',
          },
          delivery: {
            smallMinimumOrder: '{{discountValue}} off on delivery orders',
            minimumOrder: '{{discountValue}} off on delivery orders over ₪{{minimumOrder}}',
          },
          selfPickup: {
            smallMinimumOrder: '{{discountValue}} off on self-pickup orders',
            minimumOrder: '{{discountValue}} off on self-pickup orders over ₪{{minimumOrder}}',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} on orders over ₪{{minimumOrder}}',
          delivery:
            '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} on delivery orders over ₪{{minimumOrder}}',
          selfPickup:
            '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} on self-pickup orders over ₪{{minimumOrder}}',
        },
      },
      forCartEstimationAfterPreparation: {
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Free delivery on orders over ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} off delivery fees on orders over ₪{{minimumOrder}}',
          },
          delivery: {
            fullDiscount: 'Free delivery on orders over ₪{{minimumOrder}}',
            partialDiscount: '{{discountValue}} off delivery fees on orders over ₪{{minimumOrder}}',
          },
          selfPickup: {
            fullDiscount: 'No preparation fees on self-pickup orders over ₪{{minimumOrder}}',
            partialDiscount:
              '{{discountValue}} off preparation fees on self-pickup orders over  ₪{{minimumOrder}}',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: '{{discountValue}} off all orders',
            minimumOrder: '{{discountValue}} off all orders over ₪{{minimumOrder}}',
          },
          delivery: {
            smallMinimumOrder: '{{discountValue}} off on delivery orders',
            minimumOrder: '{{discountValue}} off on delivery orders over ₪{{minimumOrder}}',
          },
          selfPickup: {
            smallMinimumOrder: '{{discountValue}} off on self-pickup orders',
            minimumOrder: '{{discountValue}} off on self-pickup orders over ₪{{minimumOrder}}',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} on orders over ₪{{minimumOrder}}',
          delivery:
            '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} on delivery orders over ₪{{minimumOrder}}',
          selfPickup:
            '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} on self-pickup orders over ₪{{minimumOrder}}',
        },
      },
      forNewCustomer: {
        itemForFree: {
          all: 'Free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with your first order on our website',
          delivery:
            'Free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with your first delivery order on our website',
          selfPickup:
            'Free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with your first self-pickup order on our website',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Free delivery with your first order on our website',
            partialDiscount:
              '{{discountValue}} off delivery fees with your first order on our website',
          },
          delivery: {
            fullDiscount: 'Free delivery with your first order on our website',
            partialDiscount:
              '{{discountValue}} off delivery fees with your first order on our website',
          },
          selfPickup: {
            fullDiscount: 'No preparation fees with your first self-pickup order on our website',
            partialDiscount:
              '{{discountValue}} off preparation fees with your first self-pickup order on our website',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: '{{discountValue}} off with your first order on our website',
          },
          delivery: {
            smallMinimumOrder:
              '{{discountValue}} off with your first delivery order on our website',
          },
          selfPickup: {
            smallMinimumOrder:
              '{{discountValue}} off with your first self-pickup order on our website',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with your first order on our website',
          delivery:
            '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with your first delivery order on our website',
          selfPickup:
            '{{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with your first self-pickup order on our website',
        },
      },
      forSubscription: {
        itemForFree: {
          all: 'Subscriber Exclusive: free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with every order',
          delivery:
            'Subscriber Exclusive: free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with every delivery order',
          selfPickup:
            'Subscriber Exclusive: free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with every self-pickup order',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Subscriber Exclusive: Free delivery with every order!',
            partialDiscount:
              'Subscriber Exclusive: {{discountValue}} off delivery fees with every order!',
          },
          delivery: {
            fullDiscount: 'Subscriber Exclusive: Free delivery with every order!',
            partialDiscount:
              'Subscriber Exclusive: {{discountValue}} off delivery fees with every order!',
          },
          selfPickup: {
            fullDiscount: 'Subscriber Exclusive: No preparation fees with every self-pickup order!',
            partialDiscount:
              'Subscriber Exclusive: {{discountValue}} off preparation fees with every self-pickup order!',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'Subscriber Exclusive: {{discountValue}} off all orders',
          },
          delivery: {
            smallMinimumOrder: 'Subscriber Exclusive: {{discountValue}} off on delivery orders',
          },
          selfPickup: {
            smallMinimumOrder: 'Subscriber Exclusive: {{discountValue}} off on self-pickup orders',
          },
        },
        itemRefund: {
          all: 'Subscriber Exclusive: {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with every order!',
          delivery:
            'Subscriber Exclusive: {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with every delivery order!',
          selfPickup:
            'Subscriber Exclusive: {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with every self-pickup order!',
        },
      },
      forNewSubscription: {
        itemForFree: {
          all: 'New Subscribers: free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with your first subscription order',
          delivery:
            'New Subscribers: free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with your first delivery order',
          selfPickup:
            'New Subscribers: free {{valueFormulaSourceQuantity}} {{-sellingUnitName}} of {{-storeProductSourceName}} with your first self-pickup order',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'New Subscribers: Free delivery with your first subscription order',
            partialDiscount:
              'New Subscribers: {{discountValue}} off delivery fees with your first subscription order',
          },
          delivery: {
            fullDiscount: 'New Subscribers: Free delivery with your first subscription order',
            partialDiscount:
              'New Subscribers: {{discountValue}} off delivery fees with your first subscription order',
          },
          selfPickup: {
            fullDiscount: 'New Subscribers: No preparation fees with your first subscription order',
            partialDiscount:
              'New Subscribers: {{discountValue}} off preparation fees with your first subscription order',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder:
              'New Subscribers: {{discountValue}} off with your first subscription order',
          },
          delivery: {
            smallMinimumOrder:
              'New Subscribers: {{discountValue}} off with your first delivery order',
          },
          selfPickup: {
            smallMinimumOrder:
              'New Subscribers: {{discountValue}} off with your first self-pickup order',
          },
        },
        itemRefund: {
          all: 'New Subscribers: {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with your first subscription order',
          delivery:
            'New Subscribers: {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with your first delivery order',
          selfPickup:
            'New Subscribers: {{-storeProductSourceName}} for ₪{{productPromotedPrice}} per {{-primaryQuantityUnit}} with your first self-pickup order',
        },
      },
    },
  },
  pwa: {
    installerPromptText: 'Add us as an app \n to your home screen',
    iosPromptTitle: 'Add to Home Screen',
    iosPromptDescription:
      'For a faster shopping experience, add ״{{-storeName}}״ to your home screen in 2 simple steps',
    iosPromptStep1: '1. Tap on the "Share" button in your browser',
    iosPromptStep2: '2. Tap on "Add to Home Screen"',
  },
  serverGenericErrorDescription: 'An internal server error has occurred',
  seo: {
    products: 'products',
    genericCategoryMetadata:
      'At {{websiteName}}, we offer a wide variety of {{categoryName}} with unbeatable quality - ',
    andMore: 'and more.',
    similarProducts: 'Similar products:',
  },
  subCatalog: {
    catalogWasUpdated: 'Please note! The catalog has been updated',
  },
  homePage: {
    toAllProducts: 'To all products',
    previewMode: 'Preview mode',
  },
  membership: {
    youEarnedPoints: '{{pointsNumber}} membership points',
    youHaveBenefits: '{{benefitsNumber}} membership benefits',
    forAllBenefits: 'All benefits',
    customerClub: 'Membership club',
    customerClubDesktop: 'Membership club',
    memberNumber: 'Member ID {{memberNumber}}',
    points: 'Points',
    equalToShekel: 'Equals to {{equalNumber}} shekels',
    benefits: 'Benefits',
    redeemPoints: 'Redeem points',
    use: 'Redeem',
    remove: 'Remove',
    errorValueIsMoreThanPoints: 'The value cannot exceed your total points.',
    redeemedPointsToShekel: 'Redeemed {{points}} points = ₪{{points}}',
    calculatedByExternalCompany:
      'The discounts and benefits are calculated by an external platform and will be reflected on the tax invoice after the order is processed.',
    validUntil: 'Valid until {{expirationDate}}',
    usedAmountOfPoints: 'Redeemed {{points}} points',
    membershipClubDiscount: 'Membership club discounts',
  },
  smallEC: {
    promotionsAndDiscounts: 'Promotions and discounts',
    chooseFromTheList: 'Choose from the list',
    addProducts: 'Add products',
    pickupPointNotAvailable:
      'Sorry, the selected self-pickup time is no longer available. Please choose a different time.',
  },
};

export default englishMessages;

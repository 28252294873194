import React, { FC, useMemo } from 'react';

import { Box, Button, useTheme } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import Icon from 'ui/common/icons/Icon';

import { useRenderPrice } from 'hooks';

import { BeatLoader } from 'react-spinners';
import classNames from 'classnames';
import useStyles from './styles';

import { IPlaceOrderButton } from './types';

const PlaceOrderButton: FC<IPlaceOrderButton> = ({
  orderMode,
  loading,
  price,
  disabled,
  onClick,
  children,
  activePaymentType,
  ...rest
}) => {
  const classes = useStyles({ orderMode, loading });

  const renderPrice = useRenderPrice();

  const theme = useTheme();

  const placeOrderButtonText = useMemo(() => {
    switch (orderMode) {
      case 'addSubscription':
      case 'editSubscription':
        return 'button.placeSubscription';
      case 'new':
      case 'edit':
      default:
        return activePaymentType === 'bit' ? 'button.paymentViaBit' : 'button.placeOrder';
    }
  }, [orderMode, activePaymentType]);

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled || loading}
      onClick={onClick}
      classes={{ root: classes.root }}
      {...rest}
    >
      {loading ? (
        <Box className={classes.loader}>
          <BeatLoader color={theme.palette.primary.light} size={10} />
        </Box>
      ) : (
        <Box display="flex">
          <Box display="flex" alignItems="center">
            {activePaymentType !== 'bit' && (
              <Icon color="action" classes={{ root: classes.confirmIcon }} name="icon-lock" />
            )}
            <Typography
              color="light"
              variant="body1"
              fontWeight="medium"
              className={classNames(
                classes.placeOrderText,
                'white-space-no-wrap text-transform-initial',
              )}
            >
              {children || placeOrderButtonText}
            </Typography>
          </Box>
          <Box mx={1.25}>
            <Typography color="light" variant="body1" fontWeight="medium">
              |
            </Typography>
          </Box>
          <Typography color="light" variant="body1" fontWeight="medium">
            {renderPrice(price)}
          </Typography>
        </Box>
      )}
    </Button>
  );
};

export default PlaceOrderButton;

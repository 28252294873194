import {
  TPrepareToPlaceSubscriptionSuccessAction,
  TStartAddSubscriptionAction,
  TStartEditSubscriptionAction,
  TStopAddEditSubscriptionAction,
} from 'store/modules/subscriptions/types';
import {
  PREPARE_TO_PLACE_SUBSCRIPTION_SUCCESS,
  START_ADD_SUBSCRIPTION,
  START_EDIT_SUBSCRIPTION,
  STOP_ADD_EDIT_SUBSCRIPTION,
} from 'store/modules/subscriptions/constants';

const startAddSubscription: TStartAddSubscriptionAction = () => ({
  type: START_ADD_SUBSCRIPTION,
});

const startEditSubscription: TStartEditSubscriptionAction = (subscription) => ({
  type: START_EDIT_SUBSCRIPTION,
  payload: { subscription },
});

const stopAddEditSubscription: TStopAddEditSubscriptionAction = () => ({
  type: STOP_ADD_EDIT_SUBSCRIPTION,
});

const prepareToPlaceSubscriptionSuccess: TPrepareToPlaceSubscriptionSuccessAction = (
  prepareToPlaceSubscription,
) => ({
  type: PREPARE_TO_PLACE_SUBSCRIPTION_SUCCESS,
  payload: prepareToPlaceSubscription,
});

export {
  startAddSubscription,
  startEditSubscription,
  stopAddEditSubscription,
  prepareToPlaceSubscriptionSuccess,
};

import { ROUTER_HISTORY_UPDATE } from './constants';

import { TRouterHistoryUpdateAction } from './types';

const routerHistoryUpdate: TRouterHistoryUpdateAction = (url) => ({
  type: ROUTER_HISTORY_UPDATE,
  payload: url,
});

export { routerHistoryUpdate };

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesPromotedProductsSlider } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPromotedProductsSlider, { isOpenedTopCart: boolean }> =>
    createStyles({
      promotedProductsSlider: {
        maxWidth: '1240px',
        margin: '0 auto',
      },
      productsWrapper: ({ isOpenedTopCart }) => ({
        maxWidth: isOpenedTopCart ? 898 : '100%',
        '& .swiper-wrapper': {
          boxSizing: 'border-box',
        },
      }),
      navigationButton: {
        width: '34px',
        height: '34px',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        marginLeft: '12px',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        },
      },
      navigationIcon: {
        fontSize: '14px',
        color: theme.palette.background.paper,
      },
      swiper: {
        padding: theme.spacing(7 / 8, 0, 10 / 8),
      },
      linkLabel: {
        fontSize: '16px',
        lineHeight: '20px',
        color: theme.palette.primary.main,
        fontWeight: 500,
        marginRight: '10px',
      },
      sectionTitle: {
        fontSize: '36px',
        lineHeight: '38px',
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontFamily: 'Heebo',
      },
    }),
);

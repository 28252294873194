import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUseFooterTerms, TUseFooterTerms } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseFooterTerms, TUseFooterTerms> =>
    createStyles({
      termsLink: {
        display: 'flex',
        justifyContent: 'space-between',
        '&:not(:last-child)': {
          paddingRight: theme.spacing(1.25), // 10px,
          '&::after': {
            content: '" "',
            height: '100%',
            width: theme.spacing(0.125), // 1px,
            backgroundColor: theme.palette.background.paper,
            marginLeft: theme.spacing(1.25), // 10px,
          },
        },
      },
      termsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:not(:last-child)': {
          paddingRight: theme.spacing(1.25), // 10px,
          '&::after': {
            content: '" "',
            height: '100%',
            width: theme.spacing(0.125), // 1px,
            backgroundColor: theme.palette.background.paper,
            marginLeft: theme.spacing(1.25), // 10px,
          },
        },
      },
      termsItem: {
        textAlign: 'center',
      },
    }),
);

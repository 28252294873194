import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import {
  ICheckBox,
  TCheckboxFontSizeObj,
  TCheckboxSizeObj,
  TRenderCheckboxFontSize,
  TRenderCheckboxSize,
  TStylesCheckBox,
} from './types';

const renderCheckboxSize: TRenderCheckboxSize = (size = 'medium') => {
  const sizeObj: TCheckboxSizeObj = {
    xsmall: 2.5, // 20px,
    small: 2.75, // 22px
    medium: 3, // 24px
    large: 3.25, // 26px
  };

  return sizeObj[size];
};

const renderCheckboxFontSize: TRenderCheckboxFontSize = (size = 'medium') => {
  const sizeObj: TCheckboxFontSizeObj = {
    xsmall: 1.375, // 11px,
    small: 1.5, // 12px
    medium: 1.625, // 13px
    large: 1.75, // 14px
  };

  return sizeObj[size];
};

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCheckBox, ICheckBox> =>
    createStyles({
      icon: {
        borderRadius: theme.spacing(0.5), // 4px
        border: `1px solid ${theme.palette.grey.A200}`,
        backgroundColor: theme.palette.background.paper,
        width: ({ size }) => theme.spacing(renderCheckboxSize(size)),
        height: ({ size }) => theme.spacing(renderCheckboxSize(size)),
      },
      checkedIcon: {
        backgroundColor: theme.palette.primary.main,
        fontSize: ({ size }) => theme.spacing(renderCheckboxFontSize(size)),
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
);

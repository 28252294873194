import { TActionResult, TRexailAxiosConfig } from 'api/Api/BaseApi/types';
import axios, { AxiosInstance } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

import Router from 'next/router';

import { dialogActions, notifyActions, TStore } from 'store';
import { jweTokenUpdateAction, logOutRequest } from 'store/modules/auth/actions';
import { isServer } from 'utils/helpers/isServer';

export default class BaseApi {
  static store: TStore;

  static renewJwePromise?: Promise<void>;

  static instance(
    config: TRexailAxiosConfig = {},
    axiosRetryConfig?: IAxiosRetryConfig,
  ): AxiosInstance {
    const baseConfig = { handleError: true, ...config };
    const rootInstance: AxiosInstance = axios.create({
      baseURL: process.env.NEXT_PUBLIC_SERVER_API,
    });

    if (axiosRetryConfig) {
      axiosRetry(rootInstance, axiosRetryConfig);
    }

    rootInstance.interceptors.request.use((requestConfig) => {
      const newConfig = { ...requestConfig };
      const isClient = newConfig.url?.includes('/client');
      const requiresAuthentication = newConfig.url?.includes('/client/client');

      if (isClient) {
        newConfig.headers['X-Source-Platform'] = 'Retailer-Website';
      }

      if (this.store) {
        const {
          auth: { deviceId, jweToken, sessionOnlyJweToken, onClientBehalf },
          store: { websiteDetails },
        } = this.store.getState();

        const clientJwe = sessionOnlyJweToken || jweToken;

        if (
          isClient &&
          !newConfig.headers['X-Website'] &&
          websiteDetails &&
          websiteDetails.jsonWebEncryption
        ) {
          newConfig.headers['X-Website'] = websiteDetails.jsonWebEncryption;
        }

        // add Device-Id headers if deviceId exist
        if (deviceId && isClient && !onClientBehalf) {
          newConfig.headers['Device-Id'] = deviceId;
        }

        // add tarfash headers if clientJwe exist
        if (clientJwe && requiresAuthentication) {
          newConfig.headers.tarfash = clientJwe;
        }

        if (onClientBehalf) {
          newConfig.url =
            newConfig.url &&
            newConfig.url.replace('client/client', 'retailer/back-office/on-client-behalf');
        }
      }

      if (baseConfig.setLoading) {
        baseConfig.setLoading(true);
      }

      return newConfig;
    });

    // actionResult handling interceptor
    rootInstance.interceptors.response.use(
      (response) => {
        const isJSON = response.headers['content-type'].includes('application/json');

        if (!isJSON) {
          return response.data;
        }

        const actionResult = response.data;

        if (!actionResult.success && baseConfig.handleError) {
          // eslint-disable-next-line no-console
          console.log(
            `BaseAPI Error: ${JSON.stringify(response.config)}. ActionResult: ${JSON.stringify(
              actionResult,
            )}`,
          );
          if (this.store) {
            this.store.dispatch(
              notifyActions.showNotification({
                message: actionResult.resolvedMessage,
              }),
            );
          }
          return Promise.reject(actionResult);
        }

        return actionResult; // strip response, return actionResult
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error);

        if (this.store) {
          if (error.response) {
            // JWE expired
            if (error.response.status === 403 || error.response.status === 412) {
              this.store.dispatch(logOutRequest());
            } else if (error.response.status === 503 && !isServer()) {
              const {
                dialog: { open },
              } = this.store.getState();
              // redirect to /maintenance and hide dialog if it's open
              Router.push('/maintenance').finally(
                () => open && this.store.dispatch(dialogActions.hideDialog()),
              );
            } else if (error.response.status === 401) {
              if (!this.renewJwePromise) {
                this.renewJwePromise = this.renewJweToken()
                  .then((actionResult) => {
                    if (!actionResult.success) {
                      this.store.dispatch(logOutRequest());
                      return Promise.reject();
                    }
                    this.store.dispatch(jweTokenUpdateAction(actionResult.data));
                    return Promise.resolve();
                  })
                  .finally(() => {
                    this.renewJwePromise = undefined;
                  });
              }

              return this.renewJwePromise.then(() => this.instance().request(error.config));
            } else if (baseConfig.handleError) {
              this.store.dispatch(
                notifyActions.showNotification({
                  message: error.message,
                }),
              );
            }
          } else if (error.message && typeof error.message === 'string' && baseConfig.handleError) {
            this.store.dispatch(
              notifyActions.showNotification({
                message: error.message,
              }),
            );
          }
        }

        const errorActionResult: TActionResult<null> = {
          success: false,
          data: null,
          message: error.message,
          resolvedMessage: error.message,
          code: error.response ? error.response.status : 0,
        };

        return Promise.reject(errorActionResult);
      },
    );

    // loading interceptor
    rootInstance.interceptors.response.use(
      (response) => {
        if (baseConfig.setLoading) {
          baseConfig.setLoading(false);
        }

        return response;
      },
      (error) => {
        if (baseConfig.setLoading) {
          baseConfig.setLoading(false);
        }

        return Promise.reject(error);
      },
    );

    return rootInstance;
  }

  static init(store: TStore): void {
    this.store = store;
    this.renewJwePromise = undefined;
  }

  static renewJweToken(): Promise<TActionResult<string>> {
    return this.instance({ handleError: false }).post('/client/tarfash/renew');
  }
}

import { TCategoryReducer } from './types';

import { GET_CATEGORIES_SUCCESS } from './constants';

import { initialCategoryState } from './initialState';

const categoryReducer: TCategoryReducer = (state = initialCategoryState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { categoryReducer };

import React, { FC } from 'react';

import { Button } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';

import useStyles from './styles';

import { INavigationButton } from './types';

const NavigationButton: FC<INavigationButton> = ({
  label = 'header.navigation',
  onClick,
  iconName = 'icon-navigation',
}) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.navigationButtonRoot}
      onClick={onClick}
      TouchRippleProps={{ classes: { child: classes.buttonRipple } }}
    >
      <Icon color="action" name={iconName} classes={{ root: classes.navigationIcon }} />
      <Typography fontSize={14} fontWeight="medium" color="light">
        {label}
      </Typography>
    </Button>
  );
};

export default NavigationButton;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { TStylesPaymentInfoSection } from './types';

export const downBreakpoint: Breakpoint = 'xs';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPaymentInfoSection> =>
    createStyles({
      root: {
        width: '100%',
        minHeight: theme.spacing(7), // 56px
        padding: theme.spacing(1.25, 2.125, 1.25, 1.25), // 10px 17px 10px 10px
        borderRadius: theme.spacing(0.75), // 6px
        backgroundColor: theme.palette.grey[50], // mint-green
        alignItems: 'center',
        display: 'flex',
        textAlign: 'left',
        [theme.breakpoints.down(downBreakpoint)]: {
          minHeight: 'auto',
          padding: theme.spacing(2, 1.875, 2, 1.375), // 16px 15px 16px 11px;
          alignItems: 'stretch',
        },
      },
      iconRoot: {
        fontSize: theme.spacing(4.25), // 34px
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(1), // 8px
        [theme.breakpoints.down(downBreakpoint)]: {
          marginRight: theme.spacing(1.375), // 11px
        },
      },
      typographyRoot: {
        lineHeight: `${theme.spacing(2.25)}px`, // 18px
        textAlign: 'left',
        letterSpacing: theme.spacing(-0.025),
      },
    }),
);

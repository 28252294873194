import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { Box, IconButton, useTheme } from '@material-ui/core';
import { IStoreProductDisplay } from 'store';

import Icon from 'ui/common/icons/Icon';
import { useMobile } from 'hooks/useMobile';
import StoreProductCard from 'ui/common/StoreProductCard';
import SwiperClass from 'swiper/types/swiper-class';

import { Swiper, SwiperSlide } from 'swiper/react';
import { TMixedType } from 'types';
import { TProductCardViewMode } from 'ui/mobile/buttons/ProductCardViewMode/types';
import { ISimilarProductsSlider } from './types';

import useStyles from './styles';

const SimilarProductsSlider: FC<ISimilarProductsSlider> = (props) => {
  const { sourceEvent, slideItems, displayArrows, slideWidth, slidesPerView } = props;
  const classes = useStyles({ slideItems, slideWidth, slidesPerView });

  const { isMobile } = useMobile();

  const theme = useTheme();

  const [selectedProductCardTooltipId, setSelectedProductCardTooltipId] =
    useState<TMixedType | null>(null);

  const swiper = useRef<SwiperClass | null>(null);

  const swiperWrapper = useRef<HTMLDivElement | null>(null);

  const goNext = useCallback(() => {
    swiper.current?.slideNext();
  }, []);

  const goPrev = useCallback(() => {
    swiper.current?.slidePrev();
  }, []);

  const slide = useCallback(
    (storeProduct: IStoreProductDisplay) => {
      const mobileProps = isMobile && {
        isTooltipOpen: selectedProductCardTooltipId === storeProduct.id,
        onProductTooltipClick: (productId: TMixedType) =>
          setSelectedProductCardTooltipId(
            selectedProductCardTooltipId === productId ? null : productId,
          ),
        viewMode: 'multiple' as TProductCardViewMode,
      };

      return (
        <StoreProductCard
          storeProductDisplay={storeProduct}
          cardWidth={slideWidth}
          sourceEvent={sourceEvent}
          partialKeyboardSupport
          {...mobileProps}
        />
      );
    },
    [isMobile, selectedProductCardTooltipId, slideWidth, sourceEvent],
  );

  const sliderDirection = useMemo(() => {
    if (isMobile) {
      return theme.direction;
    }
  }, [isMobile, theme.direction]);

  return (
    <div className={classes.sliderWrapper} ref={swiperWrapper}>
      {displayArrows && (
        <IconButton classes={{ root: classes.navigationButtonNext }} onClick={goPrev}>
          <Icon
            name="icon-arrow-right"
            fontSize="small"
            color="primary"
            classes={{ root: classes.iconArrow }}
          />
        </IconButton>
      )}
      <Box className={classes.sliderInnerWrapper}>
        <Swiper
          onInit={(element: SwiperClass) => {
            swiper.current = element;
          }}
          className={classes.swiper}
          onSlideChange={() => setSelectedProductCardTooltipId(null)}
          dir={theme.direction}
          key={theme.direction}
          {...props}
        >
          {slideItems.map((item) => {
            return (
              <SwiperSlide dir={sliderDirection} key={item.id} className={classes.swiperSlide}>
                {slide(item)}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      {displayArrows && (
        <IconButton classes={{ root: classes.navigationButtonPrev }} onClick={goNext}>
          <Icon
            name="icon-arrow-left"
            fontSize="small"
            color="primary"
            classes={{ root: classes.iconArrow }}
          />
        </IconButton>
      )}
    </div>
  );
};

export default SimilarProductsSlider;

import { useCallback, useEffect, useMemo, useState } from 'react';

import { BaseApi } from 'api';

import { TDropDownOption } from 'ui/common/inputs/DropDown';
import { TCityServer } from 'types';

import { TActionResult } from 'api/Api/BaseApi/types';
import { TUseCity } from './types';

const useCity: TUseCity = () => {
  const [cities, setCities] = useState<TCityServer[]>([]);
  const [militaryBases, setMilitaryBases] = useState<TCityServer[]>([]);

  const fetchCities = useCallback(async () => {
    try {
      const actionResult = await BaseApi.instance().get<
        TActionResult<TCityServer[]>,
        TActionResult<TCityServer[]>
      >('/client/public/cities');
      const militaryBasesArray: TCityServer[] = [];
      const citiesArray: TCityServer[] = [];

      actionResult.data.forEach((city) => {
        if (city.settlementType.name === 'city') {
          citiesArray.push(city);
          // TODO check it when Aviad will add militaryBase to the list(key screens commit)
        } else if (city.settlementType.name === 'militaryBase') {
          militaryBasesArray.push(city);
        }
      });
      setCities(citiesArray);
      setMilitaryBases(militaryBasesArray);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('debounce ~ e', e);
    }
  }, []);

  useEffect(() => {
    fetchCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropdownCities = useMemo<TDropDownOption[]>(() => {
    return cities.map(({ id, name }) => ({
      id,
      value: id,
      text: name,
    }));
  }, [cities]);

  return {
    cities,
    militaryBases,
    dropdownCities,
  };
};

export default useCity;

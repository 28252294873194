import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesDeliveryDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDeliveryDialog> =>
    createStyles({
      root: {
        minHeight: '100vh',
        [theme.breakpoints.up('md')]: {
          minHeight: 'auto',
          height: '80vh',
        },
      },
      header: {
        overflow: 'hidden',
        height: '132px',
        padding: theme.spacing(0, 10 / 8, 0, 14 / 8),
        background: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 32 / 8, 0, 52 / 8),
          height: '160px',
        },
      },
      content: {
        padding: theme.spacing(0, 14 / 8, 40 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 50 / 8, 50 / 8),
          overflow: 'auto',
        },
      },
      editBlock: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(20 / 8, 0, 20 / 8, 0),

        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(30 / 8, 0),
        },
      },
      iconRoot: {
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(6 / 8, 1),
        },
      },
      editText: {
        marginLeft: theme.spacing(4 / 8),
      },
      editIcon: {
        fontSize: '18px',
      },
      closeIcon: {
        fontSize: '18px',
        [theme.breakpoints.up('md')]: {
          fontSize: '16px',
        },
      },
      headerImage: {
        height: '118px',
        [theme.breakpoints.up('md')]: {
          height: '148px',
        },
      },
      imageWrapper: {
        display: 'flex',
        position: 'absolute',
        right: 10,
        bottom: 0,
        [theme.breakpoints.up('md')]: {
          right: 0,
          position: 'relative',
          alignItems: 'flex-end',
        },
      },
      headerContent: {
        marginTop: theme.spacing(46 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(54 / 8),
        },
      },
      deliveryIcon: {
        fontSize: '24px',
        marginRight: theme.spacing(8 / 8),

        [theme.breakpoints.up('md')]: {
          fontSize: '32px',
          marginRight: theme.spacing(12 / 8), // 12px
        },
      },
      deliveryBlock: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: theme.spacing(18 / 8),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(26 / 8),
        },
      },
      coordinationBlock: {
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(24 / 8),
        paddingBottom: theme.spacing(22 / 8),

        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(30 / 8, 0, 0, 0),
        },
      },
      coordinationTitle: {
        marginBottom: theme.spacing(6 / 8),

        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(8 / 8),
        },
      },
      linkText: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
      },
      errorMessage: {
        color: theme.palette.error.main,
        fontSize: '16px',
      },
      divider: {
        backgroundColor: theme.palette.grey[200],
      },
      clientAddress: {
        fontSize: theme.spacing(15 / 8),
      },
      popupHeader: {
        fontSize: theme.spacing(18 / 8),
        lineHeight: '22px',
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(theme.direction === 'rtl' ? 30 / 8 : 24 / 8),
          lineHeight: '34px',
        },
      },
      tabsWrapper: {
        width: '278px',
        [theme.breakpoints.up('md')]: {
          width: '310px',
        },
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesTextAndImages } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesTextAndImages, { isOpenedTopCart: boolean }> =>
    createStyles({
      categoryImage: {
        borderRadius: '6px',
        minWidth: '160px',
        maxWidth: '294px',
        height: '160px',
        [theme.breakpoints.up('md')]: {
          maxWidth: '100%',
          height: ({ isOpenedTopCart }) => (isOpenedTopCart ? '208px' : '294px'),
          width: ({ isOpenedTopCart }) => (isOpenedTopCart ? '208px' : '294px'),
        },
      },
      categoryImageBig: {
        borderRadius: '6px',
        height: '160px',
        minWidth: '332px',
        maxWidth: '600px',
        [theme.breakpoints.up('md')]: {
          maxWidth: '100%',
          height: ({ isOpenedTopCart }) => (isOpenedTopCart ? '208px' : '294px'),
          width: ({ isOpenedTopCart }) => (isOpenedTopCart ? '438px' : '610px'),
        },
      },
      gridContainer: {
        display: 'grid',
        gap: '12px',
        gridTemplateColumns: 'repeat(12, 1fr)',
        maxWidth: '600px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: '100%',
          margin: 'auto',
          gap: '22px',
          gridTemplateColumns: 'repeat(12, 1fr)',
        },
      },
      gridBigItem: {
        gridColumn: 'span 12',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 6',
        },
      },
      gridItem: {
        gridColumn: 'span 6',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 3',
        },
      },
      overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
        padding: '0px 20px',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          padding: '0px 31px',
        },
      },
      sectionTitle: {
        fontSize: '26px',
        lineHeight: '28px',
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontFamily: 'Heebo',
        [theme.breakpoints.up('md')]: {
          fontSize: '36px',
          lineHeight: '38px',
        },
      },
      linkLabel: {
        fontSize: '16px',
        lineHeight: '20px',
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      categoriesGridWrapper: {
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
          maxWidth: '1240px',
        },
      },
      categoriesGridContainer: {
        padding: '0 14px',
        [theme.breakpoints.up('md')]: {
          padding: 0,
          maxWidth: ({ isOpenedTopCart }) => (isOpenedTopCart ? 898 : '100%'),
        },
      },
      categoriesGridBackground: {
        padding: '40px 0px',
        backgroundColor: theme.palette.grey[50],
        [theme.breakpoints.up('md')]: {
          padding: '80px 0px 90px',
        },
      },
      categoriesGridImage: {
        position: 'absolute',
        top: '-78px',
        width: '100px',
        height: '100px',
        left: '-30px',
        [theme.breakpoints.up('md')]: {
          top: '-164px',
          width: '180px',
          height: '180px',
          left: '-116px',
        },
      },
      loadMoreButton: {
        width: '160px',
        [theme.breakpoints.up('md')]: {
          width: '150px',
        },
      },
      loadMoreButtonLabel: {
        fontSize: '16px',
        color: theme.palette.grey.A700,
      },
      buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
      },
      categoryName: {
        fontSize: '22px',
        lineHeight: '26px',
        color: theme.palette.background.paper,
        fontWeight: 700,
        fontFamily: 'Heebo',
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
        [theme.breakpoints.up('md')]: {
          fontSize: ({ isOpenedTopCart }) => (isOpenedTopCart ? '32px' : '45px'),
          lineHeight: ({ isOpenedTopCart }) => (isOpenedTopCart ? '32px' : '45px'),
          textShadow: ({ isOpenedTopCart }) =>
            isOpenedTopCart ? '0 2px 4px rgba(0, 0, 0, 0.3)' : '0 3px 6px rgba(0, 0, 0, 0.3)',
        },
      },
    }),
);

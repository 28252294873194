import { IPromotion } from 'store/modules/promotions';
import { TOrderDetailsReducerState, TOrderReducerState, TUserReducerState } from 'store';
import { forNewCustomer } from 'utils/helpers/promotions/general/forNewCustomer';
import { forCartEstimationBeforePreparation } from './forCartEstimationBeforePreparation';

export function arePromotionConditionsFulfilled(
  promotion: IPromotion,
  cartEstimation: number,
  orderDetails: TOrderDetailsReducerState,
  detailsAtStoreLevel: TUserReducerState['detailsAtStoreLevel'],
): boolean {
  switch (promotion.promotionType.name) {
    case 'forCartEstimationBeforePreparation':
      return forCartEstimationBeforePreparation(promotion, cartEstimation, orderDetails);
    case 'forNewCustomer':
      return forNewCustomer(promotion, orderDetails, detailsAtStoreLevel);
    case 'forSubscription':
    case 'forNewSubscription':
      return true;
    default:
      return false;
  }
}

export function canItemRefundPromotionBeApplied(
  promotion: IPromotion,
  order: TOrderReducerState,
): boolean {
  if (promotion.appliedIfProductNotInCart) {
    return true;
  }

  return order.items.some(
    (item) => !item.isRemoved && item.storeProduct.id === promotion.storeProductSource?.id,
  );
}

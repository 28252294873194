import { Box, Divider } from '@material-ui/core';
import { OrderService } from 'api';
import { format } from 'date-fns';
import { useRenderDate } from 'hooks';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { validateAndEditOrder } from 'store/modules/orderEditing/actions';
import CTAButton from 'ui/common/buttons/CTAButton';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import { dateFormatInOpenOrderHistory } from 'utils/constants';

import useStyles from './styles';
import { IActivePreviousOrder } from './types';

const ActivePreviousOrder: FC<IActivePreviousOrder> = ({
  activePreviousOrder: { activeOrder, activeOrderProps },
  onEditStart,
  onViewClick,
}) => {
  const { renderDay } = useRenderDate();
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const handleViewOrder = useCallback(() => {
    router.push(`/account/orders/view/${activeOrder.id}`);
    if (onViewClick) {
      onViewClick();
    }
  }, [activeOrder.id, onViewClick, router]);

  const handleEditOrder = useCallback(
    (prevOrder) => {
      OrderService.getOrder(prevOrder.id, { setLoading }).then((actionResult) => {
        dispatch(validateAndEditOrder(actionResult.data, onEditStart));
      });
    },
    [dispatch, onEditStart],
  );

  const activeOrderDetails = useMemo(
    () => (
      <Typography variant="body1" className={classes.mainText}>
        {t('previousOrder.ongoingOrder')}
        {t(renderDay(activeOrderProps.weekDayNumber))}, &nbsp;
        {format(new Date(activeOrderProps.deliveryDate), dateFormatInOpenOrderHistory)}
      </Typography>
    ),
    [activeOrderProps.deliveryDate, activeOrderProps.weekDayNumber, renderDay, classes.mainText, t],
  );

  return (
    <>
      <Box component="section" className={classes.cardWrapper}>
        <Box display="flex" className={classes.cartTitle}>
          <Icon fontSize="inherit" name="icon-alert-circled" color="secondary" />
          <Box ml={6 / 8}>
            <Typography variant="h4" color="secondary">
              {'previousOrder.payAttention'}
            </Typography>
          </Box>
        </Box>
        {activeOrderDetails}
        <Box className={classes.btnsWrapper}>
          <Box mr={1} width={1}>
            <CTAButton size="small" fullWidth onClick={handleViewOrder}>
              {'button.view'}
            </CTAButton>
          </Box>
          <Box ml={1} width={1}>
            <CTAButton
              size="small"
              fullWidth
              className={classes.btnOutlinedBg}
              variant="outlined"
              onClick={() => handleEditOrder(activeOrder)}
              loading={loading}
            >
              {'button.edit'}
            </CTAButton>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default ActivePreviousOrder;

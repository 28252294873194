import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesAvailableService } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesAvailableService,
    { isDialog: boolean; lang?: string; isManyPointsContent?: boolean; isSubscription?: boolean }
  > =>
    createStyles({
      buttonsSection: {
        width: '100%',
        marginTop: theme.spacing(20 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(24 / 8),
        },
      },
      horizontalButtonItem: {
        '&:nth-child(4n)': {
          marginRight: 0,
        },
      },
      hourItem: {
        width: '100%',
        maxWidth: '240px',
        borderRadius: '6px',
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
          border: `1.5px solid ${theme.palette.primary.main}`,
        },
      },
      dateSection: {
        width: '38px',
        margin: theme.spacing(0, 25 / 8, 0, 0),
      },
      hoursSection: {
        marginBottom: theme.spacing(22 / 8),
      },
      mobileCTAButtonRoot: {
        minWidth: '74px',
        minHeight: '68px',
      },
      mobileCTAButtonLabel: {
        display: 'block',
      },
      dropdownTitle: ({ isDialog, isManyPointsContent }) => ({
        marginTop: isDialog ? theme.spacing(28 / 8) : theme.spacing(12 / 8),
        fontSize: isDialog ? '18px' : '16px',
        lineHeight: isDialog ? '22px' : '20px',
        color: isDialog ? theme.palette.secondary.main : theme.palette.text.secondary,
        marginLeft: isManyPointsContent && isDialog ? '16px' : 0,
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(isDialog ? 0 : 8 / 8),
          fontSize: isDialog ? '22px' : '18px',
          lineHeight: isDialog ? '24px' : '20px',
          marginBottom: theme.spacing(11 / 8),
          color: isDialog ? theme.palette.secondary.main : theme.palette.text.secondary,
        },
      }),
      collectionPoint: ({ isDialog }) => ({
        marginTop: theme.spacing(isDialog ? 0 : 12 / 8),
        marginBottom: theme.spacing(8 / 8),
        fontSize: isDialog ? '18px' : '16px',
        lineHeight: isDialog ? '22px' : '20px',
        color: isDialog ? theme.palette.secondary.main : theme.palette.text.secondary,

        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(isDialog ? 4 / 8 : 8 / 8),
          fontSize: isDialog ? '22px' : '18px',
          lineHeight: isDialog ? '24px' : '20px',
          marginBottom: theme.spacing(11 / 8),
          color: isDialog ? theme.palette.secondary.main : theme.palette.text.secondary,
        },
      }),
      dropdownBlock: ({ isDialog, isSubscription }) => ({
        marginBottom: theme.spacing(22 / 8),
        padding: !isSubscription && isDialog ? theme.spacing(0, 14 / 8, 40 / 8) : 0,
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(28 / 8),
          padding: !isSubscription && isDialog ? theme.spacing(34 / 8, 50 / 8, 40 / 8) : 0,
        },
      }),
      containedButton: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      divider: {
        backgroundColor: theme.palette.grey.A100,
      },
      selfPickupPoint: ({ isDialog, isManyPointsContent }) => ({
        color: isDialog ? theme.palette.secondary.main : theme.palette.text.secondary,
        fontSize: isDialog ? '18px' : '16px',
        lineHeight: isDialog ? '22px' : '20px',
        marginLeft: isManyPointsContent ? '16px' : 0,
        [theme.breakpoints.up('md')]: {
          margin: isDialog ? '32px 0px 20px 16px' : '0px 0px 20px',
          fontSize: !isDialog ? '18px' : (theme.direction === 'rtl' && '22px') || '20px',
          lineHeight: '24px',
        },
      }),
      activeSelfPickupPoint: ({ isDialog }) => ({
        color: isDialog ? theme.palette.secondary.main : theme.palette.text.secondary,
        fontSize: isDialog ? '18px' : '16px',
        lineHeight: isDialog ? '22px' : '20px',
        [theme.breakpoints.up('md')]: {
          fontSize: !isDialog ? '18px' : (theme.direction === 'rtl' && '22px') || '20px',
          lineHeight: '24px',
        },
      }),
      selfPickupDetails: {
        margin: theme.spacing(0, 0, 22 / 8),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(4 / 8, 0, 30 / 8),
        },
      },
      selfPickupAddress: {
        fontSize: '16px',
        lineHeight: 1.25,
      },
      pickUpDateLabel: ({ isDialog }) => ({
        fontSize: '18px',
        lineHeight: '22px',
        [theme.breakpoints.up('md')]: {
          fontSize: isDialog ? '22px' : '18px',
          lineHeight: '24px',
        },
      }),
      distance: {
        marginTop: theme.spacing(2 / 8),
        lineHeight: 1.15,
      },
      selfPickupDivider: {
        backgroundColor: theme.palette.grey.A100,
        marginTop: theme.spacing(23 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(30 / 8),
        },
      },
      iconEditSelfPickup: {
        fontSize: '18px',
      },
      btnEditSelfPickup: {
        padding: '10px 0px',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(6 / 8, 8 / 8),
        },
      },
      selfPickupEditLabel: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: 1.25,
        marginLeft: theme.spacing(4 / 8),
      },
      editSelfPickupButton: {
        padding: 0,
        '&:hover': {
          backgroundColor: 'initial',
        },
      },
      coordinationTitle: {
        marginBottom: theme.spacing(14 / 8),
      },
      additionalDeliveryBtnRoot: {
        '&:hover, &:active, &:focus': {
          backgroundColor: 'transparent',
        },
      },
      additionalDeliveryBtnText: {
        padding: 0,
      },
      titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      subscriptionChooseDayAndTimeWrapper: {
        marginTop: theme.spacing(22 / 8),
        marginBottom: theme.spacing(22 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(30 / 8),
          marginBottom: theme.spacing(24 / 8),
        },
      },
      chooseSelfDate: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 500,
      },
      loginButton: {
        minWidth: theme.direction === 'ltr' ? '90px' : '140px',
        fontSize: '16px !important',
        lineHeight: '20px !important',
        maxWidth: '200px !important',
        marginRight: '12px !important',
        height: '40px !important',
        width: '100%',
        [theme.breakpoints.up('md')]: {
          minWidth: theme.direction === 'ltr' ? '180px' : '144px',
          marginRight: '12px !important',
          width: '144px',
        },
      },
      continueShoppingButton: ({ lang }) => ({
        minWidth: theme.direction === 'ltr' ? '180px' : '140px',
        fontSize: '16px !important',
        lineHeight: lang === 'ru' ? '17px !important' : '20px !important',
        maxWidth: '200px !important',
        width: '100%',
        height: '40px !important',
        color: theme.palette.grey.A700,
        fontWeight: 'normal',
        whiteSpace: lang === 'ru' ? 'break-spaces' : 'nowrap',
        [theme.breakpoints.up('md')]: {
          whiteSpace: 'nowrap',
          minWidth: theme.direction === 'ltr' ? '180px' : '144px',
          width: lang === 'ru' ? '190px' : '144px',
        },
      }),
      submitButton: {
        [theme.breakpoints.up('md')]: {
          width: '338px',
        },
      },
      manyPointsDivider: ({ isDialog, isManyPointsContent }) => ({
        backgroundColor: theme.palette.grey.A100,
        margin: !isDialog && isManyPointsContent ? '0px' : '0px 14px',
        [theme.breakpoints.up('md')]: {
          marginLeft: isDialog ? '16px' : 0,
          width: isDialog ? '230px' : '330px',
        },
      }),
      listWrapper: ({ isDialog }) => ({
        width: '100%',
        overflow: 'auto',
        marginTop: '14px',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        [theme.breakpoints.up('md')]: {
          width: isDialog ? '262px' : '330px',
          height: 'auto',
          margin: isDialog ? '0px 0px 0px 16px' : '0px',
        },
      }),
      autoCompleteWrapper: ({ isDialog }) => ({
        padding: isDialog ? '0px 14px' : 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          margin: isDialog ? '0px 0px 26px 16px' : '0px 0px 26px 0px',
          width: isDialog ? '230px' : '330px',
        },
      }),
      pickupWrapper: ({ isDialog }) => ({
        padding: '24px 14px',
        [theme.breakpoints.up('md')]: {
          width: isDialog ? '262px' : '330px',
          padding: '26px 16px',
        },
      }),
      selectedPickup: {
        background: theme.palette.grey[50],
        [theme.breakpoints.up('md')]: {
          borderRadius: '8px',
        },
      },
      activePointContent: ({ isDialog }) => ({
        padding: isDialog ? theme.spacing(26 / 8, 14 / 8, 40 / 8) : 0,
        [theme.breakpoints.up('md')]: {
          padding: isDialog ? theme.spacing(34 / 8, 50 / 8, 40 / 8) : 0,
        },
      }),
      mobileAreaSelectedButton: {
        borderRadius: '23px',
      },
      shareMyLocationMobileButton: {
        marginLeft: '5px',
      },
    }),
);

import i18next from 'i18next';
import { put, PutEffect, SelectEffect, takeEvery } from '@redux-saga/core/effects';
import { UPDATE_LANGUAGE_CONFIG } from 'store/modules/config/constants';
import { configUpdate } from 'store/modules/config/actions';
import { IConfigUpdate, IUpdateLanguageConfig } from './types';

function* updateLanguageConfig({
  payload,
}: IUpdateLanguageConfig): Generator<SelectEffect | PutEffect<IConfigUpdate>, void, never> {
  const { language } = payload;
  const direction = language === 'he' ? 'rtl' : 'ltr';

  i18next.changeLanguage(language, (err) => {
    // eslint-disable-next-line no-console
    if (err) return console.error('something went wrong', err); // TODO make notification
  });

  yield put(
    configUpdate({
      lang: language,
      dir: direction,
    }),
  );
}

function* rootConfigSaga(): Generator {
  yield takeEvery(UPDATE_LANGUAGE_CONFIG, updateLanguageConfig);
}

export default rootConfigSaga;

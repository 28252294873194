import {
  CHECKOUT_FLOW_REQUEST,
  CONTINUE_TO_CART_REQUEST,
  CONTINUE_TO_CHECKOUT_REQUEST,
  IS_INTRODUCTORY_POPUPS_COMPLETED,
  IS_SHOW_LOCATION_UPDATE,
  REDIRECT_AFTER_HOURS_SELECTION_UPDATE,
  UPDATE_KNOWN_ADDRESS_AND_SUB_CATALOG,
} from './constants';

import {
  TCheckoutFlowRequestAction,
  TContinueToCartRequestAction,
  TContinueToCheckoutRequestAction,
  TIntroductoryPopupsCompletedAction,
  TIsShowLocationUpdateAction,
  TRedirectAfterHourSelectionUpdateAction,
  TUpdateKnownAddressAndSubCatalog,
} from './types';

const updateKnownAddressAndSubCatalog: TUpdateKnownAddressAndSubCatalog = (
  knownAddress,
  knownAddressSubCatalog,
) => ({
  type: UPDATE_KNOWN_ADDRESS_AND_SUB_CATALOG,
  payload: { knownAddress, knownAddressSubCatalog },
});

const isShowLocationUpdateAction: TIsShowLocationUpdateAction = (newIsShowLocationPopup) => ({
  type: IS_SHOW_LOCATION_UPDATE,
  payload: {
    isShowLocationPopup: newIsShowLocationPopup,
  },
});

const introductoryPopupsCompletedAction: TIntroductoryPopupsCompletedAction = (
  isIntroductoryPopupsCompleted,
) => ({
  type: IS_INTRODUCTORY_POPUPS_COMPLETED,
  payload: {
    isIntroductoryPopupsCompleted,
  },
});

const redirectAfterHourSelectionUpdateAction: TRedirectAfterHourSelectionUpdateAction = (
  newRedirectAfterHourSelection,
) => ({
  type: REDIRECT_AFTER_HOURS_SELECTION_UPDATE,
  payload: {
    redirectAfterHourSelection: newRedirectAfterHourSelection,
  },
});

const continueToCartRequest: TContinueToCartRequestAction = () => ({
  type: CONTINUE_TO_CART_REQUEST,
});

const continueToCheckoutRequest: TContinueToCheckoutRequestAction = () => ({
  type: CONTINUE_TO_CHECKOUT_REQUEST,
});

const checkoutFlowRequestAction: TCheckoutFlowRequestAction = () => ({
  type: CHECKOUT_FLOW_REQUEST,
});

export {
  isShowLocationUpdateAction,
  redirectAfterHourSelectionUpdateAction,
  continueToCartRequest,
  continueToCheckoutRequest,
  checkoutFlowRequestAction,
  introductoryPopupsCompletedAction,
  updateKnownAddressAndSubCatalog,
};

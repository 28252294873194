import { BaseApi } from 'api/Api';
import {
  TAddStoreToClientStores,
  TApplyForAuthentication,
  TAuthenticate,
  TGetAvailableMethods,
} from './types';

export default class AuthService {
  static getAvailableMethods: TGetAvailableMethods = async (cellPhone, config) => {
    const body = {
      cellPhone,
    };

    return BaseApi.instance({ handleError: false, ...config }).post(
      '/client/get-available-authentication-methods',
      body,
    );
  };

  static applyForAuthentication: TApplyForAuthentication = async (body, config) => {
    return BaseApi.instance({ handleError: false, ...config }).post(
      '/client/apply-for-authentication',
      body,
    );
  };

  static authenticate: TAuthenticate = async (body, config) => {
    return BaseApi.instance({ handleError: false, ...config }).post('/client/authenticate', body);
  };

  static addStoreToClientStores: TAddStoreToClientStores = async (storeId) => {
    return BaseApi.instance().post(
      `/client/client/stores/add-store-to-client-stores`,
      `storeId=${storeId}`,
    );
  };
}

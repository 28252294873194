import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesNavigationButton } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesNavigationButton> =>
    createStyles({
      navigationButtonRoot: {
        textTransform: 'initial',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer',
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      },
      navigationIcon: {
        fontSize: theme.spacing(2.25), // 18px
        marginRight: theme.spacing(0.75), // 6px
      },
      buttonRipple: {
        backgroundColor: `${theme.palette.background.paper} !important`,
        opacity: '0.8 !important',
      },
    }),
);

import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesBannerPrompt } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesBannerPrompt> =>
    createStyles({
      installPromptWrapper: {
        paddingTop: theme.spacing(13 / 8),
        paddingBottom: theme.spacing(26 / 8),
        marginTop: 'auto',
        position: 'fixed',
        bottom: 0,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        minHeight: '222px',
        width: '100vw',
        backgroundColor: '#fafafa',
        boxShadow: '0 -3px 6px 0 rgba(0, 0, 0, 0.2)',
      },
      topSectionWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '20px',
        paddingRight: theme.spacing(6 / 8),
        paddingLeft: theme.spacing(22 / 8),
        marginBottom: theme.spacing(12 / 8),
      },
      bottomSectionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(19 / 8),
        padding: theme.spacing(0, 22 / 8),
      },
      titleTypography: {
        fontFamily: 'Arial',
        fontSize: '18px',
        color: theme.palette.text.secondary,
        fontWeight: 700,
        letterSpacing: 'normal',
      },
      descriptionTypography: {
        fontFamily: 'Arial',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#7b7b7a',
        letterSpacing: 'normal',
      },
      stepTypography: {
        fontFamily: 'Arial',
        fontSize: '14px',
        color: '#7b7b7a',
        fontWeight: 'bold',
        lineHeight: '16px',
        letterSpacing: 'normal',
      },
      closeIcon: {
        fontFamily: 'Arial',
        fontWeight: 400,
        color: '#2d7cf6',
        padding: theme.spacing(12 / 8),
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      addIcon: {
        fontSize: 26,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(13 / 8),
      },
      shareIcon: {
        width: '24px',
        height: '33px',
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(14 / 8),
      },
      backdrop: {
        minHeight: '100vh',
        zIndex: theme.zIndex.modal,
      },
    }),
);

import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuListProps,
  useTheme,
} from '@material-ui/core';

import classNames from 'classnames';

import { useDialog, useHiLoginText, useMobile } from 'hooks';
import { useRouter } from 'next/router';
import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authSelectors, MEMBER_PROMOTION_DISCOUNT_DIALOG, userSelectors } from 'store';
import { getLang } from 'store/modules/config/selectors';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import {
  getOrderDiscounts,
  getOrderMembershipBenefits,
} from 'store/modules/orderDiscounts/selectors';
import { promotionsData } from 'store/modules/promotions/selectors';
import ClientCouponNotice from 'ui/common/ClientCouponNotice';

import Icon, { TIconName } from 'ui/common/icons/Icon';
import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';

import { defaultLogOutMenuItem } from './constants';
import useStyles from './styles';

import { IUserMenu, THandleClickMenuItem, TMenuItem } from './types';

const UserMenu: FC<IUserMenu> = ({
  menuItems = [],
  name = '',
  lastName = '',
  loginText = 'userMenu.signUpLoginText',
  hiText = 'userMenu.hiText',
  size = 'desktop',
  hideMembershipSection,
  overrideClasses,
  ...rest
}) => {
  const { isMobile } = useMobile();

  const { isLoggedIn, onLogin, onLogOut, isHideLogOut, buttonProps, menuProps, isHideText } = rest;

  const { t } = useTranslation();

  const { showDialog } = useDialog();

  const orderDiscounts = useSelector(getOrderDiscounts);

  const orderMembershipBenefits = useSelector(getOrderMembershipBenefits);

  const { clubMembershipProfile } = useSelector(promotionsData);

  const onClientBehalf = useSelector(authSelectors.onClientBehalf);

  const isMinimumProfileDataCompleted = useSelector(userSelectors.isMinimumProfileDataCompleted);

  const orderMode = useSelector(getOrderMode);

  const lang = useSelector(getLang);

  const renderHiLoginText = useHiLoginText();

  const router = useRouter();

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const showMembershipSection = useMemo(() => {
    return !!clubMembershipProfile.length && orderMode === 'new' && !hideMembershipSection;
  }, [clubMembershipProfile.length, hideMembershipSection, orderMode]);

  const clientCouponsOrderDiscounts = useMemo(() => {
    return orderDiscounts.filter((discount) => discount.discountSourceType.name === 'clientCoupon');
  }, [orderDiscounts]);

  const classes = useStyles({
    menuItems,
    name,
    loginText,
    hiText,
    size,
    onClientBehalf,
    isMinimumProfileDataCompleted,
    clientCouponsOrderDiscountsLength: clientCouponsOrderDiscounts?.length || 0,
    ...rest,
  });

  // TODO use from translations (not '')
  const renderText = useMemo<ReactNode>(() => {
    return (
      !isHideText &&
      renderHiLoginText({
        isLoggedIn,
        name: name || '',
        loginText,
        hiText,
      })
    );
  }, [isLoggedIn, name, loginText, hiText, isHideText, renderHiLoginText]);

  // need to render logout menu item in the end of menuItems array
  const renderMenuItemsArr = useMemo<TMenuItem[]>(() => {
    const newMenuItems = [...menuItems];
    // add logout item if isHideLogOut = false
    if (!isHideLogOut) {
      const logOutMenuItem: TMenuItem = {
        ...defaultLogOutMenuItem,
        // check last index item id and add 1
        // need for keys render in map
        id: menuItems.length && menuItems[menuItems.length - 1].id + 1,
      };
      // add logout cb if we have onLogOut prop
      if (onLogOut) {
        logOutMenuItem.onClick = () => onLogOut();
      }

      newMenuItems.push(logOutMenuItem);
    }

    return newMenuItems;
  }, [menuItems, isHideLogOut, onLogOut]);

  const handleClick = useCallback<Required<ButtonProps>['onClick']>(
    (e) => {
      // open menu if user logged in
      if (isLoggedIn && isMinimumProfileDataCompleted) {
        setAnchorEl(e.currentTarget);
        // run login cb if we have onLogin prop
      } else if (onLogin) {
        onLogin();
      }
    },
    [isLoggedIn, isMinimumProfileDataCompleted, onLogin],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickMenuItem = useCallback<THandleClickMenuItem>(
    (cb) => (e) => {
      // run cb if we have
      if (cb) {
        cb(e);
      }
      // close menu
      handleClose();
    },
    [handleClose],
  );

  const userIcon = useMemo(() => {
    const showNotification = !!clientCouponsOrderDiscounts.length || showMembershipSection;

    const iconClassName = showNotification ? classes.userNotificationIcon : classes.userIcon;
    let notificationIcon: TIconName = 'icon-user-membership';

    let iconName: TIconName = 'icon-user';

    if (showMembershipSection) {
      iconName = 'icon-user-notification-membership';
    }

    if (clientCouponsOrderDiscounts.length) {
      iconName = 'icon-user-notification-1';
      notificationIcon = 'icon-user-notification-2';
    }

    return (
      <Box className={classes.userIconWrapper}>
        <Icon
          classes={{ root: iconClassName }}
          name={iconName}
          color={isMobile && isLoggedIn ? 'primary' : 'action'}
        />
        {showNotification && (
          <Icon classes={{ root: classes.userNotificationDot }} name={notificationIcon} />
        )}
      </Box>
    );
  }, [
    classes.userIcon,
    classes.userIconWrapper,
    classes.userNotificationDot,
    classes.userNotificationIcon,
    clientCouponsOrderDiscounts.length,
    isLoggedIn,
    isMobile,
    showMembershipSection,
  ]);

  const storeIcon = useMemo(() => {
    return (
      <Box>
        <Icon classes={{ root: classes.storeIcon }} name="icon-store-impersonate" />
      </Box>
    );
  }, [classes.storeIcon]);

  const menuText = useMemo(() => {
    if (isHideText) return;
    if (onClientBehalf) {
      return (
        <Box mt={-2 / 8}>
          <Typography variant="body1" className={classes.storeSideText}>
            {'onClientBehalf.storeSide'}
          </Typography>
          <Typography
            variant="body1"
            className={classNames(classes.welcomeStoreText, classes.onClientBehalfWelcomeText)}
          >
            {renderHiLoginText({
              isLoggedIn,
              name: `${lastName} ${name}`,
              loginText,
              hiText: '',
            })}
          </Typography>
        </Box>
      );
    }

    return (
      <Typography
        className={classes.buttonText}
        fontWeight="medium"
        variant="body1"
        fontSize={14}
        color="light"
      >
        {renderText}
      </Typography>
    );
  }, [
    classes.buttonText,
    classes.onClientBehalfWelcomeText,
    classes.storeSideText,
    classes.welcomeStoreText,
    isHideText,
    isLoggedIn,
    lastName,
    loginText,
    name,
    onClientBehalf,
    renderHiLoginText,
    renderText,
  ]);

  const membershipPromotionsDetails = useMemo(() => {
    if (!showMembershipSection) return;

    const title = clubMembershipProfile[0].pointsEnabled
      ? t('membership.youEarnedPoints', {
          pointsNumber:
            clubMembershipProfile[0].totalPoints - (orderMembershipBenefits?.budgetToUse || 0),
        })
      : t('membership.youHaveBenefits', {
          benefitsNumber: clubMembershipProfile[0].userBenefits.length,
        });

    return (
      <Box className={classes.membershipPromotionsWrapper}>
        <Box>
          <Typography
            variant="body1"
            fontSize={lang === 'ru' ? 14 : 16}
            fontWeight="medium"
            color="secondary"
          >
            {title}
          </Typography>
          <Button
            className={classes.memberPromotionDialog}
            onClick={() => {
              showDialog({
                dialogType: MEMBER_PROMOTION_DISCOUNT_DIALOG,
              });
              handleClose();
            }}
          >
            <Typography variant="body1" fontSize={14} fontWeight="medium" color="light">
              {'membership.forAllBenefits'}
            </Typography>
            <Icon
              color="action"
              name={theme.direction === 'rtl' ? 'icon-arrow-left' : 'icon-arrow-right'}
              style={{
                fontSize: '10px',
                marginRight: theme.direction === 'rtl' ? '4px' : 0,
                marginLeft: theme.direction === 'rtl' ? 0 : '4px',
              }}
            />
          </Button>
        </Box>
        <Icon
          name="icon-diamond-membership"
          color="action"
          classes={{ root: classes.diamondMembership }}
        />
      </Box>
    );
  }, [
    showMembershipSection,
    clubMembershipProfile,
    t,
    orderMembershipBenefits?.budgetToUse,
    classes.membershipPromotionsWrapper,
    classes.memberPromotionDialog,
    classes.diamondMembership,
    lang,
    theme.direction,
    showDialog,
    handleClose,
  ]);

  return (
    <>
      <Button
        style={{ height: '100%' }}
        color="secondary"
        variant="contained"
        classes={{
          containedSecondary: classes.root,
          startIcon: classes.startIcon,
          endIcon: classes.endIcon,
          label: classes.label,
        }}
        startIcon={onClientBehalf ? storeIcon : userIcon}
        endIcon={
          !isHideText && (
            <>
              {isLoggedIn && isMinimumProfileDataCompleted ? (
                <Icon classes={{ root: classes.arrowDownIcon }} name="icon-arrow-down" />
              ) : null}
            </>
          )
        }
        onClick={handleClick}
        TouchRippleProps={{ classes: { child: classes.buttonRipple } }}
        {...buttonProps}
      >
        {menuText}
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        disablePortal={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
          list: overrideClasses?.menuList || classes.menuList,
        }}
        MenuListProps={
          {
            component: 'nav',
          } as Partial<MenuListProps>
        }
        {...menuProps}
      >
        {showMembershipSection && membershipPromotionsDetails}
        {!!clientCouponsOrderDiscounts.length && (
          <Box className={overrideClasses?.clientCouponWrapper || classes.clientCouponWrapper}>
            <ClientCouponNotice clientCoupons={clientCouponsOrderDiscounts} />
          </Box>
        )}
        {renderMenuItemsArr.map(({ id, icon, text, link, onClick }) => {
          const menuItemProps: Omit<MenuItemProps, 'button'> = {
            key: id,
            classes: {
              root: overrideClasses?.menuItem || classes.menuItem,
              selected: classNames(classes.menuItem, classes.selectedMenuItem),
            },
            onClick: handleClickMenuItem(),
            selected: router.pathname === (link && link.href),
          };
          // rewrite onclick of we have it in menu item
          if (onClick) {
            menuItemProps.onClick = handleClickMenuItem(onClick);
          }

          const menuItem = (
            <MenuItem {...menuItemProps}>
              <Icon classes={{ root: classes.menuIcon }} name={icon} color="primary" />
              {typeof text === 'string' ? t(text) : text}
            </MenuItem>
          );

          // wrap with link if we have link details
          if (link) {
            return (
              <Link key={id} {...link}>
                {menuItem}
              </Link>
            );
          }

          // by default render menu item
          return menuItem;
        })}
      </Menu>
    </>
  );
};

export default UserMenu;

import { HIDE_NOTIFY, SHOW_NOTIFY } from './constants';

import { THideAction, TShowAction } from './types';

const showNotification: TShowAction = (notifyData) => ({
  type: SHOW_NOTIFY,
  payload: notifyData,
});

const hideNotification: THideAction = () => ({
  type: HIDE_NOTIFY,
});

export { showNotification, hideNotification };

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesLocationDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLocationDialog> =>
    createStyles({
      root: {
        padding: theme.spacing(38 / 8, 24 / 8),

        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(70 / 8),
        },
      },
      image: {
        width: '78px',
        height: '72px',
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      title: {
        marginTop: theme.spacing(16 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(20 / 8),
        },
      },
      titleMain: {
        marginBottom: theme.spacing(5 / 8),
      },
      searchRoot: {
        marginTop: theme.spacing(22 / 8),
      },
      rootButton: {
        display: 'flex',
        flexDirection: 'column-reverse',
        marginTop: theme.spacing(22 / 8),
      },
      footer: {
        marginTop: theme.spacing(22 / 8),
      },
      footerItem: {
        marginBottom: theme.spacing(10 / 8),
        alignItems: 'flex-end',

        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(12 / 8),
        },
      },
      linkText: {
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
      loginBtn: {
        minWidth: '46px',
        display: 'inline-block',
        lineHeight: 'initial',
      },
      cityOption: {
        fontSize: '16px',
        lineHeight: '20px',
        paddingLeft: theme.spacing(9 / 8),
      },
      option: {
        padding: '8px 0px',
        width: 'auto',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        margin: '2px 9px',
        '&[data-focus="true"]': {
          backgroundColor: theme.palette.grey[200],
          borderRadius: '4px',
        },

        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          borderRadius: '4px',
        },
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesCategoryMenu } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCategoryMenu> =>
    createStyles({
      root: {
        display: 'flex',
        height: theme.spacing(7.5), // '60px'
        overflow: 'hidden',
        position: 'relative',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        padding: '0',
      },
      listItem: {
        height: '60px',
        padding: theme.spacing(18 / 8, 14 / 8, 0),
        display: 'block',
        textAlign: 'center',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        color: theme.palette.text.secondary,
        fontSize: '16px',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: 'inherit',
          color: theme.palette.primary.main,
          position: 'relative',
        },

        '&$selectedItem, &$selectedItem:hover': {
          backgroundColor: 'inherit',
        },
      },
      listItemHovered: {
        height: '60px',
        padding: theme.spacing(18 / 8, 14 / 8, 0),
        display: 'block',
        textAlign: 'center',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        fontSize: theme.spacing(2), // 16px
        cursor: 'pointer',
        backgroundColor: 'inherit',
        color: theme.palette.primary.main,
        position: 'relative',
      },
      selectedItem: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        position: 'relative',

        '&::after': {
          marginTop: theme.spacing(2.125), // '17px'
          width: '100%',
          content: '""',
          display: 'block',
          borderBottom: `2.5px solid ${theme.palette.primary.main}`,
        },
      },
      menuItemRoot: {
        minWidth: 'unset',
        fontWeight: 400,
        lineHeight: 'unset',
        padding: '0',
        position: 'relative',
        zIndex: 1301, // to appear above backdrop of modal
        '&:first-child $item': {
          padding: theme.spacing(0, 1.5625, 0, 0), // '0 12.5px'
        },
      },
      subMenuItem: {
        padding: '0',
        fontWeight: 400,
        lineHeight: 'unset',
        display: 'block',
        textAlign: 'start',
        width: '100%',
      },
      moreButton: {
        position: 'absolute',
        boxSizing: 'border-box',
        right: 0,
        padding: theme.spacing(0, 0, 2.25, 0),
        width: '65px',
      },
      dropDownRoot: {
        paddingLeft: theme.spacing(0.625), // '5px'
      },
      dropDownIcon: {
        fontSize: '12px',
        color: theme.palette.grey.A700,
      },
      dropDownOpened: {
        color: theme.palette.primary.main,
      },
      paper: {
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
        border: `0.5px solid ${theme.palette.grey.A100}`,
        backgroundColor: theme.palette.background.paper,
        pointerEvents: 'auto',
      },
      popover: {
        pointerEvents: 'none',
      },
      popoverRoot: {
        cursor: 'pointer',
        right: '-12px !important',
      },
      menuList: {
        display: 'flex',
        padding: theme.spacing(10 / 8),
      },
      menuItem: {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        lineHeight: '20px',
        padding: theme.spacing(10 / 8),
        minWidth: '140px',
        maxWidth: '220px',
        minHeight: 'auto',
        '&$menuItemSelected': {
          backgroundColor: 'transparent',
        },
        '&:focus, &:hover': {
          borderRadius: '6px',
          backgroundColor: theme.palette.grey[50],
        },
      },
      menuItemSelected: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        backgroundColor: theme.palette.grey[100],
      },
      dividerForSubCategories: {
        height: '190px',
        width: '1px',
        backgroundColor: theme.palette.grey[200],
        margin: '5px 10px 0px',
      },
      selectedContent: {},
      menuBackdropRoot: {
        display: 'none',
      },
      arrowIcon: {
        fontSize: '11px',
        marginLeft: theme.spacing(6 / 8),
      },
    }),
);

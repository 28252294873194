import { Box } from '@material-ui/core';
import { format } from 'date-fns';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CTAButton from 'ui/common/buttons/CTAButton';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import useStyles from './styles';
import { IMembershipPromotionCard } from './types';

const MembershipPromotionCard: FC<IMembershipPromotionCard> = ({
  promotion,
  handleBenefitClick,
  outlinedButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.membershipCardWrapper}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" style={{ opacity: outlinedButton ? 0.4 : 1 }}>
          <Icon name="icon-diamond" classes={{ root: classes.diamondIcon }} />
          <Box>
            <Typography className={classes.membershipTitle} color="textSecondary">
              {promotion.title}
            </Typography>
            <Box display="flex">
              <Typography className={classes.membershipValidUntil} color="mediumGrey">
                {t('membership.validUntil', {
                  expirationDate: format(promotion.expirationDate, 'dd.MM.yy'),
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CTAButton
          classes={{ root: classes.useButton }}
          size="medium"
          disableTouchRipple
          variant={outlinedButton ? 'outlined' : 'contained'}
          onClick={() => handleBenefitClick(promotion)}
        >
          {`membership.${outlinedButton ? 'remove' : 'use'}`}
        </CTAButton>
      </Box>
    </Box>
  );
};

export default MembershipPromotionCard;

import React, { FC, useEffect } from 'react';

import classNames from 'classnames';

import { Box, Tab, Tabs, useTheme } from '@material-ui/core';

import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';
import { useRouter } from 'next/router';
import { useCategoryMenu } from 'hooks';
import MobileSearch from '../inputs/MobileSearch';

import useStyles from './styles';

import { IMobileCategoryMenu } from './types';

const MobileCategoryMenu: FC<IMobileCategoryMenu> = ({
  menuItems,
  activeItem,
  searchProps,
  withSearch = true,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const { areSiblingCategories } = useCategoryMenu();

  const theme = useTheme();

  useEffect(() => {
    const element = document.getElementById(activeItem?.id as string);
    element?.focus();
  }, [activeItem, router]);

  return (
    <Box position="relative" bgcolor="background.paper">
      <Tabs
        value={false}
        variant="scrollable"
        scrollButtons="off"
        classes={{
          root: classes.root,
        }}
        component="nav"
        key={theme.direction} // for preventing bug on ios, when changing language categories disappears, category menu need to be "refresh"
      >
        {menuItems.map(({ id, text, link, subMenuItems }) => {
          const isSelected = id === activeItem?.id;

          if (subMenuItems?.length) {
            return;
          }

          return (
            <Link
              key={id}
              className={classes.linkRoot}
              shallow={areSiblingCategories(id, activeItem?.id)}
              scroll={true}
              {...link}
            >
              <Tab
                id={id as string}
                disableRipple
                classes={{
                  root: classNames(classes.menuItem, { [classes.menuItemSelected]: isSelected }),
                  wrapper: classNames({ [classes.menuItemSelectedLabel]: isSelected }),
                }}
                label={
                  <Typography
                    variant="body1"
                    fontSize={14}
                    fontWeight={isSelected ? 'medium' : 'regular'}
                    color={isSelected ? 'primary' : 'textSecondary'}
                    isTranslate={false}
                    className={classes.labelText}
                  >
                    {text}
                  </Typography>
                }
              />
            </Link>
          );
        })}
      </Tabs>
      {withSearch && <MobileSearch rootClassName={classes.searchRoot} {...searchProps} />}
    </Box>
  );
};

export default MobileCategoryMenu;

import React, { FC, useRef } from 'react';

import { Box, Grid } from '@material-ui/core';
import { Field, Form, FormikProps, withFormik } from 'formik';
import { object, SchemaOf, string } from 'yup';

import { useWebsiteDetails } from 'hooks/useWebsiteDetails';

import ReCAPTCHA from 'react-google-recaptcha';

import { TextField } from 'components/common/formsFields';

import CTAButton from 'ui/common/buttons/CTAButton';
import classNames from 'classnames';
import useStyles from './styles';

import { IContactStoreForm, IContactStoreFormValues } from './types';

const ContactStoreFormSchema: SchemaOf<IContactStoreFormValues> = object().shape({
  clientName: string().required('validation.required'),
  lastName: string().required('validation.required'),
  cellPhone: string().required('validation.required'),
  message: string().required('validation.required'),
  captchaToken: string().required('validation.required'),
  email: string().email('validation.email').required('validation.required'),
});

const ContactStoreForm: FC<IContactStoreForm & FormikProps<IContactStoreFormValues>> = ({
  isValid,
  dirty,
  setFieldValue,
}) => {
  const classes = useStyles();

  const websiteDetails = useWebsiteDetails();

  const captchaRef = useRef();

  if (!websiteDetails.captchaType) {
    return null;
  }

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <Field
            name="clientName"
            label="firstName"
            placeholder={false}
            required
            component={TextField}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Field name="lastName" placeholder={false} required component={TextField} />
        </Grid>
      </Grid>
      <Box mt={1.25}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Field
              name="cellPhone"
              label="phone"
              placeholder={false}
              required
              component={TextField}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Field name="email" placeholder={false} required component={TextField} />
          </Grid>
        </Grid>
      </Box>

      <Box mt={1.25}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Field
              name="message"
              label="details"
              placeholder={false}
              multiline
              rowsMin={4}
              rows={4}
              required
              component={TextField}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box mt={20 / 8}>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={websiteDetails.captchaType.clientApiKey}
                onChange={(response: string) => setFieldValue('captchaToken', response)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={36 / 8} display="flex" justifyContent="flex-end">
        <CTAButton
          disabled={!isValid || !dirty}
          type="submit"
          classes={{ root: classNames(classes.submitButton, 'font-family-rubik') }}
        >
          {'forms.contactUs.submitButton'}
        </CTAButton>
      </Box>
    </Form>
  );
};

export default withFormik<IContactStoreForm, IContactStoreFormValues>({
  mapPropsToValues: ({ defaultValues }) => {
    return {
      clientName: (defaultValues && defaultValues.clientName) || '',
      lastName: (defaultValues && defaultValues.lastName) || '',
      cellPhone: (defaultValues && defaultValues.cellPhone) || '',
      email: (defaultValues && defaultValues.email) || '',
      message: (defaultValues && defaultValues.message) || '',
      captchaToken: (defaultValues && defaultValues.captchaToken) || '',
    };
  },
  validationSchema: ContactStoreFormSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus, resetForm }) => {
    props.handleSubmit(values, { setSubmitting, setErrors, setStatus, resetForm });
  },
  enableReinitialize: true,
})(ContactStoreForm);

const SET_CATALOG_SSR_LAST_UPDATE_TIME = 'catalog/SET_CATALOG_SSR_LAST_UPDATE_TIME';
const SET_CATALOG_LAST_FETCH_TIME = 'catalog/SET_CATALOG_LAST_FETCH_TIME';
const SET_CATALOG_ID = 'catalog/SET_CATALOG_ID';
const CHECK_AND_FETCH_BRANCH_CATALOG_IF_NECESSARY =
  'catalog/CHECK_AND_FETCH_BRANCH_CATALOG_IF_NECESSARY';

const CORRECT_BRANCH_SUB_CATALOG_WAS_FETCHED = 'catalog/CORRECT_BRANCH_SUB_CATALOG_WAS_FETCHED';
const FETCH_CATALOG_VIA_SUB_CATALOG_ID = 'catalog/FETCH_CATALOG_VIA_SUB_CATALOG_ID';
const UPDATE_ITEMS_ACCORDING_TO_CURRENTLY_DISPLAYED_SUB_CATALOG =
  'catalog/UPDATE_ITEMS_ACCORDING_TO_CURRENTLY_DISPLAYED_SUB_CATALOG';

export {
  SET_CATALOG_SSR_LAST_UPDATE_TIME,
  SET_CATALOG_ID,
  FETCH_CATALOG_VIA_SUB_CATALOG_ID,
  CHECK_AND_FETCH_BRANCH_CATALOG_IF_NECESSARY,
  UPDATE_ITEMS_ACCORDING_TO_CURRENTLY_DISPLAYED_SUB_CATALOG,
  CORRECT_BRANCH_SUB_CATALOG_WAS_FETCHED,
  SET_CATALOG_LAST_FETCH_TIME,
};

import { createStyles, makeStyles, StyleRules } from '@material-ui/core';
import { TStylesDaysAndHours } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesDaysAndHours> =>
    createStyles({
      daysAndHoursWrapper: {
        display: 'table',
        width: '100%',
      },
      hoursWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      openDays: {
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'left',
        color: theme.palette.grey[600],
      },
      openHours: {
        flip: false,
        fontSize: '16px',
        textAlign: 'left',
        lineHeight: '20px',
        color: theme.palette.text.primary,
      },
      closedDay: {
        width: '88px',
        fontSize: '16px',
        color: theme.palette.text.primary,
        display: 'flex',
      },
    }),
);

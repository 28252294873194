import { TCategoryMenuItem, TPaddingWrp } from './types';

// need for
export const containerPadding: TPaddingWrp = {
  top: '18px',
  right: 0,
  bottom: 0,
  left: 0,
};
// need for stories
export const fakeMenuItemsArr: string[] = [
  'כל המוצרים',
  'מבצעים',
  'ירקות גינה',
  'ירקות שורש',
  'פירות',
  'חסות ועלים',
  'ירק ועשבי תיבול',
  'נבטים',
  'פטריות',
  'המכולת שלנו',
  'פירות יבשים',
  'פיצוחים ואגוזים',
  'דגנים',
  'נבטים',
  'קטניות',
  'מיצים ומשקאות',
  'שמן וחומץ',
  'תבלינים',
  'חמוצים שימורים',
  '״יאמי יאמי״ מתוקים ועוד',
  'קוסמטיקה אקולוגית',
  'היגיינה נשית אורגנית',
  'מוצרי ארומתרפיה',
  'חומרי ניקוי אקולוגיים',
  'מוצרים נוספים',
];

export const fakeMenuItems: TCategoryMenuItem[] = fakeMenuItemsArr.map((text, index) => {
  const id = (index + 1).toString();
  return {
    id,
    text,
    link: {
      href: '/',
      as: '/',
    },
    sortOrder: 0,
  };
});

import { TOrderDetailsReducerState } from 'store';
import { TOrderReducerState } from 'store/modules/order';
import { TOrderDiscount, TOrderDiscountState } from 'store/modules/orderDiscounts';
import { TOrderItemDTO, TPrepareToPlaceOrderServer } from 'types';

export function getCartEstimation(
  order: TOrderReducerState,
  discounts: TOrderDiscount[],
  serviceAreaUsageFees: number,
  courierTip: TOrderDetailsReducerState['courierTip'] = 0,
  orderHandlingFee: TPrepareToPlaceOrderServer['orderHandlingFee'] = 0,
): number {
  const itemsTotal = order.items
    .filter((item) => !item.isRemoved)
    .reduce((total, item) => {
      return total + getItemTotalPrice(item);
    }, 0);

  const discountsTotal = discounts.reduce(
    (total, discount) =>
      discount.state === TOrderDiscountState.Active ? total + discount.totalPrice : total,
    0,
  );

  return Math.max(
    0,
    itemsTotal + serviceAreaUsageFees + discountsTotal + courierTip + orderHandlingFee,
  );
}

export function getItemTotalPrice(item: TOrderItemDTO, manualUnitPrice?: number): number {
  const unitPrice = manualUnitPrice || item.storeProduct.price;
  if (item.storeProduct.soldByWeight) {
    const activeUnit = item.storeProduct.productSellingUnits.find(
      (productSellingUnit) => productSellingUnit.id === item.requestedSellingUnit.id,
    );
    return item.requestedQuantity * unitPrice * (activeUnit?.estimatedUnitWeight || 1);
  }
  return item.requestedQuantity * unitPrice;
}

export function shouldOrderHandlingFeeBeCharged(
  itemsEstimation: number,
  orderMinTotalValue: TPrepareToPlaceOrderServer['orderMinTotalValue'],
  orderHandlingFeeThreshold: TPrepareToPlaceOrderServer['orderHandlingFeeThreshold'],
): boolean {
  return itemsEstimation > orderMinTotalValue && itemsEstimation < orderHandlingFeeThreshold;
}

import { TWebsiteReducer } from './types';

import { GET_WEBSITE_DETAILS_SUCCESS } from './constants';

import { initialWebsiteState } from './initialState';

const websiteReducer: TWebsiteReducer = (state = initialWebsiteState, action) => {
  switch (action.type) {
    case GET_WEBSITE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { websiteReducer };

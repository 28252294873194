import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesMemberPromotionDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMemberPromotionDialog> =>
    createStyles({
      header: {
        backgroundColor: theme.palette.secondary.main,
      },
      headerTop: {
        padding: theme.spacing(43 / 8, 14 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(32 / 8, 50 / 8),
        },
      },
      diamondMembership: {
        fontSize: '32px',
        marginRight: '10px',
        [theme.breakpoints.up('md')]: {
          fontSize: '38px',
        },
      },
      limitationMainPart: {
        padding: '10px 14px',
        backgroundColor: 'rgb(0,0,0, 0.14)',
        display: 'flex',
        borderRadius: '2px',
        alignItems: 'baseline',
        [theme.breakpoints.up('md')]: {
          padding: '10px 50px',
        },
      },
      limitationTypography: {
        fontSize: '13px',
        lineHeight: '16px',
        color: theme.palette.background.paper,
        opacity: 0.7,
        textAlign: 'left',
      },
      mainPartWrapper: {
        minHeight: 'calc(100vh - 98px)',
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(28 / 8, 14 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(32 / 8, 50 / 8, 50 / 8),
          minHeight: 'auto',
        },
      },
      redeemPointsButton: {
        width: theme.direction === 'rtl' ? '130px !important' : '150px !important',
        minWidth: theme.direction === 'rtl' ? '130px !important' : '150px !important',
        marginLeft: '14px',
        [theme.breakpoints.up('md')]: {
          width: theme.direction === 'rtl' ? '150px !important' : '160px !important',
          minWidth: theme.direction === 'rtl' ? '150px !important' : '160px !important',
          marginLeft: '20px',
        },
      },
      focused: {
        border: 'none',
      },
      editPointIcon: {
        fontSize: '18px',
      },
      divider: {
        margin: '24px 0px 26px',
      },
      tooltip: {
        padding: theme.spacing(1),
        backgroundColor: `${theme.palette.text.secondary}!important`,
        top: '-14px !important',
        maxWidth: '300px !important',
        [theme.breakpoints.up('md')]: {
          top: '0px !important',
        },
      },
      tooltipArrow: {
        color: `${theme.palette.text.secondary}!important`,
      },
      customerClubTitle: {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '24px',

        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        [theme.breakpoints.up('md')]: {
          fontSize: '26px',
          lineHeight: '30px',
        },
      },
      pointLable: {
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        [theme.breakpoints.up('md')]: {
          fontSize: '22px',
        },
      },
      questionIcon: {
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
        },
      },
      helperText: {
        fontSize: '13px',
        lineHeight: '14px',
        marginTop: '10px',
      },
      editText: {
        marginLeft: theme.spacing(4 / 8),
      },
      memberNumber: {
        fontSize: '13px',
        lineHeight: '14px',
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
    }),
);

import { ReactNode } from 'react';

import { TTabItem } from './types';

const defaultDeliveryTabs: Omit<TTabItem, 'children'>[] = [
  {
    id: 'delivery',
    label: 'orderType.delivery',
    iconName: 'icon-delivery',
  },
  {
    id: 'selfPickup',
    label: 'orderType.selfPickup',
    iconName: 'icon-store',
  },
];

export const renderDeliveryPickUpTabs = (
  pickUpChildren: ReactNode,
  shippingChildren: ReactNode,
): TTabItem[] => {
  const tabsArray: TTabItem[] = defaultDeliveryTabs.map(({ id, label, iconName }) => {
    const children = id === 'delivery' ? shippingChildren : pickUpChildren;
    return {
      id,
      label,
      iconName,
      children,
    };
  });
  return tabsArray;
};

import React, { FC } from 'react';

import TermsOfUseContent from 'components/common/TermsOfUseContent';
import { ITermsOfUseDialog } from './types';

const TermsOfUseDialog: FC<ITermsOfUseDialog> = ({ backToText, onBackClick }) => {
  return <TermsOfUseContent backToText={backToText} onBackClick={onBackClick} />;
};

export default TermsOfUseDialog;

import { TOrderDetailsReducerState } from './types';

export const initialOrderDetailsState: TOrderDetailsReducerState = {
  orderMode: 'new',
  selfPickupLocation: {
    name: '',
    storeServiceAreaId: 0,
  },
  orderType: 'delivery',
  courierTip: 0,
  limitationByAgeChecked: false,
};

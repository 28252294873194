import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CardMedia } from '@material-ui/core';
import { useCategoryMenu, useFooter, useMobile, useWebsiteDetails } from 'hooks';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';
import MobileCategoryMenu from 'ui/mobile/MobileCategoryMenu';
import CategoryMenu from 'ui/desktop/CategoryMenu';
import Container from 'ui/common/Container';
import MobileFooter from 'ui/mobile/Footer/Footer';
import DesktopFooter from 'ui/desktop/Footer/Footer';
import useStyles from './styles';
import { ICustom404Content } from './types';

const Custom404Content: FC<ICustom404Content> = () => {
  const classes = useStyles();
  const { getDesktopFooterProps, getMobileFooterProps } = useFooter();

  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const websiteDetails = useWebsiteDetails();
  const { activeMenuItem, menuItems, menuItemsFlattened } = useCategoryMenu();

  return (
    <Box className={classes.root}>
      <Box className={classes.categoriesWrapper}>
        {isMobile ? (
          <MobileCategoryMenu menuItems={menuItemsFlattened} activeItem={activeMenuItem} />
        ) : (
          <Container maxContainerWidth fixed>
            <Box position="relative">
              <CategoryMenu menuItems={menuItems} activeItem={activeMenuItem} />
            </Box>
          </Container>
        )}
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.imagesWrapper} dir="rtl">
          <Icon
            name="icon-number-4"
            color="secondary"
            classes={{ root: classes.iconNumber }}
            style={{ zIndex: -3 }}
          />
          <Box className={classes.centerImageWrapper}>
            <CardMedia
              src={websiteDetails.theme.notFoundPage.images.center}
              component="img"
              className={classes.pageNotFoundImage}
              alt={t('error.pageNotFound')}
            />
          </Box>
          <Icon name="icon-number-4" color="secondary" classes={{ root: classes.iconNumber }} />
        </Box>
        <Box className={classes.textWrapper}>
          <Typography className={classes.titleText} variant="h1" color="secondary">
            {'error.oops'}
          </Typography>
          <Typography className={classes.subtitleText} variant="body1" color="grey">
            {'error.pageNotFound'}
          </Typography>
          <Box className={classes.CTAButtonWrapper}>
            <a href="/" style={{ textDecoration: 'none' }}>
              <CTAButton style={{ width: '100%' }}>{'button.backToTheHomePage'}</CTAButton>
            </a>
          </Box>
        </Box>
      </Box>
      <Box>
        {isMobile ? (
          <MobileFooter {...getMobileFooterProps('full')} />
        ) : (
          <DesktopFooter {...getDesktopFooterProps('full')} />
        )}
      </Box>
    </Box>
  );
};

export default Custom404Content;

import { useCallback, useMemo, useState } from 'react';
import { weekdayAndOpenHoursMap } from 'utils/helpers/renderHelpers';
import { TStoreDeliveryAreaServer } from 'types';
import { ILatLng } from 'ui/common/Map';
import { ContentPageService } from 'api/services/ContentPageService';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import StoreService from 'api/services/StoreService/api';
import { TAboutPageContentJson, TOpenHours, TUseAboutPageContent } from './types';

const useAboutPageContent: TUseAboutPageContent = () => {
  const websiteDetails = useWebsiteDetails();
  const [storeLocation, setStoreLocation] = useState<ILatLng>();
  const [deliveryAreas, setDeliveryAreas] = useState<TStoreDeliveryAreaServer[]>();
  const [storeOpenHours, setStoreOpenHours] = useState<TOpenHours[]>();
  const [pageContent, setPageContent] = useState<TAboutPageContentJson>();

  const getAboutPageContent = useCallback(
    (pageId: string) => {
      Promise.all([
        StoreService.getStoreServiceAreas(),
        ContentPageService.getStorePageContent(pageId),
      ]).then((actionResults) => {
        const storeServiceAreaResult = actionResults[0];
        const pageContentResult = actionResults[1];

        if (storeServiceAreaResult.data) {
          const selfPickupDetails = storeServiceAreaResult.data.selfPickupAreas.find(
            (area) => area.primary,
          );

          setDeliveryAreas(storeServiceAreaResult.data.deliveryAreas);
          setStoreLocation(JSON.parse(websiteDetails.store.addressCoordinates));

          if (selfPickupDetails) {
            setStoreOpenHours(
              weekdayAndOpenHoursMap(JSON.parse(selfPickupDetails.serviceAreaHoursJson)),
            );
          }
        }

        if (pageContentResult.data) {
          setPageContent(JSON.parse(pageContentResult.data.content));
        }
      });
    },
    [websiteDetails.store.addressCoordinates],
  );

  const aboutPage = useMemo(() => {
    return websiteDetails.contentPages.find((contentPage) => contentPage.type === 'about');
  }, [websiteDetails.contentPages]);

  return {
    getAboutPageContent,
    aboutPage,
    storeLocation,
    deliveryAreas,
    storeOpenHours,
    pageContent,
  };
};

export default useAboutPageContent;

import React, { FC, useCallback, useState } from 'react';

import { Box, Button } from '@material-ui/core';

import Typography from 'ui/common/Typography';
import TextInput, { ITextInput } from 'ui/common/inputs/TextInput';
import CTAButton from 'ui/common/buttons/CTAButton';

import { useDialog, useWebsiteDetails } from 'hooks';

import { AuthService } from 'api';

import { LOGIN_CODE_DIALOG, LOGIN_DIALOG, TERMS_OF_USE_DIALOG } from 'store';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import useStyles from './styles';

import { ILoginDialog } from './types';

const LoginDialog: FC<ILoginDialog> = ({ onLoginSuccess }) => {
  const classes = useStyles();
  const websiteDetails = useWebsiteDetails();
  const { showDialog } = useDialog();

  const [cellPhone, setCellPhone] = useState<string>('');
  const [phoneIsValid, setPhoneIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = useCallback<Required<ITextInput>['onChange']>(
    ({ target: { value } }) => {
      const regExp = new RegExp('^\\d{0,10}$');
      const isValid = regExp.test(value); // only numbers

      if (isValid) {
        setCellPhone(value);
        setPhoneIsValid(value.length === 10);
      }

      // reset error
      if (error) {
        setError('');
      }
    },
    [error],
  );

  const handleSubmit = useCallback(async () => {
    const actionResult = await AuthService.applyForAuthentication({ cellPhone }, { setLoading });

    if (!actionResult.success) {
      setError(actionResult.resolvedMessage);
      return;
    }

    showDialog({
      dialogType: LOGIN_CODE_DIALOG,
      contentProps: {
        cellPhone,
        onLoginSuccess,
      },
    });
  }, [cellPhone, onLoginSuccess, showDialog]);

  const termsOfServiceDialog = useCallback(() => {
    showDialog({
      dialogType: TERMS_OF_USE_DIALOG,
      contentProps: {
        backToText: 'links.backToTheShop',
        onBackClick: () =>
          showDialog({
            dialogType: LOGIN_DIALOG,
          }),
      },
    });
  }, [showDialog]);

  const handleKeyPress = useCallback(
    (e) => {
      // it triggers by pressing the enter key
      if (phoneIsValid && e.charCode === 13) {
        handleSubmit();
      }
    },
    [handleSubmit, phoneIsValid],
  );

  return (
    <Box className={classes.root}>
      <Avatar
        src={websiteDetails.logoPath}
        variant="circle"
        classes={{
          root: classes.logo,
          img: classes.logo,
        }}
      />
      <Box className={classes.title}>
        <Typography variant="h2" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.login.title'}
        </Typography>
        <Typography
          variant="body1"
          fontSize={16}
          color="grey"
          align="center"
          className={classes.subTitle}
        >
          {'dialog.login.subTitle'}
        </Typography>
      </Box>
      <Box className={classes.input}>
        <TextInput
          onKeyPress={handleKeyPress}
          type="tel"
          inputProps={{ pattern: '[0-9]*' }}
          inputMode="numeric"
          name="cellPhone"
          isShowLabel={false}
          startIcon="icon-phone"
          onChange={handleChange}
          value={cellPhone}
          helperText={error}
          error={!!error}
        />
      </Box>
      <Box display="flex" flexDirection="column-reverse">
        <Box className={classes.footer}>
          <Box display="flex" justifyContent="center" flexWrap="wrap" alignItems="center">
            <Typography variant="body1" color="grey" fontSize={14} align="center">
              {'dialog.login.confirmTerms'}
            </Typography>
            &nbsp;
            <Button
              onClick={termsOfServiceDialog}
              className={classNames(classes.termsOfServiceLink, 'unstyled-button')}
              disableTouchRipple
            >
              <Typography
                variant="body1"
                color="primary"
                fontSize={14}
                align="center"
                fontWeight="medium"
                className={classes.linkText}
              >
                {'dialog.login.terms'}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box className={classes.rootButton}>
          <CTAButton fullWidth disabled={!phoneIsValid} onClick={handleSubmit} loading={loading}>
            {'button.continue'}
          </CTAButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginDialog;

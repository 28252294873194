const START_ADD_SUBSCRIPTION = 'subscriptions/START_ADD_SUBSCRIPTION';
const START_EDIT_SUBSCRIPTION = 'subscriptions/START_EDIT_SUBSCRIPTION';
const STOP_ADD_EDIT_SUBSCRIPTION = 'subscriptions/STOP_ADD_EDIT_SUBSCRIPTION';
const PREPARE_TO_PLACE_SUBSCRIPTION_SUCCESS = 'subscriptions/PREPARE_TO_PLACE_SUBSCRIPTION_SUCCESS';

export {
  START_ADD_SUBSCRIPTION,
  START_EDIT_SUBSCRIPTION,
  STOP_ADD_EDIT_SUBSCRIPTION,
  PREPARE_TO_PLACE_SUBSCRIPTION_SUCCESS,
};

import { IStoreProduct, IWebsiteDetails } from 'store';
import { TIdDTO, TOrderItemDTO, TOrderItemServer, TStoreProductServer } from 'types';
import { getIdsAndByIds } from 'utils/helpers/collection';
import { prepareStoreProductServer } from 'utils/helpers/storeProduct';

export function filterUnavailableItems(
  items: TOrderItemServer[],
): TItemsFilteredByStockAvailability {
  const availableItems: TOrderItemServer[] = [];
  const unavailableItems: TOrderItemServer[] = [];

  items.forEach((item) => {
    if (item.storeProduct) {
      if (item.storeProduct.excludedFromOnlineCatalog) {
        unavailableItems.push(item);
      } else {
        availableItems.push(item);
      }
    }
  });

  return { availableItems, unavailableItems };
}

export type TItemsFilteredByStockAvailability = {
  availableItems: TOrderItemServer[];
  unavailableItems: TOrderItemServer[];
};

export function filterItemsOutOfStock<T extends TOrderItemDTO | TOrderItemServer>(
  items: T[],
  storeProductsInStockById: Record<TStoreProductServer['id'], IStoreProduct>,
  ancestorStoreProductById?: Record<TStoreProductServer['id'], IStoreProduct>,
): TItemsFilteredByCurrentStock<T> {
  const itemsInStock: T[] = [];
  const itemsOutOfStock: T[] = [];

  items.forEach((item) => {
    const newItem = { ...item };
    let stockProduct = item.storeProduct && storeProductsInStockById[item.storeProduct.id];
    if (stockProduct) {
      newItem.storeProduct = stockProduct; // when items coming from share cart it contains only id and we need to set storeProduct with all data
    }

    if (!stockProduct && item.storeProduct) {
      const productAncestor = item.storeProduct.ancestor;
      if (productAncestor) {
        stockProduct =
          storeProductsInStockById[productAncestor.id] ||
          (ancestorStoreProductById && ancestorStoreProductById[productAncestor.id]);

        if (stockProduct) {
          newItem.storeProduct = stockProduct; // dirty hack, we set the ancestor as the storeProduct.
        }
      }
    }

    if (!stockProduct) {
      stockProduct =
        item.storeProduct &&
        ancestorStoreProductById &&
        ancestorStoreProductById[item.storeProduct.id];

      if (stockProduct) {
        newItem.storeProduct = stockProduct;
      } else {
        itemsOutOfStock.push(newItem);
        return;
      }
    }

    if (
      stockProduct.productSellingUnits.find(
        (productSellingUnit) => productSellingUnit.id === item.requestedSellingUnit?.id,
      )
    ) {
      itemsInStock.push(newItem);
    } else {
      newItem.requestedSellingUnit = { id: stockProduct.productSellingUnits[0].id } as TIdDTO;
      itemsOutOfStock.push(newItem);
    }
  });

  return { itemsInStock, itemsOutOfStock };
}

export type TItemsFilteredByCurrentStock<T> = {
  itemsInStock: T[];
  itemsOutOfStock: T[];
};

export function prepareOutOfStockProducts(
  itemsOutOfStock: TOrderItemServer[],
  useMLWeightEstimations: IWebsiteDetails['store']['performSellingUnitsEstimationLearning'],
): {
  outOfStockStoreProductIds: TStoreProductServer['id'][];
  outOfStockStoreProductById: Record<TStoreProductServer['id'], IStoreProduct>;
} {
  const outOfStockProducts: IStoreProduct[] = [];

  itemsOutOfStock.forEach((item) => {
    if (item.storeProduct) {
      const newStoreProduct = prepareStoreProductServer(item.storeProduct, useMLWeightEstimations);
      const requestedSellingUnit = newStoreProduct.productSellingUnits.find(
        (productSellingUnit) => productSellingUnit.id === item.requestedSellingUnit?.id,
      );

      if (requestedSellingUnit) {
        requestedSellingUnit.maxAmount = item.requestedQuantity as number; // limiting the client not to order more than he originally did (and thus not getting the product overBooked)
      }

      outOfStockProducts.push(newStoreProduct);
    }
  });

  const { objById, arrayIds } = getIdsAndByIds<IStoreProduct>(outOfStockProducts);

  return {
    outOfStockStoreProductById: objById,
    outOfStockStoreProductIds: arrayIds,
  };
}

export const isOrderContainsWeighableItems = (
  items: TOrderItemDTO[],
  storeProductById: Record<TStoreProductServer['id'], IStoreProduct>,
): boolean => {
  // TODO Kseniia, when you will back to the task of changing structure of EC,take soldByWeight from orderItem itself and not from storeProductById!
  return !!items.find((orderItem) => {
    return storeProductById[orderItem.storeProduct.id].soldByWeight;
  });
};

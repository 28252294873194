import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';

import useStyles from './styles';

import { IDeliverySection } from './types';

const DeliverySection: FC<IDeliverySection> = ({ iconClick }) => {
  const classes = useStyles();

  return (
    <IconButton
      size="small"
      onClick={iconClick}
      classes={{ root: classes.buttonRoot }}
      disableRipple
    >
      <Icon
        color="action"
        name="icon-navigation"
        classes={{
          root: classes.iconBtn,
        }}
      />
    </IconButton>
  );
};

export default DeliverySection;

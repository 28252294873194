import { Box } from '@material-ui/core';
import Avatar from 'components/common/Avatar';

import { useDialog, useWebsiteDetails } from 'hooks';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'store';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';

import CTAButton from 'ui/common/buttons/CTAButton';

import Typography from 'ui/common/Typography';
import useStyles from './styles';

import { IWelcomeDialog } from './types';

const WelcomeDialog: FC<IWelcomeDialog> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const websiteDetails = useWebsiteDetails();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const { hideDialog } = useDialog();

  const handleClose = useCallback(() => {
    if (isLoggedIn) {
      dispatch(introductoryPopupsCompletedAction(true));
    }

    hideDialog();
  }, [dispatch, hideDialog, isLoggedIn]);

  return (
    <Box className={classes.root}>
      <Avatar
        src={websiteDetails.logoPath}
        variant="circle"
        classes={{
          root: classes.logo,
          img: classes.logo,
        }}
      />
      <Box className={classes.title}>
        <Typography variant="h2" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.welcome.title'}
        </Typography>
      </Box>
      <Box
        className={classes.content}
        dangerouslySetInnerHTML={{
          __html: websiteDetails.promotionalText,
        }}
      />

      <Box>
        <CTAButton className={classes.closeButton} onClick={handleClose}>
          {'dialog.welcome.closeButton'}
        </CTAButton>
      </Box>
    </Box>
  );
};

export default WelcomeDialog;

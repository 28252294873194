import { combineReducers } from 'redux';

import { catalogReducer } from 'store/modules/catalog';

// reducers
import { orderDiscountsReducer } from 'store/modules/orderDiscounts';
import { TStoreState } from 'store/types';
import {
  authReducer,
  categoryReducer,
  configReducer,
  dialogReducer,
  notifyReducer,
  orderDetailsReducer,
  orderReducer,
  prepareToPlaceOrderReducer,
  promotionsReducer,
  routerHistoryReducer,
  shoppingFlowReducer,
  storeProductReducer,
  uiStylesReducer,
  userReducer,
  websiteReducer,
} from '../modules';

const rootReducer = combineReducers<TStoreState>({
  activeOrder: combineReducers({
    order: orderReducer,
    orderDetails: orderDetailsReducer,
    orderDiscounts: orderDiscountsReducer,
    prepareToPlaceOrder: prepareToPlaceOrderReducer,
  }),
  category: categoryReducer,
  storeProduct: storeProductReducer,
  promotions: promotionsReducer,
  notify: notifyReducer,
  shoppingFlow: shoppingFlowReducer,
  auth: authReducer,
  dialog: dialogReducer,
  user: userReducer,
  store: websiteReducer,
  config: configReducer,
  uiStyles: uiStylesReducer,
  routerHistory: routerHistoryReducer,
  catalog: catalogReducer,
});

export default rootReducer;

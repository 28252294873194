import { Box, Button, CardMedia } from '@material-ui/core';
import classNames from 'classnames';

import { useMobile, useProduct } from 'hooks';

import { useMultiLanguage } from 'hooks/useMultiLanguage';
import useProductPrice from 'hooks/useProductPrice';
import React, { FC, useMemo } from 'react';
import CartItemQuantityAndUnitControls from 'ui/common/buttons/CartItemQuantityAndUnitControls';
import ProductQualityTag from 'ui/common/ProductQualityTag';
import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { IItemPromotionCart } from './types';

const ItemPromotionCart: FC<IItemPromotionCart> = ({ storeProduct }) => {
  const classes = useStyles();
  const { isMobile } = useMobile();

  const { handleChooseUnit, handleChangeStoreProductQuantity, handleProductDetailsPopup } =
    useProduct();
  const { pricePerUnit, pricePerOneHundredGram } = useProductPrice();

  const { renderLanguageField } = useMultiLanguage();

  const pricesSectionRendered = useMemo(() => {
    return (
      <Box className={classes.priceWrapper}>
        <Box alignItems="baseline" display="flex">
          {pricePerUnit(
            storeProduct.price,
            storeProduct.pricingSellingUnitName,
            classNames(classes.price, 'font-family-heebo'),
            classNames(classes.sellingUnit, 'font-family-heebo'),
          )}
        </Box>
        {!storeProduct.soldByWeight && (
          <Box mt="4px" ml={isMobile ? 4 / 8 : 0}>
            {pricePerOneHundredGram(
              storeProduct.productSellingUnits[0].estimatedUnitWeight,
              storeProduct.price,
              storeProduct.product.liquid,
              classNames(classes.pricePerGram, 'font-family-heebo'),
            )}
          </Box>
        )}
      </Box>
    );
  }, [
    classes.priceWrapper,
    classes.price,
    classes.sellingUnit,
    classes.pricePerGram,
    pricePerUnit,
    storeProduct.price,
    storeProduct.pricingSellingUnitName,
    storeProduct.soldByWeight,
    storeProduct.productSellingUnits,
    storeProduct.product.liquid,
    isMobile,
    pricePerOneHundredGram,
  ]);

  const quantityAndUnitSection = useMemo(() => {
    return (
      <Box className="quantityButton" mt={isMobile ? '14px' : '0px'}>
        <CartItemQuantityAndUnitControls
          orientation={isMobile ? 'horizontal' : 'block'}
          onChangeProductCount={(action) =>
            handleChangeStoreProductQuantity(storeProduct.id, action)
          }
          requestedQuantity={storeProduct.cartData?.orderItem.requestedQuantity || 0}
          activeSellingUnit={storeProduct.activeSellingUnit}
          productSellingUnits={storeProduct.productSellingUnits}
          onChooseUnit={(sellingUnitId) => handleChooseUnit(storeProduct.id, sellingUnitId)}
        />
      </Box>
    );
  }, [
    handleChangeStoreProductQuantity,
    handleChooseUnit,
    isMobile,
    storeProduct.activeSellingUnit,
    storeProduct.cartData?.orderItem.requestedQuantity,
    storeProduct.id,
    storeProduct.productSellingUnits,
  ]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.productType}>
        {storeProduct.productQuality && storeProduct.productQuality.displayQuality && (
          <ProductQualityTag
            label={renderLanguageField(storeProduct.productQuality.multiLang, 'name')}
          />
        )}
      </Box>
      <Box className={classes.main}>
        <Button
          disableRipple
          onClick={() => handleProductDetailsPopup(storeProduct.id)}
          className={classes.productDetailsButton}
        >
          <CardMedia
            classes={{ root: classes.cardImage }}
            component="img"
            image={`${storeProduct.imageUrl}`}
            alt={storeProduct.fullName}
          />
        </Button>
        <Box className={classes.nameAndPriceSection}>
          <Box className={classes.fullName}>
            <Typography
              fontSize={14}
              color="textPrimary"
              isTranslate={false}
              style={{ lineHeight: '16px' }}
            >
              {storeProduct.displayName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {pricesSectionRendered}
          </Box>
          {quantityAndUnitSection}
        </Box>
      </Box>
    </Box>
  );
};

export default ItemPromotionCart;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesPaymentByBitWasDeclined } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPaymentByBitWasDeclined> =>
    createStyles({
      root: {
        padding: theme.spacing(38 / 8, 24 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(70 / 8),
        },
      },
      image: {
        width: theme.spacing(70 / 8),
        height: theme.spacing(70 / 8),
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      title: {
        marginTop: theme.spacing(22 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(20 / 8),
        },
      },
      titleMain: {
        fontSize: theme.spacing(26 / 8),
        lineHeight: `${theme.spacing(28 / 8)}px`,
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(30 / 8),
          lineHeight: `${theme.spacing(34 / 8)}px`,
        },
      },
      subtitle: {
        fontSize: theme.spacing(16 / 8),
        lineHeight: `${theme.spacing(20 / 8)}px`,
        margin: '8px 0px',
        fontWeight: 500,
        [theme.breakpoints.up('md')]: {
          margin: '8px 0px',
        },
      },
      searchRoot: {
        marginTop: theme.spacing(23 / 8),
      },
      rootButton: {
        width: '100%',
        marginTop: theme.spacing(12 / 8),
        fontSize: '18px !important',
        lineHeight: '22px',
        fontWeight: 500,
        whiteSpace: 'initial',
      },
      footer: {
        marginTop: theme.spacing(20 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(22 / 8),
        },
      },
      footerItem: {
        marginBottom: theme.spacing(5 / 8),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(4 / 8),
        },
      },
      linkText: {
        cursor: 'pointer',
      },
      loader: {
        '& span': {
          verticalAlign: 'middle',
        },
      },
    }),
);

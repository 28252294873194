import React, { FC, useCallback, useMemo } from 'react';

import { Box, CardMedia, Grid } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import { useHeaderBackButton } from 'hooks/useHeaderBackButton';
import Container from 'ui/common/Container';
import Link from 'ui/common/Link';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'ui/desktop/LanguageSwitcher';
import { userSelectors } from 'store';
import { DeliverySection, NavigationButton } from './components';
import UserMenu from '../UserMenu';
import Search from '../inputs/Search';

import useStyles from './styles';

import { IHeader, TOnImageError } from './types';

const Header: FC<IHeader> = ({ variant = 'default', ...rest }) => {
  const {
    showUserMenu,
    showSearchSection,
    imageUrl,
    userMenuProps,
    deliveryProps,
    navigationButtonProps,
    backButtonLink = {
      href: '/cart',
      as: '/cart',
    },
    isHideLogo,
    websiteDetails,
    showLanguageSwitcher, // TODO come from useHeader and should be dynamic
  } = rest;

  const isMinimumProfileDataCompleted = useSelector(userSelectors.isMinimumProfileDataCompleted);

  const classes = useStyles({
    variant,
    isLoggedIn: userMenuProps?.isLoggedIn,
    isMinimumProfileDataCompleted,
    userMenuSpace: userMenuProps?.userMenuSpace,
    ...rest,
  });

  const { renderBackButton } = useHeaderBackButton(backButtonLink);

  const { t } = useTranslation();

  const renderHeaderItems = useMemo(() => {
    switch (variant) {
      case 'default': {
        return (
          <>
            {navigationButtonProps && (
              <Box className={classes.navigationBtn}>
                <NavigationButton {...navigationButtonProps} />
              </Box>
            )}
          </>
        );
      }
      case 'deliverySelfPickup': {
        return (
          <>
            {deliveryProps && (
              <Box className={classes.deliverySectionBtn}>
                <DeliverySection {...deliveryProps} />
              </Box>
            )}
          </>
        );
      }
      case 'checkout': {
        return renderBackButton();
      }
      default:
        return null;
    }
  }, [
    variant,
    navigationButtonProps,
    classes.navigationBtn,
    classes.deliverySectionBtn,
    deliveryProps,
    renderBackButton,
  ]);

  const search = useMemo(() => {
    if (showSearchSection) {
      return (
        <Grid item xs={4} className={classes.searchSection}>
          <Search className={classes.searchInput} />
        </Grid>
      );
    }
  }, [classes.searchInput, classes.searchSection, showSearchSection]);

  const onImageError = useCallback<TOnImageError>((e) => {
    (e.target as HTMLImageElement).src = '/assets/images/store-logo-on-error.svg';
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Container maxContainerWidth>
        <Grid
          container
          justify={showSearchSection || isHideLogo ? 'flex-end' : 'space-between'}
          className={classes.contentWrp}
        >
          {!isHideLogo && (
            <Grid item xs={4} className={classes.labelSection}>
              <Link href={'/'}>
                <Box className={classes.logoWrapper}>
                  <CardMedia
                    classes={{ root: classes.logo }}
                    component="img"
                    image={imageUrl}
                    alt={t('shoppingPage.goToHomePage', { storeName: websiteDetails.name })}
                    onError={onImageError}
                  />
                </Box>
              </Link>
              <Link href={'/'}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="light"
                  className={classes.storeName}
                  isTranslate={false}
                >
                  {websiteDetails.name}
                </Typography>
              </Link>
            </Grid>
          )}
          {search}
          <Grid item xs={4} className={classes.itemsSection}>
            {renderHeaderItems}
            {showUserMenu && (
              <Box className={classes.userMenuBtn}>
                <UserMenu {...userMenuProps} />
              </Box>
            )}
            {showLanguageSwitcher && variant !== 'checkout' && <LanguageSwitcher />}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;

import { useCallback } from 'react';

import { TCurrency } from 'types';
import { TRenderCurrencyWithDirection } from 'utils';
import { useTheme } from '@material-ui/core';
import { TRenderPrice, TUseRenderPrice } from './types';

// default value, in future it can take from redux
const defaultCurrency: TCurrency = 'shekel';

// need to control price from pne place
const useRenderPrice: TUseRenderPrice = () => {
  const theme = useTheme();

  const renderCurrencyWithDirection = useCallback<TRenderCurrencyWithDirection>(
    (price, currencyMark) => {
      return theme.direction === 'rtl' ? `${currencyMark}${price}-` : `-${currencyMark}${price}`;
    },
    [theme.direction],
  );

  return useCallback<TRenderPrice>(
    (price = 0, currency = defaultCurrency, toFixed = 2) => {
      // avoid null in stories
      const number = price || 0;
      // to get only positive value
      const positiveValue = Math.abs(number);
      const parsedPrice = positiveValue.toFixed(toFixed);
      // to check if we need to render - sign in price string
      const isNegative = number < 0;

      switch (currency) {
        case 'shekel':
        default:
          return isNegative ? renderCurrencyWithDirection(parsedPrice, '₪') : `₪${parsedPrice}`;
      }
    },
    [renderCurrencyWithDirection],
  );
};

export default useRenderPrice;

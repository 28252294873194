import { TPromotionsDataSelector } from './types';

const promotionsData: TPromotionsDataSelector = ({
  promotions: { promotionsIds, promotionsById, promotionsForBanners, clubMembershipProfile },
}) => ({
  promotionsIds,
  promotionsById,
  promotionsForBanners,
  clubMembershipProfile,
});

export { promotionsData };

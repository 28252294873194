import React, { FC, useCallback } from 'react';

import { useHelperFieldText } from 'hooks';

import MapInput from 'ui/common/inputs/MapInput';

import { IMap } from 'ui/common/Map';

import { FormControl } from '@material-ui/core';
import { TMapFieldProps } from './types';

const MapField: FC<TMapFieldProps> = ({
  field: { value, name },
  form: { touched, setFieldValue, setFieldTouched, errors, initialValues },
  isError,
  errorText,
  ...rest
}) => {
  const renderErrorText = useHelperFieldText();
  const isShowError = (touched[name] && !!errors[name]) || isError;

  const handleChange = useCallback<IMap['onLocationChange']>(
    (newPos) => {
      // do not remove this line, u will get an error
      if (newPos === undefined) return;
      setFieldValue(name, newPos);

      // update touched
      setFieldTouched(name, true);
    },
    [setFieldValue, name, setFieldTouched],
  );

  return (
    <FormControl fullWidth component="fieldset">
      <MapInput
        error={isShowError}
        helperText={isShowError ? renderErrorText(errors[name] || errorText) : ''}
        onLocationChange={handleChange}
        name={name}
        markerPosition={value}
        initialPosition={initialValues[name]}
        isTouched={!!touched[name]}
        {...rest}
      />
    </FormControl>
  );
};
export default MapField;

import { useCallback, useState } from 'react';

import { OrdersService } from 'api';

import { TOrderServer } from 'types';

import { getIdsAndByIds } from 'utils';

import { TGetPreviousOrders, TPreviousOrders, TUsePreviousOrders } from './types';

const usePreviousOrders: TUsePreviousOrders = () => {
  const [previousOrdersObj, setPreviousOrdersObj] = useState<TPreviousOrders>({
    previousOrders: [],
    previousOrdersById: {},
  });

  const [loading, setLoading] = useState<boolean>(false);

  const getPreviousOrders = useCallback<TGetPreviousOrders>(async () => {
    // fetch date only when user logged in
    const actionResult = await OrdersService.getOrders({ setLoading });

    const { objById } = getIdsAndByIds<TOrderServer, string>(actionResult.data, 'id');

    setPreviousOrdersObj({ previousOrders: actionResult.data, previousOrdersById: objById });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    previousOrders: previousOrdersObj.previousOrders,
    previousOrdersById: previousOrdersObj.previousOrdersById,
    getPreviousOrders,
  };
};

export default usePreviousOrders;

import { Box, CardMedia, useTheme } from '@material-ui/core';
import { useMobile } from 'hooks';
import React, { FC, useContext, useMemo } from 'react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import { prepareImageUrl } from 'utils/helpers/prepareImageUrl';
import useStyles from './styles';

import { IBanner } from './types';

const Banner: FC<IBanner> = ({ imagesDesktop, imagesMobile }) => {
  const { isMobile } = useMobile();
  const mainLayoutContext = useContext(MainLayoutContext);
  const theme = useTheme();

  const classes = useStyles({ isOpenedTopCart: mainLayoutContext.isOpenedTopCart });

  const pagination = useMemo<PaginationOptions>(() => {
    return {
      clickable: true,
      renderBullet(_, className) {
        return `<a class="${className}"></a>`;
      },
      bulletClass: classes.pagination,
      bulletActiveClass: classes.activeBulletPagination,
      clickableClass: classes.clickablePagination,
    };
  }, [classes.activeBulletPagination, classes.clickablePagination, classes.pagination]);

  const imagesToDisplay = useMemo(() => {
    return isMobile ? imagesMobile : imagesDesktop;
  }, [imagesDesktop, imagesMobile, isMobile]);

  return (
    <Box className={classes.bannerWrapper}>
      <Box className={classes.bannerSwiperWrapper}>
        <Swiper
          modules={[EffectFade, Pagination, Autoplay]}
          dir={theme.direction}
          spaceBetween={isMobile ? 10 : 20}
          slidesPerView="1"
          speed={800}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          key={theme.direction}
          pagination={imagesToDisplay.length > 1 && pagination}
        >
          {imagesToDisplay.map((bannerImage, index) => (
            <SwiperSlide key={index}>
              <CardMedia
                classes={{ root: classes.bannerImage }}
                alt={bannerImage.altText}
                component="img"
                image={prepareImageUrl(bannerImage.url)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Banner;

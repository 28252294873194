import React, { FC, useCallback, useState } from 'react';
import { Box, Button, CardMedia } from '@material-ui/core';
import Typography from 'ui/common/Typography';

import Icon from 'ui/common/icons/Icon';
import { useSelector } from 'react-redux';
import { getIsIos, getLang } from 'store/modules/config/selectors';
import { useTranslation } from 'react-i18next';
import PWAApplePrompt from 'components/mobile/PWAApplePrompt';
import useStyles from './styles';
import { IPWADrawerButton, THandleInstall } from './types';

const PWADrawerButton: FC<IPWADrawerButton> = ({ onInstall }) => {
  const { t } = useTranslation();

  const lang = useSelector(getLang);
  const isIOS = useSelector(getIsIos);

  const [showIOSPrompt, setShowIOSPrompt] = useState<boolean>(false);

  const classes = useStyles({ lang });

  const handleInstall = useCallback<THandleInstall>(() => {
    if (onInstall) {
      if (isIOS) {
        setShowIOSPrompt(true);
      } else {
        onInstall();
      }
    }
  }, [isIOS, onInstall]);

  return (
    <>
      <Button onClick={handleInstall} className={classes.installBtn} data-id="pwa-prompt">
        <Box minHeight={58} height={1} display="flex" mr="auto">
          <Box display="flex" alignItems="center">
            <Icon name="icon-add" color="primary" classes={{ root: classes.addIcon }} />
            <Typography className={classes.buttonLabel}>{'pwa.installerPromptText'}</Typography>
          </Box>
        </Box>
        <Box className={classes.imageWrapper}>
          <CardMedia
            component="img"
            image="/assets/images/woman-with-phone.png"
            alt={t('pwa.iosPromptTitle')}
            style={{
              objectFit: 'contain',
              ...(lang !== 'he' && {
                transform: 'scaleX(-1)',
              }),
            }}
          />
        </Box>
      </Button>
      {showIOSPrompt && (
        <PWAApplePrompt open={showIOSPrompt} onClose={() => setShowIOSPrompt(false)} />
      )}
    </>
  );
};

export default PWADrawerButton;

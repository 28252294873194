import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesExistingOrderDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesExistingOrderDialog> =>
    createStyles({
      root: {
        padding: theme.spacing(4.75, 3), // 38px 24px
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(8.75, 8.75), // 70px 70px
        },
      },
      image: {
        width: theme.spacing(85 / 8),
        height: theme.spacing(80 / 8),
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      title: {
        marginTop: theme.spacing(22 / 8),

        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(20 / 8),
        },
      },
      titleMain: {
        fontSize: theme.spacing(26 / 8),
        lineHeight: `${theme.spacing(28 / 8)}px`,
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(30 / 8),
          lineHeight: `${theme.spacing(34 / 8)}px`,
        },
      },
      rootButton: {
        width: '100%',
        marginTop: theme.spacing(1.5), // 12px
      },
      existingOrderBody: {
        'white-space': 'break-spaces',
      },
    }),
);

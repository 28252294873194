import { useRouter } from 'next/router';
import React, { FC, useCallback } from 'react';
import { useDialog, useMobile, useWebsiteDetails } from 'hooks';
import { useTranslation } from 'react-i18next';

import { Box, CardMedia } from '@material-ui/core';

import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';

import useStyles from './styles';
import { ICartWasSharedDialog } from './types';

const CartWasSharedDialog: FC<ICartWasSharedDialog> = () => {
  const { t } = useTranslation();

  const websiteDetails = useWebsiteDetails();

  const { hideDialog } = useDialog();

  const { isMobile } = useMobile();

  const router = useRouter();

  const classes = useStyles();

  const goToCart = useCallback(() => {
    hideDialog();

    if (isMobile) {
      router.push('/cart');
    }
  }, [hideDialog, isMobile, router]);

  return (
    <Box className={classes.cartWasSharedWrapper}>
      <CardMedia
        src={websiteDetails.theme.images.shareCart}
        component="img"
        className={classes.cartWasSharedImage}
        alt={t('topCard.shareCart')}
      />
      <Typography className={classes.cartWasSharedTitle} variant="h2">
        {'topCard.cartWasShared'}
      </Typography>
      <Box className={classes.cartWasSharedMainWrapper}>
        <Typography className={classes.cartWasSharedSubtitle} variant="body1">
          {'topCard.cartWasSharedSuccessfully'}
        </Typography>
        <CTAButton className={classes.goToCartButton} onClick={goToCart}>
          {'topCard.goToCart'}
        </CTAButton>
      </Box>
    </Box>
  );
};

export default CartWasSharedDialog;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesCheckBoxWithLabel } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCheckBoxWithLabel> =>
    createStyles({
      root: {
        marginLeft: '-9px',
        marginRight: 0,
        borderRadius: theme.spacing(0.5), // 4px
      },
      label: {
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(1.75), // 14px
      },
    }),
);

import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesBannerPrompt } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesBannerPrompt> =>
    createStyles({
      installBtn: {
        padding: theme.spacing(14 / 8, 0),
        height: '58px',
        backgroundColor: `${theme.palette.secondary.main} !important`,
        borderRadius: '8px',
        width: '238px',
        marginBottom: theme.spacing(30 / 8),
      },
      buttonLabel: {
        fontSize: '13px',
        maxWidth: '132px',
        textAlign: 'start',
        color: theme.palette.common.white,
        fontWeight: 500,
        lineHeight: '15px',
        letterSpacing: 'normal',
        whiteSpace: 'pre-line',
      },
      addIcon: {
        fontSize: 26,
        marginRight: theme.spacing(6 / 8),
        marginLeft: theme.spacing(12 / 8),
      },
      imageWrapper: {
        height: '82px',
        width: '60px',
        position: 'relative',
        bottom: 12,
        left: 0,
        borderBottomRightRadius: '8px',
        overflow: 'hidden',
      },
    }),
);

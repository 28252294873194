import { PREPARE_TO_PLACE_ORDER_REQUEST, PREPARE_TO_PLACE_ORDER_SUCCESS } from './constants';

import { TPrepareToPlaceOrderRequestAction, TPrepareToPlaceOrderSuccessAction } from './types';

const prepareToPlaceOrderRequestAction: TPrepareToPlaceOrderRequestAction = (
  body,
  options,
  uiHelpers,
) => ({
  type: PREPARE_TO_PLACE_ORDER_REQUEST,
  payload: {
    body,
    uiHelpers,
    ...options,
  },
});

const prepareToPlaceOrderSuccess: TPrepareToPlaceOrderSuccessAction = (prepareToPlaceOrder) => ({
  type: PREPARE_TO_PLACE_ORDER_SUCCESS,
  payload: prepareToPlaceOrder,
});

export { prepareToPlaceOrderRequestAction, prepareToPlaceOrderSuccess };

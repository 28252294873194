import { Box, Divider } from '@material-ui/core';

import { isStoreProductOutOfStock } from 'components/common/contents/ViewSubscriptionContent/utils';
import { useMobile } from 'hooks/useMobile';
import { useNotify } from 'hooks/useNotify';
import { find } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, orderSelectors } from 'store';
import { updateClientActivityTimeAction } from 'store/modules/order/actions';
import { storeProductSelectors } from 'store/modules/storeProduct';

import { TOrderItemDTO, TOrderItemServer, TProductSellingUnitServer } from 'types';
import PreviousOrderItem from 'ui/common/PreviousOrderItem';
import { filterItemsOutOfStock } from 'utils/helpers/order/items';
import useStyles from './styles';
import {
  THandleClickItem,
  TOnSubmitItems,
  TRenderPreviousItemsList,
  TSelectedPreviousOrderItem,
  TUsePreviousOrderItems,
} from './types';

const usePreviousOrderItems: TUsePreviousOrderItems = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { notifyShow } = useNotify();

  const { storeProductById, ancestorStoreProductById } = useSelector(
    storeProductSelectors.storeProductsData,
  );

  const orderItems = useSelector(orderSelectors.orderItems);

  const { isMobile } = useMobile();

  const [selectedArray, setSelectedArray] = useState<TSelectedPreviousOrderItem[]>([]);

  const handleClickItem = useCallback<THandleClickItem>(
    (storeProduct) => () => {
      let newSelectedArray: TSelectedPreviousOrderItem[];
      if (selectedArray.some((selectedElement) => selectedElement.id === storeProduct.id)) {
        newSelectedArray = selectedArray.filter((el) => el.id !== storeProduct.id);
      } else {
        newSelectedArray = [...selectedArray];
        newSelectedArray.push(storeProduct);
      }
      setSelectedArray(newSelectedArray);
    },
    [selectedArray],
  );

  const selectAllItems = useCallback((previousOrderItems: TOrderItemServer[]) => {
    const selectedItemsIds: TSelectedPreviousOrderItem[] = [];
    previousOrderItems
      .filter((item) => item.requestedSellingUnit)
      .forEach((item) => {
        // only items in stock
        if (item.storeProduct && !isStoreProductOutOfStock(item.storeProduct)) {
          selectedItemsIds.push({
            id: item.storeProduct.id,
            ancestor: item.storeProduct.ancestor,
          });
        }
      });
    setSelectedArray(selectedItemsIds);
  }, []);

  const resetSelectedArray = useCallback(() => {
    setSelectedArray([]);
  }, []);

  const renderPreviousItemsList = useMemo<TRenderPreviousItemsList>(
    () => (previousOrderItems) => {
      return previousOrderItems
        .filter((item) => item.requestedSellingUnit)
        .map((item) => {
          return (
            <>
              {item.storeProduct && (
                <Box component="article" mb={isMobile ? 0 : 1.5} key={item.storeProduct.id}>
                  <PreviousOrderItem
                    clickAway={
                      !isStoreProductOutOfStock(item.storeProduct)
                        ? handleClickItem(item.storeProduct)
                        : undefined
                    }
                    {...item}
                    showActualQuantity={false}
                    displayCheckBox
                    displayBorderBottom
                    displayBorderTop
                    displayOutOfStockSection
                    checked={
                      selectedArray.some(
                        (selectedElement) => selectedElement.id === item.storeProduct?.id,
                      ) && !isStoreProductOutOfStock(item.storeProduct)
                    }
                  />
                  {isMobile && <Divider classes={{ root: classes.divider }} />}
                </Box>
              )}
            </>
          );
        });
    },
    [isMobile, handleClickItem, selectedArray, classes.divider],
  );

  // TODO - need to set chosen previous order items in order reducer state
  // TODO - shay - debug and check what is inside previousOrderItems (verify logic)
  const onSubmitItems = useCallback<TOnSubmitItems>(
    (previousOrderItems, onSuccess) => {
      const newOrderItems: TOrderItemDTO[] = [...orderItems];

      selectedArray.forEach((selectedItem) => {
        let storeProduct =
          storeProductById[selectedItem.id] ||
          (selectedItem.ancestor && storeProductById[selectedItem.ancestor?.id]);

        if (!storeProduct && ancestorStoreProductById) {
          storeProduct =
            ancestorStoreProductById[selectedItem.id] ||
            (selectedItem.ancestor?.id && ancestorStoreProductById[selectedItem.ancestor?.id]);
        }

        const previousOrderItem =
          find(previousOrderItems, ['storeProduct.id', selectedItem.id]) ||
          (selectedItem.ancestor &&
            find(previousOrderItems, ['storeProduct.id', selectedItem.ancestor.id]));

        if (storeProduct && previousOrderItem) {
          const orderItem: TOrderItemDTO = {
            storeProduct: {
              ...storeProduct,
            },
            requestedQuantity: previousOrderItem.requestedQuantity as number,
            requestedSellingUnit: {
              id: previousOrderItem.requestedSellingUnit?.id as TProductSellingUnitServer['id'],
            },
            productComment: previousOrderItem.productComment,
            sourceEvent: `previouslyOrderedItem${isMobile ? 'Mobile' : 'Desktop'}`,
          };

          const itemInCart = newOrderItems.find(
            (newOrderItem) => newOrderItem.storeProduct.id === orderItem.storeProduct.id,
          );

          let currentSellingUnit;
          // first look for the previously requested selling unit
          currentSellingUnit = storeProduct.productSellingUnits.find(
            (unit) =>
              unit.id ===
              (itemInCart ? itemInCart.requestedSellingUnit.id : orderItem.requestedSellingUnit.id),
          );

          // if previous item's selling unit is no longer available, get the selling unit with the nearest estimated weight
          if (!currentSellingUnit) {
            const estimatedUnitWeight =
              previousOrderItem?.requestedSellingUnit?.estimatedUnitWeight;
            if (estimatedUnitWeight) {
              currentSellingUnit = storeProduct.productSellingUnits.reduce((prev, curr) => {
                const currEstimatedUnitWeight = curr.estimatedUnitWeight || 0;
                const prevEstimatedUnitWeight = prev.estimatedUnitWeight || 0;

                return Math.abs(currEstimatedUnitWeight - estimatedUnitWeight) <
                  Math.abs(prevEstimatedUnitWeight - estimatedUnitWeight)
                  ? curr
                  : prev;
              });
            }
          }

          if (currentSellingUnit) {
            // set new selling unit
            orderItem.requestedSellingUnit = currentSellingUnit;

            if (orderItem.requestedQuantity % currentSellingUnit.amountJumps !== 0) {
              orderItem.requestedQuantity =
                currentSellingUnit.amountJumps *
                Math.ceil(orderItem.requestedQuantity / currentSellingUnit.amountJumps);

              if (
                currentSellingUnit.maxAmount &&
                orderItem.requestedQuantity > currentSellingUnit.maxAmount
              ) {
                orderItem.requestedQuantity = currentSellingUnit.maxAmount;
              }
            }

            if (!itemInCart) {
              newOrderItems.push({
                ...orderItem,
                requestedQuantity: Math.min(
                  orderItem.requestedQuantity,
                  currentSellingUnit.maxAmount,
                ),
              });
            } else {
              itemInCart.isRemoved = false;
              itemInCart.requestedQuantity = Math.min(
                itemInCart.requestedQuantity + orderItem.requestedQuantity,
                currentSellingUnit.maxAmount,
              );
            }
          }
        }
      });

      const { itemsInStock } = filterItemsOutOfStock(
        newOrderItems,
        storeProductById,
        ancestorStoreProductById,
      );

      dispatch(orderActions.setOrderItemsSuccessAction(itemsInStock));
      // update order website only if we have items in array
      if (newOrderItems.length !== 0) {
        dispatch(updateClientActivityTimeAction());
        // run callback if success
        if (onSuccess) {
          onSuccess();
        }
      } else {
        notifyShow({
          message: 'error.previousOrderNotExist',
        });
      }
    },
    [
      orderItems,
      selectedArray,
      storeProductById,
      ancestorStoreProductById,
      dispatch,
      isMobile,
      notifyShow,
    ],
  );

  return {
    onSubmitItems,
    selectedArray,
    renderPreviousItemsList,
    resetSelectedArray,
    selectAllItems,
  };
};

export default usePreviousOrderItems;

import { differenceInMilliseconds } from 'date-fns';
import { omit } from 'lodash';
import { createTransform } from 'redux-persist';
import {
  initialOrderDetailsState,
  initialOrderDiscountsState,
  initialOrderState,
  initialPrepareToPlaceOrderState,
  TAuthReducerState,
  TShoppingFlowReducerState,
  TStoreProductReducerState,
} from 'store/modules';

import { TStoreState } from 'store/types';
import useStore from 'store/useStore/useStore';
import { getIdsAndByIds } from 'utils';

const authTransformer = createTransform(
  (inboundState: TAuthReducerState) => {
    return { ...omit(inboundState, ['sessionOnlyJweToken', 'onClientBehalf']) };
  },
  (outboundState) => {
    const store = useStore();
    return {
      ...outboundState,
      jweToken: store.getState().auth.jweToken || outboundState.jweToken,
      sessionOnlyJweToken: store.getState().auth.sessionOnlyJweToken,
      onClientBehalf: store.getState().auth.onClientBehalf,
    };
  },
  { whitelist: ['auth'] },
);

const shoppingFlowTransformer = createTransform(
  (inboundState: TShoppingFlowReducerState) => {
    return { ...omit(inboundState, ['isIntroductoryPopupsCompleted']) };
  },
  (outboundState) => {
    const store = useStore();
    return {
      ...store.getState().shoppingFlow,
      ...outboundState,
      redirectAfterHourSelection: store.getState().store.websiteDetails.websiteSettings.isSmallEC
        ? false
        : outboundState.redirectAfterHourSelection,
    };
  },
  { whitelist: ['shoppingFlow'] },
);

const activeOrderTransformer = createTransform(
  (inboundState: TStoreState['activeOrder']) => {
    const store = useStore();
    const authState = store.getState().auth;

    if (authState.onClientBehalf) {
      return {
        order: initialOrderState,
        prepareToPlaceOrder: initialPrepareToPlaceOrderState,
        orderDetails: {
          ...initialOrderDetailsState,
          orderType: store.getState().store.websiteDetails.websiteSettings.defaultOrderType,
        },
        orderDiscounts: initialOrderDiscountsState,
      };
    }

    return {
      ...inboundState,
      order: {
        ...inboundState.order,
        items: inboundState.order.items.map((item) => ({
          ...item,
          storeProduct: {
            ...item.storeProduct,
            metadata: {},
            productExtraDetails: '',
            productCategory: {
              ...item.storeProduct.productCategory,
              metadataJson: '',
            },
          },
        })),
      },
    };
  },
  (outboundState) => {
    const currentTime = new Date().getTime();

    const store = useStore();
    const authState = store.getState().auth;

    const { orderDetails } = outboundState;
    const editMode =
      orderDetails.orderMode === 'edit' || orderDetails.orderMode === 'editSubscription';

    if (
      authState.onClientBehalf ||
      (editMode &&
        orderDetails.lastActivityTime &&
        Math.abs(currentTime - orderDetails.lastActivityTime) > 20 * 60 * 1000)
    ) {
      return {
        order: initialOrderState,
        prepareToPlaceOrder: initialPrepareToPlaceOrderState,
        orderDetails: {
          ...initialOrderDetailsState,
          orderType: store.getState().store.websiteDetails.websiteSettings.defaultOrderType,
        },
        orderDiscounts: initialOrderDiscountsState,
      };
    }

    const orderExpirationTime = outboundState?.prepareToPlaceOrder?.expirationTime;

    if (orderExpirationTime && orderExpirationTime < currentTime) {
      return {
        ...outboundState,
        order: {
          ...outboundState.order,
          items: outboundState.order.items.map((item) => ({ ...item, loadedFrom: 'storage' })),
        },
        prepareToPlaceOrder: initialPrepareToPlaceOrderState,
        orderDetails: {
          ...initialOrderDetailsState,
          orderType: store.getState().store.websiteDetails.websiteSettings.defaultOrderType,
        },
        orderDiscounts: initialOrderDiscountsState,
      };
    }

    if (
      store.getState().store.websiteDetails.websiteSettings.onlySelfPickups &&
      orderDetails.orderType === 'delivery'
    ) {
      // if user was login and chose delivery and then store changed to onlySelfPickups,
      // we need to put selfPickup as his default orderType
      return {
        ...outboundState,
        order: {
          ...outboundState.order,
          items: outboundState.order.items.map((item) => ({ ...item, loadedFrom: 'storage' })),
        },
        prepareToPlaceOrder: initialPrepareToPlaceOrderState,
        orderDetails: {
          ...initialOrderDetailsState,
          orderType: store.getState().store.websiteDetails.websiteSettings.defaultOrderType,
        },
        orderDiscounts: initialOrderDiscountsState,
      };
    }

    return {
      ...outboundState,
      order: {
        ...outboundState.order,
        items: outboundState.order.items.map((item) => ({ ...item, loadedFrom: 'storage' })),
      },
    };
  },
  { whitelist: ['activeOrder'] },
);

const maxCatalogSizeForCache = 1572864; // 1.5MB

const storeProductTransformer = createTransform(
  (inboundState: TStoreProductReducerState) => {
    const stringifiedPayloadSize = JSON.stringify(inboundState);

    if (stringifiedPayloadSize.length > maxCatalogSizeForCache) {
      return { ...inboundState, storeProductById: {}, storeProductIds: [] };
    }

    const storeProductsIdsAndByIdsWithoutPromotions = getIdsAndByIds(
      Object.values(inboundState.storeProductById).map((storeProduct) => ({
        ...storeProduct,
        promotion: undefined,
      })),
    );

    return {
      ...inboundState,
      storeProductById: storeProductsIdsAndByIdsWithoutPromotions.objById,
      storeProductIds: storeProductsIdsAndByIdsWithoutPromotions.arrayIds,
    };
  },
  (outboundState) => {
    const store = useStore();

    if (outboundState.storeProductIds.length === 0) {
      return {
        ...store.getState().storeProduct,
      };
    }

    return {
      ...store.getState().storeProduct,
      ...outboundState,
    };
  },
  { whitelist: ['storeProduct'] },
);

const configTransformer = createTransform(
  (inboundState: TStoreState['config']) => {
    return {
      lang: inboundState.lang,
      dir: inboundState.dir,
      isPWAInstallDismissed: inboundState.isPWAInstallDismissed,
    };
  },
  (outboundState) => {
    const store = useStore();

    return {
      ...store.getState().config,
      ...outboundState,
    };
  },
  { whitelist: ['config'] },
);

const catalogTransformer = createTransform(
  (inboundState: TStoreState['catalog']) => {
    return {
      localStorageLastUpdateTime: inboundState.ssrLastUpdateTime,
      catalogId: inboundState.catalogId,
    };
  },
  (outboundState) => {
    const store = useStore();

    return {
      ...store.getState().catalog,
      ...outboundState,
    };
  },
  { whitelist: ['catalog'] },
);

const websiteDetailsTransformer = createTransform(
  (inboundState: TStoreState['store']) => inboundState,
  (outboundState) => {
    const store = useStore();

    const lastHoursOfShowingWelcomePopup = Math.floor(
      differenceInMilliseconds(
        new Date().getTime(),
        outboundState.websiteDetails.lastTimeShowingWelcomeDialog,
      ) /
        (1000 * 60 * 60),
    );

    return {
      ...store.getState().store,
      websiteDetails: {
        ...store.getState().store.websiteDetails,
        showWelcomeDialog:
          store.getState().store.websiteDetails.updateDate !==
            outboundState.websiteDetails.updateDate || +lastHoursOfShowingWelcomePopup > 24,
        lastTimeShowingWelcomeDialog: outboundState.websiteDetails.lastTimeShowingWelcomeDialog,
      },
    };
  },
  { whitelist: ['store'] },
);

export {
  authTransformer,
  activeOrderTransformer,
  configTransformer,
  websiteDetailsTransformer,
  catalogTransformer,
  storeProductTransformer,
  shoppingFlowTransformer,
};

import { TUseProductsSort } from 'hooks/useProductsSort/types';
import { useCallback, useContext } from 'react';
import { IStoreProductDisplay, storeProductSelectors, TStoreProductReducerState } from 'store';
import { TRenderLanguageField, useMultiLanguage } from 'hooks/useMultiLanguage';
import { useSelector } from 'react-redux';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';

const isPromoted = (product: IStoreProductDisplay): boolean => {
  return product.promoted || !!product.promotion;
};

const searchRelevancyComparator = (
  idProductA: IStoreProductDisplay['id'],
  idProductB: IStoreProductDisplay['id'],
  storeProductById: TStoreProductReducerState['storeProductById'],
  searchQuery: string,
  renderLanguageField: TRenderLanguageField,
): number => {
  const lowerCaseSearchQuery = searchQuery.toLowerCase();
  const searchWords = lowerCaseSearchQuery.split(' ').filter((word) => word);

  const productA = storeProductById[idProductA];
  const productB = storeProductById[idProductB];

  const productsWithSplittedProperties = [
    {
      fullName: productA.fullName.toLowerCase().split(/[ \n]/),
      name: renderLanguageField(productA.product?.multiLang, 'name').toLowerCase().split(/[ \n]/),
      productExtraDetails: productA.productExtraDetails?.toLowerCase().split(/[ \n]/),
      supplierName: productA.supplier?.name.toLowerCase().split(/[ \n]/),
      relevance: 0,
    },
    {
      fullName: productB.fullName.toLowerCase().split(/[ \n]/),
      name: renderLanguageField(productB.product?.multiLang, 'name').toLowerCase().split(/[ \n]/),
      productExtraDetails: productB.productExtraDetails?.toLowerCase().split(/[ \n]/),
      supplierName: productB.supplier?.name.toLowerCase().split(/[ \n]/),
      relevance: 0,
    },
  ];

  productsWithSplittedProperties.forEach((currentProduct) => {
    const aOrBProduct = currentProduct;

    if (aOrBProduct.fullName.join(' ') === lowerCaseSearchQuery) {
      aOrBProduct.relevance += 4000000;
      return;
    }
    if (aOrBProduct.name.join(' ') === lowerCaseSearchQuery) {
      aOrBProduct.relevance += 3000000;
      return;
    }
    if (aOrBProduct.productExtraDetails?.join(' ') === lowerCaseSearchQuery) {
      aOrBProduct.relevance += 2000000;
      return;
    }
    if (aOrBProduct.supplierName?.join(' ') === lowerCaseSearchQuery) {
      aOrBProduct.relevance += 1000000;
      return;
    }

    searchWords.forEach((word) => {
      // one of the product property start with the same word as in search
      if (aOrBProduct.fullName.indexOf(word) === 0) {
        aOrBProduct.relevance += 400000;
        return;
      }
      if (aOrBProduct.name.indexOf(word) === 0) {
        aOrBProduct.relevance += 300000;
        return;
      }
      if (aOrBProduct.productExtraDetails?.indexOf(word) === 0) {
        aOrBProduct.relevance += 200000;
        return;
      }
      if (aOrBProduct.supplierName?.indexOf(word) === 0) {
        aOrBProduct.relevance += 100000;
        return;
      }
      // one of the product property contain the same word as search
      if (aOrBProduct.fullName.find((product) => product === word)) {
        aOrBProduct.relevance += 40000;
        return;
      }
      if (aOrBProduct.name.find((product) => product === word)) {
        aOrBProduct.relevance += 30000;
        return;
      }
      if (aOrBProduct.productExtraDetails?.find((product) => product === word)) {
        aOrBProduct.relevance += 20000;
        return;
      }
      if (aOrBProduct.supplierName?.find((product) => product === word)) {
        aOrBProduct.relevance += 10000;
        return;
      }
      // product has one of the word that starts as search
      if (aOrBProduct.fullName.find((product) => product.startsWith(word))) {
        aOrBProduct.relevance += 4000;
        return;
      }
      if (aOrBProduct.name.find((product) => product.startsWith(word))) {
        aOrBProduct.relevance += 3000;
        return;
      }
      if (aOrBProduct.productExtraDetails?.forEach((product) => product.startsWith(word))) {
        aOrBProduct.relevance += 2000;
        return;
      }
      if (aOrBProduct.supplierName?.find((product) => product.startsWith(word))) {
        aOrBProduct.relevance += 1000;
        return;
      }
      // product include word from search
      if (aOrBProduct.fullName.find((partName) => partName.includes(word))) {
        aOrBProduct.relevance += 400;
        return;
      }
      if (aOrBProduct.name.find((partName) => partName.includes(word))) {
        aOrBProduct.relevance += 300;
        return;
      }
      if (aOrBProduct.productExtraDetails?.find((partName) => partName.includes(word))) {
        aOrBProduct.relevance += 200;
        return;
      }
      if (aOrBProduct.supplierName?.find((partName) => partName.includes(word))) {
        aOrBProduct.relevance += 100;
      }
    });
  });
  return productsWithSplittedProperties[1].relevance - productsWithSplittedProperties[0].relevance;
};

const promotedComparator = (a: IStoreProductDisplay, b: IStoreProductDisplay): number => {
  if (isPromoted(a) === isPromoted(b)) {
    return 0;
  }

  return isPromoted(a) ? -1 : 1;
};

const isPopular = (product: IStoreProductDisplay): boolean => {
  return product.currentRelevancy?.name === 'popular';
};

const currentRelevancyComparator = (a: IStoreProductDisplay, b: IStoreProductDisplay): number => {
  if (isPopular(a) === isPopular(b)) {
    return 0;
  }

  return isPopular(a) ? -1 : 1;
};

const useProductsSort: TUseProductsSort = () => {
  const { storeProductById } = useSelector(storeProductSelectors.storeProductsData);

  const { renderLanguageField } = useMultiLanguage();

  const mainLayoutContext = useContext(MainLayoutContext);

  const sortStoreProducts = useCallback(
    (storeProducts: IStoreProductDisplay[], selectedSortOrder) => {
      switch (selectedSortOrder) {
        case 1:
        case 12:
          return storeProducts.sort((a, b) => a.displayName.localeCompare(b.displayName));
        case 2:
        case 11:
        default:
          return storeProducts.sort(
            (a, b) =>
              currentRelevancyComparator(a, b) ||
              a.productCategory.sortOrder - b.productCategory.sortOrder ||
              promotedComparator(a, b) ||
              a.displayName.localeCompare(b.displayName),
          );
        case 3:
        case 13:
          return storeProducts.sort((a, b) => a.price - b.price);
        case 4:
        case 14:
          return storeProducts.sort((a, b) => b.price - a.price);
        case 5:
        case 15:
          return storeProducts.sort(
            (a, b) =>
              promotedComparator(a, b) ||
              a.productCategory.sortOrder - b.productCategory.sortOrder ||
              a.displayName.localeCompare(b.displayName),
          );
        case 6:
          return storeProducts.sort(
            (a, b) =>
              a.productCategory.sortOrder - b.productCategory.sortOrder ||
              a.displayName.localeCompare(b.displayName),
          );
        case 7:
          return storeProducts.sort(
            (a, b) =>
              a.productCategory.sortOrder - b.productCategory.sortOrder || a.price - b.price,
          );
        case 8:
          return storeProducts.sort(
            (a, b) =>
              a.productCategory.sortOrder - b.productCategory.sortOrder || b.price - a.price,
          );
        case 9:
          return storeProducts.sort(
            (a, b) =>
              a.productCategory.sortOrder - b.productCategory.sortOrder ||
              currentRelevancyComparator(a, b) ||
              promotedComparator(a, b) ||
              a.displayName.localeCompare(b.displayName),
          );
        case 10:
          return storeProducts.sort((productA, productB) =>
            searchRelevancyComparator(
              productA.id,
              productB.id,
              storeProductById,
              mainLayoutContext.searchContext.searchQuery as string,
              renderLanguageField,
            ),
          );
      }
    },
    [storeProductById, mainLayoutContext.searchContext.searchQuery, renderLanguageField],
  );

  return {
    sortStoreProducts,
  };
};

export default useProductsSort;

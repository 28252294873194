import { Box, CardMedia } from '@material-ui/core';

import { OrderService } from 'api';

import { getDay, parse } from 'date-fns';

import { useDialog } from 'hooks/useDialog';
import { useMobile } from 'hooks/useMobile';
import { useRenderDate } from 'hooks/useRenderDate';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { openOrderForEditing } from 'store/modules/orderEditing/actions';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';
import CTAButton from 'ui/common/buttons/CTAButton';
import Typography from 'ui/common/Typography';
import { dateFormatInOrderHistory } from 'utils/constants';

import useStyles from './styles';
import { IExistingOrderDialog } from './types';

const ExistingOrderDialog: FC<IExistingOrderDialog> = ({ order }) => {
  const classes = useStyles();

  const { hideDialog } = useDialog();
  const { isMobile } = useMobile();

  const websiteDetails = useWebsiteDetails();
  const { renderDay } = useRenderDate();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const closeDialog = useCallback(() => {
    dispatch(introductoryPopupsCompletedAction(true));

    hideDialog();
  }, [dispatch, hideDialog]);

  const editOrder = useCallback(() => {
    OrderService.getOrder(order.id, { setLoading }).then((actionResult) => {
      dispatch(openOrderForEditing(actionResult.data));
      hideDialog();
    });
  }, [dispatch, hideDialog, order]);

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={websiteDetails.theme.images.hasNewOrder}
          component="img"
          className={classes.image}
          alt={t('dialog.existingOrder.title')}
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h1" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.existingOrder.title'}
        </Typography>
        <Box mb={isMobile ? 10 / 8 : 12 / 8} mt={10 / 8}>
          <Box>
            <Typography
              variant="body1"
              fontSize={16}
              color="grey"
              align="center"
              className={classes.existingOrderBody}
              isTranslate={false}
            >
              {t('dialog.existingOrder.body', {
                deliveryDate: `${t(
                  renderDay(
                    getDay(parse(order.deliveryDate, dateFormatInOrderHistory, new Date())),
                  ),
                )}`,
                deliveryTime: order.deliveryHour,
              })}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              variant="body1"
              fontSize={16}
              color="textSecondary"
              align="center"
              fontWeight="medium"
            >
              {'dialog.existingOrder.description'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <CTAButton onClick={editOrder} className={classes.rootButton} loading={loading}>
          <Typography fontWeight="medium">{'dialog.existingOrder.submitButton'}</Typography>
        </CTAButton>
        <CTAButton onClick={closeDialog} className={classes.rootButton} variant="outlined">
          <Typography fontWeight="medium">{'dialog.existingOrder.cancelButton'}</Typography>
        </CTAButton>
      </Box>
    </Box>
  );
};

export default ExistingOrderDialog;

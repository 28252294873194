import React, { FC, useMemo } from 'react';
import { CardMedia, useTheme } from '@material-ui/core';
import { PaginationOptions } from 'swiper/types/modules/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { IProductImageSlider } from './types';
import useStyles from './styles';

const MobileProductImageSlider: FC<IProductImageSlider> = ({ additionalImages, fullName }) => {
  const classes = useStyles();
  const theme = useTheme();

  const pagination: PaginationOptions = useMemo(() => {
    return {
      clickable: true,
      renderBullet(_, className) {
        return `<a class="${className}"></a>`;
      },
      bulletClass: classes.pagination,
      bulletActiveClass: classes.activeBulletPagination,
      clickableClass: classes.clickablePagination,
    };
  }, [classes.pagination, classes.activeBulletPagination, classes.clickablePagination]);

  return (
    <Swiper
      modules={[Pagination]}
      height={240}
      className={classes.swiper}
      pagination={pagination}
      spaceBetween={12}
      dir={theme.direction}
      loop
    >
      {additionalImages?.map((url, i) => (
        <SwiperSlide dir={theme.direction} key={i}>
          <CardMedia
            classes={{ root: classes.cardImage }}
            component="img"
            image={url}
            alt={fullName}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default MobileProductImageSlider;

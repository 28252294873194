import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesDeliveringToAddressDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDeliveringToAddressDialog, { showDeliverySchedule: boolean }> =>
    createStyles({
      root: {
        padding: theme.spacing(4.75, 3), // 38px 24px
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(8.75, 8.75), // 70px 70px
        },
      },
      image: {
        width: '70px',
        height: '70px',
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      mainWrapper: {
        marginTop: theme.spacing(22 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(20 / 8),
        },
      },
      bodyWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '208px',
        margin: '0 auto',
        marginTop: '22px',
        [theme.breakpoints.up('md')]: {
          width: '220px',
        },
      },
      title: {
        textAlign: 'center',
        fontSize: '26px',
        lineHeight: '28px',
        [theme.breakpoints.up('md')]: {
          fontSize: '30px',
          lineHeight: '34px',
        },
      },
      editDeliveryAddressBtn: {
        minWidth: 'unset',
        backgroundColor: 'transparent !important',
        padding: '0 !important',
      },
      rootButton: {
        width: '100%',
        margin: theme.spacing(22 / 8, 0, 0),
      },
      showDeliveryScheduleButton: {
        padding: '0',
        marginTop: theme.spacing(22 / 8),
        width: '100%',
      },
      outlinedRootButton: {
        width: '100%',
        margin: theme.spacing(12 / 8, 0, 0),
      },
      deliveryHoursAndMoreDetails: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
      rootDivider: {
        width: '100%',
        position: 'relative',
        backgroundColor: theme.palette.grey.A100,
      },
      arrowIcon: ({ showDeliverySchedule }) => ({
        fontSize: '10px',
        marginLeft: theme.spacing(4 / 8),
        transition: 'transform 300ms',
        transform: showDeliverySchedule ? 'rotate(180deg)' : 'initial',
      }),
    }),
);

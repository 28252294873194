import React from 'react';

import { NextPage } from 'next';
import MainLayout from 'ui/common/layout/MainLayout';
import Custom404Content from 'components/common/contents/Custom404Content';

const Custom404: NextPage = () => {
  return (
    <MainLayout isHideFooter={true}>
      <Custom404Content />
    </MainLayout>
  );
};

export default Custom404;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesTermsOfUseDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesTermsOfUseDialog> =>
    createStyles({
      root: {
        padding: theme.spacing(20 / 8, 40 / 8),
      },
      backTo: {
        top: '0',
        [theme.breakpoints.up('sm')]: {
          position: 'absolute',
          textAlign: 'center',
          top: theme.spacing(6 / 8),
          right: '0',
        },
      },
      backToText: {
        paddingRight: theme.spacing(12 / 8),
      },
      textContent: {
        '& ul, ol': {
          paddingInlineStart: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
          '& ul, ol': {
            paddingInlineStart: theme.spacing(24 / 8),
          },
        },
      },
    }),
);

import React, { FC, useCallback, useMemo } from 'react';

import { useMultiLanguage } from 'hooks/useMultiLanguage';

import { Box, Button } from '@material-ui/core';
import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { ISellingUnitsSelectionButtons } from './types';

const SellingUnitsSelectionButtons: FC<ISellingUnitsSelectionButtons> = ({
  activeSellingUnit,
  productSellingUnits,
  size = 'medium',
  onClick,
  height = '26px',
}) => {
  const { renderLanguageField } = useMultiLanguage();

  const classes = useStyles({ height, size });

  const handleSellingUnitTypeChange = useCallback(
    (e, selectedSellingUnitId) => {
      // to prevent opening popup and link redirection
      e.preventDefault();
      e.stopPropagation();
      if (onClick && selectedSellingUnitId !== activeSellingUnit.id) {
        onClick(selectedSellingUnitId);
      }
    },
    [activeSellingUnit.id, onClick],
  );

  const buttons = useMemo(() => {
    return productSellingUnits.map((sellingUnit) => {
      return (
        <Button
          key={sellingUnit.id}
          disabled={activeSellingUnit.id === sellingUnit.id}
          variant={activeSellingUnit.id === sellingUnit.id ? 'contained' : 'outlined'}
          classes={{
            root: classes.sellingUnitButton,
            outlined: classes.disabledSellingUnit,
            contained: classes.activeSellingUnit,
          }}
          onClick={(e) => handleSellingUnitTypeChange(e, sellingUnit.id)}
        >
          <Typography className={classes.sellingUnitLabel} variant="body1" isTranslate={false}>
            {renderLanguageField(sellingUnit.sellingUnit.multiLang, 'name')}
          </Typography>
        </Button>
      );
    });
  }, [
    activeSellingUnit.id,
    classes.activeSellingUnit,
    classes.disabledSellingUnit,
    classes.sellingUnitButton,
    classes.sellingUnitLabel,
    handleSellingUnitTypeChange,
    productSellingUnits,
    renderLanguageField,
  ]);
  return <Box className={classes.unitsTypeWrapper}>{buttons}</Box>;
};

export default SellingUnitsSelectionButtons;

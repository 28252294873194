import { Box, Button } from '@material-ui/core';

import { SelfPickupAvailableService } from 'components/common/availableService';
import { TLocation } from 'components/common/LocationAutocomplete';
import SkeletonSelfPickupPoints from 'components/common/skeletons/SkeletonSelfPickupPoints';

import {
  TServiceArea,
  useAvailableServiceAreas,
  useDialog,
  useMobile,
  useSelfPickupAndDeliveryService,
  useWebsiteDetails,
} from 'hooks';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  authSelectors,
  DELIVERY_DIALOG,
  LOCATION_DIALOG,
  orderDetailsActions,
  userSelectors,
} from 'store';

import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import Icon from 'ui/common/icons/Icon';
import DeliveryPickUpTabs from 'ui/common/tabs/DeliveryPickUpTabs';

import Typography from 'ui/common/Typography';
import useStyles from './styles';

import { ISelfPickupDialog } from './types';

const SelfPickupDialog: FC<ISelfPickupDialog> = ({ initialServiceArea, customSelfPickupAreas }) => {
  const { isMobile } = useMobile();

  const dispatch = useDispatch();

  const { showDialog } = useDialog();

  const { loading, selfPickupErrorMessage, getAvailableAreas, selfPickupAreasArray } =
    useAvailableServiceAreas(customSelfPickupAreas);

  const websiteDetails = useWebsiteDetails();

  const {
    sortSelfPickupAreas,
    handleSelfPickupSubmit,
    handleUpdateSelfPickupArea,
    isSubmitting,
    getSelectedAddressPlaceIdAndDistanceToPickupPoints,
    resetDistanceToSelectedAddress,
    sortSelfPickupAreasByPointName,
    getUserLocationAndUpdateDistanceToSelfPickupPoints,
  } = useSelfPickupAndDeliveryService();

  const orderDetails = useSelector(getOrderDetails);
  const profile = useSelector(userSelectors.getProfile);

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const isProfileCompleted = useSelector(userSelectors.isProfileCompleted);

  const [inputValue, setInputValue] = useState<TLocation | null>(null);
  const [sortByPickupName, setSortByPickupName] = useState<boolean>();
  const [selfPickupAreasToDisplay, setSelfPickupAreasToDisplay] = useState(selfPickupAreasArray);
  const [isGetCurrentLocationOnMobileClick, setIsGetCurrentLocationOnMobileClick] =
    useState<boolean>(false);
  const [activeServiceArea, setActiveServiceArea] = useState<TServiceArea | null>(null);
  const [loadingUserCurrentLocation, setLoadingUserCurrentLocation] = useState<boolean>(false);

  const amountOfLimitedSelfPickupPoints = 8;

  const isShowMapOnFullScreen = useMemo(() => {
    return (
      websiteDetails.websiteSettings.onlySelfPickups &&
      selfPickupAreasArray?.length >= amountOfLimitedSelfPickupPoints
    );
  }, [
    amountOfLimitedSelfPickupPoints,
    selfPickupAreasArray?.length,
    websiteDetails.websiteSettings.onlySelfPickups,
  ]);

  const classes = useStyles({
    onlySelfPickups: websiteDetails.websiteSettings.onlySelfPickups,
    isShowMapOnFullScreen,
  });

  useEffect(() => {
    setSelfPickupAreasToDisplay(selfPickupAreasArray);
  }, [selfPickupAreasArray]);

  useEffect(() => {
    if (customSelfPickupAreas && !!customSelfPickupAreas.length) return;
    getAvailableAreas();
  }, [getAvailableAreas, customSelfPickupAreas]);

  useEffect(() => {
    if (isLoggedIn && orderDetails.orderType && orderDetails.orderType !== 'selfPickup') {
      dispatch(orderDetailsActions.openDeliverySelfPickupRequest('selfPickup'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  const handleClickDelivery = useCallback(() => {
    if (!isLoggedIn) {
      showDialog({
        dialogType: LOCATION_DIALOG,
      });
      return;
    }
    showDialog({
      dialogType: DELIVERY_DIALOG,
      contentProps: {
        initialView: !isProfileCompleted ? 'createNewDeliveryAddress' : 'deliveryHours',
      },
    });
  }, [isLoggedIn, isProfileCompleted, showDialog]);

  const handleChangeServiceArea = useCallback((serviceArea) => {
    setActiveServiceArea(serviceArea);
  }, []);

  const getCurrentLocationButton = useMemo(() => {
    if (!websiteDetails.websiteSettings.onlySelfPickups) return;

    if (activeServiceArea) return;

    if (isMobile && isShowMapOnFullScreen) return;

    return (
      <Button
        style={{ padding: 0, alignItems: 'center' }}
        className={classes.getUserCurrentLocation}
        disableTouchRipple
        onClick={() => {
          getUserLocationAndUpdateDistanceToSelfPickupPoints(
            selfPickupAreasArray,
            (selfPickupAreasWithDistanceToClient) =>
              setSelfPickupAreasToDisplay(selfPickupAreasWithDistanceToClient),
          );
          setInputValue(null);
          setSortByPickupName(false);
        }}
      >
        <Icon
          name="icon-current-location"
          color="primary"
          classes={{ root: classes.currentLocationIcon }}
        />
        <Typography
          fontSize={16}
          fontWeight="medium"
          color="primary"
          className={classes.sortPointsByCurrentLocationLabel}
        >
          {'deliveryPickUp.sortPointsByCurrentLocation'}
        </Typography>
      </Button>
    );
  }, [
    activeServiceArea,
    classes.currentLocationIcon,
    classes.getUserCurrentLocation,
    classes.sortPointsByCurrentLocationLabel,
    getUserLocationAndUpdateDistanceToSelfPickupPoints,
    isMobile,
    isShowMapOnFullScreen,
    selfPickupAreasArray,
    websiteDetails.websiteSettings.onlySelfPickups,
  ]);

  const renderHeaderContent = useMemo<ReactNode>(() => {
    return (
      <Box className={classes.headerContent}>
        <Box className={classes.deliveryBlock}>
          <Typography
            variant={isMobile ? 'h2' : 'h1'}
            color="light"
            className={classes.popupHeader}
          >
            {`deliveryPickUp.${
              websiteDetails.websiteSettings.onlySelfPickups
                ? (activeServiceArea && 'onlySelfPickupChoseTime') || 'onlySelfPickup'
                : 'chooseDeliveryMethod'
            }`}
          </Typography>
        </Box>
        {getCurrentLocationButton}
        {!websiteDetails.websiteSettings.onlySelfPickups && (
          <Box className={classes.tabsWrapper}>
            <DeliveryPickUpTabs
              onTabClick={handleClickDelivery}
              orderType="selfPickup"
              showSideCorners
            />
          </Box>
        )}
      </Box>
    );
  }, [
    classes.headerContent,
    classes.deliveryBlock,
    classes.popupHeader,
    classes.tabsWrapper,
    isMobile,
    websiteDetails.websiteSettings.onlySelfPickups,
    activeServiceArea,
    getCurrentLocationButton,
    handleClickDelivery,
  ]);

  const updateSelectedAddressAndSortPickupPointAccordingly = useCallback(
    (selectedAddress) => {
      setInputValue(selectedAddress);
      setIsGetCurrentLocationOnMobileClick(false);

      if (!selectedAddress) {
        const newArray = resetDistanceToSelectedAddress(selfPickupAreasArray);
        if (websiteDetails.websiteSettings.onlySelfPickups) {
          setSortByPickupName(true);
        }

        setSelfPickupAreasToDisplay(newArray);

        return;
      }

      if (selectedAddress.placeId === 'shareMyLocation') {
        getUserLocationAndUpdateDistanceToSelfPickupPoints(
          selfPickupAreasArray,
          (selfPickupAreasWithDistanceToClient) => {
            setSelfPickupAreasToDisplay(selfPickupAreasWithDistanceToClient);
            setSortByPickupName(false);
          },
        );
        return;
      }

      getSelectedAddressPlaceIdAndDistanceToPickupPoints(
        selectedAddress,
        selfPickupAreasArray,
      ).then((pickupAreasWithDistanceToSelectedAddress) => {
        if (pickupAreasWithDistanceToSelectedAddress) {
          setSortByPickupName(false);
          setSelfPickupAreasToDisplay(pickupAreasWithDistanceToSelectedAddress);
        }
      });
    },
    [
      getSelectedAddressPlaceIdAndDistanceToPickupPoints,
      getUserLocationAndUpdateDistanceToSelfPickupPoints,
      resetDistanceToSelectedAddress,
      selfPickupAreasArray,
      websiteDetails.websiteSettings.onlySelfPickups,
    ],
  );

  const selfPickupAreas = useMemo(() => {
    if (sortByPickupName) {
      return sortSelfPickupAreasByPointName(selfPickupAreasToDisplay);
    }

    return sortSelfPickupAreas(selfPickupAreasToDisplay);
  }, [
    sortByPickupName,
    sortSelfPickupAreas,
    selfPickupAreasToDisplay,
    sortSelfPickupAreasByPointName,
  ]);

  const distanceLabel = useMemo(() => {
    if (inputValue && inputValue.placeId !== 'shareMyLocation') {
      return 'kmFromSelectedAddress';
    }

    if (
      isLoggedIn &&
      profile.fullAddress &&
      !websiteDetails.websiteSettings.onlySelfPickups &&
      (!inputValue || (inputValue && inputValue.placeId !== 'shareMyLocation')) &&
      !isGetCurrentLocationOnMobileClick
    ) {
      return 'kmFromYourAddress';
    }
    return 'kmFromYourCurrentLocation';
  }, [
    inputValue,
    isGetCurrentLocationOnMobileClick,
    isLoggedIn,
    profile.fullAddress,
    websiteDetails.websiteSettings.onlySelfPickups,
  ]);

  useEffect(() => {
    // when user doesn't have address - sort by his location
    // if location turn off - sort by pickup name

    if (!profile.fullAddress && selfPickupAreasArray.length) {
      setLoadingUserCurrentLocation(true);
      getUserLocationAndUpdateDistanceToSelfPickupPoints(
        selfPickupAreasArray,
        (selfPickupAreasWithDistanceToClient) => {
          setSelfPickupAreasToDisplay(selfPickupAreasWithDistanceToClient);
          setLoadingUserCurrentLocation(false);
        },
        () => {
          setLoadingUserCurrentLocation(false);
          setSortByPickupName(true);
        },
      );
    }
  }, [
    getUserLocationAndUpdateDistanceToSelfPickupPoints,
    profile.fullAddress,
    selfPickupAreasArray,
  ]);

  return (
    <Box>
      <Box className={classes.header}>
        {renderHeaderContent}
        <Box className={classes.imageWrapper}>
          <img
            src={websiteDetails.theme.images.selfPickupPopupHeader}
            className={classes.headerImage}
            alt=""
          />
        </Box>
      </Box>
      <Box>
        {loading || loadingUserCurrentLocation ? (
          <Box margin={isMobile ? '27px 14px 50px 14px' : '33px 50px 50px 50px'}>
            <SkeletonSelfPickupPoints />
          </Box>
        ) : (
          <Box>
            {selfPickupAreasToDisplay?.length ? (
              <SelfPickupAvailableService
                onChangeServiceArea={handleChangeServiceArea}
                activeServiceArea={activeServiceArea}
                distanceLabel={`dialog.delivery.${distanceLabel}`}
                amountOfPickupPointsToShowMap={amountOfLimitedSelfPickupPoints}
                isSubmitting={isSubmitting}
                selfPickupAreasArray={selfPickupAreas}
                onSubmit={handleSelfPickupSubmit}
                initialSelfPickupLocation={
                  isLoggedIn ? initialServiceArea || orderDetails.selfPickupLocation : undefined
                }
                onUpdateArea={handleUpdateSelfPickupArea}
                addressInputValue={inputValue}
                onLocationAutocompleteChange={updateSelectedAddressAndSortPickupPointAccordingly}
                onlySelfPickups={websiteDetails.websiteSettings.onlySelfPickups}
                onGetCurrentLocationClick={() => {
                  getUserLocationAndUpdateDistanceToSelfPickupPoints(
                    selfPickupAreasArray,
                    (selfPickupAreasWithDistanceToClient) =>
                      setSelfPickupAreasToDisplay(selfPickupAreasWithDistanceToClient),
                  );
                  setInputValue(null);
                  setSortByPickupName(false);
                  setIsGetCurrentLocationOnMobileClick(true);
                }}
              />
            ) : (
              <Typography variant="body1" fontSize={16} className={classes.errorMessage}>
                {selfPickupErrorMessage}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SelfPickupDialog;

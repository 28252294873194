import { Box, Button, CardActions, Divider, IconButton, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { useDialog } from 'hooks';
import { useBagOfProducts } from 'hooks/useBagOfProducts';
import { useMobile } from 'hooks/useMobile';
import { useMultiLanguage } from 'hooks/useMultiLanguage';

import { TProductAdditionSource, useProduct } from 'hooks/useProduct';
import useProductPrice from 'hooks/useProductPrice';

import useRelevancy from 'hooks/useRelevancy';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { ITEMS_PROMOTION_DIALOG } from 'store';
import { getLang } from 'store/modules/config/selectors';
import { TSupplier } from 'types';
import ActionsOnCartButton from 'ui/common/ActionsOnCartButton';
import Icon from 'ui/common/icons/Icon';
import SellingUnitsSelectionButtons from 'ui/common/SellingUnitsSelectionButtons';
import PromotionLimitationsDescription from 'ui/common/Tooltip/PromotionLimitationsDescription';
import Typography from 'ui/common/Typography';

import BasicStoreProductCard from 'ui/desktop/BasicStoreProductCard';

import useStyles from './styles';
import { IProductDescription } from './types';

const ProductDescription: FC<IProductDescription> = (props) => {
  const {
    onChooseUnit,
    onAddProduct,
    onChangeProductCount,
    isPopup = false,
    ...storeProductDisplay
  } = props;

  const theme = useTheme();

  const { showDialog } = useDialog();

  const { t } = useTranslation();

  const { renderLanguageField } = useMultiLanguage();

  const { shouldDisplaySupplier, getProductOrBagDetails, handleProductDetailsPopup } = useProduct();

  const { showEditBasketDialog } = useBagOfProducts();

  const { renderRelevancy, relevancyDefaultText } = useRelevancy(
    storeProductDisplay.currentRelevancy?.name,
  );

  const { pricePerUnit, pricePerOneHundredGram } = useProductPrice();

  const lang = useSelector(getLang);

  const { isMobile } = useMobile();

  const classes = useStyles({
    isPopup,
    isMobile,
    bagOfProducts: storeProductDisplay.bagOfProducts,
    lang,
  });

  const editBagOfProducts = useCallback(() => {
    showEditBasketDialog(storeProductDisplay, () =>
      handleProductDetailsPopup(storeProductDisplay.id),
    );
  }, [handleProductDetailsPopup, showEditBasketDialog, storeProductDisplay]);

  const pricesBlockRendered = useMemo<ReactNode>(() => {
    return (
      <>
        <Box className={classes.priceMainWrapper}>
          <Box className={classes.priceWrapper}>
            {pricePerUnit(
              storeProductDisplay.price as number,
              storeProductDisplay.pricingSellingUnitName,
              classNames(classes.pricePerUnit, 'font-family-heebo'),
              classNames(classes.sellingUnit, 'font-family-heebo'),
            )}
          </Box>
          {storeProductDisplay.promotion && storeProductDisplay.promotion.shortName && (
            <Box className={classNames(classes.priceWrapper, classes.promotionPriceWrapper)}>
              <Typography
                className={classNames(classes.promotionShortName, 'font-family-heebo')}
                isTranslate={false}
              >
                {storeProductDisplay.promotion.shortName}
              </Typography>
            </Box>
          )}
          {storeProductDisplay.promoted && storeProductDisplay.originalPrice && (
            <Box className={classNames(classes.priceWrapper, classes.promotionPriceWrapper)}>
              {pricePerUnit(
                storeProductDisplay.originalPrice as number,
                storeProductDisplay.pricingSellingUnitName,
                classNames(classes.originalPriceAndSellingUnit, 'font-family-heebo'),
                classNames(classes.originalPriceAndSellingUnit, 'font-family-heebo'),
              )}
            </Box>
          )}
        </Box>
        {!storeProductDisplay.soldByWeight &&
          !storeProductDisplay.bagOfProducts &&
          storeProductDisplay.productSellingUnits[0].estimatedUnitWeight && (
            <Box className={classes.priceWrapper}>
              {pricePerOneHundredGram(
                storeProductDisplay.productSellingUnits[0].estimatedUnitWeight,
                storeProductDisplay.price,
                storeProductDisplay.product.liquid,
                classNames(classes.pricePerGram, 'font-family-heebo'),
              )}
            </Box>
          )}
      </>
    );
  }, [
    classes.originalPriceAndSellingUnit,
    classes.priceMainWrapper,
    classes.pricePerGram,
    classes.pricePerUnit,
    classes.priceWrapper,
    classes.promotionPriceWrapper,
    classes.promotionShortName,
    classes.sellingUnit,
    pricePerOneHundredGram,
    pricePerUnit,
    storeProductDisplay.bagOfProducts,
    storeProductDisplay.originalPrice,
    storeProductDisplay.price,
    storeProductDisplay.pricingSellingUnitName,
    storeProductDisplay.product.liquid,
    storeProductDisplay.productSellingUnits,
    storeProductDisplay.promoted,
    storeProductDisplay.promotion,
    storeProductDisplay.soldByWeight,
  ]);

  const productExtraDetails = useMemo(() => {
    if (
      !storeProductDisplay.bagOfProducts &&
      (storeProductDisplay.productExtraDetails ||
        shouldDisplaySupplier(storeProductDisplay) ||
        storeProductDisplay.currentRelevancy)
    ) {
      return (
        <Box>
          <Divider classes={{ root: classes.dividerMobile }} />
          <Box>
            <Typography fontWeight="bold" className={classes.aboutProductMobile} variant="h1">
              {'title.aboutProduct'}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.productExtraDetails} isTranslate={false}>
              {!!storeProductDisplay.productExtraDetails &&
                `${storeProductDisplay.productExtraDetails}. `}
              {shouldDisplaySupplier(storeProductDisplay) &&
                `${t('storeProductCard.supplier')}: ${
                  (storeProductDisplay.supplier as TSupplier).name
                }`}
            </Typography>
          </Box>
        </Box>
      );
    }
  }, [
    classes.aboutProductMobile,
    classes.dividerMobile,
    classes.productExtraDetails,
    shouldDisplaySupplier,
    storeProductDisplay,
    t,
  ]);

  const bagExtraDetailsText = useMemo(() => {
    return getProductOrBagDetails(storeProductDisplay.id, true);
  }, [getProductOrBagDetails, storeProductDisplay.id]);

  const bagItems = useMemo(() => {
    return (
      <Box>
        <Divider classes={{ root: classes.whatBagContainDivider }} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold" className={classes.whatBagContain} variant="h1">
            {'bagOfProducts.whatBagContain'}
          </Typography>
          {!!storeProductDisplay.bagOfProductsJson?.maxNumOfReplacements && (
            <IconButton onClick={editBagOfProducts}>
              <Icon name="icon-edit" color="primary" classes={{ root: classes.editBag }} />
            </IconButton>
          )}
        </Box>
        <Box>
          <Typography
            className={classNames(classes.productExtraDetails, classes.bagExtraDetails)}
            dangerouslySetInnerHTML={{ __html: bagExtraDetailsText as string }}
          />
        </Box>
      </Box>
    );
  }, [
    classes.whatBagContainDivider,
    classes.whatBagContain,
    classes.editBag,
    classes.productExtraDetails,
    classes.bagExtraDetails,
    storeProductDisplay.bagOfProductsJson?.maxNumOfReplacements,
    editBagOfProducts,
    bagExtraDetailsText,
  ]);

  const bagDetails = useMemo(() => {
    return (
      <Box>
        <Divider classes={{ root: classes.dividerMobile }} />
        <Box>
          <Typography
            fontWeight="bold"
            className={classNames(classes.whatBagContain, classes.moreBagDetails)}
            variant="h1"
          >
            {'bagOfProducts.moreDetails'}
          </Typography>
        </Box>
        <Box>
          <Typography fontSize={14} className={classes.productExtraDetails} isTranslate={false}>
            {storeProductDisplay.productExtraDetails}
          </Typography>
        </Box>
      </Box>
    );
  }, [
    classes.dividerMobile,
    classes.whatBagContain,
    classes.moreBagDetails,
    classes.productExtraDetails,
    storeProductDisplay.productExtraDetails,
  ]);

  const imageSize = useMemo(() => {
    if (isMobile) {
      return 270;
    }
    if (isPopup) {
      return 300;
    }

    return 320;
  }, [isMobile, isPopup]);

  const productSourceEvent = useMemo<TProductAdditionSource>(() => {
    const mobileOrDesktop = isMobile ? 'Mobile' : 'Desktop';

    if (isPopup) {
      return `productWindow${mobileOrDesktop}`;
    }

    return `productDetailsPage${mobileOrDesktop}`;
  }, [isMobile, isPopup]);

  return (
    <Box className={classes.productDescriptionWrapper}>
      <Box className={classes.basicCardWrapper}>
        <BasicStoreProductCard imageSize={imageSize} isPopup={isPopup} {...storeProductDisplay} />
      </Box>
      <Box className={classes.detailsBlock}>
        {/* name */}
        <Box className={classes.productNameWrapper}>
          <Typography className={classes.productName} variant="h1" isTranslate={false}>
            {storeProductDisplay.displayName}
          </Typography>
        </Box>
        {/* prices */}
        <Box>{pricesBlockRendered}</Box>
        <CardActions classes={{ root: classes.cardActions }}>
          <Box className={classes.cardActionsRootContent}>
            {/* selling units */}
            <Box className={classes.unitButtons}>
              <SellingUnitsSelectionButtons
                size="large"
                height="40px"
                activeSellingUnit={storeProductDisplay.activeSellingUnit}
                productSellingUnits={storeProductDisplay.productSellingUnits}
                onClick={(selectedSellingUnitId) =>
                  onChooseUnit && onChooseUnit(storeProductDisplay.id, selectedSellingUnitId)
                }
              />
            </Box>
            {/* add product button */}
            <Box className={classes.addToCartButton}>
              <ActionsOnCartButton
                size="large"
                onChangeStoreProductQuantity={(action) =>
                  onChangeProductCount && onChangeProductCount(storeProductDisplay.id, action)
                }
                count={
                  storeProductDisplay.cartData?.orderItem.isRemoved
                    ? 0
                    : storeProductDisplay.cartData?.orderItem.requestedQuantity
                }
                onAddStoreProduct={() =>
                  onAddProduct && onAddProduct(storeProductDisplay.id, productSourceEvent)
                }
              />
            </Box>
          </Box>
        </CardActions>
        {storeProductDisplay.bagOfProducts && (
          <Box className={classes.bagItemsDesktop}>{bagItems}</Box>
        )}
        <Box className={classes.descriptionBlock}>
          {storeProductDisplay.bagOfProducts && (
            <>
              <Box className={classes.bagMobile}>{bagItems}</Box>
              {storeProductDisplay.bagOfProducts && storeProductDisplay.productExtraDetails && (
                <Box className={classes.bagMobile}>{bagDetails}</Box>
              )}
            </>
          )}
          {storeProductDisplay.promotion && storeProductDisplay.promotion.externalNotes && (
            <Box className={classes.discountWrapper}>
              <Box className={classes.discountBody}>
                <Icon name="icon-discount" classes={{ root: classes.discountIcon }} />
                <Typography className={classes.externalNotes} isTranslate={false}>
                  {storeProductDisplay.promotion.externalNotes}
                </Typography>
              </Box>
              {storeProductDisplay.promotion.promotionType.name ===
                'forNonWeighableAboveQuantity' && (
                <Button
                  classes={{ root: classes.viewIncentivesButton }}
                  onClick={() => {
                    showDialog({
                      dialogType: ITEMS_PROMOTION_DIALOG,
                      contentProps: {
                        promotion: storeProductDisplay.promotion,
                      },
                    });
                  }}
                  disableTouchRipple
                >
                  <Typography
                    fontWeight="medium"
                    color="primary"
                    style={{ fontSize: '14px', lineHeight: '16px' }}
                  >
                    {'incentivePromotion.seeParticipationProducts'}
                  </Typography>
                  <Icon
                    name={theme.direction === 'rtl' ? 'icon-arrow-left' : 'icon-arrow-right'}
                    classes={{ root: classes.arrowIcon }}
                    color="primary"
                  />
                </Button>
              )}
              <Box marginTop="12px">
                <PromotionLimitationsDescription
                  promotion={storeProductDisplay.promotion}
                  customClasses={{
                    limitationMainPart: classes.limitationMainPart,
                    limitationTypography: classNames(
                      classes.limitationTypography,
                      'font-family-rubik',
                    ),
                  }}
                />
              </Box>
            </Box>
          )}
          {productExtraDetails}
          {(isMobile || !storeProductDisplay.bagOfProducts) &&
            storeProductDisplay.currentRelevancy && (
              <Box className={classes.ratingWrapper}>
                {renderRelevancy}
                <Typography className={classes.descriptionText} variant="body1" isTranslate={false}>
                  {storeProductDisplay.currentRelevancyFreeText || relevancyDefaultText}
                </Typography>
              </Box>
            )}
        </Box>
        {/* country of origin */}
        {storeProductDisplay.country && (
          <Box display="flex" marginTop={12 / 8} alignItems="center">
            <Box className={classes.countryIcon}>
              <CircleFlag
                countryCode={storeProductDisplay.country.shortIdentifier}
                height={isMobile ? '16px' : '18px'}
              />
            </Box>
            <Typography className={classes.descriptionText} variant="body1">
              {t('storeProductCard.countryOfOrigin', {
                countryName: renderLanguageField(storeProductDisplay.country.multiLang, 'name'),
              })}
            </Typography>
          </Box>
        )}
        {/* commentTypeText */}
        {storeProductDisplay.commentType && (
          <Box display="flex" alignItems="center" marginTop={12 / 8}>
            <Box>
              <Icon name="icon-knife" classes={{ root: classes.iconKnife }} />
            </Box>
            <Typography fontSize={14} className={classes.commentTypeText} fontWeight="medium">
              {'comments.preparationInstructionsAtTheEndOfThePurchase'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductDescription;

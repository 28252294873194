import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesSignUpDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSignUpDialog> =>
    createStyles({
      root: {
        wordBreak: 'break-word',
        height: 'auto',
        maxHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(40 / 8, 1.75, 20 / 8), // 54px 14px 40
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(5, 5, 2), // 40px
        },
      },
      logo: {
        width: theme.spacing(100 / 8),
        height: 'auto',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(130 / 8),
        },
      },
      title: {
        marginTop: theme.spacing(20 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(24 / 8),
        },
      },
      titleMain: {
        marginBottom: theme.spacing(0.5), // 4px

        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(1.25), // 10px
        },
      },
      content: {
        flexGrow: 1,
        textAlign: 'center',
      },
      closeButton: {
        margin: theme.spacing(10 / 8, 0, 40 / 8),
      },
    }),
);

import { alpha, createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesClientCouponNotice } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesClientCouponNotice, { lang: string }> =>
    createStyles({
      noticeTitle: ({ lang }) => ({
        fontSize: lang === 'he' ? '16px' : '14px',
        letterSpacing: lang === 'he' ? 'initial' : '-0.5px',
        color: theme.palette.secondary.main,
        fontWeight: 500,
        lineHeight: '20px',
      }),
      cardWrapper: {
        position: 'relative',
        backgroundColor: theme.palette.grey.A100,
        borderRadius: '8px',
        height: '86px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: '220px',
          height: '84px',
        },
      },
      cardsWrapper: {
        maxWidth: '270px',
      },
      cardFooter: {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bottom: 0,
        backgroundColor: alpha('#000', 0.03),
        width: '100%',
        height: '24px',
        [theme.breakpoints.up('md')]: {
          width: '220px',
          height: '22px',
        },
      },
      cardBody: {
        padding: theme.spacing(14 / 8),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(12 / 8, 14 / 8),
        },
      },
      clientCouponExpirationDate: {
        paddingRight: theme.spacing(1),
        fontSize: '11px',
        lineHeight: '14px',
        [theme.breakpoints.up('md')]: {
          fontSize: '10px',
        },
      },
      clientCouponFooterText: {
        paddingLeft: theme.spacing(1),
        fontSize: '11px',
        lineHeight: '14px',
        fontWeight: 400,
        letterSpacing: '0px',
        [theme.breakpoints.up('md')]: {
          fontSize: '10px',
        },
      },
      clientCouponIcon: {
        fontSize: '34px',
      },
      clientCouponText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '152px',
        color: '#000',
        marginTop: theme.spacing(2 / 8),
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 400,
      },
      swiper: {
        position: 'relative',
        alignSelf: 'center',
        overflow: 'hidden',
        height: '120px',
        [theme.breakpoints.up('md')]: {
          height: 'auto',
        },
      },
      pagination: {
        width: '7px',
        height: '7px',
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '50%',
        marginLeft: theme.spacing(6 / 8),
      },
      clickablePagination: {
        bottom: '12px !important',
        display: 'flex',
        justifyContent: 'center',
      },
      activeBulletPagination: {
        width: '7px',
        height: '7px',
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
      },
      sliderWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(12 / 8),
      },
      iconArrow: {
        fontSize: '12px',
        display: 'block',
      },
      navigationButtonPrev: {
        transform: `rotate(${theme.direction === 'ltr' ? '180deg' : 0})`,
        padding: '4px',
      },
      navigationButtonNext: {
        transform: `rotate(${theme.direction === 'ltr' ? '180deg' : 0})`,
        padding: '4px',
      },
    }),
);

import { BaseApi } from 'api/Api';
import {
  TGetAddressStatus,
  TGetClientCompensations,
  TGetClientDetailsOnStoreLevel,
  TPostClientDetailsOnStoreLevel,
} from './types';

export default class ClientService {
  static getClientDetailsOnStoreLevel: TGetClientDetailsOnStoreLevel = async () => {
    return BaseApi.instance().get(`/client/client/details/at-store`);
  };

  static postClientDetailsOnStoreLevel: TPostClientDetailsOnStoreLevel = async (params) => {
    return BaseApi.instance().post(`/client/client/details/at-store`, params);
  };

  static getAddressStatus: TGetAddressStatus = async (params) => {
    return BaseApi.instance().post(`/client/client/personal-details/address/process`, params);
  };

  static getClientCompensations: TGetClientCompensations = async () => {
    return BaseApi.instance().get(`/client/client/discounts/compensations`);
  };
}

import { Box } from '@material-ui/core';
import { IMobileHomePageSlider } from 'components/common/HomePageContent/components/mobile/MobileHomePageSlider/types';
import React, { FC } from 'react';
import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';
import useStyles from './styles';

const MobileHomePageSlider: FC<IMobileHomePageSlider> = ({
  slides,
  title,
  linkLabel,
  linkHref,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.title} variant="body1" isTranslate={false}>
        {title}
      </Typography>
      <Box className={classes.mobileImagesBannerSlider}>
        {slides.map((slide, index) => (
          <Box
            pl={index === 0 ? 14 / 8 : 0}
            pr={index === slides.length - 1 ? 14 / 8 : 12 / 8}
            key={index}
          >
            {slide}
          </Box>
        ))}
      </Box>
      {linkHref && linkLabel && (
        <Box className={classes.buttonWrapper}>
          <Link href={linkHref} className={classes.loadMoreButton}>
            <Typography className={classes.loadMoreButtonLabel}>{linkLabel}</Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default MobileHomePageSlider;

import { TPrepareToPlaceOrderBody } from 'api';

import { TAvailableArea } from 'hooks/useAvailableServiceAreas';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { TUseAvailableHours } from './types';

const useAvailableHours: TUseAvailableHours = () => {
  const orderDetails = useSelector(getOrderDetails);

  const initialTimeFrame = useMemo<TPrepareToPlaceOrderBody['timeFrame'] | undefined>(() => {
    if (!orderDetails.orderTime) {
      return undefined;
    }

    return {
      dayOfWeek: {
        id: orderDetails.preferredDay as number,
        name: '',
        resolvedName: '',
      },
      date: orderDetails.orderDate as string,
      openHour: orderDetails.preferredHour as number,
    };
  }, [
    orderDetails.orderDate,
    orderDetails.orderTime,
    orderDetails.preferredDay,
    orderDetails.preferredHour,
  ]);

  const getLimitAvailableHours = useCallback(
    (availableHours: TAvailableArea[], limitValue): number | undefined => {
      if (availableHours && availableHours.length > limitValue) {
        let endIndex;
        if (orderDetails.orderMode === 'new') {
          endIndex = availableHours.findIndex(
            (timeframe) => timeframe.date === orderDetails.orderDate,
          );
        } else {
          endIndex = availableHours.findIndex(
            (timeframe) => timeframe.dayOfWeek.id === initialTimeFrame?.dayOfWeek.id,
          );
        }
        if (endIndex >= limitValue) return endIndex + 1;
      }
    },
    [initialTimeFrame?.dayOfWeek.id, orderDetails.orderDate, orderDetails.orderMode],
  );

  return {
    initialTimeFrame,
    getLimitAvailableHours,
  };
};

export default useAvailableHours;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesMapInput } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMapInput> =>
    createStyles({
      labelFocused: {},
      labelError: {},
      asterisk: {},
      labelRoot: {
        color: theme.palette.grey[600],
        fontSize: theme.spacing(1.625), // 13px
        marginBottom: theme.spacing(0.875), // 7px

        '&$labelFocused': {
          color: theme.palette.grey[600],
        },

        '&$labelError': {
          color: theme.palette.grey[600],
        },

        '& $labelError$asterisk': {
          color: theme.palette.grey[600],
        },
      },
      helperText: {
        fontSize: '13px',
        marginTop: theme.spacing(2 / 8),
        lineHeight: 1.2,
      },
      closeMapButtonRoot: {
        flip: false,
        padding: 0,
        borderRadius: '50%',
        backgroundColor: theme.palette.background.paper,
        width: theme.spacing(30 / 8),
        height: theme.spacing(30 / 8),
        '&:hover': {
          backgroundColor: `${theme.palette.background.paper} !important`,
        },
        position: 'absolute',
        zIndex: 1,
        top: 10,
        right: 10,
      },
      closeMapIcon: {
        fontSize: '12px',
      },
    }),
);

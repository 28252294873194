import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { TStylesSignUpDialog } from './types';

export const dialogDownBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSignUpDialog> =>
    createStyles({
      root: {
        height: 'auto',
        padding: theme.spacing(30 / 8, 14 / 8, 40 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(40 / 8, 70 / 8, 0),
          maxHeight: '86vh',
        },
      },
      logo: {
        width: theme.spacing(130 / 8),
        height: 'auto',
        margin: '0 auto',
      },
      title: {
        marginTop: theme.spacing(20 / 8),
      },
      titleMain: {
        marginBottom: theme.spacing(1), // 10px
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(10 / 8), // 4px
        },
      },
      subTitle: {
        letterSpacing: -0.2,
      },
      formRoot: {
        marginTop: theme.spacing(3), // 24px
      },
    }),
);

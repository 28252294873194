export type TLanguages = {
  shortName: string;
  title: string;
  imageUrl: string;
}[];

export const Languages: TLanguages = [
  {
    shortName: 'he',
    title: 'עברית',
    imageUrl: '/assets/images/language-he.svg',
  },
  {
    shortName: 'en',
    title: 'English',
    imageUrl: '/assets/images/language-en.svg',
  },
  {
    shortName: 'ru',
    title: 'Русский',
    imageUrl: '/assets/images/language-ru.svg',
  },
];

import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';

import { Box } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';

import {
  TOnViewPreviousOrder,
  useDialog,
  useMobile,
  usePreviousOrderItems,
  usePreviousOrders,
} from 'hooks';
import PreviousOrdersList from 'ui/common/PreviousOrdersList';
import { LOAD_PREVIOUS_ORDERS_DIALOG, PREVIOUS_ORDER_ITEMS_DIALOG } from 'store';
import GreenLayout from 'ui/common/layout/GreenLayout';
import Container from 'ui/common/Container';
import useStyles from './styles';

const PreviousOrdersListContent: FC = () => {
  const classes = useStyles();
  const { isMobile } = useMobile();

  const { hideDialog, showDialog } = useDialog();
  const { loading, getPreviousOrders, previousOrders, previousOrdersById } = usePreviousOrders();

  const { selectedArray } = usePreviousOrderItems();

  useEffect(() => {
    getPreviousOrders();
  }, [getPreviousOrders]);

  const handleViewPreviousOrder = useCallback<TOnViewPreviousOrder>(
    (order) => () => {
      showDialog({
        dialogType: PREVIOUS_ORDER_ITEMS_DIALOG,
        dialogProps: {
          hideCloseButton: isMobile,
          backdropClick: isMobile,
        },
        contentProps: {
          order,
          onBackClick: () => {
            showDialog({
              dialogType: LOAD_PREVIOUS_ORDERS_DIALOG,
            });
          },
        },
      });
    },
    [isMobile, showDialog],
  );

  const renderHeader = useMemo<ReactNode>(() => {
    return (
      <Box component="section" className={classes.header}>
        <Box display="flex" alignItems="center">
          <Icon classes={{ root: classes.refreshIcon }} name="icon-refresh" color="secondary" />

          <Typography variant="h3" color="secondary" className={classes.loadPreviousOrderTitle}>
            {'button.previousOrder'}
          </Typography>
        </Box>
      </Box>
    );
  }, [classes]);

  return (
    <GreenLayout>
      <Container>
        <Box minHeight="100vh" pb={selectedArray.length !== 0 ? 9.25 : 0} overflow="hidden">
          {renderHeader}
          <Box component="section" className={classes.content}>
            <PreviousOrdersList
              loading={loading}
              previousOrders={previousOrders}
              previousOrdersById={previousOrdersById}
              onViewPreviousOrder={handleViewPreviousOrder}
              onEditActiveOrderStart={hideDialog}
              onViewActiveOrder={hideDialog}
            />
          </Box>
        </Box>
      </Container>
    </GreenLayout>
  );
};

export default PreviousOrdersListContent;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TProductCardViewMode } from 'ui/mobile/buttons/ProductCardViewMode/types';
import { TStylesStoreProductCard } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesStoreProductCard,
    {
      cardWidth: number;
      displayProductQuality: boolean;
      showHoverSide: boolean;
      viewMode?: TProductCardViewMode;
      isMobile: boolean;
    }
  > =>
    createStyles({
      productMainWrapper: ({ isMobile }) => ({
        height: isMobile ? '308px' : '300px',
        borderRadius: '8px',
        textDecoration: 'none',
      }),
      linkWrapper: {
        textDecoration: 'none',
      },
      productCardWrapper: ({ isMobile, cardWidth }) => ({
        height: isMobile ? '308px' : '300px',
        position: 'relative',
        width: `${cardWidth}px`,
        borderRadius: '8px',
        backgroundColor: theme.palette.background.paper,
      }),
      mouseOverWrapper: ({ isMobile, showHoverSide }) => ({
        padding: 0,
        borderRadius: isMobile ? 'initial' : '8px',
        transition: isMobile ? 'initial' : 'box-shadow .3s',
        boxShadow: showHoverSide && !isMobile ? '0 5px 15px 0 rgb(0 0 0 / 10%)' : 'none',
      }),
      buttonOpenProductWindow: {
        zIndex: 1,
        padding: 0,
        display: 'block',
        margin: '0 auto',
        width: '100%',
        [theme.breakpoints.up('md')]: {
          height: '200px',
          width: 'auto',
        },
      },
      mainPartWrapper: {
        display: 'block',
        textAlign: 'center',
      },
      hoverEffect: ({ showHoverSide }) => ({
        opacity: showHoverSide ? 0.1 : 1,
      }),
      productImage: {
        borderRadius: '6px',
      },
      hoverWrapper: ({ isMobile }) => ({
        position: 'absolute',
        top: 0,
        width: '100%',
        padding: theme.spacing(44 / 8, isMobile ? 14 / 8 : 18 / 8, isMobile ? 0 : 22 / 8),
      }),
      qualityTagWrapper: ({ isMobile }) => ({
        zIndex: 2,
        position: 'absolute',
        left: isMobile ? '6px' : '12px',
        top: isMobile ? '6px' : '12px',
      }),
      promotionTagWrapper: ({ isMobile }) => ({
        position: 'absolute',
        top: '-6px',
        right: isMobile ? 6 : 12,
        zIndex: 2,
        minWidth: 'auto',
        padding: '0',
      }),
      knifeIcon: ({ isMobile, showHoverSide }) => ({
        display: showHoverSide && !isMobile ? 'none' : 'flex',
        width: '18px',
        height: '18px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[50],
        marginTop: theme.spacing(6 / 8),
        fontSize: isMobile ? '8px' : '10px',
      }),
      basketIcon: ({ isMobile, showHoverSide }) => ({
        width: '18px !important',
        height: '18px !important',
        fontSize: isMobile ? '12px !important' : '10px !important',
        display: showHoverSide && !isMobile ? 'none' : 'flex',
      }),
      countryIcon: ({ isMobile }) => ({
        width: isMobile ? '16px' : '18px !important',
        height: isMobile ? '16px' : '18px !important',
        // this is trick to remove blur of svg on IOS
        borderRadius: '50%',
        overflow: 'hidden',
      }),
      tooltip: {
        padding: '0 !important',
        width: '200px !important',
        backgroundColor: `${theme.palette.secondary.main} !important`,
        borderRadius: '4px',
        boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.up('md')]: {
          width: '208px !important',
        },
      },
      tooltipSingle: {
        maxWidth: '300px !important',
      },
      popper: {
        pointerEvents: 'auto !important' as 'auto',
        top: '18px !important',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
          top: '6px !important',
        },
      },
      touch: {
        padding: '0px !important',
      },
      arrow: ({ isMobile }) => ({
        position: 'absolute',
        marginTop: '3px !important',
        color: `${theme.palette.secondary.main}!important `,
        '&::before': {
          height: isMobile ? '70%!important' : '100%!important',
        },
      }),
      imageWrapper: ({ viewMode, isMobile }) => ({
        width: isMobile ? (viewMode === 'single' && '200px') || '132px' : '184px',
        height: isMobile ? (viewMode === 'single' && '115px') || '76px' : '107px',
        position: 'relative',
        borderRadius: '6px',
        marginTop: theme.spacing(isMobile ? 14 / 8 : 22 / 8),
        marginBottom: isMobile ? theme.spacing(6 / 8) : theme.spacing(12 / 8),
        margin: '0 auto',
      }),
      imageWrapperHover: {
        marginTop: `${theme.spacing(-37 / 8)}px !important`,
      },
      productName: ({ viewMode, isMobile }) => ({
        whiteSpace: 'pre-line',
        fontSize: viewMode === 'single' || !isMobile ? '16px' : '14px',
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(isMobile ? 5 / 8 : 6 / 8),
        lineHeight: viewMode === 'single' || !isMobile ? '18px' : '16px',
        display: '-webkit-box',
        '-webkitLineClamp': viewMode === 'multiple' || !isMobile ? 3 : 2,
        '-webkitBoxOrient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: isMobile ? (viewMode === 'single' && '36px') || '48px' : '54px',
        padding: theme.spacing(0, 14 / 8),
      }),
      price: ({ viewMode, isMobile }) => ({
        fontSize: viewMode === 'single' || !isMobile ? '18px' : '16px',
        lineHeight: viewMode === 'single' ? '18px' : '16px',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
      }),
      sellingUnit: {
        fontSize: '14px',
        lineHeight: '16px',
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
      priceWrapper: ({ viewMode }) => ({
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        marginBottom: theme.spacing(viewMode === 'multiple' ? 3 / 8 : 4 / 8),
      }),
      productPrice: ({ isMobile, viewMode }) => ({
        paddingBottom: isMobile ? 0 : theme.spacing(18 / 8),
        height: isMobile ? (viewMode === 'single' && '51px') || '47px' : '70px',
      }),
      originalPriceWrapper: ({ viewMode }) => ({
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(viewMode === 'multiple' ? 2 / 8 : 4 / 8),
      }),
      originalPriceAndSellingUnit: ({ isMobile, viewMode }) => ({
        fontSize: isMobile ? (viewMode === 'single' && '13px') || '12px' : '13px',
        lineHeight: isMobile ? '14px' : '15px',
        color: '#7A7A7A',
        textDecoration: 'line-through',
      }),
      promotionShortName: ({ isMobile, viewMode }) => ({
        fontSize: isMobile ? (viewMode === 'single' && '13px') || '12px' : '13px',
        fontWeight: 500,
        lineHeight: isMobile ? '14px' : '15px',
        color: theme.palette.error.main,
        letterSpacing: 'normal',
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 1,
        overflow: 'hidden !important',
      }),
      promotionDescriptionIcon: ({ isMobile, viewMode }) => ({
        color: theme.palette.error.main,
        fontSize: isMobile ? (viewMode === 'single' && '14px') || '12px' : '13px',
        marginLeft: theme.spacing(2 / 8),
      }),
      productQuantity: ({ showHoverSide }) => ({
        zIndex: 2,
        position: 'absolute',
        bottom: '-6px',
        right: '12px',
        display: showHoverSide ? 'none' : 'block',
      }),
      infoIcon: ({ isMobile }) => ({
        marginTop: theme.spacing(isMobile ? 6 / 8 : 7 / 8),
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        padding: 0,
        fontSize: '18px',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }),
      bottomMobilePart: ({ isMobile }) => ({
        paddingBottom: theme.spacing(isMobile ? 15 / 8 : 0),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: isMobile ? 'absolute' : 'relative',
        width: '100%',
        bottom: 0,
      }),
      sellingUnitWrapper: ({ viewMode }) => ({
        margin: viewMode === 'single' ? 0 : '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: viewMode === 'single' ? 'end' : 'initial',
        marginBottom: viewMode === 'single' ? 0 : theme.spacing(6 / 8),
        paddingRight: viewMode === 'single' ? theme.spacing(6 / 8) : 0,
      }),
      actionButtons: ({ viewMode, isMobile }) => ({
        display: viewMode === 'single' ? 'flex' : 'block',
        marginTop: isMobile ? (viewMode === 'single' && '10px') || '8px' : '11px',
        justifyContent: viewMode === 'single' ? 'center' : 'initial',
      }),
      detailsWrapper: ({ viewMode, isMobile }) => ({
        height: isMobile ? (viewMode === 'single' && '67px') || '78px' : '89px',
      }),
      closeIconWrap: {
        position: 'absolute',
        top: 0,
        left: 0,
        fontSize: '10px',
        cursor: 'pointer',
        padding: theme.spacing(10 / 8),
        zIndex: 2,
      },
      promotionDescription: {
        padding: '4px 8px',
        display: 'flex',
        borderRadius: '2px',
        alignItems: 'baseline',
      },
      promotionWrapper: ({ viewMode }) => ({
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
        marginBottom: theme.spacing(viewMode === 'multiple' ? 2 / 8 : 4 / 8),
      }),
      pricePerGram: ({ viewMode }) => ({
        fontSize: viewMode === 'single' ? '11px' : '10px',
        lineHeight: '13px',
        color: '#7A7A7A',
        fontWeight: 'normal',
      }),
      tooltipTitle: {
        padding: theme.spacing(1),
        textAlign: 'left',
      },
      arrowIcon: {
        fontSize: '9px',
        marginLeft: '2px',
      },
    }),
);

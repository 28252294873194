import { createContext } from 'react';

import { IMainLayoutContext } from './types';

export const MainLayoutContext = createContext<IMainLayoutContext>({
  isOpenedTopCart: false,
  categoriesRef: { current: null },
  setIsOpenedTopCart: () => {},
  userTopCartChoice: undefined,
  setUserTopCartChoice: () => {},
  searchContext: {
    searchQuery: undefined,
    handleChange: () => {},
    handleClear: () => {},
  },
});

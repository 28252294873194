import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useMobile } from 'hooks';
import { ISkeletonMainLayoutBackgroundImage } from './types';
import useStyles from './styles';

const SkeletonMainLayoutBackgroundImage: FC<ISkeletonMainLayoutBackgroundImage> = () => {
  const classes = useStyles();
  const { isMobile } = useMobile();

  return (
    <Box height={300}>
      <Skeleton
        animation="wave"
        variant="rect"
        classes={{ root: classes.backgroundImageSkeleton }}
      />
      <Box zIndex={4} position="absolute" className={classes.skeletonsWrapper}>
        <Box className={classes.skeletons}>
          <Skeleton variant="circle" classes={{ root: classes.logoSkeleton }} />
          <Box ml={isMobile ? 0 : 24 / 8} mt={isMobile ? 19 / 8 : 40 / 8}>
            <Skeleton variant="rect" classes={{ root: classes.nameSkeleton }} />
            <Skeleton variant="rect" classes={{ root: classes.websiteDetailsSkeleton }} />
            {!isMobile && (
              <>
                <Skeleton variant="rect" classes={{ root: classes.websiteDetailsSkeleton }} />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonMainLayoutBackgroundImage;

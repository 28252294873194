import { Persistor } from 'redux-persist/es/types';

let persistor: Persistor;

export const setPersistor = (newPersistor: Persistor): void => {
  persistor = newPersistor;
};

export const getPersistor = (): Persistor => {
  return persistor;
};

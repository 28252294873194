import React, { FC, useCallback } from 'react';

import { Box, Button } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';

import { useSelector } from 'react-redux';
import { authSelectors } from 'store/modules/auth';
import { LOCATION_DIALOG } from 'store/modules/dialog';
import { useDialog } from 'hooks/useDialog';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { useSelfPickupAndDeliveryService } from 'hooks';
import StoreService from 'api/services/StoreService/api';
import useStyles from './styles';

import { IDeliverySection } from './types';

const DeliverySection: FC<IDeliverySection> = (props) => {
  const { iconName = 'icon-delivery-rounded', ...rest } = props;
  const { deliveryAddress, deliveryTime, onClick, onDeliveryTimeClick, enableRootClick } = rest;

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const onClientBehalf = useSelector(authSelectors.onClientBehalf);

  const orderDetails = useSelector(getOrderDetails);

  const { prepareSelfPickupAreasAndShowDialog } = useSelfPickupAndDeliveryService();

  const classes = useStyles({ iconName, isLoggedIn, onClientBehalf, ...rest });

  const { showDialog } = useDialog();

  const handleDeliveryAddress = useCallback(() => {
    if (!isLoggedIn) {
      if (orderDetails.orderType === 'selfPickup') {
        StoreService.getStoreServiceAreas().then((actionResult) => {
          if (actionResult.data.selfPickupAreas) {
            prepareSelfPickupAreasAndShowDialog(actionResult.data.selfPickupAreas);
          }
        });
        return;
      }
      showDialog({
        dialogType: LOCATION_DIALOG,
      });
    }
  }, [prepareSelfPickupAreasAndShowDialog, isLoggedIn, orderDetails.orderType, showDialog]);

  return (
    <Button
      className={classes.deliveryWrapper}
      onClick={(e) => {
        if (!enableRootClick && onClick) {
          onClick(e);
        }
      }}
      TouchRippleProps={{ classes: { child: classes.buttonRipple } }}
    >
      <Icon name={iconName} color="action" classes={{ root: classes.deliveryIcon }} />
      <Box display="flex" flexDirection="column">
        <Button
          onClick={handleDeliveryAddress}
          disableTouchRipple
          className={classes.deliveryAddress}
        >
          <Typography fontSize={14} fontWeight="medium" color="light" className={classes.address}>
            {deliveryAddress}
          </Typography>
        </Button>
        <Button
          className={classes.deliveryAddress}
          onClick={(e) => {
            if (enableRootClick && onDeliveryTimeClick) {
              onDeliveryTimeClick(e);
            }
          }}
          disableTouchRipple
        >
          <Typography
            fontSize={14}
            color="light"
            className={classes.time}
            isTranslate={false}
            fontWeight={orderDetails?.orderTime ? 'regular' : 'medium'}
          >
            {deliveryTime}
          </Typography>
        </Button>
      </Box>
    </Button>
  );
};

export default DeliverySection;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSkeletonContentPages } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSkeletonContentPages> =>
    createStyles({
      skeletonContentPageWrapper: {
        [theme.breakpoints.up('md')]: {
          padding: '0px 80px',
        },
      },
      headersSkeleton: {
        height: theme.spacing(35 / 8),
        margin: theme.spacing(0, 0, 0, 20 / 8),
        [theme.breakpoints.up('md')]: {
          height: theme.spacing(32 / 8),
          margin: theme.spacing(-5 / 8, 0, 10 / 8, 0),
        },
      },
      textSkeleton: {
        height: theme.spacing(20 / 8),
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          height: theme.spacing(28 / 8),
          margin: theme.spacing(-5 / 8, 0, 10 / 8, 0),
          width: '95%',
        },
      },
      headersSkeletonWrapper: {
        marginBottom: theme.spacing(50 / 8),
        marginTop: theme.spacing(60 / 8),
      },
      textSkeletonWrapper: {
        marginBottom: theme.spacing(40 / 8),
        marginTop: theme.spacing(40 / 8),
      },
    }),
);

import { Box } from '@material-ui/core';
import { useMobile, useWebsiteDetails } from 'hooks';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store';
import CTAButton from 'ui/common/buttons/CTAButton';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import useStyles from './styles';

import { ISelfPickupOption } from './types';

const SelfPickupAreasForStoreWithManyPoint: FC<ISelfPickupOption> = ({
  serviceArea,
  isAreaSelected,
  distanceLabel,
  onClick,
  onChoseArea,
}) => {
  const classes = useStyles({ isAreaSelected });
  const websiteDetails = useWebsiteDetails();
  const profile = useSelector(userSelectors.getProfile);

  const { isMobile } = useMobile();

  return (
    <>
      <Box className={classes.pickupOptionBtn}>
        <Box
          display="flex"
          alignItems={
            serviceArea?.distanceInKmToSelectedAddress !== undefined ||
            (!websiteDetails.websiteSettings.onlySelfPickups &&
              serviceArea?.clientDistanceInKM !== undefined &&
              profile.fullAddress)
              ? 'initial'
              : 'center'
          }
          onClick={onClick}
        >
          <Icon
            name={isMobile ? 'icon-store' : 'icon-store-rounded'}
            classes={{ root: classes.storeIcon }}
          />
          <Box ml={isMobile ? 10 / 8 : 12 / 8}>
            <Box>
              <Typography
                variant="body1"
                color="textSecondary"
                fontSize={16}
                className={classes.serviceAreaName}
              >
                {serviceArea.name}
              </Typography>
            </Box>
            {(serviceArea?.distanceInKmToSelectedAddress !== undefined ||
              (!websiteDetails.websiteSettings.onlySelfPickups &&
                serviceArea?.clientDistanceInKM !== undefined &&
                profile.fullAddress)) && (
              <Box display="flex" mt={6 / 8}>
                <Typography
                  variant="body1"
                  color="mediumGrey"
                  fontSize={13}
                  className={classes.distance}
                >
                  {serviceArea.distanceInKmToSelectedAddress?.toFixed(1) ||
                    serviceArea.clientDistanceInKM?.toFixed(1)}
                  &nbsp;
                </Typography>
                <Typography
                  variant="body1"
                  color="mediumGrey"
                  fontSize={13}
                  className={classes.distance}
                >
                  {distanceLabel}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <>
          {!isMobile && (
            <CTAButton
              size="medium"
              className={classes.choosePickupOption}
              variant="outlined"
              onClick={() => onChoseArea(serviceArea)}
            >
              <Typography fontSize={16} color="textSecondary">
                {'dialog.delivery.choose'}
              </Typography>
            </CTAButton>
          )}
        </>
      </Box>
    </>
  );
};

export default SelfPickupAreasForStoreWithManyPoint;

import useHomePageContent from 'hooks/useHomePageContent';

import { find, parseInt, snakeCase, sortBy } from 'lodash';

import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { categorySelectors, TCategoryReducerState } from 'store';

import { TCategoryMenuItem } from 'ui/desktop/CategoryMenu';

import { TUseCategoryMenu } from './types';

const useCategoryMenu: TUseCategoryMenu = () => {
  const { t } = useTranslation();
  const { homePageId } = useHomePageContent();
  const router = useRouter();

  const { categoryById, categoryIds } = useSelector(categorySelectors.categoriesData);

  const firstMenuItem = useMemo<TCategoryMenuItem>(() => {
    return {
      id: -1,
      sortOrder: 0,
      text: t('categoryMenu.allProducts'),
      link: {
        href: homePageId ? '/all-products' : '/',
      },
    };
  }, [homePageId, t]);

  const categoriesByIdsIncludingParents = useMemo(() => {
    const newMap: TCategoryReducerState['categoryById'] = { ...categoryById };

    Object.keys(categoryById).forEach((key) => {
      const category = categoryById[key];

      if (category.parent) {
        const extractedParent = category.parent;
        if (!newMap[extractedParent.id]) {
          newMap[extractedParent.id] = extractedParent;
        }
      }
    });
    return newMap;
  }, [categoryById]);

  const menuItems = useMemo<TCategoryMenuItem[]>(() => {
    const itemsMap = new Map<TCategoryMenuItem['id'], TCategoryMenuItem>();

    categoryIds.forEach((categoryId) => {
      const category = categoryById[categoryId];

      const menuItem: TCategoryMenuItem = {
        id: category.id,
        text: category.name,
        link: { href: `/category/${category.id}/${snakeCase(category.name)}` },
        sortOrder: category.sortOrder,
      };

      if (!category.parent) {
        itemsMap.set(menuItem.id, menuItem);
      } else {
        let parentItem = itemsMap.get(category.parent.id);
        if (!parentItem) {
          parentItem = {
            id: category.parent.id,
            text: category.parent.name,
            link: { href: `/category/${category.parent.id}/${snakeCase(category.parent.name)}` },
            subMenuItems: [],
            sortOrder: category.parent.sortOrder,
          };
          itemsMap.set(category.parent.id, parentItem);
        }

        menuItem.parent = parentItem;
        parentItem.subMenuItems?.push(menuItem);
      }
    });

    const items = Array.from(itemsMap.values());

    // put item with all products in the beginning
    items.push(firstMenuItem);

    items.sort((a, b) => a.sortOrder - b.sortOrder);

    const categoriesWithSortedSubmenuItems = items.map((category) => {
      if (category.subMenuItems) {
        category.subMenuItems.sort((a, b) => a.sortOrder - b.sortOrder);
        return category;
      }
      return category;
    });

    return sortBy(categoriesWithSortedSubmenuItems, 'sortOrder');
  }, [categoryById, categoryIds, firstMenuItem]);

  const menuItemsFlattened = useMemo<TCategoryMenuItem[]>(() => {
    return menuItems.reduce<TCategoryMenuItem[]>(
      (itemsFlattened, currentMenuItem) =>
        itemsFlattened.concat([currentMenuItem]).concat(currentMenuItem.subMenuItems || []),
      [],
    );
  }, [menuItems]);

  const activeMenuItem = useMemo<TCategoryMenuItem | undefined>(() => {
    if (
      (router.route === '/' && !homePageId) ||
      router.route === '/search/[query]' ||
      router.route === '/all-products'
    ) {
      return firstMenuItem;
    }
    const activeProductCategoryId =
      typeof router.query.id === 'string' ? parseInt(router.query.id) : '';

    return find(menuItemsFlattened, ['id', activeProductCategoryId]);
  }, [router.route, router.query.id, homePageId, menuItemsFlattened, firstMenuItem]);

  const areSiblingCategories = useCallback(
    (firstCategoryID, secondCategoryID): boolean => {
      if (!firstCategoryID || !secondCategoryID) return false;

      const firstCategoryParent = categoryById[firstCategoryID]?.parent;
      const secondCategoryParent = categoryById[secondCategoryID]?.parent;

      if (!firstCategoryParent || !secondCategoryParent) return false;

      return firstCategoryParent.id === secondCategoryParent.id;
    },
    [categoryById],
  );

  return {
    menuItems,
    menuItemsFlattened,
    activeMenuItem,
    areSiblingCategories,
    categoriesByIdsIncludingParents,
  };
};

export default useCategoryMenu;

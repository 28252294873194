import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Form, FormikProps, withFormik } from 'formik';
import { object, SchemaOf, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { differenceInCalendarMonths, parse, startOfToday } from 'date-fns';

import Payment from 'payment';

import { TextField } from 'components/common/formsFields';

import CTAButton from 'ui/common/buttons/CTAButton';

import PlaceOrderButton from 'ui/common/buttons/PlaceOrderButton';
import Typography from 'ui/common/Typography';

import { ITextInput } from 'ui/common/inputs/TextInput';

import { useSelector } from 'react-redux';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { useCart, useExternalPaymentForm, useWebsiteDetails } from 'hooks';
import PaymentInfoSection from 'ui/common/PaymentInfoSection';
import { TIconName } from 'ui/common/icons/Icon';
import useStyles from './styles';

import { IPaymentForm, IPaymentFormValues, TPaymentFormValidationValues } from './types';

const paymentFormSchema: SchemaOf<TPaymentFormValidationValues> = object().shape({
  cardHolderName: string().required('validation.required'),
  cardHolderID: string()
    .test(
      'test',
      'validation.error',
      (val) => !!val && val.toString().length > 6 && val.toString().length <= 9,
    )
    .required('validation.required'),
  creditCardNumber: string()
    .test(
      'test',
      'validation.error',
      (val) =>
        !!val &&
        val.toString().replace(/[\D]/g, '').length >= 8 &&
        val.toString().replace(/[\D]/g, '').length <= 16,
    )
    .required('validation.required'),
  expirationDate: string()
    .test('expirationDate', 'validation.error', (expirationDate) => {
      if (expirationDate) {
        const difference = differenceInCalendarMonths(
          startOfToday(),
          parse(expirationDate, 'MM/yy', new Date()),
        );

        return !Number.isNaN(difference) && !(difference > 0);
      }

      return false;
    })
    .required('validation.required'),
  cvv: string()
    .test(
      'test',
      'validation.error',
      (val) => !!val && val.toString().length >= 3 && val.toString().length <= 4,
    )
    .required('validation.required'),
});

const PaymentForm: FC<IPaymentForm & FormikProps<IPaymentFormValues>> = ({
  buttonLoading,
  isValid,
  isSubmitting,
  dirty,
  price,
  children,
  showRevaluation = true,
  type = 'checkout_3',
  setFieldValue,
  showPlaceOrderButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const websiteDetails = useWebsiteDetails();
  const externalPaymentFormRequired =
    websiteDetails.store.id.toString() === process.env.NEXT_PUBLIC_EXTERNAL_PAYMENT_PAGE_STORE_ID;

  const orderMode = useSelector(getOrderMode);

  const { handleContinueToExternalPaymentFormClick } = useExternalPaymentForm(price);
  const { limitedByAgeProducts } = useCart();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const orderDetails = useSelector(getOrderDetails);

  const [creditCardType, setCreditCardType] = useState<TIconName | string>('');
  const [waitingForExternalPaymentPage, setWaitingForExternalPaymentPage] =
    useState<boolean>(false);

  const isPlaceOrderButtonDisabled = useMemo(() => {
    return (
      !isValid ||
      !dirty ||
      isSubmitting ||
      (!!limitedByAgeProducts.length && !orderDetails.limitationByAgeChecked)
    );
  }, [dirty, isSubmitting, isValid, limitedByAgeProducts, orderDetails.limitationByAgeChecked]);

  const externalPaymentFormContent = useMemo(() => {
    return (
      <Box>
        <PaymentInfoSection name="icon-lock">
          {t('paymentInfoSection.creditCard.externalFormText')}
        </PaymentInfoSection>

        <Box display="flex" width="100%" mt={25 / 8}>
          {!isMobile && (
            <Grid item lg={6} md={6} sm={6}>
              {/*  <CTAButton variant="outlined" onClick={backButtonClick}>
                  {'forms.checkout.backToArrival'}
                </CTAButton> */}
            </Grid>
          )}
          <Grid item xs={12} lg={6} md={6} sm={6} className={classes.submitButtonSection}>
            <PlaceOrderButton
              orderMode={orderMode}
              loading={buttonLoading || waitingForExternalPaymentPage}
              fullWidth={isMobile}
              onClick={() =>
                handleContinueToExternalPaymentFormClick({
                  setLoading: setWaitingForExternalPaymentPage,
                })
              }
              price={price}
            >
              {'button.continue'}
            </PlaceOrderButton>
          </Grid>
        </Box>
      </Box>
    );
  }, [
    buttonLoading,
    classes.submitButtonSection,
    handleContinueToExternalPaymentFormClick,
    isMobile,
    orderMode,
    price,
    t,
    waitingForExternalPaymentPage,
  ]);

  const renderFinalCostText = useMemo<ReactNode>(() => {
    return (
      <Box mt={1} width="100%">
        <Grid container justify="center">
          <Typography color="mediumGrey" fontSize={13}>
            {'topCard.revaluation'}
          </Typography>
        </Grid>
      </Box>
    );
  }, []);

  const buttons = useMemo<ReactNode>(() => {
    switch (type) {
      case 'addNewPaymentMethod':
        return (
          <Box
            display="flex"
            flexDirection="row-reverse"
            width={'100%'}
            className={classes.addNewCardBtnWrapper}
          >
            <CTAButton
              className={classes.addNewCardBtn}
              type="submit"
              disabled={!isValid || !dirty || isSubmitting}
            >
              {'payments.options.addNewCard'}
            </CTAButton>
          </Box>
        );
      case 'smallEC':
        return (
          <Box display="flex" width="100%">
            {showPlaceOrderButton && (
              <PlaceOrderButton
                orderMode={orderMode}
                loading={buttonLoading}
                fullWidth
                type="submit"
                price={price}
                disabled={isPlaceOrderButtonDisabled}
              />
            )}
          </Box>
        );
      case 'checkout_3':
      default:
        return (
          <Box display="flex" width="100%">
            {!isMobile && (
              <Grid item lg={6} md={6} sm={6}>
                {/*  <CTAButton variant="outlined" onClick={backButtonClick}>
                  {'forms.checkout.backToArrival'}
                </CTAButton> */}
              </Grid>
            )}
            <Grid item xs={12} lg={6} md={6} sm={6} className={classes.submitButtonSection}>
              <PlaceOrderButton
                orderMode={orderMode}
                loading={buttonLoading}
                fullWidth={isMobile}
                type="submit"
                price={price}
                disabled={isPlaceOrderButtonDisabled}
              />
            </Grid>
          </Box>
        );
    }
  }, [
    type,
    classes.addNewCardBtnWrapper,
    classes.addNewCardBtn,
    classes.submitButtonSection,
    isValid,
    dirty,
    isSubmitting,
    showPlaceOrderButton,
    orderMode,
    buttonLoading,
    price,
    isPlaceOrderButtonDisabled,
    isMobile,
  ]);

  const handleCreditCardNumberChange = useCallback<Required<ITextInput>['onChange']>(
    (e) => {
      const { value } = e.target;

      if (!value) {
        setFieldValue('creditCardNumber', '');
        setCreditCardType('');
        return;
      }

      let cardNumberFormatted;
      const cardType = Payment.fns.cardType(value);
      const clearValue = value.replace(/\D+/g, '');

      switch (cardType) {
        case 'amex':
          setCreditCardType('icon-americanExpress');
          cardNumberFormatted = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            10,
          )} ${clearValue.slice(10, 15)}`;
          break;
        case 'dinersclub':
          setCreditCardType('icon-diners');
          cardNumberFormatted = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            10,
          )} ${clearValue.slice(10, 14)}`;
          break;
        default:
          if (cardType === 'mastercard') {
            setCreditCardType('icon-mastercard');
          }

          if (cardType === 'visa') {
            setCreditCardType('icon-visa');
          }
          cardNumberFormatted = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            8,
          )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
          break;
      }

      setFieldValue('creditCardNumber', cardNumberFormatted.trim());
    },
    [setFieldValue],
  );

  const handleExpirationDateChange = useCallback<Required<ITextInput>['onChange']>(
    (e) => {
      const { value } = e.target;

      const clearValue = value.replace(/\D+/g, '');

      if (clearValue.length >= 3) {
        setFieldValue('expirationDate', `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`);
        return;
      }

      setFieldValue('expirationDate', clearValue);
    },
    [setFieldValue],
  );

  if (externalPaymentFormRequired) {
    return externalPaymentFormContent;
  }

  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item lg={type === 'smallEC' ? 12 : 6} md={6} xs={12}>
          <Field name="cardHolderName" required component={TextField} />
        </Grid>
        <Grid item lg={type === 'smallEC' ? 12 : 6} md={6} xs={12}>
          <Field
            name="cardHolderID"
            type="tel"
            required
            typingPattern="^[0-9]{0,9}$"
            component={TextField}
          />
        </Grid>
      </Grid>
      <Box mt={1.5}>
        <Grid container spacing={3}>
          <Grid item lg={type === 'smallEC' ? 12 : 6} md={6} xs={12}>
            <Field
              name="creditCardNumber"
              type="tel"
              autoComplete="cc-number"
              required
              endIcon={creditCardType}
              onChange={handleCreditCardNumberChange}
              component={TextField}
              iconStyles={{ root: classes.creditCartStyles }}
              classes={{ adornedEnd: classes.adornedEnd, input: classes.creditCardInput }}
            />
          </Grid>
          <Grid item lg={type === 'smallEC' ? 6 : 3} md={3} xs={6}>
            <Field
              name="expirationDate"
              type="tel"
              required
              label="validity"
              placeholder={t('input.expirationDate')}
              component={TextField}
              onChange={handleExpirationDateChange}
            />
          </Grid>
          <Grid item lg={type === 'smallEC' ? 6 : 3} md={3} xs={6}>
            <Field
              name="cvv"
              type="tel"
              autoComplete="cc-csc"
              required
              typingPattern="^[0-9]{0,4}$"
              endIcon="icon-credit-card"
              iconSize="default"
              customIconColor="disabled"
              placeholder=""
              component={TextField}
            />
          </Grid>
        </Grid>
      </Box>
      {children}

      <Box mt={isMobile ? 2 : 5}>
        <Grid container justify="space-between">
          {buttons}
          {isMobile && showRevaluation && renderFinalCostText}
        </Grid>
      </Box>
    </Form>
  );
};

// TODO change to functional
export default withFormik<IPaymentForm, IPaymentFormValues>({
  mapPropsToValues: ({ defaultValues }) => {
    return {
      cardHolderName: (defaultValues && defaultValues.cardHolderName) || '',
      cardHolderID: (defaultValues && defaultValues.cardHolderID) || '',
      creditCardNumber: (defaultValues && defaultValues.creditCardNumber) || '',
      expirationDate: (defaultValues && defaultValues.expirationDate) || '',
      cvv: (defaultValues && defaultValues.cvv) || '',
      is_saveCredit: (defaultValues && defaultValues.is_saveCredit) || true,
    };
  },
  validationSchema: paymentFormSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus, resetForm }) => {
    props.handleSubmit(values, { setSubmitting, setErrors, setStatus, resetForm });
  },
  enableReinitialize: true,
})(PaymentForm);

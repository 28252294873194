import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesPromotionTag } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesPromotionTag, { isMobile: boolean; size: 'regular' | 'large' }> =>
    createStyles({
      promotionTagWrapper: ({ isMobile, size }) => ({
        backgroundColor: theme.palette.secondary.main,
        minWidth: '36px',
        height: isMobile ? '36px' : '38px',
        padding: theme.spacing(size === 'large' ? 2 / 8 : 0, 2 / 8),
        borderTopLeftRadius: 0,
        borderTopRightRadius: '2px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        textAlign: 'center',
        position: 'relative',
      }),
      leftBottomTriangle: {
        position: 'absolute',
        borderTop: `7px solid ${theme.palette.secondary.main}`,
        bottom: '-6px',
        borderRight: '21px solid transparent',
        left: 0,
        borderBottomLeftRadius: '2px',
      },
      rightBottomTriangle: {
        position: 'absolute',
        borderTop: `7px solid ${theme.palette.secondary.main}`,
        bottom: '-6px',
        borderLeft: '21px solid transparent',
        right: 0,
        borderBottomRightRadius: '2px',
      },
      topSection: {
        position: 'absolute',
        width: '0',
        borderBottom: '6px solid rgba(0, 0, 0, 1)',
        borderLeft: '5px solid transparent',
        top: 0,
        left: -5,
      },
      icon: {
        fontSize: '12px',
      },
      typographyRoot: ({ isMobile, size }) => ({
        letterSpacing: -0.04,
        lineHeight: (size === 'large' && 0.7) || (isMobile ? 0.4 : 0.3),
        fontSize: '12px',
      }),
    }),
);

import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';

import Typography from 'ui/common/Typography';

import Icon from 'ui/common/icons/Icon';
import DaysAndHours from 'components/common/DaysAndHours';
import classNames from 'classnames';
import useStyles from './styles';
import { IStoreOpenHoursDialog } from './types';

const StoreOpenHoursDialog: FC<IStoreOpenHoursDialog> = ({ storeOpenHours }) => {
  const lang = useSelector(getLang);

  const classes = useStyles({ lang });
  return (
    <Box className={classes.wrapper}>
      <Box display="flex" justifyContent="center" mb={24 / 8}>
        <Icon name="icon-clock" classes={{ root: classes.hourIcon }} />
        <Typography className={classNames(classes.hoursTitle, 'font-family-heebo')} variant="body1">
          {'aboutPage.openingHours'}
        </Typography>
      </Box>
      <DaysAndHours storeOpenHours={storeOpenHours} />
    </Box>
  );
};

export default StoreOpenHoursDialog;

import { ClientService } from 'api/services/ClientService';

import { IClientFormValues } from 'components/common/forms';
import { IPersonalDetailsFormValues } from 'components/common/forms/PersonalDetailsForm';
import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  initialPrepareToPlaceOrderState,
  shoppingFlowActions,
  userActions,
  userSelectors,
} from 'store';
import { profileUpdate } from 'store/modules/analytics/actions';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { prepareToPlaceOrderSuccess } from 'store/modules/prepareToPlaceOrder/actions';
import { getDetailsAtStoreLevel } from 'store/modules/user/selectors';

import { compareObjects } from 'utils';
import { TOnPersonalDetailsSubmit, TOnSubmitClientForm, TUseClientForm } from './types';

const useClientForm: TUseClientForm = () => {
  const profile = useSelector(userSelectors.getProfile);
  const orderDetails = useSelector(getOrderDetails);
  const detailsAtStoreLevel = useSelector(getDetailsAtStoreLevel);

  const dispatch = useDispatch();

  const defaultValues = useMemo<IClientFormValues>(() => {
    const clientFormDefaultValues: IClientFormValues = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      address: profile.address,
      floor: profile.floor,
      apartment: profile.apartment,
      entrance: profile.entrance,
      doorCode: profile.doorCode,
      addressNotes: profile.addressNotes,
      addressType: profile.address.addressType?.name || null,
      coordinates: null,
      clientDetailsOnStoreLevel: detailsAtStoreLevel,
    };

    if (profile.address.placeId !== null) {
      clientFormDefaultValues.address = {
        placeId: profile.address.placeId,
        address: profile.address.address,
        cityAndCountry: profile.address.cityAndCountry,
        city: profile.address.city,
        addressStatus: profile.address.addressStatus,
      };
    }

    return clientFormDefaultValues;
  }, [
    detailsAtStoreLevel,
    profile.address,
    profile.addressNotes,
    profile.apartment,
    profile.doorCode,
    profile.entrance,
    profile.firstName,
    profile.floor,
    profile.lastName,
  ]);

  const onSubmit = useCallback<TOnSubmitClientForm>(
    (successCallback, updateClientDetailsOnStoreLevel) => (values, formikHelpers) => {
      const { clientDetailsOnStoreLevel, ...changedObject } = values;
      const { clientDetailsOnStoreLevel: defaultClientDetailsOnStoreLevel, ...defaultObject } =
        defaultValues;
      // we need to remove clientDetailsOnStoreLevel to not send it under fields that were changed
      const fieldsToUpdate = compareObjects(defaultObject, changedObject);

      let promise;

      if (updateClientDetailsOnStoreLevel && values.clientDetailsOnStoreLevel) {
        promise = ClientService.postClientDetailsOnStoreLevel({
          id: values.clientDetailsOnStoreLevel.id,
          receivesCommercialMessages: values.clientDetailsOnStoreLevel.receivesCommercialMessages,
        });
      } else {
        promise = Promise.resolve(null);
      }

      promise.then(() => {
        // if form changed update client
        if (fieldsToUpdate.length !== 0) {
          dispatch(
            userActions.updateFullProfileDataRequest(
              values,
              fieldsToUpdate,
              formikHelpers,
              successCallback,
            ),
          );

          if (values.clientDetailsOnStoreLevel) {
            dispatch(userActions.updateDetailsAtStoreLevel(values.clientDetailsOnStoreLevel));
          }

          dispatch(
            profileUpdate({
              ...profile,
              ...values,
              ...values.clientDetailsOnStoreLevel,
            }),
          );

          dispatch(
            updateOrderDetails({
              ...orderDetails,
              orderTime: undefined,
              orderDate: undefined,
              orderHour: undefined,
              preferredDay: undefined,
              preferredHour: undefined,
            }),
          );

          dispatch(
            prepareToPlaceOrderSuccess({
              ...initialPrepareToPlaceOrderState,
            }),
          );

          // if no just go to next step
        } else if (successCallback) {
          successCallback(values);
        }
      });
    },
    [defaultValues, dispatch, orderDetails, profile],
  );

  const defaultValuesForPersonalDetailsForm = useMemo<IPersonalDetailsFormValues>(() => {
    const { firstName, lastName, email, cellPhone } = profile;

    const personalDetailsDefaultValues: IPersonalDetailsFormValues = {
      firstName,
      lastName,
      email,
      cellPhone,
      clientDetailsOnStoreLevel: detailsAtStoreLevel,
    };

    return personalDetailsDefaultValues;
  }, [detailsAtStoreLevel, profile]);

  const onPersonalDetailsSubmit = useCallback<TOnPersonalDetailsSubmit>(
    (successCallback) => (values, formikHelpers) => {
      const { clientDetailsOnStoreLevel, ...changedObject } = values;
      const { clientDetailsOnStoreLevel: defaultClientDetailsOnStoreLevel, ...defaultObject } =
        defaultValuesForPersonalDetailsForm;
      // we need to remove clientDetailsOnStoreLevel to not send it under fields that were changed
      const fieldsToUpdate = compareObjects(defaultObject, changedObject);

      dispatch(shoppingFlowActions.updateKnownAddressAndSubCatalog(null, null));

      let promise;

      if (values.clientDetailsOnStoreLevel) {
        promise = ClientService.postClientDetailsOnStoreLevel({
          id: values.clientDetailsOnStoreLevel.id,
          receivesCommercialMessages: values.clientDetailsOnStoreLevel.receivesCommercialMessages,
        });
      } else {
        promise = Promise.resolve(null);
      }

      promise.then(() => {
        // if form changed update client
        if (fieldsToUpdate.length !== 0) {
          dispatch(
            userActions.updateMinimumProfileDataRequest(
              values,
              fieldsToUpdate,
              formikHelpers,
              successCallback,
            ),
          );
        } else if (successCallback) {
          successCallback();
        }
      });
    },
    [defaultValuesForPersonalDetailsForm, dispatch],
  );

  return {
    defaultValues,
    onSubmit,
    onPersonalDetailsSubmit,
    defaultValuesForPersonalDetailsForm,
  };
};

export default useClientForm;

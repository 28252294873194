import { createTheme as createMuiTheme } from '@material-ui/core';

import { defaultsDeep } from 'lodash';
import { TCreateTheme } from './types';

export const createTheme: TCreateTheme = (storeThemePalette, direction = 'rtl') =>
  createMuiTheme({
    direction,
    typography: {
      fontFamily: 'Rubik, "Roboto", "Helvetica", "Arial", sans-serif',
    },
    palette: defaultsDeep(
      {
        error: {
          main: '#ed5c5a', // red
        },
        text: {
          primary: '#000000', // black
          secondary: '#333333', // dark-grey
        },
        grey: {
          100: '#f1f1f1', // very light grey
          600: '#666666', // grey
          A400: '#999999', // medium-grey
          A700: '#494949', // dark-grey2
        },
        background: {
          paper: '#fff', //
          darkGrey: '333',
        },
        warning: {
          main: '#ffcf00', // yellow
        },
      },
      storeThemePalette,
    ),
    props: {
      MuiIconButton: {
        disableFocusRipple: true,
      },
      MuiButton: {
        disableFocusRipple: true,
      },
    },
    overrides: {
      MuiTouchRipple: {
        rippleVisible: {
          animationDuration: '400ms',
        },
      },
      MuiIcon: {
        root: {
          overflow: 'visible',
        },
      },
      MuiButton: {
        outlinedPrimary: {
          '&:hover': {
            backgroundColor: storeThemePalette.primary.light,
          },
        },
        outlined: {
          padding: '5px 14px',
        },
        root: {
          padding: '6px 12px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: storeThemePalette.primary.main,
          },
          '&:focus-visible': {
            outline: 'solid 2px #007aff !important',
            outlineOffset: '4px !important',
            boxShadow: 'none !important',
          },
          '&& .MuiTouchRipple-child': {
            backgroundColor: storeThemePalette.primary.main,
            opacity: 0.2,
          },
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&& .MuiTouchRipple-child': {
            backgroundColor: storeThemePalette.primary.main,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: '10px',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
          '&:focus-visible': {
            outline: 'solid 2px #007aff !important',
            boxShadow: 'none !important',
          },
          '&& .MuiTouchRipple-child': {
            backgroundColor: storeThemePalette.primary.main,
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '4px',
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#d0d6d6',
            borderRadius: '3px',
          },
          '#__next, body, html': {
            height: '100%',
          },
          // in user agent stylesheet in new version (119) of google it applied automatically direction of rtl, but we supposed to change it depending on the current language
          'input[type="tel"]': {
            direction: 'ltr',
          },
          '#date-picker-dialog': {
            direction: 'ltr',
          },
        },
      },
    },
  });

/* eslint-disable */
// @ts-nocheck

import { put, PutEffect, select, SelectEffect, takeEvery } from '@redux-saga/core/effects';
import { startCheckout } from 'store/modules/analytics/actions';
import {
  CHANGE_PRODUCT_CARD_MODE,
  COMPLETE_REGISTRATION,
  FULLY_COMPLETE_REGISTRATION,
  INIT_STORE_ACCOUNTS,
  PAGE_VIEW,
  PRODUCT_CLICK,
  PROFILE_UPDATE,
  START_CHECKOUT,
  USER_LOGIN,
} from 'store/modules/analytics/constants';
import {
  IChangeProductCardMode,
  IExternalWebTrackingAccount,
  IFacebookAndTiktokPixelCheckoutInfo,
  IFacebookPixelItem,
  IFullyCompleteRegistration,
  IGoogleAnalytics4Item,
  IGoogleTagItem,
  IPageView,
  IProductClick,
  IRegistrationComplete,
  IStartCheckout,
  ITiktokPixelItem,
  IUserLogin,
  IUserProfileUpdate,
} from 'store/modules/analytics/types';
import { categoriesData } from 'store/modules/category/selectors';
import {
  IPlaceOrderSuccess,
  ISetOrderItemRequest,
  PLACE_ORDER_SUCCESS,
  SET_ORDER_ITEM_REQUEST,
  TOrderReducerState,
} from 'store/modules/order';
import { orderData, orderItems } from 'store/modules/order/selectors';
import { TOrderDiscount } from 'store/modules/orderDiscounts';
import { getOrderDiscounts } from 'store/modules/orderDiscounts/selectors';
import {
  prepareToPlaceOrderSelectors,
  TPrepareToPlaceOrderReducerState,
} from 'store/modules/prepareToPlaceOrder';
import { IStoreProduct } from 'store/modules/storeProduct';
import { storeProductsData } from 'store/modules/storeProduct/selectors';
import { IWebsiteDetails } from 'store/modules/website';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import { TOrderItemDTO, TPrepareToPlaceOrderServer } from 'types';
import { getCartEstimation } from 'utils/helpers/cartEstimation';

declare const window: any;

function initializeGoogleTagManager(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0];
  const j = d.createElement(s);
  const dl = l != 'dataLayer' ? `&l=${l}` : '';
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  f.parentNode.insertBefore(j, f);
}

function initializeFacebookPixel(f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
}

function initializeTiktokPixel(w, d, t) {
  w.TiktokAnalyticsObject = t;
  const ttq = (w[t] = w[t] || []);
  ttq.methods = [
    'page',
    'track',
    'identify',
    'instances',
    'debug',
    'on',
    'off',
    'once',
    'ready',
    'alias',
    'group',
    'enableCookie',
    'disableCookie',
    'holdConsent',
    'revokeConsent',
    'grantConsent',
  ];

  ttq.setAndDefer = function (t, e) {
    t[e] = function () {
      t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
    };
  };

  for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);

  ttq.instance = function (t) {
    for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
      ttq.setAndDefer(e, ttq.methods[n]);
    return e;
  };

  ttq.load = function (e, n) {
    const r = 'https://analytics.tiktok.com/i18n/pixel/events.js';

    ttq._i = ttq._i || {};
    ttq._i[e] = [];
    ttq._i[e]._u = r;
    ttq._t = ttq._t || {};
    ttq._t[e] = +new Date();
    ttq._o = ttq._o || {};
    ttq._o[e] = n || {};

    n = document.createElement('script');
    n.type = 'text/javascript';
    n.async = !0;
    n.src = `${r}?sdkid=${e}&lib=${t}`;
    e = document.getElementsByTagName('script')[0];
    e.parentNode.insertBefore(n, e);
  };
}
function* initStoreAnalyticsAccountsSaga(): Generator<SelectEffect, void, any> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { gtag } = window;
    getAllAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleAnalytics':
          if (gtag) {
            gtag('config', trackingAccount.identifier);
          }
          break;
        case 'facebookPixel':
          initializeFacebookPixel(
            window,
            document,
            'script',
            'https://connect.facebook.net/en_US/fbevents.js',
          );
          if (window.fbq) {
            const { fbq } = window;
            fbq('init', trackingAccount.identifier);
            fbq('track', 'PageView');
          }
          break;
        case 'tiktokPixel':
          initializeTiktokPixel(window, document, 'ttq');
          if (window.ttq) {
            const { ttq } = window;
            ttq.load(trackingAccount.identifier);
            ttq.page();
          }
          break;
        case 'googleTagManager':
          window.dataLayer = window.dataLayer || [];
          initializeGoogleTagManager(
            window,
            document,
            'script',
            'dataLayer',
            trackingAccount.identifier,
          );
          /*  if (gtag) {
            gtag('config', trackingAccount.identifier);
          } */
          break;
        case 'googleTagManagerAPI':
          window.dataLayerTM = window.dataLayerTM || [];
          initializeGoogleTagManager(
            window,
            document,
            'script',
            'dataLayerTM',
            trackingAccount.identifier,
          );
          break;
        default:
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* pageViewSaga({
  payload,
}: IPageView): Generator<SelectEffect | PutEffect<IStartCheckout>, void, any> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);

    const { gtag, dataLayerTM } = window;
    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManagerAPI':
          dataLayerTM.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayerTM.push({
            event: 'Pageview',
            pagePath: payload,
          });
          break;
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('config', trackingAccount.identifier, {
              page_path: payload,
            });
          }
          break;
        case 'facebookPixel':
          if (window.fbq) {
            const { fbq } = window;
            fbq('track', 'PageView');
          }
          break;
        default:
      }
    });

    if (payload === '/checkout/payment') {
      yield put(startCheckout());
    }
  } catch (e) {
    console.error(e);
  }
}

function* completeRegistrationSaga({
  payload,
}: IRegistrationComplete): Generator<SelectEffect | PutEffect<IRegistrationComplete>, void, any> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { gtag, dataLayerTM } = window;

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManagerAPI':
          dataLayerTM.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayerTM.push({
            event: 'signUp',
            profile: payload,
          });
          break;
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('event', 'sign_up', { payload });
          }
          break;
        case 'facebookPixel':
          if (window.fbq) {
            const { fbq } = window;
            fbq('track', 'CompleteRegistration');
          }
          break;
        case 'tiktokPixel':
          if (window.ttq) {
            const { ttq } = window;
            ttq.track('CompleteRegistration');
          }
          break;
        default:
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* fullyCompleteRegistrationSaga({
  payload,
}: IFullyCompleteRegistration): Generator<
  SelectEffect | PutEffect<IFullyCompleteRegistration>,
  void,
  any
> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { dataLayer } = window;

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManager':
        case 'googleAnalytics':
          if (dataLayer) {
            dataLayer.push({
              event: 'signup',
              flashy: {
                contact: {
                  email: payload.email,
                  first_name: payload.firstName,
                  last_name: payload.lastName,
                  phone: payload.cellPhone,
                  city: payload.address.cityAndCountry,
                  address: payload.address.address,
                },
                allow_marketing: payload.receivesCommercialMessages,
              },
            });
          }
          break;
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* userLoginSaga({
  payload,
}: IUserLogin): Generator<SelectEffect | PutEffect<IUserLogin>, void, any> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { gtag, dataLayer, dataLayerTM } = window;

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManagerAPI':
          dataLayerTM.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayerTM.push({
            event: 'userLogin',
            profile: payload,
          });
          break;
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('event', 'userLogin', { payload });
          }
          if (dataLayer) {
            dataLayer.push({
              event: 'signin',
              flashy: {
                email: payload.email,
                phone: payload.cellPhone,
              },
            });
          }
          break;
        case 'facebookPixel':
          if (window.fbq) {
            const { fbq } = window;
            fbq('track', 'UserLogin');
          }
          break;
        case 'tiktokPixel':
          if (window.ttq) {
            const { ttq } = window;
            ttq.track('UserLogin');
          }
          break;
        default:
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* addToCartSaga({ payload }: ISetOrderItemRequest): Generator<SelectEffect, void, any> {
  try {
    const { storeProduct, action } = payload;
    const { categoryById } = yield select(categoriesData);
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const items: TOrderReducerState['items'] = yield select(orderItems);

    const { gtag, dataLayerTM } = window;
    const categoryName =
      categoryById[storeProduct.productCategory.id] &&
      categoryById[storeProduct.productCategory.id].name;

    let requestedQuantity: number;
    const storeProductInCart = items.find((item) => item.storeProduct.id === storeProduct.id);
    if (storeProductInCart) {
      requestedQuantity = storeProductInCart.requestedQuantity;
    }

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManagerAPI':
          dataLayerTM.push({ ecommerce: null }); // Clear the previous ecommerce object.
          if (action === 'add') {
            dataLayerTM.push({
              event: 'addToCart',
              ecommerce: {
                add: {
                  products: [storeProductToGTagItem(storeProduct, categoryName, requestedQuantity)],
                },
              },
            });
          } else {
            dataLayerTM.push({
              event: 'removeFromCart',
              ecommerce: {
                remove: {
                  products: [storeProductToGTagItem(storeProduct, categoryName)],
                },
              },
            });
          }
          break;
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('event', action === 'add' ? 'add_to_cart' : 'remove_from_cart', {
              items: [storeProductToAnalytics4Item(storeProduct, categoryName)],
            });
          }
          break;
        case 'facebookPixel':
          if (action === 'add' && window.fbq) {
            const { fbq } = window;
            fbq('track', 'AddToCart', storeProductToFacebookPixelItem(storeProduct, categoryName));
          }
          break;
        case 'tiktokPixel':
          if (action === 'add' && window.ttq) {
            const { ttq } = window;
            ttq.track('AddToCart', storeProductToTiktokPixelItem(storeProduct, categoryName));
          }
          break;
        default:
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* productClickSaga({ payload }: IProductClick): Generator<SelectEffect, void, any> {
  try {
    const { productId, sourceEvent } = payload;

    const { storeProductById } = yield select(storeProductsData);

    const storeProduct = storeProductById[productId];

    const { categoryById } = yield select(categoriesData);
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { gtag, dataLayerTM } = window;
    const categoryName =
      categoryById[storeProduct.productCategory] && categoryById[storeProduct.productCategory].name;

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManagerAPI':
          dataLayerTM.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayerTM.push({
            event: 'productClick',
            ecommerce: {
              detail: {
                actionField: { list: sourceEvent }, // Optional list property.
                products: [storeProductToGTagItem(storeProduct, categoryName)],
              },
            },
          });
          break;
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('event', 'view_item', {
              items: [storeProductToAnalytics4Item(storeProduct, categoryName)],
            });
          }
          break;
        default:
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* startCheckoutSaga(): Generator<SelectEffect, void, any> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { categoryById } = yield select(categoriesData);
    const { storeProductById, outOfStockStoreProductById } = yield select(storeProductsData);
    const prepareToPlaceOrder: TPrepareToPlaceOrderReducerState = yield select(
      prepareToPlaceOrderSelectors.getPrepareToPlaceOrder,
    );
    const order: TOrderReducerState = yield select(orderData);
    const orderDiscounts: TOrderDiscount[] = yield select(getOrderDiscounts);

    const { gtag, dataLayerTM } = window;

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManagerAPI':
          dataLayerTM.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayerTM.push({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: { step: 3 },
                products: storeProductsToGTagItems(
                  order.items,
                  storeProductById,
                  categoryById,
                  outOfStockStoreProductById,
                ),
              },
            },
          });
          break;
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('event', 'begin_checkout', {
              items: storeProductsToAnalytics4Items(
                order.items,
                storeProductById,
                categoryById,
                outOfStockStoreProductById,
              ).filter((googleTagItem) => !!googleTagItem),
            });
          }
          break;
        case 'facebookPixel':
          if (window.fbq) {
            const { fbq } = window;
            fbq(
              'track',
              'InitiateCheckout',
              generateFacebookAndTiktokPixelCheckoutAndPurchaseInfo(
                order,
                orderDiscounts,
                prepareToPlaceOrder.serviceAreaUsageFees,
              ),
            );
          }
          break;
        case 'tiktokPixel':
          if (window.ttq) {
            const { ttq } = window;
            ttq.track(
              'InitiateCheckout',
              generateFacebookAndTiktokPixelCheckoutAndPurchaseInfo(
                order,
                orderDiscounts,
                prepareToPlaceOrder.serviceAreaUsageFees,
              ),
            );
          }
          break;
        default:
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* purchaseSaga({ payload }: IPlaceOrderSuccess): Generator<SelectEffect, void, any> {
  try {
    const transactionId = new Date().getTime(); // TODO - take from order

    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { categoryById } = yield select(categoriesData);
    const { storeProductById, outOfStockStoreProductById } = yield select(storeProductsData);

    const { order, orderDiscounts, prepareToPlaceOrder } = payload;
    const { gtag, dataLayerTM } = window;
    const storeCoupon = orderDiscounts.find(
      (orderDiscount) => orderDiscount.discountSourceType.name === 'storeCoupon',
    );

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManagerAPI':
          dataLayerTM.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayerTM.push({
            event: 'purchase',
            ecommerce: {
              purchase: {
                currencyCode: 'ILS',
                actionField: {
                  id: transactionId, // Transaction ID. Required for purchases and refunds.
                  revenue: +getCartEstimation(
                    order,
                    orderDiscounts,
                    prepareToPlaceOrder.serviceAreaUsageFees,
                  ).toFixed(2), // Total transaction value (incl. tax and shipping)
                  shipping: +prepareToPlaceOrder.serviceAreaUsageFees.toFixed(2),
                  coupon: storeCoupon ? storeCoupon.name : '',
                },
                products: storeProductsToGTagItems(
                  order.items,
                  storeProductById,
                  categoryById,
                  outOfStockStoreProductById,
                ),
              },
            },
          });
          break;
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('event', 'purchase', {
              transaction_id: transactionId,
              value: +getCartEstimation(
                order,
                orderDiscounts,
                prepareToPlaceOrder.serviceAreaUsageFees,
              ).toFixed(2),
              currency: 'ILS',
              shipping: +prepareToPlaceOrder.serviceAreaUsageFees.toFixed(2),
              items: storeProductsToAnalytics4Items(
                order.items,
                storeProductById,
                categoryById,
                outOfStockStoreProductById,
              ).filter((googleTagItem) => !!googleTagItem),
            });
          }
          break;
        case 'facebookPixel':
          if (window.fbq) {
            const { fbq } = window;
            fbq(
              'track',
              'Purchase',
              generateFacebookAndTiktokPixelCheckoutAndPurchaseInfo(
                order,
                orderDiscounts,
                prepareToPlaceOrder.serviceAreaUsageFees,
              ),
            );
          }
          break;
        case 'tiktokPixel':
          if (window.ttq) {
            const { ttq } = window;
            ttq.track(
              'CompletePayment',
              generateFacebookAndTiktokPixelCheckoutAndPurchaseInfo(
                order,
                orderDiscounts,
                prepareToPlaceOrder.serviceAreaUsageFees,
              ),
            );
          }
          break;
        default:
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* updateProfileSaga({
  payload,
}: IUserProfileUpdate): Generator<SelectEffect | PutEffect<IFullyCompleteRegistration>, void, any> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { dataLayer } = window;

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManager':
        case 'googleAnalytics':
          if (dataLayer) {
            dataLayer.push({
              event: 'contactUpdate',
              flashy: {
                contact: {
                  email: payload.email,
                  first_name: payload.firstName,
                  last_name: payload.lastName,
                  phone: payload.cellPhone,
                  city: payload.address.cityAndCountry,
                  address: payload.address.address,
                },
                allow_marketing: payload.clientDetailsOnStoreLevel?.receivesCommercialMessages,
              },
            });
          }
          break;
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function* changeProductCardMode({
  payload,
}: IChangeProductCardMode): Generator<SelectEffect, void, any> {
  try {
    const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
    const { gtag } = window;

    getOneOfEachTypeAnalyticsAccounts(websiteDetails).forEach((trackingAccount) => {
      switch (trackingAccount.accountType) {
        case 'googleTagManager':
        case 'googleAnalytics':
          if (gtag) {
            gtag('event', 'productCardMode', {
              productCardMode: payload.viewMode,
            });
          }
          break;
      }
    });
  } catch (e) {
    console.error(e);
  }
}

function getAllAnalyticsAccounts(websiteDetails: IWebsiteDetails): IExternalWebTrackingAccount[] {
  const result: IExternalWebTrackingAccount[] = [];
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    result.push({
      accountType: 'googleAnalytics',
      identifier: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    });
  }

  return result.concat(websiteDetails.externalWebTrackingAccounts);
}

function getOneOfEachTypeAnalyticsAccounts(
  websiteDetails: IWebsiteDetails,
): IExternalWebTrackingAccount[] {
  const result: IExternalWebTrackingAccount[] = [];
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    result.push({
      accountType: 'googleAnalytics',
      identifier: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    });
  }

  const accountsArray = result.concat(
    websiteDetails.externalWebTrackingAccounts.filter(
      (account) =>
        account.accountType !== 'googleAnalytics' && account.accountType !== 'googleTagManager',
    ),
  );

  const accountsMap = accountsArray.reduce(
    (acc: Map<string, IExternalWebTrackingAccount>, account) => {
      acc.set(account.accountType, account);
      return acc;
    },
    new Map(),
  );

  return [...accountsMap.values()];
}

function generateFacebookAndTiktokPixelCheckoutAndPurchaseInfo(
  order: TOrderReducerState,
  orderDiscounts: TOrderDiscount[],
  serviceAreaUsageFees: TPrepareToPlaceOrderServer['serviceAreaUsageFees'],
): IFacebookAndTiktokPixelCheckoutInfo {
  return {
    content_ids: order.items.map((item) => item.storeProduct.id),
    contents: order.items.map((item) => ({
      id: item.storeProduct.id,
      quantity: item.requestedQuantity,
    })),
    currency: 'ILS',
    num_items: order.items.length,
    value: +getCartEstimation(order, orderDiscounts, serviceAreaUsageFees).toFixed(2),
  };
}

function storeProductToGTagItem(
  storeProduct: IStoreProduct,
  categoryName: string,
  requestedQuantity?: number,
): IGoogleTagItem {
  const currentQuantity =
    storeProduct.productSellingUnits.find(
      (sellingUnit) => sellingUnit.id === storeProduct.defaultSelectedSellingUnit.id,
    )?.amountJumps || 1;
  const totalQuantity = requestedQuantity ? requestedQuantity + currentQuantity : currentQuantity;

  return {
    id: storeProduct.id,
    name: storeProduct.fullName.replaceAll('\n', ' '),
    category: categoryName,
    quantity: totalQuantity,
    price: storeProduct.price * currentQuantity,
  };
}

function storeProductToAnalytics4Item(
  storeProduct: IStoreProduct,
  categoryName: string,
  requestedQuantity?: number,
): IGoogleAnalytics4Item {
  const currentQuantity =
    storeProduct.productSellingUnits.find(
      (sellingUnit) => sellingUnit.id === storeProduct.defaultSelectedSellingUnit.id,
    )?.amountJumps || 1;
  const totalQuantity = requestedQuantity ? requestedQuantity + currentQuantity : currentQuantity;

  return {
    item_id: storeProduct.id,
    item_name: storeProduct.fullName.replaceAll('\n', ' '),
    item_category: categoryName,
    price: storeProduct.price * currentQuantity,
    quantity: totalQuantity,
  };
}

function storeProductsToGTagItems(
  storeProducts: TOrderItemDTO[],
  storeProductById: any,
  categoryById: any,
  outOfStockStoreProductById: any,
): IGoogleTagItem[] {
  const formattedProducts: IGoogleTagItem[] = [];

  storeProducts.map((item) => {
    const storeProduct =
      storeProductById[item.storeProduct.id] || outOfStockStoreProductById[item.storeProduct.id];
    const categoryName =
      categoryById[storeProduct.productCategory] && categoryById[storeProduct.productCategory].name;

    if (storeProduct) {
      formattedProducts.push(storeProductToGTagItem(storeProduct, categoryName));
    }
  });

  return formattedProducts;
}

function storeProductsToAnalytics4Items(
  storeProducts: TOrderItemDTO[],
  storeProductById: any,
  categoryById: any,
  outOfStockStoreProductById: any,
): IGoogleAnalytics4Item[] {
  const formattedProducts: IGoogleAnalytics4Item[] = [];

  storeProducts.map((item) => {
    const storeProduct =
      storeProductById[item.storeProduct.id] || outOfStockStoreProductById[item.storeProduct.id];
    const categoryName =
      categoryById[storeProduct.productCategory] && categoryById[storeProduct.productCategory].name;

    if (storeProduct) {
      formattedProducts.push(storeProductToAnalytics4Item(storeProduct, categoryName));
    }
  });

  return formattedProducts;
}

function storeProductToFacebookPixelItem(
  storeProduct: IStoreProduct,
  categoryName: string,
): IFacebookPixelItem {
  return {
    content_name: storeProduct.fullName,
    content_category: categoryName,
    content_ids: [storeProduct.id],
    content_type: 'product',
    value: storeProduct.price,
    currency: 'ILS',
  };
}

function storeProductToTiktokPixelItem(
  storeProduct: IStoreProduct,
  categoryName: string,
): ITiktokPixelItem {
  return {
    contents: [
      {
        content_id: storeProduct.id,
        content_name: storeProduct.fullName,
        price: storeProduct.price,
        content_category: categoryName,
      },
    ],
    content_type: 'product',
    value: storeProduct.price,
    currency: 'ILS',
  };
}

function* rootAnalyticsSaga(): Generator {
  yield takeEvery(INIT_STORE_ACCOUNTS, initStoreAnalyticsAccountsSaga);
  yield takeEvery(PAGE_VIEW, pageViewSaga);
  yield takeEvery(COMPLETE_REGISTRATION, completeRegistrationSaga);
  yield takeEvery(FULLY_COMPLETE_REGISTRATION, fullyCompleteRegistrationSaga);
  yield takeEvery(USER_LOGIN, userLoginSaga);
  yield takeEvery(SET_ORDER_ITEM_REQUEST, addToCartSaga);
  yield takeEvery(START_CHECKOUT, startCheckoutSaga);
  yield takeEvery(PLACE_ORDER_SUCCESS, purchaseSaga);
  yield takeEvery(PRODUCT_CLICK, productClickSaga);
  yield takeEvery(PROFILE_UPDATE, updateProfileSaga);
  yield takeEvery(CHANGE_PRODUCT_CARD_MODE, changeProductCardMode);
}

export default rootAnalyticsSaga;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { ICartItemQuantityAndUnitControls, TStylesQuantityButton } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesQuantityButton, ICartItemQuantityAndUnitControls> =>
    createStyles({
      wrapper: {
        width: ({ orientation }) => {
          switch (orientation) {
            case 'horizontal': {
              return 'auto';
            }
            case 'vertical':
              return theme.spacing(6.5); // 52px
            case 'block':
              return theme.spacing(9.125); // 73px
            default:
              return 'auto';
          }
        },
        direction: 'ltr',
        alignItems: 'center',
        textAlign: 'center',
        display: ({ orientation }) => (orientation === 'horizontal' ? 'flex' : 'block'),
      },
      buttonRoot: ({ orientation }) => ({
        marginTop: orientation === 'horizontal' ? 0 : theme.spacing(0.625), // 5px
        width:
          orientation === 'horizontal'
            ? theme.spacing(2.5) // 20px
            : theme.spacing(2), // 16px
        height:
          orientation === 'horizontal'
            ? theme.spacing(2.5) // 20px
            : theme.spacing(2), // 16px
        backgroundColor: theme.palette.primary.main,
        minWidth:
          orientation === 'horizontal'
            ? theme.spacing(2.5) // 20px
            : theme.spacing(2), // 16px,
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
      iconRoot: {
        fontSize: theme.spacing(1), // 8px
      },
      quantity: {
        marginTop: ({ orientation }) => (orientation === 'horizontal' ? 0 : theme.spacing(0.5)), // 4px
      },
      dropDown: {
        marginBottom: ({ orientation }) =>
          orientation === 'horizontal' ? 0 : theme.spacing(0.625), // 5px
      },
      buttonsWrapper: {
        display: 'flex',
        flexDirection: theme.direction === 'rtl' ? 'initial' : 'row-reverse',
      },
      horizontalPlusBtn: {
        flip: false as unknown as string,
        margin: ({ orientation }) =>
          orientation === 'block'
            ? theme.spacing(0, 0, 0, 12 / 8)
            : theme.spacing(0, theme.direction === 'rtl' ? 0 : 9 / 8, 0, 9 / 8),
      },
      horizontalMinusBtn: {
        flip: false as unknown as string,
        margin: ({ orientation }) =>
          orientation === 'block'
            ? theme.spacing(0, 12 / 8, 0, 0)
            : theme.spacing(0, 10 / 8, 0, theme.direction === 'rtl' ? 9 / 8 : 0),
      },
      unitSwitcherSection: {
        width: '100%',
        maxWidth: ({ orientation }) => (orientation === 'block' ? 'auto' : '52px'),
        marginTop: ({ orientation }) =>
          orientation === 'block'
            ? theme.spacing(0.75) // 6px
            : 0,
      },
      blockOrientation: {
        flip: false as unknown as string,
        direction: 'rtl',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      containedSecondary: {
        '&:disabled': {
          color: theme.palette.text.secondary,
          boxShadow: 'inherit',
          backgroundColor: theme.palette.grey.A100,
        },
      },
    }),
);

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMobile, useRenderDate } from 'hooks';

import { Box } from '@material-ui/core';
import Typography from 'ui/common/Typography';

import classNames from 'classnames';
import useStyles from './styles';
import { IPromotionLimitationsDescription } from './types';

const PromotionLimitationsDescription: FC<IPromotionLimitationsDescription> = ({
  promotion,
  customClasses,
}) => {
  const { isMobile } = useMobile();
  const classes = useStyles({ isMobile });

  const { t } = useTranslation();

  const { renderWeekdayShortNames } = useRenderDate();

  const { maxQuantity, requiredQuantity, orderType, relevantWeekdays } = promotion.parameters;

  const maxQuantityForPromotion =
    maxQuantity && promotion.promotionType.name === 'forNonWeighableAboveQuantity'
      ? Math.floor(maxQuantity / (requiredQuantity || 1))
      : maxQuantity;

  const promotionContent = useMemo(() => {
    const promotions: string[] = [];
    if (orderType || (relevantWeekdays && !!relevantWeekdays.length)) {
      promotions.push(t('discount.valid'));
      if (orderType) {
        promotions.push(` ${t(`discount.forOrders.${orderType}`)}`);
      }

      if (relevantWeekdays && !!relevantWeekdays.length) {
        promotions.push(` ${t('discount.days')}`);
        promotions.push(` ${renderWeekdayShortNames(relevantWeekdays)}`);
      }

      promotions.push(`\u00A0 | \u00A0`);
    }

    if (maxQuantityForPromotion) {
      promotions.push(
        `${t(`discount.maxQuantityUsage.${promotion.promotionType.name}`, {
          maxQuantity: maxQuantityForPromotion,
        })}\u00A0 | \u00A0`,
      );
    }

    promotions.push(`${t('discount.validUntil')} ${promotion.expirationTime}`);

    return promotions;
  }, [
    maxQuantityForPromotion,
    orderType,
    promotion.expirationTime,
    promotion.promotionType.name,
    relevantWeekdays,
    renderWeekdayShortNames,
    t,
  ]);

  return (
    <Box className={customClasses?.limitationMainPart || classes.limitationMainPart}>
      <Box display="flex">
        <Typography
          className={
            customClasses?.limitationTypography ||
            classNames(classes.limitationTypography, 'font-family-rubik')
          }
        >
          {promotionContent.map((promotionDetails, index) => (
            <span key={index}>{promotionDetails}</span>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};

export default PromotionLimitationsDescription;

import { Box, CardMedia } from '@material-ui/core';
import { useMobile, useWebsiteDetails } from 'hooks';
import { useRouter } from 'next/router';
import React, { FC, useContext } from 'react';
import CTAButton from 'ui/common/buttons/CTAButton';
import Icon from 'ui/common/icons/Icon';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import Link from 'ui/common/Link';

import Typography from 'ui/common/Typography';
import { prepareImageUrl } from 'utils/helpers/prepareImageUrl';
import useStyles from './styles';

import { ITextAndImage } from './types';

const TextAndImage: FC<ITextAndImage> = ({ image, title, description, linkLabel, linkHref }) => {
  const mainLayoutContext = useContext(MainLayoutContext);
  const { isMobile } = useMobile();
  const router = useRouter();
  const websiteDetails = useWebsiteDetails();

  const classes = useStyles({ isOpenedTopCart: mainLayoutContext.isOpenedTopCart });

  return (
    <Box position="relative">
      <Box className={classes.textAndImageBackground}>
        <Box className={classes.textAndImageWrapper}>
          <Box className={classes.textAndImageContainer}>
            <Box width={isMobile ? 'auto' : '50%'}>
              <Typography className={classes.sectionTitle} variant="body1" isTranslate={false}>
                {title}
              </Typography>
              <Typography
                className={classes.sectionDescription}
                variant="body1"
                isTranslate={false}
              >
                {description}
              </Typography>
              {!isMobile && linkHref && linkLabel && (
                <Box>
                  <Link href={linkHref} targetBlank className={classes.textAndImageLink}>
                    <Typography className={classes.linkLabel} isTranslate={false}>
                      {linkLabel}
                    </Typography>
                    <Icon
                      name="icon-arrow-left"
                      color="primary"
                      classes={{ root: classes.iconArrow }}
                    />
                  </Link>
                </Box>
              )}
            </Box>
            <Box position="relative" className={classes.imageContainer}>
              <CardMedia
                src={prepareImageUrl(image.value)}
                component="img"
                className={classes.image}
                alt={image.altText}
              />
            </Box>
            {isMobile && linkHref && linkLabel && (
              <Box className={classes.buttonWrapper}>
                <CTAButton
                  onClick={() => router.push(linkHref)}
                  className={classes.loadMoreButton}
                  size="medium"
                  variant="outlined"
                >
                  <Typography className={classes.loadMoreButtonLabel} isTranslate={false}>
                    {linkLabel}
                  </Typography>
                </CTAButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <CardMedia
        classes={{ root: classes.textAndImageSideImage }}
        alt=""
        component="img"
        image={websiteDetails.theme.homePage.images.imageWithText}
      />
    </Box>
  );
};

export default TextAndImage;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesTextAndImages } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesTextAndImages, { isOpenedTopCart: boolean }> =>
    createStyles({
      textAndImageWrapper: {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          margin: 'auto',
          maxWidth: '1240px',
        },
      },
      textAndImageBackground: {
        padding: '40px 14px',
        backgroundColor: theme.palette.grey[50],
        [theme.breakpoints.up('md')]: {
          padding: '80px 0px',
        },
      },
      textAndImageContainer: {
        maxWidth: '600px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          margin: '0px',
          maxWidth: ({ isOpenedTopCart }) => (isOpenedTopCart ? 898 : '100%'),
          display: 'flex',
          alignItems: 'center',
        },
      },
      imageContainer: {
        padding: '0px 16px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        [theme.breakpoints.up('md')]: {
          padding: '0px',
          display: 'block',
          marginTop: '0px',
        },
      },
      image: {
        minHeight: '300px',
        minWidth: '300px',
        maxHeight: '400px',
        maxWidth: '400px',
        width: '100%',
        aspectRatio: '1/1',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 1,
        [theme.breakpoints.up('md')]: {
          height: ({ isOpenedTopCart }) => (isOpenedTopCart ? '449px' : '590px'),
          width: ({ isOpenedTopCart }) => (isOpenedTopCart ? '449px' : '590px'),
          aspectRatio: 'initial',
          marginRight: ({ isOpenedTopCart }) =>
            theme.direction === 'rtl' ? (isOpenedTopCart && '50px') || '100px' : 0,
          marginLeft: ({ isOpenedTopCart }) =>
            theme.direction === 'rtl' ? 0 : (isOpenedTopCart && '50px') || '100px',
          borderRadius: '21px',
          minHeight: 'initial',
          minWidth: 'initial',
          maxHeight: 'initial',
          maxWidth: 'initial',
        },
      },
      sectionTitle: {
        fontSize: '26px',
        lineHeight: '28px',
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontFamily: 'Heebo',
        [theme.breakpoints.up('md')]: {
          fontSize: '36px',
          lineHeight: '38px',
        },
      },
      sectionDescription: {
        fontSize: '18px',
        lineHeight: '26px',
        marginTop: '12px',
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('md')]: {
          fontSize: '20px',
          lineHeight: '30px',
          marginTop: '14px',
        },
      },
      linkLabel: {
        fontSize: '18px',
        lineHeight: '22px',
        color: theme.palette.primary.main,
        fontWeight: 500,
        marginRight: '6px',
      },
      textAndImageLink: {
        display: 'flex',
        marginTop: '20px',
        alignItems: 'baseline',
      },
      iconArrow: {
        fontSize: '14px',
      },
      textAndImageSideImage: {
        position: 'absolute',
        top: '-40px',
        width: '120px',
        height: '120px',
        right: '0px',
        [theme.breakpoints.up('md')]: {
          top: ({ isOpenedTopCart }) => (isOpenedTopCart ? '14px' : '-5px'),
          width: '190px',
          height: '190px',
          left: ({ isOpenedTopCart }) =>
            theme.direction === 'rtl'
              ? (isOpenedTopCart && '792px') || '594px'
              : (isOpenedTopCart && '514px') || '704px',
        },
      },
      loadMoreButton: {
        width: '160px',
        [theme.breakpoints.up('md')]: {
          width: '150px',
        },
      },
      loadMoreButtonLabel: {
        fontSize: '16px',
        color: theme.palette.grey.A700,
      },
      buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
      },
    }),
);

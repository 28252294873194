/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, PutEffect, select, SelectEffect, takeEvery } from '@redux-saga/core/effects';

import {
  IAddOrRemovePromotionsCategory,
  IGetCategoriesRequest,
  IUpdateProductByIdSuccess,
  promotionsActions,
  storeProductActions,
  storeProductSelectors,
  TStoreProductReducerState,
} from 'store';
import { IUpdateHomePageUiStyles } from 'store/modules/uiStyles';

import { storePromotionType, TPromotionParameters } from 'types';

import { getIdsAndByIds } from 'utils';
import { prepareStoreProductSource } from 'utils/helpers/storeProduct/storeProduct';
import { GET_PROMOTIONS_REQUEST } from './constants';

import { IGetPromotionsRequest, IGetPromotionsSuccess, IPromotion } from './types';

function* getPromotions({
  payload,
}: IGetPromotionsRequest): Generator<
  | SelectEffect
  | PutEffect<IGetPromotionsSuccess>
  | PutEffect<IUpdateProductByIdSuccess>
  | PutEffect<IUpdateHomePageUiStyles>
  | PutEffect<IGetCategoriesRequest>
  | PutEffect<IAddOrRemovePromotionsCategory>,
  void,
  any
> {
  const { promotions } = payload;

  const newPromotions: IPromotion[] = promotions.map((promotion) => {
    // parse string to rewrite parameters
    const parsedParameters: TPromotionParameters = JSON.parse(promotion.parameters);

    return {
      ...promotion,
      storeProductSource: prepareStoreProductSource(promotion.storeProductSource),
      parameters: parsedParameters,
      discountSourceType: storePromotionType,
    };
  });

  const promotionsForCalculation = newPromotions.filter((promotion) => {
    return (
      promotion.promotionType.name !== 'forNewSubscription' &&
      promotion.promotionType.name !== 'forSubscription' &&
      promotion.promotionType.name !== 'forCartEstimationAfterPreparation'
    );
  });

  const relevantPromotionsForBanners = newPromotions.filter((promotion) => {
    if (promotion.storeProductSource) {
      if (promotion.valueFormulaType.sign !== '%' && !promotion.bannerText) {
        // valueFormulaType in % limited up to 100% on backoffice side
        return promotion.storeProductSource.price >= +promotion.valueFormula;
      }
    }

    return (
      promotion.promotionType.name !== 'forWeighableBetweenQuantity' &&
      promotion.promotionType.name !== 'forNonWeighableAboveQuantity'
    );
  });

  // take products and write the promotion attribute
  const {
    storeProductById,
  }: {
    storeProductIds: TStoreProductReducerState['storeProductIds'];
    storeProductById: TStoreProductReducerState['storeProductById'];
  } = yield select(storeProductSelectors.storeProductsData);

  const { objById, arrayIds } = getIdsAndByIds<IPromotion, number>(
    promotionsForCalculation,
    'nonObfuscatedId',
  );

  // update promotions state
  yield put(
    promotionsActions.getPromotionsSuccessAction(arrayIds, objById, relevantPromotionsForBanners),
  );

  const newStoreProductById = { ...storeProductById };

  // loop newPromotions and add promotion if we have storeProduct id in participatingProduct or participatingProducts array
  promotionsForCalculation.forEach(({ parameters, nonObfuscatedId }) => {
    if (parameters.participatingProduct && storeProductById[parameters.participatingProduct.id]) {
      newStoreProductById[parameters.participatingProduct.id] = {
        ...newStoreProductById[parameters.participatingProduct.id],
        // save promotion id on store product
        promotion: nonObfuscatedId,
      };
    }

    if (parameters.participatingProducts) {
      parameters.participatingProducts.forEach((participatingProduct) => {
        if (storeProductById[participatingProduct.id]) {
          newStoreProductById[participatingProduct.id] = {
            ...newStoreProductById[participatingProduct.id],
            // save promotion id on store product
            promotion: nonObfuscatedId,
          };
        }
      });
    }
  });

  // update store products with promotion property
  yield put(storeProductActions.updateProductByIdSuccess(newStoreProductById));
}

function* rootPromotionsSaga(): Generator {
  yield takeEvery(GET_PROMOTIONS_REQUEST, getPromotions);
}

export default rootPromotionsSaga;

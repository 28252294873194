import {
  TGetKnownAddressSubCatalog,
  TIsIntroductoryPopupsCompleted,
  TIsShowLocationSelector,
  TKnownAddressSelector,
  TRedirectAfterHourSelectionSelector,
} from './types';

const getKnownAddress: TKnownAddressSelector = ({ shoppingFlow }) => shoppingFlow.knownAddress;

const getIsShowLocation: TIsShowLocationSelector = ({ shoppingFlow }) =>
  shoppingFlow.isShowLocationPopup;

const getIntroductoryPopupsCompleted: TIsIntroductoryPopupsCompleted = ({ shoppingFlow }) =>
  shoppingFlow.isIntroductoryPopupsCompleted;

const getRedirectAfterHourSelection: TRedirectAfterHourSelectionSelector = ({ shoppingFlow }) =>
  shoppingFlow.redirectAfterHourSelection;

const getKnownAddressSubCatalog: TGetKnownAddressSubCatalog = ({ shoppingFlow }) =>
  shoppingFlow.knownAddressSubCatalog;

export {
  getKnownAddress,
  getIsShowLocation,
  getRedirectAfterHourSelection,
  getIntroductoryPopupsCompleted,
  getKnownAddressSubCatalog,
};

import { BaseApi } from 'api/Api/BaseApi';
import { TGetProductsYouMightWant, TGetSimilarProducts } from './types';

export default class ProductRecommendationService {
  static getSimilarProducts: TGetSimilarProducts = async (productId, config) => {
    return BaseApi.instance(config).get(
      `/client/public/store/catalog/${productId}/similar-products`,
    );
  };

  static getProductsYouMightWant: TGetProductsYouMightWant = async (body, config) => {
    return BaseApi.instance(config).post(`/client/public/store/catalog/products/recommend`, body);
  };
}

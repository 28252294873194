import React, { FC } from 'react';
import { Box, Divider } from '@material-ui/core';
import Typography from 'ui/common/Typography';
import { useRenderPrice } from 'hooks';
import { IDeliveryFeesAndMinimumOrder } from './types';

const DeliveryFeesAndMinimumOrder: FC<IDeliveryFeesAndMinimumOrder> = ({
  deliveryFees,
  orderMinTotalValue,
}) => {
  const renderPrice = useRenderPrice();

  return (
    <Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" my={16 / 8}>
        <Box mr={20 / 8}>
          <Typography
            variant="body1"
            fontSize={16}
            color="textSecondary"
            align="justify"
            fontWeight="medium"
          >
            {'serviceAreaUsageFees.delivery'}
          </Typography>
        </Box>
        <Typography variant="body1" fontSize={16} align="justify" color="textSecondary">
          {renderPrice(deliveryFees)}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" mt={16 / 8}>
        <Box mr={20 / 8}>
          <Typography
            variant="body1"
            fontSize={16}
            color="textSecondary"
            align="justify"
            fontWeight="medium"
          >
            {'dialog.orderMinimumLabel'}
          </Typography>
        </Box>
        <Typography variant="body1" fontSize={16} align="justify" color="textSecondary">
          {renderPrice(orderMinTotalValue)}
        </Typography>
      </Box>
    </Box>
  );
};

export default DeliveryFeesAndMinimumOrder;

import React, { FC, useCallback } from 'react';

import { Box, CardMedia } from '@material-ui/core';
import Typography from 'ui/common/Typography';

import CTAButton from 'ui/common/buttons/CTAButton';
import { useDialog, useMobile, useWebsiteDetails } from 'hooks';
import useStyles from './styles';

import { IGenericDialog } from './types';

const GenericDialog: FC<IGenericDialog> = ({ title, body, buttons, imageSrc, translate }) => {
  const classes = useStyles();
  const { hideDialog } = useDialog();
  const websiteDetails = useWebsiteDetails();
  const handleCloseButtonClicked = useCallback(
    (callback) => {
      if (callback) callback();
      hideDialog();
    },
    [hideDialog],
  );
  const { isMobile } = useMobile();

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={imageSrc || websiteDetails.theme.images.warning}
          component="img"
          className={classes.image}
          alt="Warning"
        />
      </Box>
      <Box className={classes.title}>
        <Typography
          isTranslate={translate}
          variant="h1"
          color="secondary"
          align="center"
          className={classes.titleMain}
        >
          {title}
        </Typography>
        <Box mb={isMobile ? 10 / 8 : 12 / 8} mt={10 / 8}>
          <Typography
            isTranslate={translate}
            variant="body1"
            fontSize={16}
            color="grey"
            align="center"
          >
            {body}
          </Typography>
        </Box>
      </Box>
      <Box>
        {buttons &&
          buttons.map((button) => {
            return button.closeButton ? (
              <CTAButton
                key={button.text}
                variant={button.variant || 'contained'}
                onClick={() => handleCloseButtonClicked(button.onClick)}
                className={button.overrideClassName || classes.rootButton}
              >
                <Typography fontWeight="medium">{button.text}</Typography>
              </CTAButton>
            ) : (
              <CTAButton
                key={button.text}
                {...button}
                className={button.overrideClassName || classes.rootButton}
              >
                {button.text}
              </CTAButton>
            );
          })}
      </Box>
    </Box>
  );
};

export default GenericDialog;

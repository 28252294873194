import { OrdersService, SubscriptionService } from 'api';
import { TActionResult } from 'api/Api/BaseApi/types';
import { isBefore } from 'date-fns';
import { useDialog } from 'hooks/useDialog';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useCallback } from 'react';
import { ACTIVE_SUBSCRIPTION_DIALOG, EXISTING_ORDER_DIALOG } from 'store';
import { TSubscriptionServer } from 'types';
import {
  TCheckActiveOrdersAndSubscriptionsCallback,
  TUseActiveOrdersAndSubscriptionsChecks,
} from './types';

const useActiveOrdersAndSubscriptionsChecks: TUseActiveOrdersAndSubscriptionsChecks = () => {
  const websiteDetails = useWebsiteDetails();
  const { showDialog } = useDialog();

  const checkActiveOrdersAndSubscriptions = useCallback<TCheckActiveOrdersAndSubscriptionsCallback>(
    (callbackIfOrderOrSubscriptionDontExist) => {
      const ordersServicePromise = OrdersService.getOrders();
      const subscriptionServicePromise: Promise<TActionResult<TSubscriptionServer[]>> =
        websiteDetails.websiteSettings.enableSubscriptions
          ? SubscriptionService.getSubscriptions()
          : Promise.resolve({
              success: true,
              data: [],
              code: '',
              message: '',
              resolvedMessage: '',
            });

      Promise.all([ordersServicePromise, subscriptionServicePromise]).then((actionResults) => {
        const ordersResult = actionResults[0];
        const subscriptionsResult = actionResults[1];

        const activeSubscription = subscriptionsResult.data.find(
          (subscription) => subscription.status.name === 'active',
        );

        const newOrder = ordersResult.data.find(
          (order) =>
            order.status.name === 'newOrder' &&
            isBefore(new Date().getTime(), order.maxOrderUpdateTimeAllowed),
        );

        if (newOrder) {
          showDialog({
            dialogType: EXISTING_ORDER_DIALOG,
            contentProps: {
              order: newOrder, // orders are order by date on the server, the first order in array - last by date
            },
          });
        } else if (activeSubscription) {
          showDialog({
            dialogType: ACTIVE_SUBSCRIPTION_DIALOG,
            contentProps: {
              subscription: activeSubscription,
            },
          });
        } else {
          callbackIfOrderOrSubscriptionDontExist();
        }
      });
    },
    [showDialog, websiteDetails.websiteSettings.enableSubscriptions],
  );

  return {
    checkActiveOrdersAndSubscriptions,
  };
};

export default useActiveOrdersAndSubscriptionsChecks;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesPaymentForm } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPaymentForm> =>
    createStyles({
      submitButtonSection: {
        textAlign: 'end',
      },
      addNewCardBtn: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(356 / 8),
        },
      },
      addNewCardBtnWrapper: {
        flip: false as unknown as string,
        direction: 'rtl',
        marginTop: theme.spacing(20 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: '0',
        },
      },
      creditCartStyles: {
        overflow: 'inherit',
      },
      adornedEnd: {
        paddingRight: theme.spacing(24 / 8),
      },
      creditCardInput: {
        // @ts-ignore
        direction: theme.direction === 'rtl' ? 'rtl !important' : 'ltr !important',
        textAlign: theme.direction === 'rtl' ? 'end' : 'initial',
      },
    }),
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton } from '@material-ui/core';
/* eslint-disable react/jsx-no-bind */
import React, { PropsWithChildren, ReactElement, useMemo } from 'react';

import { get, merge } from 'lodash';

import { AutocompleteRenderInputParams, Autocomplete as MuiAutocomplete } from '@material-ui/lab';

import { useTranslation } from 'react-i18next';
import Icon from 'ui/common/icons/Icon';
import TextInput from '../TextInput';

import useStyles from './styles';

import { IAutocompleteProps, TAutocompleteValue } from './types';

function Autocomplete<
  T = TAutocompleteValue,
  Multiple extends boolean | undefined = boolean | undefined,
  DisableClearable extends boolean | undefined = boolean | undefined,
  FreeSolo extends boolean | undefined = boolean | undefined,
>({
  label,
  customLabel,
  error,
  helperText,
  optionText = 'name',
  optionValue = 'id',
  required,
  inputProps,
  value,
  openAutocomplete,
  overrideClassNames,
  onClearInput,
  ...rest
}: PropsWithChildren<IAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>>): ReactElement<
  any,
  any
> | null {
  const { t } = useTranslation();

  const classes = useStyles();

  const renderInput = useMemo(
    () => (params: AutocompleteRenderInputParams) => {
      return (
        <TextInput
          className={classes.root}
          {...params}
          innerRef={params.InputProps.ref}
          isShowLabel={false}
          label={label}
          customLabel={customLabel}
          startIcon={value ? 'icon-location-2' : 'icon-search'}
          endAdornment={
            onClearInput &&
            value && (
              <IconButton
                disableTouchRipple
                aria-label={t('shoppingPage.cleanSearch')}
                classes={{ root: classes.closeButtonRoot }}
                onClick={onClearInput}
              >
                <Icon color="primary" name="icon-button-x" fontSize="inherit" />
              </IconButton>
            )
          }
          error={error}
          helperText={helperText}
          required={required}
          {...inputProps}
        />
      );
    },
    [
      classes.root,
      classes.closeButtonRoot,
      label,
      customLabel,
      value,
      onClearInput,
      t,
      error,
      helperText,
      required,
      inputProps,
    ],
  );

  return (
    <MuiAutocomplete
      open={openAutocomplete}
      filterSelectedOptions
      includeInputInList
      autoComplete
      loadingText={t('autocomplete.loading')}
      noOptionsText={t('validation.noOptions')}
      getOptionLabel={(option) => (typeof option === 'string' ? option : get(option, optionText))}
      getOptionSelected={(option, v) => get(option, optionValue) === get(v, optionValue)}
      classes={merge(
        { paper: classes.paper, option: classes.option, listbox: classes.listbox },
        overrideClassNames,
      )}
      renderInput={renderInput}
      value={value}
      {...rest}
    />
  );
}

export default Autocomplete;

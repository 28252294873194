import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { MAX_CONTAINER_WIDTH } from 'ui/common/Container';
import { TStylesFooter } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesFooter> =>
    createStyles({
      wrapper: {
        width: '100%',
        position: 'relative',
        minWidth: MAX_CONTAINER_WIDTH,
      },
      footerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: 999,
      },
      fullWrapper: {
        height: theme.spacing(29.125), // 233px
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(7.875, 0, 7, 0),
        display: 'flex',
        justifyContent: 'space-between',
      },
      miniWrapper: {
        width: '100%',
        height: theme.spacing(7.5), // 60px
        backgroundColor: theme.palette.text.secondary,
        padding: theme.spacing(2.625, 0),
      },
      menuSection: {
        display: 'block',

        '&:last-child': {
          marginRight: theme.spacing(130 / 8),
        },
      },
      contactLinkItem: {
        display: 'flex',
      },
      contactItem: {
        marginLeft: theme.spacing(1), // 8px
        marginBottom: theme.spacing(1.75), // 14px
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      labelSection: {
        display: 'flex',
        height: 'fit-content',
        alignItems: 'center',
        marginLeft: theme.spacing(0.625), // 5px
      },
      buttonMenuItem: {
        textAlign: 'start',
        display: 'block',
        padding: 0,
        textTransform: 'initial',
      },
      menuItem: {
        marginBottom: theme.spacing(1.75), // 14px
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      labelContent: {
        maxWidth: theme.spacing(43.75), // 350px
        marginLeft: theme.spacing(2.75), // 22px
      },
      logoSection: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
      },
      termsSection: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
      },
      allRightsSection: {
        display: 'flex',
        marginRight: theme.spacing(0.25),
        flex: 1,
        justifyContent: 'flex-start',
      },
      upImage: {
        transform: theme.direction === 'rtl' ? 'none' : 'scaleX(-1)',
        width: theme.spacing(10),
        height: theme.spacing(10),
        position: 'absolute',
        top: -88,
        left: 10,
      },
      bottomImage: {
        transform: theme.direction === 'rtl' ? 'none' : 'scaleX(-1)',
        width: theme.spacing(100 / 8),
        height: theme.spacing(100 / 8),
        position: 'absolute',
        bottom: -70,
        right: -7,
      },
      showDialog: {
        cursor: 'pointer',
      },
      socialLinkWrapper: {
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        marginRight: theme.spacing(14 / 8),
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      socialLinkItem: {
        height: '18px',
      },
      socialItemIcon: {
        fontSize: '12px',
      },
    }),
);

const INIT_STORE_ACCOUNTS = 'analytics/INIT_STORE_ACCOUNTS';
const PAGE_VIEW = 'analytics/PAGE_VIEW';
const COMPLETE_REGISTRATION = 'analytics/COMPLETE_REGISTRATION';
const FULLY_COMPLETE_REGISTRATION = 'analytics/FULLY_COMPLETE_REGISTRATION';
const USER_LOGIN = 'analytics/USER_LOGIN';
const START_CHECKOUT = 'analytics/START_CHECKOUT';
const PRODUCT_CLICK = 'analytics/PRODUCT_CLICK';
const PROFILE_UPDATE = 'analytics/PROFILE_UPDATE';
const CHANGE_PRODUCT_CARD_MODE = 'analytics/CHANGE_PRODUCT_CARD_MODE';

export {
  INIT_STORE_ACCOUNTS,
  PAGE_VIEW,
  COMPLETE_REGISTRATION,
  START_CHECKOUT,
  PRODUCT_CLICK,
  USER_LOGIN,
  FULLY_COMPLETE_REGISTRATION,
  PROFILE_UPDATE,
  CHANGE_PRODUCT_CARD_MODE,
};

import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';

import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { Box, Button } from '@material-ui/core';

import CTAButton from 'ui/common/buttons/CTAButton';
import Typography from 'ui/common/Typography';

import { useDialog, useMobile, usePreviousOrderItems } from 'hooks';

import Icon from 'ui/common/icons/Icon';
import FixedButton from 'components/mobile/FixedButton';
import GreenLayout from 'ui/common/layout/GreenLayout';
import BackIcon from 'components/common/icons/BackIcon';
import Container from 'ui/common/Container';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import useStyles from './styles';

import { IPreviousOrdersItemsDialog } from './types';

const PreviousOrdersItemsDialog: FC<IPreviousOrdersItemsDialog> = ({
  order,
  onSuccess,
  onBackClick,
}) => {
  const { isMobile } = useMobile();
  const { t } = useTranslation();
  const router = useRouter();
  const { hideDialog } = useDialog();
  const { selectedArray, renderPreviousItemsList, selectAllItems, onSubmitItems } =
    usePreviousOrderItems();

  const lang = useSelector(getLang);

  const classes = useStyles({ lang });

  const backButtonCallback = useCallback(() => {
    // run callback from props or use default callback
    return onBackClick ? onBackClick() : hideDialog();
  }, [hideDialog, onBackClick]);

  useEffect(() => selectAllItems(order?.items), [selectAllItems, order?.items]);

  const defaultSuccessCallback = useCallback(() => {
    hideDialog();
    router.push('/');
  }, [hideDialog, router]);

  const handleSubmitItems = useCallback(() => {
    // run callback from props or use default callback
    onSubmitItems(order?.items, onSuccess || defaultSuccessCallback);
  }, [onSubmitItems, order?.items, onSuccess, defaultSuccessCallback]);

  const renderTitle = useMemo(() => {
    return (
      <>
        <Typography
          variant="body1"
          color={isMobile ? 'textSecondary' : 'light'}
          fontSize={isMobile ? 16 : 18}
          fontWeight="medium"
        >
          {'order.orderNumber'}
        </Typography>
        <Typography
          className={classes.headerText}
          isTranslate={false}
          variant="body1"
          color={isMobile ? 'textSecondary' : 'light'}
          fontSize={isMobile ? 16 : 18}
          fontWeight="medium"
        >
          {order?.nonObfuscatedId}
        </Typography>
      </>
    );
  }, [classes.headerText, isMobile, order?.nonObfuscatedId]);

  const renderMobileHeader = useMemo<ReactNode>(() => {
    return (
      <Box component="section" className={classes.header}>
        <Button
          variant="text"
          className={classes.backButton}
          onClick={backButtonCallback}
          disableRipple
        >
          <BackIcon classes={{ root: classes.backIcon }} color="secondary" />
          <Typography
            variant="body1"
            fontSize={14}
            color="secondary"
            fontWeight="regular"
            className="white-space-no-wrap"
          >
            {'previousOrder.backToOrderList'}
          </Typography>
        </Button>

        <Box display="flex" alignItems="center">
          <Icon classes={{ root: classes.refreshIcon }} name="icon-refresh" color="secondary" />

          <Typography variant="h1" color="secondary" className={classes.loadPreviousOrderTitle}>
            {'button.loadPreviousOrder'}
          </Typography>
        </Box>
      </Box>
    );
  }, [
    classes.backButton,
    classes.backIcon,
    classes.header,
    classes.loadPreviousOrderTitle,
    classes.refreshIcon,
    backButtonCallback,
  ]);

  const renderDesktopHeader = useMemo<ReactNode>(() => {
    return (
      <Box className={classNames(classes.header, classes.root)}>
        <Box className={classes.headerTextRoot}>{renderTitle}</Box>
        <CTAButton
          isTranslate={false}
          disabled={selectedArray.length === 0}
          onClick={handleSubmitItems}
          className={classes.button}
        >
          <Typography style={{ opacity: selectedArray.length === 0 ? 0.4 : 1 }} fontWeight="medium">
            {t('previousOrder.addItems', { count: selectedArray.length })}
          </Typography>
        </CTAButton>
      </Box>
    );
  }, [
    classes.header,
    classes.root,
    classes.headerTextRoot,
    classes.button,
    renderTitle,
    selectedArray.length,
    handleSubmitItems,
    t,
  ]);

  const renderMobileContent = useMemo(() => {
    return (
      <GreenLayout>
        <Container>
          <Box minHeight="100vh" pb={selectedArray.length !== 0 ? 74 / 8 : 0}>
            {renderMobileHeader}
            <Box component="section" className={classes.content}>
              <Box height={51} width="100%" display="flex" alignItems="center" bgcolor="grey.200">
                <Box className={classes.headerTextRoot}>{renderTitle}</Box>
              </Box>
              {order && order.items && renderPreviousItemsList(order?.items, false)}
            </Box>

            {selectedArray.length !== 0 && (
              <FixedButton>
                <CTAButton
                  className={classes.addItemsBtn}
                  fullWidth
                  isTranslate={false}
                  disabled={selectedArray.length === 0}
                  onClick={handleSubmitItems}
                >
                  {t('previousOrder.addItems', { count: selectedArray.length })}
                </CTAButton>
              </FixedButton>
            )}
          </Box>
        </Container>
      </GreenLayout>
    );
  }, [
    classes.addItemsBtn,
    classes.content,
    classes.headerTextRoot,
    handleSubmitItems,
    order,
    renderMobileHeader,
    renderPreviousItemsList,
    renderTitle,
    selectedArray.length,
    t,
  ]);

  return (
    <>
      {isMobile ? (
        renderMobileContent
      ) : (
        <>
          {renderDesktopHeader}
          {order && (
            <Box className={classNames(classes.root, classes.content)}>
              {renderPreviousItemsList(order?.items, false)}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default PreviousOrdersItemsDialog;

import { BaseApi } from 'api/Api/BaseApi';
import { TGetStorePageContent } from './types';

export default class ContentPageService {
  static getStorePageContent: TGetStorePageContent = async (id, xForwardedIpAddress) => {
    return BaseApi.instance().get(`/client/public/content/pages/${id}`, {
      headers: xForwardedIpAddress ? { 'X-Source-Ip': xForwardedIpAddress } : {},
    });
  };
}

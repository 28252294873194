import { TCalculateDistance } from './types';

export const calculateDistance: TCalculateDistance = (lat1, lng1, lat2, lng2) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const { cos } = Math;
  const calculation =
    0.5 -
    cos((lat2 - lat1) * p) / 2 +
    (cos(lat1 * p) * cos(lat2 * p) * (1 - cos((lng2 - lng1) * p))) / 2;

  const result = 12742 * Math.asin(Math.sqrt(calculation)); // 2 * R; R = 6371 km

  return result && Number(result.toFixed(1));
};

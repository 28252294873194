import { Box, Button, IconButton, useTheme } from '@material-ui/core';
import classNames from 'classnames';

import CustomImageLoader from 'components/common/CustomImageLoader';

import { useDialog, useMobile, useProduct } from 'hooks';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import useProductPrice from 'hooks/useProductPrice';
import useRelevancy from 'hooks/useRelevancy';

import { snakeCase } from 'lodash';

import dynamic from 'next/dynamic';

import Image from 'next/image';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { useDispatch } from 'react-redux';
import { ITEMS_PROMOTION_DIALOG } from 'store';

import { productClick } from 'store/modules/analytics/actions';
import ActionsOnCartButton from 'ui/common/ActionsOnCartButton';

import ProductQualityTag from 'ui/common/ProductQualityTag';

import SellingUnitsSelectionButtons from 'ui/common/SellingUnitsSelectionButtons';
import PromotionLimitationsDescription from 'ui/common/Tooltip/PromotionLimitationsDescription';

import Typography from 'ui/common/Typography';

import { TProductCardViewMode } from 'ui/mobile/buttons/ProductCardViewMode/types';
import useStyles from './styles';
import { IStoreProductCard } from './types';

const Tooltip = dynamic(() => import('ui/common/Tooltip'));
const Icon = dynamic(() => import('ui/common/icons/Icon'));
const ProductQuantityTag = dynamic(() => import('ui/desktop/ProductQuantityTag'));
const PromotionTag = dynamic(() => import('ui/common/PromotionTag'));
const HoverStoreProductCard = dynamic(
  () => import('ui/common/StoreProductCard/HoverStoreProductCard/HoverStoreProductCard'),
);

const StoreProductCard: FC<IStoreProductCard> = ({
  storeProductDisplay,
  cardWidth,
  sourceEvent,
  viewMode,
  onProductTooltipClick,
  isTooltipOpen,
  partialKeyboardSupport,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const { isMobile } = useMobile();

  const { renderLanguageField } = useMultiLanguage();

  const {
    handleProductDetailsPopup,
    handleChooseUnit,
    handleChangeStoreProductQuantity,
    handleAddStoreProduct,
  } = useProduct();

  const {
    bagOfProducts,
    commentType,
    displayName,
    fullName,
    id,
    imageUrl,
    productQuality,
    promoted,
    promotion,
    currentRelevancy,
    pricingSellingUnitName,
    price,
    originalPrice,
    cartData,
    activeSellingUnit,
    productExtraDetails,
    currentRelevancyFreeText,
    bagOfProductsJson,
    productSellingUnits,
    product,
    soldByWeight,
    country,
  } = storeProductDisplay;

  const theme = useTheme();

  const { showDialog } = useDialog();

  const { renderRelevancy, relevancyDefaultText } = useRelevancy(currentRelevancy?.name);

  const { pricePerUnit, pricePerOneHundredGram } = useProductPrice();

  const [showHoverSide, setShowHoverSide] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const classes = useStyles({
    cardWidth,
    displayProductQuality: productQuality && productQuality.displayQuality,
    showHoverSide,
    viewMode,
    isMobile,
  });

  const handleShowDetails = useCallback((e) => {
    // avoid link redirect and popup opening
    e.preventDefault();
    e.stopPropagation();

    setShowHoverSide((oldHoverSide) => !oldHoverSide);
  }, []);

  const productHasExtraDetails = useMemo(() => {
    if (bagOfProducts || productExtraDetails || commentType || currentRelevancy) {
      return true;
    }

    if (bagOfProducts && bagOfProductsJson?.selectedItems.length) {
      return true;
    }
  }, [
    bagOfProducts,
    bagOfProductsJson?.selectedItems.length,
    commentType,
    currentRelevancy,
    productExtraDetails,
  ]);

  const productQualityAndTypeDetails = useMemo(() => {
    return (
      <Box className={classNames(classes.qualityTagWrapper, { [classes.hoverEffect]: isMobile })}>
        {productQuality && productQuality.displayQuality && (
          <ProductQualityTag label={renderLanguageField(productQuality.multiLang, 'name')} />
        )}
        {commentType && (
          <Icon classes={{ root: classes.knifeIcon }} color="secondary" name="icon-knife" />
        )}
        {bagOfProducts && (
          <Icon
            classes={{ root: classNames(classes.knifeIcon, classes.basketIcon) }}
            color="secondary"
            name="icon-basket"
          />
        )}
        {country && (
          <Box
            className={classes.countryIcon}
            marginTop="6px"
            display={showHoverSide && productHasExtraDetails && !isMobile ? 'none' : 'flex'}
          >
            <CircleFlag countryCode={country.shortIdentifier} height={isMobile ? '16px' : '18px'} />
          </Box>
        )}
        {isMobile &&
          (productExtraDetails ||
            commentType ||
            currentRelevancy ||
            (bagOfProducts && bagOfProductsJson)) && (
            <IconButton onClick={handleShowDetails} className={classes.infoIcon}>
              <Icon fontSize="inherit" name="icon-info" color="action" />
            </IconButton>
          )}
      </Box>
    );
  }, [
    bagOfProducts,
    bagOfProductsJson,
    classes.basketIcon,
    classes.countryIcon,
    classes.hoverEffect,
    classes.infoIcon,
    classes.knifeIcon,
    classes.qualityTagWrapper,
    commentType,
    country,
    currentRelevancy,
    handleShowDetails,
    isMobile,
    productExtraDetails,
    productHasExtraDetails,
    productQuality,
    renderLanguageField,
    showHoverSide,
  ]);

  const handlePromotionsTooltip = useCallback(
    (e) => {
      // avoid link redirect
      e.preventDefault();
      e.stopPropagation();

      if (!onProductTooltipClick) return;
      onProductTooltipClick(id);
    },
    [onProductTooltipClick, id],
  );

  const promotionTagTemplate = useMemo(() => {
    return (
      <Box className={classes.promotionTagWrapper}>
        <PromotionTag size="large" label={promotion ? 'tag.deal' : 'tag.sale'} />
      </Box>
    );
  }, [classes.promotionTagWrapper, promotion]);

  const handleStoreProductCardClick = useCallback(
    (e) => {
      e.preventDefault();
      setShowTooltip(false);
      setShowHoverSide(false);

      if (handleProductDetailsPopup) handleProductDetailsPopup(id);
      if (isMobile && isTooltipOpen) {
        handlePromotionsTooltip(e);
      }
      dispatch(productClick({ productId: id, sourceEvent }));
    },
    [
      dispatch,
      handleProductDetailsPopup,
      handlePromotionsTooltip,
      id,
      isMobile,
      isTooltipOpen,
      sourceEvent,
    ],
  );

  const addProductToTheCart = useCallback(() => {
    handleAddStoreProduct(id, sourceEvent);
    setShowTooltip(false);
  }, [handleAddStoreProduct, id, sourceEvent]);

  const productImage = useMemo(() => {
    return (
      <Box
        className={classNames(classes.imageWrapper, {
          [classes.imageWrapperHover]: showHoverSide && !isMobile,
        })}
      >
        <CustomImageLoader
          src={imageUrl}
          errorImageSrc="/assets/images/product-image-on-error.svg"
          alt={fullName}
          classes={classNames(productHasExtraDetails && classes.hoverEffect, classes.productImage)}
          loaderComponent={
            <Image
              src="/assets/images/product-image-skeleton.svg"
              layout="fill"
              objectFit="contain"
              className={productHasExtraDetails && classes.hoverEffect}
            />
          }
          unoptimized
        />
      </Box>
    );
  }, [
    classes.hoverEffect,
    classes.imageWrapper,
    classes.imageWrapperHover,
    classes.productImage,
    fullName,
    imageUrl,
    isMobile,
    productHasExtraDetails,
    showHoverSide,
  ]);

  const originalPriceTemplate = useMemo(() => {
    return (
      <Box className={classes.originalPriceWrapper}>
        {pricePerUnit(
          originalPrice as number,
          pricingSellingUnitName,
          classNames(classes.originalPriceAndSellingUnit, 'font-family-heebo'),
          classNames(classes.originalPriceAndSellingUnit, 'font-family-heebo'),
        )}
      </Box>
    );
  }, [
    classes.originalPriceAndSellingUnit,
    classes.originalPriceWrapper,
    originalPrice,
    pricePerUnit,
    pricingSellingUnitName,
  ]);

  const productPrice = useMemo(() => {
    const props = {
      open: isMobile ? isTooltipOpen : showTooltip,
      disableFocusListener: true,
      disableHoverListener: true,
      disableTouchListener: true,
    };

    return (
      <>
        <Box className={classes.priceWrapper}>
          {pricePerUnit(
            price,
            pricingSellingUnitName,
            classNames(classes.price, 'font-family-heebo'),
            classNames(classes.sellingUnit, 'font-family-heebo'),
          )}
        </Box>
        {promoted && originalPrice && originalPriceTemplate}
        {promotion?.shortName && (
          <Box onMouseLeave={() => !isMobile && setShowTooltip(false)}>
            <Tooltip
              PopperProps={{
                disablePortal: true, // for displaying tooltip inside product window
                onClick: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handlePromotionsTooltip(e);
                },
              }}
              {...props}
              initialClasses={{
                tooltip: classNames(classes.tooltip, {
                  [classes.tooltipSingle]: viewMode === 'single',
                }),
                popper: classes.popper,
                touch: classes.touch,
                arrow: classes.arrow,
              }}
              placement="top"
              title={
                <Box>
                  <Box className={classes.tooltipTitle}>{promotion.externalNotes}</Box>
                  {promotion.promotionType.name === 'forNonWeighableAboveQuantity' && (
                    <Button
                      onBlur={() => !isMobile && setShowTooltip(false)}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handlePromotionsTooltip(e);
                        showDialog({
                          dialogType: ITEMS_PROMOTION_DIALOG,
                          contentProps: {
                            promotion,
                          },
                        });
                      }}
                      disableTouchRipple
                      style={{
                        minWidth: '100%',
                        padding: '0px 8px 8px',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography fontSize={13} fontWeight="medium" color="primary">
                        {'incentivePromotion.seeParticipationProducts'}
                      </Typography>
                      <Icon
                        name={theme.direction === 'rtl' ? 'icon-arrow-left' : 'icon-arrow-right'}
                        classes={{ root: classes.arrowIcon }}
                        color="primary"
                      />
                    </Button>
                  )}
                  <PromotionLimitationsDescription promotion={promotion} />
                </Box>
              }
            >
              <Button
                disableRipple
                className={classNames(classes.promotionWrapper, 'padding-0')}
                onClick={(e) => isMobile && handlePromotionsTooltip(e)}
                onMouseOver={() => !isMobile && showHoverSide && setShowTooltip(true)}
                // onMouseLeave={() => !isMobile && setShowTooltip(false)}
                onFocus={() => setShowTooltip(true)} // to show it on accessibility
                onBlur={() => {
                  if (promotion.promotionType.name !== 'forNonWeighableAboveQuantity') {
                    setShowTooltip(false);
                  }
                }}
              >
                <Typography
                  className={classNames(classes.promotionShortName, 'font-family-heebo')}
                  isTranslate={false}
                >
                  {promotion?.shortName}
                </Typography>
                {(isMobile || showHoverSide) && (
                  <Icon
                    name="icon-question-1"
                    fontSize="small"
                    classes={{ root: classes.promotionDescriptionIcon }}
                  />
                )}
              </Button>
            </Tooltip>
          </Box>
        )}
        {!soldByWeight &&
          pricePerOneHundredGram(
            productSellingUnits[0].estimatedUnitWeight,
            price,
            product.liquid,
            classNames(classes.pricePerGram, 'font-family-heebo'),
          )}
      </>
    );
  }, [
    isMobile,
    isTooltipOpen,
    showTooltip,
    classes.priceWrapper,
    classes.price,
    classes.sellingUnit,
    classes.tooltip,
    classes.tooltipSingle,
    classes.popper,
    classes.touch,
    classes.arrow,
    classes.tooltipTitle,
    classes.arrowIcon,
    classes.promotionWrapper,
    classes.promotionShortName,
    classes.promotionDescriptionIcon,
    classes.pricePerGram,
    pricePerUnit,
    price,
    pricingSellingUnitName,
    promoted,
    originalPrice,
    originalPriceTemplate,
    promotion,
    viewMode,
    theme.direction,
    showHoverSide,
    soldByWeight,
    pricePerOneHundredGram,
    productSellingUnits,
    product.liquid,
    showDialog,
    handlePromotionsTooltip,
  ]);

  const productQuantity = useMemo(() => {
    if (!isMobile && cartData && !cartData.orderItem.isRemoved) {
      return (
        <Box className={classes.productQuantity}>
          <ProductQuantityTag
            quantity={cartData.orderItem.requestedQuantity}
            sellingUnitName={renderLanguageField(activeSellingUnit.sellingUnit.multiLang, 'name')}
          />
        </Box>
      );
    }
  }, [
    activeSellingUnit.sellingUnit.multiLang,
    cartData,
    classes.productQuantity,
    isMobile,
    renderLanguageField,
  ]);

  const actionButtons = useMemo(() => {
    return (
      <Box className={classes.actionButtons}>
        <Box className={classes.sellingUnitWrapper}>
          <SellingUnitsSelectionButtons
            height={viewMode === 'single' ? '30px' : '26px'}
            activeSellingUnit={activeSellingUnit}
            productSellingUnits={productSellingUnits}
            onClick={(selectedSellingUnitId) =>
              handleChooseUnit(storeProductDisplay.id, selectedSellingUnitId)
            }
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <ActionsOnCartButton
            count={cartData?.orderItem.isRemoved ? 0 : cartData?.orderItem.requestedQuantity}
            onChangeStoreProductQuantity={(action) =>
              handleChangeStoreProductQuantity &&
              handleChangeStoreProductQuantity(storeProductDisplay.id, action)
            }
            onAddStoreProduct={addProductToTheCart}
          />
        </Box>
      </Box>
    );
  }, [
    activeSellingUnit,
    addProductToTheCart,
    cartData?.orderItem.isRemoved,
    cartData?.orderItem.requestedQuantity,
    classes.actionButtons,
    classes.sellingUnitWrapper,
    handleChangeStoreProductQuantity,
    handleChooseUnit,
    productSellingUnits,
    storeProductDisplay.id,
    viewMode,
  ]);

  const hoverTemplate = useMemo(() => {
    return (
      <Box className={classes.hoverWrapper}>
        {isMobile && (
          <IconButton onClick={handleShowDetails} className={classes.closeIconWrap}>
            <Icon fontSize="inherit" name="icon-button-x" color="primary" />
          </IconButton>
        )}
        <HoverStoreProductCard
          storeProductId={id}
          commentType={commentType}
          bagOfProducts={bagOfProducts}
          renderRelevancy={renderRelevancy}
          currentRelevancyFreeText={
            (currentRelevancy && currentRelevancyFreeText) || relevancyDefaultText
          }
          priceTemplate={productPrice}
          viewMode={viewMode as TProductCardViewMode}
          actionButtons={actionButtons}
        />
      </Box>
    );
  }, [
    classes.hoverWrapper,
    classes.closeIconWrap,
    isMobile,
    handleShowDetails,
    id,
    commentType,
    bagOfProducts,
    renderRelevancy,
    currentRelevancy,
    currentRelevancyFreeText,
    relevancyDefaultText,
    productPrice,
    viewMode,
    actionButtons,
  ]);

  const productMainPart = useMemo(() => {
    return (
      <div
        className={classes.productMainWrapper}
        ref={ref}
        onFocus={() => !isMobile && setShowHoverSide(true)}
      >
        <Box
          className={classes.mouseOverWrapper}
          onMouseOver={() => !isMobile && setShowHoverSide(true)}
          onMouseLeave={() => {
            if (!isMobile) {
              setShowHoverSide(false);
              setShowTooltip(false);
            }
          }}
        >
          <Box className={classes.productCardWrapper}>
            <Box className={classes.mainPartWrapper}>
              {(promotion || promoted) && promotionTagTemplate}
              {productQualityAndTypeDetails}
              <a
                href={`/product-details/${id}/${snakeCase(fullName)}`}
                className={classes.linkWrapper}
                tabIndex={-1}
              >
                <Button
                  disableRipple
                  onClick={handleStoreProductCardClick}
                  className={classes.buttonOpenProductWindow}
                >
                  {productImage}
                  {!showHoverSide && (
                    <>
                      <Box className={classNames(classes.detailsWrapper)}>
                        <Box>
                          <Typography
                            isTranslate={false}
                            variant="body1"
                            className={classes.productName}
                          >
                            {displayName}
                          </Typography>
                          {renderRelevancy}
                        </Box>
                      </Box>
                      <Box className={classes.productPrice}>{productPrice}</Box>
                    </>
                  )}
                </Button>
              </a>
              {showHoverSide && hoverTemplate}
              {isMobile && <Box className={classes.bottomMobilePart}>{actionButtons}</Box>}
              {productQuantity}
            </Box>
          </Box>
        </Box>
      </div>
    );
  }, [
    actionButtons,
    classes.bottomMobilePart,
    classes.buttonOpenProductWindow,
    classes.detailsWrapper,
    classes.linkWrapper,
    classes.mainPartWrapper,
    classes.mouseOverWrapper,
    classes.productCardWrapper,
    classes.productMainWrapper,
    classes.productName,
    classes.productPrice,
    displayName,
    fullName,
    handleStoreProductCardClick,
    hoverTemplate,
    id,
    isMobile,
    productImage,
    productPrice,
    productQualityAndTypeDetails,
    productQuantity,
    promoted,
    promotion,
    promotionTagTemplate,
    renderRelevancy,
    showHoverSide,
  ]);

  const handeFocusOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.relatedTarget)) {
        setShowHoverSide(false);
      }
    },
    [ref],
  );

  useEffect(() => {
    if (ref.current && !isMobile && !partialKeyboardSupport) {
      const currentRef = ref.current;
      currentRef.addEventListener('focusout', handeFocusOutside);
      return () => {
        currentRef.removeEventListener('focusout', handeFocusOutside);
      };
    }
  }, [handeFocusOutside, isMobile, ref, partialKeyboardSupport]);

  return productMainPart;
};

export default StoreProductCard;

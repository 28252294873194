import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesLink } from './types';

export default makeStyles(
  (): StyleRules<TStylesLink> =>
    createStyles({
      link: {
        textDecoration: 'none',
      },
    }),
);

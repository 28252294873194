import { BaseApi } from 'api/Api';
import { TGetPersonalDetails, TResendEmail, TUpdatePersonalDetails } from './types';

export default class PersonalDetailsService {
  static getPersonalDetails: TGetPersonalDetails = async () => {
    return BaseApi.instance().get('/client/client/personal-details/get');
  };

  static updatePersonalDetails: TUpdatePersonalDetails = async (body) => {
    return BaseApi.instance({ handleError: false }).post(
      '/client/client/personal-details/update',
      body,
    );
  };

  static resendEmail: TResendEmail = async () => {
    return BaseApi.instance().post('/client/client/personal-details/update/email/resend');
  };
}

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { IClientForm } from 'components/common/forms/ClientForm/types';
import { TStylesDeliveryTabForm } from '../types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDeliveryTabForm, { type: IClientForm['type'] }> =>
    createStyles({
      submitButtonSection: {
        [theme.breakpoints.up('md')]: {
          width: '338px',
        },
      },
      link: {
        fontSize: theme.spacing(13 / 8),
        fontWeight: 500,
        color: theme.palette.primary.main,
        lineHeight: '15px',
      },
      arrowRightIcon: {
        fontSize: theme.spacing(10 / 8),
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2 / 8),
      },
      linkWrapper: {
        cursor: 'pointer',
        padding: 0,
      },
      receivesMessagesLabel: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        lineHeight: 1.29,
      },
      iconButton: {
        borderRadius: '6px',
        border: 'solid 1.5px #3c6',
        padding: '0px',
      },
      iconButtonActive: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
      },
      iconButtonLabel: {
        padding: '8px',
        flexDirection: 'column',
      },
      iconButtonIcon: {
        fontSize: '22px',
      },
      iconButtonDescription: {
        color: theme.palette.grey.A700,
        fontSize: '14px',
        lineHeight: '18px',
        width: '74px',
      },
      iconButtonDescriptionActive: {
        color: theme.palette.background.paper,
        fontWeight: 500,
      },
      fieldLabel: {
        color: theme.palette.grey[600],
        fontSize: '13px',
        lineHeight: '15px',
      },
      sliderWrapper: ({ type }) => ({
        margin: theme.spacing(0, type === 'edit_details' ? -20 / 8 : -14 / 8),
        display: 'flex',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'transparent !important',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent !important',
          borderRadius: 'none',
        },
      }),
      couldNotLocateAddress: {
        fontSize: '14px',
        lineHeight: '16px',
        color: theme.palette.error.main,
        marginBottom: '16px',
        [theme.breakpoints.up('md')]: {
          marginBottom: '14px',
        },
      },
      markLocationOnTheMapButton: {
        marginTop: theme.spacing(16 / 8),
        padding: 0,
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(24 / 8),
        },
      },
      markLocationOnTheMap: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 500,
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(4 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: '13px',
          lineHeight: '16px',
        },
      },
    }),
);

import { Box } from '@material-ui/core';
import { TFinalCostData } from 'components/common/checkout/CheckoutStep3/components/types';
import { useCart } from 'hooks/useCart';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { prepareToPlaceOrderSelectors } from 'store';
import TooltipOnIcon from 'ui/common/TooltipOnIcon';
import Typography from 'ui/common/Typography';
import { shouldOrderHandlingFeeBeCharged } from 'utils/helpers/cartEstimation';
import { calculateDiscountTotalPrice } from 'utils/helpers/promotions/priceCalculation';
import useStyles from './styles';
import { TUseFinalCostData } from './types';

const useFinalCostData: TUseFinalCostData = (
  serviceAreaUsageFees,
  orderType,
  orderDiscounts,
  courierTip,
  orderHandlingFee,
) => {
  const classes = useStyles();

  const { itemsEstimation, itemsEstimationForCheckout, cartEstimation } = useCart();

  const { orderMinTotalValue, orderHandlingFeeThreshold } = useSelector(
    prepareToPlaceOrderSelectors.getPrepareToPlaceOrder,
  );

  const { t } = useTranslation();

  const totalServiceAreaDiscounts = useMemo(() => {
    return orderDiscounts.reduce(
      (total, discount) =>
        discount.discountType.name === 'serviceAreaUsageFeesDiscount'
          ? total + discount.totalPrice
          : total,
      0,
    );
  }, [orderDiscounts]);

  const serviceAreaDiscountLabel = useMemo(() => {
    const relevantDiscounts = orderDiscounts.filter(
      (discount) => discount.discountType.name === 'serviceAreaUsageFeesDiscount',
    );

    if (relevantDiscounts.length === 1 && relevantDiscounts[0].discountSourceType) {
      return `${t(`discount.${orderType}UsageFees`)} (${t(
        `discount.${relevantDiscounts[0].discountSourceType.name}`,
      )})`;
    }

    return `discount.${orderType}UsageFees`;
  }, [orderDiscounts, orderType, t]);

  const clientCouponCreditedAtCheckout = useMemo(() => {
    const relevantDiscounts = orderDiscounts.filter(
      (discount) =>
        discount.creditedAtCheckout && discount.discountSourceType.name === 'clientCoupon',
    );

    const template = (
      <Box display="flex">
        <Typography className={classes.creditAtCheckoutDescription}>
          {'discount.payment'}
        </Typography>
        <TooltipOnIcon
          tooltipTitle="discount.creditAtCheckoutDescription"
          tooltipPlacement="top"
          initialClasses={{ tooltip: classes.tooltip }}
        />
      </Box>
    );

    return {
      template,
      price: +relevantDiscounts
        .reduce((total, discount) => total + -Math.abs(parseFloat(discount.valueFormula)), 0)
        .toFixed(2),
    };
  }, [classes.creditAtCheckoutDescription, classes.tooltip, orderDiscounts]);

  const generalDiscountOriginatedFromStoreCoupon = useMemo(() => {
    const relevantDiscount = orderDiscounts.filter(
      (discount) =>
        discount.discountType.name === 'generalDiscount' &&
        discount.discountSourceType.name === 'storeCoupon',
    )[0];

    if (!relevantDiscount) return;

    // TODO check if we can remove discountFromTotalPrice and instead use relevantDiscounts[0].totalPrice
    const discountFromTotalPrice = calculateDiscountTotalPrice(
      relevantDiscount.valueFormula,
      relevantDiscount.valueFormulaType,
      itemsEstimationForCheckout,
    );

    return {
      label: `${t('discount.couponDiscount')} (${t(
        `${
          relevantDiscount.creditedAtCheckout
            ? 'discount.payment'
            : `checkout.coupon.${relevantDiscount.discountType.name}`
        }`,
      )})`,
      price: relevantDiscount.creditedAtCheckout
        ? -Math.abs(parseFloat(relevantDiscount.valueFormula))
        : +discountFromTotalPrice.toFixed(2),
      creditedAtCheckout: relevantDiscount.creditedAtCheckout,
    };
  }, [itemsEstimationForCheckout, orderDiscounts, t]);

  const itemRefundOriginatedFromStoreCoupon = useMemo(() => {
    const relevantDiscounts = orderDiscounts.filter(
      (discount) =>
        discount.discountSourceType.name === 'storeCoupon' &&
        discount.discountType.name === 'itemRefund',
    );

    if (!relevantDiscounts.length) return;

    return {
      label: `${t('discount.couponDiscount')} (${t(
        `checkout.coupon.${relevantDiscounts[0].discountType.name}`,
      )})`,
      price: relevantDiscounts[0].totalPrice,
    };
  }, [orderDiscounts, t]);

  const finalCostData = useMemo<TFinalCostData[]>(() => {
    const finalCostArray = [
      {
        id: 1,
        label: 'topCard.totalShoppingCart',
        price: itemsEstimationForCheckout,
      },
      {
        id: 8,
        label: 'order.totalToPay',
        price: cartEstimation,
        total: true,
      },
    ] as TFinalCostData[];

    if (generalDiscountOriginatedFromStoreCoupon) {
      finalCostArray.splice(-1, 0, {
        id: 2,
        label: generalDiscountOriginatedFromStoreCoupon.label,
        price: generalDiscountOriginatedFromStoreCoupon.price,
        typographyColor: 'error',
      });
    }

    if (itemRefundOriginatedFromStoreCoupon) {
      finalCostArray.splice(-1, 0, {
        id: 2,
        label: itemRefundOriginatedFromStoreCoupon.label,
        price: itemRefundOriginatedFromStoreCoupon.price,
        typographyColor: 'error',
      });
    }

    if (clientCouponCreditedAtCheckout && clientCouponCreditedAtCheckout.price) {
      finalCostArray.splice(-1, 0, {
        id: 3,
        template: clientCouponCreditedAtCheckout.template,
        price: clientCouponCreditedAtCheckout.price,
        typographyColor: 'error',
      });
    }

    if (
      shouldOrderHandlingFeeBeCharged(
        itemsEstimation,
        orderMinTotalValue,
        orderHandlingFeeThreshold,
      ) &&
      orderHandlingFee &&
      orderHandlingFee > 0
    ) {
      finalCostArray.splice(-1, 0, {
        id: 4,
        label: 'checkout.handlingFee',
        price: orderHandlingFee,
      });
    }

    if (serviceAreaUsageFees) {
      finalCostArray.splice(-1, 0, {
        id: 5,
        label: `serviceAreaUsageFees.${orderType}`,
        price: serviceAreaUsageFees,
      });
    }

    if (totalServiceAreaDiscounts !== 0) {
      finalCostArray.splice(-1, 0, {
        id: 6,
        label: serviceAreaDiscountLabel,
        price: totalServiceAreaDiscounts,
        typographyColor: 'error',
      });
    }

    if (courierTip && courierTip > 0) {
      finalCostArray.splice(-1, 0, {
        id: 7,
        label: 'checkout.tipForCourier',
        price: courierTip,
      });
    }

    return finalCostArray;
  }, [
    itemsEstimationForCheckout,
    cartEstimation,
    generalDiscountOriginatedFromStoreCoupon,
    itemRefundOriginatedFromStoreCoupon,
    serviceAreaUsageFees,
    totalServiceAreaDiscounts,
    courierTip,
    itemsEstimation,
    orderMinTotalValue,
    orderHandlingFeeThreshold,
    orderHandlingFee,
    clientCouponCreditedAtCheckout,
    orderType,
    serviceAreaDiscountLabel,
  ]);

  return {
    finalCostData,
    generalDiscountOriginatedFromStoreCoupon,
  };
};

export default useFinalCostData;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from '@redux-saga/core/effects';
import { getDay, isBefore, parse } from 'date-fns';
import i18next from 'i18next';
import { toNumber } from 'lodash';
import Router from 'next/router';
import {
  catalogActions,
  dialogActions,
  ICheckAndFetchBranchCatalogIfNecessaryRequest,
  IFetchCatalogBySubCatalogId,
  IUpdateMembershipBenefits,
  IValidateAndEditOrder,
  ORDER_EDITING_IS_NOT_POSSIBLE_DIALOG,
  TMembershipBenefits,
  TOrderDetailsReducerState,
  VALIDATE_AND_EDIT_ORDER,
} from 'store';
import { IShowNotify } from 'store/modules/notify';
import { showNotification } from 'store/modules/notify/actions';
import { initialOrderState, IOrderUpdate } from 'store/modules/order';
import { orderUpdateAction } from 'store/modules/order/actions';
import { initialOrderDetailsState, IUpdateOrderDetails } from 'store/modules/orderDetails';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import {
  IClientCouponsUpdate,
  IGetStaticPromotions,
  IUpdateStaticPromotions,
} from 'store/modules/orderDiscounts';
import {
  clientCouponsUpdate,
  getStaticPromotions,
  updateMembershipBenefits,
  updateStaticPromotions,
} from 'store/modules/orderDiscounts/actions';
import { OPEN_ORDER_FOR_EDITING, STOP_ORDER_EDITING } from 'store/modules/orderEditing/constants';
import { IOpenOrderForEditing } from 'store/modules/orderEditing/types';
import {
  initialPrepareToPlaceOrderState,
  IPrepareToPlaceOrderSuccess,
} from 'store/modules/prepareToPlaceOrder';
import { prepareToPlaceOrderSuccess } from 'store/modules/prepareToPlaceOrder/actions';
import { IUpdateOutOfStockProducts } from 'store/modules/storeProduct';
import { updateOutOfStockProducts } from 'store/modules/storeProduct/actions';
import { storeProductsData } from 'store/modules/storeProduct/selectors';
import { IFetchAndUpdateClientCompensationsRequest } from 'store/modules/user';
import { fetchAndUpdateClientCompensationsRequest } from 'store/modules/user/actions';
import { IWebsiteDetails } from 'store/modules/website';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import { getPersistor } from 'store/useStore/persist';
import {
  TCouponServer,
  TOrderItemDTO,
  TOrderMembershipBenefits,
  TProductSellingUnitServer,
  TSourceEventServer,
  TStoreProductServer,
  TUserBenefit,
} from 'types';
import { dateFormatInOrderHistory } from 'utils/constants';
import {
  filterItemsOutOfStock,
  filterUnavailableItems,
  prepareOutOfStockProducts,
} from 'utils/helpers/order/items';
import { prepareCoupons } from 'utils/helpers/promotions/prepareClientCoupons';
import { prepareStoreProductSource } from 'utils/helpers/storeProduct';
import { IGetPromotionsRequest, IPromotion } from '../promotions';
import { openOrderForEditing } from './actions';

function* openOrderForEditingSaga({
  payload,
}: IOpenOrderForEditing): Generator<
  | SelectEffect
  | PutEffect<
      | IUpdateOutOfStockProducts
      | IOrderUpdate
      | IPrepareToPlaceOrderSuccess
      | IUpdateOrderDetails
      | IShowNotify
      | IGetPromotionsRequest
      | IGetStaticPromotions
      | IClientCouponsUpdate
      | IFetchCatalogBySubCatalogId
      | ICheckAndFetchBranchCatalogIfNecessaryRequest
      | IUpdateMembershipBenefits
    >
  | CallEffect,
  void,
  any
> {
  const editedOrder = payload.order;

  const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);

  const { storeProductById, ancestorStoreProductById } = yield select(storeProductsData);

  const { itemsOutOfStock } = filterItemsOutOfStock(
    editedOrder.items,
    storeProductById,
    ancestorStoreProductById,
  );

  const { outOfStockStoreProductIds, outOfStockStoreProductById } = prepareOutOfStockProducts(
    itemsOutOfStock,
    websiteDetails.store.performSellingUnitsEstimationLearning,
  );

  yield put(updateOutOfStockProducts(outOfStockStoreProductIds, outOfStockStoreProductById));

  const { availableItems, unavailableItems } = filterUnavailableItems(editedOrder.items);

  const relevantCoupons: TCouponServer[] = editedOrder.coupons
    ? editedOrder.coupons
        .filter((coupon) => coupon.discountSourceType.name === 'clientCoupon')
        .map((coupon) => ({
          discountType: coupon.discountType,
          externalNotes: coupon.externalNotes,
          valueFormula: coupon.valueFormula,
          storeProductSource: coupon.storeProductSource,
          valueFormulaSourceQuantity: coupon.valueFormulaSourceQuantity,
          valueFormulaSourceName: coupon.valueFormulaSourceName,
          valueFormulaType: coupon.valueFormulaType,
          evaluationPhase: coupon.evaluationPhase,
          creditedAtCheckout: coupon.creditedAtCheckout,
          appliedIfProductNotInCart: coupon.appliedIfProductNotInCart,
        }))
    : []; // refund

  const relevantPromotions: IPromotion[] = editedOrder.coupons
    ? editedOrder.coupons
        .filter(
          (coupon) =>
            !coupon.originatedFromPromotionAtCheckout &&
            coupon.discountSourceType.name === 'storePromotion',
        )
        .map((promotion) => ({
          discountType: promotion.discountType,
          externalNotes: promotion.externalNotes,
          valueFormula: promotion.valueFormula,
          valueFormulaSourceQuantity: promotion.valueFormulaSourceQuantity,
          valueFormulaSourceName: promotion.valueFormulaSourceName,
          valueFormulaType: promotion.valueFormulaType,
          parameters: promotion.dynamicParameters && JSON.parse(promotion.dynamicParameters),
          name: promotion.originDescription,
          shortName: promotion.originDescription,
          nonObfuscatedId: promotion.promotion && promotion.promotion.id,
          promotionType: promotion.promotionType,
          evaluationPhase: promotion.evaluationPhase,
          expirationTime: promotion.expirationTime,
          estimatedValue: promotion.estimatedValue,
          skipPromotionValidation: true,
          appliedIfProductNotInCart: promotion.appliedIfProductNotInCart,
          discountSourceType: promotion.discountSourceType,
          storeProductSource: prepareStoreProductSource(promotion.storeProductSource),
        }))
    : []; // contain promotion forNewCustomer

  const membershipBenefits = editedOrder.coupons
    ? editedOrder.coupons.filter((coupon) => coupon.discountSourceType.name === 'external')
    : [];

  if (membershipBenefits.length) {
    const promotionsMap: TMembershipBenefits['requestedBenefits'] = {};
    let pointsToUse: number = 0;

    membershipBenefits.forEach((coupon) => {
      const externalResponse: TOrderMembershipBenefits =
        coupon.dynamicParameters &&
        JSON.parse(coupon.dynamicParameters) &&
        JSON.parse(JSON.parse(coupon.dynamicParameters)?.metadata);

      if (!externalResponse) return;

      if (externalResponse.requestedBenefits && externalResponse.requestedBenefits.length) {
        externalResponse.requestedBenefits.forEach((externalPromotion) => {
          promotionsMap[externalPromotion.id] = externalPromotion as TUserBenefit;
        });
      }

      if (externalResponse.budgetToUse) {
        pointsToUse = externalResponse.budgetToUse;
      }
    });

    yield put(
      updateMembershipBenefits({
        budgetToUse: toNumber(pointsToUse || 0),
        requestedBenefits: promotionsMap,
      }),
    );
    yield put(getStaticPromotions(relevantPromotions));
  }

  yield put(
    orderUpdateAction({
      id: editedOrder.id,
      nonObfuscatedId: editedOrder.nonObfuscatedId,
      items: availableItems.map((item) => {
        const storeProductItem =
          storeProductById[item.storeProduct?.id as TStoreProductServer['id']] ||
          outOfStockStoreProductById[item.storeProduct?.id as TStoreProductServer['id']] ||
          storeProductById[item.storeProduct?.ancestor?.id as TStoreProductServer['id']] ||
          outOfStockStoreProductById[
            item.storeProduct?.ancestor?.id as TStoreProductServer['id']
          ] ||
          ancestorStoreProductById[item.storeProduct?.id as TStoreProductServer['id']] ||
          ancestorStoreProductById[item.storeProduct?.ancestor?.id as TStoreProductServer['id']];
        const populatedItem: TOrderItemDTO = {
          id: item.id,
          storeProduct: {
            ...storeProductItem,
          },
          requestedQuantity: item.requestedQuantity as number,
          requestedSellingUnit: {
            id: item.requestedSellingUnit?.id as TProductSellingUnitServer['id'],
          },
          productComment: item.productComment,
          sourceEvent: item.sourceEvent
            ? item.sourceEvent.name
            : ('unknown' as TSourceEventServer['name']),
        };

        if (item.bagOfProducts) {
          const bagOfProductsJsonParsed = JSON.parse(item.bagOfProductsJson);
          populatedItem.selectedBagItems = bagOfProductsJsonParsed.selectedItems;
        }

        return populatedItem;
      }),
      comments: editedOrder.comments,
      paymentMethodType: editedOrder.deferredPaymentType || editedOrder.paymentMethodType,
      itemsSubstitution: editedOrder.itemsSubstitution && editedOrder.itemsSubstitution.name,
      ecoPackaging: editedOrder.ecoPackaging,
      subCatalog: editedOrder?.subCatalog?.id,
    }),
  );

  yield put(
    prepareToPlaceOrderSuccess({
      ...initialPrepareToPlaceOrderState,
      encryptedOrderToken: editedOrder.id,
      orderMinTotalValue: editedOrder.storeServiceArea.orderMinTotalValue, // TODO bags
      serviceAreaUsageFees: editedOrder.serviceAreaUsageFees,
      orderHandlingFee: editedOrder.storeServiceArea.orderHandlingFee,
      orderHandlingFeeThreshold: editedOrder.storeServiceArea.orderHandlingFeeThreshold,
    }),
  );

  yield put(clientCouponsUpdate(prepareCoupons(relevantCoupons)));

  yield put(
    updateOrderDetails({
      orderMode: 'edit',
      orderType: editedOrder.orderType.name,
      selfPickupLocation:
        editedOrder.orderType.name === 'selfPickup'
          ? {
              name: editedOrder.storeServiceArea.name,
              storeServiceAreaId: editedOrder.storeServiceArea.id,
            }
          : undefined,
      orderTime: `${editedOrder.deliveryDate} ${editedOrder.deliveryHour}`,
      orderDate: editedOrder.deliveryDate,
      orderHour: editedOrder.deliveryHour,
      preferredDay:
        getDay(parse(editedOrder.deliveryDate, dateFormatInOrderHistory, new Date())) || 7, // TODO: getDay will return 0 for sunday, we will change it for 7 like for preferred day in all others places. Ask Aviad if he can send
      lastActivityTime: new Date().getTime(),
      subscription: editedOrder.subscription,
      courierTip: editedOrder.courierTip,
      limitationByAgeChecked: editedOrder.items.some(
        (item) => item.storeProduct?.product.limitedByAge,
      ),
      orderAddress: `${editedOrder.address}, ${editedOrder.city.name}`,
    }),
  );

  yield call(Router.push, '/');
  yield put(catalogActions.checkAndFetchBranchCatalogIfNecessary());

  if (unavailableItems.length > 0) {
    yield put(
      showNotification({
        message: i18next.t('editOrder.removedItemsExcludedFromCatalog', {
          removedItems: unavailableItems.map((item) => item.storeProduct?.fullName).join(', '),
        }),
      }),
    );
  }
}

export function* stopOrderEditing(): Generator<
  | SelectEffect
  | PutEffect<
      | IUpdateOutOfStockProducts
      | IOrderUpdate
      | IPrepareToPlaceOrderSuccess
      | IUpdateOrderDetails
      | IUpdateStaticPromotions
      | IFetchAndUpdateClientCompensationsRequest
      | ICheckAndFetchBranchCatalogIfNecessaryRequest
      | IUpdateMembershipBenefits
    >
  | CallEffect,
  void,
  any
> {
  const persistor = getPersistor();
  const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
  persistor.persist();

  yield put(updateOutOfStockProducts([], {}));
  yield put(updateStaticPromotions({}, []));
  yield put(updateMembershipBenefits(undefined));

  yield put(
    orderUpdateAction({
      ...initialOrderState,
      itemsSubstitution: websiteDetails.websiteSettings.itemsSubstitution
        ? 'substituteWithSimilar'
        : undefined,
    }),
  );

  yield put(
    prepareToPlaceOrderSuccess({
      ...initialPrepareToPlaceOrderState,
    }),
  );

  yield put(fetchAndUpdateClientCompensationsRequest());

  yield put(
    updateOrderDetails({
      ...initialOrderDetailsState,
      orderType: websiteDetails.websiteSettings.defaultOrderType,
    }),
  );

  yield put(catalogActions.checkAndFetchBranchCatalogIfNecessary());
}

export function* validateAndEditOrder({
  payload,
}: IValidateAndEditOrder): Generator<SelectEffect | PutEffect | CallEffect, void, any> {
  const { order, onEditStart } = payload;
  const orderMode: TOrderDetailsReducerState['orderMode'] = yield select(getOrderMode);

  const isEditingPossible = isBefore(new Date().getTime(), order.maxOrderUpdateTimeAllowed);

  if (!isEditingPossible) {
    yield put(
      dialogActions.showDialog({
        dialogType: ORDER_EDITING_IS_NOT_POSSIBLE_DIALOG,
      }),
    );

    if (orderMode === 'edit') {
      yield call(stopOrderEditing);
    }
    return;
  }

  if (onEditStart) {
    yield call(onEditStart);
  }

  yield put(openOrderForEditing(order));
}

function* rootOrderEditingSaga(): Generator {
  yield takeEvery(OPEN_ORDER_FOR_EDITING, openOrderForEditingSaga);
  yield takeEvery(STOP_ORDER_EDITING, stopOrderEditing);
  yield takeEvery(VALIDATE_AND_EDIT_ORDER, validateAndEditOrder);
}

export default rootOrderEditingSaga;

import { BaseApi } from 'api/Api';
import { TGetClubMembershipProfile } from './types';

export default class ClientMembershipService {
  static getClubMembershipProfile: TGetClubMembershipProfile = async (config) => {
    return BaseApi.instance({ handleError: false, ...config }).get(
      '/client/client/membership/external/info',
    );
  };
}

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useProduct } from 'hooks/useProduct';
import ProductDetails from 'ui/common/ProductDetails';
import { Box, useTheme } from '@material-ui/core';
import Container from 'ui/common/Container';
import GreenLayout from 'ui/common/layout/GreenLayout';
import { useMobile, useWebsiteDetails } from 'hooks';
import { IProductDetailsDialog } from './types';
import useStyles from './styles';

const ProductDetailsDialog: FC<IProductDetailsDialog> = (props) => {
  const {
    handleChooseUnit,
    handleAddStoreProduct,
    handleChangeStoreProductQuantity,
    handleProductDetailsPopup,
    getDisplayableStoreProduct,
  } = useProduct();

  const { isMobile } = useMobile();
  const theme = useTheme();
  const websiteDetails = useWebsiteDetails();

  const classes = useStyles();

  const displayableStoreProduct = useMemo(() => {
    return getDisplayableStoreProduct(props.storeProductId);
  }, [getDisplayableStoreProduct, props.storeProductId]);

  const handlePopupScrollTop = useCallback(() => {
    const dialogScrollElement = document.getElementsByClassName(
      isMobile ? 'MuiDialog-paperScrollBody' : 'MuiDialogContent-root',
    )[0];

    if (dialogScrollElement) {
      dialogScrollElement.scrollTo({ top: 0 });
    }
  }, [isMobile]);

  useEffect(() => {
    if (props.storeProductId) handlePopupScrollTop();
  }, [handlePopupScrollTop, props.storeProductId]);

  const content = useMemo(() => {
    return (
      <Container className={classes.productContentContainer}>
        <Box className={classes.productContentWrapper}>
          {displayableStoreProduct && (
            <ProductDetails
              {...displayableStoreProduct}
              onChooseUnit={handleChooseUnit}
              onAddProduct={handleAddStoreProduct}
              onChangeProductCount={handleChangeStoreProductQuantity}
              handleProductDetailsPopup={handleProductDetailsPopup}
              showSimilarItems={!websiteDetails.websiteSettings.isSmallEC}
              isPopup
            />
          )}
        </Box>
      </Container>
    );
  }, [
    classes.productContentContainer,
    classes.productContentWrapper,
    displayableStoreProduct,
    handleAddStoreProduct,
    handleChangeStoreProductQuantity,
    handleChooseUnit,
    handleProductDetailsPopup,
    websiteDetails.websiteSettings.isSmallEC,
  ]);

  return (
    <Box className={classes.productDetailsDialog}>
      {isMobile ? (
        <GreenLayout backgroundColor={theme.palette.grey[50]}>{content}</GreenLayout>
      ) : (
        content
      )}
    </Box>
  );
};

export default ProductDetailsDialog;

import React, { FC } from 'react';

import classNames from 'classnames';

import NextLink from 'next/link';

import useStyles from './styles';

import { ILink } from './types';

const Link: FC<ILink> = ({ children, className, style, targetBlank, ...rest }) => {
  const classes = useStyles();

  return (
    <NextLink {...rest}>
      <a
        style={style}
        className={classNames(classes.link, className)}
        target={targetBlank ? '_blank' : '_self'}
        aria-current={rest.ariaCurrent || undefined}
      >
        {children}
      </a>
    </NextLink>
  );
};

export default Link;

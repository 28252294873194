import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesSnackbar } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSnackbar> =>
    createStyles({
      root: {
        minHeight: theme.spacing(48 / 8),
        backgroundColor: theme.palette.text.secondary,
        borderRadius: theme.spacing(0.75), // 6px
        padding: theme.spacing(1.75, 2), // 14px 16px,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
        zIndex: 9999,
      },
      contentsPropsRoot: {
        backgroundColor: theme.palette.text.secondary,
        boxShadow: 'none',
        fontSize: theme.spacing(2), // 16px
        padding: 0,
      },
      buttonRoot: {
        color: theme.palette.grey.A700,
      },
      message: {
        padding: 0,
      },
    }),
);

import { alpha, createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesMenuDrawer } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMenuDrawer> =>
    createStyles({
      root: {},
      backdropRoot: {
        backgroundColor: alpha(theme.palette.common.black, 0.8), //  'rgba(0, 0, 0, 0.8)'
      },
      paper: {
        minWidth: '270px',
        background: theme.palette.grey[50],
        '-webkit-overflow-scrolling': 'auto',
      },
      closeButton: {
        position: 'fixed',
        top: 2,
        right: 5,
        zIndex: theme.zIndex.modal + 1, // 1301
        fontSize: '20px',
      },
      header: {
        backgroundColor: theme.palette.primary.main,
        display: 'block',
        padding: theme.spacing(20 / 8, 24 / 8),
      },
      userIcon: {
        fontSize: '32px',
      },
      userMenuIcon: {
        fontSize: '18px',
        marginRight: theme.spacing(12 / 8),
        color: theme.palette.text.secondary,
      },
      userMenuIconSelected: { color: theme.palette.primary.main },
      dropDownRoot: {
        paddingLeft: theme.spacing(5 / 8),
      },
      dropDownIcon: {
        fontSize: theme.spacing(14 / 8),
        color: theme.palette.primary.main,
      },
      dropDownOpened: {
        transform: 'rotate(180deg)',
      },
      moreButton: {
        position: 'relative',
        cursor: 'pointer',
      },
      listRoot: {
        padding: 0,
        overflowY: 'auto',
        height: '100%',
      },
      categoryIcon: {
        marginRight: theme.spacing(12 / 8),
        fontSize: '20px',
      },
      rootListItemFocus: {
        padding: theme.spacing(16 / 8, 24 / 8, 16 / 8, 30 / 8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '52px',
        '&:focus': {
          width: '246px',
          borderRadius: '6px',
          backgroundColor: theme.palette.grey.A100,
          color: theme.palette.text.secondary,
          margin: '0 auto',
          padding: '22px 12px !important',
        },
        '&$selectedCategoryItem, &$selectedCategoryItem:focus': {
          backgroundColor: 'inherit',
        },
      },
      rootListItem: {
        padding: theme.spacing(16 / 8, 24 / 8, 16 / 8, 30 / 8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&$selectedCategoryItem, &$selectedCategoryItem:focus': {
          backgroundColor: 'inherit',
        },
      },
      rootSubCategoryMenuItem: {
        paddingTop: theme.spacing(12 / 8),
        paddingRight: theme.spacing(24 / 8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&$selectedCategoryItem, &$selectedCategoryItem:focus': {
          backgroundColor: 'inherit',
        },
      },
      rootSubCategory: {
        paddingLeft: theme.spacing(42 / 8),
        '&:hover': {
          backgroundColor: 'inherit !important',
        },
      },
      rootListItemCategory: {
        padding: theme.spacing(16 / 8, 24 / 8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&$selectedCategoryItem, &$selectedCategoryItem:focus': {
          backgroundColor: 'inherit',
        },
      },
      rootListItemCategoryFocus: {
        padding: theme.spacing(22 / 8, 24 / 8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:focus': {
          width: '246px',
          borderRadius: '6px',
          backgroundColor: theme.palette.grey.A100,
          color: theme.palette.text.secondary,
          margin: '0 auto',
          padding: '22px 12px !important',
        },
        '&$selectedCategoryItem, &$selectedCategoryItem:focus': {
          backgroundColor: 'inherit',
        },
      },
      categoriesTitle: { color: theme.palette.text.secondary },
      categoriesTitleActive: { color: theme.palette.primary.main, fontWeight: 500 },
      storeTitle: {
        padding: theme.spacing(14 / 8, 0, 14 / 8, 24 / 8),
      },
      rootListItemGeneralPages: {
        paddingLeft: theme.spacing(24 / 8),
        paddingBottom: theme.spacing(14 / 8),
      },
      rootListItemGeneralPagesLink: {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        cursor: 'pointer',
        paddingTop: theme.spacing(12 / 8),
      },
      rootCategoryListItem: {
        padding: theme.spacing(22 / 8, 24 / 8),
      },
      divider: {
        background: theme.palette.grey.A100,
        margin: theme.spacing(20 / 8, 24 / 8, 0),
      },
      loadPreviousItemsDivider: {
        background: theme.palette.grey.A100,
        margin: theme.spacing(0, 24 / 8),
      },
      categoriesDivider: {
        background: theme.palette.grey.A100,
        margin: theme.spacing(0, 24 / 8),
      },
      rootUserListItem: {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        cursor: 'pointer',
        paddingTop: theme.spacing(10 / 8),
        paddingBottom: theme.spacing(10 / 8),
        justifyContent: 'initial',
      },
      selectedRootUserListItem: {
        fontWeight: 500,
        color: theme.palette.primary.main,

        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
      },
      contentPageBtn: {
        fontWeight: 'unset',
        lineHeight: 'unset',
      },
      // not delete, used in rootListItem styles
      selectedCategoryItem: {},
      selectedText: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        position: 'relative',
      },
      rootLoadPreviousItems: {
        fontSize: '18px',
        justifyContent: 'initial',
        padding: '22px 24px !important',
      },
      langImageMenu: {
        width: '20px',
        height: '20px',
        marginRight: '12px',
      },
      menuNotificationIcon: {
        position: 'absolute',
      },
      menuNotificationDot: {
        color: theme.palette.primary.main,
        position: 'relative',
      },
      storeSideWrapper: {
        display: 'flex',
        height: '32px',
        alignItems: 'flex-start',
      },
      storeIcon: {
        color: theme.palette.secondary.main,
        fontSize: '32px',
        marginRight: theme.spacing(10 / 8),
      },
      storeSideText: {
        fontWeight: 500,
        fontSize: '16px',
        textAlign: 'left',
        color: theme.palette.secondary.main,
        lineHeight: 1.25,
      },
      welcomeStoreText: {
        fontSize: '14px',
        color: theme.palette.background.paper,
        textAlign: 'left',
        lineHeight: 1.25,
      },
      arrowIcon: {
        fontSize: '14px',
      },
      userNotificationDot: {
        fontSize: '32px',
        color: theme.palette.secondary.main,
        position: 'relative',
      },
      memberPromotionDialog: {
        padding: '0px !important',
        marginTop: '4px',
      },
    }),
);

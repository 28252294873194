import React, { FC } from 'react';

import { Box, Grid } from '@material-ui/core';
import { number, object, SchemaOf, string } from 'yup';
import { Field, Form, FormikProps, withFormik } from 'formik';

import { TextField } from 'components/common/formsFields';
import CTAButton from 'ui/common/buttons/CTAButton';
import { useMobile } from 'hooks';
import useStyles from './styles';

import { IPickupForm, IPickUpFormValues, TPickupFormSchema } from './types';

const pickupFormSchema: SchemaOf<TPickupFormSchema> = object().shape({
  firstName: string().required('validation.required'),
  lastName: string().required('validation.required'),
  email: string().email('validation.email').required('validation.required'),
  cellPhone: number().required('validation.required'),
});

const PickupForm: FC<IPickupForm & FormikProps<IPickUpFormValues>> = ({
  isValid,
  isSubmitting,
}) => {
  const classes = useStyles();

  const { isMobile } = useMobile();

  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item lg={6} xs={6}>
          <Field name="firstName" required component={TextField} />
        </Grid>
        <Grid item lg={6} xs={6}>
          <Field name="lastName" required component={TextField} />
        </Grid>
      </Grid>
      <Box mt={1.25}>
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Field name="email" type="email" disabled required component={TextField} />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Field name="cellPhone" type="tel" disabled required component={TextField} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={isMobile ? 30 / 8 : 5} textAlign="end">
        <Grid item>
          <CTAButton
            fullWidth={isMobile}
            type="submit"
            disabled={!isValid || isSubmitting}
            className={classes.submitButtonSection}
          >
            {'forms.checkout.submitButton'}
          </CTAButton>
        </Grid>
      </Box>
    </Form>
  );
};

export default withFormik<IPickupForm, IPickUpFormValues>({
  mapPropsToValues: ({ defaultValues }) => {
    return {
      firstName: (defaultValues && defaultValues.firstName) || '',
      lastName: (defaultValues && defaultValues.lastName) || '',
      email: (defaultValues && defaultValues.email) || '',
      cellPhone: (defaultValues && defaultValues.cellPhone) as string,
    };
  },
  validationSchema: pickupFormSchema,
  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus, resetForm }) => {
    props.handleSubmit(values, { setSubmitting, setErrors, setStatus, resetForm });
  },
  enableReinitialize: true,
})(PickupForm);

import React, { FC, useCallback } from 'react';

import { useHelperFieldText } from 'hooks';

import TextInput, { ITextInput } from 'ui/common/inputs/TextInput';

import { TTextFieldProps } from './types';

const TextField: FC<TTextFieldProps> = ({
  field: { onChange, ...restField },
  form: { touched, errors },
  typingPattern,
  ...rest
}) => {
  // TODO think of better way (not general enough)
  const handleChange = useCallback<Required<ITextInput>['onChange']>(
    (e) => {
      if (typingPattern) {
        const regExp = new RegExp(typingPattern);

        if (e.target.value === '' || regExp.test(e.target.value)) {
          onChange(e);
        }
      } else {
        onChange(e);
      }
    },
    [onChange, typingPattern],
  );
  const renderErrorText = useHelperFieldText();
  const isShowError = touched[restField.name] && !!errors[restField.name];

  return (
    <TextInput
      error={isShowError}
      helperText={isShowError ? renderErrorText(errors[restField.name]) : ''}
      onChange={handleChange}
      {...restField}
      {...rest}
    />
  );
};
export default TextField;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesPreviousOrdersList } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPreviousOrdersList> =>
    createStyles({
      image: {
        width: theme.spacing(70 / 8),
        margin: '0 auto',
      },
      title: {
        fontSize: theme.spacing(16 / 8),
        color: theme.palette.grey[600],
        textAlign: 'center',
        marginTop: theme.spacing(18 / 8),
      },
      noOrdersWrapper: {
        padding: theme.spacing(20 / 8),
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
    }),
);

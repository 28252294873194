import React, { FC } from 'react';

import { Box } from '@material-ui/core';

import useStyles from './styles';

import { IGreenLayout } from './types';

const GreenLayout: FC<IGreenLayout> = ({ children, backgroundColor }) => {
  const classes = useStyles({ backgroundColor });

  return <Box className={classes.colorSection}>{children}</Box>;
};

export default GreenLayout;

import { alpha, createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { IPlaceOrderButton, TStylesPlaceOrderButton } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesPlaceOrderButton, Pick<IPlaceOrderButton, 'loading' | 'orderMode'>> =>
    createStyles({
      root: ({ loading }) => ({
        height: '46px',
        minWidth: '338px',
        borderRadius: '6px',
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
          minWidth: 0,
        },

        '&:hover': {
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: `${alpha(theme.palette.primary.main, loading ? 1 : 0.4)} !important`,
        },
      }),
      confirmIcon: {
        color: theme.palette.background.paper,
        marginRight: theme.spacing(5 / 8),
        fontSize: '20px',
      },
      loader: {
        '& span': {
          verticalAlign: 'middle',
        },
      },
      placeOrderText: {
        whiteSpace: 'pre',
        overflow: 'hidden',
        maxWidth: 'calc(67vw - 100px)',
        textOverflow: 'ellipsis',
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesStoreOpenHoursDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesStoreOpenHoursDialog, { lang: string }> =>
    createStyles({
      wrapper: {
        padding: theme.spacing(50 / 8, 20 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(60 / 8, 60 / 8, 44 / 8, 60 / 8),
        },
      },
      hourIcon: {
        fontSize: theme.spacing(26 / 8),
        color: theme.palette.secondary.main,
        marginRight: theme.spacing(6 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(26 / 8),
        },
      },
      hoursTitle: {
        fontWeight: 'bold',
        fontSize: theme.spacing(26 / 8),
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
          lineHeight: '28px',
          fontSize: ({ lang }) => theme.spacing(lang === 'en' ? 24 / 8 : 30 / 8),
        },
      },
      weekday: {
        fontSize: theme.spacing(16 / 8),
      },
    }),
);

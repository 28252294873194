import { i18n, ResourceKey } from 'i18next';

export type TLanguage = string;

export type TResource = Record<TLanguage, { translation: ResourceKey }>;

export type TCreateI18n = (lng?: TLanguage, fallbackLng?: TLanguage) => i18n;

export const supportedLanguages = ['he', 'en'];

// need for translations
export type TLanguageTranslations = {
  languages: {
    language: string;
  };
  rexail: {
    formalName: string;
    brandName: string;
  };
  pages: {
    titles: {
      cart: string;
      checkout: string;
    };
  };
  currency: {
    shekel: string;
  };
  button: {
    continue: string;
    loadPreviousOrder: string;
    previousOrder: string;
    goToCheckout: string;
    goToCart: string;
    continueToCheckout: string;
    placeOrder: string;
    paymentViaBit: string;
    placeSubscription: string;
    continueShopping: string;
    viewSubscription: string;
    viewSubscriptionCompletedCard: string;
    viewOrder: string;
    view: string;
    orderAgain: string;
    edit: string;
    cancelOrder: string;
    contactStore: string;
    downloadInvoice: string;
    downloadDeliveryCertificate: string;
    restore: string;
    ok: string;
    cancel: string;
    saveChanges: string;
    backToTheHomePage: string;
    orderAgainBtn: string;
    increaseQuantity: string;
    decreaseQuantity: string;
    loadMoreProducts: string;
  };
  input: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    fullAddress: string;
    floor: string;
    apartment: string;
    doorCode: string;
    entrance: string;
    optional: string;
    addressNotes: string;
    search: string;
    chooseStore: string;
    cardHolderName: string;
    cardHolderID: string;
    creditCardNumber: string;
    expirationYear: string;
    expirationMonth: string;
    expirationDate: string;
    cvv: string;
    validity: string;
    coupon: string;
    cellPhone: string;
    hidden: string;
    coordinates: string;
    city: string;
    streetAndStreetNumber: string;
    streetAndStreetExample: string;
    address: string;
    details: string;
    receivesCommercialMessages: string;
    whatDeliveryPersonShouldKnow: string;
    notSelected: string;
    otherAmount: string;
    tipPlaceholder: string;
    cityMoshavKibuts: string;
    streetAndNumberOfBuilding: string;
    nameOfBase: string;
    cityPlaceholder: string;
    searchPickup: string;
    searchLocationByAddress: string;
  };
  payments: {
    options: {
      addNewCard: string;
    };
    hiddenDigits: string;
    noPaymentMethods: string;
    creditCardDetails: string;
    backToPayments: string;
    validUntil: string;
    bit: {
      name: string;
      awaitingPayment: string;
      sendLinkTo: string;
      resend: string;
      paymentDeclined: string;
      linkIsValidTenMinutes: string;
      paymentAccepted: string;
      bitLinkWasSendSuccessfully: string;
    };
  };
  link: {
    backToTheShop: string;
  };
  userMenu: {
    hiText: string;
    loginText: string;
    signUpLoginText: string;
    accountDetails: string;
    payments: string;
    mySubscriptions: string;
    orderHistory: string;
    logOut: string;
    clientCoupons: {
      clientCoupon: string;
      validUntil: string;
    };
  };
  accountDetails: {
    edit: string;
    sendVerificationEmailTo: string;
    sendToOldAndNewEmail: string;
    forSecurityPurposes: string;
    sendToNewEmail: string;
    emailNotDefined: string;
    keep: string;
    verified: string;
    waitingForVerificationAtTheEmail: string;
    waitingForVerification: string;
  };
  categoryMenu: {
    more: string;
    title: string;
    allProducts: string;
    allItems: string;
    promotions: string;
  };
  topCard: {
    expandCart: string;
    myShoppingBag: string;
    continue: string;
    payment: string;
    cardEmpty: string;
    startAdding: string;
    revaluation: string;
    minimumOrder: string;
    backToStore: string;
    orderSummary: string;
    commentsToPreparer: string;
    totalShoppingCart: string;
    listHeader: {
      product: string;
      pricePerUnit: string;
      quantity: string;
      total: string;
      remove: string;
    };
    deleteBasket: string;
    backToCart: string;
    showPreviousOrder: string;
    shoppingCartNItems: string;
    deleteFromCart: string;
    productsYouMightMissed: string;
    restore: string;
    delete: string;
    removedItem: string;
    shareCart: string;
    shareCartItems: string;
    cartWasShared: string;
    cartWasSharedSuccessfully: string;
    shareCartInFollowingWays: string;
    shareLink: string;
    whatsapp: string;
    checkSharedCard: string;
    emailSharedCard: string;
    goToCart: string;
    deviceNotSupportShare: string;
    linkCopiedSuccessfully: string;
  };
  generalPage: {
    about: string;
    subscribe: string;
    deliveryAreas: string;
    contact: string;
  };
  deliveryPickUp: {
    withoutDeliveryTimeText: string;
    allTimeFramesAreFull: string;
    errorMessage: string;
    chooseDeliveryMethod: string;
    sortPointsByCurrentLocation: string;
    onlySelfPickup: string;
    onlySelfPickupChoseTime: string;
  };
  orderType: {
    delivery: string;
    selfPickup: string;
  };
  stepper: {
    order: {
      personalInformation: string;
      coordinationArrival: string;
      payment: string;
    };
    subscription: {
      personalInformation: string;
      coordinationArrival: string;
      payment: string;
    };
  };
  overlay: {
    title: string;
    buttonText: string;
  };
  storeProductCard: {
    addToCart: string;
    productCommentTypeAvailable: string;
    originalPrice: string;
    relevancy: {
      popular: string;
      high: string;
      medium: string;
      low: string;
    };
    supplier: string;
    pricePerOneHundredGram: string;
    pricePerOneHundredMl: string;
    countryOfOrigin: string;
    addMoreItemMultiple: string;
    addMoreItemSingle: string;
    toPromotion: string;
  };
  tag: {
    sale: string;
    deal: string;
    freeItem: string;
  };
  header: {
    backButton: string;
    navigation: string;
    chooseDelivery: string;
    chooseSelfPickup: string;
    chooseDeliveryTime: string;
    chooseSelfPickupTime: string;
    chooseDeliverySubscriptionMode: string;
    chooseSelfPickupSubscriptionMode: string;
    skipToTheCategory: string;
    skipToTheMainContent: string;
    skipToTheFooter: string;
  };
  previousOrder: {
    price: string;
    items: string;
    addItems: string;
    backToOrderList: string;
    payAttention: string;
    ongoingOrder: string;
    itemIsOutOfStock: string;
  };
  editOrder: {
    removedItemsExcludedFromCatalog: string;
    removedItemsOutOfStock: string;
  };
  order: {
    deliveryDate: string;
    orderType: string;
    numberOfItems: string;
    totalToPay: string;
    total: string;
    orderNumber: string;
    status: string;
    deliveryAddress: string;
    selfPickupAddress: string;
    paymentMethod: string;
    comments: string;
    revaluation: string;
    noOrders: string;
    startBuying: string;
    orderTime: string;
    ecoPackaging: string;
    itemsSubstitution: string;
    suppliedItems: string;
    notSupplied: string;
    orderedItems: string;
    chooseEcoPackaging: string;
    inCaseItemIsOutOfStock: string;
    substituteWithSimilar: string;
    doNotSubstitute: string;
    contactMe: string;
  };
  days: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
    shortName: {
      monday: string;
      tuesday: string;
      wednesday: string;
      thursday: string;
      friday: string;
      saturday: string;
      sunday: string;
    };
    today: string;
    tomorrow: string;
  };
  footer: {
    menu: {
      about: string;
      delivery: string;
      contact: string;
      details: string;
      paymentMethod: string;
      subscription: string;
      orderHistory: string;
    };
    termsAndConditions: {
      privacy: string;
      accessibilityStatement: string;
      terms: string;
    };
    allRights: string;
    companyType: string;
    ariaLabels: {
      storeAddress: string;
      storePhone: string;
      storeEmail: string;
      storeFacebook: string;
      storeWhatsapp: string;
      storeYoutube: string;
      storeInstagram: string;
    };
  };
  snackbar: {
    undo: string;
    orderMinimumFellShort: {
      delivery: string;
      selfPickup: string;
    };
    orderMinimumFellShortIfNoDeliveryFee: string;
    promotionAppliedSuccessfully: string;
  };
  forms: {
    checkout: {
      submitButton: string;
      backButton: string;
      arrivalSubtitle: string;
      collectionPoint: string;
      pickUpDate: string;
      backToPersonal: string;
      backToArrival: string;
      moveToPay: string;
      additionalDelivery: string;
      creditDetails: string;
      billingCredit: string;
      saveCredit: string;
      saveCreditTooltip: string;
    };
    contactUs: {
      title: string;
      submitButton: string;
    };
  };
  validation: {
    email: string;
    required: string;
    chooseCity: string;
    error: string;
    minMonth: string;
    maxMonth: string;
    minYear: string;
    twoDigits: string;
    noOptions: string;
    productComment: string;
    chooseHowToPrepareItems: string;
    language: string;
  };
  map: {
    getLocationFromBrowser: string;
    moveMapToGetPosition: string;
    navigatorNotSupported: string;
    markLocationOnTheMap: string;
    pinIsFar: string;
    pinIsInvalid: string;
  };
  title: {
    aboutProduct: string;
    ordersHistory: string;
    activeOrders: string;
    closedOrders: string;
    subscriptions: string;
  };
  comments: {
    preparationInstructionsAtTheEndOfThePurchase: string;
    youMayAlsoWant: string;
  };
  shoppingPage: {
    searchResults: string;
    cleanSearch: string;
    goToHomePage: string;
    sort: {
      sortBy: string;
      sortByName: string;
      sortByCategoryAndName: string;
      sortByPriceAsc: string;
      sortByPriceDesc: string;
      sortByPromoted: string;
      sortBySearchRelevancy: string;
    };
  };
  paymentInfoSection: {
    creditCard: {
      text: string;
      externalFormText: string;
      link: string;
    };
    deferredPayment: string;
    absent: string;
    cash: string;
    bit: {
      onlyNonWeighableItems: string;
      weighableItems: string;
    };
  };
  auth: {
    loginText: string;
  };
  dialog: {
    bitErrorViewOrder: string;
    bitErrorOrderCompleted: string;
    multipleDeliveryAreas: string;
    oneDeliveryArea: string;
    unavailableTimeframe: string;
    searchSucceeded: string;
    searchFailed: string;
    closeButton: string;
    orderMinimumLabel: string;
    catalogWasUpdated: {
      title: string;
      body: string;
      okButton: string;
    };
    orderMinimum: {
      title: string;
      body: {
        delivery: string;
        selfPickup: string;
      };
      bodyIfNoDeliveryFee: string;
      okButton: string;
    };
    handlingFee: {
      title: string;
      body: string;
      okButton: string;
      cancelButton: string;
    };
    emptyCart: {
      title: string;
      body: string;
      yesButton: string;
      noButton: string;
    };
    location: {
      title: string;
      subTitle: string;
      haveAccount: string;
      addressExample: string;
      interestingInSelfPickup: string;
      chooseSelfPickup: string;
      deliveryHoursAndMoreDetails: string;
      residential: string;
      locationType: string;
      private: string;
      office: string;
      militaryBase: string;
      couldNotLocateAddress: string;
    };
    login: {
      title: string;
      subTitle: string;
      confirmTerms: string;
      terms: string;
    };
    loginCode: {
      titleSendViaTel: string;
      titleSendViaEmail: string;
      subTitleTel: string;
      subTitleEmail: string;
      sendAgain: string;
      selectMethodToReceivedCode: string;
      dontReceivedCode: string;
      smsMessage: string;
      phoneCall: string;
      email: string;
      enterEmailToSendAuthCode: string;
      send: string;
      back: string;
      codeFromSms: string;
    };
    signUp: {
      title: string;
      subTitle: string;
      submitButton: string;
      addressExample: string;
    };
    welcome: {
      title: string;
      closeButton: string;
    };
    paymentMethod: {
      title: string;
      body: string;
      yesButton: string;
      noButton: string;
    };
    delivery: {
      shippingAddress: string;
      storeHours: {
        title: string;
      };
      coordination: {
        title: string;
        description: string;
      };
      editText: string;
      storeDoesntMakeDeliveryToYourAddressButSelfPickupExist: string;
      kmFromYourAddress: string;
      kmFromSelectedAddress: string;
      kmFromYourCurrentLocation: string;
      choose: string;
      chooseAddress: string;
      chooseSelfPickup: string;
      dropDownChooseSelfPickup: string;
      selfPickupPoint: string;
      changeSelfPickupPoint: string;
      toChooseSelfPickupDateNeedLog: string;
      chooseSelfPickupTime: string;
    };
    existingOrder: {
      title: string;
      body: string;
      description: string;
      submitButton: string;
      cancelButton: string;
    };
    activeSubscription: {
      title: string;
      body: string;
      bodyDescription: string;
      okButton: string;
      cancelButton: string;
    };
    cancelOrder: {
      title: string;
      editedTitle: string;
      body: string;
      editedBody: string;
      yesButton: string;
      noButton: string;
    };
    cancelSubscription: {
      title: string;
      editedTitle: string;
      cancel: string;
      ok: string;
      body: string;
      editedBody: string;
      justUnsubscribe: string;
    };
    unsuspendSubscription: {
      title: string;
      body: string;
      cancel: string;
      ok: string;
    };
    suspendSubscription: {
      cancel: string;
      suspendAndCancelOrder: string;
      justSuspend: string;
    };
    editOrderMode: {
      title: string;
      body: string;
      keepEditing: string;
      exitEditMode: string;
    };
    deliveryToClientAddress: {
      performDeliveryTitle: string;
      performDeliveryBody: string;
      performsSelfPickupBody: string;
      performsSelfPickupAndDeliveryBody: string;
      performDeliveryYesButton: string;
      performSelfPickupYesButton: string;
      doesntPerformDeliveryTitle: string;
      doesntPerformDeliveryBody: string;
      doesntPerformDeliveryChangeAddressButton: string;
      doesntPerformDeliveryChangeCloseButton: string;
      edit: string;
    };
    threeDSecure: {
      errorTitle: string;
      apiLoadError: string;
      authError: string;
      genericAuthError: string;
      okButton: string;
    };
    basket: {
      title: string;
      subtitle: string;
      save: string;
      productInBasket: string;
      replaceProducts: string;
      maxReplacementsReached: string;
      defaultItemQuantity: string;
    };
    exitModeIndicator: {
      addSubscription: {
        title: string;
        body: string;
        okButton: string;
        cancelButton: string;
      };
      editSubscription: {
        title: string;
        body: string;
        okButton: string;
        cancelButton: string;
      };
      edit: {
        title: string;
        body: string;
        okButton: string;
        cancelButton: string;
      };
    };
    updateSubscription: {
      title: string;
      body: string;
      bodyIfTipExist: string;
      okButton: string;
      cancelButton: string;
    };
    orderEditingIsNotPossible: {
      title: string;
      body: string;
      yesButton: string;
      noButton: string;
    };
    paymentByBit: {
      title: string;
      body: string;
      or: string;
      scanQR: string;
      awaitingPaymentViaBit: string;
      completePayment: string;
    };
    paymentByBitWasDeclined: {
      title: string;
      subtitle: string;
      body: string;
      yesButton: string;
      noButton: string;
    };
  };
  location: {
    cantFind: string;
    selectByMap: string;
    shareMyLocation: string;
    myLocation: string;
  };
  availableHours: {
    chooseDay: string;
    chooseTimeframe: string;
    today: string;
    tomorrow: string;
  };
  links: {
    backToSubscriptions: string;
    backToTheShop: string;
    backToOrderHistory: string;
    backToAddressSearch: string;
  };
  completedOrder: {
    yourOrderInTheSystem: string;
    noteCompletedOrder: string;
    backToTheHomeScreen: string;
    orderDetails: string;
    orderNumber: string;
  };
  completedSubscription: {
    yourSubscriptionInTheSystem: string;
    noteCompletedSubscription: string;
    backToTheHomeScreen: string;
    subscriptionDetails: string;
  };
  subscription: {
    frequency: string;
    orderType: string;
    numberOfItems: string;
    nextOrder: string;
    subscriptionTo: string;
    status: string;
    deliveryTime: string;
    lastOrderDate: string;
    expectedNextOrder: string;
    methodsOfPayment: string;
    subscriptionDate: string;
    suspendSubscription: string;
    localedPreferredDay: string;
    unsuspendSubscription: string;
    cancelSubscription: string;
    day: string;
    selectDay: string;
    dateIsInThePast: string;
    invalidDate: string;
    ok: string;
    cancel: string;
    noSubscription: string;
    startToSubscribeTo: string;
    chooseFrequency: string;
    chooseDayAndTime: string;
    coordinationArrival: string;
  };
  whatIsSubscription: {
    title: string;
    subTitle: string;
    howItWorks: string;
    assembleTheBasketTitle: string;
    assembleTheBasketSubtitle: string;
    chooseFrequencyDayAndTimeTitle: string;
    chooseFrequencyDayAndTimeSubtitle: string;
    startToSubscribe: string;
    whatIsSubscriptionAndHowItWorksTitle: string;
    whatIsSubscriptionAndHowItWorksDescription: string;
    whatIsSubscriptionAndHowItWorksDescriptionTwo: string;
    whatIsSubscriptionAndHowItWorksDescriptionThree: string;

    enterPaymentDetailsTitle: string;
    enterPaymentDetailsSubtitle: string;
    addNewSubscriptionTitle: string;
    addNewSubscriptionDescription: string;
    addNewSubscriptionDescriptionTwo: string;
    addNewSubscriptionDescriptionThree: string;
    addNewSubscriptionDescriptionFour: string;
    howDoIFreezeSubscriptionTitle: string;
    howDoIFreezeSubscriptionDescription: string;
    howDoIFreezeSubscriptionDescriptionTwo: string;
    howDoIFreezeSubscriptionDescriptionThree: string;
    deficienciesInOrderingSubscriptionTitle: string;
    deficienciesInOrderingSubscriptionDescription: string;
    changeDeliveryDateTitle: string;
    changeDeliveryDateDescription: string;
    feelingConfused: string;
    answersFrequentlyQuestions: string;
  };
  error: {
    itemsNotFoundTitle: string;
    itemsNotFoundDescription: string;
    previousOrderNotExist: string;
    oops: string;
    pageNotFound: string;
    somethingWentWrong: string;
    errorDescription: string;
    increaseYourExperience: string;
    systemCurrentlyUpgraded: string;
    maxAmountOfProduct: string;
    errorDetails: string;
  };
  personalDetails: {
    floorApartment: string;
    apartment: string;
    floor: string;
  };
  checkout: {
    noOrderComments: string;
    choosePayment: string;
    chooseCard: string;
    info: {
      address: string;
    };
    paymentMethod: {
      creditCard: string;
      deferredPayment: string;
      absent: string;
      cash: string;
      bit: string;
    };
    coupon: {
      couponDiscount: string;
      generalDiscount: string;
      itemRefund: string;
      code: string;
      noCoupon: string;
      couponValueMessage: string;
      refundItemIsNotInTheCart: string;
    };
    numberOfPayments: string;
    betweenHours: string;
    courierTip: string;
    noTip: string;
    other: string;
    tipForCourier: string;
    handlingFee: string;
  };
  autocomplete: {
    loading: string;
  };
  bagOfProducts: {
    whatBagContain: string;
    moreDetails: string;
  };
  serviceAreaUsageFees: {
    delivery: string;
    selfPickup: string;
  };
  orderModeIndicator: {
    edit: string;
    addSubscription: string;
    editSubscription: string;
    exit: string;
  };
  aboutPage: {
    aboutTitle: string;
    openHours: string;
    regularlyTitle: string;
    regularlySubtitleOne: string;
    regularlySubtitleTwo: string;
    regularlyBtn: string;
    divisionalTitle: string;
    divisionalSubtitle: string;
    contactTitle: string;
    openingHours: string;
    closed: string;
    moreDetails: string;
    check: string;
    checkAgain: string;
    citySearchDescription: string;
    followUs: string;
  };
  discount: {
    payment: string;
    creditAtCheckoutDescription: string;
    deliveryUsageFees: string;
    selfPickupUsageFees: string;
    storePromotion: string;
    storeCoupon: string;
    clientCoupon: string;
    couponDiscount: string;
    generalDiscount: string;
    itemRefund: string;
    itemForFree: string;
    forOrders: {
      delivery: string;
      selfPickup: string;
    };
    valid: string;
    days: string;
    maxQuantityUsage: {
      forNonWeighableAboveQuantity: string;
      forWeighableBetweenQuantity: string;
    };
    validUntil: string;
    notValidInSelectedDay: string;
  };
  onClientBehalf: {
    storeSide: string;
  };
  ageValidation: {
    ageValidation: string;
    orderContainAgeLimitedItems: string;
    ageConformation: string;
  };
  incentivePromotion: {
    tooltip: {
      redeemRefund: string;
    };
    generatedText: {
      forCartEstimationBeforePreparation: {
        itemForFree: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: string;
            partialDiscount: string;
          };
          delivery: {
            fullDiscount: string;
            partialDiscount: string;
          };
          selfPickup: {
            fullDiscount: string;
            partialDiscount: string;
          };
        };
        generalDiscount: {
          all: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
          delivery: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
          selfPickup: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
        };
        itemRefund: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
      };
    };
    productsParticipatingSale: string;
    seeParticipationProducts: string;
  };
  promotionsBanner: {
    validForDays: string;
    until: string;
    validUntil: string;
    maxQuantityPerOrder: string;
    tooltip: {
      readMore: string;
      forCartEstimationBeforePreparation: string;
      forCartEstimationAfterPreparation: string;
      forSubscription: string;
      forNewSubscription: string;
    };
    generatedText: {
      forCartEstimationBeforePreparation: {
        itemForFree: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: string;
            partialDiscount: string;
          };
          delivery: {
            fullDiscount: string;
            partialDiscount: string;
          };
          selfPickup: {
            fullDiscount: string;
            partialDiscount: string;
          };
        };
        generalDiscount: {
          all: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
          delivery: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
          selfPickup: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
        };
        itemRefund: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
      };
      forCartEstimationAfterPreparation: {
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: string;
            partialDiscount: string;
          };
          delivery: {
            fullDiscount: string;
            partialDiscount: string;
          };
          selfPickup: {
            fullDiscount: string;
            partialDiscount: string;
          };
        };
        generalDiscount: {
          all: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
          delivery: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
          selfPickup: {
            smallMinimumOrder: string;
            minimumOrder: string;
          };
        };
        itemRefund: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
      };
      forNewCustomer: {
        itemForFree: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: string;
            partialDiscount: string;
          };
          delivery: {
            fullDiscount: string;
            partialDiscount: string;
          };
          selfPickup: {
            fullDiscount: string;
            partialDiscount: string;
          };
        };
        generalDiscount: {
          all: {
            smallMinimumOrder: string;
          };
          delivery: {
            smallMinimumOrder: string;
          };
          selfPickup: {
            smallMinimumOrder: string;
          };
        };
        itemRefund: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
      };
      forSubscription: {
        itemForFree: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: string;
            partialDiscount: string;
          };
          delivery: {
            fullDiscount: string;
            partialDiscount: string;
          };
          selfPickup: {
            fullDiscount: string;
            partialDiscount: string;
          };
        };
        generalDiscount: {
          all: {
            smallMinimumOrder: string;
          };
          delivery: {
            smallMinimumOrder: string;
          };
          selfPickup: {
            smallMinimumOrder: string;
          };
        };
        itemRefund: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
      };
      forNewSubscription: {
        itemForFree: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: string;
            partialDiscount: string;
          };
          delivery: {
            fullDiscount: string;
            partialDiscount: string;
          };
          selfPickup: {
            fullDiscount: string;
            partialDiscount: string;
          };
        };
        generalDiscount: {
          all: {
            smallMinimumOrder: string;
          };
          delivery: {
            smallMinimumOrder: string;
          };
          selfPickup: {
            smallMinimumOrder: string;
          };
        };
        itemRefund: {
          all: string;
          delivery: string;
          selfPickup: string;
        };
      };
    };
  };
  pwa: {
    installerPromptText: string;
    iosPromptTitle: string;
    iosPromptDescription: string;
    iosPromptStep1: string;
    iosPromptStep2: string;
  };
  seo: {
    products: string;
    genericCategoryMetadata: string;
    andMore: string;
    similarProducts: string;
  };
  serverGenericErrorDescription: string;
  subCatalog: {
    catalogWasUpdated: string;
  };
  homePage: {
    toAllProducts: string;
    previewMode: string;
  };
  membership: {
    youHaveBenefits: string;
    youEarnedPoints: string;
    forAllBenefits: string;
    customerClub: string;
    customerClubDesktop: string;
    memberNumber: string;
    points: string;
    equalToShekel: string;
    benefits: string;
    redeemPoints: string;
    use: string;
    remove: string;
    errorValueIsMoreThanPoints: string;
    redeemedPointsToShekel: string;
    calculatedByExternalCompany: string;
    validUntil: string;
    usedAmountOfPoints: string;
    membershipClubDiscount: string;
  };
  smallEC: {
    promotionsAndDiscounts: string;
    chooseFromTheList: string;
    addProducts: string;
    pickupPointNotAvailable: string;
  };
};

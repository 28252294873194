import React, { FC, ReactNode, useMemo } from 'react';

import { Box, Grid } from '@material-ui/core';
import Typography from 'ui/common/Typography';
import PlaceOrderButton from 'ui/common/buttons/PlaceOrderButton';

import { useMobile } from 'hooks';

import { useSelector } from 'react-redux';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import useStyles from './styles';

import { IPaymentFormCheckoutFooter } from './types';

const PaymentFormCheckoutFooter: FC<IPaymentFormCheckoutFooter> = ({
  isValid,
  isSubmitting,
  dirty,
  price,
  showRevaluation = true,
  onSubmit,
  buttonLoading,
  activePaymentType,
}) => {
  const orderMode = useSelector(getOrderMode);
  const { isMobile } = useMobile();

  const classes = useStyles();

  const renderFinalCostText = useMemo<ReactNode>(() => {
    return (
      <Box mt={1} width="100%">
        <Grid container justify="center">
          <Typography color="mediumGrey" fontSize={13}>
            {'topCard.revaluation'}
          </Typography>
        </Grid>
      </Box>
    );
  }, []);

  return (
    <Box mt={isMobile ? 2 : 5}>
      <Grid container justify="space-between">
        {!isMobile && (
          <Grid item lg={6} md={6} sm={6}>
            {/* <CTAButton variant="outlined" onClick={backButtonClick}>
              {'forms.checkout.backToArrival'}
            </CTAButton> */}
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.submitButtonSection}>
          {/* TODO - check if the button refreshes when buttonLoading changes */}
          <PlaceOrderButton
            orderMode={orderMode}
            activePaymentType={activePaymentType}
            fullWidth={isMobile}
            type="submit"
            onClick={onSubmit}
            price={price}
            disabled={!isValid || !dirty || isSubmitting}
            loading={buttonLoading}
          />
        </Grid>
        {isMobile && showRevaluation && renderFinalCostText}
      </Grid>
    </Box>
  );
};

export default PaymentFormCheckoutFooter;

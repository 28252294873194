import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesPersonalDetailsForm } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPersonalDetailsForm> =>
    createStyles({
      submitButtonSection: {
        textAlign: 'end',
      },
      link: {
        fontSize: theme.spacing(13 / 8),
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
      arrowRightIcon: {
        fontSize: theme.spacing(10 / 8),
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2 / 8),
      },
      linkWrapper: {
        top: '-3px',
        position: 'absolute',
        right: 0,
        cursor: 'pointer',
        zIndex: 2,
        padding: 0,
      },
      receivesMessagesLabel: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        lineHeight: 1.29,
      },
    }),
);

import React, { FC, useCallback, useMemo } from 'react';

import dynamic from 'next/dynamic';

import classNames from 'classnames';

import { Box, Button } from '@material-ui/core';
import Typography from 'ui/common/Typography';

import useStyles from './styles';
import { IActionsOnCartButton } from './types';

const CTAButton = dynamic(() => import('ui/common/buttons/CTAButton'));
const Icon = dynamic(() => import('ui/common/icons/Icon'));

const ActionsOnCartButton: FC<IActionsOnCartButton> = ({
  count,
  onChangeStoreProductQuantity,
  onAddStoreProduct,
  size = 'regular',
}) => {
  const classes = useStyles({ size });

  const disableOnClickPropagation = useCallback((e) => {
    // avoid link redirect + popup opening
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleChangeProductCount = useCallback(
    (e, action) => {
      disableOnClickPropagation(e);
      onChangeStoreProductQuantity(action);
    },
    [disableOnClickPropagation, onChangeStoreProductQuantity],
  );

  const onAddProductToCart = useCallback(
    (e) => {
      disableOnClickPropagation(e);
      onAddStoreProduct();
    },
    [disableOnClickPropagation, onAddStoreProduct],
  );

  const actionsTemplate = useMemo(() => {
    return count ? (
      <Box className={classes.buttonsWrapper}>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => handleChangeProductCount(e, 'add')}
          classes={{ contained: classNames(classes.buttonActionRoot, classes.addButton) }}
        >
          <Icon name="icon-plus" color="action" classes={{ root: classes.icon }} />
        </Button>
        <Typography
          variant="body2"
          className={classes.buttonText}
          color="light"
          fontWeight="medium"
        >
          {count}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleChangeProductCount(e, 'remove')}
          classes={{ contained: classNames(classes.buttonActionRoot, classes.removeButton) }}
        >
          <Icon name="icon-minus" color="action" classes={{ root: classes.icon }} />
        </Button>
      </Box>
    ) : (
      <CTAButton
        className={classes.actionsOnCartButtonRoot}
        classes={{
          startIcon: classes.startIconRoot,
        }}
        startIcon={<Icon name="icon-plus" color="action" />}
        size="medium"
        onClick={onAddProductToCart}
      >
        <Typography
          className={classes.buttonLabel}
          variant="body1"
          color="light"
          fontWeight="medium"
        >
          {'storeProductCard.addToCart'}
        </Typography>
      </CTAButton>
    );
  }, [
    classes.actionsOnCartButtonRoot,
    classes.addButton,
    classes.buttonActionRoot,
    classes.buttonLabel,
    classes.buttonText,
    classes.buttonsWrapper,
    classes.icon,
    classes.removeButton,
    classes.startIconRoot,
    count,
    handleChangeProductCount,
    onAddProductToCart,
  ]);

  return actionsTemplate;
};

export default ActionsOnCartButton;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { IWebsiteSettings } from 'store';

import { TStylesSelfPickupDialog } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesSelfPickupDialog,
    {
      onlySelfPickups: IWebsiteSettings['onlySelfPickups'];
      isShowMapOnFullScreen: boolean;
    }
  > =>
    createStyles({
      header: ({ onlySelfPickups }) => ({
        overflow: 'hidden',
        height: '132px',
        paddingLeft: theme.spacing(14 / 8),
        background: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        alignItems: onlySelfPickups ? 'center' : 'initial',
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(52 / 8),
          height: '160px',
        },
      }),
      imageWrapper: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        right: theme.direction === 'ltr' ? '-42px' : 0,
        [theme.breakpoints.up('md')]: {
          right: 0,
          position: 'relative',
          alignItems: 'flex-end',
        },
      },
      editBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(24 / 8, 0),
      },
      iconRoot: {
        display: 'flex',
        cursor: 'pointer',
      },
      editText: {
        marginLeft: theme.spacing(4 / 8),
      },
      editIcon: {
        fontSize: '18px',
      },
      headerContent: ({ onlySelfPickups }) => ({
        marginTop: theme.spacing(onlySelfPickups ? 0 : 46 / 8),
        fontSize: onlySelfPickups ? '22px' : '18px',
        lineHeight: onlySelfPickups ? '15px' : '24px',
        maxWidth: theme.direction === 'rtl' ? '100%' : '70%',
        [theme.breakpoints.up('md')]: {
          marginTop: onlySelfPickups ? 0 : '54px',
          maxWidth: '100%',
          fontSize: '30px',
          lineHeight: onlySelfPickups ? '15px' : '34px',
        },
      }),
      deliveryIcon: {
        fontSize: '24px',
        marginRight: theme.spacing(8 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: '32px',
          marginRight: theme.spacing(12 / 8),
        },
      },
      deliveryBlock: {
        alignItems: 'center',
        display: 'flex',
        zIndex: 1,
        position: 'relative',
      },
      getUserCurrentLocation: ({ onlySelfPickups }) => ({
        marginTop: theme.spacing(onlySelfPickups ? 2 / 8 : 18 / 8),
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(onlySelfPickups ? 4 / 8 : 26 / 8),
        },
      }),
      coordinationBlock: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(27 / 8, 0, 24 / 8, 0),
      },
      coordinationTitle: {
        marginBottom: theme.spacing(6 / 8),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(8 / 8),
        },
      },
      linkText: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
      },
      errorMessage: {
        color: theme.palette.error.main,
      },
      headerImage: {
        transform: theme.direction === 'ltr' ? 'scaleX(-1)' : 'none',
        height: '132px',
        [theme.breakpoints.up('md')]: {
          height: '160px',
        },
      },
      popupHeader: ({ onlySelfPickups }) => ({
        fontSize: onlySelfPickups ? '22px' : '18px',
        lineHeight: onlySelfPickups ? '24px' : '22px',
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(theme.direction === 'rtl' ? 30 / 8 : 24 / 8),
          lineHeight: '34px',
        },
      }),
      tabsWrapper: {
        position: 'absolute',
        bottom: 0,
        width: '278px',
        [theme.breakpoints.up('md')]: {
          width: '310px',
        },
      },
      sortPointsByCurrentLocationLabel: {
        marginLeft: '6px',
        fontSize: '14px',
        lineHeight: '18px',
        [theme.breakpoints.up('md')]: {
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
      currentLocationIcon: {
        fontSize: '14px',
        [theme.breakpoints.up('md')]: {
          fontSize: '16px',
        },
      },
    }),
);

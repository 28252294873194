import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesUseCart } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseCart, { lang: string }> =>
    createStyles({
      promotionWrapper: {
        height: '32px',
        marginBottom: theme.spacing(1),
        // disables bottom margin if promotion ribbon is a direct sibling to cart item (article)
        // in top cart
        '&:has(+ article)': {
          marginBottom: 0,
        },
        // in cart page.
        '&:has(+ tr)': {
          marginBottom: 0,
        },
      },
      promotionTextWrapper: {
        fontSize: '13px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 500,
      },
      promotionPrimaryText: {
        display: 'inline-block',
        order: 1,
        marginRight: theme.spacing(3 / 8),
      },
      promotionSecondaryText: {
        display: 'inline',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 400,
        order: 2,
        '&:has(> #membershipDescription)': {
          display: 'flex',
        },
      },
      promotionPriceWrapper: {
        paddingLeft: theme.spacing(12 / 8),
      },
      addMoreItemsTitle: {
        fontSize: theme.direction === 'rtl' ? '13px' : '12px',
        letterSpacing: theme.direction === 'rtl' ? '-0.1px' : '-0.2px',
        fontWeight: 500,
        lineHeight: '15px',
      },
      toPromotionLabel: {
        fontSize: '9px',
        marginLeft: '2px',
      },
      secondaryTypography: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        letterSpacing: -0.31,
      },
      priceTypography: {
        letterSpacing: -0.31,
      },
      icon: {
        objectFit: 'contain',
        fontSize: theme.spacing(2.5), // 20px
        marginRight: theme.spacing(0.5), // 4px
      },
      tagWrapper: {
        display: 'flex',
        alignItems: 'center',
      },
      tooltip: {
        padding: theme.spacing(1),
        backgroundColor: `${theme.palette.text.secondary}!important`,
        top: '-14px !important',
        [theme.breakpoints.up('md')]: {
          top: '0px !important',
          maxWidth: '300px !important',
        },
      },
      tooltipArrow: {
        color: `${theme.palette.text.secondary}!important`,
      },
      questionIcon: {
        fontSize: '14px',
        [theme.breakpoints.up('md')]: {
          fontSize: '16px',
        },
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TDefaultStyles, TStylesDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesDialog, TDefaultStyles> =>
    createStyles({
      root: {
        maxWidth: ({ maxWidth }) =>
          typeof maxWidth === 'string' ? maxWidth : theme.spacing(maxWidth),
        maxHeight: ({ maxHeight, fullScreen }) => {
          if (fullScreen) return 'none';
          if (!maxHeight) return 'auto';
          return typeof maxHeight === 'string' ? maxHeight : theme.spacing(maxHeight);
        },
        width: '100%',
        borderRadius: ({ borderRadius }) => theme.spacing(borderRadius),
        margin: 'auto',
      },
      closeIcon: {
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
        },
      },
      rootHeader: {
        right: theme.direction === 'rtl' ? 4 : 0,
        width: '40px',
        flip: false as unknown as string,
        padding: 0,
        position: 'absolute',
        zIndex: 2,
        top: 4,
        [theme.breakpoints.up('md')]: {
          flip: false as unknown as string,
          top: 10,
          right: theme.direction === 'rtl' ? 10 : 8,
        },
      },
      rootHeaderEmailDialog: {
        width: '40px',
        flip: false as unknown as string,
        padding: 0,
        position: 'absolute',
        zIndex: 2,
        top: 6,
        right: theme.direction === 'rtl' ? 6 : 45,
        [theme.breakpoints.up('md')]: {
          right: theme.direction === 'rtl' ? 18 : 49,
        },
      },
      rootContent: {
        padding: '0 !important',
        zIndex: 1,
      },
      closeButton: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        minWidth: '18px',
        borderRadius: '50%',
      },
      closeEmailDialog: {
        flip: false as unknown as string,
        direction: 'rtl',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        minWidth: '18px',
        borderRadius: '50%',
      },
      close: {
        height: '100%',
        color: theme.palette.primary.main,
      },
      whiteCloseIcon: {
        fontSize: '16px',
        color: theme.palette.background.paper,
        [theme.breakpoints.up('md')]: {
          color: theme.palette.primary.main,
          fontSize: '18px',
        },
      },
      customCloseText: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: '16px',
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { ISnackbar } from 'ui/common/Snackbar';
import { TStylesNotify } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesNotify, { type: ISnackbar['type'] }> =>
    createStyles({
      notifyIcon: ({ type }) => ({
        color: type === 'error' ? theme.palette.error.main : theme.palette.primary.main,
        fontSize: '16px',
        marginRight: '6px',
      }),
    }),
);

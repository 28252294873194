import { CatalogService, PromotionService } from 'api';
import i18next from 'i18next';
import { Dispatch } from 'redux';
import { IProductCategory, IWebsiteDetails, promotionsActions } from 'store';
import {
  addOrRemovePromotionsCategoryAction,
  getCategoriesRequestAction,
} from 'store/modules/category/actions';
import { orderDiscountsRecalculate } from 'store/modules/orderDiscounts/actions';
import { TWebsiteDetailsServer } from 'types';

export const getPromotionsCategory = (): IProductCategory => ({
  id: 0,
  name: i18next.t('categoryMenu.promotions'),
  sortOrder: 0.5,
  mediaFilePath: null,
  mobileMediaFilePath: null,
  metadata: {
    title: i18next.t('categoryMenu.promotions'),
    description: i18next.t('categoryMenu.promotions'),
    keywords: i18next.t('categoryMenu.promotions'),
  },
});

export const fetchAndSetCategories = async (
  dispatch: Dispatch,
  websiteDetails: TWebsiteDetailsServer | IWebsiteDetails,
  xForwardedIpAddress?: string,
  addPromotionsCategory?: boolean,
): Promise<void> => {
  // get promotions and website details
  const categories = await CatalogService.getCategories(
    websiteDetails.jsonWebEncryption,
    xForwardedIpAddress,
  );

  const parsedCategories: IProductCategory[] = categories.data.map((category) => ({
    ...category,
    metadata: JSON.parse(category.metadataJson || '{}'),
    parent: category.parent
      ? {
          ...category.parent,
          metadata: JSON.parse(category?.parent?.metadataJson || '{}'),
        }
      : undefined,
  }));

  if (addPromotionsCategory) {
    parsedCategories.push(getPromotionsCategory());
  }

  dispatch(getCategoriesRequestAction(parsedCategories));
};

export const fetchAndSetPromotions = async (
  dispatch: Dispatch,
  websiteDetails: TWebsiteDetailsServer | IWebsiteDetails,
  xForwardedIpAddress?: string,
): Promise<void> => {
  const promotionsResult = await PromotionService.getPromotions(
    websiteDetails.jsonWebEncryption,
    xForwardedIpAddress,
  );

  await dispatch(promotionsActions.getPromotionsRequestAction(promotionsResult.data));

  await dispatch(addOrRemovePromotionsCategoryAction());

  await dispatch(orderDiscountsRecalculate());
};

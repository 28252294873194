import { useSelector } from 'react-redux';

import { configSelectors } from 'store';

import { TUseMobile } from './types';

const useMobile: TUseMobile = () => {
  const isMobile = useSelector(configSelectors.getIsMobile);

  return {
    isMobile,
  };
};

export default useMobile;

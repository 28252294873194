import { OPEN_DELIVERY_SELF_PICKUP_REQUEST } from 'store/modules/orderDetails';
import { LOG_OUT_SUCCESS } from '../auth';
import { PLACE_ORDER_SUCCESS } from '../order';
import { PREPARE_TO_PLACE_ORDER_SUCCESS } from './constants';
import { initialPrepareToPlaceOrderState } from './initialState';
import { TPrepareToPlaceOrderReducer } from './types';

const prepareToPlaceOrderReducer: TPrepareToPlaceOrderReducer = (
  state = initialPrepareToPlaceOrderState,
  action,
) => {
  switch (action.type) {
    case PREPARE_TO_PLACE_ORDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case LOG_OUT_SUCCESS:
    case OPEN_DELIVERY_SELF_PICKUP_REQUEST:
    case PLACE_ORDER_SUCCESS:
      return {
        ...initialPrepareToPlaceOrderState,
      };

    default:
      return state;
  }
};

export { prepareToPlaceOrderReducer };

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  TakeEffect,
  takeEvery,
} from '@redux-saga/core/effects';
import { addMinutes } from 'date-fns';
import i18next from 'i18next';
import Router from 'next/router';
import {
  catalogActions,
  ICheckAndFetchBranchCatalogIfNecessaryRequest,
  IFetchCatalogBySubCatalogId,
} from 'store';
import { IShowNotify } from 'store/modules/notify';
import { showNotification } from 'store/modules/notify/actions';
import {
  initialOrderState,
  IOrderTokenUpdate,
  IOrderUpdate,
  orderActions,
} from 'store/modules/order';
import { orderUpdateAction } from 'store/modules/order/actions';
import {
  initialOrderDetailsState,
  IUpdateOrderDetails,
  IUpdateOrderMode,
} from 'store/modules/orderDetails';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import { IClientCouponsUpdate } from 'store/modules/orderDiscounts';
import { clientCouponsUpdate } from 'store/modules/orderDiscounts/actions';
import { stopOrderEditing } from 'store/modules/orderEditing/operations';
import {
  initialPrepareToPlaceOrderState,
  IPrepareToPlaceOrderSuccess,
} from 'store/modules/prepareToPlaceOrder';
import { prepareToPlaceOrderSuccess } from 'store/modules/prepareToPlaceOrder/actions';
import { storeProductsData } from 'store/modules/storeProduct/selectors';
import {
  PREPARE_TO_PLACE_SUBSCRIPTION_SUCCESS,
  START_ADD_SUBSCRIPTION,
  START_EDIT_SUBSCRIPTION,
  STOP_ADD_EDIT_SUBSCRIPTION,
} from 'store/modules/subscriptions/constants';
import {
  IPrepareToPlaceSubscriptionSuccess,
  IStartEditSubscription,
} from 'store/modules/subscriptions/types';
import { IFetchAndUpdateClientCompensationsRequest } from 'store/modules/user';
import { fetchAndUpdateClientCompensationsRequest } from 'store/modules/user/actions';
import { IWebsiteDetails } from 'store/modules/website';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import {
  TOrderItemDTO,
  TProductSellingUnitServer,
  TSourceEventServer,
  TStoreProductServer,
} from 'types';
import { formatSubscriptionDate } from 'utils';
import { filterItemsOutOfStock } from 'utils/helpers/order/items';

function* startAddSubscription(): Generator<
  | PutEffect<IUpdateOrderDetails | IPrepareToPlaceOrderSuccess | IClientCouponsUpdate>
  | CallEffect
  | SelectEffect,
  void,
  any
> {
  const orderMode = yield select(getOrderMode);

  const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);

  if (orderMode === 'edit') {
    yield call(stopOrderEditing);
  }

  if (orderMode === 'editSubscription') {
    yield call(stopAddEditSubscription);
  }

  yield put(
    updateOrderDetails({
      ...initialOrderDetailsState,
      orderType: websiteDetails.websiteSettings.defaultOrderType,
      orderMode: 'addSubscription',
    }),
  );

  yield put(
    prepareToPlaceOrderSuccess({
      ...initialPrepareToPlaceOrderState,
    }),
  );

  yield put(clientCouponsUpdate([]));
  yield call(Router.push, '/');
}

function* startEditSubscription({
  payload,
}: IStartEditSubscription): Generator<
  | SelectEffect
  | TakeEffect
  | PutEffect<
      | IUpdateOrderMode
      | IUpdateOrderDetails
      | IOrderUpdate
      | IPrepareToPlaceOrderSuccess
      | IShowNotify
      | IClientCouponsUpdate
      | IFetchCatalogBySubCatalogId
      | ICheckAndFetchBranchCatalogIfNecessaryRequest
    >
  | CallEffect,
  void,
  any
> {
  const editedSubscription = payload.subscription;

  const { storeProductById, ancestorStoreProductById } = yield select(storeProductsData);

  const { itemsInStock, itemsOutOfStock } = filterItemsOutOfStock(
    editedSubscription.items,
    storeProductById,
    ancestorStoreProductById,
  );

  yield put(
    orderUpdateAction({
      id: editedSubscription.id,
      items: itemsInStock.map((item) => {
        const storeProductItem =
          storeProductById[item.storeProduct?.id as TStoreProductServer['id']] ||
          storeProductById[item.storeProduct?.ancestor?.id as TStoreProductServer['id']] ||
          ancestorStoreProductById[item.storeProduct?.id as TStoreProductServer['id']];
        const populatedItem: TOrderItemDTO = {
          id: item.id,
          storeProduct: {
            ...storeProductItem,
          },
          requestedQuantity: item.requestedQuantity as number,
          requestedSellingUnit: {
            id: item.requestedSellingUnit?.id as TProductSellingUnitServer['id'],
          },
          productComment: item.productComment,
          sourceEvent: item.sourceEvent
            ? item.sourceEvent.name
            : ('unknown' as TSourceEventServer['name']),
        };

        if (item.bagOfProducts) {
          const bagOfProductsJsonParsed = JSON.parse(item.bagOfProductsJson);
          populatedItem.selectedBagItems = bagOfProductsJsonParsed.selectedItems;
        }

        return populatedItem;
      }),
      comments: editedSubscription.comments,
      ecoPackaging: editedSubscription.ecoPackaging,
      itemsSubstitution:
        editedSubscription.itemsSubstitution && editedSubscription.itemsSubstitution.name,
      paymentMethodType:
        editedSubscription.deferredPaymentType || editedSubscription.paymentMethodType,
      subCatalog: editedSubscription.subCatalog?.id,
    }),
  );

  yield put(
    prepareToPlaceOrderSuccess({
      ...initialPrepareToPlaceOrderState,
      encryptedOrderToken: editedSubscription.id,
      orderMinTotalValue: editedSubscription.storeServiceArea.orderMinTotalValue,
      bagsMinTotalValue: editedSubscription.storeServiceArea.bagsMinTotalValue,
      serviceAreaUsageFees: editedSubscription.storeServiceArea.usageFees,
      orderHandlingFeeThreshold: editedSubscription.storeServiceArea.orderHandlingFeeThreshold,
      orderHandlingFee: editedSubscription.storeServiceArea.orderHandlingFee,
    }),
  );

  yield put(clientCouponsUpdate([]));

  yield put(
    updateOrderDetails({
      orderMode: 'editSubscription',
      orderType: editedSubscription.orderType.name,
      selfPickupLocation: {
        name: editedSubscription.storeServiceArea.name,
        storeServiceAreaId: editedSubscription.storeServiceArea.id,
      },
      orderTime: formatSubscriptionDate(
        editedSubscription.frequency,
        editedSubscription.localedPreferredDay,
        editedSubscription.preferredHour,
        editedSubscription.preferredTimeFrameDurationInMinutes,
      ),
      serviceAreaId: editedSubscription.storeServiceArea.id,
      frequency: editedSubscription.frequency,
      preferredDay: editedSubscription.preferredDay,
      preferredHour: editedSubscription.preferredHour,
      lastActivityTime: new Date().getTime(),
      limitationByAgeChecked: editedSubscription.items.some(
        (item) => item.storeProduct?.product.limitedByAge,
      ),
    }),
  );

  yield call(Router.push, '/');
  yield put(catalogActions.checkAndFetchBranchCatalogIfNecessary());

  if (itemsOutOfStock.length > 0) {
    yield put(
      showNotification({
        message: i18next.t('editOrder.removedItemsOutOfStock', {
          removedItems: itemsOutOfStock.map((item) => item.storeProduct?.fullName).join(', '),
        }),
      }),
    );
  }
}

function* stopAddEditSubscription(): Generator<
  | SelectEffect
  | CallEffect
  | PutEffect<
      | IPrepareToPlaceOrderSuccess
      | IUpdateOrderDetails
      | IOrderUpdate
      | IFetchAndUpdateClientCompensationsRequest
      | ICheckAndFetchBranchCatalogIfNecessaryRequest
    >,
  void,
  any
> {
  const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);

  yield put(
    orderUpdateAction({
      ...initialOrderState,
      itemsSubstitution: websiteDetails.websiteSettings.itemsSubstitution
        ? 'substituteWithSimilar'
        : undefined,
    }),
  );

  yield put(
    prepareToPlaceOrderSuccess({
      ...initialPrepareToPlaceOrderState,
    }),
  );

  yield put(
    updateOrderDetails({
      ...initialOrderDetailsState,
      orderType: websiteDetails.websiteSettings.defaultOrderType,
    }),
  );

  yield put(fetchAndUpdateClientCompensationsRequest());
  yield put(catalogActions.checkAndFetchBranchCatalogIfNecessary());
}

export function* prepareToPlaceSubscriptionSuccessSaga({
  payload,
}: IPrepareToPlaceSubscriptionSuccess): Generator<
  SelectEffect | PutEffect<IOrderTokenUpdate | IClientCouponsUpdate | IPrepareToPlaceOrderSuccess>,
  void,
  any
> {
  const prepareToPlaceSubscription = payload;
  // update orderToken in order state
  yield put(
    orderActions.orderTokenUpdateAction(prepareToPlaceSubscription.encryptedSubscriptionToken),
  );

  yield put(
    prepareToPlaceOrderSuccess({
      orderMinTotalValue: prepareToPlaceSubscription.orderMinTotalValue,
      bagsMinTotalValue: prepareToPlaceSubscription.bagsMinTotalValue,
      clientServiceFee: prepareToPlaceSubscription.clientServiceFee,
      serviceAreaUsageFees: prepareToPlaceSubscription.serviceAreaUsageFees,
      encryptedOrderToken: prepareToPlaceSubscription.encryptedSubscriptionToken,
      expirationTime: addMinutes(new Date(), 120).getTime(),
      orderHandlingFee: prepareToPlaceSubscription.orderHandlingFee,
      orderHandlingFeeThreshold: prepareToPlaceSubscription.orderHandlingFeeThreshold,
    }),
  );

  // update coupons to include client coupons
  yield put(clientCouponsUpdate([]));
}

function* rootSubscriptionsSaga(): Generator {
  yield takeEvery(START_ADD_SUBSCRIPTION, startAddSubscription);
  yield takeEvery(START_EDIT_SUBSCRIPTION, startEditSubscription);
  yield takeEvery(STOP_ADD_EDIT_SUBSCRIPTION, stopAddEditSubscription);
  yield takeEvery(PREPARE_TO_PLACE_SUBSCRIPTION_SUCCESS, prepareToPlaceSubscriptionSuccessSaga);
}

export default rootSubscriptionsSaga;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesProductDescription } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesProductDescription,
    { isPopup: boolean; bagOfProducts: boolean; isMobile: boolean; lang: string }
  > =>
    createStyles({
      productDescriptionWrapper: {
        width: 'auto',
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      detailsBlock: ({ isPopup }) => ({
        width: '100%',
        marginLeft: 0,
        marginTop: theme.spacing(22 / 8),
        [theme.breakpoints.up('md')]: {
          width: isPopup ? '344px' : '460px',
          marginLeft: theme.spacing((isPopup ? 30 : 40) / 8),
          marginTop: '0',
        },
      }),
      basicCardWrapper: ({ isPopup }) => ({
        width: 'auto',
        height: '220px',
        [theme.breakpoints.up('md')]: {
          maxWidth: 'auto',
          width: isPopup ? '326px' : '400px',
          height: isPopup ? '326px' : '400px',
        },
      }),
      productNameWrapper: {
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(18 / 8),
        height: 'auto',
        [theme.breakpoints.up('md')]: {
          fontWeight: 'bold',
          lineHeight: 1.9,
        },
      },
      productName: {
        fontFamily: 'Rubik, "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        color: theme.palette.text.secondary,
        fontWeight: 'normal',
        textAlign: 'center',
        lineHeight: 'normal',
        [theme.breakpoints.up('md')]: {
          lineHeight: ({ isPopup }) => (isPopup ? '28px' : '34px'),
          color: ({ isPopup }) =>
            isPopup ? theme.palette.text.primary : theme.palette.secondary.main,
          textAlign: 'inherit',
          fontFamily: ({ isPopup }) =>
            isPopup
              ? 'Rubik, "Roboto", "Helvetica", "Arial", sans-serif'
              : 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: ({ isPopup }) => (isPopup ? '24px' : '30px'),
          fontWeight: ({ isPopup }) => (isPopup ? 'normal' : 'bold'),
        },
      },
      cardActions: {
        padding: 0,
        marginTop: theme.spacing(22 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(24 / 8),
        },
      },
      cardActionsRootContent: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        [theme.breakpoints.up('md')]: {
          margin: '0',
          height: '66px',
          justifyContent: 'flex-start',
          display: 'flex',
        },
      },
      unitButtons: {
        display: 'flex',
        paddingBottom: theme.spacing(24 / 8),
      },
      addToCartButton: {
        paddingBottom: theme.spacing(24 / 8),
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(20 / 8),
        },
      },
      productExtraDetails: {
        fontSize: '16px',
        color: theme.palette.text.secondary,
        lineHeight: 1.4,
        marginBottom: theme.spacing(10 / 8),
        '& .productQualityInBag': {
          color: theme.palette.grey[600],
        },
        [theme.breakpoints.up('md')]: {
          lineHeight: 1.29,
          fontSize: '14px',
        },
      },
      bagExtraDetails: {
        [theme.breakpoints.up('md')]: {
          lineHeight: 1.5,
          fontSize: '16px',
        },
      },
      ratingWrapper: {
        display: 'flex',
        alignItems: 'center',
      },
      descriptionBlock: ({ isPopup }) => ({
        [theme.breakpoints.up('md')]: {
          width: isPopup ? '344px' : '460px',
        },
      }),
      descriptionText: {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        lineHeight: 1.4,
        marginLeft: theme.spacing(6 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
          lineHeight: 1.29,
          marginTop: '0',
          textAlign: 'inherit',
          color: theme.palette.text.secondary,
          marginLeft: theme.spacing(6 / 8),
        },
      },
      iconKnife: {
        color: theme.palette.grey[50],
        fontSize: '8px',
        borderRadius: '50%',
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: theme.spacing(3 / 8),
        background: theme.palette.secondary.main,
      },
      commentTypeText: {
        color: theme.palette.secondary.main,
        fontSize: '16px',
        marginLeft: theme.spacing(4 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
        },
      },
      dividerMobile: {
        display: 'block',
        height: '1px',
        color: theme.palette.grey.A100,
        margin: theme.spacing(24 / 8, 0),
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      whatBagContainDivider: {
        display: 'block',
        marginTop: theme.spacing(6 / 8),
        marginBottom: theme.spacing(18 / 8),
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      aboutProductMobile: {
        lineHeight: '22px',
        display: 'block',
        fontSize: '18px',
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(10 / 8),
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      whatBagContain: {
        display: 'block',
        fontSize: '18px',
        color: theme.palette.secondary.main,
        lineHeight: 1.22,
        [theme.breakpoints.up('md')]: {
          marginBottom: '0',
          fontSize: '22px',
          lineHeight: '24px',
        },
      },
      moreBagDetails: {
        marginBottom: theme.spacing(10 / 8),
        [theme.breakpoints.up('md')]: {
          marginBottom: '0',
        },
      },
      editBag: {
        fontSize: '18px',
        [theme.breakpoints.up('md')]: {
          fontSize: '20px',
        },
      },
      bagItemsDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'block',
        },
      },
      bagMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      discountWrapper: {
        marginBottom: '30px',
        borderRadius: '4px',
        backgroundColor: theme.palette.grey.A100,
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(14 / 8),
        },
      },
      discountBody: {
        padding: theme.spacing(10 / 8, 10 / 8, 0),
        display: 'flex',
      },
      limitationTypography: {
        fontSize: '12px',
        lineHeight: 1.33,
        color: theme.palette.grey[600],
      },
      limitationMainPart: {
        borderRadius: '0 0 4px 4px',
        backgroundColor: 'rgb(0,0,0, 0.04)',
        padding: theme.spacing(6 / 8, 10 / 8),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      },
      discountIcon: {
        color: theme.palette.primary.main,
        fontSize: '18px',
        marginRight: '6px',
      },
      externalNotes: {
        fontSize: '14px',
        lineHeight: 1.29,
        color: theme.palette.text.secondary,
      },
      pricePerUnit: ({ isMobile, lang }) => ({
        fontSize: isMobile ? '26px' : '24px',
        lineHeight: isMobile ? '26px' : '24px',
        color: theme.palette.text.primary,
        fontWeight: lang === 'ru' ? 700 : 600,
      }),
      sellingUnit: {
        fontSize: '20px',
        lineHeight: '20px',
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
      priceMainWrapper: ({ isMobile }) => ({
        display: isMobile ? 'block' : 'flex',
        alignItems: 'baseline',
        marginBottom: isMobile ? 0 : theme.spacing(1),
      }),
      priceWrapper: ({ isMobile }) => ({
        display: 'flex',
        alignItems: 'center',
        marginBottom: isMobile ? theme.spacing(5 / 8) : 0,
        justifyContent: isMobile ? 'center' : 'initial',
      }),
      promotionPriceWrapper: ({ isMobile }) => ({
        marginBottom: isMobile ? '6px !important' : 0,
        marginLeft: isMobile ? 0 : '12px',
      }),
      originalPriceAndSellingUnit: {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#7A7A7A',
        textDecoration: 'line-through',
      },
      pricePerGram: {
        fontSize: '14px',
        lineHeight: '14px',
        color: '#7A7A7A',
        fontWeight: 'normal',
      },
      promotionShortName: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '20px',
        color: theme.palette.error.main,
        letterSpacing: 'normal',
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        '-webkitLineClamp': 1,
        overflow: 'hidden !important',
      },
      countryIcon: ({ isMobile }) => ({
        width: isMobile ? '16px' : '18px !important',
        height: isMobile ? '16px' : '18px !important',
        // this is trick to remove blur of svg on IOS
        borderRadius: '50%',
        overflow: 'hidden',
      }),
      arrowIcon: {
        fontSize: '11px',
        marginLeft: '3px',
      },
      viewIncentivesButton: {
        minWidth: '100%',
        padding: theme.spacing(6 / 8, 0, 0, 33 / 8),
        justifyContent: 'flex-start',
      },
    }),
);

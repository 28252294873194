import { get } from 'lodash';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getIdsAndByIds = <T extends Record<string, any>, K = string>(
  arr: T[],
  key: string = 'id',
): {
  arrayIds: Array<K>;
  objById: Record<string, T>;
} => {
  const results: {
    arrayIds: Array<K>;
    objById: Record<string, T>;
  } = {
    arrayIds: [],
    objById: {},
  };

  arr.forEach((item) => {
    const objectKey = get(item, key);

    results.arrayIds.push(objectKey);
    results.objById[objectKey] = { ...item };
  });
  return results;
};

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesTooltipOnIcon } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesTooltipOnIcon> =>
    createStyles({
      questionIcon: {
        fontSize: '16px',
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(4 / 8),
        overflow: 'initial',
      },
      questionIconButton: {
        padding: '0',
        marginBottom: theme.spacing(2 / 8),
      },
      tooltip: {
        width: '221px',
        padding: theme.spacing(1),
        backgroundColor: `${theme.palette.secondary.main}!important`,
        top: '-14px !important',
        left: '2px !important',
        [theme.breakpoints.up('md')]: {
          top: '-5px !important',
          left: '1px !important',
        },
      },
      tooltipTitle: {
        fontSize: '14px',
        lineHeight: '16px',
      },
    }),
);

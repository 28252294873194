/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, PutEffect, takeEvery } from '@redux-saga/core/effects';
import { omit } from 'lodash';
import { processBannersByCategory } from 'utils/helpers/categoryBanners';
import { defaultWebsiteSettings } from 'store/modules/website/initialState';
import { GET_WEBSITE_DETAILS_REQUEST } from './constants';

import {
  IGetWebsiteDetailsRequest,
  IGetWebsiteDetailsSuccess,
  TWebsiteReducerState,
} from './types';
import { getWebsiteSuccess } from './actions';

function* getWebsiteDetails({
  payload,
}: IGetWebsiteDetailsRequest): Generator<PutEffect<IGetWebsiteDetailsSuccess>, void, any> {
  const { newWebsiteDetails } = payload;

  const newWebsiteState: TWebsiteReducerState = {
    websiteDetails: {
      ...omit(newWebsiteDetails, 'themeJson', 'banners', 'metadataJson'),
      externalWebTrackingAccounts: JSON.parse(newWebsiteDetails.externalWebTrackingAccounts) || [],
      theme: JSON.parse(newWebsiteDetails.themeJson),
      metadata: JSON.parse(newWebsiteDetails.metadataJson) || {},
      websiteSettings: {
        ...defaultWebsiteSettings,
        ...(JSON.parse(newWebsiteDetails.settingsJson) || {}),
        allowClosingDeliveringToAddressPopup: newWebsiteDetails.store.hasOnlineBranches
          ? (JSON.parse(newWebsiteDetails.settingsJson).allowClosingDeliveringToAddressPopup !==
              undefined &&
              JSON.parse(newWebsiteDetails.settingsJson).allowClosingDeliveringToAddressPopup) ||
            false
          : true,
        onlySelfPickups:
          JSON.parse(newWebsiteDetails.settingsJson).defaultOrderType === 'selfPickup' &&
          JSON.parse(newWebsiteDetails.settingsJson).onlySelfPickups,
      },
      showWelcomeDialog: true,
      lastTimeShowingWelcomeDialog: 0,
      store: {
        ...newWebsiteDetails.store,
        businessFullAddressWithCity:
          newWebsiteDetails.businessAddressFormatted ||
          newWebsiteDetails.store.businessFullAddressWithCity,
      },
      bannersByCategory: processBannersByCategory(newWebsiteDetails.banners),
    },
  };
  // update website products with promotion property
  yield put(getWebsiteSuccess(newWebsiteState));
}

function* rootStoreSaga(): Generator {
  yield takeEvery(GET_WEBSITE_DETAILS_REQUEST, getWebsiteDetails);
}

export default rootStoreSaga;

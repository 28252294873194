import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesImagesWrapper } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesImagesWrapper> =>
    createStyles({
      title: {
        fontSize: '26px',
        lineHeight: '28px',
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontFamily: 'Heebo',
        marginBottom: '22px',
        padding: '0 14px',
      },
      mobileImagesBannerSlider: {
        display: 'flex',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'transparent !important',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent !important',
          borderRadius: 'none',
        },
        paddingTop: '7px',
      },
      loadMoreButton: {
        width: '160px',
        height: '40px',
        fontWeight: 'normal',
        border: `1.5px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          width: '150px',
        },
      },
      loadMoreButtonLabel: {
        fontSize: '16px',
        color: theme.palette.grey.A700,
      },
      buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
      },
    }),
);

import { TStore, TUseStore } from 'store/types';

import { makeStore } from './makeStore';

import { TInitializeStore } from './types';

let store: TStore | undefined;

const initializeStore: TInitializeStore = (preloadedState) => {
  // eslint-disable-next-line no-underscore-dangle
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current website
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

const useStore: TUseStore = (initialState) => {
  return initializeStore(initialState);
};

export default useStore;

import { stringify } from 'qs';

import { BaseApi } from 'api/Api';
import {
  TCancelOrder,
  TEditOrder,
  TGetAvailableServiceAreas,
  TGetOrder,
  TGetOrderInvoice,
  TPlaceOrder,
  TPrepareToPlaceOrder,
} from './types';

export default class OrderService {
  static getAvailableServiceAreas: TGetAvailableServiceAreas = async (params, config) => {
    return BaseApi.instance(config).get(
      `/client/client/stores/store-available-service-areas?${stringify(params)}`,
    );
  };

  static getOrder: TGetOrder = async (orderId, config) => {
    return BaseApi.instance(config).get(
      `/client/client/orders/my-orders/view-order?orderId=${orderId}`,
    );
  };

  static getOrderInvoice: TGetOrderInvoice = async (orderId, config) => {
    return BaseApi.instance(config).get(`/client/client/invoice/${orderId}`);
  };

  static cancelOrder: TCancelOrder = async (orderId) => {
    return BaseApi.instance().post(
      `/client/client/orders/my-orders/cancel-order`,
      `orderId=${orderId}`,
    );
  };

  static prepareToPlaceOrder: TPrepareToPlaceOrder = async (body) => {
    const bodyTransformed = {
      ...body,
      timeFrame: { ...body.timeFrame, dayOfWeek: body.timeFrame.dayOfWeek?.name },
    };
    return BaseApi.instance().post(
      '/client/client/orders/new/prepare-to-place-order',
      bodyTransformed,
    );
  };

  static placeOrder: TPlaceOrder = async (body) => {
    return BaseApi.instance().post('/client/client/orders/new/place-order', body);
  };

  static editOrder: TEditOrder = async (body) => {
    return BaseApi.instance().post('/client/client/orders/my-orders/edit-order', body);
  };
}

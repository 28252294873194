import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { MAX_CONTAINER_WIDTH, MIN_TABLET_WIDTH } from 'ui/common/Container';
import { TStylesPopper } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPopper, { lang: string }> =>
    createStyles({
      buttonRoot: {
        // reset default button width
        width: '30px',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        [theme.breakpoints.between(MIN_TABLET_WIDTH, MAX_CONTAINER_WIDTH)]: {
          marginRight: theme.spacing(1.5), // 12px
        },
      },
      iconBtn: {
        fontSize: theme.spacing(3),
      },
      selected: {
        color: theme.palette.primary.main,
      },
      popper: ({ lang }) => ({
        zIndex: 1,
        padding: theme.spacing(2, 2, 0, 1.5), // 16px 16px 0px 12px
        height: theme.spacing(12.5), // 100px
        width: lang === 'ru' ? '235px' : '230px',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 0.5px rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(6 / 8),
      }),
      arrow: {
        position: 'absolute',
        top: 0,
        width: theme.spacing(6),
        marginTop: theme.spacing(-1),
        fontSize: theme.spacing(1),
        '&::before': {
          width: 0,
          height: 0,
          margin: 'auto',
          content: '" "',
          display: 'block',
          borderStyle: 'solid',
          borderColor: 'transparent transparent #fff transparent',
          borderWidth: theme.spacing(0, 2, 2, 2),
        },
      },
    }),
);

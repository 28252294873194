import React, { MouseEvent, useMemo } from 'react';

import Link from 'ui/common/Link';
import Typography, { ITypography } from 'ui/common/Typography';

import { Box, Button } from '@material-ui/core';

import useStyles from './styles';

import { TRenderTerms, TUseFooterTerms } from './types';

const useFooterTerms: TUseFooterTerms = (termsItems) => {
  const classes = useStyles();

  const renderTerms = useMemo<TRenderTerms>(
    () => (options) => {
      const typographyProps: ITypography = {
        color: 'light',
        fontSize: 13,
        ...options,
      };
      return (
        <>
          {termsItems?.map(({ label, link, id, onClick }) => {
            return (
              <Link key={id} className={classes.termsLink} {...link}>
                <Box className={classes.termsBox}>
                  <Button
                    onClick={(e: MouseEvent<HTMLButtonElement>) => onClick(e)}
                    className="padding-0"
                    disableRipple
                  >
                    <Typography className={classes.termsItem} {...typographyProps}>
                      {label}
                    </Typography>
                  </Button>
                </Box>
              </Link>
            );
          })}
        </>
      );
    },
    [classes, termsItems],
  );

  return { renderTerms };
};

export default useFooterTerms;

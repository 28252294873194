import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store';
import { TFloorAndApartmentLabel, TUsePersonalDetails } from './types';

const usePersonalDetails: TUsePersonalDetails = () => {
  const profile = useSelector(userSelectors.getProfile);

  const { t } = useTranslation();

  const floorAndApartmentLabel = useMemo<TFloorAndApartmentLabel>(() => {
    if (profile.floor || profile.apartment) {
      if (profile.apartment && profile.floor) {
        return t('personalDetails.floorApartment', {
          floor: profile.floor,
          apartment: profile.apartment,
        });
      }
      if (profile.floor) {
        return t('personalDetails.floor', { floor: profile.floor });
      }
      if (profile.apartment) {
        return t('personalDetails.apartment', { apartment: profile.apartment });
      }
    }
  }, [profile.apartment, profile.floor, t]);
  return {
    floorAndApartmentLabel,
  };
};

export default usePersonalDetails;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from '@redux-saga/core/effects';

import { OrderService, TPlaceOrderOrderBody, TPrepareToPlaceOrderBody } from 'api';
import { SubscriptionService } from 'api/services/SubscriptionService';
import { TPaymentMethodForPlaceOrder } from 'components/common/forms';
import { paymentMethodCreator } from 'components/common/forms/PaymentForm/helpers';
import i18next from 'i18next';

import { filter, find } from 'lodash';

import Router from 'next/router';

import {
  catalogActions,
  CLEANUP_HALF_DELETED_ITEMS_REQUEST,
  configSelectors,
  dialogActions,
  GENERIC_DIALOG,
  ICheckAndFetchBranchCatalogIfNecessaryRequest,
  IFetchAndUpdateClientCompensationsRequest,
  IFetchAndUpdateClientDetailsAtStoreLevel,
  IGetClubMembershipProfileRequest,
  IProductCartDeletedSuccess,
  IPromotion,
  IShowDialog,
  ITogglePartlyRemoveProductFromCartRequest,
  IUpdateCourierTip,
  IUpdateDetailsAtStoreLevel,
  IUpdateOrderDetails,
  IUpdateOrderItemCommentRequest,
  IUpdateOrderItemSelectedBagItemsRequest,
  IWebsiteDetails,
  notifyActions,
  orderActions,
  orderDetailsActions,
  orderSelectors,
  prepareToPlaceOrderSelectors,
  PRODUCT_CART_DELETED_REQUEST,
  PRODUCT_CART_DELETED_SUCCESS,
  storeProductActions,
  storeProductSelectors,
  TOrderDetailsReducerState,
  TOrderDiscount,
  TPrepareToPlaceOrderReducerState,
  TStoreProductReducerState,
  UPDATE_CLIENT_ACTIVITY_TIME,
  UPDATE_ORDER_ITEM_COMMENT_REQUEST,
  UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST,
  userActions,
  websiteSelectors,
} from 'store';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import {
  getOrderDiscounts,
  getOrderMembershipBenefits,
} from 'store/modules/orderDiscounts/selectors';
import { promotionsData } from 'store/modules/promotions/selectors';
import { fetchAndUpdateClientCompensationsRequest } from 'store/modules/user/actions';
import { getDetailsAtStoreLevel } from 'store/modules/user/selectors';
import { getPersistor } from 'store/useStore/persist';

import { TOrderItemDTO } from 'types';
import perform3dSecure, {
  T3DSecureAuthenticateProcessedResult,
  T3DSecurePlaceOrderFailureData,
} from 'utils/helpers/3dSecure/3dSecure';
import { setBagOfProductsReceivedJson } from 'utils/helpers/order/items/bagOfProducts';

import { IShowNotify } from '../notify';
import { IUpdateProductByIdSuccess } from '../storeProduct';

import { placeOrderSuccessAction, productCartWasDeletedSuccess } from './actions';

import {
  PLACE_ORDER_REQUEST,
  SET_ORDER_ITEM_REQUEST,
  TOGGLE_PARTLY_REMOVE_PRODUCT_FROM_CART,
  UPDATE_ORDER_ITEM_UNIT_REQUEST,
} from './constants';
import { orderItems } from './selectors';

import {
  IPlaceOrderRequest,
  IPlaceOrderSuccess,
  ISetOrderItemRequest,
  ISetOrderItemsSuccess,
  IUpdateOrderItemUnitRequest,
  TOrderReducerState,
} from './types';

function* setOrderItem({
  payload,
}: ISetOrderItemRequest): Generator<
  SelectEffect | PutEffect<ISetOrderItemsSuccess> | PutEffect<IShowNotify>,
  void,
  any
> {
  const { storeProduct, action, sourceEvent } = payload;

  // get order items from redux store
  const currentOrderItems: TOrderItemDTO[] = yield select(orderItems);

  // find product in current oder items array
  const existingOrderItem = find(currentOrderItems, ['storeProduct.id', storeProduct.id]);

  // get active unit
  const activeUnit = find(storeProduct.productSellingUnits, [
    'id',
    existingOrderItem
      ? existingOrderItem.requestedSellingUnit.id
      : storeProduct.defaultSelectedSellingUnit.id,
  ]);

  if (activeUnit) {
    let newOrderItems: TOrderItemDTO[] | undefined;
    // active unit id, need for OrderItemDTO item
    const requestedSellingUnitId = activeUnit.id;
    const { amountJumps, maxAmount } = activeUnit;
    // get current quantity
    const existingRequestedQuantity = existingOrderItem?.requestedQuantity;

    switch (true) {
      // if product exist in order need to increase only requestedQuantity
      case action === 'add' && !!existingOrderItem:
        newOrderItems = currentOrderItems.map((orderItem) => {
          const newOrderItem = { ...orderItem };
          if (storeProduct.id === orderItem.storeProduct.id) {
            if (orderItem.isRemoved) {
              newOrderItem.isRemoved = false;
              newOrderItem.requestedQuantity = 0;
            }

            newOrderItem.requestedQuantity += amountJumps;
            newOrderItem.updateTime = new Date().getTime();
          }

          return newOrderItem;
        });
        break;
      // if not exist in order need to create new order item and push in array
      case action === 'add' && !existingOrderItem: {
        const newOrderItem: TOrderItemDTO = {
          storeProduct: { ...storeProduct },
          requestedQuantity: amountJumps,
          requestedSellingUnit: { id: requestedSellingUnitId },
          insertTime: new Date().getTime(),
          updateTime: new Date().getTime(),
          sourceEvent,
        };
        newOrderItems = [...currentOrderItems];
        newOrderItems.push(newOrderItem);
        break;
      }
      // delete item from array
      // if quantity is equal amountJumps, need to remove item from array
      case action === 'delete':
      case action === 'remove' && existingRequestedQuantity === amountJumps:
        newOrderItems = currentOrderItems.filter(
          (orderItem) => orderItem.storeProduct.id !== storeProduct.id,
        );
        break;
      // if more than amountJumps just need to decrease by amountJumps requestedQuantity
      case action === 'remove' &&
        existingRequestedQuantity &&
        existingRequestedQuantity > amountJumps:
        newOrderItems = currentOrderItems.map((orderItem) => {
          const newOrderItem = { ...orderItem };

          if (storeProduct.id === orderItem.storeProduct.id) {
            newOrderItem.requestedQuantity -= amountJumps;
          }
          return newOrderItem;
        });
        break;
      default:
        break;
    }
    const currentOrderItem = find(newOrderItems, ['storeProduct.id', storeProduct.id]);
    // if current requestedQuantity not more than maxAmount avoid update order items
    if (currentOrderItem && currentOrderItem?.requestedQuantity > maxAmount) {
      yield put(
        notifyActions.showNotification({
          message: 'error.maxAmountOfProduct',
        }),
      );

      return;
    }
    // update store items only if we have newOrderItems and
    if (newOrderItems) {
      yield put(orderActions.setOrderItemsSuccessAction(newOrderItems, true));
    }
  }
}

function* updateSellingUnit({
  payload,
}: IUpdateOrderItemUnitRequest): Generator<
  SelectEffect | PutEffect<IUpdateProductByIdSuccess> | PutEffect<ISetOrderItemsSuccess>,
  void,
  any
> {
  const { storeProductId, sellingUnitId } = payload;

  // get order items from redux store
  const currentOrderItems: TOrderItemDTO[] = yield select(orderItems);

  // get store products
  const {
    storeProductById,
  }: {
    storeProductIds: TStoreProductReducerState['storeProductIds'];
    storeProductById: TStoreProductReducerState['storeProductById'];
  } = yield select(storeProductSelectors.storeProductsData);

  // get clicked unit
  const activeUnit =
    storeProductById[storeProductId] &&
    find(storeProductById[storeProductId].productSellingUnits, ['id', sellingUnitId]);

  if (activeUnit) {
    const { amountJumps, maxAmount } = activeUnit;

    // update order items with new requestedSellingUnit
    const newOrderItems: TOrderItemDTO[] = currentOrderItems.map((orderItem) => {
      const newOrderItem = { ...orderItem };

      if (orderItem.storeProduct.id === storeProductId) {
        newOrderItem.requestedSellingUnit = activeUnit;

        // check if requestedQuantity ready for new amountJumps
        const remainderQuantity = newOrderItem.requestedQuantity % amountJumps;
        // if remainderQuantity not equal to 0 decrease requestedQuantity by remainderQuantity
        if (remainderQuantity !== 0) {
          newOrderItem.requestedQuantity -= remainderQuantity;
        }

        if (newOrderItem.requestedQuantity === 0) {
          newOrderItem.requestedQuantity += amountJumps;
        }

        // if requestedQuantity more than maxAmount change requestedQuantity to maxAmount
        if (newOrderItem.requestedQuantity > maxAmount) {
          newOrderItem.requestedQuantity = maxAmount;
        }
      }

      return newOrderItem;
    });

    // remove order items with requestedQuantity = 0
    const filteredOrderItem = filter<TOrderItemDTO>(
      newOrderItems,
      ({ requestedQuantity }) => !!requestedQuantity,
    );

    // TODO consider to remove this up to "yield put(storeProductActions.updateProductByIdSuccess(newStoreProductById))";
    const newStoreProductById: TStoreProductReducerState['storeProductById'] = {
      ...storeProductById,
    };

    newStoreProductById[storeProductId].defaultSelectedSellingUnit = activeUnit;

    // update product
    yield put(storeProductActions.updateProductByIdSuccess(newStoreProductById));

    // update order items
    yield put(orderActions.setOrderItemsSuccessAction(filteredOrderItem, true));
  }
}

function* updateProductComment({
  payload,
}: IUpdateOrderItemCommentRequest): Generator<
  SelectEffect | PutEffect<ISetOrderItemsSuccess>,
  void,
  any
> {
  const { storeProductId, productComment } = payload;
  // get order items from redux store
  const currentOrderItems: TOrderItemDTO[] = yield select(orderItems);

  const newOrderItems: TOrderItemDTO[] = currentOrderItems.map((orderItem) => {
    if (orderItem.storeProduct.id === storeProductId) {
      return { ...orderItem, productComment };
    }
    return orderItem;
  });

  // update order items
  yield put(orderActions.setOrderItemsSuccessAction(newOrderItems));
}

function* togglePartlyRemoveProductFromCart({
  payload,
}: ITogglePartlyRemoveProductFromCartRequest): Generator<
  SelectEffect | PutEffect<ISetOrderItemsSuccess>,
  void,
  any
> {
  const { storeProductId } = payload;
  // get order items from redux store
  const currentOrderItems: TOrderItemDTO[] = yield select(orderItems);

  const newOrderItems: TOrderItemDTO[] = currentOrderItems.map((orderItem) => {
    if (orderItem.storeProduct.id === storeProductId) {
      return { ...orderItem, isRemoved: !orderItem.isRemoved };
    }
    return orderItem;
  });

  // update order items
  yield put(orderActions.setOrderItemsSuccessAction(newOrderItems));
}

function* cleanupHalfDeletedItems(): Generator<
  SelectEffect | PutEffect<ISetOrderItemsSuccess>,
  void,
  any
> {
  const currentOrderItems: TOrderItemDTO[] = yield select(orderItems);

  const newOrderItems: TOrderItemDTO[] = currentOrderItems.filter(
    (orderItem) => !orderItem.isRemoved,
  );

  yield put(orderActions.setOrderItemsSuccessAction(newOrderItems));
}

function* updateSelectedBagItems({
  payload,
}: IUpdateOrderItemSelectedBagItemsRequest): Generator<
  SelectEffect | PutEffect<ISetOrderItemsSuccess>,
  void,
  any
> {
  const { storeProductId, selectedBagItems, sourceEvent } = payload;
  // get order items from redux store
  const currentOrderItems: TOrderItemDTO[] = yield select(orderItems);

  const newOrderItems: TOrderItemDTO[] = currentOrderItems.map((orderItem) => {
    if (orderItem.storeProduct.id === storeProductId) {
      return { ...orderItem, selectedBagItems, sourceEvent };
    }
    return orderItem;
  });

  // update order items
  yield put(orderActions.setOrderItemsSuccessAction(newOrderItems));
}

function* placeOrder({
  payload,
}: IPlaceOrderRequest): Generator<
  | SelectEffect
  | PutEffect<
      | IPlaceOrderSuccess
      | IShowNotify
      | IShowDialog
      | IUpdateDetailsAtStoreLevel
      | IFetchAndUpdateClientCompensationsRequest
      | ICheckAndFetchBranchCatalogIfNecessaryRequest
      | IGetClubMembershipProfileRequest
      | IFetchAndUpdateClientDetailsAtStoreLevel
    >
  | CallEffect,
  void,
  any
> {
  const {
    cartEstimation,
    paymentMethod,
    paymentMethodType,
    numberOfCreditPayments,
    uiHelpers,
    threeDSecureDetails,
    terminalId,
    updateSubscription,
  } = payload;

  if (uiHelpers) {
    uiHelpers.setLoading(true);
  }

  const websiteDetails: IWebsiteDetails = yield select(websiteSelectors.getWebsiteDetails);
  const order: TOrderReducerState = yield select(orderSelectors.orderData);
  const orderDetails: TOrderDetailsReducerState = yield select(getOrderDetails);
  const orderDiscounts: TOrderDiscount[] = yield select(getOrderDiscounts);
  const detailsAtStoreLevel = yield select(getDetailsAtStoreLevel);
  const isMobile = yield select(configSelectors.getIsMobile);
  const isIOS = yield select(configSelectors.getIsIos);
  const { clubMembershipProfile } = yield select(promotionsData);
  const orderMembershipBenefits = yield select(getOrderMembershipBenefits);

  const { promotionsForBanners } = yield select(promotionsData);

  const isPromotionForNewClientExist = promotionsForBanners.some(
    (promotion: IPromotion) => promotion.promotionType.name === 'forNewCustomer',
  );

  const prepareToPlaceOrder: TPrepareToPlaceOrderReducerState = yield select(
    prepareToPlaceOrderSelectors.getPrepareToPlaceOrder,
  );

  const {
    storeProductById,
    outOfStockStoreProductById,
  }: {
    storeProductById: TStoreProductReducerState['storeProductById'];
    outOfStockStoreProductById: TStoreProductReducerState['outOfStockStoreProductById'];
  } = yield select(storeProductSelectors.storeProductsData);

  let paymentMethodToPost: TPaymentMethodForPlaceOrder = {};
  let externalDiscounts: TPlaceOrderOrderBody['externalDiscounts'];

  if (paymentMethodType === 'creditCard') {
    paymentMethodToPost = { ...paymentMethodCreator(paymentMethod), threeDSecureDetails };

    if (paymentMethodToPost.newPaymentMethod) {
      paymentMethodToPost.newPaymentMethod = {
        ...paymentMethodToPost.newPaymentMethod,
        terminalId,
      };
    }
  }

  if (clubMembershipProfile.length) {
    externalDiscounts = [
      {
        company: clubMembershipProfile[0].company.name,
        metadata: JSON.stringify({
          budgetToUse: orderMembershipBenefits?.budgetToUse || 0,
          requestedBenefits: orderMembershipBenefits?.requestedBenefits
            ? Array.from(Object.keys(orderMembershipBenefits.requestedBenefits), (key) => ({
                id: key,
                title: orderMembershipBenefits?.requestedBenefits[key].title,
              }))
            : [],
        }),
      },
    ];
  }

  const orderPostData: TPlaceOrderOrderBody = {
    ...order,
    items: order.items.map((item) => ({
      ...setBagOfProductsReceivedJson(item, storeProductById, outOfStockStoreProductById),
      storeProduct: { id: item.storeProduct.id },
    })),
    numberOfCreditPayments,
    comments: order.comments,
    rxclid: websiteDetails.rexailAffiliateId,
    courierTip: orderDetails.courierTip || 0,
    paymentMethodType: paymentMethodType === 'cash' ? 'deferredPayment' : paymentMethodType,
    updateSubscription,
    ...(paymentMethodType === 'cash' && { deferredPaymentType: 'cash' }),
    ...paymentMethodToPost,
    externalDiscounts,
  };

  try {
    let actionResult;
    switch (orderDetails.orderMode) {
      case 'new':
      case 'edit':
      default:
        actionResult = yield call(
          orderDetails.orderMode === 'edit' ? OrderService.editOrder : OrderService.placeOrder,
          orderPostData,
        );

        if (paymentMethodType === 'bit') {
          if (isMobile) {
            yield call(
              Router.push,
              actionResult.data.paymentResponse[isIOS ? 'iosPaymentLink' : 'androidPaymentLink'],
            );
          }
          yield call(
            Router.push,
            `/checkout/order-completed/${actionResult.data.id}?android=${encodeURIComponent(
              actionResult.data.paymentResponse.androidPaymentLink,
            )}&ios=${encodeURIComponent(actionResult.data.paymentResponse.iosPaymentLink)}`,
          );
        } else {
          yield call(Router.push, `/checkout/order-completed/${actionResult.data.id}`);
        }
        break;
      case 'addSubscription':
        actionResult = yield call(SubscriptionService.placeSubscription, {
          ...orderPostData,
          subscriptionToken: orderPostData.orderToken,
          storeServiceAreaId: orderDetails.serviceAreaId as number,
          frequency: orderDetails.frequency?.name as string,
          preferredDay: orderDetails.preferredDay as number,
          preferredHour: orderDetails.preferredHour as number,
        });
        yield call(Router.push, `/checkout/subscription-completed/${actionResult.data.id}`);
        break;
      case 'editSubscription':
        yield call(SubscriptionService.editSubscription, {
          ...orderPostData,
          orderType: orderDetails.orderType as TPrepareToPlaceOrderBody['orderType'],
          storeServiceAreaId: orderDetails.serviceAreaId as number,
          frequency: orderDetails.frequency?.name as string,
          preferredDay: orderDetails.preferredDay as number,
          preferredHour: orderDetails.preferredHour as number,
        });
        yield call(Router.push, `/checkout/subscription-completed/${(orderPostData as any).id}`);
        break;
    }

    if (isPromotionForNewClientExist && detailsAtStoreLevel && !detailsAtStoreLevel.ordersCount) {
      yield put(userActions.fetchAndUpdateClientDetailsAtStoreLevelAction());
    }
    // reset orderReducer reducer
    // reset prepareToPlaceOrderReducer
    yield put(
      placeOrderSuccessAction({
        order,
        orderDiscounts,
        prepareToPlaceOrder,
        defaultOrderType: websiteDetails.websiteSettings.defaultOrderType,
      }),
    );
    yield put(catalogActions.checkAndFetchBranchCatalogIfNecessary());
    yield put(fetchAndUpdateClientCompensationsRequest());

    getPersistor().persist();
  } catch (e: any) {
    if (e.code === 'creditCardNeedToPerform3DSecure') {
      let result: T3DSecureAuthenticateProcessedResult;
      const placeOrderFailureData = e.failureData;
      try {
        result = yield call(
          perform3dSecure,
          cartEstimation,
          paymentMethodToPost,
          placeOrderFailureData as T3DSecurePlaceOrderFailureData,
        );
      } catch (threeDSecureError) {
        yield put(
          dialogActions.showDialog({
            dialogType: GENERIC_DIALOG,
            contentProps: {
              title: 'dialog.threeDSecure.errorTitle',
              body: 'dialog.threeDSecure.apiLoadError',
              buttons: [
                {
                  text: 'dialog.threeDSecure.okButton',
                  variant: 'contained',
                  closeButton: true,
                },
              ],
            },
          }),
        );
        return;
      }

      if (result.result !== 'APPROVED') {
        yield put(
          dialogActions.showDialog({
            dialogType: GENERIC_DIALOG,
            contentProps: {
              translate: false,
              title: i18next.t('dialog.threeDSecure.errorTitle'),
              body:
                result.errCode || result.errorDescription
                  ? i18next.t('dialog.threeDSecure.authError', {
                      errorCode: `${result.errorDescription} (${result.errCode})`,
                    })
                  : i18next.t('dialog.threeDSecure.genericAuthError'),
              buttons: [
                {
                  text: 'dialog.threeDSecure.okButton',
                  variant: 'contained',
                  closeButton: true,
                },
              ],
            },
          }),
        );
        return;
      }

      // 3d secure success
      yield call(placeOrder, {
        payload: {
          ...payload,
          threeDSecureDetails: {
            electronicCommerceIndicator: result.eci,
            transactionId: result.xid,
            authVerificationValue: result.cavv,
            version: result.cg3dsVersion,
            card3DSExternalId: result.userPaymentOptionId,
          },
          terminalId: placeOrderFailureData.terminalId,
        },
      } as IPlaceOrderRequest);
    }
    if (e.code === 'orderExpired') {
      yield put(
        dialogActions.showDialog({
          dialogType: GENERIC_DIALOG,
          contentProps: {
            title: 'error.oops',
            body: 'dialog.unavailableTimeframe',
            buttons: [
              {
                text: 'button.ok',
                variant: 'contained',
                closeButton: true,
              },
            ],
          },
        }),
      );
      yield call(Router.replace, '/checkout/timeframe');
      return;
    }
  } finally {
    if (paymentMethod.formikHelpers && paymentMethod.formikHelpers.setSubmitting) {
      paymentMethod.formikHelpers.setSubmitting(false);
    }

    if (uiHelpers) {
      uiHelpers.setLoading(false);
    }
  }
}

function* updateClientActivity(): Generator<
  SelectEffect | PutEffect<IUpdateOrderDetails>,
  void,
  any
> {
  const orderDetails: TOrderDetailsReducerState = yield select(getOrderDetails);

  const newOrderDetails: TOrderDetailsReducerState = {
    ...orderDetails,
    lastActivityTime: new Date().getTime(),
  };

  yield put(updateOrderDetails(newOrderDetails));
}

function* deleteProductCart(): Generator<
  | SelectEffect
  | PutEffect<ISetOrderItemsSuccess>
  | PutEffect<IUpdateOrderDetails>
  | PutEffect<IUpdateCourierTip>
  | PutEffect<IProductCartDeletedSuccess>,
  void,
  any
> {
  yield put(orderActions.setOrderItemsSuccessAction([]));

  yield put(orderDetailsActions.updateCourierTip(0));

  yield put(productCartWasDeletedSuccess());
}

function* rootOrderSaga(): Generator {
  yield takeEvery(SET_ORDER_ITEM_REQUEST, setOrderItem);
  yield takeEvery(UPDATE_ORDER_ITEM_UNIT_REQUEST, updateSellingUnit);
  yield takeEvery(UPDATE_ORDER_ITEM_COMMENT_REQUEST, updateProductComment);
  yield takeEvery(TOGGLE_PARTLY_REMOVE_PRODUCT_FROM_CART, togglePartlyRemoveProductFromCart);
  yield takeEvery(UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST, updateSelectedBagItems);
  yield takeEvery(PLACE_ORDER_REQUEST, placeOrder);
  yield takeEvery(PRODUCT_CART_DELETED_REQUEST, deleteProductCart);
  yield takeEvery(CLEANUP_HALF_DELETED_ITEMS_REQUEST, cleanupHalfDeletedItems);
  yield takeEvery(
    [
      SET_ORDER_ITEM_REQUEST,
      UPDATE_ORDER_ITEM_UNIT_REQUEST,
      UPDATE_ORDER_ITEM_COMMENT_REQUEST,
      UPDATE_ORDER_ITEM_SELECTED_BAG_ITEMS_REQUEST,
      PRODUCT_CART_DELETED_SUCCESS,
      UPDATE_CLIENT_ACTIVITY_TIME,
    ],
    updateClientActivity,
  );
}

export default rootOrderSaga;

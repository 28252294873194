import { TLanguageTranslations } from '../types';

const russianMessages: TLanguageTranslations = {
  languages: {
    language: 'Язык',
  },
  rexail: {
    formalName: 'Retailer Online LTD',
    brandName: 'Rexail',
  },
  pages: {
    titles: {
      cart: 'Корзина',
      checkout: 'Оформление заказа',
    },
  },
  currency: {
    shekel: '₪',
  },
  button: {
    continue: 'Продолжить',
    loadPreviousOrder: 'К предыдущим заказам',
    previousOrder: 'Предыдущие заказы',
    goToCheckout: 'Оформить заказ',
    goToCart: 'К корзине',
    continueToCheckout: 'Оформить заказ',
    placeOrder: 'Завершить заказ',
    paymentViaBit: 'Оплата через бит',
    placeSubscription: 'Оформить подписку',
    continueShopping: 'Продолжить покупки',
    view: 'Детали',
    viewSubscription: 'Детали подписки',
    viewSubscriptionCompletedCard: 'Смотреть',
    viewOrder: 'Смотреть',
    orderAgain: 'Перезаказать',
    edit: 'Редактировать',
    cancelOrder: 'Отменить заказ',
    contactStore: 'Связаться с магазином',
    downloadInvoice: 'Скачать счет-фактуру',
    downloadDeliveryCertificate: 'Скачать счет-проформу',
    restore: 'Восстановить',
    cancel: 'Отменить',
    ok: 'Ок',
    saveChanges: 'Сохранить изменения',
    backToTheHomePage: 'На главную',
    orderAgainBtn: 'Заказать снова',
    increaseQuantity: 'Увеличить кол-во',
    decreaseQuantity: 'Уменьшить кол-во',
    loadMoreProducts: 'Показать ещё',
  },
  input: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Электронная почта',
    phone: 'Номер телефона',
    fullAddress: 'Полный адрес',
    floor: 'Этаж',
    apartment: 'Квартира',
    doorCode: 'Код двери',
    entrance: 'Подъезд',
    optional: 'Необязательный',
    addressNotes: 'Примечания к адресу',
    search: 'Поиск товаров',
    chooseStore: 'Выберите магазин',
    cardHolderName: 'Имя владельца карты',
    cardHolderID: 'Теудат зеут владельца карты',
    creditCardNumber: 'Номер карты',
    expirationYear: 'Год истечения',
    expirationMonth: 'Месяц истечения',
    expirationDate: 'MM/YY',
    cvv: 'CVV',
    validity: 'Срок действия',
    coupon: 'Введите код купона здесь',
    cellPhone: 'Мобильный телефон',
    hidden: '',
    coordinates: 'Пожалуйста, перетащите карту, чтобы отметить свое точное местоположение',
    city: 'Город (только иврит)',
    streetAndStreetNumber: 'Улица и номер дома',
    streetAndStreetExample: 'Например: Weizmann 5', // name of the street not translate, because it will not do search (only hebrew or english)
    address: 'Адрес',
    details: 'Сообщение',
    receivesCommercialMessages:
      'Я хочу получать информацию об акциях и скидках по электронной почте и SMS',
    whatDeliveryPersonShouldKnow: 'Дополнительная информация для курьера',
    notSelected: 'Не выбран', // выбрано
    otherAmount: 'Другая сумма',
    tipPlaceholder: 'Вы можете ввести до 100 шекелей',
    cityMoshavKibuts: 'Город/ Мошав/ Кибуц',
    streetAndNumberOfBuilding: 'Название улицы и номер',
    nameOfBase: 'Название базы',
    cityPlaceholder: 'Пожалуйста, выбери',
    searchPickup: 'Искать точку самовывоза',
    searchLocationByAddress: 'Поиск локации по адресу',
  },
  payments: {
    options: {
      addNewCard: 'Добавить новую карту',
    },
    hiddenDigits: '••••',
    noPaymentMethods: 'У вас еще нет сохраненных способов оплаты',
    creditCardDetails: 'Данные кредитной карты',
    backToPayments: 'Назад к способам оплаты',
    validUntil: 'Истекает',
    bit: {
      name: 'bit',
      awaitingPayment: 'Ожидание оплаты.',
      sendLinkTo: 'Мы отправили ссылку для оплаты на номер {{phoneNumber}}',
      resend: 'Отправить еще раз',
      paymentDeclined: 'Платеж отклонен.',
      linkIsValidTenMinutes: 'Ссылка на оплату действительна в течение 10 минут.',
      paymentAccepted: 'Платеж принят',
      bitLinkWasSendSuccessfully: 'Sms было отправлено успешно',
    },
  },
  link: {
    backToTheShop: 'Назад к покупкам',
  },
  userMenu: {
    hiText: 'Привет',
    loginText: 'Войти', // Авторизоваться
    signUpLoginText: 'Регистр./войти',
    accountDetails: 'Мой аккаунт',
    payments: 'Способы оплаты',
    mySubscriptions: 'Мои подписки',
    orderHistory: 'История заказов',
    logOut: 'Выйти',
    clientCoupons: {
      clientCoupon: 'Вы получили возврат', // возмещение,
      validUntil: 'Годен до ',
    },
  },
  accountDetails: {
    edit: 'Редактировать',
    sendVerificationEmailTo:
      'После нажатия кнопки «Сохранить» мы отправим письмо с подтверждением на', // подтверждением или проверкой
    sendToOldAndNewEmail: 'ваш старый и новый адрес электронной почты',
    sendToNewEmail: 'ваш новый адрес электронной почты',
    forSecurityPurposes: 'в целях безопасности.',
    emailNotDefined: 'Ваш адрес электронной почты не определен',
    keep: 'Сохранить',
    verified: 'Подтверждено',
    waitingForVerificationAtTheEmail: 'Ожидается подтверждение в {{email}}',
    waitingForVerification: 'Ожидается подтверждение',
  },
  categoryMenu: {
    more: 'Более',
    title: 'Категории',
    allProducts: 'Все товары',
    allItems: 'Все товары категории',
    promotions: 'Акции',
  },
  topCard: {
    expandCart: 'Развернуть / свернуть корзину',
    myShoppingBag: 'Моя корзина',
    continue: 'Оформить',
    payment: 'заказа',
    cardEmpty: 'Ваша корзина пуста',
    startAdding: 'Начните добавлять товары', // продукты
    revaluation: 'Примерная стоимость. Итоговая по весу.',
    minimumOrder: 'Минимальный заказ',
    backToStore: 'Вернуться в магазин',
    orderSummary: 'Детали заказа', // итоги, обзор
    commentsToPreparer: 'Комментарии к заказу',
    totalShoppingCart: 'Сумма товаров', // корзины
    listHeader: {
      product: 'Товар',
      pricePerUnit: 'Цена за единицу',
      quantity: 'Количество',
      total: 'Итог',
      remove: 'Удалить',
    },
    deleteBasket: 'Очистить',
    backToCart: 'Вернуться в мою корзину',
    showPreviousOrder: 'Предыдущие заказы',
    shoppingCartNItems: 'В вашей корзине {{items}} товаров',
    deleteFromCart: 'Удалить из корзины',
    productsYouMightMissed: 'Товары которые вы могли пропустить',
    restore: 'Вернуть',
    delete: 'Удалить',
    removedItem: 'Товар был удален',
    shareCart: 'Поделиться',
    shareCartItems: 'Поделиться корзиной',
    cartWasShared: 'Корзина которой с вами поделились',
    shareCartInFollowingWays:
      'Делиться – значит заботиться! Поделитесь своей корзиной одним из следующих способов',
    shareLink: 'Копировать ссылку',
    whatsapp: 'Whatsapp',
    checkSharedCard: 'Посмотрите эту корзину, полную вкусностей, которой с вами поделились!',
    cartWasSharedSuccessfully: 'Посмотрите эту корзину, полную вкусностей!',
    emailSharedCard: 'Посмотрите эту корзину из магазина {{storeName}} которой с вами поделились!',
    goToCart: 'Перейти в корзину',
    deviceNotSupportShare:
      'Упс! Функция поделиться не поддерживается на вашем устройстве. Ссылка на корзину скопирована в буфер обмена.',
    linkCopiedSuccessfully: 'Ссылка успешно скопирована!',
  },
  generalPage: {
    about: 'О магазине',
    subscribe: 'Оформить подписку',
    deliveryAreas: 'Зоны доставки', // области // зоны
    contact: 'Связаться с нами',
  },
  deliveryPickUp: {
    withoutDeliveryTimeText: 'Выберите дату доставки',
    allTimeFramesAreFull:
      'К сожалению, нет доступных временных слотов для заказа, пожалуйста, зайдите позже',
    errorMessage: 'Нам не удалось найти доступные временные слоты из-за ошибки. Повторите попытку.',
    chooseDeliveryMethod: 'Выберите способ доставки',
    onlySelfPickup: 'Пункты самовывоза',
    onlySelfPickupChoseTime: 'Пункты самовывоза',
    sortPointsByCurrentLocation: 'Сортировать пункты по локации',
  },
  orderType: {
    delivery: 'Доставка',
    selfPickup: 'Самовывоз',
  },
  stepper: {
    order: {
      personalInformation: 'Личные данные',
      coordinationArrival: 'День и время', // детали прибытия //детали доставки //время и место
      payment: 'Оплата',
    },
    subscription: {
      personalInformation: 'Выбрать товары',
      coordinationArrival: 'Частота и время', // Период-ть
      payment: 'Оплата',
    },
  },
  overlay: {
    title: 'Проведите вправо, чтобы увидеть остальные категории',
    buttonText: 'Я понял(а)',
  },
  storeProductCard: {
    addToCart: 'Добавить',
    productCommentTypeAvailable: 'Варианты под-ки на след. этапе', // комментарии
    originalPrice: 'Первоначальная цена (до скидок)',
    relevancy: {
      popular: 'Рекомендуемый товар',
      high: 'Превосходно',
      medium: 'Стандарное качество',
      low: 'Низкая оценка',
    },
    supplier: 'Поставщик',
    pricePerOneHundredGram: '₪{{price}} за 100 гр',
    pricePerOneHundredMl: '₪{{price}} за 100 мл',
    countryOfOrigin: 'Страна происхождения {{countryName}}',
    addMoreItemMultiple: 'Еще {{numberOfItems}} товара и получите скидку',
    addMoreItemSingle: 'Еще {{numberOfItems}} товар и получите скидки',
    toPromotion: 'К акции',
  },
  tag: {
    sale: 'Скидка',
    deal: 'Акция',
    freeItem: 'Бесплатный товар',
  },
  header: {
    backButton: 'К корзине',
    navigation: 'Куда доставить?',
    chooseSelfPickupTime: 'самовывоза и время',
    chooseDeliveryTime: 'выбрать время доставки',
    chooseDelivery: 'Выбрать время доставки', // дату
    chooseSelfPickup: 'Выбрать время самовывоза', // дату // получения
    chooseDeliverySubscriptionMode: 'Выберите частоту и время доставки', // частоту
    chooseSelfPickupSubscriptionMode: 'Выберите частоту и время получения', // самовывоза
    skipToTheCategory: 'Перейти к меню категорий',
    skipToTheMainContent: 'Перейти к главному',
    skipToTheFooter: 'Перейти к футеру',
  },
  previousOrder: {
    price: 'Итог',
    items: 'товаров',
    addItems: 'Добавить {{count}} товаров в корзину',
    backToOrderList: 'Назад к истории заказов',
    payAttention: 'Обратите внимание',
    ongoingOrder: 'Планируется выполнение текущего заказа к ', // или без слова текущего
    itemIsOutOfStock: 'Товара нет в наличии',
  },
  editOrder: {
    removedItemsExcludedFromCatalog:
      'Следующие товары были удалены из вашего заказа, поскольку они недоступны: {{- removeItems}}.',
    removedItemsOutOfStock:
      'Следующие товары были удалены из вашего заказа, так как их нет в наличии: {{- removeItems}}.',
  },
  order: {
    deliveryDate: 'Выбранное время', // запланированное
    orderType: 'Тип заказа',
    numberOfItems: 'Товары',
    totalToPay: 'Сумма',
    total: 'Сумма',
    orderNumber: 'Заказ №',
    status: 'Статус',
    deliveryAddress: 'Адрес доставки',
    selfPickupAddress: 'Адрес самовывоза',
    paymentMethod: 'Способ оплаты',
    comments: 'Комментарии',
    revaluation: 'Примерная стоимость. Окончательная по весу.',
    noOrders: 'Вы еще не сделали ни одного заказа',
    startBuying: 'Начать покупать',
    orderTime: 'Заказ размещен',
    ecoPackaging: 'ЭКО упаковка',
    itemsSubstitution: 'Замена товара',
    suppliedItems: 'Поставляемые товары',
    notSupplied: 'Не поставляемые товары',
    orderedItems: 'Заказанные товары',
    chooseEcoPackaging: 'ЭКО-упаковка - как можно меньше пакетов',
    inCaseItemIsOutOfStock: 'В случае отсутствия товара на складе',
    substituteWithSimilar: 'Заменить на аналогичный товар',
    doNotSubstitute: 'Не заменять',
    contactMe: 'Свяжитесь со мной',
  },
  days: {
    monday: 'Понедельник',
    tuesday: 'Вторик',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
    shortName: {
      monday: 'Пн.',
      tuesday: 'Вт.',
      wednesday: 'Ср.',
      thursday: 'Чт.',
      friday: 'Пт.',
      saturday: 'Сб.',
      sunday: 'Вс.',
    },
    today: 'Сегодня',
    tomorrow: 'Завтра',
  },
  footer: {
    menu: {
      about: 'О магазине',
      delivery: 'Зона доставки', // области // зоны
      contact: 'Связаться с нами',
      details: 'Мой аккаунт',
      paymentMethod: 'Способы оплаты',
      subscription: 'Подписки',
      orderHistory: 'История заказов',
    },
    termsAndConditions: {
      privacy: 'Политика Конфиденциальности',
      accessibilityStatement: 'Заявление о доступности',
      terms: 'Условия эксплуатации',
    },
    allRights: '© Retailer Online LTD, {{year}}. Все права защищены',
    companyType: 'Идентификатор компании',
    ariaLabels: {
      storeAddress: 'Адрес магазина',
      storePhone: 'Телефон магазина',
      storeWhatsapp: 'Whatsapp магазина',
      storeEmail: 'Электронная почта магазина',
      storeFacebook: 'Facebook магазина',
      storeYoutube: 'Youtube магазина',
      storeInstagram: 'Instagram магазина',
    },
  },
  snackbar: {
    undo: 'Отменить',
    orderMinimumFellShort: {
      delivery:
        'Пожалуйста, обрати внимание! Чтобы совершить покупку, ваш заказ должен достигнуть минимальной суммы {{orderMinimum}}₪ без учета стоимости доставки.',
      selfPickup:
        'Пожалуйста, обрати внимание! Чтобы совершить покупку, ваш заказ должен достигнуть минимальной суммы {{orderMinimum}}₪ без учета стоимости подготовки заказа.',
    },
    orderMinimumFellShortIfNoDeliveryFee:
      'Пожалуйста, обрати внимание! Чтобы совершить покупку, ваш заказ должен достигнуть минимальной суммы {{orderMinimum}}₪.',
    promotionAppliedSuccessfully: 'Акция успешно добавлена в корзину!',
  },
  forms: {
    checkout: {
      submitButton: 'Продолжить',
      backButton: 'Вернуться в корзину',
      arrivalSubtitle: 'Выберите дату',
      collectionPoint: 'Выберите пункт самовывоза',
      pickUpDate: 'Выберите время',
      backToPersonal: 'Назад в мой аккаунт',
      backToArrival: 'Вернуться к согласованию прибытия',
      moveToPay: 'Оформить заказ',
      additionalDelivery: 'Показать больше сроков доставки >',
      creditDetails: 'Данные кредитной карты',
      billingCredit: 'Оплата кредитной картой',
      saveCredit: 'Сохраните данные карты для будущих платежей',
      saveCreditTooltip:
        'Платежные реквизиты не хранятся на серверах сайта, они надежно хранятся с использованием современных технологий токенизации в сотрудничестве с Credit-Guard Payments Gateway',
    },
    contactUs: {
      title: 'Вы также можете оставить нам сообщение',
      submitButton: 'Отправить',
    },
  },
  validation: {
    email: 'Неверный адрес электронной почты',
    required: 'Это поле обязательно к заполнению',
    chooseCity: 'Пожалуйста, выберите город',
    error: 'Неверное значение',
    minMonth: 'Минимальное значение это 01',
    maxMonth: 'Максимальное значение это 12',
    minYear: 'Минимальный год должен быть текущим',
    twoDigits: 'Значение должно содержать две цифры',
    noOptions: 'Нет результатов',
    productComment: 'Выберите вариант по подготовки', // комментарий //инструктиция
    chooseHowToPrepareItems: 'Выберите вариант по подготовке для соответствующих товаров',
    language: 'В данный момент это действие недоступно, повторите попытку позже.',
  },
  map: {
    getLocationFromBrowser: 'Получить местоположение из браузера',
    moveMapToGetPosition:
      'Пожалуйста, перетащите карту, чтобы отметить свое точное местоположение *',
    navigatorNotSupported:
      'Ваш браузер либо не поддерживает геолокацию, либо вы не предоставили разрешение на доступ к местоположению.',
    markLocationOnTheMap: 'Отметьте местоположение на карте',
    pinIsFar: 'Положение маркера находится слишком далеко от введенного адреса.',
    pinIsInvalid: 'Положение маркера недействительно. Попробуйте переместить маркер точнее.',
  },
  title: {
    aboutProduct: 'О товаре',
    ordersHistory: 'История заказов',
    activeOrders: 'Активные заказы',
    closedOrders: 'Закрытые заказы',
    subscriptions: 'Мои подписки',
  },
  comments: {
    preparationInstructionsAtTheEndOfThePurchase:
      'Варианты по подготовке товара на этапе оформления', // комментарии
    youMayAlsoWant: 'Вам также могут понравиться',
  },
  shoppingPage: {
    searchResults: 'Результаты поиска',
    cleanSearch: 'Очистить поиск',
    goToHomePage: 'Перейти на главную страницу {{storeName}}',
    sort: {
      sortBy: 'Сорт.',
      sortByName: 'по имени товара',
      sortByCategoryAndName: 'по категории и имени',
      sortByPriceAsc: 'от дешевых к дорогим',
      sortByPriceDesc: 'от дорогих к дешевым',
      sortByPromoted: 'акционные товары',
      sortBySearchRelevancy: 'актуальные',
    },
  },
  paymentInfoSection: {
    creditCard: {
      text: 'Оплата осуществляется безопасно, ваши платежные реквизиты защищены с помощью современных технологий токенизации в сотрудничестве с Credit-Guard Payments Gateway',
      externalFormText:
        'Нажав «Продолжить», вы будете перенаправлены на внешнюю платежную страницу, созданную в сотрудничестве с Credit-Guard, ведущей компанией по обработке кредитных карт в Израиле.',
      link: 'Узнать больше',
    },
    deferredPayment:
      'Оплата не будет произведена сейчас, мы свяжемся с вами позже для урегулирования платеж.',
    absent:
      'Заказ будет создан без платежной информации. Позже вы сможете связать способ оплаты с заказом.',
    cash: 'Курьер заберет оплату наличными в день доставки. Пожалуйста, подготовьте точную сумму (сдача может быть недоступна).',
    bit: {
      onlyNonWeighableItems:
        'После нажатия кнопки «Оплата через бит» вы получите SMS со ссылкой для продолжения оплаты через Bit. Мы приступим к подготовке заказа после подтверждения оплаты. Статус оплаты вы можете отслеживать на странице «История заказов.',
      weighableItems:
        'После нажатия кнопки «Оплата через бит» вы получите SMS со ссылкой для продолжения оплаты через Bit. Мы приступим к подготовке заказа после подтверждения оплаты. Кроме того, мы временно зарезервируем сумму, немного превышающую расчетную, чтобы учесть возможные различия в весе собранных товаров. Окончательная сумма к оплате будет зависеть от точного взвешенного количества.',
    },
  },
  auth: {
    loginText: 'Войти', // Авторизоваться
  },
  dialog: {
    bitErrorViewOrder: 'Мы столкнулись с проблемой, страница будет обновлена.',
    bitErrorOrderCompleted:
      'Произошла ошибка автоматического изменения статуса вашего платежа в бит, вы будете перенаправлены на страницу просмотра заказа.',
    multipleDeliveryAreas:
      'Мы доставляем в несколько зон в {{cityName}}, пожалуйста, введите свой полный адрес, чтобы продолжить.',
    oneDeliveryArea:
      'Мы осуществляем доставку в определенный район города {{cityName}}, пожалуйста, введите свой полный адрес, чтобы продолжить.',
    unavailableTimeframe:
      'Выбранный вами временной интервал больше недоступен. Вас перенаправляют для выбора нового.',
    searchSucceeded: 'Поиск выполнен успешно',
    searchFailed: 'Поиск не удался',
    closeButton: 'Закрыть всплывающее диалоговое окно',
    orderMinimumLabel: 'Минимальный заказ',
    catalogWasUpdated: {
      title: 'Обновление каталога',
      body: 'Пожалуйста, обрати внимание! На основании выбранного вами адреса вас определили в филиал, обслуживающий ваш регион. Наличие на складе и цены могут отличаться.',
      okButton: 'OK',
    },
    orderMinimum: {
      title: 'Минимальная сумма заказа',
      body: {
        delivery:
          'Минимальная сумма заказа составляет {{orderMinimum}}₪ (без учета стоимости доставки). Пожалуйста, добавьте больше товаров в корзину.',
        selfPickup:
          'Минимальная сумма заказа составляет {{orderMinimum}}₪ (без учета платы за подготовку). Пожалуйста, добавьте больше товаров в корзину.',
      },
      bodyIfNoDeliveryFee:
        'Минимальная сумма заказа составляет {{orderMinimum}}. Пожалуйста, добавьте больше товаров в корзину.',
      okButton: 'Продолжить покупки',
    },
    handlingFee: {
      title: 'Плата за обработку',
      body: 'Для заказов на сумму менее {{orderHandlingFeeThreshold}}₪ будет добавлена плата за обработку в размере {{orderHandlingFee}}₪ к общей сумме оплаты.',
      okButton: 'К оформлению заказа',
      cancelButton: 'Назад к покупкам',
    },
    emptyCart: {
      title: 'Очистить корзину',
      body: 'Вы уверены, что хотите очистить корзину?',
      yesButton: 'Да, очистить корзину',
      noButton: 'Нет, оставить товары',
    },
    location: {
      title: 'Добро пожаловать!',
      subTitle: 'Давайте убедимся, что мы осуществляем доставку в ваш город',
      haveAccount: 'Уже есть аккаунт?',
      addressExample: 'Улица, номер дома и город',
      interestingInSelfPickup: 'Предпочитаете самовывоз?',
      chooseSelfPickup: 'Выбрать пункт',
      residential: 'Жилой дом',
      locationType: 'Тип локации',
      private: 'Частный дом',
      office: 'Офис',
      militaryBase: 'Военная база',
      couldNotLocateAddress: 'Мы не смогли найти ваш адрес, пожалуйста, введите свой адрес здесь:',
      deliveryHoursAndMoreDetails: 'Время доставки и более подробная информация',
    },
    login: {
      title: 'Добро пожаловать!',
      subTitle: 'Введите номер мобильного телефона',
      confirmTerms:
        'При нажатии кнопки "Продолжить", я подтверждаю, что ознакомился(-ась) и принимаю',
      terms: 'условия эксплуатации',
    },
    loginCode: {
      titleSendViaTel: 'Аутентификация по мобильному номеру',
      titleSendViaEmail: 'Аутентификация по электронной почте',
      subTitleTel: 'Введите код, полученный по',
      subTitleEmail: 'Введите код, отправленый по',
      sendAgain: 'Отправить еще раз',
      selectMethodToReceivedCode: 'Пожалуйста, выберите способ получения кода подтверждения',
      dontReceivedCode: 'Не получили код?',
      smsMessage: 'SMS-сообщение',
      phoneCall: 'Телефонный звонок',
      email: 'Электронная почта',
      enterEmailToSendAuthCode:
        'Пожалуйста, введите адрес электронной почты, чтобы отправить код подтверждения',
      send: 'Отправить',
      back: 'Назад',
      codeFromSms: 'Код подтверждения - {{number}} числа из 6',
    },
    signUp: {
      title: 'Мы рады встрече!',
      subTitle: 'Еще несколько деталей для завершения процесса регистрации',
      submitButton: 'Подтвердить',
      addressExample: 'Пример: Dizengoff 20, Tel Aviv',
    },
    welcome: {
      title: 'Добро пожаловать!',
      closeButton: 'Хорошо, давайте начнем',
    },
    paymentMethod: {
      title: 'Удалить способ оплаты',
      body: 'Вы уверены, что хотите удалить карточку, оканчивающуюся на {{fourLastDigits}}?',
      yesButton: 'Да, удалить карту',
      noButton: 'Отменить',
    },
    delivery: {
      shippingAddress: 'Адрес доставки',
      storeHours: {
        title: 'Время доставки в {{-areaName}}',
      },
      coordination: {
        title: 'Выберите время',
        description:
          'Указанные временные рамки приблизительны и могут изменяться в зависимости от количества заказов и условий в регионе. Пожалуйста, учтите возможные изменения в графике и, в случае нестандартных ситуаций, свяжитесь с магазином.', // необычных //исключительных //случаи особых обстоятельств
      },
      editText: 'Редактировать детали',
      storeDoesntMakeDeliveryToYourAddressButSelfPickupExist:
        'К сожалению, магазин не осуществляет доставку по вашему адресу, но у нас есть пункты самовывоза в вашем городе:',
      kmFromYourAddress: 'км от вашего адреса',
      kmFromSelectedAddress: 'км от выбраного адреса',
      kmFromYourCurrentLocation: 'км от текущего местоположения',
      choose: 'Выбрать',
      chooseAddress: 'Введите адрес чтобы',
      chooseSelfPickup: 'Выбрать пункт',
      dropDownChooseSelfPickup: 'Пожалуйста, выберите опцию',
      selfPickupPoint: 'Пункт самовывоза',
      changeSelfPickupPoint: 'Изменить',
      toChooseSelfPickupDateNeedLog:
        'Для выбора даты самовывоза Вам необходимо авторизоваться в системе',
      chooseSelfPickupTime: 'Выбрать время самовывоза',
    },
    existingOrder: {
      title: 'Существующий заказ',
      body: 'Мы заметили, что в последний раз, когда вы были здесь, вы сделали заказ на {{deliveryDate}} между {{deliveryTime}}',
      description: 'Вы хотите отредактировать существующий заказ или разместить другой заказ?',
      submitButton: 'Редактировать заказ',
      cancelButton: 'Разместить другой',
    },
    activeSubscription: {
      title: 'Активная подписка',
      body: 'У вас есть активная подписка с ожидаемым временем следующего заказа {{-nextOrderDate}}.',
      bodyDescription: 'Желаете изменить подписку или пришли за чем-то другим?',
      okButton: 'Изменить подписку',
      cancelButton: 'Перейти на главную',
    },
    cancelOrder: {
      title: 'Отменить заказ',
      editedTitle: 'Отменить заказ в режиме редактирования',
      body: 'Вы уверены, что хотите отменить заказ № {{id}}?',
      editedBody:
        'Вы уверены, что хотите отменить заказ № {{id}}, который сейчас находится в режиме редактирования?',
      yesButton: 'Да, отменить заказ',
      noButton: 'Нет, оставить заказ',
    },
    cancelSubscription: {
      title: 'Отменить подписку',
      editedTitle: 'Отменить подписку в режиме редактирования',
      body: 'Вы хотите отменить только подписку или также ожидающий заказ по подписке?',
      editedBody:
        'Вы уверены, что хотите отменить подписку, которая в данный момент находится в режиме редактирования, или также отменить ожидающийся заказ по этой подписке?',
      cancel: 'Оставить подписку',
      ok: 'Отменить подписку и заказ',
      justUnsubscribe: 'Отменить только подписку',
    },
    unsuspendSubscription: {
      title: 'Возобновить подписку', // отменить приостановку
      body: 'Вы уверены, что хотите возобновить подписку?', // отменить приостановку
      cancel: 'Нет',
      ok: 'Да, возобновить', // отменить приостановку
    },
    suspendSubscription: {
      cancel: 'Оставить подписку',
      suspendAndCancelOrder: 'Приостановить и отменить предстоящий заказ',
      justSuspend: 'Только приостановить',
    },
    editOrderMode: {
      title: 'Режим редактирования заказа',
      body: 'К вашему сведению, в настоящее время вы редактируете заказ, который должен быть выполнен {{- orderDate}}. Как вы хотите продолжить?',
      keepEditing: 'Продолжить редактировать заказ',
      exitEditMode: 'Отменить и выйти из режима редактирования',
    },
    deliveryToClientAddress: {
      performDeliveryTitle: 'Да!',
      performDeliveryBody: 'Мы доставляем в {{city}}',
      performsSelfPickupBody:
        'Мы не доставляем по вашему адресу, но у нас есть пункт самовывоза в выбранном городе.',
      performsSelfPickupAndDeliveryBody:
        'Мы доставляем в {{city}} и, кроме того, у нас есть пункт самовывоза в выбранном городе!',
      performDeliveryYesButton: 'Продолжить покупки',
      performSelfPickupYesButton: 'Выбрать самовывоз', // место получения
      doesntPerformDeliveryTitle: 'Извините…',
      doesntPerformDeliveryBody: 'Мы не доставляем по вашему адрессу',
      doesntPerformDeliveryChangeAddressButton: 'Изменить город',
      doesntPerformDeliveryChangeCloseButton: 'Закрыть',
      edit: 'Редактировать',
    },
    threeDSecure: {
      errorTitle: 'Ошибка',
      apiLoadError:
        'Произошла ошибка при связи с компанией по кредитным картам. Пожалуйста, обновите страницу и попробуйте снова.',
      authError:
        'Ошибка проверки данных вашей кредитной карты. Убедитесь, что все данные указаны верно, и повторите попытку или попробуйте другую карту. Ошибка, полученная от компании по кредитным картам: {{errorCode}}',
      genericAuthError:
        'Ошибка проверки данных вашей кредитной карты. Убедитесь, что все данные указаны верно, и повторите попытку или попробуйте другую карту.',
      okButton: 'OK',
    },
    basket: {
      title: 'Редактировать {{- bagName}}',
      subtitle:
        'Вы можете заменить до {{maxNumOfReplacements}} товаров. Нажмите на товар, чтобы заменить его.',
      save: 'Сохранить',
      productInBasket: 'Товаров в пакете',
      replaceProducts: 'Хочу получить взамен',
      maxReplacementsReached: 'Вы достигли максимального количества замен',
      defaultItemQuantity: 'Часть',
    },
    exitModeIndicator: {
      addSubscription: {
        title: 'Выйти из режима подписки',
        body: 'Вы уверены, что хотите выйти из режима создания подписки?',
        okButton: 'Да, я уверен(а)',
        cancelButton: 'Отмена',
      },
      editSubscription: {
        title: 'Выйти из режима редактирования подписки',
        body: 'Вы уверены, что хотите выйти из режима редактирования подписки?',
        okButton: 'Да, я уверен(а)',
        cancelButton: 'Отмена',
      },
      edit: {
        title: 'Выйти из режима редактирования заказа',
        body: 'Вы уверены, что хотите выйти из режима редактирования заказа?',
        okButton: 'Да, я уверен(а)',
        cancelButton: 'Отмена',
      },
    },
    updateSubscription: {
      title: 'Изменения в подписке',
      body: 'Применить эти изменения так же к будущим заказам этой подписки?',
      bodyIfTipExist: 'Выбранные вами чаевые для курьера будет применяться только к этому заказу.',
      okButton: 'Да применить',
      cancelButton: 'Нет, только для этого заказа',
    },
    orderEditingIsNotPossible: {
      title: 'Упс!',
      body: 'Извините, но слишком поздно вносить изменения в этот заказ, так как осталось недолго до времени доставки. Хотите связаться с магазином?',
      yesButton: 'Да, связатся с магазином',
      noButton: 'Нет, просто отмените редактирование',
    },
    paymentByBit: {
      title: 'Оплата через bit',
      body: 'Мы отправили SMS с запросом на оплату в размере ₪{{cartEstimation}} на номер',
      or: 'или',
      scanQR: 'Отсканируйте QR-код',
      awaitingPaymentViaBit: 'Ожидание оплаты через Bit',
      completePayment:
        'Пожалуйста, завершите процесс оплаты в приложении bit, чтобы завершить заказ.',
    },
    paymentByBitWasDeclined: {
      title: 'Оплата через bit',
      subtitle: 'Платеж через бит не был принят!',
      body: 'Вы можете перейти на страницу деталей заказа, чтобы завершить оплату.',
      yesButton: 'Получите новую ссылку для оплаты',
      noButton: 'Перейти на страницу деталей заказа',
    },
  },
  location: {
    cantFind: 'Не можете найти свой адрес?',
    selectByMap: 'Введите свой адрес вручную',
    shareMyLocation: 'Поделиться своей локацией',
    myLocation: 'Mое местонахождение',
  },
  availableHours: {
    chooseDay: 'Выберите день',
    chooseTimeframe: 'Выберите временные рамки',
    today: 'היום', // supposed to be NOT translated for now
    tomorrow: 'מחר', // supposed to be NOT translated for now
  },
  links: {
    backToSubscriptions: 'Назад к моим подпискам',
    backToTheShop: 'Назад к покупкам',
    backToOrderHistory: 'Назад к истории заказов',
    backToAddressSearch: 'К поиску по адресу',
  },
  completedOrder: {
    yourOrderInTheSystem: 'Ваш заказ был получен!',
    noteCompletedOrder:
      'Обратите внимание, что название компании, которое будет указано в выписке по кредитной карте, относится к платформе заказов Rexail. После подготовки вашего заказа и взвешивания продуктов мы вышлем вам счет-фактуру, и с кредитной карты будет снята соответствующая сумма.',
    backToTheHomeScreen: 'Назад к покупкам',
    orderDetails: 'Детали заказа',
    orderNumber: 'Заказ №',
  },
  completedSubscription: {
    yourSubscriptionInTheSystem: 'Ваша подписка принята!',
    noteCompletedSubscription:
      'Вы можете редактировать и отписаться в любое время простым способом, используя личный кабинет.',
    backToTheHomeScreen: 'Назад к покупкам',
    subscriptionDetails: 'Детали подписки',
  },
  error: {
    itemsNotFoundTitle: 'Поиск не дал результатов для: {{searchQuery}}',
    itemsNotFoundDescription:
      'Пожалуйста, проверьте наличие опечаток, или возможно товара нет в наличии.',
    previousOrderNotExist: 'Товары не существуют',
    oops: 'Упс!',
    pageNotFound: 'Мы не можем найти страницу, которую вы ищете',
    somethingWentWrong: 'Что-то пошло не так…',
    errorDescription: 'Что-то пошло не так. Нажмите, чтобы вернуться на главную страницу.',
    increaseYourExperience: 'Обновление в процессе: Скоро вернемся с улучшениями!',
    systemCurrentlyUpgraded:
      'В настоящее время система модернизируется.\n Пожалуйста, вернитесь через несколько минут.',
    maxAmountOfProduct: 'Вы достигли максимального количества этого товара :)',
    errorDetails: 'Сведения об ошибке',
  },
  subscription: {
    frequency: 'Частота', // переодичность
    orderType: 'Тип заказа',
    numberOfItems: 'Товары',
    nextOrder: 'Следующий заказ',
    subscriptionTo: 'Подписка на {{shopName}}',
    status: 'Статус',
    deliveryTime: 'Выбранное время',
    subscriptionDate: 'Дата подписки',
    lastOrderDate: 'Последний заказ',
    localedPreferredDay: 'Выбранное время',
    expectedNextOrder: 'Ожидаемый следующий заказ',
    methodsOfPayment: 'Способ оплаты',
    suspendSubscription: 'Приостановить подписку',
    unsuspendSubscription: 'Возобновить подписку', // Отменить приостановку
    cancelSubscription: 'Отменить подписку',
    day: 'День',
    selectDay: 'Выберите дату активации подписки',
    dateIsInThePast: 'Дата в прошлом',
    invalidDate: 'Неверная дата',
    cancel: 'Отмена',
    ok: 'Подтвердить',
    noSubscription: 'Подписок пока нет',
    startToSubscribeTo: 'Создать подписку',
    chooseFrequency: 'Выберите периодичность',
    chooseDayAndTime: 'Выберите день и время',
    coordinationArrival: 'Периодичность и время',
  },
  whatIsSubscription: {
    title: 'Еще не наши подписчики?',
    subTitle:
      'Создание подписки экономит время, и вам не нужно каждый раз помнить о повторе заказа.',
    howItWorks: 'Как это работает?',
    assembleTheBasketTitle: 'Соберите корзину',
    assembleTheBasketSubtitle:
      'С вашими любимыми продуктами, а мы позаботимся о свежих и качественных продуктах',
    chooseFrequencyDayAndTimeTitle: 'Выберите периодичность, день и время',
    chooseFrequencyDayAndTimeSubtitle:
      'Еженедельно, раз в две недели или ежемесячно, по мере необходимости, вы всегда можете редактировать и обновлять',
    startToSubscribe: 'Создать подписку',
    whatIsSubscriptionAndHowItWorksTitle: 'Что такое подписка и как она работает?',
    whatIsSubscriptionAndHowItWorksDescription:
      'Заказы по подписке создаются автоматически за два дня до даты доставки, в зависимости от переодичности и выбранного дня доставки, за исключением особых случаев, когда день доставки недоступен на этой неделе (например, в праздничные дни) - в этом случае система создаст заказ на следующий день и дата доставки будет обновлена (уведомление будет отправлено по электронной почте)',
    whatIsSubscriptionAndHowItWorksDescriptionTwo:
      'При создании заказа по подписке отправляется SMS-напоминание с указанием даты доставки и электронное письмо от адреса postmaster@rexail.co.il. В приложенном к письму PDF-файле содержится детальная информация о новом заказе сделаном по подписке, а в тексте письма указаны сведения о возможных нехватках товаров в соответствии с имеющимся на сайте запасом. Кроме того, в тексте письма будет представлена быстрая прямая ссылка для редактирования/отмены заказа (без необходимости входа в учетную запись).',
    whatIsSubscriptionAndHowItWorksDescriptionThree:
      'Обратите внимание! Заказы, созданные пользователем или подписчиком, являются ответственностью клиента! Пожалуйста, следите за важными объявлениями и обновлениями. При необходимости вы можете связаться с магазином в рабочее время',
    enterPaymentDetailsTitle: 'Введите платежные реквизиты',
    enterPaymentDetailsSubtitle:
      'Оплата будет производиться после доставки заказа в соответствии с выбранной периодичностью.',
    addNewSubscriptionTitle: 'Добавить новую подписку',
    addNewSubscriptionDescription:
      'Вы можете добавить новую подписку на странице «Мои подписки» (после авторизации).',
    addNewSubscriptionDescriptionTwo:
      'Шаг первый - Соберите корзину из товаров, которые вы хотели бы получать регулярно. После того, как вы закончите собирать товары, нажмите кнопку «Оформить заказ», и вы сразу же попадете на страницу «Моя корзина», где вы сможете просмотреть все товары, которые вы добавили в корзину, и изменить их, если это необходимо.', // check name of the button
    addNewSubscriptionDescriptionThree:
      'Шаг второй - выбор предпочтительную периодичность, день и время доставки.',
    addNewSubscriptionDescriptionFour:
      'Шаг третитй - ввод платежных реквизитов. После добавления или выбора кредитной карты для оплаты нажмите на кнопку «Оформить подписку», после чего на экране отобразится подтверждение о получении подписки вместе с деталями подписки. Оплата будет производиться после доставки заказа, в зависимости от выбранной частоты.',
    howDoIFreezeSubscriptionTitle: 'Редактирование/приостановка/отмена подписки',
    howDoIFreezeSubscriptionDescription: 'Есть два способа отредактировать заказ:',
    howDoIFreezeSubscriptionDescriptionTwo:
      '1. Через электронное письмо, с подтверждением заказа от postmaster@rexail.co.il, содержащее прямую ссылку для редактирования/отмены заказа (без необходимости входа в систему), которая отправляется автоматически в конце создания заказа.',
    howDoIFreezeSubscriptionDescriptionThree:
      '2. На странице «Мои подписки» (после авторизации). На этой странице вы можете просмотреть свои подписки. Чтобы отредактировать/приостановить/отменить существующую подписку, нажмите «Детали подписки» и выберите нужное действие.',
    deficienciesInOrderingSubscriptionTitle: 'Недостатки в заказе подписки',
    deficienciesInOrderingSubscriptionDescription:
      'Нехватка товаров на сайте компании может быть вызвана различными причинами, например: определенный товар поступил и был объявлен не подходящим для упаковки, товар не в сезоне, или выращен в малом количестве, или по любой другой причинее.\n' +
      'Не беспокойтесь! Товары, находящиеся в подписке и не предоставленные в заказе из-за недостатков, не будут оплачиваться.',
    changeDeliveryDateTitle: 'Разовое изменение даты доставки',
    changeDeliveryDateDescription:
      'Если заказ от подписчика уже сформирован (за два дня до даты доставки), изменение даты доставки возможно, связавшись с магазином.',
    feelingConfused: 'Все еще чувствуете себя сбитым с толку?',
    answersFrequentlyQuestions: 'Здесь вы найдете ответы на часто задаваемые вопросы',
  },
  personalDetails: {
    floorApartment: '{{floor}} этаж, квартира {{apartment}}',
    apartment: 'квартира {{apartment}}',
    floor: 'этаж {{floor}}',
  },
  checkout: {
    noOrderComments: 'Нет комментариев',
    choosePayment: 'Выберите способ оплаты',
    chooseCard: 'Выберите карту',
    info: {
      address: 'Адрес',
    },
    paymentMethod: {
      creditCard: 'Кредитная карта',
      deferredPayment: 'Отложенная оплата',
      absent: 'Без оплаты',
      cash: 'Наличные',
      bit: 'Бит',
    },
    coupon: {
      couponDiscount: 'Купон',
      generalDiscount: 'общая скидка',
      itemRefund: 'возврат за товар', // возмещение
      code: 'Код купона',
      noCoupon: 'Нет купона',
      couponValueMessage: 'Сохранено! {{valueFormula}}',
      refundItemIsNotInTheCart: 'Товар со скидкой отсутствует в вашей корзине',
    },
    numberOfPayments: 'Количество платежей',
    betweenHours: 'Между',
    courierTip: 'Чаевые курьеру (по желанию)',
    noTip: 'Без чаевых',
    other: 'Другое',
    tipForCourier: 'Чаевые для курьера',
    handlingFee: 'Плата за обработку',
  },
  autocomplete: {
    loading: 'Загрузка...',
  },
  bagOfProducts: {
    whatBagContain: 'Что в пакете товаров?',
    moreDetails: 'Подробнее',
  },
  serviceAreaUsageFees: {
    delivery: 'Стоимость доставки',
    selfPickup: 'Плата за подготовку заказа',
  },
  orderModeIndicator: {
    edit: 'Редактирования заказа №.{{id}}',
    addSubscription: 'Режим создания подписки',
    editSubscription: 'Режим редактирования подписки',
    exit: 'Выход',
  },
  aboutPage: {
    aboutTitle: 'О нас',
    openHours: 'Часы работы магазина',
    regularlyTitle: 'Вы покупаете у нас регулярно?',
    regularlySubtitleOne: 'Выберите периодичность и получайте свой регулярный заказ прямо домой!',
    regularlySubtitleTwo:
      'За два дня до прибытия заказа вы получите напоминание на свой мобильный телефон и электронную почту, и вы сможете обновить содержимое заказа или отменить его.',
    regularlyBtn: 'Подписаться',
    divisionalTitle: 'Зоны доставки', // области // зоны
    divisionalSubtitle: 'Проверьте, доставляем ли мы в ваш город',
    contactTitle: 'Связаться с нами',
    openingHours: 'Часы работы',
    closed: 'Закрыто',
    moreDetails: 'Подробнее',
    check: 'Проверить',
    checkAgain: 'Проверить снова',
    citySearchDescription:
      'Введите город или поселение, чтобы узнать, делаем ли мы доставку до вашего места',
    followUs: 'Подписатся на нас',
  },
  discount: {
    payment: 'Бонус в магазине',
    creditAtCheckoutDescription:
      'Бонус в магазине — это одноразовый ваучер, который нельзя разделить на несколько транзакций.',
    deliveryUsageFees: 'Скидка на доставку',
    selfPickupUsageFees: 'Плата за подготовку',
    storePromotion: 'Скидка',
    storeCoupon: 'Купон',
    clientCoupon: 'Возврат', // возмещение
    couponDiscount: 'Купон',
    generalDiscount: 'Общая скидка',
    itemRefund: 'Возврат за товар', // возмещение
    itemForFree: 'Бесплатный товар',
    forOrders: {
      delivery: 'при заказе доставки',
      selfPickup: 'при заказе самовывоза',
    },
    valid: 'Действительна',
    days: 'по',
    maxQuantityUsage: {
      forNonWeighableAboveQuantity: 'Макс. {{maxQuantity}} использований на заказ',
      forWeighableBetweenQuantity: 'Макс. {{maxQuantity}} кг на заказ',
    },
    validUntil: 'Действительна до',
    notValidInSelectedDay:
      'Некоторые акции в корзине не действуют для выбранного дня доставки и были удалены из заказа',
  },
  onClientBehalf: {
    storeSide: 'Магазин',
  },
  ageValidation: {
    ageValidation: 'Проверка возраста',
    orderContainAgeLimitedItems:
      'Ваш заказ содержит товары, продажа которых по закону разрешена только лицам старше 18 лет.',
    ageConformation:
      'Я подтверждаю, что мне исполнилось 18 лет. При передаче заказа мне потребуется предъявить удостоверение личности.',
  },
  incentivePromotion: {
    tooltip: {
      redeemRefund: 'Чтобы воспользоваться скидкой, добавьте {{productName}} в корзину',
    },
    generatedText: {
      forCartEstimationBeforePreparation: {
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Добавьте еще на ₪{{discountShortage}} и доставка станет бесплатной',
            partialDiscount:
              'Добавьте еще на ₪{{discountShortage}} и вы получите скидку {{discountValue}} на доставку',
          },
          delivery: {
            fullDiscount: 'Добавьте еще на ₪{{discountShortage}} и доставка станет бесплатной',
            partialDiscount:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на доставку',
          },
          selfPickup: {
            fullDiscount:
              'Добавьте еще на ₪{{discountShortage}} и получите бесплатную подготовку заказа',
            partialDiscount:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на подготовку заказа',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на заказ',
            minimumOrder:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на заказ',
          },
          delivery: {
            smallMinimumOrder:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на заказ',
            minimumOrder:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на заказ',
          },
          selfPickup: {
            smallMinimumOrder:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на заказ',
            minimumOrder:
              'Добавьте еще на ₪{{discountShortage}} и получите скидку {{discountValue}} на заказ',
          },
        },
        itemForFree: {
          all: 'Добавьте еще на ₪{{discountShortage}} и получите бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}}',
          delivery:
            'Добавьте еще на ₪{{discountShortage}} и получите бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}}',
          selfPickup:
            'Добавьте еще на ₪{{discountShortage}} и получите бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}}',
        },
        itemRefund: {
          all: 'Добавьте еще на ₪{{discountShortage}} и получите {{-storeProductSourceName}} по  ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}}',
          delivery:
            'Добавьте еще на ₪{{discountShortage}} и получите {{-storeProductSourceName}} по  ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}}',
          selfPickup:
            'Добавьте еще на ₪{{discountShortage}} и получите {{-storeProductSourceName}} по  ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}}',
        },
      },
    },
    productsParticipatingSale: 'Товары участвующие в акции',
    seeParticipationProducts: 'К товарам в этой акции',
  },
  promotionsBanner: {
    validForDays: 'Действует по',
    until: 'До',
    validUntil: 'Действ. до',
    maxQuantityPerOrder: 'Макс. {{valueFormulaSourceQuantity}} {{-unitName}} на заказ',
    tooltip: {
      readMore: 'Подробнее',
      forCartEstimationBeforePreparation:
        'Скидка будет рассчитана только в соответствии со стоимостью корзины, указанной на сайте, независимо от окончательной суммы оплаты, которая определяется после взвешивания и доставки товаров.',
      forCartEstimationAfterPreparation:
        'Скидка будет получена на этапе выставления счета, после взвешивания товаров и только в том случае, если окончательная сумма выставления счета соответствует условиям. Скидка появится в счете-фактуре.',
      forSubscription:
        'Скидка для подписчиков будет получена на этапе выставления счета, после взвешивания и поставки товаров. Скидка появится в счете-фактуре.',
      forNewSubscription:
        'Скидка для подписчиков будет получена на этапе выставления счета, после взвешивания и поставки товаров. Скидка появится в счете-фактуре.',
    },
    generatedText: {
      forCartEstimationBeforePreparation: {
        itemForFree: {
          all: 'Бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при заказе свыше ₪{{minimumOrder}}',
          delivery:
            'Бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} для заказов с доставкой свыше ₪{{minimumOrder}}',
          selfPickup:
            'Бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} для заказов с самовывозом свыше ₪{{minimumOrder}}',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Бесплатная доставка при заказе свыше ₪{{minimumOrder}}',
            partialDiscount:
              'Скидка {{discountValue}} на доставку при заказе свыше ₪{{minimumOrder}}',
          },
          delivery: {
            fullDiscount: 'Бесплатная доставка при заказе свыше ₪{{minimumOrder}}',
            partialDiscount:
              'Скидка {{discountValue}} на доставку при заказе свыше ₪{{minimumOrder}}',
          },
          selfPickup: {
            fullDiscount:
              'При заказе самовывоза на сумму свыше ₪{{minimumOrder}}, нет платы за подготовку заказа',
            partialDiscount:
              'Скидка {{discountValue}} на плату за подготовку заказов при самовывозе свыше ₪{{minimumOrder}}',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'Скидка {{discountValue}} на все заказы',
            minimumOrder: 'Скидка {{discountValue}} на все заказы свыше ₪{{minimumOrder}}',
          },
          delivery: {
            smallMinimumOrder: 'Скидка {{discountValue}} на заказы с доставкой',
            minimumOrder: 'Скидка {{discountValue}} на заказы с доставкой свыше ₪{{minimumOrder}}',
          },
          selfPickup: {
            smallMinimumOrder: 'Скидка {{discountValue}} на заказы с самовывозом',
            minimumOrder:
              'Скидка {{discountValue}} на заказы с самовывозом свыше ₪{{minimumOrder}}',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при заказе свыше ₪{{minimumOrder}}',
          delivery:
            '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} для заказов с доставкой свыше ₪{{minimumOrder}}',
          selfPickup:
            '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} для заказов с самовывозом свыше ₪{{minimumOrder}}',
        },
      },
      forCartEstimationAfterPreparation: {
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Бесплатная доставка при заказе свыше ₪{{minimumOrder}}',
            partialDiscount:
              'Скидка {{discountValue}} на доставку при заказе свыше ₪{{minimumOrder}}',
          },
          delivery: {
            fullDiscount: 'Бесплатная доставка при заказе свыше ₪{{minimumOrder}}',
            partialDiscount:
              'Скидка {{discountValue}} на доставку при заказе свыше ₪{{minimumOrder}}',
          },
          selfPickup: {
            fullDiscount:
              'При заказе самовывоза на сумму свыше ₪{{minimumOrder}}, нет платы за подготовку заказа',
            partialDiscount:
              'Скидка {{discountValue}} на плату за подготовку заказов при самовывозе свыше ₪{{minimumOrder}}',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'Скидка {{discountValue}} на все заказы',
            minimumOrder: 'Скидка {{discountValue}} на все заказы свыше ₪{{minimumOrder}}',
          },
          delivery: {
            smallMinimumOrder: 'Скидка {{discountValue}} на заказы с доставкой',
            minimumOrder: 'Скидка {{discountValue}} на заказы с доставкой свыше ₪{{minimumOrder}}',
          },
          selfPickup: {
            smallMinimumOrder: 'Скидка {{discountValue}} на заказы с самовывозом',
            minimumOrder:
              'Скидка {{discountValue}} на заказы с самовывозом свыше ₪{{minimumOrder}}',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} для заказов свыше ₪{{minimumOrder}}',
          delivery:
            '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} для заказов с доставкой свыше ₪{{minimumOrder}}',
          selfPickup:
            '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} для заказов с самовывозом свыше ₪{{minimumOrder}}',
        },
      },
      forNewCustomer: {
        itemForFree: {
          all: 'Бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при первом заказе на нашем сайте',
          delivery:
            'Бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при первом заказе с доставкой на нашем сайте',
          selfPickup:
            'Бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при первом заказе с самовывозом на нашем сайте',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Бесплатная доставка при первом заказе на нашем сайте',
            partialDiscount:
              'Скидка {{discountValue}} на доставку при первом заказе на нашем сайте',
          },
          delivery: {
            fullDiscount: 'Бесплатная доставка при первом заказе на нашем сайте',
            partialDiscount:
              'Скидка {{discountValue}} на доставку при первом заказе на нашем сайте',
          },
          selfPickup: {
            fullDiscount:
              'Никаких сборов за подготовку при первом заказе с самовывозом на нашем сайте.',
            partialDiscount:
              'Скидка {{discountValue}} на подготовку при первом заказе с самовывозом на нашем сайте',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'Скидка {{discountValue}} при первом заказе на нашем сайте',
          },
          delivery: {
            smallMinimumOrder:
              'Скидка {{discountValue}} при первом заказе с доставкой на нашем сайте',
          },
          selfPickup: {
            smallMinimumOrder:
              'Скидка {{discountValue}} при первом заказе с самовывозом на нашем сайте',
          },
        },
        itemRefund: {
          all: '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при первом заказе на нашем сайте',
          delivery:
            '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при первом заказе с доставкой на нашем сайте',
          selfPickup:
            '{{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при первом заказе с самовывозом на нашем сайте',
        },
      },
      forSubscription: {
        itemForFree: {
          all: 'Только для подписчиков: бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при каждом заказе',
          delivery:
            'Только для подписчиков: бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при каждом заказе с доставкой',
          selfPickup:
            'Только для подписчиков: бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при каждом заказе с самовывозом',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Только для подписчиков: бесплатная доставка при каждом заказе!',
            partialDiscount:
              'Только для подписчиков: скидка {{discountValue}} на доставку при каждом заказе!',
          },
          delivery: {
            fullDiscount: 'Только для подписчиков: бесплатная доставка при каждом заказе!',
            partialDiscount:
              'Только для подписчиков: скидка {{discountValue}} на доставку при каждом заказе!',
          },
          selfPickup: {
            fullDiscount:
              'Только для подписчиков: никаких сборов за подготовку при каждом заказе с самовывозом!',
            partialDiscount:
              'Только для подписчиков: скидка {{discountValue}} на плату за подготовку при каждом заказе с самовывозом!',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder: 'Только для подписчиков: скидка {{discountValue}} для всех заказов',
          },
          delivery: {
            smallMinimumOrder:
              'Только для подписчиков: скидка {{discountValue}} для заказов с доставкой',
          },
          selfPickup: {
            smallMinimumOrder:
              'Только для подписчиков: скидка {{discountValue}} для заказов с самовывозом',
          },
        },
        itemRefund: {
          all: 'Только для подписчиков: {{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при каждом заказе',
          delivery:
            'Только для подписчиков: {{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при каждом заказе с доставкой',
          selfPickup:
            'Только для подписчиков: {{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при каждом заказе с самовывозом',
        },
      },
      forNewSubscription: {
        itemForFree: {
          all: 'Новые подписчики: бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при первом заказе по подписке',
          delivery:
            'Новые подписчики: бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при первом заказе по подписке с доставкой',
          selfPickup:
            'Новые подписчики: бесплатно {{valueFormulaSourceQuantity}} {{-sellingUnitName}} {{-storeProductSourceName}} при первом заказе по подписке с самовывозом',
        },
        serviceAreaUsageFeesDiscount: {
          all: {
            fullDiscount: 'Новые подписчики: бесплатная доставка при первом заказе по подписке',
            partialDiscount:
              'Новые подписчики: скидка {{discountValue}} на доставку при первом заказе по подписке',
          },
          delivery: {
            fullDiscount: 'Новые подписчики: бесплатная доставка при первом заказе по подписке',
            partialDiscount:
              'Новые подписчики: скидка {{discountValue}} на доставку при первом заказе по подписке',
          },
          selfPickup: {
            fullDiscount:
              'Новые подписчики: никаких сборов за подготовку заказа при первом заказе по подписке',
            partialDiscount:
              'Новые подписчики: скидка {{discountValue}} на плату за подготовку при первом заказе по подписке',
          },
        },
        generalDiscount: {
          all: {
            smallMinimumOrder:
              'Новые подписчики: скидка {{discountValue}} при первом заказе по подписке',
          },
          delivery: {
            smallMinimumOrder:
              'Новые подписчики: скидка {{discountValue}} при первом заказе по подписке с доставкой',
          },
          selfPickup: {
            smallMinimumOrder:
              'Новые подписчики: скидка {{discountValue}} при первом заказе по подписке с самовывозом',
          },
        },
        itemRefund: {
          all: 'Новые подписчики: {{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при первом заказе по подписке',
          delivery:
            'Новые подписчики: {{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при первом заказе по подписке с доставкой',
          selfPickup:
            'Новые подписчики: {{-storeProductSourceName}} по ₪{{productPromotedPrice}} за {{-primaryQuantityUnit}} при первом заказе по подписке с самовывозом',
        },
      },
    },
  },
  pwa: {
    installerPromptText: 'Добавить нас на главный экран',
    iosPromptTitle: 'Добавить на главный экран',
    iosPromptDescription:
      'Для более быстрого совершения покупок добавьте ״{{-storeName}}״ на главный экран, выполнив 2 простых шага.',
    iosPromptStep1: '1. Нажмите кнопку «Поделиться» в браузере.',
    iosPromptStep2: '2. Нажмите «Добавить на главный экран».',
  },
  serverGenericErrorDescription: 'Произошла внутренняя ошибка сервера',
  seo: {
    products: 'товары',
    genericCategoryMetadata:
      'В {{websiteName}}, мы предлагаем широкий выбор {{categoryName}} непревзойденного качества - ',
    andMore: 'и другие.',
    similarProducts: 'Похожие товары:',
  },
  subCatalog: {
    catalogWasUpdated: 'Пожалуйста, обрати внимание! Каталог был обновлен',
  },
  homePage: {
    toAllProducts: 'Ко всем продуктам',
    previewMode: 'Режим предварительного просмотра',
  },
  membership: {
    youHaveBenefits: '{{benefitsNumber}} клубных преимуществ',
    youEarnedPoints: '{{pointsNumber}} клубных баллов',
    forAllBenefits: 'Все преимущества',
    customerClub: 'Членство в клубе',
    customerClubDesktop: 'Членство в клубе',
    memberNumber: 'Номер участника {{memberNumber}}',
    points: 'Баллы',
    equalToShekel: 'Равняется {{equalNumber}} шекелям',
    benefits: 'Преимущества',
    redeemPoints: 'Обменять баллы',
    use: 'Обменять',
    remove: 'Удалить',
    errorValueIsMoreThanPoints: 'Значение не может быть выше общего количества имеющихся баллов.',
    redeemedPointsToShekel: 'Использованы {{points}} баллов = ₪{{points}}',
    calculatedByExternalCompany:
      'Скидки и льготы рассчитываются сторонней платформой и будут отражены в налоговой накладной после обработки заказа.',
    validUntil: 'Действительно до {{expirationDate}}',
    usedAmountOfPoints: 'Использовано {{points}} баллов',
    membershipClubDiscount: 'Скидки для участников клуба',
  },
  smallEC: {
    promotionsAndDiscounts: 'Акции и скидки',
    chooseFromTheList: 'Выберите из списка',
    addProducts: 'Добавить товары',
    pickupPointNotAvailable:
      'Извините, выбранное время самовывоза больше недоступно. Пожалуйста, выберите другое время',
  },
};

export default russianMessages;

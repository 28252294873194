import { Box, Button } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateContentPagePreview } from 'store/modules/uiStyles/actions';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import useStyles from './styles';

const PreviewContentPagesIndicator: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();

  const exitPreviewMode = useCallback(() => {
    dispatch(updateContentPagePreview(false));
    router.push('/');
  }, [dispatch, router]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <Box display="flex">
          <Icon name="icon-alert-circled" classes={{ root: classes.indicatorIcon }} />
          <Typography
            fontSize={14}
            fontWeight="medium"
            color="light"
            component="span"
            className="white-space-no-wrap"
          >
            {'homePage.previewMode'}
          </Typography>
        </Box>
        <Box>
          <Button className={classes.exitButton} onClick={exitPreviewMode} disableRipple>
            <Typography fontWeight="medium" fontSize={14} color="primary" component="span">
              {'orderModeIndicator.exit'}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewContentPagesIndicator;

import { ContainerProps, createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { MAX_CONTAINER_WIDTH } from './constants';

import { TStylesContainer } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesContainer,
    ContainerProps & { isMobile: boolean; maxContainerWidth: boolean }
  > =>
    createStyles({
      root: {
        width: ({ isMobile, maxContainerWidth }) => {
          if (isMobile) {
            return '100%';
          }
          if (maxContainerWidth) {
            return MAX_CONTAINER_WIDTH;
          }

          return 1140;
        },
        margin: '0 auto',
        padding: ({ isMobile }) => (isMobile ? theme.spacing(0, 14 / 8) : 0),
      },
    }),
);

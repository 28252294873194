import { TUiStylesReducerState } from './types';

export const initialUiStylesState: TUiStylesReducerState = {
  homePage: {
    storeProductCardView: 'multiple',
    hidePromotionsBanner: false,
    hideIncentivePromotion: false,
  },
  isActiveContentPagePreviewMode: false,
};

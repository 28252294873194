import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { MAX_CONTAINER_WIDTH } from 'ui/common/Container';

import { TStylesPreviousOrderItem } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesPreviousOrderItem,
    {
      displayPrice: boolean;
      displayBorderTop: boolean;
      displayBorderBottom: boolean;
      displayCheckBox: boolean;
      displayOutOfStockSection: boolean;
      outOfStock?: boolean;
    }
  > =>
    createStyles({
      wrapper: {
        cursor: 'default',
        height: '100px',
        position: 'relative',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.background.paper, // override default theme hover button behavior
        },
        padding: theme.spacing(18 / 8, 10 / 8, 18 / 8),
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: ({ displayBorderTop, displayBorderBottom }) => {
          if (displayBorderBottom && displayBorderTop) {
            return theme.spacing(1);
          }

          if (displayBorderTop) {
            return theme.spacing(1, 1, 0, 0);
          }

          if (displayBorderBottom) {
            return theme.spacing(0, 0, 1, 1);
          }

          return '0';
        },
        [theme.breakpoints.up('md')]: {
          padding: ({ displayCheckBox }) =>
            theme.spacing(18 / 8, 22 / 8, 18 / 8, displayCheckBox ? 22 / 8 : 37 / 8),
        },
      },
      outOfStockWrapper: {
        transform: 'translate(50%)',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        right: '50%',
      },
      outOfStockLabel: {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        fontWeight: 500,
        textAlign: 'center',
      },
      outOfStockOpacity: ({ displayOutOfStockSection, outOfStock }) => ({
        padding: 0,
        alignItems: 'center',
        position: 'initial',
        opacity: outOfStock && displayOutOfStockSection ? 0.15 : 1,
      }),
      productDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(12 / 8),
        flex: ({ displayPrice }) => {
          if (displayPrice) {
            return 1;
          }
          return 0;
        },
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(22 / 8),
        },
      },
      itemPrice: {
        display: 'flex',
        alignItems: 'center',
      },
      tag: {
        position: 'absolute',
        zIndex: 4,
        left: 39,
        top: 5,
        [theme.breakpoints.up('md')]: {
          left: 60,
          top: 10,
        },
      },
      tagWithoutCheckbox: {
        zIndex: 1,
        position: 'absolute',
        left: 10,
        top: 5,
        [theme.breakpoints.up('md')]: {
          left: 10,
          top: 4,
        },
      },
      productWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          flex: '0 1 auto',
        },
      },
      imageWrapper: ({ displayCheckBox, displayOutOfStockSection, outOfStock }) => ({
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(displayCheckBox ? 6 / 8 : 10 / 8),
        marginLeft: theme.spacing(displayOutOfStockSection && outOfStock ? 28 / 8 : 0),

        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(12 / 8),
          marginLeft: theme.spacing(displayOutOfStockSection && outOfStock ? 30 / 8 : 3 / 8),
        },
      }),
      description: {
        display: 'flex',
        alignItems: 'center',
      },
      cardImage: ({ displayCheckBox }) => ({
        width: displayCheckBox ? '70px' : '74px',
        height: '54.2px',
        [theme.breakpoints.up('md')]: {
          width: '90px',
          height: '52px',
        },
      }),
      totalPrice: ({ displayCheckBox }) => ({
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        width: displayCheckBox ? '18vw' : '70px',
        [theme.breakpoints.up('md')]: {
          width: displayCheckBox ? '80px' : '95px',
        },
      }),
      quantity: ({ displayCheckBox }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(4 / 8),
        width: '40px',
        [theme.breakpoints.up(MAX_CONTAINER_WIDTH)]: {
          width: displayCheckBox ? '60px' : '90px',
          marginRight: 0,
        },
      }),
      descriptionText: ({ displayCheckBox, displayPrice }) => ({
        display: '-webkit-box',
        '-webkitLineClamp': 3,
        '-webkitBoxOrient': 'vertical',
        fontSize: '14px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: !displayCheckBox && !displayPrice ? '46vw' : '32vw',
        maxHeight: '64px',
        textAlign: 'initial',
        [theme.breakpoints.up(MAX_CONTAINER_WIDTH)]: {
          fontSize: '16px',
          lineHeight: '18px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth:
            !displayCheckBox && !displayPrice ? '400px' : (displayCheckBox && '180px') || '200px',
          maxHeight: '64px',
        },
      }),
      priceAndSellingUnit: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 400,
        letterSpacing: '-0.2px',
      },
    }),
);

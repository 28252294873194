import { TPromotionsReducer } from './types';

import { GET_CLUB_MEMBERSHIP_PROFILE_SUCCESS, GET_PROMOTIONS_SUCCESS } from './constants';

import { initialPromotionsState } from './initialState';

const promotionsReducer: TPromotionsReducer = (state = initialPromotionsState, action) => {
  switch (action.type) {
    case GET_PROMOTIONS_SUCCESS:
    case GET_CLUB_MEMBERSHIP_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { promotionsReducer };

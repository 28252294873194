import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesAutocomplete } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesAutocomplete> =>
    createStyles({
      root: {
        height: '40px',
      },
      paper: {
        borderRadius: theme.spacing(0.5), // 4px
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: theme.palette.grey[50],
        marginTop: theme.spacing(1), // 8px
      },
      option: {
        minHeight: theme.spacing(2.25), // 18px
        paddingLeft: theme.spacing(1.625), // 13px
        paddingTop: theme.spacing(1), // 8px
        paddingBottom: theme.spacing(1), // 8px
        '&[data-focus="true"]': {
          backgroundColor: theme.palette.grey[50],
        },
        '&[aria-selected="true"]': {
          backgroundColor: 'inherit',
        },
      },
      listbox: {
        padding: theme.spacing(1.375, 0),
      },
      closeButtonRoot: {
        fontSize: '12px',
        padding: 0,
        marginLeft: '6px',
      },
    }),
);

import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  takeEvery,
} from '@redux-saga/core/effects';
import { CatalogService, OrderService, SubscriptionService } from 'api';
import { TActionResult } from 'api/Api/BaseApi/types';
import {
  authSelectors,
  catalogActions,
  CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED,
  GET_STORE_PRODUCTS_SUCCESS,
  ICheckAndFetchBranchCatalogIfNecessaryRequest,
  IFetchAndUpdateClientAndAreaSubCatalog,
  IFetchCatalogBySubCatalogId,
  ISetCatalogIdRequest,
  ISetCorrectBranchSubCatalogWasFetched,
  ISetOrderItemsSuccess,
  IStartEditSubscription,
  IUpdateItemsAccordingToCurrentlyDisplayedSubCatalogRequest,
  IUpdateKnownAddressAndSubCatalog,
  IValidateAndEditOrder,
  orderActions,
  orderSelectors,
  SET_ORDER_ITEMS_SUCCESS,
  shoppingFlowActions,
  shoppingFlowSelectors,
  TOrderDetailsReducerState,
  TOrderDiscount,
  TOrderReducerState,
  UPDATE_ITEMS_ACCORDING_TO_CURRENTLY_DISPLAYED_SUB_CATALOG,
  userActions,
} from 'store';
import { getCatalogId } from 'store/modules/catalog/selectors';
import { dialogActions, GENERIC_DIALOG, IShowDialog } from 'store/modules/dialog';
import { IShowNotify, notifyActions } from 'store/modules/notify';
import { orderData, orderItems } from 'store/modules/order/selectors';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { getOrderDiscounts } from 'store/modules/orderDiscounts/selectors';
import { validateAndEditOrder } from 'store/modules/orderEditing/actions';
import { getKnownAddressSubCatalog } from 'store/modules/shoppingFlow/selectors';
import { IGetStoreProductsRequest, storeProductActions } from 'store/modules/storeProduct';
import { storeProductsData } from 'store/modules/storeProduct/selectors';
import { startEditSubscription } from 'store/modules/subscriptions/actions';
import {
  getClientDetailsAtStoreLevelWereUpdated,
  getDeliveryAreaSubCatalogId,
  getUserSubCatalogId,
} from 'store/modules/user/selectors';
import { IWebsiteDetails } from 'store/modules/website';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import { TOrderItemDTO, TOrderServer, TPublicCatalogServer, TSubscriptionServer } from 'types';
import { getCartEstimation } from 'utils/helpers/cartEstimation';
import { filterItemsOutOfStock } from 'utils/helpers/order/items';
import {
  fetchCatalogBySubCatalogId,
  setCatalogId,
  setCorrectBranchSubCatalogWasFetched,
  updateItemsAccordingToCurrentlyDisplayedSubCatalog,
} from './actions';
import {
  CHECK_AND_FETCH_BRANCH_CATALOG_IF_NECESSARY,
  FETCH_CATALOG_VIA_SUB_CATALOG_ID,
} from './constants';

const CATALOG_INVALID_ERR = 'CATALOG_INVALID_ERR';

function* updateItemsAccordingToSubCatalog(): Generator<
  | SelectEffect
  | CallEffect
  | PutEffect<
      | IFetchCatalogBySubCatalogId
      | IShowDialog
      | IShowNotify
      | IGetStoreProductsRequest
      | ISetCatalogIdRequest
      | ISetOrderItemsSuccess
    >,
  void,
  never
> {
  const currentOrderItems: TOrderItemDTO[] = yield select(orderItems);
  const currentOrder = yield select(orderSelectors.orderData);
  const currentOrderDiscount: TOrderDiscount[] = yield select(getOrderDiscounts);

  const currentItemsEstimation = getCartEstimation(
    currentOrder,
    currentOrderDiscount.filter(
      (discount) =>
        discount.discountType.name !== 'serviceAreaUsageFeesDiscount' &&
        !discount.creditedAtCheckout,
    ),
    0,
  );

  const { storeProductById, ancestorStoreProductById } = yield select(storeProductsData);

  const orderMode: TOrderDetailsReducerState['orderMode'] = yield select(getOrderMode);

  const { itemsInStock, itemsOutOfStock } = filterItemsOutOfStock(
    currentOrderItems,
    storeProductById,
    ancestorStoreProductById,
  );

  let newOrderItems: TOrderItemDTO[] = itemsInStock;

  if (orderMode === 'edit') {
    newOrderItems = [...itemsInStock, ...itemsOutOfStock];
  }

  yield put(orderActions.setOrderItemsSuccessAction(newOrderItems as TOrderItemDTO[]));

  const newOrder = yield select(orderSelectors.orderData);
  const newOrderDiscounts: TOrderDiscount[] = yield select(getOrderDiscounts);

  const newItemsEstimation = getCartEstimation(
    newOrder,
    newOrderDiscounts.filter(
      (discount) =>
        discount.discountType.name !== 'serviceAreaUsageFeesDiscount' &&
        !discount.creditedAtCheckout,
    ),
    0,
  );

  if (newItemsEstimation !== currentItemsEstimation) {
    yield put(
      dialogActions.showDialog({
        dialogType: GENERIC_DIALOG,
        contentProps: {
          title: 'dialog.catalogWasUpdated.title',
          body: 'dialog.catalogWasUpdated.body',
          buttons: [
            {
              text: 'dialog.catalogWasUpdated.okButton',
              variant: 'contained',
              closeButton: true,
            },
          ],
        },
      }),
    );
    return;
  }

  yield put(
    notifyActions.showNotification({
      message: 'subCatalog.catalogWasUpdated',
    }),
  );
}

function* fetchCatalogByCatalogId({
  payload,
}: IFetchCatalogBySubCatalogId): Generator<
  | SelectEffect
  | CallEffect
  | PutEffect<
      | IFetchCatalogBySubCatalogId
      | IShowDialog
      | IShowNotify
      | IGetStoreProductsRequest
      | ISetCatalogIdRequest
      | ISetOrderItemsSuccess
      | IUpdateItemsAccordingToCurrentlyDisplayedSubCatalogRequest
      | ISetCorrectBranchSubCatalogWasFetched
      | IStartEditSubscription
      | IValidateAndEditOrder
      | IFetchAndUpdateClientAndAreaSubCatalog
      | IUpdateKnownAddressAndSubCatalog
      | ICheckAndFetchBranchCatalogIfNecessaryRequest
    >,
  void,
  never
> {
  const { catalogId, catalogRequestParams, callback } = payload;

  const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);
  const orderMode: TOrderDetailsReducerState['orderMode'] = yield select(getOrderMode);
  const order: TOrderReducerState = yield select(orderData);
  const isLoggedIn: boolean = yield select(authSelectors.isLoggedIn);

  try {
    const catalogResult: TActionResult<TPublicCatalogServer> = yield call(
      CatalogService.getPublicCatalog,
      {
        websiteJWE: websiteDetails.jsonWebEncryption,
        catalogId,
        master: (orderMode === 'edit' || orderMode === 'editSubscription') && !catalogId,
        ...catalogRequestParams,
      },
    );

    if (!catalogResult.success) {
      throw new Error(CATALOG_INVALID_ERR);
    }

    yield put(
      storeProductActions.getStoreProductsRequest(
        catalogResult.data.products,
        websiteDetails.store.performSellingUnitsEstimationLearning,
      ),
    );
    yield put(setCatalogId(catalogId));

    if (callback) {
      yield call(callback);
    }
  } catch (err: unknown) {
    const error = err as Error;

    if (error.message === CATALOG_INVALID_ERR) {
      if (order.id) {
        if (orderMode === 'edit') {
          const actionResult: TActionResult<TOrderServer> = yield call(
            OrderService.getOrder,
            order.id,
          );
          yield put(validateAndEditOrder(actionResult.data));
        }

        if (orderMode === 'editSubscription') {
          const actionResult: TActionResult<TSubscriptionServer> = yield call(
            SubscriptionService.getSubscription,
            order.id,
          );
          yield put(startEditSubscription(actionResult.data));
        }
        return;
      }

      if (isLoggedIn) {
        yield put(userActions.fetchAndUpdateClientAndAreaSubCatalogRequest());
      } else {
        yield put(shoppingFlowActions.updateKnownAddressAndSubCatalog(null, null));
      }

      yield put(catalogActions.checkAndFetchBranchCatalogIfNecessary());
    }
  }
}

function* checkAndFetchBranchCatalogIfNecessary({
  payload,
}: ICheckAndFetchBranchCatalogIfNecessaryRequest): Generator<
  | SelectEffect
  | CallEffect
  | TakeEffect
  | PutEffect<
      | IFetchCatalogBySubCatalogId
      | IUpdateItemsAccordingToCurrentlyDisplayedSubCatalogRequest
      | ISetCorrectBranchSubCatalogWasFetched
    >,
  void,
  never
> {
  const { successCallback } = payload;

  const isLoggedIn: boolean = yield select(authSelectors.isLoggedIn);
  const clientDetailsAtStoreLevelUpdated = yield select(getClientDetailsAtStoreLevelWereUpdated);

  if (isLoggedIn && !clientDetailsAtStoreLevelUpdated) {
    yield take(CLIENT_DETAILS_AT_STORE_LEVEL_WERE_UPDATED);
  }

  const currentlyDisplayedCatalogId = yield select(getCatalogId);
  const userSubCatalogId = yield select(getUserSubCatalogId);
  const deliveryAreaSubCatalogId = yield select(getDeliveryAreaSubCatalogId);
  const knownAddressSubCatalogId = yield select(getKnownAddressSubCatalog);
  const knownAddress = yield select(shoppingFlowSelectors.getKnownAddress);
  const orderDetails: TOrderDetailsReducerState = yield select(getOrderDetails);
  const order: TOrderReducerState = yield select(orderSelectors.orderData);
  const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);

  const orderMode: TOrderDetailsReducerState['orderMode'] = yield select(getOrderMode);

  let requestedCatalog: IFetchCatalogBySubCatalogId['payload']['catalogId'] = userSubCatalogId;

  if (orderMode === 'edit' || orderMode === 'editSubscription') {
    requestedCatalog = order.subCatalog;
  }

  if (orderMode === 'new' || orderMode === 'addSubscription') {
    if (!userSubCatalogId && orderDetails.orderType === 'delivery') {
      requestedCatalog = deliveryAreaSubCatalogId;
    }

    if (knownAddress && !isLoggedIn) {
      requestedCatalog = knownAddressSubCatalogId;
    }

    // in some scenario requestedCatalog will be null which means show catalog of the website
    // and main catalog of the website is subCatalog
    if (!requestedCatalog && websiteDetails.subCatalog?.id) {
      requestedCatalog = websiteDetails.subCatalog?.id;
    }

    if (orderDetails.orderType === 'selfPickup') {
      requestedCatalog = userSubCatalogId || websiteDetails.subCatalog?.id;
    }
  }

  if (
    requestedCatalog === currentlyDisplayedCatalogId ||
    (!requestedCatalog && !currentlyDisplayedCatalogId)
  ) {
    yield put(setCorrectBranchSubCatalogWasFetched(true));
    if (successCallback) {
      successCallback();
    }
    return;
  }

  yield put(fetchCatalogBySubCatalogId(requestedCatalog));
  yield take(GET_STORE_PRODUCTS_SUCCESS);
  yield put(setCorrectBranchSubCatalogWasFetched(true));
  yield put(updateItemsAccordingToCurrentlyDisplayedSubCatalog());

  if (successCallback) {
    yield take(SET_ORDER_ITEMS_SUCCESS);
    successCallback();
  }
}

function* rootCatalogSaga(): Generator {
  yield takeEvery(
    CHECK_AND_FETCH_BRANCH_CATALOG_IF_NECESSARY,
    checkAndFetchBranchCatalogIfNecessary,
  );
  yield takeEvery(FETCH_CATALOG_VIA_SUB_CATALOG_ID, fetchCatalogByCatalogId);
  yield takeEvery(
    UPDATE_ITEMS_ACCORDING_TO_CURRENTLY_DISPLAYED_SUB_CATALOG,
    updateItemsAccordingToSubCatalog,
  );
}

export default rootCatalogSaga;

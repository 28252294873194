import React, { useCallback } from 'react';

import Typography from 'ui/common/Typography';

import { useRenderPrice } from 'hooks/useRenderPrice';
import useStyles from './styles';

import { TRenderCartItemTotalPrice, TRenderPricePerUnit, TUseCardPrice } from './types';

const useCardPrice: TUseCardPrice = (pricingSellingUnitName) => {
  const renderPrice = useRenderPrice();
  const classes = useStyles();

  // used for render price and old price on one way
  const renderCardPrice = useCallback<TRenderPricePerUnit>(
    (pricePerUnit, rootTypographyProps, priceTypographyProps = {}) => {
      const priceProps = { ...rootTypographyProps, ...priceTypographyProps };

      return (
        <Typography
          variant="body2"
          color="textPrimary"
          isTranslate={false}
          align="center"
          className={classes.priceText}
          {...rootTypographyProps}
        >
          <Typography
            component="span"
            variant="body2"
            display="inline"
            isTranslate={false}
            {...priceProps}
          >
            {renderPrice(pricePerUnit)}
          </Typography>
          {pricingSellingUnitName && (
            <>
              {'/'}
              {pricingSellingUnitName}
            </>
          )}
        </Typography>
      );
    },
    [classes.priceText, renderPrice, pricingSellingUnitName],
  );

  // render price and old price, used in Cart Item Desktop
  const renderCartItemTotalPrice = useCallback<TRenderCartItemTotalPrice>(
    (price, originalPrice, isMobile, fontWeight = 'bold', displayPricingSellingUnitName) => {
      return (
        <>
          <Typography
            variant="body2"
            color="textPrimary"
            isTranslate={false}
            align="center"
            className={classes.priceText}
          >
            <Typography
              component="span"
              variant="body2"
              display="inline"
              isTranslate={false}
              fontSize={16}
              fontWeight={fontWeight}
            >
              {displayPricingSellingUnitName
                ? renderCardPrice(price, { fontSize: isMobile ? 12 : 16 })
                : renderPrice(price)}
            </Typography>
          </Typography>
          {!!originalPrice && originalPrice > price && (
            <Typography
              variant="body2"
              color="textPrimary"
              isTranslate={false}
              className={classes.productSaleTextForProductDetails}
            >
              <Typography
                component="span"
                variant="body2"
                display="inline"
                isTranslate={false}
                color="mediumGrey"
                fontSize={isMobile ? 12 : 13}
                className={classes.originalTotalPrice}
              >
                {displayPricingSellingUnitName
                  ? renderCardPrice(originalPrice, {
                      className: classes.productOldPriceText,
                      fontSize: isMobile ? 12 : 13,
                      color: 'mediumGrey',
                    })
                  : renderPrice(originalPrice)}
              </Typography>
            </Typography>
          )}
        </>
      );
    },
    [
      classes.originalTotalPrice,
      classes.priceText,
      classes.productOldPriceText,
      classes.productSaleTextForProductDetails,
      renderCardPrice,
      renderPrice,
    ],
  );

  return {
    renderCardPrice,
    renderCartItemTotalPrice,
  };
};

export default useCardPrice;

import React, { FC, useMemo } from 'react';
import DropDown, { TDropDownOption } from 'ui/common/inputs/DropDown';
import SellingUnitsSelectionButtons from 'ui/common/SellingUnitsSelectionButtons';

import { IProductSellingUnit } from 'store';

import { useMultiLanguage } from 'hooks/useMultiLanguage';

import { IUnitSwitcher } from './types';

const UnitSwitcher: FC<IUnitSwitcher> = ({ productSellingUnits, activeSellingUnit, onChange }) => {
  const { renderLanguageField } = useMultiLanguage();

  const productSellingUnitsAsDropdownOptions = useMemo<TDropDownOption[]>(() => {
    return productSellingUnits.map((productSellingUnit) => ({
      id: productSellingUnit.id,
      value: productSellingUnit.id,
      text: renderLanguageField(productSellingUnit.sellingUnit.multiLang, 'name'),
    }));
  }, [renderLanguageField, productSellingUnits]);

  return (
    <>
      {/* If we have 1 item into productSellingUnits - render UnitTypeButton, if no - render Dropdown */}
      {productSellingUnits.length === 1 ? (
        <SellingUnitsSelectionButtons
          size="small"
          height="20px"
          activeSellingUnit={activeSellingUnit}
          productSellingUnits={productSellingUnits}
        />
      ) : (
        <DropDown
          size="small"
          dropDownOptions={productSellingUnitsAsDropdownOptions}
          value={activeSellingUnit.id}
          onChange={(event) =>
            onChange && onChange(event.target.value as IProductSellingUnit['id'])
          }
        />
      )}
    </>
  );
};

export default UnitSwitcher;

import {
  CLIENT_COUPONS_UPDATE,
  GET_STATIC_PROMOTIONS,
  ORDER_DISCOUNTS_RECALCULATE,
  ORDER_DISCOUNTS_UPDATE,
  STORE_COUPON_UPDATE,
  UPDATE_MEMBERSHIP_BENEFITS,
  UPDATE_STATIC_PROMOTIONS,
} from './constants';

import {
  TClientCouponsUpdateAction,
  TGetStaticPromotionsAction,
  TOrderDiscountsRecalculateAction,
  TOrderDiscountsUpdateAction,
  TStoreCouponUpdateAction,
  TUpdateMembershipBenefitsAction,
  TUpdateStaticPromotionsAction,
} from './types';

const orderDiscountsRecalculate: TOrderDiscountsRecalculateAction = () => ({
  type: ORDER_DISCOUNTS_RECALCULATE,
});

const orderDiscountsUpdate: TOrderDiscountsUpdateAction = (newPromotions) => ({
  type: ORDER_DISCOUNTS_UPDATE,
  payload: { discounts: newPromotions },
});

const clientCouponsUpdate: TClientCouponsUpdateAction = (newCoupons) => ({
  type: CLIENT_COUPONS_UPDATE,
  payload: {
    clientCoupons: newCoupons,
  },
});

const storeCouponUpdate: TStoreCouponUpdateAction = (newCoupon) => ({
  type: STORE_COUPON_UPDATE,
  payload: {
    storeCoupon: newCoupon,
  },
});

const getStaticPromotions: TGetStaticPromotionsAction = (staticPromotions) => ({
  type: GET_STATIC_PROMOTIONS,
  payload: {
    staticPromotions,
  },
});

const updateMembershipBenefits: TUpdateMembershipBenefitsAction = (membershipBenefits) => ({
  type: UPDATE_MEMBERSHIP_BENEFITS,
  payload: {
    membershipBenefits,
  },
});

const updateStaticPromotions: TUpdateStaticPromotionsAction = (
  staticPromotionsById,
  staticPromotionsIds,
) => ({
  type: UPDATE_STATIC_PROMOTIONS,
  payload: {
    staticPromotions: {
      staticPromotionsById,
      staticPromotionsIds,
    },
  },
});

export {
  orderDiscountsRecalculate,
  orderDiscountsUpdate,
  clientCouponsUpdate,
  storeCouponUpdate,
  updateStaticPromotions,
  getStaticPromotions,
  updateMembershipBenefits,
};

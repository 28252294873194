import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/he';

import Icon from 'ui/common/icons/Icon';

import { useTheme } from '@material-ui/core';
import useStyles from './styles';
import { IDatePicker } from './types';

const DatePicker: FC<IDatePicker> = (props) => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const theme = useTheme();

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={i18n.language === 'he' ? deLocale : undefined}
    >
      <KeyboardDatePicker
        margin="normal"
        id="date-picker-dialog"
        format="dd/MM/yyyy"
        placeholder="dd/mm/yyyy"
        autoComplete="off"
        okLabel={t('button.ok')}
        cancelLabel={t('button.cancel')}
        keyboardIcon={<Icon name="icon-calendar" classes={{ root: classes.icon }} />}
        InputProps={{
          style: {
            height: 46,
            direction: theme.direction === 'rtl' ? 'ltr' : 'rtl',
          },
          disableUnderline: true,
        }}
        DialogProps={{ className: classes.dataPickerRoot }}
        style={{
          width: '100%',
          margin: '12px 0px',
          border: `1px solid ${theme.palette.grey.A200}`,
          borderRadius: '6px',
          padding: theme.spacing(
            0,
            theme.direction === 'rtl' ? 0 : 14 / 8,

            0,
            theme.direction === 'rtl' ? 14 / 8 : 0,
          ),
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;

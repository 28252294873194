import { useEffect, useState } from 'react';

const useAutoHideOnKeyboardOpen = (): boolean => {
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    document.querySelectorAll('input').forEach((item) => {
      item.addEventListener('focus', () => setVisible(false));
    });
    document.querySelectorAll('input').forEach((item) => {
      item.addEventListener('blur', () => setVisible(true));
    });

    return function cleanup() {
      document.querySelectorAll('input').forEach((item) => {
        item.removeEventListener('focus', () => {});
      });
      document.querySelectorAll('input').forEach((item) => {
        item.removeEventListener('blur', () => {});
      });
    };
  }, []);

  return visible;
};

export default useAutoHideOnKeyboardOpen;

/* eslint-disable no-param-reassign */
import { find } from 'lodash';
import { TOrderDiscount, TOrderDiscountState } from 'store/modules/orderDiscounts';
import { IPromotion } from 'store/modules/promotions';
import { IStoreProduct } from 'store/modules/storeProduct';
import { TMixedType } from 'types';

export function forWeighableBetweenQuantityProductAdded(
  storeProduct: IStoreProduct,
  requestedSellingUnitId: TMixedType,
  quantityAdded: number,
  cartIndex: number,
  promotion: IPromotion,
  orderDiscount: TOrderDiscount,
): void {
  const activeUnit = find(storeProduct.productSellingUnits, ['id', requestedSellingUnitId]);
  const primaryUnitConversionRateToKG =
    storeProduct.product.primaryQuantityUnit.conversionRateToKG || 1;
  let requestedQuantityAddedInKgOrUnit =
    (quantityAdded * (activeUnit?.estimatedUnitWeight || 1)) / primaryUnitConversionRateToKG;
  const minQuantity = promotion.parameters.minQuantity || 0;
  const maxQuantity = promotion.parameters.maxQuantity || 0;
  const newPriceForKG = promotion.parameters.newPriceForKG || 0;

  if (orderDiscount.accumulatedQuantity + requestedQuantityAddedInKgOrUnit > maxQuantity) {
    requestedQuantityAddedInKgOrUnit = maxQuantity - orderDiscount.accumulatedQuantity;
  }

  orderDiscount.accumulatedQuantity += requestedQuantityAddedInKgOrUnit;
  orderDiscount.accumulatedProducts.push({
    storeProduct,
    quantity: requestedQuantityAddedInKgOrUnit,
    cartIndex,
  });

  if (orderDiscount.accumulatedQuantity >= minQuantity) {
    orderDiscount.state = TOrderDiscountState.Active;
    orderDiscount.cartIndex = orderDiscount.accumulatedProducts.reduce(
      (maxCartIndex, accumulatedProduct) => Math.max(maxCartIndex, accumulatedProduct.cartIndex),
      0,
    );
    let originalTotalPrice = 0;
    let originalTotalQuantity = 0;

    orderDiscount.accumulatedProducts.forEach((accumulatedProduct) => {
      originalTotalPrice +=
        accumulatedProduct.storeProduct.price *
        primaryUnitConversionRateToKG *
        accumulatedProduct.quantity;
      originalTotalQuantity += accumulatedProduct.quantity;
    });

    const newTotalPrice = newPriceForKG * originalTotalQuantity;
    orderDiscount.totalParticipatingProductsPrice = newTotalPrice;
    orderDiscount.totalPrice = newTotalPrice - originalTotalPrice;
  }
}

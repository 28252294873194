import { omit } from 'lodash';
import { LOG_OUT_SUCCESS } from 'store/modules/auth';
import { PLACE_ORDER_SUCCESS } from 'store/modules/order';

import {
  CLIENT_COUPONS_UPDATE,
  ORDER_DISCOUNTS_UPDATE,
  STORE_COUPON_UPDATE,
  UPDATE_MEMBERSHIP_BENEFITS,
  UPDATE_STATIC_PROMOTIONS,
} from './constants';

import { initialOrderDiscountsState, initialStaticPromotionsState } from './initialState';
import { TOrderDiscountsReducer } from './types';

const orderDiscountsReducer: TOrderDiscountsReducer = (
  state = initialOrderDiscountsState,
  action,
) => {
  switch (action.type) {
    case ORDER_DISCOUNTS_UPDATE:
    case CLIENT_COUPONS_UPDATE:
    case UPDATE_STATIC_PROMOTIONS:
    case UPDATE_MEMBERSHIP_BENEFITS:
      return {
        ...state,
        ...action.payload,
      };
    case STORE_COUPON_UPDATE:
      return {
        ...omit(state, 'storeCoupon'),
        ...action.payload,
      };

    case PLACE_ORDER_SUCCESS:
      return {
        ...initialOrderDiscountsState,
      };

    case LOG_OUT_SUCCESS:
      return {
        ...state,
        clientCoupons: [],
        ...initialStaticPromotionsState,
      };
    default:
      return state;
  }
};

export { orderDiscountsReducer };

import { TUseAnalytics } from 'hooks/useAnalytics/types';
import { useEffect } from 'react';
import { initStoreAnalyticsAccounts, pageView } from 'store/modules/analytics/actions';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

const useAnalytics: TUseAnalytics = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    dispatch(initStoreAnalyticsAccounts());
  }, [dispatch]);

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      dispatch(pageView(url));
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [dispatch, router.events]);
};

export default useAnalytics;

import { Box } from '@material-ui/core';

import classNames from 'classnames';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import Link from 'ui/common/Link';

import Typography from 'ui/common/Typography';
import useStyles from './styles';
import { ITextInsideImage } from './types';

const TextInsideImage: FC<ITextInsideImage> = ({
  title,
  description,
  backgroundImage,
  linkLabel,
  linkHref,
}) => {
  const mainLayoutContext = useContext(MainLayoutContext);
  const classes = useStyles({
    backgroundImage,
    isOpenedTopCart: mainLayoutContext.isOpenedTopCart,
  });

  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const backgroundImageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentWrapperRef.current && backgroundImageRef.current) {
      const textBoxHeight = contentWrapperRef.current.offsetHeight + 200;
      backgroundImageRef.current.style.height = `${textBoxHeight}px`;
    }
  }, []);

  return (
    <Box position="relative">
      <Box className={classes.contentBackground}>
        <Box className={classes.wrapper}>
          <div className={classes.textInsideImageWrapper} ref={backgroundImageRef}>
            <Box className={classes.overlay}>
              <div ref={contentWrapperRef}>
                <Typography
                  isTranslate={false}
                  className={classNames(classes.textInsideImageTitle, 'font-family-heebo')}
                  variant="body1"
                >
                  {title}
                </Typography>
                <Typography
                  isTranslate={false}
                  className={classes.textInsideImageDescription}
                  variant="body1"
                >
                  {description}
                </Typography>
                {linkLabel && linkHref && (
                  <Box margin="0 auto">
                    <Link
                      href={linkHref as string}
                      targetBlank
                      className={classes.textInsideImageButton}
                    >
                      <Typography className={classes.buttonLabel}>{linkLabel}</Typography>
                    </Link>
                  </Box>
                )}
              </div>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default TextInsideImage;

import React, { FC, useMemo } from 'react';

import classNames from 'classnames';

import { Box, IconButton, useTheme } from '@material-ui/core';

import { format } from 'date-fns';

import { useRenderDate, useRenderPrice } from 'hooks';
import { renderItemCount } from 'utils';

import Icon from '../icons/Icon';
import Typography from '../Typography';

import useStyles from './styles';

import { IPreviousOrder } from './types';

const formatDate: string = 'dd.MM';

const PreviousOrder: FC<IPreviousOrder> = ({
  watchButtonClick,
  price,
  itemsCount,
  deliveryDate,
  weekDayNumber,
}) => {
  const classes = useStyles();

  const renderPrice = useRenderPrice();
  const { renderDayShortName, renderDay } = useRenderDate();

  const theme = useTheme();

  const renderWatchButton = useMemo(() => {
    return (
      <IconButton
        size="small"
        onClick={watchButtonClick}
        classes={{ root: classes.watchIconButtonRoot }}
        TouchRippleProps={{ classes: { child: classes.buttonRipple } }}
      >
        <Icon fontSize="default" name="icon-eye" color="action" />
      </IconButton>
    );
  }, [classes, watchButtonClick]);

  const renderPriceSection = useMemo(() => {
    return (
      <>
        <Typography
          dir="ltr" // need because price can be big
          color="textSecondary"
          className={classNames(classes.typography, classes.price)}
        >
          {renderPrice(price)}
        </Typography>
        <Typography fontSize={14} color="textSecondary">
          {'previousOrder.price'}
        </Typography>
      </>
    );
  }, [price, renderPrice, classes]);

  const renderCount = useMemo(() => {
    return (
      <>
        <Typography color="textSecondary" className={classes.typography}>
          {renderItemCount(itemsCount)}
        </Typography>
        <Typography fontSize={14} color="textSecondary">
          {'previousOrder.items'}
        </Typography>
      </>
    );
  }, [itemsCount, classes]);

  const renderPreviousOrderDate = useMemo(() => {
    return format(new Date(deliveryDate), formatDate);
  }, [deliveryDate]);

  const renderDelivery = useMemo(() => {
    return (
      <>
        <Typography color="textSecondary" className={classes.typography}>
          {renderPreviousOrderDate}
        </Typography>
        <Typography fontSize={14} color="textSecondary">
          {theme.direction === 'rtl' ? renderDay(weekDayNumber) : renderDayShortName(weekDayNumber)}
        </Typography>
      </>
    );
  }, [
    classes.typography,
    renderPreviousOrderDate,
    theme.direction,
    renderDay,
    weekDayNumber,
    renderDayShortName,
  ]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.infoSection}>
        <Box className={classes.deliverySection}>{renderDelivery}</Box>
        <Box className={classes.countSection}>{renderCount}</Box>
        <Box>{renderPriceSection}</Box>
      </Box>
      <Box className={classes.watchSection}>{renderWatchButton}</Box>
    </Box>
  );
};

export default PreviousOrder;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesActionsOnCartButton } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesActionsOnCartButton, { size: 'regular' | 'large' }> =>
    createStyles({
      actionsOnCartButtonRoot: ({ size }) => ({
        flip: false as unknown as string,
        direction: 'rtl',
        borderRadius: '20px',
        height: size === 'large' ? '40px' : '30px',
        minWidth: size === 'large' ? '150px' : '126px',
      }),
      startIconRoot: {
        flip: false as unknown as string,
        marginRight: theme.spacing(-2 / 8),
        marginLeft: theme.spacing(theme.direction === 'rtl' ? 11 / 8 : 6 / 8),
        '& > *:first-child': {
          fontSize: ({ size }) => (size === 'large' ? '16px' : '12px'),
        },
      },
      buttonLabel: ({ size }) => ({
        fontSize: size === 'large' ? '18px' : '15px',
        whiteSpace: 'nowrap',
      }),
      icon: ({ size }) => ({
        fontSize: size === 'large' ? '16px' : '12px',
      }),
      buttonText: ({ size }) => ({
        fontSize: size === 'large' ? '18px' : '15px',
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.primary.main,
        padding: 0,
        width: size === 'large' ? '50px' : '46px',
        minWidth: 'auto',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }),
      buttonActionRoot: {
        flip: false as unknown as string,
        backgroundColor: theme.palette.primary.main,
        minWidth: '40px',
        width: ({ size }) => (size === 'large' ? '50px' : '46px'),
        borderRadius: '20px',
        boxShadow: 'none',

        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 'none',
        },

        '&:hover': {
          boxShadow: 'none',
        },
      },
      buttonsWrapper: ({ size }) => ({
        direction: 'rtl',
        flip: false as unknown as string,
        borderRadius: '20px',
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main,
        maxWidth: size === 'large' ? '150px' : '126px',
        height: size === 'large' ? '40px' : '30px',
      }),
      removeButton: {
        direction: 'rtl',
        flip: false as unknown as string,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      addButton: {
        direction: 'rtl',
        flip: false as unknown as string,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    }),
);

import React, { FC, useRef, useState } from 'react';

import useSearch from 'hooks/useSearch';
import { IMainLayoutContext, MainLayoutContext } from './MainLayoutContext';

import { IMainLayoutProvider } from './types';

const MainLayoutProvider: FC<IMainLayoutProvider> = ({ children }) => {
  const [isOpenedTopCart, setIsOpenedTopCart] = useState<boolean>(true);
  const [topCartState, setTopCartState] = useState<IMainLayoutContext['userTopCartChoice']>();
  const { searchQuery, searchInputValue, handleChange, handleClear } = useSearch();

  const categoriesRef = useRef<null | HTMLDivElement>(null);

  return (
    <MainLayoutContext.Provider
      value={{
        isOpenedTopCart,
        categoriesRef,
        userTopCartChoice: topCartState,
        setIsOpenedTopCart,
        setUserTopCartChoice: setTopCartState,
        searchContext: {
          searchQuery,
          searchInputValue,
          handleChange,
          handleClear,
        },
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export default MainLayoutProvider;

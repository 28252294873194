import {
  TClientCouponsSelector,
  TGetMembershipBenefitsSelector,
  TGetStaticPromotionsSelector,
  TOrderDiscountsSelector,
  TStoreCouponSelector,
} from './types';

const getOrderDiscounts: TOrderDiscountsSelector = (state) =>
  state.activeOrder.orderDiscounts.discounts;

const getClientCoupons: TClientCouponsSelector = (state) =>
  state.activeOrder.orderDiscounts.clientCoupons;

const getStoreCoupon: TStoreCouponSelector = (state) =>
  state.activeOrder.orderDiscounts.storeCoupon;

const staticPromotionsData: TGetStaticPromotionsSelector = (state) =>
  state.activeOrder.orderDiscounts.staticPromotions;

const getOrderMembershipBenefits: TGetMembershipBenefitsSelector = (state) =>
  state.activeOrder.orderDiscounts.membershipBenefits;

export {
  getOrderDiscounts,
  getClientCoupons,
  getStoreCoupon,
  staticPromotionsData,
  getOrderMembershipBenefits,
};

import {
  TGetChildrenSelector,
  TGetContentPropsSelector,
  TGetDialogPropsSelector,
  TGetDialogTypeSelector,
  TGetOpenSelector,
} from './types';

const getOpen: TGetOpenSelector = ({ dialog }) => dialog.open;

const getDialogChildren: TGetChildrenSelector = ({ dialog }) => dialog.children;

const getDialogProps: TGetDialogPropsSelector = ({ dialog }) => dialog.dialogProps;

const getDialogType: TGetDialogTypeSelector = ({ dialog }) => dialog.dialogType;

const getContentProps: TGetContentPropsSelector = ({ dialog }) => dialog.contentProps;

export { getOpen, getDialogChildren, getDialogProps, getDialogType, getContentProps };

import { all } from 'redux-saga/effects';

import {
  rootAnalyticsSaga,
  rootAuthSaga,
  rootCatalogSaga,
  rootCategorySaga,
  rootConfigSaga,
  rootOrderDetailsSaga,
  rootOrderDiscountsSaga,
  rootOrderEditingSaga,
  rootOrderSaga,
  rootPrepareToPlaceOrderSaga,
  rootPromotionsSaga,
  rootShoppingFlowSaga,
  rootStoreProductSaga,
  rootSubscriptionsSaga,
  rootUserSaga,
  rootWebsiteSaga,
} from 'store';

export default function* rootSaga(): Generator {
  yield all([
    rootOrderSaga(),
    rootOrderDetailsSaga(),
    rootOrderDiscountsSaga(),
    rootOrderEditingSaga(),
    rootCategorySaga(),
    rootCatalogSaga(),
    rootStoreProductSaga(),
    rootPromotionsSaga(),
    rootPrepareToPlaceOrderSaga(),
    rootShoppingFlowSaga(),
    rootAuthSaga(),
    rootUserSaga(),
    rootWebsiteSaga(),
    rootAnalyticsSaga(),
    rootSubscriptionsSaga(),
    rootConfigSaga(),
  ]);
}

import {
  TGetOrderMinTotalValueSelector,
  TGetPrepareToPlaceOrderSelector,
  TIsPrepareToPlaceOrderCompletedSelector,
  TPrepareToPlaceOrderTokenSelector,
} from './types';

const orderToken: TPrepareToPlaceOrderTokenSelector = ({
  activeOrder: {
    prepareToPlaceOrder: { encryptedOrderToken },
  },
}) => encryptedOrderToken;

const isPrepareToPlaceOrderCompleted: TIsPrepareToPlaceOrderCompletedSelector = ({
  activeOrder: {
    orderDetails,
    order: { id },
    prepareToPlaceOrder: { encryptedOrderToken },
  },
}) => {
  switch (orderDetails.orderMode) {
    case 'new':
    default:
      return !!encryptedOrderToken;
    case 'addSubscription':
      return (
        !!encryptedOrderToken &&
        !!orderDetails.frequency &&
        !!orderDetails.preferredDay &&
        !!orderDetails.preferredHour
      );
    case 'edit':
      return !!id;
    case 'editSubscription':
      return (
        !!id &&
        !!orderDetails.frequency &&
        !!orderDetails.preferredDay &&
        !!orderDetails.preferredHour
      );
  }
};

const getPrepareToPlaceOrder: TGetPrepareToPlaceOrderSelector = ({
  activeOrder: { prepareToPlaceOrder },
}) => prepareToPlaceOrder;

const getOrderMinTotalValue: TGetOrderMinTotalValueSelector = ({
  activeOrder: {
    prepareToPlaceOrder: { orderMinTotalValue },
  },
}) => orderMinTotalValue;

export {
  orderToken,
  isPrepareToPlaceOrderCompleted,
  getOrderMinTotalValue,
  getPrepareToPlaceOrder,
};

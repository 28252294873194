/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, PutEffect, SelectEffect, takeEvery } from '@redux-saga/core/effects';

import { forEach } from 'lodash';

import {
  catalogActions,
  IAddOrRemovePromotionsCategory,
  ISetCatalogLastFetchTime,
  storeProductActions,
} from 'store';

import { TStoreProductServer } from 'types';

import { getIdsAndByIds } from 'utils';

import { prepareStoreProductServer } from 'utils/helpers/storeProduct';

import { IGetCategoriesRequest } from '../category';
import { GET_STORE_PRODUCTS_REQUEST } from './constants';

import {
  IGetStoreProductsRequest,
  IGetStoreProductsSuccess,
  TStoreProductReducerState,
} from './types';

function* getStoreProducts({
  payload,
}: IGetStoreProductsRequest): Generator<
  | SelectEffect
  | PutEffect<IGetCategoriesRequest>
  | PutEffect<IGetStoreProductsSuccess>
  | PutEffect<IAddOrRemovePromotionsCategory>
  | PutEffect<ISetCatalogLastFetchTime>,
  void,
  any
> {
  const filteredPayload = payload.storeProducts.filter(
    (storeProduct) => !!storeProduct && storeProduct.product,
  );
  const { objById, arrayIds } = getIdsAndByIds<TStoreProductServer>(filteredPayload);

  // new objects with ids in productCategory and productSellingUnits
  const storeProductById: TStoreProductReducerState['storeProductById'] = {};
  const ancestorStoreProductById: TStoreProductReducerState['storeProductById'] = {};

  forEach(objById, (storeProduct) => {
    if (storeProduct.product) {
      const newStoreProduct = prepareStoreProductServer(
        storeProduct,
        payload.useMLWeightEstimations,
      );

      storeProductById[storeProduct.id] = newStoreProduct;

      if (storeProduct.ancestor) {
        ancestorStoreProductById[storeProduct.ancestor.id] = newStoreProduct;
      }
    }
  });

  // update store product state
  yield put(
    storeProductActions.getStoreProductsSuccess(
      arrayIds,
      storeProductById,
      ancestorStoreProductById,
    ),
  );

  yield put(catalogActions.setCatalogLastFetchTime(new Date().getTime()));
}

function* rootStoreProductSaga(): Generator {
  yield takeEvery(GET_STORE_PRODUCTS_REQUEST, getStoreProducts);
}

export default rootStoreProductSaga;

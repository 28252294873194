/* eslint-disable react/jsx-no-bind */
import { Box, Divider } from '@material-ui/core';

import { BaseApi } from 'api';

import { TActionResult } from 'api/Api/BaseApi/types';
import classNames from 'classnames';

import { useAutocompleteOptionTitle } from 'hooks';

import { debounce } from 'lodash';

import { stringify } from 'qs';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Icon from 'ui/common/icons/Icon';

import Autocomplete, {
  IAutocompleteProps,
  TAutocompleteValue,
} from 'ui/common/inputs/Autocomplete';
import Typography from 'ui/common/Typography';

import useStyles from './styles';

import { TFetchData, TLocation, TLocationAutocomplete, TRenderOptionItem } from './types';

const debounceWait = 600;

// TODO separate value & internal value (the string & object)
const LocationAutocomplete: FC<TLocationAutocomplete> = ({
  variant = 'addresses',
  onChange,
  value: locationValue,
  onInputChange,
  minChars,
  extraOption,
  openOnFocus,
  onClearInput,
  addressesOnly = true,
  ...rest
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { renderOptionText } = useAutocompleteOptionTitle();

  const [options, setOptions] = useState<TLocation[]>([]);
  const [loading, setLoading] = useState(false);
  const [minCharsValid, setMinCharsValid] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const fetchData = useCallback<TFetchData>(
    async (value = '') => {
      if (minChars && value.length < minChars) {
        if (openOnFocus && extraOption) {
          setOptions([extraOption]);
          setLoading(false);
          return;
        }
        return;
      }

      if (value.length === 0) {
        setOptions([]);
        setLoading(false);
        return;
      }

      try {
        const query = {
          q: value,
          addressesOnly,
        };

        const actionResult = await BaseApi.instance({ handleError: false }).get<
          TActionResult<TLocation[]>,
          TActionResult<TLocation[]>
        >(
          variant === 'addresses'
            ? `/location?${stringify(query)}`
            : `/location/predict/all?${stringify(query)}`,
        );

        if (!actionResult.success) {
          // eslint-disable-next-line no-console
          console.error(actionResult);
          return;
        }

        const newOptions: TLocation[] = actionResult.data || [];
        if (extraOption) {
          newOptions.push(extraOption);
        }
        setOptions(newOptions);
      } finally {
        setLoading(false);
      }
    },
    [extraOption, minChars, openOnFocus, addressesOnly, variant],
  );

  const debounceFetch = useMemo(
    () =>
      debounce((value) => {
        fetchData(value);
      }, debounceWait),
    [fetchData],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = useCallback<Required<IAutocompleteProps<TLocation, false>>['onChange']>(
    (_, newValue) => {
      if (newValue) {
        setMinCharsValid(false);
      }
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const handleInputChange = useCallback<Required<IAutocompleteProps>['onInputChange']>(
    (e, inputValue, reason) => {
      if (!openOnFocus || inputValue.length >= (minChars || 1)) {
        setOptions([]);
        setLoading(true);
      }

      setMinCharsValid(inputValue.length >= (minChars || 1));

      if (reason === 'input' || (reason === 'reset' && !e)) {
        debounceFetch(inputValue);
      }

      if (openOnFocus && reason === 'reset' && extraOption) {
        setOptions([extraOption]);
        setLoading(false);
      }

      if (onInputChange) {
        onInputChange(e, inputValue, reason);
      }
    },
    [debounceFetch, extraOption, minChars, onInputChange, openOnFocus],
  );

  const renderOptionItem = useMemo<TRenderOptionItem>(
    () =>
      (
        address,
        cityAndCountry,
        inputValue,
        isSelected,
        icon = 'icon-location-2',
        isTranslate,
        iconColor,
        optionTextWeight,
      ) => {
        return (
          <Box
            className={classNames(classes.rootOption, { [classes.rootOptionSelected]: isSelected })}
          >
            <Icon name={icon} color={iconColor} classes={{ root: classes.icon }} />
            <Box>
              <Typography
                fontSize={16}
                variant="body1"
                isTranslate={isTranslate}
                className={classes.optionText}
                color="textSecondary"
                fontWeight={optionTextWeight}
              >
                {/* if last item disable highlight */}
                {isTranslate ? address : renderOptionText(inputValue, address)}
              </Typography>

              <Typography
                fontSize={13}
                variant="body1"
                isTranslate={isTranslate}
                className={classes.optionText}
                color="mediumGrey"
              >
                {cityAndCountry}
              </Typography>
            </Box>
          </Box>
        );
      },
    [
      classes.icon,
      classes.optionText,
      classes.rootOption,
      classes.rootOptionSelected,
      renderOptionText,
    ],
  );

  const renderOption = useMemo<IAutocompleteProps<TAutocompleteValue, false>['renderOption']>(
    () =>
      ({ address, cityAndCountry, placeId }, { inputValue }) => {
        const selectedItem =
          typeof locationValue !== 'string' && locationValue && locationValue?.placeId;
        const isSelected = selectedItem === placeId;

        const Item = renderOptionItem(address, cityAndCountry, inputValue, isSelected);

        if (placeId === extraOption?.placeId) {
          return (
            <Box width="100%">
              <Box mb={2.125}>
                <Divider />
              </Box>
              {renderOptionItem(
                t(address),
                t(cityAndCountry),
                inputValue,
                isSelected,
                extraOption?.icon || 'icon-location',
                true,
                'primary',
                'medium',
              )}
            </Box>
          );
        }
        return Item;
      },
    [extraOption, locationValue, renderOptionItem, t],
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const renderOptionLabel = useCallback(
    (option: TLocation) => {
      if (option.placeId === extraOption?.placeId) {
        return t(extraOption?.customLabel) || `${t(option.address)}, ${t(option.cityAndCountry)}`;
      }

      if (option.address && option.cityAndCountry) {
        return `${option.address}, ${option.cityAndCountry}`;
      }
      return '';
    },
    [extraOption, t],
  );

  const isOpenAutocomplete = useMemo(() => {
    if (openOnFocus) {
      return isFocused && !locationValue;
    }

    if (minChars) {
      return isFocused && minCharsValid;
    }
  }, [isFocused, locationValue, minChars, minCharsValid, openOnFocus]);

  return (
    <Autocomplete<TLocation, false>
      openAutocomplete={isOpenAutocomplete}
      loading={loading}
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onClearInput={onClearInput}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={locationValue}
      optionValue="placeId"
      renderOption={renderOption}
      getOptionLabel={(option) => renderOptionLabel(option)}
      filterSelectedOptions={false}
      filterOptions={(x) => x}
      {...rest}
    />
  );
};

export default LocationAutocomplete;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, PutEffect, select, SelectEffect, takeEvery } from '@redux-saga/core/effects';
import {
  IOpenDeliverySelfPickupRequest,
  IUpdateOrderDetails,
  TOrderDetailsReducerState,
} from 'store/modules/orderDetails/types';
import { initialOrderDetailsState } from 'store/modules/orderDetails/initialState';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { OPEN_DELIVERY_SELF_PICKUP_REQUEST } from 'store/modules/orderDetails/constants';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import { IOrderDiscountsRecalculate } from 'store/modules/orderDiscounts';
import { orderDiscountsRecalculate } from 'store/modules/orderDiscounts/actions';

function* openDeliverySelfPickup({
  payload,
}: IOpenDeliverySelfPickupRequest): Generator<
  PutEffect<IUpdateOrderDetails> | PutEffect<IOrderDiscountsRecalculate> | SelectEffect,
  void,
  any
> {
  const orderMode = yield select(getOrderMode);

  const newOrderDetails: TOrderDetailsReducerState = {
    ...initialOrderDetailsState,
    orderMode,
    orderType: payload.orderType,
    selfPickupLocation: payload.selfPickupLocation,
  };

  yield put(updateOrderDetails(newOrderDetails));
  yield put(orderDiscountsRecalculate());
}

function* rootOrderDetailsSaga(): Generator {
  yield takeEvery(OPEN_DELIVERY_SELF_PICKUP_REQUEST, openDeliverySelfPickup);
}

export default rootOrderDetailsSaga;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TWithMobileStyles } from 'types';

import { TOrderDetailsReducerState } from 'store';
import { IMainLayout, TStylesGoToCart } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesGoToCart,
    IMainLayout & {
      orderMode: TOrderDetailsReducerState['orderMode'];
      loadingBackgroundImage: boolean;
      showHeaderWithLinks?: boolean;
    } & TWithMobileStyles
  > =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      },
      topBlockWrapper: {
        minWidth: '100%',
        height: ({ orderMode }) => theme.spacing(orderMode === 'new' ? 300 / 8 : 340 / 8),
        position: 'absolute',
        zIndex: 6,
        left: 0,
        top: 0,
        overflow: 'hidden',
      },
      topBlock: {
        position: 'relative',
        zIndex: 1,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.secondary.main,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          width: '100%',
          height: '100%',
          mixBlendMode: 'multiply',
          backgroundImage:
            'linear-gradient(to top, rgba(255, 255, 255, 0.5) 60%, rgba(0, 0, 0, 0.2)), ' +
            'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))',
        },
      },
      mainBlock: {
        marginTop: ({ showBigHeader, isMobile, loadingBackgroundImage }) => {
          if (loadingBackgroundImage) return '301px';
          if (!showBigHeader) return 0;
          if (isMobile) return '244px';

          return '220px';
        },
      },
      accessibilityTooltips: {
        justifyContent: 'space-between',
        width: '704px',
        display: 'flex',
        position: 'absolute',
        zIndex: 8,
        top: '12px',
        left: '-705px',
        transition: 'all 400ms ease',
        '&:focus, &:focus-within': {
          left: '154px',
          '&+ $backgroundForTooltip': {
            display: 'block',
          },
        },
      },
      tooltipLink: {
        textAlign: 'center',
        padding: theme.spacing(12 / 8),
        backgroundColor: theme.palette.primary.main,
        width: '220px',
        height: '46px',
        borderRadius: '6px',
      },
      linkTitle: {
        fontSize: '18px',
        fontWeight: 500,
        color: theme.palette.background.paper,
      },
      backgroundForTooltip: {
        display: 'none',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 7,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    }),
);

import React, { useMemo } from 'react';

import Typography, { ITypography } from 'ui/common/Typography';
import { useTranslation } from 'react-i18next';
import { useMobile } from 'hooks/useMobile';
import { TUseFooterAllRights } from './types';

const useFooterAllRights: TUseFooterAllRights = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  const allRightsSection = useMemo(() => {
    const year = new Date().getFullYear();
    const typographyProps: ITypography = {
      color: isMobile ? 'mediumGrey' : 'light',
      fontSize: isMobile ? 13 : 14,
    };
    return <Typography {...typographyProps}>{t('footer.allRights', { year })}</Typography>;
  }, [isMobile, t]);

  return { allRightsSection };
};

export default useFooterAllRights;

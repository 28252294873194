import React, { FC, useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { find, merge, omit } from 'lodash';

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';

import Typography from 'ui/common/Typography';
import Icon from 'ui/common/icons/Icon';

import useStyles from './styles';

import { IDropDown, TRenderSelectValue } from './types';

const DropDown: FC<IDropDown> = ({
  menuItemWidth,
  addBorderRadius = true,
  size = 'medium',
  isShowStartIcon,
  startIcon = 'icon-knife',
  formControlProps,
  dropDownOptions,
  menuItemProps,
  placeholder,
  helperText,
  error,
  required,
  isShowLabel,
  label,
  outlinedInputStyle,
  selectOverrideClasses,
  ...rest
}) => {
  const classes = useStyles({
    size,
    addBorderRadius,
    isShowStartIcon,
    startIcon,
    formControlProps,
    dropDownOptions,
    menuItemProps,
    placeholder,
    helperText,
    error,
    menuItemWidth,
    ...rest,
  });

  // custom arrow icon
  const renderIcon = useCallback(() => {
    return (
      <Icon
        classes={{
          root: classNames(classes.iconArrow, { [classes.iconArrowSmall]: size === 'small' }),
        }}
        name="icon-arrow-down"
        color={error ? 'error' : 'primary'}
      />
    );
  }, [classes, size, error]);

  // need for render custom placeholder
  const renderSelectValue = useMemo<TRenderSelectValue>(
    () => (selectValue) => {
      const activeValue = find(dropDownOptions, ['value', selectValue]);

      return (
        <>
          {selectValue ? (
            activeValue?.selectedText || activeValue?.text
          ) : (
            <Typography
              className={selectOverrideClasses?.placeholder || classes.placeholder}
              variant="body1"
              color="mediumGrey"
            >
              {placeholder}
            </Typography>
          )}
        </>
      );
    },
    [dropDownOptions, selectOverrideClasses?.placeholder, classes.placeholder, placeholder],
  );

  return (
    <>
      <FormControl error={error} fullWidth {...formControlProps} className={classes.formControl}>
        {isShowLabel && (
          <FormLabel
            classes={{
              root: classes.labelRoot,
              focused: classes.labelFocused,
              error: classes.labelError,
              asterisk: classes.asterisk,
            }}
            component="legend"
            required={required}
          >
            {label}
          </FormLabel>
        )}
        <Select
          classes={{
            root: classes.rootSelect,
          }}
          renderValue={renderSelectValue}
          displayEmpty
          input={
            <OutlinedInput
              classes={{
                root: classes.root,
                notchedOutline: classNames(
                  classes.notchedOutline,
                  selectOverrideClasses?.notchedOutline,
                ),
                focused: classes.focused,
                input: classNames(classes.notchedOutline, {
                  [classes.rootSelectWithIcon]: isShowStartIcon,
                }),
              }}
              style={{ ...outlinedInputStyle }}
            />
          }
          MenuProps={merge(
            {
              transformOrigin: {
                horizontal: 'center',
                vertical: 'top',
              },
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
              },
              getContentAnchorEl: null,
              classes: merge(
                { paper: classes.paper, list: classes.menuList },
                rest.MenuProps?.classes,
              ),
            },
            omit(rest.MenuProps, ['classes']),
          )}
          IconComponent={renderIcon}
          error={error}
          {...omit(rest, ['MenuProps'])}
        >
          {dropDownOptions?.map(({ id, value, text, ariaLabel, divider }) => {
            // divider
            if (divider) return <Box key={id}>{divider}</Box>;

            return (
              <MenuItem
                classes={merge(
                  {
                    root: classes.menuItem,
                    selected: classes.menuItemSelected,
                  },
                  menuItemProps?.classes,
                )}
                key={id}
                value={value}
                {...{
                  'aria-label': (ariaLabel || value || '').toString(),
                  ...omit(menuItemProps, ['classes']),
                }}
                disableRipple
              >
                {text}
              </MenuItem>
            );
          })}
        </Select>
        {/* start icon sould be outside because it broke fullwidth dropdown render */}
        {isShowStartIcon && (
          <Icon
            classes={{ root: classes.startIcon }}
            color={error ? 'error' : 'primary'}
            name={startIcon}
            fontSize="small"
          />
        )}
      </FormControl>
      {/* helper text should be outside because it broke start icon render */}
      {helperText && (
        <FormHelperText error={error} classes={{ root: classes.helperText }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default DropDown;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { IStoreProductDisplay } from 'store';
import { TStylesSlider } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesSlider,
    {
      slideItems: IStoreProductDisplay[];
      slideWidth: number;
      slidesPerView?: number | 'auto';
    }
  > =>
    createStyles({
      sliderInnerWrapper: {
        width: 'calc(100% + 30px)',
        padding: theme.spacing(0, 15 / 8),
        marginLeft: theme.spacing(-15 / 8),
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
          overflow: ({ slidesPerView, slideItems }) =>
            (slidesPerView && (slideItems.length > slidesPerView ? 'hidden' : 'initial')) ||
            'hidden',
          padding: '0px',
          marginLeft: '0px',
        },
      },
      sliderWrapper: ({ slideItems }) => ({
        display: slideItems.length > 1 ? 'flex' : 'block',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .swiper-wrapper': {
          boxSizing: 'border-box',
        },
      }),
      swiper: {
        height: '100%',
        padding: theme.spacing(7 / 8, 0, 42 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(7 / 8, 0, 50 / 8),
        },
      },
      swiperSlide: ({ slideItems, slidesPerView, slideWidth }) => ({
        width: 'auto',
        [theme.breakpoints.up('md')]: {
          width:
            (slidesPerView &&
              (slideItems.length > slidesPerView
                ? `${slideWidth}px !important`
                : 'auto !important')) ||
            'auto !important',
        },
      }),
      iconArrow: {
        fontSize: '18px',
        display: 'block',
      },
      navigationButtonPrev: {
        transform: `rotate(${theme.direction === 'ltr' ? '180deg' : 0})`,
        padding: theme.spacing(10 / 8),
        marginRight: '-46px',
      },
      navigationButtonNext: {
        transform: `rotate(${theme.direction === 'ltr' ? '180deg' : 0})`,
        padding: theme.spacing(10 / 8),
        marginLeft: '-43px',
      },
    }),
);

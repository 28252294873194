import React, { useCallback, useMemo } from 'react';
import { Box, CardMedia, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';

import Typography, { ITypography } from 'ui/common/Typography';
import { MAX_CONTAINER_WIDTH } from 'ui/common/Container';

import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import useStyles from './styles';

import {
  TOnImageError,
  TRenderDescription,
  TRenderIcon,
  TRenderLabel,
  TRenderSubTitle,
  TUseFooterAboutSection,
} from './types';

const useFooterAboutSection: TUseFooterAboutSection = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(MAX_CONTAINER_WIDTH));

  const websiteDetails = useWebsiteDetails();

  const onImageError = useCallback<TOnImageError>((e) => {
    (e.target as HTMLImageElement).src = '/assets/images/store-logo-on-error.svg';
  }, []);

  const renderIcon = useMemo<TRenderIcon>(
    () =>
      (url = '', wrapperClasses) => {
        return (
          <Box className={classes.logoWrapper} {...wrapperClasses}>
            <CardMedia
              classes={{ root: classNames(classes.logo, { [classes.mobileLogo]: isMobile }) }}
              component="img"
              image={url}
              alt={websiteDetails.name}
              onError={onImageError}
            />
          </Box>
        );
      },
    [
      classes.logo,
      classes.logoWrapper,
      classes.mobileLogo,
      isMobile,
      onImageError,
      websiteDetails.name,
    ],
  );

  const renderLabel = useMemo<TRenderLabel>(
    () => (label, options) => {
      const typographyProps: ITypography = {
        color: 'light',
        fontWeight: 'bold',
        isTranslate: false,
        ...options,
      };

      return (
        <Typography variant="body2" className={classes.label} {...typographyProps}>
          {label}
        </Typography>
      );
    },
    [classes],
  );

  const renderSubTitle = useMemo<TRenderSubTitle>(
    () => (subtitle, options) => {
      const typographyProps: ITypography = {
        color: 'light',
        align: 'center',
        fontWeight: 'regular',
        isTranslate: false,
        ...options,
      };
      return <Typography {...typographyProps}>{subtitle}</Typography>;
    },
    [],
  );

  const renderDescription = useMemo<TRenderDescription>(
    () =>
      (description = '', options) => {
        const typographyProps: ITypography = {
          color: 'light',
          fontSize: 14,
          align: 'left',
          isTranslate: false,
          ...options,
        };
        return (
          <Box>
            <Typography
              className={classes.description}
              {...typographyProps}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Box
              display="flex"
              justifyContent={isMobile ? 'center' : 'inherit'}
              mt={isMobile ? 1 : 6 / 8}
            >
              {websiteDetails.store.businessNumber && (
                <>
                  <Typography className={classes.websiteDetails} color="light" align="left">
                    &quot;{websiteDetails.store.businessName}&quot; ,
                  </Typography>
                  <Typography className={classes.websiteDetails} color="light" align="left">
                    {'footer.companyType'}
                  </Typography>
                  <Typography className={classes.websiteDetails} color="light" align="left">
                    &nbsp; {websiteDetails.store.businessNumber}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        );
      },
    [
      classes.description,
      classes.websiteDetails,
      isMobile,
      websiteDetails.store.businessName,
      websiteDetails.store.businessNumber,
    ],
  );

  return { renderIcon, renderLabel, renderDescription, renderSubTitle };
};

export default useFooterAboutSection;

import React, { FC } from 'react';

import { merge } from 'lodash';

import { Container as MUIContainer } from '@material-ui/core';

import { useMobile } from 'hooks';

import useStyles from './styles';
import { IContainer } from './types';

const Container: FC<IContainer> = ({
  maxContainerWidth = false,
  maxWidth = false,
  fixed,
  classes: overrideClasses,
  ...rest
}) => {
  const { children } = rest;

  const { isMobile } = useMobile();

  const classes = useStyles({
    isMobile,
    maxContainerWidth,
    ...rest,
  });

  return (
    <MUIContainer
      classes={merge(
        {
          root: classes.root,
        },
        overrideClasses,
      )}
      disableGutters
      maxWidth={maxWidth}
      {...rest}
    >
      {children}
    </MUIContainer>
  );
};

export default Container;

import { Box, Divider } from '@material-ui/core';
import { storeContactItems } from 'components/desktop/AboutPage/components/LeaveMessageSection/constants';
import { useContentPages } from 'hooks/useContentPages';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import Container from 'ui/common/Container';
import Icon from 'ui/common/icons/Icon';
import Link from 'ui/common/Link';
import Typography from 'ui/common/Typography';

import useStyles from './styles';

const LinksHeader: FC = () => {
  const classes = useStyles();

  const websiteDetails = useSelector(getWebsiteDetails);
  const { createContentPagesList } = useContentPages();
  const contentPages = createContentPagesList(false, true);

  const contactItems = useMemo(() => {
    return storeContactItems(websiteDetails).filter(
      ({ iconName }) => !iconName.includes('phone') && !iconName.includes('mail'),
    );
  }, [websiteDetails]);

  return (
    <Box className={classes.wrapper}>
      <Container maxContainerWidth>
        <Box display="flex" justifyContent="space-between" style={{ opacity: 0.8 }}>
          <Box display="flex">
            {contentPages.map((contentPage, i) => {
              return (
                <Link
                  className={classes.contactItem}
                  key={contentPage.id}
                  href={`/${contentPage.type}${
                    contentPage.id
                      ? contentPage.nonObfuscatedId && `/${contentPage.nonObfuscatedId}`
                      : ''
                  }`}
                >
                  <Typography
                    fontSize={14}
                    fontWeight="medium"
                    color="light"
                    className={classes.contactItemLable}
                  >
                    {contentPage.name}
                  </Typography>
                  {contentPages.length !== i + 1 && (
                    <Divider className={classes.dividerForContentPage} />
                  )}
                </Link>
              );
            })}
          </Box>
          <Box display="flex" alignItems="center">
            {contactItems
              ?.filter((contactItem) => contactItem.component !== 'address')
              ?.map(({ iconName, link, id, component }) => {
                return (
                  <Box
                    component={component || 'div'}
                    fontStyle="inherit"
                    key={id}
                    display="flex"
                    alignItems="center"
                  >
                    <Link targetBlank {...link} style={{ height: '14px', display: 'flex' }}>
                      <Icon
                        name={iconName}
                        classes={{ root: classes.socialIcon }}
                        color="action"
                        aria-hidden={false}
                      />
                    </Link>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LinksHeader;

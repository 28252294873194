import { Box, CardMedia } from '@material-ui/core';
import { useDialog, useMobile, useWebsiteDetails } from 'hooks';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useMemo } from 'react';

import CTAButton from 'ui/common/buttons/CTAButton';
import Typography from 'ui/common/Typography';
import useStyles from './styles';

const OrderEditingIsNotPossibleDialog: FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const { hideDialog } = useDialog();
  const websiteDetails = useWebsiteDetails();

  const { isMobile } = useMobile();

  const contactUsPage = useMemo(() => {
    return websiteDetails.contentPages.find((contentPage) => contentPage.type === 'about');
  }, [websiteDetails.contentPages]);

  const contactStore = useCallback(() => {
    if (!isMobile && contactUsPage) {
      router.push(`/about/${contactUsPage?.nonObfuscatedId}#contact`);
    } else {
      window.open(`tel:${websiteDetails.businessPhone}`);
    }
    hideDialog();
  }, [contactUsPage, hideDialog, isMobile, router, websiteDetails.businessPhone]);

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={websiteDetails.theme.images.warning}
          component="img"
          className={classes.image}
          alt="Warning"
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h1" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.orderEditingIsNotPossible.title'}
        </Typography>
        <Box mb={isMobile ? 10 / 8 : 12 / 8} mt={10 / 8}>
          <Typography variant="body1" fontSize={16} color="grey" align="center">
            {'dialog.orderEditingIsNotPossible.body'}
          </Typography>
        </Box>
      </Box>
      <Box>
        <CTAButton className={classes.rootButton} onClick={contactStore} variant="contained">
          <Typography fontWeight="medium" fontSize={isMobile ? 16 : 18}>
            {'dialog.orderEditingIsNotPossible.yesButton'}
          </Typography>
        </CTAButton>
        <CTAButton className={classes.rootButton} variant="outlined" onClick={hideDialog}>
          {'dialog.orderEditingIsNotPossible.noButton'}
        </CTAButton>
      </Box>
    </Box>
  );
};

export default OrderEditingIsNotPossibleDialog;

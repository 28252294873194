import { GET_WEBSITE_DETAILS_REQUEST, GET_WEBSITE_DETAILS_SUCCESS } from './constants';

import { TGetWebsiteDetailsRequestAction, TGetWebsiteSuccessAction } from './types';

const getWebsiteDetailsRequest: TGetWebsiteDetailsRequestAction = (newWebsiteDetails) => ({
  type: GET_WEBSITE_DETAILS_REQUEST,
  payload: {
    newWebsiteDetails,
  },
});

const getWebsiteSuccess: TGetWebsiteSuccessAction = (newStoreState) => ({
  type: GET_WEBSITE_DETAILS_SUCCESS,
  payload: newStoreState,
});

export { getWebsiteDetailsRequest, getWebsiteSuccess };

import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesContactStoreForm } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesContactStoreForm> =>
    createStyles({
      submitButton: {
        width: '100%',
        height: theme.spacing(5.75), // 46px
        fontSize: theme.spacing(18 / 8),
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(303 / 8),
        },
      },
    }),
);

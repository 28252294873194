import { Box, Button, IconButton } from '@material-ui/core';

import { AuthService, TAuthenticateBody } from 'api';
import Avatar from 'components/common/Avatar';

import { useActiveOrdersAndSubscriptionsChecks, useDialog, useWebsiteDetails } from 'hooks';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { useDispatch, useSelector } from 'react-redux';

import {
  authActions,
  authSelectors,
  DELIVERY_DIALOG,
  EMAIL_VERIFICATION_DIALOG,
  initialOrderDetailsState,
  LOGIN_CODE_DIALOG,
  LOGIN_DIALOG,
  orderDetailsActions,
  SELF_PICKUP_DIALOG,
  shoppingFlowActions,
  shoppingFlowSelectors,
  SIGN_UP_DIALOG,
  userActions,
} from 'store';
import { userLogin } from 'store/modules/analytics/actions';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { fetchAndUpdateClientCompensationsRequest } from 'store/modules/user/actions';
import { TServerAvailableAuthenticationMethod } from 'types';
import { TAuthMethodType } from 'types/dataTypes';
import CTAButton from 'ui/common/buttons/CTAButton';

import Icon from 'ui/common/icons/Icon';
import CodeInput, { TCodeInputOnChange, TCodeInputOnComplete } from 'ui/common/inputs/CodeInput';

import Typography from 'ui/common/Typography';
import useStyles from './styles';

import { ILoginCodeDialog, TShowPreferredDialog } from './types';

const LoginCodeDialog: FC<ILoginCodeDialog> = ({
  cellPhone,
  initialAuthenticationMethodType = 'SMS',
  displayCodeCounter = false,
  showAlternativeMethod = false,
  customMethodOfAuthToDisplay = cellPhone,
  onLoginSuccess,
}) => {
  const dispatch = useDispatch();

  const websiteDetails = useWebsiteDetails();

  const deviceId = useSelector(authSelectors.getDeviceId);

  const orderMode = useSelector(getOrderMode);

  const knownAddress = useSelector(shoppingFlowSelectors.getKnownAddress);

  const orderDetails = useSelector(getOrderDetails);

  const { showDialog, hideDialog } = useDialog();

  const [authenticationMethodType, setAuthenticationMethodType] = useState<TAuthMethodType>(
    initialAuthenticationMethodType,
  );

  const { checkActiveOrdersAndSubscriptions } = useActiveOrdersAndSubscriptionsChecks();

  const [loading, setLoading] = useState<boolean>(false);

  const [authToken, setAuthToken] = useState<string>('');

  const [error, setError] = useState<string>('');

  const [showAlternativeLoginMethod, setShowAlternativeLoginMethod] =
    useState<boolean>(showAlternativeMethod);
  const [displayResendCodeCounter, setDisplayResendCodeCounter] =
    useState<boolean>(displayCodeCounter);

  const [methodOfAuthToDisplay, setMethodOfAuthToDisplay] = useState<string>(
    customMethodOfAuthToDisplay,
  );

  const [availableAuthenticationMethod, setAvailableAuthenticationMethod] =
    useState<TServerAvailableAuthenticationMethod | null>(null);

  const title = useMemo<string>(() => {
    switch (authenticationMethodType) {
      case 'SMS':
      case 'VOICE':
      default:
        return 'dialog.loginCode.titleSendViaTel';
      case 'EMAIL':
        return 'dialog.loginCode.titleSendViaEmail';
    }
  }, [authenticationMethodType]);

  const subTitle = useMemo<string>(() => {
    switch (authenticationMethodType) {
      case 'SMS':
      case 'VOICE':
      default:
        return 'dialog.loginCode.subTitleTel';
      case 'EMAIL':
        return 'dialog.loginCode.subTitleEmail';
    }
  }, [authenticationMethodType]);

  const editAuthMethod = useMemo<boolean>(() => {
    return authenticationMethodType !== 'EMAIL';
  }, [authenticationMethodType]);

  const classes = useStyles({
    numberOfAuthMethod:
      (availableAuthenticationMethod && availableAuthenticationMethod.methods.length) || 0,
    newEmailAllowed:
      (availableAuthenticationMethod && availableAuthenticationMethod.newEmailAllowed) || false,
    email: (availableAuthenticationMethod && !!availableAuthenticationMethod.email) || false,
  });

  const handleChange = useCallback<TCodeInputOnChange>(
    (currentInputValue) => {
      // reset error
      if (error) {
        setError('');
      }

      setAuthToken(currentInputValue);
    },
    [error],
  );

  const showPreferredOrderTypeDialog = useCallback<TShowPreferredDialog>(
    (deliveryDialogContentProps) => {
      let dialogToDisplay = websiteDetails.websiteSettings.defaultOrderType;
      const { onlySelfPickups } = websiteDetails.websiteSettings;

      if (onlySelfPickups) {
        showDialog({
          dialogType: SELF_PICKUP_DIALOG,
        });
        return;
      }

      if (knownAddress) {
        dialogToDisplay = 'delivery';
      }

      if (orderDetails.selfPickupLocation && orderDetails.selfPickupLocation.name) {
        dialogToDisplay = 'selfPickup';
      }

      if (dialogToDisplay === 'delivery') {
        showDialog({
          dialogType: DELIVERY_DIALOG,
          ...deliveryDialogContentProps,
        });
        return;
      }

      showDialog({
        dialogType: SELF_PICKUP_DIALOG,
      });
    },
    [knownAddress, orderDetails.selfPickupLocation, showDialog, websiteDetails.websiteSettings],
  );

  const handleSubmit = useCallback<TCodeInputOnComplete>(
    async (currentAuthToken) => {
      try {
        setLoading(true);
        const body: TAuthenticateBody = {
          authToken: currentAuthToken,
          cellPhone,
          deviceId,
        };
        const actionResult = await AuthService.authenticate(body);

        if (!actionResult.success) {
          setError(actionResult.resolvedMessage);
          return;
        }

        // update token
        dispatch(authActions.jweTokenUpdateAction(actionResult.data.second));

        // TODO - remove this and aviad will add client to client stores automatically
        await AuthService.addStoreToClientStores(websiteDetails.store.id);

        // update profile
        dispatch(userActions.updateProfile(actionResult.data.first));

        const {
          address,
          cellPhone: phoneNumber,
          firstName,
          lastName,
          email,
        } = actionResult.data.first;

        const isMinimumProfileDataCompleted = !!phoneNumber && !!firstName && !!lastName && !!email;

        const isProfileCompleted =
          !!address.address && !!address.cityAndCountry && isMinimumProfileDataCompleted;

        if (!isMinimumProfileDataCompleted) {
          showDialog({
            dialogType: SIGN_UP_DIALOG,
            dialogProps: {
              onClose: () => {
                if (!isMinimumProfileDataCompleted) {
                  dispatch(authActions.logOutRequest());
                }
              },
            },
          });
          return;
        }

        dispatch(userLogin({ firstName, lastName, email, address, cellPhone: phoneNumber }));

        if (onLoginSuccess) {
          onLoginSuccess();
        }

        if (!isProfileCompleted) {
          dispatch(shoppingFlowActions.updateKnownAddressAndSubCatalog(null, null));
        }

        dispatch(fetchAndUpdateClientCompensationsRequest());

        if (websiteDetails.websiteSettings.isSmallEC) {
          hideDialog();
          return;
        }

        if (orderMode === 'new') {
          if (
            websiteDetails.websiteSettings.onlySelfPickups &&
            (!orderDetails.selfPickupLocation || !orderDetails.selfPickupLocation.name)
          ) {
            // because default order type of EC is delivery we need to change it to selfPickup once user is login
            // and if he didn't choose any pickup point inside "LocationDialog"
            dispatch(
              orderDetailsActions.updateOrderDetails({
                ...initialOrderDetailsState,
                orderType: websiteDetails.websiteSettings.defaultOrderType,
              }),
            );
          }

          // show delivery preferred dialog if no active orders or subscription
          checkActiveOrdersAndSubscriptions(() =>
            showPreferredOrderTypeDialog(
              isProfileCompleted
                ? null
                : {
                    contentProps: {
                      initialView: 'createNewDeliveryAddress',
                    },
                  },
            ),
          );
          return;
        }
        showPreferredOrderTypeDialog(
          isProfileCompleted
            ? null
            : {
                contentProps: {
                  initialView: 'createNewDeliveryAddress',
                },
              },
        );
      } finally {
        setLoading(false);
      }
    },
    [
      cellPhone,
      deviceId,
      dispatch,
      websiteDetails.store.id,
      websiteDetails.websiteSettings.isSmallEC,
      websiteDetails.websiteSettings.onlySelfPickups,
      websiteDetails.websiteSettings.defaultOrderType,
      onLoginSuccess,
      orderMode,
      showPreferredOrderTypeDialog,
      showDialog,
      hideDialog,
      orderDetails.selfPickupLocation,
      checkActiveOrdersAndSubscriptions,
    ],
  );

  const handleKeyPress = useCallback(
    (e) => {
      // it triggers by pressing the enter key
      if ((authToken.length === 6 || !!error) && e.charCode === 13) {
        handleSubmit(authToken);
      }
    },
    [authToken, error, handleSubmit],
  );

  const handleLogin = useCallback(() => {
    showDialog({
      dialogType: LOGIN_DIALOG,
    });
  }, [showDialog]);

  const sendAuthenticationCode = useCallback(
    async (selectedAuthenticationMethod: TAuthMethodType) => {
      if (displayResendCodeCounter) {
        return;
      }

      setDisplayResendCodeCounter(true);
      setAuthenticationMethodType(selectedAuthenticationMethod);

      if (selectedAuthenticationMethod === 'EMAIL') {
        if (availableAuthenticationMethod && availableAuthenticationMethod.email) {
          setMethodOfAuthToDisplay(availableAuthenticationMethod.email);
        } else {
          showDialog({
            dialogType: EMAIL_VERIFICATION_DIALOG,
            contentProps: { cellPhone },
            dialogProps: {
              onClose: () =>
                showDialog({ dialogType: LOGIN_CODE_DIALOG, contentProps: { cellPhone } }),
            },
          });
          return;
        }
      } else {
        setMethodOfAuthToDisplay(cellPhone);
      }

      const actionResult = await AuthService.applyForAuthentication({
        cellPhone,
        selectedAuthenticationMethod,
      });

      if (!actionResult.success) {
        setError(actionResult.resolvedMessage);
      }
    },
    [availableAuthenticationMethod, cellPhone, displayResendCodeCounter, showDialog],
  );

  const alternativeAuthenticationMethods = useMemo(() => {
    if (!availableAuthenticationMethod) {
      return;
    }

    return (
      <Box position="relative" textAlign="center">
        <Typography fontSize={14} color="grey">
          {'dialog.loginCode.selectMethodToReceivedCode'}
        </Typography>
        <Box className={classes[displayResendCodeCounter ? 'opacityBlock' : 'rootBlock']}>
          {availableAuthenticationMethod.methods.indexOf('SMS') > -1 && (
            <Box position="relative">
              <IconButton
                classes={{
                  root: classes.btnRoot,
                  label: classes.labelButton,
                }}
                onClick={() => sendAuthenticationCode('SMS')}
              >
                <Icon color="primary" name="icon-message" fontSize="small" />
                <Typography className={classes.descriptionOfAuthMethod}>
                  {'dialog.loginCode.smsMessage'}
                </Typography>
              </IconButton>
            </Box>
          )}
          {availableAuthenticationMethod.methods.indexOf('VOICE') > -1 && (
            <>
              <Box className={classes.verticalDivider} />
              <Box position="relative">
                <IconButton
                  classes={{
                    root: classes.btnRoot,
                    label: classes.labelButton,
                  }}
                  onClick={() => sendAuthenticationCode('VOICE')}
                >
                  <Icon color="primary" name="icon-phone" fontSize="small" />
                  <Typography className={classes.descriptionOfAuthMethod}>
                    {'dialog.loginCode.phoneCall'}
                  </Typography>
                </IconButton>
              </Box>
            </>
          )}
          {((availableAuthenticationMethod.methods.indexOf('EMAIL') > -1 &&
            availableAuthenticationMethod.email) ||
            availableAuthenticationMethod.newEmailAllowed) && (
            <>
              <Box className={classes.verticalDivider} />
              <Box position="relative">
                <IconButton
                  classes={{
                    root: classes.btnRoot,
                    label: classes.labelButton,
                  }}
                  onClick={() => sendAuthenticationCode('EMAIL')}
                >
                  <Icon color="primary" name="icon-mail" fontSize="small" />
                  <Typography className={classes.descriptionOfAuthMethod}>
                    {'dialog.loginCode.email'}
                  </Typography>
                </IconButton>
              </Box>
            </>
          )}
        </Box>
        {displayResendCodeCounter && (
          <Box className={classes.counterWrapper}>
            <CountdownCircleTimer
              isPlaying
              size={34}
              strokeWidth={2}
              duration={60}
              trailColor="#e9eded"
              colors="#666666"
              onComplete={() => setDisplayResendCodeCounter(false)}
            >
              {({ remainingTime }) => (
                <Typography color="grey" fontSize={14}>
                  {remainingTime}
                </Typography>
              )}
            </CountdownCircleTimer>
          </Box>
        )}
      </Box>
    );
  }, [availableAuthenticationMethod, classes, displayResendCodeCounter, sendAuthenticationCode]);

  const getAvailableAuthMethod = useCallback(() => {
    AuthService.getAvailableMethods(cellPhone, { setLoading }).then((actionResult) => {
      if (!actionResult.success) {
        setError(actionResult.resolvedMessage);
        return;
      }

      setShowAlternativeLoginMethod(true);
      setAvailableAuthenticationMethod(actionResult.data);
    });
  }, [cellPhone]);

  return (
    <Box className={classes.root}>
      <Avatar
        src={websiteDetails.logoPath}
        variant="circle"
        classes={{
          root: classes.logo,
          img: classes.logo,
        }}
      />
      <Box className={classes.title}>
        <Typography variant="h2" color="secondary" align="center" className={classes.titleMain}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          fontSize={16}
          color="grey"
          align="center"
          className={classes.subTitle}
        >
          {subTitle}
        </Typography>
        <Box display="flex" justifyContent="center" mt={5 / 8}>
          <Typography
            variant="body1"
            fontSize={16}
            color="grey"
            align="center"
            fontWeight="medium"
            className={classes.cellPhone}
          >
            {methodOfAuthToDisplay}
          </Typography>
          {editAuthMethod && (
            <IconButton aria-label="close" size="small" onClick={handleLogin}>
              <Icon name="icon-edit" color="primary" fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box className={classes.codeInput}>
        <CodeInput
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          onComplete={handleSubmit}
          helperText={error}
          error={!!error}
        />
      </Box>
      <Box display="flex" flexDirection="column-reverse">
        <Box className={classes.footer}>
          {showAlternativeLoginMethod ? (
            <Box>{alternativeAuthenticationMethods}</Box>
          ) : (
            <Box display="flex" justifyContent="center">
              <Typography variant="body1" color="grey" fontSize={14} align="center">
                {'dialog.loginCode.dontReceivedCode'}
              </Typography>
              <Box>&nbsp;</Box>
              <Button
                className={classes.linkBtn}
                onClick={getAvailableAuthMethod}
                disableTouchRipple
              >
                <Typography
                  variant="body1"
                  color="primary"
                  fontSize={14}
                  align="center"
                  fontWeight="medium"
                  className={classes.linkText}
                >
                  {'dialog.loginCode.sendAgain'}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <Box className={classes.rootButton}>
          <CTAButton
            fullWidth
            disabled={authToken.length < 6 || !!error}
            onClick={() => handleSubmit(authToken)}
            loading={loading}
          >
            {'userMenu.loginText'}
          </CTAButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginCodeDialog;

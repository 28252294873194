import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, CardMedia, ListItem, Menu, MenuItem } from '@material-ui/core';

import classNames from 'classnames';
import i18next from 'i18next';
import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import { configActions } from 'store';
import { useDispatch } from 'react-redux';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import useStyles from './styles';
import { Languages } from './constants';
import { ILanguageSwitcher } from './types';

const LanguageSwitcher: FC<ILanguageSwitcher> = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(Languages[0]); // he
  const { relevantLanguages } = useMultiLanguage();

  const handleLanguageMenu = useCallback(
    (event) => {
      setOpenMenu(!openMenu);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl, openMenu],
  );

  const languageWasChanged = useCallback(
    (selectedLanguage) => {
      setCurrentLanguage(selectedLanguage);
      setOpenMenu(false);
      setAnchorEl(null);

      dispatch(configActions.updateLanguageConfigAction(selectedLanguage.shortName));
    },
    [dispatch],
  );

  const currentLanguageSection = useMemo(() => {
    return (
      <Box display="flex">
        <CardMedia
          image={currentLanguage.imageUrl}
          component="img"
          className={classes.selectedLanguageImage}
          alt={currentLanguage.title}
        />
        <Box className={classes.dropDownRoot}>
          <Icon
            name="icon-arrow-down"
            classes={{
              root: classNames(classes.dropDownIcon),
            }}
          />
        </Box>
      </Box>
    );
  }, [classes.dropDownIcon, classes.dropDownRoot, classes.selectedLanguageImage, currentLanguage]);

  useEffect(() => {
    setCurrentLanguage(
      relevantLanguages.filter((language) => language.shortName === i18next.language)[0] ||
        relevantLanguages[0],
    );
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [i18next.language]);

  return (
    <Box>
      <Button
        className={classes.languageSwitcherButton}
        onClick={(event) => handleLanguageMenu(event)}
        TouchRippleProps={{ classes: { child: classes.buttonRipple } }}
      >
        <ListItem
          button
          component="div"
          classes={{
            root: classes.selectedLanguageItem,
          }}
          disableRipple
        >
          {currentLanguageSection}
        </ListItem>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleLanguageMenu}
        transformOrigin={{
          vertical: -61,
          horizontal: 45,
        }}
        classes={{
          paper: classes.paper,
          list: classes.menuList,
        }}
      >
        {relevantLanguages.map((lang) => {
          return (
            <MenuItem
              key={lang.shortName}
              classes={{
                root: classes.langMenuItem,
              }}
              disableRipple
              onClick={() => languageWasChanged(lang)}
            >
              <Box display="flex">
                <CardMedia
                  image={lang.imageUrl}
                  component="img"
                  className={classes.langImageMenu}
                  alt={lang.title}
                />
                <Typography
                  className={classNames(classes.langTitleMenu, {
                    [classes.langTitleMenuSelected]: currentLanguage.shortName === lang.shortName,
                  })}
                >
                  {lang.title}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
export default LanguageSwitcher;

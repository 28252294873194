import {
  CHECK_AND_FETCH_BRANCH_CATALOG_IF_NECESSARY,
  CORRECT_BRANCH_SUB_CATALOG_WAS_FETCHED,
  FETCH_CATALOG_VIA_SUB_CATALOG_ID,
  SET_CATALOG_ID,
  SET_CATALOG_LAST_FETCH_TIME,
  SET_CATALOG_SSR_LAST_UPDATE_TIME,
  UPDATE_ITEMS_ACCORDING_TO_CURRENTLY_DISPLAYED_SUB_CATALOG,
} from 'store/modules/catalog/constants';
import {
  TCheckAndFetchBranchCatalogIfNecessary,
  TFetchCatalogBySubCatalogId,
  TSetCatalogId,
  TSetCatalogLastFetchTime,
  TSetCatalogSSRLastUpdateTimeRequest,
  TSetCorrectBranchSubCatalogWasFetched,
  TUpdateItemsAccordingToCurrentlyDisplayedSubCatalog,
} from './types';

const setCatalogSSRLastUpdateTimeRequest: TSetCatalogSSRLastUpdateTimeRequest = (
  ssrLastUpdateTime,
) => ({
  type: SET_CATALOG_SSR_LAST_UPDATE_TIME,
  payload: { ssrLastUpdateTime },
});

const setCatalogLastFetchTime: TSetCatalogLastFetchTime = (lastFetchTime) => ({
  type: SET_CATALOG_LAST_FETCH_TIME,
  payload: { lastFetchTime },
});

const setCatalogId: TSetCatalogId = (catalogId) => ({
  type: SET_CATALOG_ID,
  payload: { catalogId },
});

const checkAndFetchBranchCatalogIfNecessary: TCheckAndFetchBranchCatalogIfNecessary = (
  successCallback,
) => ({
  type: CHECK_AND_FETCH_BRANCH_CATALOG_IF_NECESSARY,
  payload: {
    successCallback,
  },
});

const setCorrectBranchSubCatalogWasFetched: TSetCorrectBranchSubCatalogWasFetched = (
  correctBranchSubCatalogWasFetched,
) => ({
  type: CORRECT_BRANCH_SUB_CATALOG_WAS_FETCHED,
  payload: {
    correctBranchSubCatalogWasFetched,
  },
});

const fetchCatalogBySubCatalogId: TFetchCatalogBySubCatalogId = (
  requestedCatalog,
  catalogRequestParams,
  callback,
) => ({
  type: FETCH_CATALOG_VIA_SUB_CATALOG_ID,
  payload: { catalogId: requestedCatalog, catalogRequestParams, callback },
});

const updateItemsAccordingToCurrentlyDisplayedSubCatalog: TUpdateItemsAccordingToCurrentlyDisplayedSubCatalog =
  () => ({
    type: UPDATE_ITEMS_ACCORDING_TO_CURRENTLY_DISPLAYED_SUB_CATALOG,
  });

export {
  setCatalogSSRLastUpdateTimeRequest,
  setCatalogId,
  checkAndFetchBranchCatalogIfNecessary,
  fetchCatalogBySubCatalogId,
  updateItemsAccordingToCurrentlyDisplayedSubCatalog,
  setCorrectBranchSubCatalogWasFetched,
  setCatalogLastFetchTime,
};

import {
  THandleContinueToExternalPaymentFormClick,
  TUseExternalPaymentForm,
} from 'hooks/useExternalPaymentForm/types';
import { useCallback } from 'react';
import { PaymentMethodService } from 'api';
import { useRouter } from 'next/router';

const useExternalPaymentForm: TUseExternalPaymentForm = (cartEstimation = 0) => {
  const router = useRouter();

  const handleContinueToExternalPaymentFormClick =
    useCallback<THandleContinueToExternalPaymentFormClick>(
      (config) => {
        PaymentMethodService.initiateExternalGatewayPaymentPage(cartEstimation, config).then(
          (actionResult) => {
            router.push(actionResult.data);
          },
        );
      },
      [cartEstimation, router],
    );

  return {
    handleContinueToExternalPaymentFormClick,
  };
};

export default useExternalPaymentForm;

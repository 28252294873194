import React, { useMemo } from 'react';

import Link from 'ui/common/Link';
import Typography, { ITypography } from 'ui/common/Typography';

import BackIcon from 'components/common/icons/BackIcon';
import useStyles from './styles';

import { TRenderBackButton, TUseHeaderBackButton } from './types';

const useHeaderbackButton: TUseHeaderBackButton = (linkProps, label = 'header.backButton') => {
  const classes = useStyles();

  const renderBackButton = useMemo<TRenderBackButton>(
    () => (options) => {
      const typographyProps: ITypography = {
        align: 'center',
        variant: 'body2',
        fontWeight: 'medium',
        fontSize: 16,
        color: 'light',
        ...options,
      };

      return (
        <Link className={classes.backButton} {...linkProps}>
          <BackIcon color="action" classes={{ root: classes.backIcon }} />
          <Typography {...typographyProps} className="font-family-rubik">
            {label}
          </Typography>
        </Link>
      );
    },
    [classes, label, linkProps],
  );
  return { renderBackButton };
};

export default useHeaderbackButton;

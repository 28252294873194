import React, { FC, useCallback, useState } from 'react';

import classNames from 'classnames';

import { ButtonProps, ClickAwayListener, IconButton, Popper as MUIPopper } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';

import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import useStyles from './styles';

import { IPopper } from './types';

const Popper: FC<IPopper> = ({
  children,
  iconName = 'icon-store',
  iconColor = 'action',
  iconButtonProps,
  popperProps,
  isOpen,
  onClick,
  overrideClasses,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);

  const lang = useSelector(getLang);

  const classes = useStyles({ lang });

  const handleClick = useCallback<Required<ButtonProps>['onClick']>(
    (e) => {
      if (onClick) onClick();
      setAnchorEl(e.currentTarget);
    },
    [onClick],
  );

  const handleArrowRef = useCallback((node) => {
    setArrowRef(node);
  }, []);

  const clickAwayHandler = useCallback(
    (e) => {
      setAnchorEl(null);
      if (onClick) onClick();
      e.preventDefault();
    },
    [onClick],
  );
  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        classes={{ root: classes.buttonRoot }}
        disableRipple
        {...iconButtonProps}
      >
        <Icon
          color={iconColor}
          name={iconName}
          classes={{
            root: classNames(classes.iconBtn, { [classes.selected]: isOpen }),
          }}
        />
      </IconButton>
      {isOpen && (
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <MUIPopper
            anchorEl={anchorEl}
            open={isOpen}
            placement="bottom-end"
            disablePortal
            modifiers={{
              arrow: {
                enabled: true,
                element: arrowRef,
              },
              offset: {
                enabled: true,
                offset: '0, 12',
              },
            }}
            className={classNames(classes.popper, overrideClasses && overrideClasses.popper)}
            {...popperProps}
          >
            {/* Use span to avoid Box TS Error */}
            <span className={classes.arrow} ref={handleArrowRef} />
            {children}
          </MUIPopper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Popper;

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesPaymentFormCheckoutFooter } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPaymentFormCheckoutFooter> =>
    createStyles({
      submitButtonSection: {
        textAlign: 'end',
      },
      dividerRoot: {
        background: theme.palette.grey.A100,
      },
    }),
);

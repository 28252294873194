import React, { FC, useCallback, useEffect, useState } from 'react';

import { find } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { Box, Divider } from '@material-ui/core';
import Typography from 'ui/common/Typography';
import DropDown, { IDropDown, TDropDownOption } from 'ui/common/inputs/DropDown';
import { TNameAndResolvedName } from 'types';
import { SubscriptionService } from 'api/services/SubscriptionService';
import { Skeleton } from '@material-ui/lab';
import { isSubscriptionMode } from 'utils/helpers/subscriptions';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { useMobile } from 'hooks';
import useStyles from 'components/common/availableService/styles';
import { IDeliveryAvailableService } from './types';
import { AvailableHours, TOnSubmitAvailableHours } from '../components';

const DeliveryAvailableService: FC<IDeliveryAvailableService> = ({
  availableAreas,
  onSubmit,
  isSubmitting,
  isDialog = true,
  availableHoursGridSize,
}) => {
  const classes = useStyles({ isDialog });
  const dispatch = useDispatch();
  const { isMobile } = useMobile();

  const orderMode = useSelector(getOrderMode);
  const orderDetails = useSelector(getOrderDetails);

  const [loadingFrequencies, setLoadingFrequencies] = useState<boolean>(true);
  const [frequency, setFrequency] = useState<TNameAndResolvedName['name'] | null>(null);
  const [frequencies, setFrequencies] = useState<TDropDownOption[]>([]);

  const handleChangeFrequency = useCallback<Required<IDropDown>['onChange']>(
    ({ target: { value } }) => {
      setFrequency(value as TNameAndResolvedName['name']);

      if (!isMobile && isDialog) {
        dispatch(
          updateOrderDetails({
            ...orderDetails,
            orderTime: undefined,
            preferredDay: undefined,
            preferredHour: undefined,
          }),
        );
      }
    },
    [isMobile, isDialog, dispatch, orderDetails],
  );

  const handleSubmit = useCallback<TOnSubmitAvailableHours>(
    (timeFrame) => {
      const area = find(availableAreas, ['date', timeFrame.date]);
      const date = find(area?.availableHours, ['openHour', timeFrame.openHour]);
      const options = {
        duration: date?.duration,
        frequency:
          frequencies
            .map((f) => ({
              name: f.value as string,
              resolvedName: f.text as string,
            }))
            .find((f) => f.name === frequency) || undefined,
      };

      onSubmit(timeFrame, options);
    },
    [availableAreas, frequencies, frequency, onSubmit],
  );

  useEffect(() => {
    if (isSubscriptionMode(orderMode)) {
      SubscriptionService.getFrequencies({
        setLoading: setLoadingFrequencies,
      }).then((actionResult) => {
        const frequenciesAsDropdrownOptions = actionResult.data.map((currentFrequency) => ({
          id: currentFrequency.name,
          value: currentFrequency.name,
          text: currentFrequency.resolvedName,
        }));

        setFrequencies(frequenciesAsDropdrownOptions);
        if (orderDetails.frequency) {
          setFrequency(orderDetails.frequency.name);
        } else {
          setFrequency(frequenciesAsDropdrownOptions[0].value);
        }
      });
    }
  }, [orderMode, orderDetails.frequency]);

  return (
    <>
      <Box>
        {isSubscriptionMode(orderMode) && !!availableAreas.length && (
          <Box>
            <Box mt={isMobile ? 0 : 28 / 8} mb={isMobile ? 8 / 8 : 16 / 8}>
              <Typography fontSize={isMobile ? 16 : 18} fontWeight="medium" color="textSecondary">
                {'subscription.chooseFrequency'}
              </Typography>
            </Box>
            {loadingFrequencies ? (
              <Skeleton height={40} variant="rect" />
            ) : (
              <DropDown
                size="large"
                fullWidth
                value={frequency}
                dropDownOptions={frequencies}
                onChange={handleChangeFrequency}
              />
            )}

            <Box className={classes.subscriptionChooseDayAndTimeWrapper}>
              {isSubscriptionMode(orderMode) && isMobile ? (
                <Divider className={classes.divider} />
              ) : (
                <Typography fontSize={18} fontWeight="medium" color="textSecondary">
                  {'header.chooseDelivery'}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {!isSubscriptionMode(orderMode) && !isMobile && (
          <Box my={24 / 8}>
            <Divider className={classes.divider} />
          </Box>
        )}
        <AvailableHours
          isSubmitting={isSubmitting}
          availableAreas={availableAreas}
          onSubmit={handleSubmit}
          initialLimitAvailableHours={3}
          isDialog={isDialog}
          gridSize={availableHoursGridSize}
        />
      </Box>
    </>
  );
};

export default DeliveryAvailableService;

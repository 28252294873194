import { TPrepareToPlaceOrderBody } from 'api';
import { LocationService } from 'api/services/LocationService';
import {
  ISelfPickupAvailableService,
  TOnSubmitSelfPickupHours,
} from 'components/common/availableService';
import { calculateDistance } from 'components/common/Dialog/components/LocationDialog/helpers';
import { TSelfPickupAreaItem } from 'hooks/useAvailableServiceAreas';
import { useDialog } from 'hooks/useDialog';
import { useNotify } from 'hooks/useNotify';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  catalogActions,
  GENERIC_DIALOG,
  orderDetailsActions,
  prepareToPlaceOrderActions,
  SELF_PICKUP_DIALOG,
  shoppingFlowActions,
} from 'store';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { stopOrderEditing } from 'store/modules/orderEditing/actions';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';
import {
  TGetDistanceFromPickupPointsToSelectedLocation,
  TGetSelectedAddressPlaceIdAndDistanceToPickupPoints,
  TGetUserCurrentLocation,
  THandleDeliverySubmit,
  TPrepareSelfPickupAreasAndShowDialog,
  TSortSelfPickupAreas,
  TUseSelfPickupAndDeliveryService,
} from './types';

const useSelfPickupAndDeliveryService: TUseSelfPickupAndDeliveryService = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showDialog, hideDialog } = useDialog();

  const websiteDetails = useWebsiteDetails();
  const orderDetails = useSelector(getOrderDetails);

  const { notifyShow } = useNotify();

  const { selfPickupLocation } = useSelector(getOrderDetails);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const sortSelfPickupAreas = useCallback<TSortSelfPickupAreas>((selfPickupAreasArray) => {
    return selfPickupAreasArray.sort((a, b) => {
      const aClientDistanceInKM =
        a.serviceArea.distanceInKmToSelectedAddress !== undefined
          ? a.serviceArea.distanceInKmToSelectedAddress
          : a.serviceArea.clientDistanceInKM;
      const bClientDistanceInKM =
        b.serviceArea.distanceInKmToSelectedAddress !== undefined
          ? b.serviceArea.distanceInKmToSelectedAddress
          : b.serviceArea.clientDistanceInKM;

      if (
        aClientDistanceInKM !== undefined &&
        aClientDistanceInKM >= 0 &&
        bClientDistanceInKM !== undefined &&
        bClientDistanceInKM >= 0
      ) {
        return aClientDistanceInKM - bClientDistanceInKM;
      }

      return a.serviceArea.name.localeCompare(b.serviceArea.name);
    });
  }, []);

  const sortSelfPickupAreasByPointName = useCallback<TSortSelfPickupAreas>(
    (selfPickupAreasArray) => {
      return selfPickupAreasArray.sort((a, b) => {
        // TODO temporary trick for יוכי אספרגוס, in future make something more efficient
        if (websiteDetails.store.id === 93) {
          if (a.serviceArea.name.includes('Monday')) {
            return -1;
          }
          if (b.serviceArea.name.includes('Monday')) {
            return 1;
          }
        }

        return a.serviceArea.name.localeCompare(b.serviceArea.name);
      });
    },
    [websiteDetails.store.id],
  );

  const handleSelfPickupSubmit = useCallback<TOnSubmitSelfPickupHours>(
    (timeFrame, storeServiceAreaId, options) => {
      setIsSubmitting(true);

      const selfPickupBody: TPrepareToPlaceOrderBody = {
        orderType: 'selfPickup',
        storeServiceAreaId,
        timeFrame,
      };

      dispatch(introductoryPopupsCompletedAction(true));
      dispatch(catalogActions.checkAndFetchBranchCatalogIfNecessary());

      dispatch(
        prepareToPlaceOrderActions.prepareToPlaceOrderRequestAction(
          selfPickupBody,
          {
            ...options,
          },
          {
            setLoading: setIsSubmitting,
          },
        ),
      );
    },
    [dispatch],
  );

  const handleUpdateSelfPickupArea = useCallback<
    Required<ISelfPickupAvailableService>['onUpdateArea']
  >(
    (newSelfPickupLocation) => {
      if (selfPickupLocation?.storeServiceAreaId !== newSelfPickupLocation?.storeServiceAreaId) {
        dispatch(
          orderDetailsActions.openDeliverySelfPickupRequest('selfPickup', {
            selfPickupLocation: newSelfPickupLocation,
          }),
        );
        dispatch(shoppingFlowActions.updateKnownAddressAndSubCatalog(null, null));

        dispatch(catalogActions.checkAndFetchBranchCatalogIfNecessary());
      }
    },
    [selfPickupLocation, dispatch],
  );

  const handleDeliverySubmit = useCallback<THandleDeliverySubmit>(
    (storeServiceAreaId, timeFrame, options) => {
      setIsSubmitting(true);

      const deliveryBody: TPrepareToPlaceOrderBody = {
        orderType: 'delivery',
        storeServiceAreaId,
        timeFrame,
      };
      dispatch(introductoryPopupsCompletedAction(true));
      dispatch(catalogActions.checkAndFetchBranchCatalogIfNecessary());

      dispatch(
        prepareToPlaceOrderActions.prepareToPlaceOrderRequestAction(deliveryBody, options, {
          setLoading: setIsSubmitting,
        }),
      );
    },
    [dispatch],
  );

  const resetDistanceToSelectedAddress =
    useCallback<TGetDistanceFromPickupPointsToSelectedLocation>((selfPickupAreas) => {
      const selfPickupAreasWithDistanceToClient: TSelfPickupAreaItem[] = selfPickupAreas.map(
        (area) => {
          const selfPickupArea = {
            ...area,
            serviceArea: {
              ...area.serviceArea,
              distanceInKmToSelectedAddress: undefined,
            },
          };
          return selfPickupArea;
        },
      );

      return selfPickupAreasWithDistanceToClient;
    }, []);

  const getDistanceFromPickupPointsToSelectedLocation =
    useCallback<TGetDistanceFromPickupPointsToSelectedLocation>((selfPickupAreas, position) => {
      const selfPickupAreasWithDistanceToClient: TSelfPickupAreaItem[] = selfPickupAreas.map(
        (area) => {
          let distanceInKmToSelectedAddress;

          if (position && area.serviceArea.coordinatesLatLng) {
            distanceInKmToSelectedAddress = calculateDistance(
              position.lat,
              position.lng,
              area.serviceArea.coordinatesLatLng.lat,
              area.serviceArea.coordinatesLatLng.lng,
            );
          }

          const selfPickupArea = {
            ...area,
            serviceArea: {
              ...area.serviceArea,
              distanceInKmToSelectedAddress,
            },
          };
          return selfPickupArea;
        },
      );

      return selfPickupAreasWithDistanceToClient;
    }, []);

  const prepareSelfPickupAreasAndShowDialog = useCallback<TPrepareSelfPickupAreasAndShowDialog>(
    (selfPickupAreas) => {
      const customSelfPickupAreas: TSelfPickupAreaItem[] = [];

      selfPickupAreas.forEach((area) => {
        if (JSON.parse(area.serviceAreaHoursJson).length)
          customSelfPickupAreas.push({
            serviceArea: {
              coordinatesLatLng: area.coordinatesLatLng,
              name: area.name,
              storeServiceAreaId: area.id,
            },
          });
      });

      showDialog({
        dialogType: SELF_PICKUP_DIALOG,
        contentProps: {
          customSelfPickupAreas,
        },
      });
    },
    [showDialog],
  );

  const getUserCurrentLocation = useCallback<TGetUserCurrentLocation>(() => {
    return new Promise((resolve, reject) => {
      return navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: 10000 });
      // default timeout for getCurrentPosition is infinite,
      // so we want the user to wait no more than 10 seconds
    });
  }, []);

  const getUserLocationAndUpdateDistanceToSelfPickupPoints = useCallback(
    (selfPickupAreasArray, successCallback, errorCallback) => {
      return getUserCurrentLocation()
        .then((currentPosition) => {
          if (currentPosition) {
            const selfPickupAreasWithDistanceToClient =
              getDistanceFromPickupPointsToSelectedLocation(selfPickupAreasArray, {
                lat: currentPosition.coords.latitude,
                lng: currentPosition.coords.longitude,
              });

            if (successCallback) {
              successCallback(selfPickupAreasWithDistanceToClient);
            }
          }
        })
        .catch(() => {
          notifyShow({
            message: 'map.navigatorNotSupported',
          });
          if (errorCallback) {
            errorCallback();
          }
        });
    },
    [getDistanceFromPickupPointsToSelectedLocation, getUserCurrentLocation, notifyShow],
  );

  const getSelectedAddressPlaceIdAndDistanceToPickupPoints =
    useCallback<TGetSelectedAddressPlaceIdAndDistanceToPickupPoints>(
      (selectedAddress, selfPickupAreasArray) => {
        return LocationService.getPlaceDetails(selectedAddress?.placeId).then((actionResult) => {
          if (!actionResult.data.coordinates) return;

          return getDistanceFromPickupPointsToSelectedLocation(
            selfPickupAreasArray,
            actionResult.data?.coordinates,
          );
        });
      },
      [getDistanceFromPickupPointsToSelectedLocation],
    );

  const showChangeOrderTypeNotPossibleInEditModeDialog = useCallback(() => {
    showDialog({
      dialogType: GENERIC_DIALOG,
      contentProps: {
        translate: false,
        title: t('dialog.editOrderMode.title'),
        body: t('dialog.editOrderMode.body', { orderDate: orderDetails.orderTime }),
        buttons: [
          {
            text: 'dialog.editOrderMode.keepEditing',
            variant: 'contained',
            onClick: () => {
              hideDialog();
            },
          },
          {
            text: 'dialog.editOrderMode.exitEditMode',
            variant: 'outlined',
            onClick: () => {
              dispatch(stopOrderEditing());
              hideDialog();
            },
          },
        ],
      },
    });
  }, [dispatch, hideDialog, orderDetails.orderTime, showDialog, t]);

  return {
    sortSelfPickupAreas,
    sortSelfPickupAreasByPointName,
    handleSelfPickupSubmit,
    handleDeliverySubmit,
    handleUpdateSelfPickupArea,
    prepareSelfPickupAreasAndShowDialog,
    isSubmitting,
    getUserCurrentLocation,
    getDistanceFromPickupPointsToSelectedLocation,
    getUserLocationAndUpdateDistanceToSelfPickupPoints,
    getSelectedAddressPlaceIdAndDistanceToPickupPoints,
    resetDistanceToSelectedAddress,
    showChangeOrderTypeNotPossibleInEditModeDialog,
  };
};

export default useSelfPickupAndDeliveryService;

import { BaseApi } from 'api/Api';
import { TGetSharedCart, TShareCart } from './types';

export default class ShareCartService {
  static shareCart: TShareCart = async (params) => {
    return BaseApi.instance().post(`/client/client/shared-cart `, params);
  };

  static getSharedCart: TGetSharedCart = async (shareCartId) => {
    return BaseApi.instance().get(`/client/public/shared-carts?id=${shareCartId}`);
  };
}

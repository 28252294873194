import { BaseApi, CatalogService, StoreService, TGetWebsiteDetailsParams } from 'api';
import { TActionResult } from 'api/Api/BaseApi/types';
import Dialog from 'components/common/Dialog';
import ErrorBoundary from 'components/common/ErrorBoundary';

import Notify from 'components/common/Notify';

import AppContainer from 'container/AppContainer';
import { supportedLanguages } from 'i18n/types';

import App, { AppContext, AppInitialProps } from 'next/app';

import Head from 'next/head';
import React, { useEffect, useMemo } from 'react';

import { Provider } from 'react-redux';
import { Dispatch } from 'redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { catalogActions, configActions, useStore, websiteActions } from 'store';
import {
  jweTokenUpdateAction,
  onClientBehalfUpdateAction,
  sessionOnlyJweTokenUpdateAction,
} from 'store/modules/auth/actions';
import { updateContentPagePreview } from 'store/modules/uiStyles/actions';
import { getPersistor, setPersistor } from 'store/useStore/persist';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { IMyApp, TWebsiteDetailsServer } from 'types';
import MainLayoutProvider from 'ui/common/layout/MainLayout/MainLayoutProvider';
import { fetchAndSetCategories } from 'utils/helpers/catalog';
import { isServer } from 'utils/helpers/isServer';

/**
 * Import Global Styles
 */
import '../globalStyles/index.scss';
import Custom404 from './404';

const errorPages = ['/error', '/maintenance'];
const healthPages = ['/health'];
const smallECPages = [
  '/',
  '/checkout/order-completed',
  '/account/orders',
  '/account/payment-methods',
  '/account/personal-details',
];

const detectAndSetMobile = (appContext: AppContext, dispatch: Dispatch): void => {
  let userAgent: string;
  if (appContext.ctx.req) {
    // if you are on the server, and you get a 'req' property from your context
    userAgent = appContext.ctx.req.headers['user-agent'] || ''; // get the user-agent from the headers
  } else {
    userAgent = navigator.userAgent; // if you are on the client you can access the navigator from the window object
  }

  // https://newbedev.com/how-to-detect-the-device-on-react-ssr-app-with-next-js
  const isMobile = Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
  );
  const isIOS = Boolean(userAgent.match(/iPad|iPhone|iPod/));
  // update isMobile in redux
  dispatch(configActions.configUpdate({ isMobile, isIOS }));
};

const detectAndSetLanguage = (dispatch: Dispatch, langQueryParam?: string | string[]): void => {
  if (langQueryParam) {
    const lang = langQueryParam.toString().toLowerCase();
    if (supportedLanguages.indexOf(lang) !== -1) {
      dispatch(
        configActions.configUpdate({
          lang,
          dir: lang === 'he' ? 'rtl' : 'ltr',
        }),
      );
    }
  }
};

const fetchAndSetWebsiteDetails = async (
  storeJweQueryParam: string | string[] | undefined,
  rexailAffiliateIdQueryParam: string | string[] | undefined,
  host: string | undefined,
  dispatch: Dispatch,
  xForwardedIpAddress?: string,
): Promise<TWebsiteDetailsServer> => {
  const params: TGetWebsiteDetailsParams = {};
  if (storeJweQueryParam) {
    params.s_jwe = storeJweQueryParam.toString();
  } else {
    params.domain = host;
  }

  // get website catalog
  const websiteDetailsResult = await StoreService.getWebsiteDetails(params, xForwardedIpAddress);
  const websiteDetails = { ...websiteDetailsResult.data };

  if (rexailAffiliateIdQueryParam) {
    websiteDetails.rexailAffiliateId = rexailAffiliateIdQueryParam.toString();
  }

  await dispatch(websiteActions.getWebsiteDetailsRequest(websiteDetails));

  return websiteDetails;
};

const setClientJwe = async (
  clientJweQueryParam: string | string[],
  permanent: boolean,
  dispatch: Dispatch,
): Promise<void> => {
  if (permanent) {
    dispatch(jweTokenUpdateAction(clientJweQueryParam.toString()));
  } else {
    dispatch(sessionOnlyJweTokenUpdateAction(clientJweQueryParam.toString()));
  }
};

const handleEditOrderFromLink = (
  appContext: AppContext,
  orderIdQueryParam: string | string[],
  clientJweQueryParam: string | string[],
): void => {
  if (appContext.ctx.res) {
    const response = appContext.ctx.res;
    response.writeHead(307, {
      Location: `/account/orders/view/${orderIdQueryParam}?c_jwe=${clientJweQueryParam}`,
    });
    response.end();
  }
};

const handleEditOrderOnClientBehalf = (
  appContext: AppContext,
  orderIdQueryParam: string | string[],
  onClientBehalfJweQueryParam: string | string[],
): void => {
  if (appContext.ctx.res) {
    const response = appContext.ctx.res;
    response.writeHead(307, {
      Location: `/account/orders/view/${orderIdQueryParam}?cs_jwe=${onClientBehalfJweQueryParam}`,
    });
    response.end();
  }
};

const handleEditSubscriptionFromLink = (
  appContext: AppContext,
  subscriptionIdQueryParam: string | string[],
  clientJweQueryParam: string | string[],
): void => {
  if (appContext.ctx.res) {
    const response = appContext.ctx.res;
    response.writeHead(307, {
      Location: `/account/subscriptions/view/${subscriptionIdQueryParam}?c_jwe=${clientJweQueryParam}`,
    });
    response.end();
  }
};

const handleEditSubscriptionOnClientBehalf = (
  appContext: AppContext,
  subscriptionIdQueryParam: string | string[],
  onClientBehalfJweQueryParam: string | string[],
): void => {
  if (appContext.ctx.res) {
    const response = appContext.ctx.res;
    response.writeHead(307, {
      Location: `/account/subscriptions/view/${subscriptionIdQueryParam}?cs_jwe=${onClientBehalfJweQueryParam}`,
    });
    response.end();
  }
};

const populatePageData = async (
  dispatch: Dispatch,
  websiteDetails: TWebsiteDetailsServer,
  xForwardedIpAddress?: string,
): Promise<void> => {
  await fetchAndSetCategories(dispatch, websiteDetails, xForwardedIpAddress, true);

  const catalogLastUpdateTime = await CatalogService.getCatalogVersion({
    websiteJWE: websiteDetails.jsonWebEncryption,
    xForwardedIpAddress,
  });
  dispatch(catalogActions.setCatalogSSRLastUpdateTimeRequest(catalogLastUpdateTime.data));
};

const MyApp: IMyApp = ({ Component, pageProps }) => {
  // source code:
  // https://github.com/vercel/next.js/blob/canary/examples/with-redux-persist/pages/_app.js
  const store = useStore(pageProps?.initialReduxState);
  const { pwaEnabled } = store.getState().store.websiteDetails.websiteSettings;

  let persistor = getPersistor();

  if (!persistor) {
    persistor = persistStore(store, {}, () => {
      // persistor.persist();
    });
    setPersistor(persistor);
  }

  // init Client Api and set website to use inside
  BaseApi.init(store);

  const componentToRender = useMemo(() => {
    if (pageProps && pageProps.statusCode && pageProps.statusCode === 404) {
      return <Custom404 />;
    }

    return <Component {...pageProps} />;
  }, [Component, pageProps]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles: Element | null = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    if (pwaEnabled && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/serviceworker.js', { scope: '/', updateViaCache: 'none' });
    }
  }, [pwaEnabled]);

  return (
    <>
      <Head>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <Provider store={store}>
        {process.browser ? (
          <PersistGate loading={<div>loading</div>} persistor={persistor}>
            <MainLayoutProvider>
              <AppContainer>
                <Notify />
                <Dialog />
                <ErrorBoundary>{componentToRender}</ErrorBoundary>
              </AppContainer>
            </MainLayoutProvider>
          </PersistGate>
        ) : (
          <AppContainer>
            <Notify />
            <Dialog />
            {componentToRender}
          </AppContainer>
        )}
      </Provider>
    </>
  );
};

// TODO - refactor to getServerSideProps / getStaticProps (need to check multi domain progressive static rendering)
MyApp.getInitialProps = async (appContext: AppContext) => {
  let pageInitialProps: AppInitialProps;

  pageInitialProps = {
    pageProps: {
      err: appContext.ctx.err || undefined,
    },
  };
  if (isServer() && !healthPages.includes(appContext.ctx.asPath || '')) {
    try {
      // need for SSR
      // source code:: https://www.quintessential.gr/blog/development/how-to-integrate-redux-with-next-js-and-ssr
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const store = useStore();
      const { dispatch } = store;
      const host = appContext.ctx.req ? appContext.ctx.req.headers.host : window.location.hostname;
      const storeJweQueryParam = appContext.ctx.query.s_jwe;
      const clientJweQueryParam = appContext.ctx.query.c_jwe;
      const onClientBehalfJweQueryParam = appContext.ctx.query.cs_jwe;
      const orderIdQueryParam = appContext.ctx.query.order_id;
      const subscriptionIdQueryParam = appContext.ctx.query.edit_subscription;
      const langQueryParam = appContext.ctx.query.lang;
      const rexailAffiliateIdQueryParam = appContext.ctx.query.rxclid;
      const isPreviewContentPagesMode = appContext.ctx.query.preview_mode;

      const xForwardedIpAddress = appContext.ctx.req?.headers['x-forwarded-for'] as
        | string
        | undefined;

      detectAndSetMobile(appContext, dispatch);
      detectAndSetLanguage(dispatch, langQueryParam);

      if (orderIdQueryParam && clientJweQueryParam) {
        // edit order from link
        handleEditOrderFromLink(appContext, orderIdQueryParam, clientJweQueryParam);
        return { ...pageInitialProps };
      }

      if (subscriptionIdQueryParam && clientJweQueryParam) {
        // edit subscription from link
        handleEditSubscriptionFromLink(appContext, subscriptionIdQueryParam, clientJweQueryParam);
        return { ...pageInitialProps };
      }

      if (onClientBehalfJweQueryParam && orderIdQueryParam) {
        // retailer edit order from back office
        handleEditOrderOnClientBehalf(appContext, orderIdQueryParam, onClientBehalfJweQueryParam);
        return { ...pageInitialProps };
      }

      if (onClientBehalfJweQueryParam && subscriptionIdQueryParam) {
        // retailer edit subscription from back office
        handleEditSubscriptionOnClientBehalf(
          appContext,
          subscriptionIdQueryParam,
          onClientBehalfJweQueryParam,
        );
        return { ...pageInitialProps };
      }

      if (clientJweQueryParam) {
        await setClientJwe(clientJweQueryParam, true, dispatch);
      }

      if (onClientBehalfJweQueryParam) {
        dispatch(onClientBehalfUpdateAction(true));
        await setClientJwe(onClientBehalfJweQueryParam, false, dispatch);
      }

      if (isPreviewContentPagesMode) {
        dispatch(updateContentPagePreview(true));
      }

      // fetch SSR data (website details, catalog, promotions..)
      BaseApi.init(store);

      const websiteDetails = await fetchAndSetWebsiteDetails(
        storeJweQueryParam,
        rexailAffiliateIdQueryParam,
        host,
        dispatch,
        xForwardedIpAddress,
      );

      const parsedWebsiteSettings = JSON.parse(websiteDetails.settingsJson) || {};

      if (
        parsedWebsiteSettings.isSmallEC &&
        !smallECPages.some((allowedPage) => {
          return (
            (appContext.ctx.asPath as string) === allowedPage ||
            (appContext.ctx.asPath as string).startsWith(`${allowedPage}/`)
          );
        })
      ) {
        if (appContext.ctx.res) {
          const response = appContext.ctx.res;

          response.writeHead(307, {
            Location: '/',
          });
          response.end();
        }
      }

      // if fetch passed successfully and user is on error page than redirect to home.
      if (errorPages.some((page) => (appContext.ctx.asPath || '').includes(page))) {
        if (appContext.ctx.res) {
          const response = appContext.ctx.res;

          response.writeHead(307, {
            Location: '/',
          });
          response.end();
        }
      } else {
        await populatePageData(dispatch, websiteDetails, xForwardedIpAddress);
      }

      const enhancedPageContext = {
        ...appContext,
        ctx: { ...appContext.ctx, store },
      };

      pageInitialProps = await App.getInitialProps(enhancedPageContext);

      return {
        pageProps: {
          initialReduxState: store.getState(),
          ...pageInitialProps.pageProps,
        },
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e); // TODO - pass error to page
      const errorAsActionResult = e as TActionResult<void>;

      // if received error and user is not on error page than redirect to error page.
      if (!errorPages.some((page) => (appContext.ctx.asPath?.split('?')[0] || '').includes(page))) {
        if (appContext.ctx.res) {
          const response = appContext.ctx.res;

          response.writeHead(307, {
            Location:
              errorAsActionResult && +errorAsActionResult.code === 503
                ? '/maintenance'
                : `/error?description=${encodeURIComponent(JSON.stringify(errorAsActionResult))}`,
          });
          response.end();
        }
      }
    }
  }

  return {
    ...pageInitialProps,
  };
};

export default MyApp;

import { Box, Button, Divider, IconButton, useTheme } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';
import { SubscriptionService } from 'api/services/SubscriptionService';
import classNames from 'classnames';
import SelfPickupAreasForStoreWithManyPoint from 'components/common/Dialog/components/SelfPickupAreasForStoreWithManyPoint';
import LocationAutocomplete, { shareCurrentLocation } from 'components/common/LocationAutocomplete';
import SelfPickupOption from 'components/common/SelfPickupOption';
import FixedButton from 'components/mobile/FixedButton';
import { PositionProperty } from 'csstype';

import { useDialog, useMobile, useWebsiteDetails } from 'hooks';

import { find } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors, LOGIN_DIALOG, orderDetailsActions, userSelectors } from 'store';
import { getLang } from 'store/modules/config/selectors';
import { updateOrderDetails } from 'store/modules/orderDetails/actions';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { TNameAndResolvedName } from 'types';
import CTAButton from 'ui/common/buttons/CTAButton';
import Icon from 'ui/common/icons/Icon';
import DropDown, { IDropDown, TDropDownOption } from 'ui/common/inputs/DropDown';
import Map, { GOOGLE_MAP_INITIAL_POSITION, ILatLng } from 'ui/common/Map';
import Typography from 'ui/common/Typography';
import { isSubscriptionMode } from 'utils/helpers/subscriptions';
import { AvailableHours, IAvailableHours, TOnSubmitAvailableHours } from '../components';
import useStyles from '../styles';
import { ISelfPickupAvailableService } from './types';

const SelfPickupAvailableService: FC<ISelfPickupAvailableService> = ({
  selfPickupAreasArray,
  activeServiceArea,
  onChangeServiceArea,
  onSubmit,
  initialSelfPickupLocation,
  isSubmitting,
  onUpdateArea,
  availableHoursGridSize,
  isDialog = true,
  addressInputValue = null,
  onLocationAutocompleteChange,
  amountOfPickupPointsToShowMap,
  distanceLabel,
  onlySelfPickups,
  onGetCurrentLocationClick,
}) => {
  const { isMobile } = useMobile();
  const dispatch = useDispatch();
  const orderMode = useSelector(getOrderMode);
  const orderDetails = useSelector(getOrderDetails);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const lang = useSelector(getLang);
  const websiteDetails = useWebsiteDetails();
  const profile = useSelector(userSelectors.getProfile);

  const classes = useStyles({
    isDialog,
    lang,
    isManyPointsContent: selfPickupAreasArray.length >= amountOfPickupPointsToShowMap,
    isSubscription: isSubscriptionMode(orderMode),
  });

  const { showDialog, hideDialog } = useDialog();

  const theme = useTheme();
  const { t } = useTranslation();

  const mapRef = useRef<HTMLDivElement>(null);
  const endOfListRef = useRef<HTMLDivElement>(null);
  const pointsListRef = useRef<HTMLDivElement>(null);

  const [isSticky, setIsSticky] = useState(false);
  const [loadingFrequencies, setLoadingFrequencies] = useState<boolean>(true);
  const [frequency, setFrequency] = useState<TNameAndResolvedName['name'] | null>(null);
  const [frequencies, setFrequencies] = useState<TDropDownOption[]>([]);
  const [inputValue, setInputValue] = useState(addressInputValue);
  const [selectedSelfPickupPointIndex, setSelectedSelfPickupPointIndex] = useState<number>();

  const handleChangeFrequency = useCallback<Required<IDropDown>['onChange']>(
    ({ target: { value } }) => {
      setFrequency(value as TNameAndResolvedName['name']);

      if (!isMobile && isDialog) {
        dispatch(
          updateOrderDetails({
            ...orderDetails,
            orderTime: undefined,
            preferredDay: undefined,
            preferredHour: undefined,
          }),
        );
      }
    },
    [isMobile, isDialog, dispatch, orderDetails],
  );

  const getActiveHoursArray = useMemo<IAvailableHours['availableAreas']>(() => {
    const activeHoursArray = find(selfPickupAreasArray, [
      'serviceArea.storeServiceAreaId',
      activeServiceArea?.storeServiceAreaId,
    ]);

    return activeHoursArray?.availableArea || [];
  }, [selfPickupAreasArray, activeServiceArea]);

  const handleSubmit = useCallback<TOnSubmitAvailableHours>(
    (timeFrame) => {
      const selfPickupLocation = find(selfPickupAreasArray, [
        'serviceArea.storeServiceAreaId',
        activeServiceArea?.storeServiceAreaId,
      ]);
      const area = find(selfPickupLocation?.availableArea, ['date', timeFrame.date]);
      const date = find(area?.availableHours, ['openHour', timeFrame.openHour]);

      const options = {
        selfPickupLocation: selfPickupLocation?.serviceArea,
        duration: date?.duration,
        frequency:
          frequencies
            .map((f) => ({
              name: f.value as string,
              resolvedName: f.text as string,
            }))
            .find((f) => f.name === frequency) || undefined,
      };

      onSubmit(timeFrame, activeServiceArea?.storeServiceAreaId as number, options);
    },
    [selfPickupAreasArray, activeServiceArea, frequencies, onSubmit, frequency],
  );

  useEffect(() => {
    setInputValue(addressInputValue);
  }, [addressInputValue]);

  // set default active service area to show hours
  useEffect(() => {
    if (initialSelfPickupLocation?.storeServiceAreaId) {
      const selectedSelfPickup = selfPickupAreasArray.filter(
        (selfPickup) =>
          selfPickup.serviceArea.storeServiceAreaId ===
          initialSelfPickupLocation?.storeServiceAreaId,
      );

      if (!selectedSelfPickup.length) {
        // when point chosen by client is not available anymore we will remove
        // it and show self pickup points list again
        onChangeServiceArea(null);
        dispatch(
          orderDetailsActions.updateOrderDetails({
            orderMode,
            selfPickupLocation: {
              name: '',
              storeServiceAreaId: 0,
            },
            courierTip: 0,
            orderType: 'selfPickup',
          }),
        );
        return;
      }
      onChangeServiceArea(selectedSelfPickup[0].serviceArea);
    } else if (
      selfPickupAreasArray.length === 1 &&
      selfPickupAreasArray[0].serviceArea &&
      selfPickupAreasArray[0].serviceArea.storeServiceAreaId
    ) {
      onChangeServiceArea(selfPickupAreasArray[0].serviceArea);
    }
    // dont put selfPickupAreasArray because it will cause rerender when user when chose option from autocomplete array will be changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelfPickupLocation, dispatch, orderMode]);

  useEffect(() => {
    if (
      onUpdateArea &&
      selfPickupAreasArray.length !== 0 &&
      !!activeServiceArea?.storeServiceAreaId
    ) {
      const selfPickupLocation = find(selfPickupAreasArray, [
        'serviceArea.storeServiceAreaId',
        activeServiceArea?.storeServiceAreaId,
      ]);

      onUpdateArea(selfPickupLocation?.serviceArea);
    }
  }, [activeServiceArea, selfPickupAreasArray, onUpdateArea]);

  useEffect(() => {
    if (isSubscriptionMode(orderMode)) {
      SubscriptionService.getFrequencies({
        setLoading: setLoadingFrequencies,
      }).then((actionResult) => {
        const frequenciesAsDropdrownOptions = actionResult.data.map((currentFrequency) => ({
          id: currentFrequency.name,
          value: currentFrequency.name,
          text: currentFrequency.resolvedName,
        }));

        setFrequencies(frequenciesAsDropdrownOptions);
        if (orderDetails.frequency) {
          setFrequency(orderDetails.frequency.name);
        } else {
          setFrequency(frequenciesAsDropdrownOptions[0].value);
        }
      });
    }
  }, [orderMode, orderDetails.frequency]);

  useEffect(() => {
    if (activeServiceArea) return;

    const options = {
      root: null,
      rootMargin: isDialog ? '-5px 0px 0px' : '-60px 0px 0px', // on checkout, we need to take into consideration sticky header
      threshold: 1,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsSticky(!entry.isIntersecting);
    }, options);

    const { current } = mapRef;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [activeServiceArea, isDialog, selectedSelfPickupPointIndex]);

  const openLoginDialog = useCallback(() => {
    showDialog({
      dialogType: LOGIN_DIALOG,
    });
  }, [showDialog]);

  const onSelectSelfPickup = useCallback((index) => {
    setSelectedSelfPickupPointIndex(index);
  }, []);

  const scrollToSpecificPoint = useCallback((index) => {
    if (!pointsListRef?.current) return;

    const item = pointsListRef.current.children[index];
    const isLastItem = index === pointsListRef.current.children.length - 1;

    if (isLastItem) {
      endOfListRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    if (item) {
      item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  const mapContainerStyles = useMemo(() => {
    let styles = {
      width: '330px',
      height: '414px',
      margin: theme.direction === 'rtl' ? '0px 24px 44px 0px' : '0px 0px 44px 24px',
      borderRadius: '8px',
    };

    if (isDialog) {
      styles = {
        width: onlySelfPickups ? '296px' : '269px',
        height: onlySelfPickups ? '393px' : '330px',
        margin:
          isDialog && !onlySelfPickups
            ? (theme.direction === 'rtl' && '32px 16px 0px 28px') || '32px 32px 0px 16px'
            : '0px',
        borderRadius: onlySelfPickups ? '0px' : '8px',
      };
    }

    if (isMobile) {
      styles = {
        width: '100%',
        height: '210px',
        margin: '0px',
        borderRadius: '8px',
      };
    }

    return styles;
  }, [theme.direction, isDialog, isMobile, onlySelfPickups]);

  const manyPointsContent = useMemo(() => {
    const mobileMapStyles = {
      width: isDialog ? '100%' : 'calc(100% + 28px)',
      zIndex: 1,
      height: '238px',
      top: isDialog ? '0px' : '56px',
      padding: '14px',
      background: 'white',
      boxShadow: isSticky ? 'rgba(0, 0, 0, 0.06) 0px 4px 10px 0px' : 'none',
      borderRadius: '8px',
      position: 'sticky' as PositionProperty,
    };

    return (
      <>
        {isMobile && (
          <Typography
            variant={isDialog ? 'h3' : 'body1'}
            fontWeight="medium"
            className={classes.dropdownTitle}
          >
            {'dialog.location.chooseSelfPickup'}
          </Typography>
        )}
        <Box
          display="flex"
          height={isMobile ? '100%' : (isDialog && '394px') || '414px'}
          flexDirection={isMobile ? 'column-reverse' : 'initial'}
          alignItems={isMobile ? 'center' : 'initial'}
          paddingBottom={isMobile ? '14px' : 0}
          justifyContent="space-between"
        >
          <Box className={classes.listWrapper}>
            {!isMobile && (
              <Typography
                variant={isDialog ? 'h3' : 'body1'}
                className={classes.selfPickupPoint}
                fontWeight="medium"
              >
                {'dialog.location.chooseSelfPickup'}
              </Typography>
            )}
            <Box className={classes.autoCompleteWrapper}>
              <LocationAutocomplete
                openOnFocus={!isMobile}
                minChars={3}
                variant="addresses"
                customLabel={t('input.searchLocationByAddress')}
                onChange={onLocationAutocompleteChange}
                value={inputValue}
                onClearInput={() => {
                  onLocationAutocompleteChange(null);
                  setInputValue(null);
                }}
                defaultValue={inputValue}
                extraOption={!isMobile ? shareCurrentLocation : undefined}
                addressesOnly={false}
              />
            </Box>
            {isMobile ? (
              <Button
                style={{ padding: '0px 14px', margin: '8px 0px 5px' }}
                disableTouchRipple
                onClick={() => {
                  onGetCurrentLocationClick();
                  setInputValue(null);
                }}
              >
                <Icon name="icon-current-location" color="primary" style={{ fontSize: '14px' }} />
                <Typography
                  fontSize={14}
                  fontWeight="medium"
                  color="primary"
                  className={classes.shareMyLocationMobileButton}
                >
                  {'location.shareMyLocation'}
                </Typography>
              </Button>
            ) : (
              <Divider className={classes.manyPointsDivider} />
            )}
            {/* @ts-ignore */}
            <Box ref={pointsListRef}>
              {selfPickupAreasArray.map(({ serviceArea }, index) => (
                <Box key={index}>
                  <Box
                    className={classNames(classes.pickupWrapper, {
                      [classes.selectedPickup]: index === selectedSelfPickupPointIndex,
                    })}
                  >
                    <SelfPickupAreasForStoreWithManyPoint
                      onClick={() => onSelectSelfPickup(index)}
                      serviceArea={serviceArea}
                      isAreaSelected={index === selectedSelfPickupPointIndex}
                      onChoseArea={(selfPickupArea) => {
                        onChangeServiceArea(selfPickupArea);
                      }}
                      distanceLabel={distanceLabel}
                    />
                  </Box>

                  {selfPickupAreasArray.length !== index + 1 && (
                    <Divider className={classes.manyPointsDivider} />
                  )}
                </Box>
              ))}
            </Box>
            {/* @ts-ignore */}
            {selectedSelfPickupPointIndex !== undefined && <Box height="60px" ref={endOfListRef} />}
          </Box>
          {/* @ts-ignore */}
          <Box style={isMobile ? mobileMapStyles : {}} ref={isMobile ? mapRef : null}>
            <Map
              containerStyle={mapContainerStyles}
              initialPosition={GOOGLE_MAP_INITIAL_POSITION}
              onLocationChange={() => {}}
              pointers={selfPickupAreasArray?.map(
                (area) =>
                  ({
                    lat: area.serviceArea?.coordinatesLatLng?.lat,
                    lng: area.serviceArea?.coordinatesLatLng?.lng,
                  } as ILatLng),
              )}
              onPointerClick={(index) => {
                onSelectSelfPickup(index);
                scrollToSpecificPoint(index);
              }}
              pointersIcon="icon-pickup-on-map"
              pointersColor={theme.palette.grey.A700}
              pointersFontSize={32}
              selectedPointerIndex={selectedSelfPickupPointIndex}
              showUserLocation
              customZoom={9}
            />
          </Box>
        </Box>
        {isMobile && selectedSelfPickupPointIndex !== undefined && (
          <FixedButton>
            <CTAButton
              fullWidth
              onClick={() => {
                onChangeServiceArea(selfPickupAreasArray[selectedSelfPickupPointIndex].serviceArea);
              }}
              className={classes.mobileAreaSelectedButton}
            >
              {t('dialog.delivery.chooseSelfPickupTime')}
            </CTAButton>
          </FixedButton>
        )}
      </>
    );
  }, [
    isDialog,
    isSticky,
    isMobile,
    classes.dropdownTitle,
    classes.listWrapper,
    classes.selfPickupPoint,
    classes.autoCompleteWrapper,
    classes.shareMyLocationMobileButton,
    classes.manyPointsDivider,
    classes.mobileAreaSelectedButton,
    classes.pickupWrapper,
    classes.selectedPickup,
    t,
    onLocationAutocompleteChange,
    inputValue,
    selfPickupAreasArray,
    selectedSelfPickupPointIndex,
    mapContainerStyles,
    theme.palette.grey.A700,
    onGetCurrentLocationClick,
    distanceLabel,
    onSelectSelfPickup,
    onChangeServiceArea,
    scrollToSpecificPoint,
  ]);

  const activePointContent = useMemo(() => {
    return (
      <>
        <Box className={classes.activePointContent}>
          <Box>
            <Box className={classes.titleWrapper} mt={isMobile ? -6 / 8 : -4 / 8}>
              <Typography
                variant={isDialog ? 'h3' : 'body1'}
                className={classes.activeSelfPickupPoint}
                fontWeight="medium"
              >
                {'dialog.delivery.selfPickupPoint'}
              </Typography>
              <Box display="flex">
                <IconButton
                  size="medium"
                  onClick={() => onChangeServiceArea(null)}
                  className={classes.btnEditSelfPickup}
                >
                  <Icon
                    name="icon-edit"
                    color="primary"
                    classes={{ root: classes.iconEditSelfPickup }}
                  />
                  {!isMobile && (
                    <Typography
                      variant="h3"
                      color="primary"
                      className={classNames(classes.selfPickupEditLabel, 'font-family-rubik')}
                    >
                      {'dialog.delivery.changeSelfPickupPoint'}
                    </Typography>
                  )}
                </IconButton>
              </Box>
            </Box>
            <Box className={classes.selfPickupDetails}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.selfPickupAddress}
              >
                {activeServiceArea?.name}
              </Typography>
              {(activeServiceArea?.distanceInKmToSelectedAddress !== undefined ||
                (!websiteDetails.websiteSettings.onlySelfPickups &&
                  activeServiceArea?.clientDistanceInKM !== undefined &&
                  profile.fullAddress)) && (
                <Box display="flex">
                  <Typography
                    variant="body1"
                    color="mediumGrey"
                    fontSize={13}
                    className={classes.distance}
                  >
                    {activeServiceArea?.distanceInKmToSelectedAddress?.toFixed(1) ||
                      activeServiceArea?.clientDistanceInKM?.toFixed(1)}
                    &nbsp;
                  </Typography>
                  <Typography
                    variant="body1"
                    color="mediumGrey"
                    fontSize={13}
                    className={classes.distance}
                  >
                    {distanceLabel}
                  </Typography>
                </Box>
              )}
              <Divider className={classes.selfPickupDivider} />
            </Box>
          </Box>
          {!isMobile && !isSubscriptionMode(orderMode) && (
            <Box mb={16 / 8}>
              <Typography
                className={classes.pickUpDateLabel}
                variant={isDialog ? 'h3' : 'body1'}
                color={isDialog ? 'secondary' : 'textSecondary'}
                fontWeight="medium"
              >
                {'forms.checkout.pickUpDate'}
              </Typography>
            </Box>
          )}
          {!isMobile && isDialog && isSubscriptionMode(orderMode) && (
            <Box mb={16 / 8}>
              <Typography
                className={classes.pickUpDateLabel}
                variant={isDialog ? 'h3' : 'body1'}
                color={isDialog ? 'secondary' : 'textSecondary'}
                fontWeight="medium"
              >
                {'stepper.subscription.coordinationArrival'}
              </Typography>
            </Box>
          )}
          {!isLoggedIn && (
            <>
              {isMobile && (
                <Box mb={1}>
                  <Typography
                    className={classes.pickUpDateLabel}
                    variant={isDialog ? 'h3' : 'body1'}
                    color={isDialog ? 'secondary' : 'textSecondary'}
                    fontWeight="medium"
                  >
                    {'forms.checkout.pickUpDate'}
                  </Typography>
                </Box>
              )}
              <Typography className={classes.chooseSelfDate}>
                {'dialog.delivery.toChooseSelfPickupDateNeedLog'}
              </Typography>
              <Box mt={24 / 8} display="flex">
                <CTAButton className={classes.loginButton} onClick={openLoginDialog}>
                  {'userMenu.loginText'}
                </CTAButton>
                <CTAButton
                  className={classes.continueShoppingButton}
                  onClick={hideDialog}
                  variant="outlined"
                >
                  {'button.continueShopping'}
                </CTAButton>
              </Box>
            </>
          )}
          {isMobile && isDialog && isLoggedIn && (
            <Typography variant="h4" color="secondary" className={classes.coordinationTitle}>
              {isSubscriptionMode(orderMode)
                ? 'stepper.subscription.coordinationArrival'
                : 'forms.checkout.pickUpDate'}
            </Typography>
          )}

          {isSubscriptionMode(orderMode) && (
            <>
              <Box className={classes.dropdownBlock}>
                <Box mb={isMobile ? 8 / 8 : 16 / 8}>
                  <Typography
                    fontSize={isMobile ? 16 : 18}
                    fontWeight="medium"
                    color="textSecondary"
                  >
                    {'subscription.chooseFrequency'}
                  </Typography>
                </Box>
                {loadingFrequencies ? (
                  <Skeleton height={40} variant="rect" />
                ) : (
                  <DropDown
                    size="large"
                    fullWidth
                    value={frequency}
                    dropDownOptions={frequencies}
                    onChange={handleChangeFrequency}
                  />
                )}
              </Box>
              <Box className={classes.subscriptionChooseDayAndTimeWrapper}>
                {isSubscriptionMode(orderMode) && isMobile ? (
                  <Divider className={classes.divider} />
                ) : (
                  <Typography fontSize={18} fontWeight="medium" color="textSecondary">
                    {'forms.checkout.pickUpDate'}
                  </Typography>
                )}
              </Box>
            </>
          )}

          <Box>
            <AvailableHours
              isSubmitting={isSubmitting}
              availableAreas={getActiveHoursArray}
              onSubmit={handleSubmit}
              initialLimitAvailableHours={3}
              isDialog={isDialog}
              gridSize={availableHoursGridSize}
            />
          </Box>
        </Box>
      </>
    );
  }, [
    activeServiceArea,
    availableHoursGridSize,
    classes.activePointContent,
    classes.activeSelfPickupPoint,
    classes.btnEditSelfPickup,
    classes.chooseSelfDate,
    classes.continueShoppingButton,
    classes.coordinationTitle,
    classes.distance,
    classes.divider,
    classes.dropdownBlock,
    classes.iconEditSelfPickup,
    classes.loginButton,
    classes.pickUpDateLabel,
    classes.selfPickupAddress,
    classes.selfPickupDetails,
    classes.selfPickupDivider,
    classes.selfPickupEditLabel,
    classes.subscriptionChooseDayAndTimeWrapper,
    classes.titleWrapper,
    distanceLabel,
    frequencies,
    frequency,
    getActiveHoursArray,
    handleChangeFrequency,
    handleSubmit,
    hideDialog,
    isDialog,
    isLoggedIn,
    isMobile,
    isSubmitting,
    loadingFrequencies,
    onChangeServiceArea,
    openLoginDialog,
    orderMode,
    profile.fullAddress,
    websiteDetails.websiteSettings.onlySelfPickups,
  ]);

  const content = useMemo(() => {
    return (
      <Box>
        <Box className={classes.dropdownBlock}>
          <Typography
            variant={isDialog ? 'h3' : 'body1'}
            fontWeight="medium"
            className={classes.dropdownTitle}
          >
            {'dialog.location.chooseSelfPickup'}
          </Typography>
          {selfPickupAreasArray.map((serviceArea, index) => (
            <Box mt={2} key={serviceArea.serviceArea.storeServiceAreaId}>
              <Box my={24 / 8}>
                <SelfPickupOption
                  onClick={onChangeServiceArea}
                  serviceArea={serviceArea.serviceArea}
                  distanceLabel={distanceLabel}
                  isDialog={isDialog}
                />
              </Box>
              {/* If not in Dialog and item is last in list, don't render a divider */}
              {(isDialog || selfPickupAreasArray.length !== index + 1) && (
                <Divider className={classes.divider} />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }, [
    classes.divider,
    classes.dropdownBlock,
    classes.dropdownTitle,
    distanceLabel,
    isDialog,
    onChangeServiceArea,
    selfPickupAreasArray,
  ]);

  const mainContent = useMemo(() => {
    if (activeServiceArea) return activePointContent;

    return selfPickupAreasArray.length >= amountOfPickupPointsToShowMap
      ? manyPointsContent
      : content;
  }, [
    activePointContent,
    activeServiceArea,
    amountOfPickupPointsToShowMap,
    content,
    manyPointsContent,
    selfPickupAreasArray.length,
  ]);

  return mainContent;
};

export default SelfPickupAvailableService;

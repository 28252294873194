import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesTestimonialsSlider } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesTestimonialsSlider, { isOpenedTopCart: boolean; showOpacity: boolean }> =>
    createStyles({
      testimonialsSlider: { maxWidth: '1240px', margin: '0 auto' },
      quoets: {
        fontSize: '46px !important',
        color: theme.palette.primary.main,
      },
      testimonialsWrapper: ({ isOpenedTopCart, showOpacity }) => ({
        maxWidth: isOpenedTopCart ? 898 : '100%',
        opacity: 0.04,
        [theme.breakpoints.up('md')]: {
          opacity: 1,
          '& .swiper-wrapper': {
            boxSizing: 'border-box',
            '& .swiper-slide': {
              opacity: showOpacity ? 0.5 : 1,
            },
            '& .swiper-slide-next': {
              opacity: showOpacity ? 0.75 : 1,
            },
            '& .swiper-slide-active': {
              opacity: 1,
            },
          },
        },
      }),
      testimonialCardWrapper: {
        backgroundColor: theme.palette.background.paper,
        padding: '34px 22px 34px',
        height: '380px',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        [theme.breakpoints.up('md')]: {
          backgroundImage: 'none',
          padding: '30px 26px 26px',
          width: ({ isOpenedTopCart }) => (isOpenedTopCart ? '284px' : '398px'),
        },
      },
      testimonialDescription: {
        fontSize: '20px',
        lineHeight: '30px',
        marginTop: '14px',
        color: theme.palette.text.secondary,
        fontWeight: 500,
      },
      testimonialName: {
        fontSize: '16px',
        lineHeight: '18px',
        fontWeight: 500,
        color: theme.palette.text.secondary,
        marginBottom: '4px',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
          lineHeight: '22px',
        },
      },
      testimonialCity: {
        fontSize: '16px',
        lineHeight: '18px',
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
          lineHeight: '22px',
        },
      },
      mobileImagesBannerSlider: {
        padding: '0px  14px',
        display: 'flex',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'transparent !important',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'transparent !important',
          borderRadius: 'none',
        },
      },
      swiperWrapper: {
        '& .swiper': {
          overflowY: 'visible !important',
        },
      },
      swiper: {
        '& .swiper-wrapper': {
          boxSizing: 'border-box',
          '& .swiper-slide': {
            width: '300px !important',
          },
        },
      },
      pagination: {
        alignItems: 'center',
        width: theme.spacing(1),
        height: theme.spacing(1),
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '50%',
        marginLeft: theme.spacing(10 / 8),
      },
      clickablePagination: {
        display: 'flex',
        justifyContent: 'center',
        bottom: '-30px !important',
        alignItems: 'center',
      },
      activeBulletPagination: {
        width: theme.spacing(10 / 8),
        height: theme.spacing(10 / 8),
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
      },
      title: {
        fontSize: '26px',
        lineHeight: '28px',
        color: theme.palette.secondary.main,
        fontWeight: 700,
        fontFamily: 'Heebo',
        marginBottom: '22px',
        textAlign: 'center',
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesTooltip } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesTooltip, { isMobile: boolean }> =>
    createStyles({
      tooltip: ({ isMobile }) => ({
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '2px',
        boxShadow: isMobile ? '0 2px 4px 0 rgba(0, 0, 0, 0.18)' : '0 5px 15px 0 rgba(0, 0, 0, 0.1)',
      }),
      arrow: {
        marginTop: '3px',
        color: `${theme.palette.secondary.main}!important `,
      },
      title: ({ isMobile }) => ({
        fontSize: isMobile ? '14px' : '13px',
        letterSpacing: -0.2,
        lineHeight: 1.2,
      }),
      popper: {},
      touch: {},
      limitationMainPart: {
        backgroundColor: 'rgb(0,0,0, 0.14)',
        padding: '4px 8px',
        display: 'flex',
        borderRadius: '2px',
        alignItems: 'baseline',
      },
      limitationTypography: {
        textAlign: 'left',
        fontSize: '10px',
        lineHeight: 1.4,
        color: theme.palette.background.paper,
        opacity: 0.8,
      },
      tooltipPlacementBottom: ({ isMobile }) => ({
        top: isMobile ? '-22px !important' : 0,
      }),
    }),
);

import React, { FC, useCallback, useState } from 'react';

import { Box } from '@material-ui/core';
import Typography from 'ui/common/Typography';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';

import CTAButton from 'ui/common/buttons/CTAButton';
import EmailInput from 'ui/common/inputs/EmailInput';

import { useTranslation } from 'react-i18next';

import { AuthService } from 'api/services/AuthService';
import { LOGIN_CODE_DIALOG } from 'store/modules/dialog';
import { useDialog } from 'hooks/useDialog';
import Avatar from 'components/common/Avatar';
import useStyles from './styles';
import { IEmailVerificationDialog } from './types';

const EmailVerificationDialog: FC<IEmailVerificationDialog> = ({ cellPhone }) => {
  const classes = useStyles();
  const websiteDetails = useWebsiteDetails();

  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean>(false);

  const { showDialog } = useDialog();

  const handleSubmit = useCallback(async () => {
    setError('');

    const actionResult = await AuthService.applyForAuthentication(
      { email, cellPhone, selectedAuthenticationMethod: 'EMAIL' },
      { setLoading },
    );

    if (!actionResult.success) {
      setError(actionResult.resolvedMessage);
      return;
    }

    showDialog({
      dialogType: LOGIN_CODE_DIALOG,
      contentProps: {
        cellPhone,
        initialAuthenticationMethodType: 'EMAIL',
        customMethodOfAuthToDisplay: email,
      },
    });
  }, [cellPhone, email, showDialog]);

  return (
    <Box className={classes.root}>
      <Avatar
        src={websiteDetails.logoPath}
        variant="circle"
        classes={{
          root: classes.logo,
          img: classes.logo,
        }}
      />
      <Box className={classes.title}>
        <Typography variant="h2" color="secondary" align="center" className={classes.titleMain}>
          {'dialog.loginCode.titleSendViaEmail'}
        </Typography>
        <Typography
          variant="body1"
          fontSize={16}
          color="grey"
          align="center"
          className={classes.subTitle}
        >
          {'dialog.loginCode.enterEmailToSendAuthCode'}
        </Typography>
      </Box>

      <Box className={classes.rootButton}>
        <EmailInput
          value={email}
          onChange={(value) => setEmail(value)}
          onIsValidChange={setIsInputValid}
          inputProps={{
            placeholder: t('input.email'),
            startIcon: 'icon-mail',
            isShowLabel: false,
          }}
          customError={!!error}
          errorMessage={error}
        />
        <Box mt={22 / 8}>
          <CTAButton
            fullWidth
            onClick={handleSubmit}
            loading={loading}
            disabled={!email || !isInputValid}
          >
            {'dialog.loginCode.send'}
          </CTAButton>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerificationDialog;

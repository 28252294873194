import { IThemePalette } from 'store';

export const defaultPalette: IThemePalette = {
  primary: {
    main: '#33cc66', // light-green
    dark: '#2eb75b', // light-green-darker
    light: '#eefbf2', // very light green
  },
  secondary: {
    main: '#005350', // dark-green
  },
  grey: {
    50: '#f7f9fb', // mint-green
    200: '#f0f3f5', // light-grey 2
    A100: '#e9eded', // light-grey
    A200: '#c6cbcb', // medium-grey 2
  },
  background: {
    default: '#eef1ef', // BG
  },
};

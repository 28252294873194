import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesSearch } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSearch> =>
    createStyles({
      root: {
        background: 'transparent',
        height: theme.spacing(4), // 32px
        borderRadius: theme.spacing(2), // 16px,
        color: theme.palette.background.paper,

        '&$focused $notchedOutline': {
          borderWidth: '1px',
        },
        '&:hover $notchedOutline': {
          borderColor: theme.palette.background.paper,
        },
        '&:hover$focused $notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
      },
      focused: {},
      notchedOutline: {
        borderColor: theme.palette.background.paper,
      },
      input: {
        fontSize: theme.spacing(1.75), // 14px

        '&::placeholder': {
          color: '#fff',
          opacity: 0.8,
        },
      },
      closeRoot: {
        marginRight: 0,
      },
      closeButtonRoot: {
        fontSize: theme.spacing(1.5), // 12px
        marginRight: theme.spacing(-1.375), // -11px
      },
    }),
);

import { TStoreState } from 'store';
import { initialCatalogState } from 'store/modules/catalog';
import { initialOrderState } from 'store/modules/order';
import { initialOrderDetailsState } from 'store/modules/orderDetails';
import { initialAuthState } from 'store/modules/auth';
import { initialCategoryState } from 'store/modules/category';
import { initialDialogState } from 'store/modules/dialog';
import { initialNotifyState } from 'store/modules/notify';
import { initialOrderDiscountsState } from 'store/modules/orderDiscounts';
import { initialPrepareToPlaceOrderState } from 'store/modules/prepareToPlaceOrder';
import { initialPromotionsState } from 'store/modules/promotions';
import { initialShoppingFlowState } from 'store/modules/shoppingFlow';
import { initialStoreProductState } from 'store/modules/storeProduct';
import { initialWebsiteState } from 'store/modules/website';
import { initialUserState } from 'store/modules/user';
import { initialConfigState } from 'store/modules/config';
import { initialUiStylesState } from 'store/modules/uiStyles';
import { initialRouterHistoryState } from 'store/modules/routerHistory';

export const initialAppState: TStoreState = {
  activeOrder: {
    order: initialOrderState,
    orderDetails: initialOrderDetailsState,
    orderDiscounts: initialOrderDiscountsState,
    prepareToPlaceOrder: initialPrepareToPlaceOrderState,
  },
  category: initialCategoryState,
  storeProduct: initialStoreProductState,
  promotions: initialPromotionsState,
  notify: initialNotifyState,
  shoppingFlow: initialShoppingFlowState,
  auth: initialAuthState,
  dialog: initialDialogState,
  user: initialUserState,
  store: initialWebsiteState,
  config: initialConfigState,
  uiStyles: initialUiStylesState,
  routerHistory: initialRouterHistoryState,
  catalog: initialCatalogState,
};

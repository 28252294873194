import React, { useCallback } from 'react';

import FreeProductCard from 'components/common/FreeProductCard';
import { TDiscountForEditOrder } from 'types';
import { TOrderDiscount } from 'store';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { prepareMultiLangObject } from 'utils/helpers/storeProduct';
import { Divider } from '@material-ui/core';
import { TFilterFreeProductsDiscounts, TRenderFreeProducts, TUseFreeProducts } from './types';
import useStyles from './styles';

const useFreeProducts: TUseFreeProducts = () => {
  const classes = useStyles();
  const websiteDetails = useWebsiteDetails();
  const filterFreeProductsDiscounts = useCallback<TFilterFreeProductsDiscounts>((discounts) => {
    const freeProductsDiscounts: (TDiscountForEditOrder | TOrderDiscount)[] = [];
    discounts?.forEach((discount) => {
      if (discount.discountType.name === 'itemForFree') {
        freeProductsDiscounts.push(discount);
      }
    });
    return freeProductsDiscounts;
  }, []);

  const renderFreeProducts = useCallback<TRenderFreeProducts>(
    ({
      freeProductsDiscounts,
      showPricePerUnit = false,
      expandedLayout = false,
      showFreeProductsBasedOnSupply = 'default',
    }) => {
      return freeProductsDiscounts.map((clientCoupon, index) => {
        const isClientCoupon = clientCoupon.discountSourceType.name === 'clientCoupon';

        if (
          !clientCoupon.storeProductSource ||
          !clientCoupon.storeProductSource.productSellingUnits
        ) {
          return;
        }

        const isCouponSupplied = (clientCoupon as TDiscountForEditOrder).actualValue;

        if (
          (showFreeProductsBasedOnSupply === 'supplied' && !isCouponSupplied) ||
          (showFreeProductsBasedOnSupply === 'notSupplied' && isCouponSupplied)
        ) {
          return <></>;
        }

        // avoid duplicated keys in case we have multiple instances of the same product
        const key = index.toString() + clientCoupon.storeProductSource.id;

        const storeProductToDisplay = {
          ...clientCoupon.storeProductSource,
          imageUrl: clientCoupon.storeProductSource.imageUrl
            ? `${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${clientCoupon.storeProductSource.imageUrl}`
            : websiteDetails?.genericProductsDefaultImagePath,
          sellingUnit: prepareMultiLangObject(
            clientCoupon.storeProductSource.productSellingUnits[0].sellingUnit,
          ),
        };

        return (
          <>
            {!!index && <Divider className={classes.divider} />}
            <FreeProductCard
              key={key}
              quantity={Number(clientCoupon.valueFormulaSourceQuantity)}
              isClientCoupon={isClientCoupon}
              storeProductDisplay={storeProductToDisplay}
              showPricePerUnit={showPricePerUnit}
              expandedLayout={expandedLayout}
            />
          </>
        );
      });
    },
    [classes.divider, websiteDetails?.genericProductsDefaultImagePath],
  );

  return {
    renderFreeProducts,
    filterFreeProductsDiscounts,
  };
};

export default useFreeProducts;

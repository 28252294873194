import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(2 / 8),
      color: theme.palette.primary.main,
      fontSize: theme.spacing(16 / 8),
    },
    dataPickerRoot: {
      '& .MuiPickersToolbarText-toolbarBtnSelected': {
        color: theme.palette.background.paper,
        fontFamily: 'Heebo, "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: 1,
      },
      '& .MuiPickersDay-day:not(.MuiPickersDay-daySelected)': {
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.light} !important`,
        },
      },
      '& .MuiPickersDay-daySelected': {
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
      },
      '& button': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
);

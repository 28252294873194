import React, { FC, ReactNode, useMemo } from 'react';

import { merge } from 'lodash';

import { Box, Button, useTheme } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { BeatLoader } from 'react-spinners';
import useStyles from './styles';

import { ICTAButton } from './types';

const CTAButton: FC<ICTAButton> = ({
  variant = 'contained',
  size = 'large',
  onClick,
  children,
  isTranslate = true,
  classes: overrideClasses,
  loading,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  const classes = useStyles({ variant, loading });

  const theme = useTheme();

  const renderChildren = useMemo<ReactNode>(() => {
    return typeof children === 'string' && isTranslate ? t(children) : children;
  }, [children, t, isTranslate]);

  const beatLoaderColor = useMemo(() => {
    if (variant === 'outlined') {
      return theme.palette.primary.main;
    }
    return theme.palette.primary.light;
  }, [theme.palette.primary.light, theme.palette.primary.main, variant]);

  return (
    <Button
      classes={merge(
        {
          root: classes.root,
          sizeSmall: classes.rootSizeSmall,
          sizeLarge: classes.rootSizeLarge,
          contained: classes.rootContained,
          outlined: classes.rootOutlined,
          outlinedSizeSmall: classes.outlinedSizeSmall,
          outlinedSizeLarge: classes.outlinedSizeLarge,
        },
        overrideClasses,
      )}
      variant={variant}
      size={size}
      color="primary"
      onClick={onClick}
      disabled={disabled || loading}
      TouchRippleProps={{ classes: { child: classes.buttonRipple } }}
      {...rest}
    >
      {loading ? (
        <Box className={classes.loader}>
          <BeatLoader color={beatLoaderColor} size={10} />
        </Box>
      ) : (
        renderChildren
      )}
    </Button>
  );
};

export default CTAButton;

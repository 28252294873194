import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesGreenLayout } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesGreenLayout, { backgroundColor?: string }> =>
    createStyles({
      colorSection: ({ backgroundColor }) => ({
        background: `linear-gradient(180deg, ${theme.palette.primary.main} 140px,  ${
          backgroundColor || theme.palette.background.default
        } 140px)`,
        [theme.breakpoints.up('md')]: {
          background: `linear-gradient(180deg, ${theme.palette.primary.main} 210px,  ${
            backgroundColor || theme.palette.background.default
          } 210px)`,
        },
      }),
    }),
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from '@redux-saga/core/effects';
import i18next from 'i18next';
import Router from 'next/router';

import {
  authSelectors,
  CHECKOUT_FLOW_REQUEST,
  CONTINUE_TO_CART_REQUEST,
  CONTINUE_TO_CHECKOUT_REQUEST,
  DELIVERY_DIALOG,
  dialogActions,
  GENERIC_DIALOG,
  IRedirectAfterHourSelectionUpdate,
  IShowDialog,
  IStoreProduct,
  IWebsiteDetails,
  LOCATION_DIALOG,
  LOGIN_DIALOG,
  notifyActions,
  orderSelectors,
  prepareToPlaceOrderSelectors,
  SELF_PICKUP_DIALOG,
  SET_ORDER_ITEM_REQUEST,
  shoppingFlowSelectors,
  storeProductSelectors,
  TOrderDetailsReducerState,
  TOrderDiscount,
  TPrepareToPlaceOrderReducerState,
  userSelectors,
  websiteSelectors,
} from 'store';
import { setOrderItemsSuccessAction } from 'store/modules/order/actions';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { getOrderDiscounts } from 'store/modules/orderDiscounts/selectors';
import { redirectAfterHourSelectionUpdateAction } from 'store/modules/shoppingFlow/actions';
import { getRedirectAfterHourSelection } from 'store/modules/shoppingFlow/selectors';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import { TOrderItemDTO } from 'types';

import { getCartEstimation, shouldOrderHandlingFeeBeCharged } from 'utils/helpers/cartEstimation';
import { IIsShowLocationUpdate } from './types';

function* checkLocationAfterItemAdded(): Generator<
  SelectEffect | PutEffect<IIsShowLocationUpdate> | PutEffect<IShowDialog>,
  void,
  any
> {
  const isLoggedIn: boolean = yield select(authSelectors.isLoggedIn);
  const currentOrderItems: TOrderItemDTO[] = yield select(orderSelectors.orderItems);
  const isShowLocation: boolean = yield select(shoppingFlowSelectors.getIsShowLocation);
  const websiteDetails: IWebsiteDetails = yield select(websiteSelectors.getWebsiteDetails);
  const knownAddress = yield select(shoppingFlowSelectors.getKnownAddress);

  if (websiteDetails.websiteSettings.isSmallEC || isLoggedIn) return;
  if (
    (!isShowLocation || (websiteDetails.store.hasOnlineBranches && !knownAddress)) &&
    currentOrderItems.length === 0 &&
    (websiteDetails.websiteSettings.displayDeliveringToAddressPopupOnload ||
      websiteDetails.store.hasOnlineBranches)
  ) {
    // show location dialog
    yield put(
      dialogActions.showDialog({
        dialogType: LOCATION_DIALOG,
        dialogProps: {
          hideCloseButton: !websiteDetails.websiteSettings.allowClosingDeliveringToAddressPopup,
          backdropClick: !websiteDetails.websiteSettings.allowClosingDeliveringToAddressPopup,
        },
      }),
    );
  }
}

function* continueToCart(): Generator<
  CallEffect | PutEffect<IRedirectAfterHourSelectionUpdate>,
  void,
  any
> {
  yield call(Router.push, '/cart');
}

function* continueToCheckout(): Generator<SelectEffect | CallEffect | PutEffect, void, any> {
  const orderItems: TOrderItemDTO[] = yield select(orderSelectors.orderItems);
  const { storeProductById, outOfStockStoreProductById } = yield select(
    storeProductSelectors.storeProductsData,
  );

  let productCommentsValid = true;

  // validate product comments
  const newOrderItems = orderItems.map((orderItem) => {
    const storeProduct: IStoreProduct =
      storeProductById[orderItem.storeProduct.id] ||
      outOfStockStoreProductById[orderItem.storeProduct.id];
    if (storeProduct.commentType) {
      if (!orderItem.productComment) {
        productCommentsValid = false;
        return { ...orderItem, productCommentValidationFailed: true };
      }
    }

    return orderItem;
  });

  if (!productCommentsValid) {
    yield put(
      notifyActions.showNotification({
        message: 'validation.chooseHowToPrepareItems',
      }),
    );

    yield put(setOrderItemsSuccessAction(newOrderItems));
    return;
  }

  yield put(redirectAfterHourSelectionUpdateAction(true));
  yield call(checkoutFlow);
}

// TODO rename to something like preCheckoutRequirements
// TODO (bigger) popups need to be promises, and then we can simply chain these and lose the redirectAfterHourSelectionUpdateAction flag
function* checkoutFlow(): Generator<
  SelectEffect | PutEffect<IShowDialog> | CallEffect,
  boolean,
  any
> {
  const isLoggedIn: boolean = yield select(authSelectors.isLoggedIn);

  if (!isLoggedIn) {
    yield put(
      dialogActions.showDialog({
        dialogType: LOGIN_DIALOG,
      }),
    );
    return false;
  }

  const isMinimumProfileDataCompleted: boolean = yield select(
    userSelectors.isMinimumProfileDataCompleted,
  );
  const isProfileCompleted: boolean = yield select(userSelectors.isProfileCompleted);
  const knownAddress: string = yield select(shoppingFlowSelectors.getKnownAddress);
  const orderDetails: TOrderDetailsReducerState = yield select(getOrderDetails);
  const websiteDetails: IWebsiteDetails = yield select(getWebsiteDetails);

  if (!isMinimumProfileDataCompleted) {
    let dialogToDisplay = websiteDetails.websiteSettings.defaultOrderType;

    if (knownAddress) {
      dialogToDisplay = 'delivery';
    }

    if (orderDetails.selfPickupLocation && orderDetails.selfPickupLocation.name) {
      dialogToDisplay = 'selfPickup';
    }

    if (dialogToDisplay === 'delivery') {
      yield put(
        dialogActions.showDialog({
          dialogType: DELIVERY_DIALOG,
          contentProps: {
            initialView: 'createNewDeliveryAddress',
          },
        }),
      );
    } else {
      yield put(
        dialogActions.showDialog({
          dialogType: SELF_PICKUP_DIALOG,
        }),
      );
    }
    return false;
  }

  const isPrepareToPlaceOrderCompleted: boolean = yield select(
    prepareToPlaceOrderSelectors.isPrepareToPlaceOrderCompleted,
  );

  const prepareToPlaceOrder: TPrepareToPlaceOrderReducerState = yield select(
    prepareToPlaceOrderSelectors.getPrepareToPlaceOrder,
  );

  if (!isPrepareToPlaceOrderCompleted) {
    if (orderDetails.orderType === 'selfPickup') {
      yield put(
        dialogActions.showDialog({
          dialogType: SELF_PICKUP_DIALOG,
        }),
      );
    } else {
      yield put(
        dialogActions.showDialog({
          dialogType: DELIVERY_DIALOG,
          contentProps: {
            initialView: isProfileCompleted ? 'deliveryHours' : 'createNewDeliveryAddress',
          },
        }),
      );
    }
    return false;
  }

  const order = yield select(orderSelectors.orderData);
  const orderDiscounts: TOrderDiscount[] = yield select(getOrderDiscounts);

  const itemsEstimation = getCartEstimation(
    order,
    orderDiscounts.filter(
      (discount) =>
        discount.discountType.name !== 'serviceAreaUsageFeesDiscount' &&
        !discount.creditedAtCheckout,
    ),
    0,
  );
  const minimumOrderPassed = prepareToPlaceOrder.orderMinTotalValue <= itemsEstimation;

  if (!minimumOrderPassed) {
    const body =
      prepareToPlaceOrder.serviceAreaUsageFees === 0
        ? i18next.t('dialog.orderMinimum.bodyIfNoDeliveryFee', {
            orderMinimum: prepareToPlaceOrder.orderMinTotalValue,
          })
        : i18next.t(`dialog.orderMinimum.body.${orderDetails.orderType}`, {
            orderMinimum: prepareToPlaceOrder.orderMinTotalValue,
          });

    yield put(
      dialogActions.showDialog({
        dialogType: GENERIC_DIALOG,
        contentProps: {
          title: 'dialog.orderMinimum.title',
          body,
          buttons: [
            {
              text: 'dialog.orderMinimum.okButton',
              variant: 'contained',
              closeButton: true,
            },
          ],
        },
      }),
    );
    return false;
  }

  if (
    shouldOrderHandlingFeeBeCharged(
      itemsEstimation,
      prepareToPlaceOrder.orderMinTotalValue,
      prepareToPlaceOrder.orderHandlingFeeThreshold,
    )
  ) {
    yield put(
      dialogActions.showDialog({
        dialogType: GENERIC_DIALOG,
        contentProps: {
          title: 'dialog.handlingFee.title',
          body: i18next.t('dialog.handlingFee.body', {
            orderHandlingFeeThreshold: prepareToPlaceOrder.orderHandlingFeeThreshold,
            orderHandlingFee: prepareToPlaceOrder.orderHandlingFee,
          }),
          buttons: [
            {
              text: 'dialog.handlingFee.okButton',
              variant: 'contained',
              closeButton: true,
              onClick: () => Router.push('/checkout/payment'),
            },
            {
              text: 'dialog.handlingFee.cancelButton',
              variant: 'outlined',
              closeButton: true,
              onClick: () => Router.push('/'),
            },
          ],
        },
      }),
    );
    return false;
  }

  const redirectAfterHourSelection = yield select(getRedirectAfterHourSelection);

  if (redirectAfterHourSelection) {
    yield call(Router.push, '/checkout/payment');
  }

  return true;
}

function* rootShoppingFlowSaga(): Generator {
  yield takeEvery(SET_ORDER_ITEM_REQUEST, checkLocationAfterItemAdded);
  yield takeEvery(CONTINUE_TO_CART_REQUEST, continueToCart);
  yield takeEvery(CONTINUE_TO_CHECKOUT_REQUEST, continueToCheckout);
  yield takeEvery(CHECKOUT_FLOW_REQUEST, checkoutFlow);
}

export default rootShoppingFlowSaga;

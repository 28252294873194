/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from '@redux-saga/core/effects';

import Router from 'next/router';

import {
  authActions,
  authSelectors,
  catalogActions,
  ICheckAndFetchBranchCatalogIfNecessaryRequest,
  IGetClubMembershipProfileRequest,
  IUpdateMembershipBenefits,
  promotionsActions,
} from 'store';
import { updateMembershipBenefits } from 'store/modules/orderDiscounts/actions';

import { generateDeviceId } from 'utils';
import { DEVICE_ID_GENERATE, LOG_OUT_REQUEST } from './constants';

import { IDeviceIdUpdate, ILogOutSuccess } from './types';

function* generateDeviceIdSaga(): Generator<
  SelectEffect | PutEffect<IDeviceIdUpdate> | CallEffect,
  void,
  any
> {
  const existingDeviceId = yield select(authSelectors.getDeviceId);

  if (!existingDeviceId) {
    const deviceId = yield call(generateDeviceId);

    yield put(authActions.deviceIdUpdateAction(deviceId));
  }
}

function* logOut(): Generator<
  | PutEffect<
      | ILogOutSuccess
      | ICheckAndFetchBranchCatalogIfNecessaryRequest
      | IGetClubMembershipProfileRequest
      | IUpdateMembershipBenefits
    >
  | CallEffect,
  void,
  any
> {
  /**
   * remove jweToken in auth reducer
   * reset user reducer
   * reset prepareToPlaceOrder reducer
   * reset shoppingFlow reducer
   */
  yield put(authActions.logOutSuccess());
  yield call(Router.push, '/');
  yield put(catalogActions.checkAndFetchBranchCatalogIfNecessary());
  yield put(promotionsActions.getClubMembershipProfileSuccessAction([]));
  yield put(updateMembershipBenefits(undefined));
}

function* rootAuthSaga(): Generator {
  yield takeEvery(DEVICE_ID_GENERATE, generateDeviceIdSaga);
  yield takeEvery(LOG_OUT_REQUEST, logOut);
}

export default rootAuthSaga;

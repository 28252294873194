import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesEmailVerificationDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesEmailVerificationDialog> =>
    createStyles({
      root: {
        padding: theme.spacing(30 / 8, 20 / 8, 0),

        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(40 / 8, 70 / 8, 70 / 8),
        },
      },
      logo: {
        width: theme.spacing(130 / 8),
        height: 'auto',
        margin: '0 auto',
      },
      title: {
        marginTop: theme.spacing(20 / 8),
      },
      titleMain: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(1.25), // 10px
        },
      },
      subTitle: {
        letterSpacing: -0.2,
      },
      rootButton: {
        maxWidth: theme.spacing(320 / 8),
        margin: '0 auto',
        marginTop: theme.spacing(2.75), // 22px
      },
    }),
);

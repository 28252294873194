import { GOOGLE_MAP_INITIAL_POSITION } from 'ui/common/Map';
import { TUserReducerState } from './types';

export const initialUserState: TUserReducerState = {
  profile: {
    firstName: '',
    lastName: '',
    email: '',
    cellPhone: '',
    apartment: '',
    floor: '',
    doorCode: '',
    fullAddress: '',
    addressNotes: '',
    address: {
      city: {
        id: 0,
        name: '',
        location: { lat: 1, lng: 1 },
        governmentPlaceId: 0,
        settlementType: {
          name: 'city',
        },
      },
      cityAndCountry: '',
      address: '',
      coordinates: GOOGLE_MAP_INITIAL_POSITION,
    },
    entrance: '',
    emailVerified: false,
    unauthorizedPendingEmail: '',
  },
  detailsAtStoreLevel: undefined,
  clubMembership: false,
};

import { PLACE_ORDER_SUCCESS } from 'store/modules/order';
import { TStoreProductReducer } from './types';

import {
  GET_STORE_PRODUCTS_SUCCESS,
  UPDATE_OUT_OF_STOCK_PRODUCTS,
  UPDATE_PRODUCTS_BY_ID_SUCCESS,
} from './constants';

import { initialStoreProductState } from './initialState';

const storeProductReducer: TStoreProductReducer = (state = initialStoreProductState, action) => {
  switch (action.type) {
    case UPDATE_OUT_OF_STOCK_PRODUCTS:
    case UPDATE_PRODUCTS_BY_ID_SUCCESS:
    case GET_STORE_PRODUCTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        outOfStockStoreProductById: {},
        outOfStockStoreProductIds: [],
      };
    default:
      return state;
  }
};

export { storeProductReducer };

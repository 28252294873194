import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesIcon } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesIcon> =>
    createStyles({
      colorAction: {
        color: theme.palette.background.paper,
      },
      fontSizeSmall: {
        fontSize: theme.spacing(2), // 16px
      },
    }),
);

import FingerprintJS from '@fingerprintjs/fingerprintjs';

/* eslint-disable no-bitwise */
const fallbackDeviceId = (): string => {
  return `${[1e7]}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/[018]/g, (ch) => {
    const c = Number(ch);
    return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
  });
};

export const generateDeviceId = async (): Promise<string> => {
  try {
    const fp = await FingerprintJS.load();
    const result = await fp.get();

    return result.visitorId;
  } catch (err) {
    return fallbackDeviceId();
  }
};

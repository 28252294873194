import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesMobileCategoryMenu } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMobileCategoryMenu> =>
    createStyles({
      root: {
        minHeight: theme.spacing(5), // '40px',
        marginLeft: theme.spacing(48 / 8),
      },
      searchRoot: {
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translate( 0, -50%)',
        padding: theme.spacing(0, 1.75), // '0 14px'
        display: 'flex',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      menuItem: {
        fontSize: theme.spacing(1.75), // '14px'
        lineHeight: `${theme.spacing(2.25)}px`, // '18px'
        fontWeight: 'normal',
        alignItems: 'flex-start',
        color: theme.palette.text.secondary,
        minHeight: theme.spacing(5), // '40px',
        padding: theme.spacing(1.25, 0, 0),
        minWidth: 'auto',
        opacity: 1,
        whiteSpace: 'nowrap',
        margin: theme.spacing(0, 1),
        textTransform: 'initial',
      },
      labelText: {
        letterSpacing: -0.2,
      },
      menuItemSelected: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        textTransform: 'initial',
      },
      menuItemSelectedLabel: {
        position: 'relative',

        '&::after': {
          marginTop: theme.spacing(1.125), // '9px'
          width: '100%',
          content: '""',
          display: 'block',
          borderBottom: `2.5px solid ${theme.palette.primary.main}`,
        },
      },
      linkRoot: {
        // disable padding on first item
        '&:first-child $menuItem': {
          padding: theme.spacing(1.25, 0, 0),
        },
        '&:last-child $menuItem': {
          padding: theme.spacing(1.25, 0, 0),
        },
      },
    }),
);

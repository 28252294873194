import { Box } from '@material-ui/core';
import classNames from 'classnames';
import SkeletonMainLayoutBackgroundImage from 'components/common/skeletons/SkeletonMainLayoutBackgroundImage';

import { useFooter, useHeader, useMobile, useWebsiteDetails } from 'hooks';
import { useContentPages } from 'hooks/useContentPages';
import useHomePageContent from 'hooks/useHomePageContent';
import { useMainLayout } from 'hooks/useMainLayout';
import { useMultiLanguage } from 'hooks/useMultiLanguage';
import i18next from 'i18next';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configActions } from 'store';
import { getOrderMode } from 'store/modules/orderDetails/selectors';
import { getContentPagePreviewMode } from 'store/modules/uiStyles/selectors';
import CTAButton from 'ui/common/buttons/CTAButton';
import OrderModeIndicator from 'ui/common/OrderModeIndicator';
import PreviewContentPagesIndicator from 'ui/common/PreviewContentPagesIndicator/PreviewContentPagesIndicator';
import Typography from 'ui/common/Typography';
import DesktopFooter from 'ui/desktop/Footer';

import DesktopHeader from 'ui/desktop/Header';
import LinksHeader from 'ui/desktop/LinksHeader';
import MobileFooter from 'ui/mobile/Footer';
import MobileHeader from 'ui/mobile/Header';
import HeaderBigInfo from './components/HeaderBigInfo';
import StickyHeader from './components/StickyHeader';

import useStyles from './styles';

import { IMainLayout, TRenderHeader } from './types';

const MainLayout: FC<IMainLayout> = (props) => {
  const {
    headerVariant,
    footerVariant,
    showBigHeader,
    isHideFooter,
    children,
    showSearchSection,
    showAccessibilityButtons,
  } = props;

  const websiteDetails = useWebsiteDetails();
  const orderMode = useSelector(getOrderMode);
  const isActiveContentPagePreviewMode = useSelector(getContentPagePreviewMode);

  const { isMobile } = useMobile();

  const { onMainHeaderObserve, onLoadBackgroundImage, getBackgroundImage } = useMainLayout();

  const { relevantLanguages } = useMultiLanguage();

  const dispatch = useDispatch();

  const { homePageId } = useHomePageContent();

  const { createContentPagesList } = useContentPages();

  // header logic
  const { getDesktopHeaderProps, getMobileHeaderProps } = useHeader();

  // footer logic
  const { getDesktopFooterProps, getMobileFooterProps } = useFooter();

  const contentPages = createContentPagesList(false, true);

  const [loadingBackgroundImage, setLoadingBackgroundImage] = useState<boolean>(!!showBigHeader);

  const classes = useStyles({
    loadingBackgroundImage,
    isMobile,
    orderMode,
    showBigHeader,
    ...props,
  });

  const showHeaderWithLinks = useMemo(() => {
    return !!homePageId && !!contentPages.length && headerVariant !== 'checkout';
  }, [contentPages.length, headerVariant, homePageId]);

  const renderHeader = useMemo<TRenderHeader>(
    () => (mainHeaderProps) => {
      return (
        <>
          {isMobile ? (
            <MobileHeader {...getMobileHeaderProps(headerVariant)} {...mainHeaderProps} />
          ) : (
            <DesktopHeader {...getDesktopHeaderProps(headerVariant)} {...mainHeaderProps} />
          )}
        </>
      );
    },
    [getDesktopHeaderProps, headerVariant, getMobileHeaderProps, isMobile],
  );

  const focusSelectedElement = useCallback((elementId: string, scrollTopValue: number = 0) => {
    window.location.href = `#${elementId}`;

    const element = document.getElementById(elementId);

    if (element) {
      element.focus();

      window.scrollTo({
        top: element.offsetTop + scrollTopValue,
        behavior: 'smooth',
      });
    }
  }, []);

  const accessibilityButtons = useMemo(() => {
    if (showAccessibilityButtons) {
      return (
        <>
          <Box component="nav" className={classes.accessibilityTooltips}>
            <CTAButton
              className={classes.tooltipLink}
              onClick={() => focusSelectedElement('categoryMenu')}
            >
              <Typography className={classes.linkTitle}>{'header.skipToTheCategory'}</Typography>
            </CTAButton>
            <CTAButton
              className={classes.tooltipLink}
              onClick={() => focusSelectedElement('catalog', -150)}
            >
              <Typography className={classes.linkTitle}>{'header.skipToTheMainContent'}</Typography>
            </CTAButton>
            <CTAButton
              className={classes.tooltipLink}
              onClick={() => focusSelectedElement('footer')}
            >
              <Typography className={classes.linkTitle}>{'header.skipToTheFooter'}</Typography>
            </CTAButton>
          </Box>
          <Box className={classes.backgroundForTooltip} />
        </>
      );
    }
  }, [
    classes.accessibilityTooltips,
    classes.backgroundForTooltip,
    classes.linkTitle,
    classes.tooltipLink,
    focusSelectedElement,
    showAccessibilityButtons,
  ]);

  const topHeaders = useMemo(() => {
    if (isMobile)
      return (
        <StickyHeader ariaHidden={showBigHeader}>
          {orderMode !== 'new' && <OrderModeIndicator orderMode={orderMode} />}
          {renderHeader({ showSearchSection })}
        </StickyHeader>
      );

    return (
      <>
        {isActiveContentPagePreviewMode && <PreviewContentPagesIndicator />}
        {orderMode !== 'new' && (
          <Box
            style={{
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 15,
              boxShadow: 'none',
              paddingLeft: '0px !important',
              height: '40px',
            }}
          >
            <OrderModeIndicator orderMode={orderMode} />
          </Box>
        )}
        {showHeaderWithLinks && <LinksHeader />}
        <StickyHeader ariaHidden={showBigHeader}>
          {renderHeader({ showSearchSection })}
        </StickyHeader>
      </>
    );
  }, [
    isActiveContentPagePreviewMode,
    isMobile,
    orderMode,
    renderHeader,
    showBigHeader,
    showHeaderWithLinks,
    showSearchSection,
  ]);

  useEffect(() => {
    if (getBackgroundImage(true)) {
      onLoadBackgroundImage(() => setLoadingBackgroundImage(false), true);
    }
  }, [getBackgroundImage, onLoadBackgroundImage]);

  useEffect(() => {
    const active =
      relevantLanguages.filter((language) => language.shortName === i18next.language)[0] ||
      relevantLanguages[0];
    dispatch(configActions.updateLanguageConfigAction(active.shortName));
  }, [dispatch, relevantLanguages]);

  useEffect(() => {
    if (showBigHeader && !loadingBackgroundImage) {
      onMainHeaderObserve();
    }
  }, [loadingBackgroundImage, showBigHeader, onMainHeaderObserve]);

  return (
    <>
      {accessibilityButtons}
      <Box className={classes.root}>
        {!loadingBackgroundImage && topHeaders}
        <Box component="main">
          {showBigHeader && (
            <Box
              component="section"
              className={classNames(classes.topBlockWrapper, 'mui-fixed')}
              id="mainHeader"
            >
              {orderMode !== 'new' && <OrderModeIndicator orderMode={orderMode} />}
              <Box id="bg-img" className={classes.topBlock}>
                {loadingBackgroundImage ? (
                  <SkeletonMainLayoutBackgroundImage />
                ) : (
                  <>
                    {renderHeader({
                      isTransparent: true,
                      isHideLogo: true,
                      websiteDetails,
                      imageUrl: '',
                      showSearchSection,
                    })}
                    <HeaderBigInfo
                      storeName={websiteDetails.name}
                      imageUrl={websiteDetails.logoPath}
                    />
                  </>
                )}
              </Box>
            </Box>
          )}
          {/* TODO after rewriting greenLayout styles we can always use the mainBlock */}
          {showBigHeader ? (
            <Box component="section" className={classes.mainBlock}>
              {children}
            </Box>
          ) : (
            children
          )}
        </Box>
        {!isHideFooter && (
          <Box component="footer" id="footer" zIndex={5}>
            {isMobile ? (
              <MobileFooter {...getMobileFooterProps(footerVariant)} />
            ) : (
              <DesktopFooter {...getDesktopFooterProps(footerVariant)} />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default MainLayout;

import React, {
  createRef,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, List, ListItem, Popover } from '@material-ui/core';
import Icon from 'ui/common/icons/Icon';
import Link from 'ui/common/Link';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import { useCategoryMenu } from 'hooks';
import useStyles from './styles';

import { ICategoryMenu, TCategoryMenuItem, TCategoryMenuItemWithRefs } from './types';

const CategoryMenu: FC<ICategoryMenu> = ({ menuItems, activeItem }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mainLayoutContext = useContext(MainLayoutContext);
  const container = useRef<HTMLDivElement>(null);
  const { areSiblingCategories } = useCategoryMenu();

  const [visibleMenuItems, setVisibleMenuItems] = useState<TCategoryMenuItemWithRefs[]>();
  const [currentlyOpenSubmenu, setCurrentlyOpenSubmenu] = useState<TCategoryMenuItemWithRefs>();

  const menuItemsWithRefs = useMemo<TCategoryMenuItemWithRefs[]>(() => {
    setVisibleMenuItems(undefined);

    const result: TCategoryMenuItemWithRefs[] = [];

    for (let i = 0; i < menuItems.length; i += 1) {
      result.push({
        ...menuItems[i],
        ref: createRef(),
        subMenuRef: menuItems[i].subMenuItems ? createRef() : undefined,
      });
    }

    return result;
  }, [menuItems]);

  const closeSubMenu = useCallback(() => {
    setCurrentlyOpenSubmenu(undefined);
  }, []);

  const openSubmenu = useCallback((menuItem: TCategoryMenuItemWithRefs) => {
    setCurrentlyOpenSubmenu(menuItem);
  }, []);

  // need to calculate how many menu items we can show and how many we need to hide in dropdown
  // TODO after fonts load - calculate again
  const calculateMenuItemsCount = useCallback(() => {
    const graceSpace = 15;
    // width of container
    const containerWidth = container.current?.offsetWidth || 0;
    // scroll width of container (with scroll)
    const scrollContainerWidth = container.current?.scrollWidth || 0;
    // width of more button menu item
    const moreButtonWidth = 70;
    const availableWidthForCategories = containerWidth - moreButtonWidth - graceSpace;

    if (scrollContainerWidth > availableWidthForCategories) {
      // sum of menu items to show
      let totalItemsWidth = 0;
      // count of items to show (rest in popover)
      let count = 0;

      for (let i = 0; i < menuItemsWithRefs.length; i += 1) {
        const menuItemWidth = menuItemsWithRefs[i].ref.current?.getBoundingClientRect().width || 0;
        totalItemsWidth += menuItemWidth;
        if (totalItemsWidth >= availableWidthForCategories) {
          break;
        }

        count += 1;
      }

      const visibleItems = menuItemsWithRefs.slice(0, count);
      const excessiveItems = menuItemsWithRefs.slice(count);
      if (excessiveItems.length > 0) {
        visibleItems.push({
          id: 'more',
          text: t('categoryMenu.more'),
          link: {
            href: '/',
          },
          ref: createRef(),
          subMenuRef: createRef(),
          subMenuItems: excessiveItems.reduce<TCategoryMenuItem[]>(
            (flattened, item) => flattened.concat(item.subMenuItems ? item.subMenuItems : [item]),
            [],
          ),
          sortOrder: visibleItems[visibleItems.length - 1].sortOrder + 1,
        });
      }
      setVisibleMenuItems(visibleItems);
    }
  }, [menuItemsWithRefs, t]);

  useEffect(() => {
    calculateMenuItemsCount();
  }, [calculateMenuItemsCount]);

  const renderMenuItemWithoutSubItems = useCallback(
    (menuItem: TCategoryMenuItemWithRefs) => {
      const isSelected = menuItem.id === activeItem?.id;

      return (
        <Box key={menuItem.id}>
          <Button
            className={classes.menuItemRoot}
            onMouseEnter={closeSubMenu}
            onClick={mainLayoutContext.searchContext.handleClear}
            disableRipple
          >
            <Link {...menuItem.link} shallow={false} scroll={true}>
              <ListItem
                button
                component="div"
                classes={{ root: classes.listItem, selected: classes.selectedItem }}
                innerRef={menuItem.ref}
                selected={isSelected}
                disableRipple
              >
                {menuItem.text}
              </ListItem>
            </Link>
          </Button>
        </Box>
      );
    },
    [
      activeItem?.id,
      classes.listItem,
      classes.menuItemRoot,
      classes.selectedItem,
      closeSubMenu,
      mainLayoutContext.searchContext.handleClear,
    ],
  );

  const renderMenuItemWithSubItems = useCallback(
    (menuItem: TCategoryMenuItemWithRefs) => {
      const isChildSelected = menuItem.id === activeItem?.parent?.id;
      const isParentSelected = menuItem.id === activeItem?.id;

      const subMenuItemsCopy = menuItem.subMenuItems ? [...menuItem.subMenuItems] : [];
      const subItemsColumns = [];
      const numberOfCategoriesInOneColumn =
        menuItem.id === 'more' ? menuItem.subMenuItems?.length || 0 : 5;

      for (let i = 0; i < subMenuItemsCopy.length; i += numberOfCategoriesInOneColumn) {
        subItemsColumns.push(subMenuItemsCopy.slice(i, i + numberOfCategoriesInOneColumn));
      }
      const content = (
        <>
          <div
            role="button"
            ref={menuItem.subMenuRef}
            tabIndex={0}
            className={classes.menuItemRoot}
            onMouseEnter={() => openSubmenu(menuItem)}
            onMouseLeave={closeSubMenu}
            onKeyPress={() => openSubmenu(menuItem)}
          >
            <ListItem
              button
              component="div"
              classes={{
                root: classNames(classes.listItem, {
                  [classes.listItemHovered]: currentlyOpenSubmenu === menuItem,
                }),
                selected: classes.selectedItem,
              }}
              innerRef={menuItem.ref}
              selected={activeItem?.parent ? isChildSelected : isParentSelected}
              disableRipple
            >
              {menuItem.text}
              <Icon
                name="icon-arrow-down"
                classes={{ root: classes.arrowIcon }}
                color={currentlyOpenSubmenu === menuItem ? 'primary' : 'default'}
              />
            </ListItem>
          </div>
          <Popover
            transitionDuration={150}
            anchorEl={menuItem.subMenuRef?.current}
            keepMounted
            disableRestoreFocus
            open={currentlyOpenSubmenu === menuItem}
            onClose={closeSubMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              root: classes.popoverRoot,
              paper: classes.paper,
            }}
            className={classes.popover}
            PaperProps={{
              onMouseEnter: () => openSubmenu(menuItem),
              onMouseLeave: closeSubMenu,
            }}
          >
            <Box className={classes.menuList}>
              {subItemsColumns.map((subMenuItemsArray, i) => (
                <Box display="flex" key={i}>
                  {subMenuItemsArray.length && i !== 0 && (
                    <Divider className={classes.dividerForSubCategories} />
                  )}
                  <Box>
                    {subMenuItemsArray &&
                      subMenuItemsArray.map(({ id, text, link }) => {
                        const isSubmenuItemSelected = id === activeItem?.id;
                        return (
                          <Button
                            key={id}
                            className={classes.subMenuItem}
                            onClick={closeSubMenu}
                            disableTouchRipple
                          >
                            <Link
                              {...link}
                              shallow={areSiblingCategories(id, activeItem?.id)}
                              scroll={true}
                            >
                              <Box
                                className={`${classes.menuItem} ${
                                  isSubmenuItemSelected ? classes.menuItemSelected : ''
                                }`}
                              >
                                <Box className={classes.selectedContent}>{text}</Box>
                              </Box>
                            </Link>
                          </Button>
                        );
                      })}
                  </Box>
                </Box>
              ))}
            </Box>
          </Popover>
        </>
      );

      return menuItem.id === 'more' ? (
        <Box key={menuItem.id}>{content}</Box>
      ) : (
        <Link {...menuItem.link} key={menuItem.id}>
          {content}
        </Link>
      );
    },
    [
      activeItem?.parent,
      activeItem?.id,
      classes.menuItemRoot,
      classes.listItem,
      classes.listItemHovered,
      classes.selectedItem,
      classes.arrowIcon,
      classes.popover,
      classes.popoverRoot,
      classes.paper,
      classes.menuList,
      classes.dividerForSubCategories,
      classes.subMenuItem,
      classes.menuItem,
      classes.menuItemSelected,
      classes.selectedContent,
      closeSubMenu,
      currentlyOpenSubmenu,
      openSubmenu,
      areSiblingCategories,
    ],
  );

  const menuItemsRendered = useMemo(() => {
    // on first render visibleMenuItems is undefined (it needs to be calculated) so we use the entire "menuItemsWithRefs" list
    return (visibleMenuItems || menuItemsWithRefs).map((menuItem) => {
      return menuItem.subMenuItems
        ? renderMenuItemWithSubItems(menuItem)
        : renderMenuItemWithoutSubItems(menuItem);
    });
  }, [
    visibleMenuItems,
    menuItemsWithRefs,
    renderMenuItemWithSubItems,
    renderMenuItemWithoutSubItems,
  ]);

  return (
    <Box>
      <List component="nav" classes={{ root: classes.root }} innerRef={container} id="categoryMenu">
        {menuItemsRendered}
      </List>
    </Box>
  );
};

export default CategoryMenu;

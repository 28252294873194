import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesActivePreviousOrder } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesActivePreviousOrder> =>
    createStyles({
      cardWrapper: {
        backgroundColor: theme.palette.grey[50],
        margin: theme.spacing(16 / 8),
        minHeight: '120px',
        borderRadius: '8px',
        padding: theme.spacing(14 / 8, 16 / 8, 16 / 8),
      },
      cartTitle: {
        fontSize: '18px',
        marginBottom: theme.spacing(6 / 8),
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(4 / 8),
        },
      },
      btnsWrapper: {
        display: 'flex',
        justifyContent: 'start',
        marginTop: theme.spacing(12 / 8),
        maxWidth: '400px',
      },
      btnOutlinedBg: {
        color: theme.palette.grey.A700,
        backgroundColor: theme.palette.background.paper,
      },
      mainText: {
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
        },
      },
    }),
);

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSkeletonMainLayoutBackgroundImage } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSkeletonMainLayoutBackgroundImage> =>
    createStyles({
      logoSkeleton: {
        background: theme.palette.background.paper,
        boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.08);',
        width: '130px',
        height: '130px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          margin: '0',
          width: '160px',
          height: '160px',
        },
      },
      backgroundImageSkeleton: {
        background: theme.palette.grey[100],
        position: 'relative',
        zIndex: 1,
        width: '100%',
        height: '300px',
      },
      nameSkeleton: {
        width: '150px',
        height: '26px',
        margin: '0 auto 12px',
        [theme.breakpoints.up('md')]: {
          width: '182px',
          marginBottom: 0,
        },
      },
      skeletonsWrapper: {
        display: 'block',
        top: 56,
        width: '100%',
        marginTop: theme.spacing(4 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
          top: 70,
          display: 'flex',
        },
      },
      skeletons: {
        [theme.breakpoints.up('md')]: {
          width: '1240px',
          margin: '0 auto',
          display: 'flex',
        },
      },
      websiteDetailsSkeleton: {
        margin: '0 auto',
        width: '121px',
        height: '18px',
        [theme.breakpoints.up('md')]: {
          width: '151px',
          margin: '10px 0',
        },
      },
    }),
);

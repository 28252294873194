import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesLocationHoursDialog } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLocationHoursDialog> =>
    createStyles({
      root: {
        padding: theme.spacing(38 / 8, 24 / 8),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(70 / 8),
        },
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      image: {
        width: '78px',
        height: '72px',
      },
      title: {
        marginTop: theme.spacing(20 / 8),
      },
      titleMain: {
        fontSize: '26px',
        lineHeight: '28px',
        marginBottom: theme.spacing(10 / 8),
        [theme.breakpoints.up('md')]: {
          fontSize: '30px',
          lineHeight: '34px',
        },
      },
      searchRoot: {
        marginTop: theme.spacing(23 / 8),
      },
      rootButton: {
        display: 'flex',
        flexDirection: 'column-reverse',
        marginTop: theme.spacing(22 / 8),
      },
      footer: {
        marginTop: theme.spacing(20 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(22 / 8),
        },
      },
      footerItem: {
        marginBottom: theme.spacing(5 / 8),
        alignItems: 'flex-end',

        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(4 / 8),
        },
      },
      linkText: {
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
      loginBtn: {
        minWidth: '46px',
      },
    }),
);

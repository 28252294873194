import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { TStylesGenericDialog } from './types';

export const dialogDownBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesGenericDialog> =>
    createStyles({
      root: {
        padding: theme.spacing(8.75, 8.75), // 70px 70px

        [theme.breakpoints.down(dialogDownBreakpoint)]: {
          padding: theme.spacing(4.75, 3), // 38px 24px
        },
      },
      image: {
        width: theme.spacing(70 / 8),
        height: theme.spacing(70 / 8),
      },
      rootImage: {
        display: 'flex',
        justifyContent: 'center',
      },
      title: {
        marginTop: theme.spacing(22 / 8),

        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(20 / 8),
        },
      },
      titleMain: {
        fontSize: theme.spacing(26 / 8),
        lineHeight: `${theme.spacing(28 / 8)}px`,
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(30 / 8),
          lineHeight: `${theme.spacing(34 / 8)}px`,
        },
      },
      searchRoot: {
        marginTop: theme.spacing(2.875), // 23px
      },
      rootButton: {
        width: '100%',
        marginTop: theme.spacing(1.5), // 12px
        fontSize: '18px !important',
        lineHeight: '22px',
        fontWeight: 500,
        whiteSpace: 'initial',
      },
      footer: {
        marginTop: theme.spacing(2.75), // 22px

        [theme.breakpoints.down(dialogDownBreakpoint)]: {
          marginTop: theme.spacing(2.5), // 20px
        },
      },
      footerItem: {
        marginBottom: theme.spacing(0.5), // 4px

        [theme.breakpoints.down(dialogDownBreakpoint)]: {
          marginBottom: theme.spacing(0.625), // 5px
        },
      },
      linkText: {
        cursor: 'pointer',
      },
    }),
);

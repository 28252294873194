import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { IHeader, TStylesHeader } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesHeader,
    IHeader & {
      isLoggedIn?: boolean;
      isMinimumProfileDataCompleted?: boolean;
      userMenuSpace?: string;
    }
  > =>
    createStyles({
      wrapper: ({ isTransparent }) => {
        if (isTransparent) {
          return {
            backgroundColor: 'transparent',
          };
        }

        return {
          backgroundColor: theme.palette.secondary.main,
          width: '100%',
        };
      },
      contentWrp: {
        height: '80px',
        padding: theme.spacing(1.25, 0),
        backgroundColor: ({ isTransparent }) =>
          !isTransparent ? theme.palette.secondary.main : 'transparent',
      },
      logo: {
        borderRadius: '50%',
        width: '100%',
        height: 'auto',
      },
      labelSection: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      storeName: {
        fontSize: theme.spacing(3.75), // 30px
      },
      searchInput: {
        width: theme.spacing(37.5), // 300px
      },
      searchSection: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      itemsSection: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
      },
      logoWrapper: {
        width: '60px',
        marginRight: theme.spacing(15 / 8),
      },

      navigationBtn: ({ showLanguageSwitcher }) => ({
        padding: '0',
        minWidth: '156px',
        display: 'flex',
        marginRight: theme.spacing(showLanguageSwitcher ? 12 / 8 : 24 / 8),
        marginLeft: theme.spacing(showLanguageSwitcher ? 69 / 8 : 179 / 8),
        justifyContent: 'flex-end',
      }),
      userMenuBtn: ({ showLanguageSwitcher, userMenuSpace }) => ({
        padding: '0',
        minWidth: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        marginRight: userMenuSpace || theme.spacing(showLanguageSwitcher ? 12 / 8 : 24 / 8),
      }),
      deliverySectionBtn: ({ showLanguageSwitcher }) => ({
        display: 'flex',
        padding: '0',
        minWidth: 'fit-content',
        marginRight: theme.spacing(showLanguageSwitcher ? 12 / 8 : 24 / 8),
      }),
    }),
);

import React, { FC } from 'react';

import classNames from 'classnames';

import { Box, IconButton } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import { renderItemCount } from 'utils';
import { useTranslation } from 'react-i18next';
import Typography from '../../Typography';

import useStyles from './styles';

import { ICartUniqueItemsCount } from './types';

const CartUniqueItemsCount: FC<ICartUniqueItemsCount> = ({
  count,
  className,
  iconClasses,
  iconFontSize = 'large',
  color = 'primary',
  textColor = 'light',
  textFontSize = 12,
  onIconClick,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Box className={classNames(classes.root, className)}>
      <IconButton className={'unstyled-button'} onClick={onIconClick}>
        <Icon fontSize={iconFontSize} classes={iconClasses} name="icon-basket" color={color} />
      </IconButton>
      <Box className={classes.count}>
        <Typography
          color={textColor}
          variant="body2"
          fontSize={textFontSize}
          fontWeight="medium"
          aria-label={t('topCard.shoppingCartNItems', { items: count })}
        >
          {renderItemCount(count)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CartUniqueItemsCount;

import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Repeat from 'components/common/Repeat';
import { useMobile } from 'hooks/useMobile';

import { ISkeletonSimilarProductsSlider } from './types';
import useStyles from './styles';

const SkeletonSimilarProductsSlider: FC<ISkeletonSimilarProductsSlider> = () => {
  const classes = useStyles();
  const { isMobile } = useMobile();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.mainPart}>
        <Repeat times={isMobile ? 2 : 3}>
          <Skeleton variant="rect" className={classes.image} />
        </Repeat>
      </Box>
    </Box>
  );
};

export default SkeletonSimilarProductsSlider;

import React, { FC, useCallback, useState } from 'react';

import { Box, CardMedia, useTheme } from '@material-ui/core';
import { BeatLoader } from 'react-spinners';
import Typography from 'ui/common/Typography';

import CTAButton from 'ui/common/buttons/CTAButton';
import { useDialog, useMobile, useWebsiteDetails } from 'hooks';
import useStyles from './styles';

import { IPaymentByBitWasDeclined } from './types';

const PaymentByBitWasDeclined: FC<IPaymentByBitWasDeclined> = ({
  title,
  subtitle,
  body,
  buttons,
}) => {
  const classes = useStyles();
  const { hideDialog } = useDialog();
  const { isMobile } = useMobile();
  const websiteDetails = useWebsiteDetails();
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseButtonClicked = useCallback(
    (callback) => {
      if (callback) callback();
      hideDialog();
    },
    [hideDialog],
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.rootImage}>
        <CardMedia
          src={websiteDetails.theme.images.warning}
          component="img"
          className={classes.image}
          alt="Warning"
        />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h1" color="secondary" align="center" className={classes.titleMain}>
          {title}
        </Typography>
        <Typography variant="body1" color="grey" align="center" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Box mb={isMobile ? 10 / 8 : 12 / 8} mt={1}>
          <Typography variant="body1" fontSize={16} color="grey" align="center">
            {body}
          </Typography>
        </Box>
      </Box>
      <Box>
        {buttons &&
          buttons.map((button) => {
            return button.closeButton ? (
              <CTAButton
                key={button.text}
                variant={button.variant || 'contained'}
                onClick={() => handleCloseButtonClicked(button.onClick)}
                className={button.overrideClassName || classes.rootButton}
              >
                <Typography fontWeight="medium">{button.text}</Typography>
              </CTAButton>
            ) : (
              <CTAButton
                key={button.text}
                {...button}
                onClick={() => {
                  setLoading(true);
                  if (button.onClick) {
                    button.onClick();
                  }
                }}
                className={button.overrideClassName || classes.rootButton}
              >
                {button.showLoader && loading ? (
                  <Box className={classes.loader}>
                    <BeatLoader color={theme.palette.primary.light} size={10} />
                  </Box>
                ) : (
                  <Typography fontWeight="medium">{button.text}</Typography>
                )}
              </CTAButton>
            );
          })}
      </Box>
    </Box>
  );
};

export default PaymentByBitWasDeclined;

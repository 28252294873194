import React, { ReactNode, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import Rating from 'ui/common/Rating';

import { TUseRelevancy } from './types';

const useRelevancy: TUseRelevancy = (rating) => {
  const { t } = useTranslation();

  // need for render count of stars in rating
  const relevancyToNumberOfStars = useMemo<number>(() => {
    switch (rating) {
      case 'popular':
      case 'high':
        return 3;
      case 'medium':
        return 2;
      case 'low':
        return 1;
      default:
        return 0;
    }
  }, [rating]);

  // render stars block, if don't have start do not render
  const renderRelevancy = useMemo<ReactNode>(() => {
    if (!rating) {
      return null;
    }
    return <Rating value={relevancyToNumberOfStars} />;
  }, [relevancyToNumberOfStars, rating]);

  const relevancyDefaultText = useMemo(() => {
    switch (rating) {
      case 'popular':
        return t('storeProductCard.relevancy.popular');
      case 'high':
        return t('storeProductCard.relevancy.high');
      case 'medium':
        return t('storeProductCard.relevancy.medium');
      case 'low':
        return t('storeProductCard.relevancy.low');
      default:
        return '';
    }
  }, [rating, t]);

  return { renderRelevancy, relevancyDefaultText };
};

export default useRelevancy;

import useHomePageContent from 'hooks/useHomePageContent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { TUseSearch } from 'hooks/useSearch/types';
import { debounce, OutlinedInputProps } from '@material-ui/core';

// VERY IMPORTANT - this hook should never be used directly in components, it's used once in the context and exposed via the context
// TODO - move to MainLayoutProvider as a private function (private hook)
const useSearch: TUseSearch = () => {
  const router = useRouter();
  const { homePageId } = useHomePageContent();

  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const debouncedOnSearch = useMemo(
    () =>
      debounce(
        (newSearchQuery: string) => {
          setSearchQuery(newSearchQuery);

          if (newSearchQuery && homePageId && router.pathname === '/') {
            router.push(`/search/${newSearchQuery}`);
          }
        },
        homePageId && router.pathname === '/' ? 500 : 350,
      ),
    [homePageId, router],
  );

  const handleClear = useCallback(() => {
    setSearchInputValue('');
    debouncedOnSearch('');
  }, [debouncedOnSearch]);

  const handleChange = useCallback<Required<OutlinedInputProps>['onChange']>(
    (e) => {
      const { value: newSearchQuery } = e.target;
      setSearchInputValue(newSearchQuery);

      debouncedOnSearch(newSearchQuery);
    },
    [debouncedOnSearch],
  );

  const handleSearchUrl = useCallback((query) => {
    setSearchInputValue(query);
    setSearchQuery(query);
  }, []);

  useEffect(() => {
    const {
      query: { query },
    } = router;

    if (query) handleSearchUrl(query);
  }, [router, handleSearchUrl]);

  return {
    searchQuery,
    searchInputValue,
    handleChange,
    handleClear,
  };
};

export default useSearch;

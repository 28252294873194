import React, { FC } from 'react';

import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import Typography from '../Typography';
import Icon from '../icons/Icon';

import { IPaymentInfoSection } from './types';

import useStyles, { downBreakpoint } from './styles';

const PaymentInfoSection: FC<IPaymentInfoSection> = ({
  children,
  name = 'icon-lock',
  iconProps,
  ...rest
}) => {
  const classes = useStyles();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(downBreakpoint));

  return (
    <Box className={classes.root} {...rest}>
      <Icon name={name} classes={{ root: classes.iconRoot }} {...iconProps} />
      <Typography className={classes.typographyRoot} color="grey" fontSize={isMobile ? 13 : 14}>
        {children}
      </Typography>
    </Box>
  );
};

export default PaymentInfoSection;

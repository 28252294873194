import { useCategoryMenu } from 'hooks/useCategoryMenu';
import useProducts from 'hooks/useProducts';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TUseGeneratedMetadata } from './types';

const useGeneratedMetadata: TUseGeneratedMetadata = () => {
  const { t } = useTranslation();
  const websiteDetails = useWebsiteDetails();
  const { activeMenuItem } = useCategoryMenu();
  const { sortedProducts } = useProducts(9, activeMenuItem);
  const { categoriesByIdsIncludingParents } = useCategoryMenu();

  // category default metadata:
  const activeCategory = useMemo(() => {
    return categoriesByIdsIncludingParents[activeMenuItem?.id || -1];
  }, [activeMenuItem?.id, categoriesByIdsIncludingParents]);

  const categoryGeneratedDescription = useMemo<string>(() => {
    const websiteName = websiteDetails.name;
    const categoryName = activeCategory?.name || t('seo.products');

    return `${
      t('seo.genericCategoryMetadata', { websiteName, categoryName }) +
      sortedProducts
        ?.slice(0, 10)
        .map((product) => product.displayName)
        ?.join(', ')
    }, ${t('seo.andMore')}`;
  }, [activeCategory?.name, sortedProducts, t, websiteDetails.name]);

  return {
    categoryGeneratedDescription,
  };
};

export default useGeneratedMetadata;

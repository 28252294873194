import { Box } from '@material-ui/core';
import StoreService from 'api/services/StoreService/api';

import {
  useCart,
  useCategoryMenu,
  useDefaultMenuItems,
  useDialog,
  useMobile,
  useRenderDate,
  useSelfPickupAndDeliveryService,
  useWebsiteDetails,
} from 'hooks';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import {
  authActions,
  authSelectors,
  DELIVERY_DIALOG,
  LOAD_PREVIOUS_ORDERS_DIALOG,
  LOCATION_DIALOG,
  LOGIN_DIALOG,
  SELF_PICKUP_DIALOG,
  shoppingFlowSelectors,
  userSelectors,
} from 'store';

import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { redirectAfterHourSelectionUpdateAction } from 'store/modules/shoppingFlow/actions';
import { IHeader, THeaderVariant } from 'ui/desktop/Header';
import { dateFormatInOrderHistory, defaultDateFormat } from 'utils/constants';
import { TGetDesktopHeaderProps, TGetMobileHeaderProps, TUseHeader } from './types';

const useHeader: TUseHeader = () => {
  const { t } = useTranslation();
  const { defaultMenuItems } = useDefaultMenuItems();

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const knownAddress = useSelector(shoppingFlowSelectors.getKnownAddress);
  const orderDetails = useSelector(getOrderDetails);
  const profile = useSelector(userSelectors.getProfile);
  const clientAddress = useSelector(userSelectors.getClientAddress);
  const isMinimumProfileDataCompleted = useSelector(userSelectors.isMinimumProfileDataCompleted);
  const isProfileCompleted = useSelector(userSelectors.isProfileCompleted);

  const { showDialog } = useDialog();
  const { menuItems, activeMenuItem } = useCategoryMenu();
  const { uniqueItemCount } = useCart();
  const websiteDetails = useWebsiteDetails();
  const { renderDateAndWeekday } = useRenderDate();
  const { isMobile } = useMobile();

  const { prepareSelfPickupAreasAndShowDialog, showChangeOrderTypeNotPossibleInEditModeDialog } =
    useSelfPickupAndDeliveryService();

  const handleLogin = useCallback(() => {
    showDialog({
      dialogType: LOGIN_DIALOG,
    });
  }, [showDialog]);

  const handleLogOut = useCallback(() => {
    dispatch(authActions.logOutRequest());
  }, [dispatch]);

  // start user story 1
  const handleOpenLocation = useCallback(() => {
    if (websiteDetails.websiteSettings.onlySelfPickups) {
      StoreService.getStoreServiceAreas().then((actionResult) => {
        if (actionResult.data.selfPickupAreas) {
          prepareSelfPickupAreasAndShowDialog(actionResult.data.selfPickupAreas);
        }
      });
      return;
    }

    showDialog({
      dialogType: LOCATION_DIALOG,
    });
  }, [
    prepareSelfPickupAreasAndShowDialog,
    showDialog,
    websiteDetails.websiteSettings.onlySelfPickups,
  ]);

  const editDeliveryAddress = useCallback(() => {
    if (!isLoggedIn) {
      if (orderDetails.orderType === 'selfPickup') {
        StoreService.getStoreServiceAreas().then((actionResult) => {
          if (actionResult.data.selfPickupAreas) {
            prepareSelfPickupAreasAndShowDialog(actionResult.data.selfPickupAreas);
          }
        });
        return;
      }
      showDialog({
        dialogType: LOCATION_DIALOG,
      });
    }
  }, [prepareSelfPickupAreasAndShowDialog, isLoggedIn, orderDetails.orderType, showDialog]);

  const handleDeliverySelfPickupClick = useCallback(() => {
    switch (orderDetails.orderMode) {
      case 'new':
      default:
        dispatch(redirectAfterHourSelectionUpdateAction(false));
        if (orderDetails.orderType === 'selfPickup') {
          showDialog({
            dialogType: SELF_PICKUP_DIALOG,
            contentProps: {
              initialServiceArea: null,
            },
          });
        } else {
          showDialog({
            dialogType: DELIVERY_DIALOG,
            contentProps: {
              initialView: !isProfileCompleted ? 'createNewDeliveryAddress' : 'deliveryHours',
            },
          });
        }
        break;
      case 'edit':
        showChangeOrderTypeNotPossibleInEditModeDialog();
        break;
    }
  }, [
    dispatch,
    isProfileCompleted,
    orderDetails.orderMode,
    orderDetails.orderType,
    showChangeOrderTypeNotPossibleInEditModeDialog,
    showDialog,
  ]);

  const onLoadPreviousOrder = useCallback(() => {
    showDialog({
      dialogType: LOAD_PREVIOUS_ORDERS_DIALOG,
    });
  }, [showDialog]);

  const getNavigationButtonProps = useMemo<IHeader['navigationButtonProps']>(() => {
    if (!knownAddress && !profile.fullAddress) {
      return {
        onClick: handleOpenLocation,
      };
    }
  }, [knownAddress, handleOpenLocation, profile]);

  // if undefined it will show navigation Button
  const orderAddress = useMemo<string | undefined>(() => {
    const { selfPickupLocation, orderType } = orderDetails;
    if (orderType === 'selfPickup') {
      return selfPickupLocation?.name || (isMobile ? '' : t('dialog.delivery.chooseSelfPickup'));
    }

    if (clientAddress) {
      return clientAddress;
    }

    if (knownAddress) {
      return typeof knownAddress === 'string'
        ? knownAddress
        : `${knownAddress.address}, ${knownAddress.cityAndCountry}`;
    }
  }, [orderDetails, clientAddress, knownAddress, isMobile, t]);

  const timeTextMobile = useMemo(() => {
    const { orderType, orderMode, selfPickupLocation } = orderDetails;
    let text = 'header.chooseDelivery';

    if (orderMode === 'new' || orderMode === 'edit') {
      if (orderType === 'selfPickup') {
        if (!selfPickupLocation?.name) {
          return (
            <Box textAlign="initial">
              <span>{t('dialog.delivery.chooseSelfPickup')}</span>
              <br />
              <span>{t('header.chooseSelfPickupTime')}</span>
            </Box>
          );
        }
        return t('header.chooseSelfPickup');
      }

      if (orderType === 'delivery') {
        if (clientAddress || knownAddress) {
          return t('header.chooseDelivery');
        }
        return (
          <Box textAlign="initial">
            {!knownAddress && (
              <>
                <span>{t('dialog.delivery.chooseAddress')}</span>
                <br />
              </>
            )}
            <span>{t('header.chooseDeliveryTime')}</span>
          </Box>
        );
      }
    }

    text =
      orderType === 'selfPickup'
        ? 'header.chooseSelfPickupSubscriptionMode'
        : 'header.chooseDeliverySubscriptionMode';

    return t(text);
  }, [clientAddress, knownAddress, orderDetails, t]);

  const chooseTimeText = useMemo(() => {
    const { orderType, orderMode, selfPickupLocation } = orderDetails;
    let text = 'header.chooseDelivery';

    if (orderMode === 'new' || orderMode === 'edit') {
      if (orderType === 'selfPickup') {
        if (!selfPickupLocation?.name) {
          text = 'header.chooseSelfPickupTime';
        } else {
          text = 'header.chooseSelfPickup';
        }
      }

      if (orderType === 'delivery' && !clientAddress && !knownAddress) {
        text = 'header.chooseDeliveryTime';
      }
      return t(text);
    }

    text =
      orderType === 'selfPickup'
        ? 'header.chooseSelfPickupSubscriptionMode'
        : 'header.chooseDeliverySubscriptionMode';

    return t(text);
  }, [orderDetails, t, clientAddress, knownAddress]);

  const orderDate = useMemo<string>(() => {
    if (orderDetails.orderDate && orderDetails.orderHour) {
      return `${renderDateAndWeekday(
        orderDetails.orderDate,
        orderDetails.orderMode === 'edit' ? dateFormatInOrderHistory : defaultDateFormat,
        undefined,
        true,
      )}, ${orderDetails.orderHour}`;
    }

    if (orderDetails.orderTime) return orderDetails.orderTime;

    return chooseTimeText;
  }, [
    chooseTimeText,
    orderDetails.orderDate,
    orderDetails.orderHour,
    orderDetails.orderMode,
    orderDetails.orderTime,
    renderDateAndWeekday,
  ]);

  const getDeliveryProps = useMemo<IHeader['deliveryProps']>(() => {
    const { orderTime, orderType } = orderDetails;

    return {
      iconName: orderType === 'selfPickup' ? 'icon-store-rounded' : 'icon-delivery-rounded',
      deliveryAddress: orderAddress || t('dialog.delivery.chooseAddress'),
      enableRootClick: !orderTime && !isLoggedIn,
      onDeliveryTimeClick: handleLogin,
      deliveryTime: orderDate,
      onClick: handleDeliverySelfPickupClick,
    };
  }, [
    orderDetails,
    orderAddress,
    t,
    isLoggedIn,
    handleLogin,
    orderDate,
    handleDeliverySelfPickupClick,
  ]);

  // used for mobile and desktop
  const getHeaderVariant = useMemo<THeaderVariant>(() => {
    return isMinimumProfileDataCompleted || orderAddress ? 'deliverySelfPickup' : 'default';
  }, [orderAddress, isMinimumProfileDataCompleted]);

  const getDesktopHeaderProps = useMemo<TGetDesktopHeaderProps>(
    () => (variant) => {
      const baseProps = {
        websiteDetails,
        imageUrl: websiteDetails.logoPath,
        showLanguageSwitcher: websiteDetails.websiteSettings.displayMultiLanguage,
      };
      switch (variant) {
        case 'checkout':
          return {
            variant,
            ...baseProps,
          };
        case 'smallEC':
          return {
            showUserMenu: true,
            variant: getHeaderVariant,
            userMenuProps: {
              userMenuSpace: '36px',
              hideMembershipSection: true,
              isLoggedIn,
              onLogin: handleLogin,
              onLogOut: handleLogOut,
              name: profile.firstName,
              lastName: profile.lastName,
              menuItems: defaultMenuItems,
            },
            ...baseProps,
          };
        default:
          return {
            showUserMenu: true,
            variant: getHeaderVariant,
            userMenuProps: {
              isLoggedIn,
              onLogin: handleLogin,
              onLogOut: handleLogOut,
              name: profile.firstName,
              lastName: profile.lastName,
              menuItems: defaultMenuItems,
            },
            navigationButtonProps: getNavigationButtonProps,
            deliveryProps: getDeliveryProps,
            ...baseProps,
          };
      }
    },
    [
      websiteDetails,
      getHeaderVariant,
      isLoggedIn,
      handleLogin,
      handleLogOut,
      profile.firstName,
      profile.lastName,
      defaultMenuItems,
      getNavigationButtonProps,
      getDeliveryProps,
    ],
  );

  const getMobileHeaderProps = useMemo<TGetMobileHeaderProps>(
    () => (variant) => {
      const { orderTime, orderType } = orderDetails;
      const baseProps = {
        categories: menuItems,
        activeCategory: activeMenuItem,
        websiteDetails,
        imageUrl: websiteDetails.logoPath,
      };

      switch (variant) {
        case 'checkout':
          return {
            variant,
            showHamburgerMenu: false,
            showUserMenu: false,
            ...baseProps,
          };
        case 'smallEC':
          return {
            showUserMenu: true,
            variant,
            userMenuProps: {
              hideMembershipSection: true,
              isLoggedIn,
              onLogin: handleLogin,
              onLogOut: handleLogOut,
              name: profile.firstName,
              menuItems: defaultMenuItems,
            },
            ...baseProps,
          };
        default:
          return {
            variant: getHeaderVariant,
            showUserMenu: true,
            showHamburgerMenu: true,
            deliverySelfPickupPopUpProps: {
              iconName: orderType === 'selfPickup' ? 'icon-store' : 'icon-delivery',
              label: `orderType.${orderType}`,
              onDeliveryTimeClick: !isLoggedIn ? handleLogin : handleDeliverySelfPickupClick, // TODO rename onDeliveryTimeClick to onTimeClick
              editClick: !isLoggedIn ? editDeliveryAddress : handleDeliverySelfPickupClick,
              deliveryTime: orderDate,
              deliveryAddress: orderAddress,
              withoutDeliveryTime: !orderTime,
              withoutDeliveryTimeText: timeTextMobile,
            },
            deliverySectionProps: {
              iconClick: handleOpenLocation,
            },
            basketCount: uniqueItemCount,
            userMenuProps: {
              isLoggedIn,
              onLogin: handleLogin,
              onLogOut: handleLogOut,
              name: profile.firstName,
              menuItems: defaultMenuItems,
            },
            menuDrawerProps: {
              onLoadPreviousOrder,
              isLoggedIn,
              onLogin: handleLogin,
              onLogOut: handleLogOut,
              name: profile.firstName,
              lastName: profile.lastName,
              showLanguageSwitcher: websiteDetails.websiteSettings.displayMultiLanguage,
              menuUserItems: defaultMenuItems,
            },
            ...baseProps,
          };
      }
    },
    [
      orderDetails,
      menuItems,
      activeMenuItem,
      websiteDetails,
      isLoggedIn,
      handleLogin,
      handleLogOut,
      profile.firstName,
      profile.lastName,
      getHeaderVariant,
      handleDeliverySelfPickupClick,
      editDeliveryAddress,
      orderDate,
      orderAddress,
      timeTextMobile,
      handleOpenLocation,
      uniqueItemCount,
      defaultMenuItems,
      onLoadPreviousOrder,
    ],
  );

  const shouldDisplayStoreAddress = useMemo((): boolean => {
    return !!(
      websiteDetails.websiteSettings.displayAddress &&
      websiteDetails.store.businessFullAddressWithCity &&
      !websiteDetails.store.hasOnlineBranches
    );
  }, [
    websiteDetails.store.businessFullAddressWithCity,
    websiteDetails.store.hasOnlineBranches,
    websiteDetails.websiteSettings.displayAddress,
  ]);

  return {
    handleLogin,
    getDesktopHeaderProps,
    getMobileHeaderProps,
    shouldDisplayStoreAddress,
  };
};

export default useHeader;

import { useCallback } from 'react';
import { BASKET_DIALOG } from 'store/modules/dialog';
import { TBagOfProductsItemWithMetadata } from 'store/modules/storeProduct';
import { useDialog } from 'hooks/useDialog';
import { useProduct } from 'hooks/useProduct';
import { TUseBagOfProducts } from 'hooks/useBagOfProducts/types';
import { useMobile } from 'hooks/useMobile';

const useBagOfProducts: TUseBagOfProducts = () => {
  const { showDialog, hideDialog } = useDialog();

  const { handleProductSelectedBagItemsChanged } = useProduct();

  const { handleAddStoreProduct } = useProduct();

  const { isMobile } = useMobile();

  const showEditBasketDialog = useCallback(
    (storeProduct, onSaveCallback) => {
      showDialog({
        dialogType: BASKET_DIALOG,
        contentProps: {
          ...storeProduct,
          onSave: (selectedItems: TBagOfProductsItemWithMetadata[]) => {
            if (!storeProduct.cartData) {
              handleAddStoreProduct(storeProduct.id);
            }
            handleProductSelectedBagItemsChanged(
              storeProduct.id,
              selectedItems,
              `productWindow${isMobile ? 'Mobile' : 'Desktop'}`,
            );

            if (onSaveCallback) {
              onSaveCallback();
              return;
            }
            hideDialog();
          },
        },
      });
    },
    [handleAddStoreProduct, handleProductSelectedBagItemsChanged, hideDialog, isMobile, showDialog],
  );

  return {
    showEditBasketDialog,
  };
};

export default useBagOfProducts;

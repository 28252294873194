import { TPrepareToPlaceOrderReducerState } from './types';

export const initialPrepareToPlaceOrderState: TPrepareToPlaceOrderReducerState = {
  clientServiceFee: 0,
  bagsMinTotalValue: 0,
  encryptedOrderToken: '',
  orderMinTotalValue: 0,
  serviceAreaUsageFees: 0,
  expirationTime: 0,
  orderHandlingFeeThreshold: 0,
  orderHandlingFee: 0,
};

import { MouseEvent, useCallback, useMemo } from 'react';

import { TRexailFooterItem } from 'ui/desktop/Footer';
import {
  ACCESSIBILITY_DIALOG,
  PRIVACY_POLICY_DIALOG,
  TERMS_OF_USE_DIALOG,
} from 'store/modules/dialog';
import { useDialog } from 'hooks/useDialog';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { storeContactItems } from 'components/desktop/AboutPage/components/LeaveMessageSection/constants';
import { TGetDesktopFooterProps, TGetMobileFooterProps, TUseFooter } from './types';
import { useDefaultMenuItems } from '../useDefaultMenuItems';

const useFooter: TUseFooter = () => {
  const { showDialog } = useDialog();
  const { defaultFooterMenuItems } = useDefaultMenuItems();

  const termsOfServiceDialog = useCallback(() => {
    showDialog({
      dialogType: TERMS_OF_USE_DIALOG,
      contentProps: {},
    });
  }, [showDialog]);

  const accessibilityDialog = useCallback(() => {
    showDialog({
      dialogType: ACCESSIBILITY_DIALOG,
      contentProps: {},
    });
  }, [showDialog]);

  const privacyPolicyDialog = useCallback(() => {
    showDialog({
      dialogType: PRIVACY_POLICY_DIALOG,
      contentProps: {},
    });
  }, [showDialog]);

  const websiteDetails = useWebsiteDetails();
  const termsItems: TRexailFooterItem[] = useMemo(() => {
    return [
      {
        id: 1,
        label: 'footer.termsAndConditions.terms',
        link: { href: '/terms-of-use' },
        onClick: (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          termsOfServiceDialog();
        },
      },
      {
        id: 2,
        label: 'footer.termsAndConditions.accessibilityStatement',
        link: { href: '/accessibility-statement' },
        onClick: (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          accessibilityDialog();
        },
      },
      {
        id: 3,
        label: 'footer.termsAndConditions.privacy',
        link: { href: '/privacy-policy' },
        onClick: (e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          privacyPolicyDialog();
        },
      },
    ];
  }, [accessibilityDialog, privacyPolicyDialog, termsOfServiceDialog]);

  const contactItems = useMemo(() => {
    return storeContactItems(websiteDetails);
  }, [websiteDetails]);

  // TODO contact items should be rendered directly inside footer + figure out target blank for links that go outside of website
  const getDesktopFooterProps = useMemo<TGetDesktopFooterProps>(
    () => (variant) => {
      return {
        websiteDetails,
        variant,
        termsItems,
        menuItems: defaultFooterMenuItems,
        imagesUrl: {
          upImageUrl: websiteDetails.theme.images.footerTop,
          bottomImageUrl: websiteDetails.theme.images.footerBottom,
          iconUrl: websiteDetails.logoPath,
          companyPoweredLogoUrl: '/assets/images/logo-powered-by.svg',
        },
        description: websiteDetails.descriptionForCustomers,
        contactItems,
      };
    },
    [contactItems, defaultFooterMenuItems, websiteDetails, termsItems],
  );

  // TODO - decide which pages to show on mobile
  const getMobileFooterProps = useMemo<TGetMobileFooterProps>(
    () => (variant) => {
      return {
        websiteDetails,
        variant,
        termsItems,
        imagesUrl: {
          upImageUrl: websiteDetails.theme.images.footerTop,
          bottomImageUrl: websiteDetails.theme.images.footerBottom,
          iconUrl: websiteDetails.logoPath,
          companyPoweredLogoUrl: '/assets/images/logo-powered-by.svg',
        },
        address: websiteDetails.store.businessFullAddressWithCity,
        description: websiteDetails.descriptionForCustomers,
        menuItems: [],
        contactItems,
      };
    },
    [contactItems, websiteDetails, termsItems],
  );

  return {
    getDesktopFooterProps,
    getMobileFooterProps,
  };
};

export default useFooter;

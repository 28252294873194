import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesUseFinalCostData } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesUseFinalCostData> =>
    createStyles({
      creditAtCheckoutDescription: {
        fontSize: 16,
        color: theme.palette.grey[600],
      },
      tooltip: {
        width: '221px',
        padding: theme.spacing(1),
        backgroundColor: `${theme.palette.secondary.main}!important`,
        top: '10px !important',
        left: '2px !important',
        [theme.breakpoints.up('md')]: {
          width: theme.direction === 'rtl' ? '221px' : '268px',
          top: '5px !important',
          left: '1px !important',
        },
      },
    }),
);

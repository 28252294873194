import {
  TOrderDetailsSelector,
  TOrderModeSelector,
  TOrderTypeSelector,
} from 'store/modules/orderDetails/types';

const getOrderType: TOrderTypeSelector = ({ activeOrder: { orderDetails } }) =>
  orderDetails.orderType;
const getOrderMode: TOrderModeSelector = ({ activeOrder: { orderDetails } }) =>
  orderDetails.orderMode;
const getOrderDetails: TOrderDetailsSelector = ({ activeOrder: { orderDetails } }) => orderDetails;

export { getOrderMode, getOrderDetails, getOrderType };

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesContentCardSlider } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesContentCardSlider, { isOpenedTopCart: boolean }> =>
    createStyles({
      contentCardsSlider: {
        maxWidth: '1240px',
        margin: '0 auto',
      },
      contentCardWrapper: {
        height: '420px',
        width: '320px',
        objectFit: 'contain',
        borderRadius: ' 16px',
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          height: '536px',
          width: ({ isOpenedTopCart }) => (isOpenedTopCart ? 438 : 609),
        },
      },
      cardBottomSection: {
        padding: '20px 20px 26px',
        display: 'flex',
        flexDirection: 'column',
        height: '56%',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          padding: '30px 30px 34px',
          height: '50%',
        },
      },

      contentCardsSliderWrapper: ({ isOpenedTopCart }) => ({
        maxWidth: isOpenedTopCart ? 898 : '100%',
        '& .swiper-wrapper': {
          boxSizing: 'border-box',
        },
      }),
      imageWrapper: {
        height: '184px',
        borderRadius: '16px 16px 0px 0px',
        [theme.breakpoints.up('md')]: {
          height: '270px',
        },
      },
      contentCardLabel: {
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 500,
        textAlign: 'left',
        marginBottom: '6px',
        [theme.breakpoints.up('md')]: {
          fontSize: '26px',
          lineHeight: '30px',
          marginBottom: '10px',
        },
      },
      contentCardDescription: {
        fontSize: '16px',
        lineHeight: '23px',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
          fontSize: '20px',
          lineHeight: '30px',
        },
      },
      linkLabel: {
        fontSize: '16px',
        lineHeight: '20px',
        color: theme.palette.primary.main,
        fontWeight: 500,
        marginRight: '6px',
        [theme.breakpoints.up('md')]: {
          fontSize: '18px',
          lineHeight: '22px',
        },
      },
      contentCardLink: {
        display: 'flex',
        alignItems: 'baseline',
      },
      iconArrow: {
        fontSize: '12px',
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
        },
      },
    }),
);

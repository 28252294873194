import { Box, Divider, IconButton } from '@material-ui/core';

import { DeliveryAvailableService } from 'components/common/availableService';

import { ClientForm } from 'components/common/forms';
import SelfPickupOption from 'components/common/SelfPickupOption';
import SkeletonDeliveryOrSelfPickupAvailableHours from 'components/common/skeletons/SkeletonDeliveryOrSelfPickupAvailableHours';

import {
  TSelfPickupAreaItem,
  useAvailableServiceAreas,
  useClientForm,
  useDialog,
  useMobile,
  useSelfPickupAndDeliveryService,
  useWebsiteDetails,
} from 'hooks';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  authSelectors,
  orderDetailsActions,
  SELF_PICKUP_DIALOG,
  shoppingFlowActions,
  userSelectors,
} from 'store';
import { fullyCompleteRegistration } from 'store/modules/analytics/actions';

import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { getDetailsAtStoreLevel } from 'store/modules/user/selectors';
import Icon from 'ui/common/icons/Icon';

import DeliveryPickUpTabs from 'ui/common/tabs/DeliveryPickUpTabs';

import Typography from 'ui/common/Typography';

import { isSubscriptionMode } from 'utils/helpers/subscriptions';
import useStyles from './styles';
import { IDeliveryDialog, TDeliveryView } from './types';

const DeliveryDialog: FC<IDeliveryDialog> = ({ initialView }) => {
  const classes = useStyles();

  const { isMobile } = useMobile();

  const dispatch = useDispatch();

  const { defaultValues, onSubmit } = useClientForm();

  const { showDialog } = useDialog();

  const onClientBehalf = useSelector(authSelectors.onClientBehalf);
  const orderMode = useSelector(getOrderMode);
  const orderDetails = useSelector(getOrderDetails);
  const clientAddress = useSelector(userSelectors.getClientAddress);
  const websiteDetails = useWebsiteDetails();
  const detailsAtStoreLevel = useSelector(getDetailsAtStoreLevel);

  const {
    loading,
    deliveryArea,
    deliveryAreaErrorMessage,
    getAvailableAreas,
    selfPickupAreasArray,
  } = useAvailableServiceAreas();

  const { sortSelfPickupAreas } = useSelfPickupAndDeliveryService();

  const { handleDeliverySubmit, isSubmitting } = useSelfPickupAndDeliveryService();

  const [view, setView] = useState<TDeliveryView>(initialView || 'deliveryHours');
  const [selfPickupAreaMatchesClientCity, setSelfPickupAreaMatchesClientCity] =
    useState<TSelfPickupAreaItem[]>();

  const handleEditPersonalDetails = useCallback(() => {
    setView('editDeliveryAddress');
  }, []);

  const cancelEditPersonalDetails = useCallback(() => {
    setView('deliveryHours');
  }, []);

  const onSuccessUpdateClient = useCallback(
    (values) => {
      getAvailableAreas();
      setView('deliveryHours');

      if (view === 'createNewDeliveryAddress') {
        dispatch(fullyCompleteRegistration({ ...values, ...detailsAtStoreLevel }));
      }
    },
    [detailsAtStoreLevel, dispatch, getAvailableAreas, view],
  );

  const handleClickSelfPickup = useCallback(
    (serviceArea) => {
      dispatch(shoppingFlowActions.updateKnownAddressAndSubCatalog(null, null));

      showDialog({
        dialogType: SELF_PICKUP_DIALOG,
        contentProps: {
          initialServiceArea: serviceArea,
        },
      });
    },
    [dispatch, showDialog],
  );

  const renderHeaderContent = useMemo<ReactNode>(() => {
    return (
      <Box className={classes.headerContent}>
        <Box className={classes.deliveryBlock}>
          <Typography
            variant={isMobile ? 'h2' : 'h1'}
            color="light"
            className={classes.popupHeader}
          >
            {'deliveryPickUp.chooseDeliveryMethod'}
          </Typography>
        </Box>
        <Box className={classes.tabsWrapper}>
          <DeliveryPickUpTabs
            onTabClick={handleClickSelfPickup}
            orderType="delivery"
            showSideCorners
          />
        </Box>
      </Box>
    );
  }, [
    classes.deliveryBlock,
    classes.headerContent,
    classes.popupHeader,
    classes.tabsWrapper,
    handleClickSelfPickup,
    isMobile,
  ]);

  const renderEditBlock = useMemo<ReactNode>(() => {
    return (
      <Box className={classes.editBlock}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={isMobile ? 'h4' : 'h3'} color="secondary">
            {'dialog.delivery.shippingAddress'}
          </Typography>
          {!onClientBehalf && (
            <IconButton className={classes.iconRoot} onClick={handleEditPersonalDetails}>
              <Icon
                name="icon-edit"
                color="primary"
                classes={{
                  root: classes.editIcon,
                }}
              />
              {!isMobile && (
                <Typography
                  className={classes.editText}
                  variant="body1"
                  color="primary"
                  fontSize={16}
                  fontWeight="medium"
                >
                  {'dialog.delivery.editText'}
                </Typography>
              )}
            </IconButton>
          )}
        </Box>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.clientAddress}
          isTranslate={false}
        >
          {clientAddress}
        </Typography>
      </Box>
    );
  }, [
    classes.editBlock,
    classes.iconRoot,
    classes.editIcon,
    classes.editText,
    classes.clientAddress,
    isMobile,
    onClientBehalf,
    handleEditPersonalDetails,
    clientAddress,
  ]);

  const renderCoordination = useMemo(() => {
    return (
      <Box className={classes.coordinationBlock}>
        <Box>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            color="secondary"
            className={classes.coordinationTitle}
          >
            {isSubscriptionMode(orderMode)
              ? 'stepper.subscription.coordinationArrival'
              : 'header.chooseDelivery'}
          </Typography>
          {!selfPickupAreaMatchesClientCity?.length && !deliveryAreaErrorMessage && (
            <Typography variant="body1" color="grey" fontSize={14}>
              {'dialog.delivery.coordination.description'}
            </Typography>
          )}
          {deliveryAreaErrorMessage && !selfPickupAreaMatchesClientCity?.length && (
            <Box>
              <Typography variant="body1" fontSize={14} className={classes.errorMessage}>
                {deliveryAreaErrorMessage}
              </Typography>
            </Box>
          )}
          {!!selfPickupAreaMatchesClientCity?.length && (
            <Box>
              <Typography variant="body1" fontSize={14} fontWeight="medium">
                {'dialog.delivery.storeDoesntMakeDeliveryToYourAddressButSelfPickupExist'}
              </Typography>
              {selfPickupAreaMatchesClientCity.map((serviceArea) => (
                <Box mt={2} key={serviceArea.serviceArea.storeServiceAreaId}>
                  <Box my={24 / 8}>
                    <SelfPickupOption
                      onClick={() => handleClickSelfPickup(serviceArea.serviceArea)}
                      serviceArea={serviceArea.serviceArea}
                      distanceLabel="dialog.delivery.kmFromYourAddress"
                    />
                  </Box>
                  <Divider className={classes.divider} />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  }, [
    classes.coordinationBlock,
    classes.coordinationTitle,
    classes.divider,
    classes.errorMessage,
    deliveryAreaErrorMessage,
    handleClickSelfPickup,
    isMobile,
    orderMode,
    selfPickupAreaMatchesClientCity,
  ]);

  const renderEditProfileBlock = useMemo<ReactNode>(() => {
    return (
      <Box mt={isMobile ? 20 / 8 : 30 / 8}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={isMobile ? 'h4' : 'h3'} color="secondary">
            {'dialog.delivery.shippingAddress'}
          </Typography>
          {view === 'editDeliveryAddress' && (
            <IconButton className={classes.iconRoot} onClick={cancelEditPersonalDetails}>
              <Icon
                name="icon-button-x"
                color="primary"
                classes={{
                  root: classes.closeIcon,
                }}
              />
              {!isMobile && (
                <Typography
                  className={classes.editText}
                  variant="body1"
                  color="primary"
                  fontSize={16}
                  fontWeight="medium"
                >
                  {'button.cancel'}
                </Typography>
              )}
            </IconButton>
          )}
        </Box>
        <Box pt={14 / 8}>
          <ClientForm
            defaultValues={defaultValues}
            handleSubmit={onSubmit(onSuccessUpdateClient)}
            type="delivery_popup"
          />
        </Box>
      </Box>
    );
  }, [
    isMobile,
    view,
    classes.iconRoot,
    classes.closeIcon,
    classes.editText,
    cancelEditPersonalDetails,
    defaultValues,
    onSubmit,
    onSuccessUpdateClient,
  ]);

  const renderDelivery = useMemo<ReactNode>(() => {
    return (
      <>
        {renderEditBlock}

        <Divider className={classes.divider} />
        {renderCoordination}
        {loading ? (
          <Box mb={20}>
            <SkeletonDeliveryOrSelfPickupAvailableHours />
            <SkeletonDeliveryOrSelfPickupAvailableHours />
          </Box>
        ) : (
          !!deliveryArea.availableArea.length && (
            <Box>
              <DeliveryAvailableService
                isSubmitting={isSubmitting}
                availableAreas={deliveryArea.availableArea}
                onSubmit={(timeFrame, options) =>
                  handleDeliverySubmit(deliveryArea.storeServiceAreaId, timeFrame, options)
                }
              />
            </Box>
          )
        )}
      </>
    );
  }, [
    renderEditBlock,
    classes.divider,
    renderCoordination,
    loading,
    deliveryArea.availableArea,
    deliveryArea.storeServiceAreaId,
    isSubmitting,
    handleDeliverySubmit,
  ]);

  const renderContent = useMemo<ReactNode>(() => {
    switch (view) {
      case 'editDeliveryAddress':
      case 'createNewDeliveryAddress':
        return renderEditProfileBlock;
      case 'deliveryHours':
      default:
        return renderDelivery;
    }
  }, [view, renderEditProfileBlock, renderDelivery]);

  useEffect(() => {
    getAvailableAreas();
  }, [getAvailableAreas]);

  useEffect(() => {
    if (orderDetails.orderType && orderDetails.orderType !== 'delivery') {
      dispatch(orderDetailsActions.openDeliverySelfPickupRequest('delivery'));
    }
  }, [dispatch, orderDetails.orderType]);

  useEffect(() => {
    if (deliveryAreaErrorMessage) {
      const selfPickupAreasThatMatchesClientCity = selfPickupAreasArray.filter(
        (area) => area.serviceArea.matchesClientCity,
      );
      setSelfPickupAreaMatchesClientCity(sortSelfPickupAreas(selfPickupAreasThatMatchesClientCity));
    } else {
      setSelfPickupAreaMatchesClientCity([]);
    }
  }, [deliveryAreaErrorMessage, selfPickupAreasArray, sortSelfPickupAreas]);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        {renderHeaderContent}
        <Box className={classes.imageWrapper}>
          <img
            alt=""
            className={classes.headerImage}
            src={websiteDetails.theme.images.deliveryPopupHeader}
          />
        </Box>
      </Box>
      <Box className={classes.content}>{renderContent}</Box>
    </Box>
  );
};

export default DeliveryDialog;

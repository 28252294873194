import { Box, IconButton } from '@material-ui/core';

import { useMobile } from 'hooks/useMobile';
import React, { FC, useCallback, useMemo, useState } from 'react';
import Icon from 'ui/common/icons/Icon/Icon';
import Tooltip from 'ui/common/Tooltip/Tooltip';

import Typography from 'ui/common/Typography/Typography';
import useStyles from './styles';
import { ITooltipOnIcon } from './types';

const TooltipOnIcon: FC<ITooltipOnIcon> = ({
  tooltipTitle,
  iconName = 'icon-question-1',
  tooltipPlacement = 'bottom',
  initialClasses,
}) => {
  const { isMobile } = useMobile();

  const classes = useStyles();

  const [openTooltipOnMobile, setOpenTooltipOnMobile] = useState(false);

  const toggleTooltip = useCallback(() => {
    if (!isMobile) return;
    setOpenTooltipOnMobile((oldState) => !oldState);
  }, [isMobile]);

  const mobileProps = useMemo(() => {
    if (!isMobile) return;

    return {
      disableFocusListener: true,
      disableHoverListener: true,
      disableTouchListener: true,
      open: openTooltipOnMobile,
    };
  }, [isMobile, openTooltipOnMobile]);

  return (
    <Tooltip
      placement={tooltipPlacement}
      title={<Typography className={classes.tooltipTitle}>{tooltipTitle}</Typography>}
      PopperProps={{
        ...mobileProps,
        disablePortal: true,
      }}
      initialClasses={{
        tooltip: initialClasses?.tooltip || classes.tooltip,
      }}
    >
      <Box display="inline">
        <IconButton className={classes.questionIconButton} onClick={toggleTooltip}>
          <Icon
            name={iconName}
            color="primary"
            fontSize="small"
            classes={{ root: initialClasses?.questionIcon || classes.questionIcon }}
          />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default TooltipOnIcon;

import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { Box, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import CTAButton from 'ui/common/buttons/CTAButton';

import { useMobile } from 'hooks/useMobile';
import BasketEditCard from 'ui/common/BasketEditCard';
import { useNotify } from 'hooks/useNotify';
import { useTranslation } from 'react-i18next';
import FixedButton from 'components/mobile/FixedButton';
import { TBagOfProductsItemWithMetadata } from 'store';
import useStyles from './styles';

import { IBasketDialog } from './types';

const BasketDialog: FC<IBasketDialog> = ({ onSave, ...storeProductDisplay }) => {
  const { isMobile } = useMobile();
  const { notifyShow } = useNotify();
  const { t } = useTranslation();
  const lang = useSelector(getLang);

  const [showReplacementSection, setShowReplacementSection] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [selectedReplacementItem, setSelectedReplacementItem] =
    useState<TBagOfProductsItemWithMetadata>();

  const [selectedOriginalItem, setSelectedOriginalItem] =
    useState<TBagOfProductsItemWithMetadata>();
  const [currentSelectedItems, setCurrentSelectedItems] = useState<
    TBagOfProductsItemWithMetadata[]
  >(() => {
    if (storeProductDisplay.cartData && storeProductDisplay.cartData.orderItem.selectedBagItems) {
      return storeProductDisplay.cartData.orderItem.selectedBagItems;
    }

    if (!storeProductDisplay.bagOfProductsJson) {
      return [];
    }

    return storeProductDisplay.bagOfProductsJson?.selectedItems.map((selectedItem) => ({
      ...selectedItem,
      isReplacementItem: false,
      isOriginalItem: true,
    }));
  });

  const classes = useStyles({ isReplaceSectionShow: showReplacementSection, lang });

  const eligibleReplacementItems = useMemo<TBagOfProductsItemWithMetadata[]>(() => {
    if (!selectedOriginalItem || !storeProductDisplay.bagOfProductsJson) {
      return [];
    }

    return storeProductDisplay.bagOfProductsJson.items
      .filter(
        (item) =>
          item.groupId === selectedOriginalItem.groupId && item.id !== selectedOriginalItem.id,
      )
      .map((item) => ({
        ...item,
        isOriginalItem:
          selectedIndex !== undefined &&
          item.id === storeProductDisplay.bagOfProductsJson?.selectedItems[selectedIndex].id,
        isReplacementItem: false,
      }));
  }, [selectedIndex, selectedOriginalItem, storeProductDisplay.bagOfProductsJson]);

  const hideReplacementSection = useCallback(() => {
    setShowReplacementSection(false);
    setSelectedIndex(undefined);
    setSelectedOriginalItem(undefined);
    setSelectedReplacementItem(undefined);
  }, []);

  const replaceItems = useCallback(
    (selectedItemToReplace: TBagOfProductsItemWithMetadata) => {
      const newSelectedItems = currentSelectedItems.map((item, index) => {
        if (index !== selectedIndex) {
          return { ...item };
        }

        return {
          ...selectedItemToReplace,
          requestedQuantity: 1,
          isReplacementItem: !selectedItemToReplace.isOriginalItem,
        };
      });

      if (
        storeProductDisplay.bagOfProductsJson &&
        newSelectedItems.reduce(
          (totalReplacements, selectedItem) =>
            totalReplacements + (selectedItem.isReplacementItem ? 1 : 0),
          0,
        ) > storeProductDisplay.bagOfProductsJson.maxNumOfReplacements
      ) {
        notifyShow({
          message: 'dialog.basket.maxReplacementsReached',
        });
        return;
      }

      setCurrentSelectedItems(newSelectedItems);

      setTimeout(() => {
        hideReplacementSection();
      }, 700);
    },
    [
      currentSelectedItems,
      hideReplacementSection,
      notifyShow,
      selectedIndex,
      storeProductDisplay.bagOfProductsJson,
    ],
  );

  const handleSaveClick = useCallback(() => {
    if (onSave) onSave(currentSelectedItems);
  }, [currentSelectedItems, onSave]);

  const headerContent = useMemo<ReactNode>(() => {
    return (
      <Box className={classes.headerContent}>
        <Box>
          <Typography color="light" fontWeight="bold" variant="body2" className={classes.title}>
            {t('dialog.basket.title', { bagName: storeProductDisplay.fullName })}
          </Typography>
          <Typography color="light" fontSize={14}>
            {t('dialog.basket.subtitle', {
              maxNumOfReplacements: storeProductDisplay.bagOfProductsJson?.maxNumOfReplacements,
            })}
          </Typography>
        </Box>
        {!isMobile && (
          <CTAButton onClick={handleSaveClick} classes={{ root: classes.saveBtnRoot }}>
            <Typography fontWeight="medium">{'dialog.basket.save'}</Typography>
          </CTAButton>
        )}
      </Box>
    );
  }, [
    classes.headerContent,
    classes.title,
    classes.saveBtnRoot,
    t,
    storeProductDisplay.fullName,
    storeProductDisplay.bagOfProductsJson?.maxNumOfReplacements,
    isMobile,
    handleSaveClick,
  ]);

  const replacementsContent = useMemo(() => {
    return (
      <Box className={classes.replaceSectionWrapper}>
        {showReplacementSection && (
          <Box className={classes.replaceSection}>
            {isMobile && (
              <IconButton
                aria-label="close"
                onClick={hideReplacementSection}
                classes={{ root: classes.closeButton }}
                size="small"
              >
                <Icon name="icon-button-x" color="primary" classes={{ root: classes.closeIcon }} />
              </IconButton>
            )}

            <Typography color="secondary" fontWeight="bold" variant="body2">
              {'dialog.basket.replaceProducts'}
            </Typography>

            <Box mt={1.5}>
              {showReplacementSection &&
                eligibleReplacementItems.map((item) => {
                  return (
                    <Box className={classes.cardWrapper} key={item.id}>
                      <BasketEditCard
                        bagItem={item}
                        cardToSwap
                        displayBorder={
                          selectedReplacementItem && item.id === selectedReplacementItem.id
                        }
                        markArrow={item.isOriginalItem}
                        quantity={{ text: t('dialog.basket.defaultItemQuantity'), value: 1 }}
                        imageUrl={`${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${item.product.mainImageUrl}`}
                        onItemClick={() => {
                          setSelectedReplacementItem(item);
                          replaceItems(item);
                        }}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}
      </Box>
    );
  }, [
    classes.cardWrapper,
    classes.closeButton,
    classes.closeIcon,
    classes.replaceSection,
    classes.replaceSectionWrapper,
    eligibleReplacementItems,
    hideReplacementSection,
    isMobile,
    replaceItems,
    selectedReplacementItem,
    showReplacementSection,
    t,
  ]);

  const mainContent = useMemo<ReactNode>(() => {
    return (
      <>
        <Box className={classes.contentWrapper}>
          <Box className={classes.inBasketSection}>
            <Typography color="secondary" fontWeight="bold" variant="body2">
              {'dialog.basket.productInBasket'}
            </Typography>
            <Box mt={1.5}>
              {currentSelectedItems.map((item, index) => {
                return (
                  <Box component="article" key={index}>
                    <Box className={classes.cardWrapper}>
                      <BasketEditCard
                        bagItem={item}
                        markArrow={item.isReplacementItem}
                        displayBorder={selectedIndex === index}
                        quantity={{ text: t('dialog.basket.defaultItemQuantity'), value: 1 }}
                        imageUrl={`${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${item.product.mainImageUrl}`}
                        onItemClick={() => {
                          if (selectedIndex === index) {
                            hideReplacementSection();
                          } else {
                            setShowReplacementSection(true);
                            setSelectedIndex(index);
                            setSelectedOriginalItem(item);
                            setSelectedReplacementItem(undefined);
                          }
                        }}
                      />
                    </Box>
                    {isMobile && selectedIndex === index && replacementsContent}
                  </Box>
                );
              })}
            </Box>
          </Box>
          {!isMobile && replacementsContent}
        </Box>
      </>
    );
  }, [
    classes.contentWrapper,
    classes.inBasketSection,
    classes.cardWrapper,
    currentSelectedItems,
    isMobile,
    replacementsContent,
    selectedIndex,
    t,
    hideReplacementSection,
  ]);

  return (
    <Box>
      <Box className={classNames(classes.root, classes.header)}>{headerContent}</Box>
      <Box className={classNames(classes.root, classes.content)}>{mainContent}</Box>
      {isMobile && (
        <FixedButton>
          <CTAButton fullWidth onClick={handleSaveClick}>
            dialog.basket.save
          </CTAButton>
        </FixedButton>
      )}
    </Box>
  );
};

export default BasketDialog;

import { Box, Grid } from '@material-ui/core';

import { CheckBoxWithLabelField, TextField } from 'components/common/formsFields';
import { Field, Form, FormikProps, withFormik } from 'formik';

import { useMobile } from 'hooks';
import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import CTAButton from 'ui/common/buttons/CTAButton';

import { object, string } from 'yup';

import useStyles from './styles';
import {
  IPersonalDetailsForm,
  IPersonalDetailsFormValues,
  TPersonalDetailsFormSchema,
} from './types';

const PersonalDetailsSchema: TPersonalDetailsFormSchema = () => {
  return object().shape({
    firstName: string().required('validation.required'),
    lastName: string().required('validation.required'),
    email: string().email('validation.email').required('validation.required'),
    cellPhone: string().required('validation.required'),
  });
};

const PersonalDetailsForm: FC<IPersonalDetailsForm & FormikProps<IPersonalDetailsFormValues>> = ({
  isValid,
  isSubmitting,
  dirty,
  defaultValues,
}) => {
  const classes = useStyles();

  const { isMobile } = useMobile();

  const { t } = useTranslation();

  return (
    <Form>
      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={6}>
          <Field name="firstName" required component={TextField} />
        </Grid>
        <Grid item xs={6}>
          <Field name="lastName" required component={TextField} />
        </Grid>
      </Grid>
      <Box mt={isMobile ? 1 : 12 / 8}>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12}>
            <Field
              name="email"
              type="email"
              required
              component={TextField}
              disabled={!!defaultValues?.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="cellPhone"
              label="phone"
              type="number"
              required
              disabled
              component={TextField}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={isMobile ? 9 / 8 : 15 / 8}>
        <Field
          name="clientDetailsOnStoreLevel.receivesCommercialMessages"
          component={CheckBoxWithLabelField}
          type="checkbox"
          classes={{ label: classes.receivesMessagesLabel }}
          label={t('input.receivesCommercialMessages')}
        />
      </Box>
      <Box mt={15 / 8} pb={isMobile ? 0 : 70 / 8}>
        <CTAButton fullWidth type="submit" disabled={!isValid || !dirty || isSubmitting}>
          {'dialog.signUp.submitButton'}
        </CTAButton>
      </Box>
    </Form>
  );
};

export default withFormik<IPersonalDetailsForm, IPersonalDetailsFormValues>({
  mapPropsToValues: ({ defaultValues }) => {
    return {
      firstName: (defaultValues && defaultValues.firstName) || '',
      lastName: (defaultValues && defaultValues.lastName) || '',
      email: (defaultValues && defaultValues.email) || '',
      cellPhone: (defaultValues && defaultValues.cellPhone) as string,
      clientDetailsOnStoreLevel:
        (defaultValues && defaultValues.clientDetailsOnStoreLevel) || undefined,
    };
  },
  validationSchema: (props: IPersonalDetailsForm) => PersonalDetailsSchema(props),
  handleSubmit: (values, { props, setSubmitting, setErrors, setStatus, resetForm }) => {
    props.handleSubmit(values, { setSubmitting, setErrors, setStatus, resetForm });
  },
  enableReinitialize: true,
})(PersonalDetailsForm);

import { ICoupon } from 'store';
import { clientCouponType, TCouponServer } from 'types';
import { prepareStoreProductSource } from 'utils/helpers/storeProduct';

export function prepareCoupons(couponsToChange: TCouponServer[]): ICoupon[] {
  return couponsToChange.map((coupon) => ({
    ...coupon,
    storeProductSource: prepareStoreProductSource(coupon.storeProductSource),
    discountSourceType: clientCouponType,
  }));
}

import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesMembershipPromotionCard } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesMembershipPromotionCard> =>
    createStyles({
      membershipCardWrapper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '6px',
        minHeight: '74px',
        padding: '17px 14px 17px 18px',
        [theme.breakpoints.up('md')]: {
          padding: ' 20px',
        },
      },
      diamondIcon: {
        fontSize: '32px',
        color: theme.palette.primary.main,
        marginRight: '13px',
      },
      membershipTitle: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
        marginBottom: '4px',
      },
      membershipValidUntil: {
        fontSize: '13px',
        lineHeight: '14px',
      },
      useButton: {
        width: theme.direction === 'rtl' ? '70px' : '90px',
        minWidth: theme.direction === 'rtl' ? '70px' : '90px',
        boxShadow: 'none',
        marginLeft: '12px',
        [theme.breakpoints.up('md')]: {
          width: '110px',
          minWidth: '110px',
        },
      },
    }),
);

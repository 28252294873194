import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { useFormik } from 'formik';

import TextInput from 'ui/common/inputs/TextInput';
import { IEmailInput } from './types';

const EmailInput: FC<IEmailInput> = ({
  value,
  onChange,
  onIsValidChange,
  customError,
  errorMessage,
  inputProps,
}) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    email: yup.string().email(t('validation.email')),
  });

  const formik = useFormik({
    initialValues: {
      email: value,
    },
    validationSchema,
    onSubmit: () => {}, //  prop is required
  });

  useEffect(() => {
    onIsValidChange(formik.isValid);
  }, [onIsValidChange, formik.isValid]);

  return (
    <TextInput
      label="email"
      type="email"
      id="email"
      inputProps={{
        style: { textTransform: 'lowercase' },
      }}
      onChange={(e) => {
        formik.setFieldValue('email', e.target.value);
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      value={formik.values.email}
      onBlur={formik.handleBlur}
      error={(formik.touched.email && !!formik.errors.email) || customError}
      helperText={(formik.touched.email && formik.errors.email) || errorMessage || ''}
      {...inputProps}
      required
    />
  );
};

export default EmailInput;

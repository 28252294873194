import { Box } from '@material-ui/core';
import { IPromotedProductsSlider } from 'components/common/HomePageContent/components/common/PromotedProductsSlider/types';
import HomePageSlider from 'components/common/HomePageContent/components/desktop/HomePageSlider';
import MobileHomePageSlider from 'components/common/HomePageContent/components/mobile/MobileHomePageSlider';
import { useMobile } from 'hooks';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { IStoreProductDisplay } from 'store';
import { TMixedType } from 'types';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import StoreProductCard from 'ui/common/StoreProductCard';
import { TProductCardViewMode } from 'ui/mobile/buttons/ProductCardViewMode/types';
import useStyles from './styles';

const PromotedProductsSlider: FC<IPromotedProductsSlider> = ({
  products,
  linkLabel,
  linkHref,
  title,
}) => {
  const { isMobile } = useMobile();

  const [selectedProductCardTooltipId, setSelectedProductCardTooltipId] =
    useState<TMixedType | null>(null);

  const mainLayoutContext = useContext(MainLayoutContext);

  const classes = useStyles({ isOpenedTopCart: mainLayoutContext.isOpenedTopCart });

  const amountOfProductsCardToShow = useMemo(() => {
    return mainLayoutContext.isOpenedTopCart ? 4 : 5;
  }, [mainLayoutContext.isOpenedTopCart]);

  const promotedProduct = useCallback(
    (storeProductToDisplay: IStoreProductDisplay) => {
      const mobileProps = isMobile && {
        isTooltipOpen: selectedProductCardTooltipId === storeProductToDisplay.id,
        onProductTooltipClick: (productId: TMixedType) =>
          setSelectedProductCardTooltipId(
            selectedProductCardTooltipId === productId ? null : productId,
          ),
        viewMode: 'multiple' as TProductCardViewMode,
      };

      return (
        <Box width={isMobile ? 154 : (mainLayoutContext.isOpenedTopCart && 208) || 230}>
          <StoreProductCard
            storeProductDisplay={storeProductToDisplay}
            cardWidth={isMobile ? 154 : (mainLayoutContext.isOpenedTopCart && 208) || 230}
            sourceEvent={`homePage${isMobile ? 'Mobile' : 'Desktop'}`}
            partialKeyboardSupport
            {...mobileProps}
          />
        </Box>
      );
    },
    [isMobile, mainLayoutContext.isOpenedTopCart, selectedProductCardTooltipId],
  );

  return (
    <>
      {isMobile ? (
        <>
          {!!products?.length && (
            <MobileHomePageSlider
              slides={products.map((product) => promotedProduct(product))}
              title={title}
              linkLabel={linkLabel}
              linkHref={linkHref}
            />
          )}
        </>
      ) : (
        <Box className={classes.promotedProductsSlider}>
          {!!products?.length && (
            <Box className={classes.productsWrapper}>
              <HomePageSlider
                slides={products.map((product) => promotedProduct(product))}
                linkLabel={linkLabel}
                linkHref={linkHref}
                title={title}
                amountOfSlidesToShow={amountOfProductsCardToShow}
                spaceBetweenSlides={22}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default PromotedProductsSlider;

import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { userSelectors } from 'store';
import { TRenderHiLoginText, TUseHiLoginText } from './types';

// need to render login text or hi text for mobile and desktop
const useHiLoginText: TUseHiLoginText = () => {
  const { t } = useTranslation();

  const isMinimumProfileDataCompleted = useSelector(userSelectors.isMinimumProfileDataCompleted);

  const renderHiLoginText = useMemo<TRenderHiLoginText>(
    () =>
      ({ isLoggedIn, hiText, name, loginText }) => {
        return isLoggedIn && isMinimumProfileDataCompleted ? `${t(hiText)} ${name}` : t(loginText);
      },
    [isMinimumProfileDataCompleted, t],
  );

  return renderHiLoginText;
};

export default useHiLoginText;

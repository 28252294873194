import React, { FC, useCallback } from 'react';

import { Button, Snackbar as MUISnackbar } from '@material-ui/core';

import Typography from '../Typography';

import useStyles from './styles';

import { ISnackbar, TOnClose } from './types';

const Snackbar: FC<ISnackbar> = ({
  showUndo,
  undoText = 'snackbar.undo',
  vertical = 'bottom',
  horizontal = 'center',
  onClose,
  ...rest
}) => {
  const classes = useStyles();

  const contentsProps = {
    classes: { root: classes.contentsPropsRoot, message: classes.message },
  };

  const handleOnClose = useCallback<TOnClose>(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      if (onClose) {
        onClose(event, reason);
      }
    },
    [onClose],
  );

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      classes={{ root: classes.root }}
      onClose={handleOnClose}
      ContentProps={contentsProps}
      action={
        <>
          {showUndo && (
            <Button size="small" classes={{ root: classes.buttonRoot }} onClick={handleOnClose}>
              <Typography fontWeight="medium" fontSize={16} color="primary">
                {undoText}
              </Typography>
            </Button>
          )}
        </>
      }
      {...rest}
    />
  );
};

export default Snackbar;
